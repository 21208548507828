import {
  ADD_DIALOG,
  CLOSE_DIALOG,
  MAKE_DIALOG_CHOICE,
  ADD_RESPONSE_DIALOG
} from './actionTypes';

export const addDialog = (action) => ({
  type: ADD_DIALOG,
  ...action,
});

export const addResponseDialog = ({ response }) => ({
  type: ADD_RESPONSE_DIALOG,
  response,
});

export const makeDialogChoice = (action) => ({
  type: MAKE_DIALOG_CHOICE,
  ...action,
});

export const closeDialog = (action) => ({
  type: CLOSE_DIALOG,
  ...action,
});

export default {
  addDialog,
  closeDialog,
  makeDialogChoice,
};
