import React, {Fragment, useEffect} from 'react';
import { OTP_DISABLE_LOW_LEVEL_USERS_URL, OTP_DISABLE_HIGH_LEVEL_USERS_URL } from '../../../utils/constants';


import {
  compose, pure, withHandlers, withProps, withState
} from 'recompose';
import { connect } from 'react-redux';

import { translate } from 'react-i18next';
import { reduxForm } from 'redux-form';

import { withStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';

import OtpForm from './OtpForm';
import AccountListItemField from '../components/AccountListItemField';
import validate from '../../../utils/validate';
import SubmitButton from '../../../components/Forms/SubmitButton';
import { updateProfileRequested } from '../../../redux/actions';
import renderBranch from '../../../components/renderBranch';

import { generateOtpRequested } from '../../../redux/actions';
import { SUPPORT_MAIL } from '../../../config';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const styles = theme => ({
  '@media screen and (min-width: 600px)': {
    list: {
        display: 'flex',
        flexDirection: 'column',
        padding: 0,
        height:'100px',
    },
  },
  list: {
    flexDirection: 'row',
  },
  root: {
    flex: 1,
    paddingBottom: 100,
  },
  fieldItem: {
    padding: 0,
    ...theme.typography.body1,
    fontSize: theme.typography.pxToRem(13),
    color: theme.palette.text.secondary,
  },
});

const GeneralInfoFormAndOtpButton = props => {
  const {
    requestGenerateOtp,
    generateOtp,
    otp_active,
    t,
    submitting,
    pristine,
    invalid,
    handleSubmit,
    setOpenPhrase,
    setOpen2fa,
    open2fa,
    openPhrase,
    classes,
    actualTab
  } = props;

  // this hooks makes the components close when the user change tabs
  useEffect(() => {
    actualTab !== 'security' && setOpen2fa(false);
    actualTab !== 'security' && setOpenPhrase(false);
  })

  // this component actually renders the two lower buttons of security tab in profile page.
  // It represents the button and form that changes the security phrase of the user, but it calls
  // the components that renders the form of the 2fa key.
  return (
    <Fragment>
      <List className={classes.list}>
        <ListItem>
          <Button
          style={{width:'100%', minHeight: '80% !important', height: '80%'}}
          variant='raised'
          color='primary'
          onClick={()=>{
            if(!openPhrase){
              setOpenPhrase(true);
            }
          }}>
          {'definir frase de segurança '}&nbsp;<FontAwesomeIcon icon='pencil-alt' style={{marginBottom:'4px', marginLeft:'8px'}}/>
          </Button>
        </ListItem>
        <ListItem>
          <Button
          style={{width:'100%', minHeight: '80% !important', height: '80%'}}
          variant="contained"
          color="primary"
          onClick={()=>{
              generateOtp({requestGenerateOtp, otp_active})
              setOpen2fa(true);
          }}>
          {
            otp_active
              ? t('security.requestRegenerateOtp')
              : <div>Gerar chave 2fa&nbsp;<FontAwesomeIcon icon='key' style={{marginBottom:'1px', marginLeft:'8px'}}/></div>
          }
          </Button>
        </ListItem>
      </List>
      { openPhrase ?
        <form onSubmit={handleSubmit}>
          <AccountListItemField
            primary={t('forms.safetyPhrase.label')}
            i18nScope="forms.safetyPhrase"
            name="safety_phrase"
            canEdit
            tooltipTitle={t('info.securityPhrase')}
            listItemSecondaryAction={(
          <SubmitButton
            submitText={t('common.save')}
            disabled={submitting || (pristine || invalid)}
          />)}/>
        </form>:<div></div>}

      {open2fa ? <OtpForm/> : <div></div>}

    </Fragment>
  );
};

const schemas = {
  safety_phrase: {
    type: String,
    max: 30,
  },
};

const mapStateToProps = (state) => ({
  safety_phrase: state.user.profile.safety_phrase,
  otp_active: Number(state.user.profile.otp_active),
  qrCodeInfos: state.appData.service.generateOtp,
  kycLevel: state.user.profile.kyc_level,
});


const maspDispatchToProps = dispatch => ({
  requestGenerateOtp: () => dispatch(generateOtpRequested()),
  updateProfile: profile => dispatch(updateProfileRequested(profile)),
});

const enhance = compose(
  withStyles(styles),
  translate(),
  connect(mapStateToProps, maspDispatchToProps),
  renderBranch(['otp_active']),
  withState('openPhrase', 'setOpenPhrase', false),
  withState('open2fa', 'setOpen2fa', false),
  withHandlers({
    generateOtp: (props) => ({requestGenerateOtp, otp_active}) => {
      const kycLevel = Number(props.kycLevel);
      if(otp_active)
          window.open(kycLevel < 2 || isNaN(kycLevel) ? OTP_DISABLE_LOW_LEVEL_USERS_URL : OTP_DISABLE_HIGH_LEVEL_USERS_URL , '_blank');
       else
          requestGenerateOtp();
    },
    onSubmit: (props) => values => {
      const { updateProfile } = props;
      updateProfile(values);
    },
  }),
  withProps(({ safety_phrase }) => (
    { initialValues: { safety_phrase } })),
  reduxForm({
    form: 'credentials',
    validate: validate(schemas),
    enableReinitialize: true,
  }),
  pure,
);

export default enhance(GeneralInfoFormAndOtpButton);
