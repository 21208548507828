import React, { useEffect } from "react";
import * as R from "ramda";
import { connect } from "react-redux";
import { translate } from "react-i18next";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";

import AppCard from "../../components/Cards/AppCard";
import TradeOrderForm from "../Trade/TradeOrderForm/index";

import useOrderbook from "../../hooks/useOrderbook";
import { enableSyncOrders, disableSyncOrders } from "../../redux/actions";
import { getCurrencyBySymbol } from "../../models/Currency";
import ActivityIndicator from "../../components/ActivityIndicator";

const styles = (theme) => ({
  sellLabel: {
    color: theme.palette.primary.contrastText,
  },
  cardHeaderClass: {
    backgroundColor: theme.sell,
  },
  loaderWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    minHeight: 391.59,
  },
});

const Transaction = (props) => {
  const {
    t,
    market,
    startSyncOrders,
    stopSyncOrders,
    handleOrderBook,
    classes,
    location,
    markets,
    currencies,
  } = props;
  const scene = location.pathname.split("/")[1];

  const orderbook = useOrderbook(market, startSyncOrders, stopSyncOrders);
  const currency = getCurrencyBySymbol(currencies, market.split("-")[0]);
  const quoteCurrency = getCurrencyBySymbol(currencies, "BRL");
  const marketInfo = markets[market];
  const hasMarkets = !R.isEmpty(markets);

  useEffect(() => {
    if (R.isEmpty(orderbook.asks)) {
      return;
    }
    handleOrderBook(orderbook);
  }, [orderbook]);
  return (
    <AppCard
      cardClassName={classes.sellLabel}
      noHeader
      cardHeaderClass={classes.cardHeaderClass}
      cardLabel={t(`transactions.${scene}.action`)}
    >
      {hasMarkets ? (
        <TradeOrderForm
          key={scene}
          orderType={scene.toUpperCase()}
          quoteCurrency={quoteCurrency}
          currency={currency}
          marketInfo={marketInfo}
          markets={markets}
        />
      ) : (
        <div className={classes.loaderWrapper}>
          <ActivityIndicator />
        </div>
      )}
    </AppCard>
  );
};
const mapStateToProps = (state) => ({
  market: state.market.selectedMarket,
  currencies: state.currencies.currencies,
  markets: state.currencies.markets,
});

const mapDispatchToProps = (dispatch) => ({
  startSyncOrders: () => dispatch(enableSyncOrders()),
  stopSyncOrders: () => dispatch(disableSyncOrders()),
  handleOrderBook: (orderBook) =>
    dispatch({
      orderBook,
      type: "HANDLE_ORDERBOOK_FROM_SOCKETS",
    }),
});

export default compose(
  translate(),
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(Transaction);
