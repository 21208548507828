import * as actions from './actionTypes'

const initialState = {
  dismissedNews: [],
  news: []
}

const newsDialogReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.SET_NEWS_READ:
      return {
        ...state,
        dismissedNews: [...state.dismissedNews, action.newsId]
      }
    case actions.ADD_NEWS_DATA:
      const { news: { data:news } } = action;
      const newsHasKeys = Object.keys(news).length !== 0;

      if (news && newsHasKeys && !state.dismissedNews.includes(news[0].banner_id)){
        const newsState = state.news ? [...state.news, news[0]] : [news[0]];
        return {
          ...state,
          news: newsState
        }
      }
      return state;
    default:
      return state;
  }
}

export default newsDialogReducer
