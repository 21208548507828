import { merge } from 'ramda';
import * as actions from './actionTypes';

const initialState = {
  asks: [],
  bids: [],
};

const updateOrderBook = (state, orderBook) => {
  return merge(state, orderBook);
};

const orderBookReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.FETCH_ORDER_BOOK_SUCCEED:
      return updateOrderBook(state, action.orderBook);

    default:
      return state;
  }
};

export default orderBookReducer;
