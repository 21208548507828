import React from 'react';
import { Field } from 'redux-form';
import TextFieldComponent from './TextFieldComponent';

function onKeyPressed(e) {
	if(e.charCode === 42 || e.charCode === 64) {
		e.preventDefault();
	}
}


const TextField = (props) => {
  return (
    <Field {...props}
    	onKeyPress={onKeyPressed}
    	component={TextFieldComponent} />
  );
};

export default TextField;
