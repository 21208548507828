import React, { Fragment } from "react";
import SwipeableViews from 'react-swipeable-views'
import ArrowIos from '@material-ui/icons/ArrowBackIos';
import MobileStepper from '@material-ui/core/MobileStepper';
import { compose, withHandlers, withState } from "recompose";
import { withStyles } from '@material-ui/core/styles'
import { translate } from "react-i18next";
import { Hidden } from "@material-ui/core";


const styles = theme => ({
    stepperRoot: {
      width: '100%',
      justifyContent: 'center',
      position: 'fixed',
      flexGrow: 1,
      bottom: theme.spacing.unit * 7,
    },
    stepperDot: {
      width: 100,
      backgroundColor: theme.palette.primary.main,
      borderRadius: '20%',
    },
    stepperDotActive: {
      width: 100,
      backgroundColor: theme.palette.secondary.main,
      borderRadius: '20%',
    },
    iconArrow: {
      display: 'flex',
      alignContent: 'center',
      alignItems: 'center',
      backgroundColor: 'rgb(255, 255, 255)',
      width: '5%',
      height: '75%',
      margin: 0,
    },
    iconPositionRight: {
      display: 'flex',
      alignItems: 'center',
      position: 'absolute',
      top: '45%',
      right: '10px',
      marginTop: 'auto',
      marginBottom: 'auto',
      width: 35,
      height: 35,
      backgroundColor: '#bfbfbf',
      opacity: 0.65,
      boxShadow: '0px 5px 24px -6px rgba(0,0,0,0.69)',
      color: 'white',
      borderRadius: 100,
      margin: 0,
      marginLeft: -30,
      zIndex: 100,
    },
    iconPositionLeft: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'end',
      position: 'absolute',
      top: '45%',
      left: '10px',
      width: 35,
      height: 35,
      backgroundColor: '#bfbfbf',
      opacity: 0.65,
      boxShadow: '0px 5px 24px -6px rgba(0,0,0,0.69)',
      color: 'white',
      borderRadius: 100,
      zIndex: 100,
    },
     rotateIcon: {
      transform: 'rotate(180deg)',
     },
     executedOrders: {
      marginTop: '30px',
     },
     relative: {
      position: 'relative'
     }
  })

const SwipeableViewsMobile = (props) => {
    const { 
      children,
      setActiveTabIndex,
      setActiveTabIndexSwipeable,
      activeTabIndex,
      classes,
      steps,
      direction
    } = props;

    const axis = direction === 'ltr' ? 'x-reverse' : 'x'

    const slideStyles = {
     slideContainer: {
        height: '100%',
        WebkitOverflowScrolling: 'touch', // iOS momentum scrolling
     },
    };

    return (
      <Fragment>
        <Hidden mdUp>
          <div className={classes.relative}>
            <div
              onClick={e => {
                if (activeTabIndex > 0) {
                setActiveTabIndexSwipeable(setActiveTabIndex, activeTabIndex - 1);
                }
              }}
              className={classes.iconArrow}
            >
              {activeTabIndex !== 0 && (
                <div className={classes.iconPositionLeft}>
                  <ArrowIos className={classes.icon} />
                </div>
              )}
            </div>
            <SwipeableViews
              className={classes.slideContainer}
              containerStyle={slideStyles.slideContainer}
              axis={axis}
              index={activeTabIndex}
              onChangeIndex={val => setActiveTabIndexSwipeable(setActiveTabIndex, val)}
            >
              {children}
            </SwipeableViews>
            <div
              onClick={e => {
                if (activeTabIndex < (steps - 1)) {
                setActiveTabIndexSwipeable(setActiveTabIndex, activeTabIndex + 1);
                }
              }}
              className={classes.iconArrow}
            >
              {activeTabIndex !== (steps - 1 ) && (
                <div className={classes.iconPositionRight}>
                  <ArrowIos className={classes.rotateIcon} />
                </div>
              )}
            </div>
            <MobileStepper
              variant="dots"
              steps={steps}
              position="static"
              activeStep={activeTabIndex}
              className={classes.stepperRoot}
              classes={{
                dot: classes.stepperDot,
                dotActive: classes.stepperDotActive,
              }}
            />
          </div>
        </Hidden>
        <Hidden smDown>
          {children}
        </Hidden>
      </Fragment>
    )
}

const enhance = compose(
    withStyles(styles),
    translate(),
    withState("activeTabIndex", "setActiveTabIndex", (props) => props.initialTabIndex),
    withHandlers({
        setActiveTabIndexSwipeable: ( ) => (setActiveTabIndex, val) => {
        setActiveTabIndex(val);
        },
    }),
)

export default enhance(SwipeableViewsMobile);