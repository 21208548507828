import React from 'react';
import * as R from 'ramda';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core/styles';
import { translate } from 'react-i18next';
import ActivityIndicator from '../../components/ActivityIndicator';

import SumaryContent from '../../components/Summary/SummaryContent';
import SumaryLabel from '../../components/Summary/SummaryLabel';
import TrendingIcon from './TrendingIcon';
import BrasilNumberFormat from '../../utils/NumberFormat/BrasilNumberFormat';
import InfoTooltip from '../../components/InfoTooltip';


const styles = _theme => ({
  flex: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
  },
  flexIcon: {
    flex: 1,
    display: 'flex',
    height: '100%',
    alignItems: 'flex-start',
  },
  variation: {
    color: 'white',
    fontWeight: 500
  }
});

const BtcTicker = props => {
  const {
    variation,
    t,
    classes,
  } = props;

  if (R.isNil(variation)) {
    return <ActivityIndicator color="secondary" size={16} />;
  }

  return (
    <div >
      <div className={classes.flex}>
        <SumaryLabel sumaryTitle={t('market.variation')} />
        <InfoTooltip title={t('info.variation')} gutterBottom iconProps={{ style: { fontSize: 14 } }} placement="left-end" />
      </div>
      <div className={classes.flex}>
        <SumaryContent className={classes.flexIcon}>
          <TrendingIcon variation={variation} />
        </SumaryContent>
        <SumaryContent className={classes.variation}>
          <BrasilNumberFormat suffix="%" value={variation} />
        </SumaryContent>
      </div>
    </div>
  );
};

const enhance = compose(
  translate(),
  withStyles(styles),
);

export default enhance(BtcTicker);
