import {
  put, call, cancel, fork, take, select, takeLatest
} from 'redux-saga/effects';
import { delay } from 'redux-saga';
import moment from 'moment'

import {
  FETCH_EXTRACT,
  ENABLE_SYNC_EXTRACT,
  DISABLE_SYNC_EXTRACT,
  DOWNLOAD_EXTRACT_HISTORY_CSV
} from './actionTypes';
import { fetchExtractRequested, fetchService, downloadExtractHistoryCSV, addSnack } from '../../actions';
import { SHORT_SYNC_INTERVAL } from '../../../config';

function lastYear(){
  return new Date().getFullYear()-1;
}

function* fetchExtract() {
  const formRequest = yield select(state=>state.user.extract.form);
  yield put(fetchExtractRequested(formRequest));
}

function* enableSyncExtractSaga() {
  const form = yield select(state=>state.user.extract.form);
  while (true) {
    try {
      if (!document.hidden) {
        yield put(fetchExtractRequested(form));

        yield call(delay, SHORT_SYNC_INTERVAL);
      } else {
        yield call(delay, SHORT_SYNC_INTERVAL);
      }
    } catch (error) {
      console.error('shortPublicLazySync', error);
      yield call(delay, SHORT_SYNC_INTERVAL);
    }
  }
}

function* toggleSyncExtractSaga() {
  while (yield take(ENABLE_SYNC_EXTRACT)) {
    const bgSyncTask = yield fork(enableSyncExtractSaga);
    yield take(DISABLE_SYNC_EXTRACT);
    yield cancel(bgSyncTask);
  }
}

function* downloadExtractHistoryBiscoint() {
  try {
    yield put(fetchService({
      requestType: 'downloadExtractHistoryBiscoint',
      responseToUser: {
        onFail: 'snack',
      },
      paramns: {
        privateService: true,
        options: {
          cmd: 'biscoint_history',
          method: 'POST',
          isXLSX: true,
          filename: `biscoint_history.xlsx`
        },
      },
    }));
  } catch (e) {
    console.error('downloadExtractHistoryBiscoint', e);
  }
}

function* downloadExtractHistoryCSVSaga({ from, to, onlyBalance }) {
  const fromDate = moment(from).format('YYYY-MM-DD')
  const toDate = moment(to).format('YYYY-MM-DD')
  const lastYearNumber = lastYear()
  try {
    if (onlyBalance) {
      yield put(fetchService({
        requestType: 'downloadExtractHistoryCSV',
        responseToUser: {
          onFail: 'snack',
        },
        paramns: {
          privateService: true,
          form: {
            year: lastYearNumber,
          },
          options: {
            cmd: 'annual_income',
            method: 'POST',
            downloadInPDF: true,
            filename: `bity_irpf_${lastYearNumber}`
          },
        },
      }));
    } else {
      yield put(fetchService({
        requestType: 'downloadExtractHistoryCSV',
        paramns: {
          privateService: true,
          form: {
            from: fromDate,
            to: toDate,
            only_balance: onlyBalance
          },
          options: {
            cmd: 'csv_history',
            method: 'POST',
            isCSV: true,
            filename: `bitpreco_history_${toDate}`
          },
        },
      }));
    }
  } catch (e) {
    console.error('downloadExtractHistoryCSV', e);
  }
}

function * downloadCompleteIR ({onlyBalance}) {
  try {
    const from = moment().year(lastYear()).startOf('year')
    const to = moment().year(lastYear()).endOf('year')
    yield put(downloadExtractHistoryCSV({ from, to, onlyBalance }))
  } catch (e) {
    console.error('downloadCompleteIR', e)
  }
}

function * downloadSimpleIR ({onlyBalance}) {
  try {
    const from = moment(`${lastYear()}-12-31`)
    const to = moment(`${lastYear()}-12-31`)
    yield put(downloadExtractHistoryCSV({ from, to, onlyBalance }))
  } catch (e) {
    console.error('downloadSimpleIR', e)
  }
}

export default function* watchMessage() {
  yield takeLatest(FETCH_EXTRACT, fetchExtract)
  yield takeLatest(DOWNLOAD_EXTRACT_HISTORY_CSV, downloadExtractHistoryCSVSaga)
  yield takeLatest('DOWNLOAD_COMPLETE_IR', downloadCompleteIR)
  yield takeLatest('DOWNLOAD_SIMPLE_IR', downloadSimpleIR)
  yield takeLatest('DOWNLOAD_EXTRACT_HISTORY_BISCOINT', downloadExtractHistoryBiscoint)
  yield toggleSyncExtractSaga();
}
