import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  Slide,
  Dialog,
  DialogTitle,
  Hidden,
  DialogContent,
  Typography,
  withStyles,
  ButtonBase,
} from "@material-ui/core";
import { addCardDialog, closeCardDialog } from "../../redux/actions";
import React from "react";
import { translate } from "react-i18next";
import QRCode from "qrcode.react";
import classNames from "classnames";
import DangerouslySetInnerHTML from "../../components/DangerouslySetInnerHTML";
import StoreLinks from "../../components/Buttons/StoreLinks";
import MissionInfo from "../../pages/MGM/Cashback/components/MissionInfo";
import {
  AttachMoneyRounded,
  GroupAddOutlined,
  CloseOutlined,
  OpenInNew,
} from "@material-ui/icons";
import { conditionals } from "../../pages/MGM/conditionals";
import { FIREBASE_MGM_INFO } from "../../config";

function Transition(props) {
  const windowWidth = window.innerWidth;
  return (
    <Slide direction={`${windowWidth}` > 600 ? "left" : "up"} {...props} />
  );
}

const dialogContentComponents = {
  cardSell: ({ t, classes, title }) => (
    <>
      <div className={classes.sectionContainer}>
        <div>
          <h2 className={classes.dialogTitleContent}>
            {title ? t(title) : t("screens.cards.dialog.secondTitle")}
          </h2>
        </div>

        <Hidden xsDown>
          <DangerouslySetInnerHTML
            className={classNames(classes.infoDialog, classes.textMuted)}
            title={t("screens.cards.dialog.secondContent")}
          />
          <div className={classes.qrCode}>
            <QRCode size={120} value={"https://link.bity.com.br/app"} />
          </div>
        </Hidden>

        <Hidden smUp>
          <DangerouslySetInnerHTML
            className={classNames(classes.infoDialog, classes.textMuted)}
            title={t("screens.cards.dialog.secondContentMobile")}
          />
          <DangerouslySetInnerHTML
            className={classNames(classes.infoDialog, classes.textMuted)}
            title={t("screens.cards.dialog.redirect")}
          />
        </Hidden>
      </div>

      <div
        className={classNames(
          classes.flexColumn,
          classes.center,
          classes.textMuted,
          classes.gap45
        )}
      >
        <div>
          <Hidden xsDown>
            <p>{t("screens.cards.dialog.links")}</p>
          </Hidden>
          <StoreLinks></StoreLinks>
        </div>
        <a
          className={classNames(
            classes.textMuted,
            classes.cardLanding,
            classes.disclamer
          )}
          href="https://bity.com.br/cartao"
          target="_blank"
          rel="noopener noreferrer"
        >
          {t("screens.cards.dialog.redirect")}
          <OpenInNew />
        </a>
      </div>
    </>
  ),
  cashbackMissions: ({ t, classes, renderComponentProps, closeDialog }) => {
    const { userData, redirectToMgm } = renderComponentProps;
    const { missionsData, referralsData, cardLimits } = userData;
    const {
      getTotalPurchasesLastThirtyDays,
      getBuyMoreToEarnCashback,
      getTotalPurchasesLastNinetyDays,
      getMissingReferralsToEarnCashback,
    } = conditionals;
    let {
      nextMgmLevel,
      nextCardLevel,
      missingCard,
      missingMgm,
      hasFirstMonthBonus,
    } = missionsData;
    const hasCashbackBonusMarketingEvent =
      userData.cashbackInfo.cashback_composition.marketing_event !== null &&
      userData.cashbackInfo.cashback_composition.marketing_event !== 0;

    const cardVolumeTitle = hasFirstMonthBonus
      ? t("screens.mgm.cashback.dailyCashback.modalCardMissionFirstMonthBonus")
      : nextCardLevel === null
        ? t("screens.mgm.cashback.dailyCashback.modalCardMissionCompleteTitle")
        : t("screens.mgm.cashback.dailyCashback.cardCta", {
          value: missingCard,
        });

    const mgmTitle =
      nextMgmLevel === null
        ? t("screens.mgm.cashback.dailyCashback.modalReferMissionCompleteTitle")
        : missingMgm > 1
          ? t("screens.mgm.cashback.dailyCashback.referCtaPlural", {
            count: missingMgm,
          })
          : t("screens.mgm.cashback.dailyCashback.referCtaSingular");

    const mgmCollapseExtra = () => {
      return (
        <ButtonBase
          onClick={() => {
            closeDialog();
            redirectToMgm();
          }}
          className={classes.bitybankButton}
        >
          {t("screens.mgm.cashback.dailyCashback.referNow")}
        </ButtonBase>
      );
    };

    return (
      <>
        <div
          className={classNames(
            classes.sectionContainer,
            classes.maxWidthMissionsContent
          )}
        >
          <div>
            <Typography
              component={"h2"}
              align={"left"}
              className={classes.titleBig}
            >
              {t("screens.mgm.cashback.dailyCashback.missions")}
            </Typography>
          </div>

          <DangerouslySetInnerHTML
            className={classNames(classes.textContent, classes.textMuted)}
            title={t(
              "screens.mgm.cashback.dailyCashback.missionsDialogContent"
            )}
          />

          {!hasCashbackBonusMarketingEvent && (
            <MissionInfo
              cardIcon={AttachMoneyRounded}
              nextLevelValue={nextCardLevel}
              headerTitle={cardVolumeTitle}
              headerValueLabel={
                nextMgmLevel
                  ? t("screens.mgm.cashback.dailyCashback.ofCashback")
                  : null
              }
              collapseFirstCardContent={getTotalPurchasesLastThirtyDays(
                cardLimits,
                t
              )}
              collapseSecondCardContent={getBuyMoreToEarnCashback(
                hasFirstMonthBonus,
                missionsData,
                t
              )}
            />
          )}
          <MissionInfo
            cardIcon={GroupAddOutlined}
            nextLevelValue={nextMgmLevel}
            headerTitle={mgmTitle}
            headerValueLabel={
              nextMgmLevel
                ? t(
                  "screens.mgm.cashback.dailyCashback.ofCashbackAndMoreInYourAccount",
                  { value: FIREBASE_MGM_INFO.winValuePerIndication }
                )
                : null
            }
            collapseFirstCardContent={getTotalPurchasesLastNinetyDays(
              referralsData,
              t
            )}
            collapseSecondCardContent={getMissingReferralsToEarnCashback(
              missionsData,
              FIREBASE_MGM_INFO.winValuePerIndication,
              t
            )}
            collapseExtraContent={mgmCollapseExtra}
          />
        </div>

        <div
          className={classNames(
            classes.flexColumn,
            classes.center,
            classes.textMuted,
            classes.gap10,
            classes.maxWidthMissionsContent
          )}
        >
          <p>{t("screens.mgm.cashback.totalCashback.knowMoreThroughTheApp")}</p>

          <StoreLinks width={"100%"} flexDirection={"column"}></StoreLinks>
        </div>
      </>
    );
  },
};

const styles = (theme) => ({
  dialogContainer: {
    backdropFilter: "blur(1.5px)",
    display: "flex",
    justifyContent: "right",
    fontFamily: "'Helvetica','Arial', 'sans-serif'",
    [theme.breakpoints.down("xs")]: {
      justifyContent: "center",
    },
  },
  dialogPaper: {
    margin: "auto 0 auto 0",
    height: "100%",
    maxHeight: "100%",
    borderRadius: "55px 0 0 55px",
    width: "32%",
    minWidth: "420px",
    display: "flex",
    justifyContent: "center",
    backgroundColor: "rgb(247, 247, 247)",
    [theme.breakpoints.down("xs")]: {
      borderRadius: "25px 25px 0 0",
      height: "80%",
      maxHeight: "80%",
      minHeight: "450px",
      margin: "auto 0 0 0",
      width: "100%",
      minWidth: "320px",
    },
  },
  bgFullWhite: {
    backgroundColor: "white !important",
  },
  flexColumn: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  center: {
    justifyContent: "center",
    alignItems: "center",
  },
  dialogTitleContent: {
    lineHeight: "32px",
    margin: "auto",
    marginBottom: "13px",
    fontSize: "27px",
  },
  closeButton: {
    border: "none",
    backgroundColor: "transparent",
    display: "flex",
    flexDirection: "row",
    justifyContent: "end",
    width: "97%",
  },
  dialogContent: {
    maxWidth: "80%",
    margin: "auto",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      maxWidth: "90%",
    },
  },
  cardMissionsDialogContent: {
    width: "100%",
    margin: "auto",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      padding: "0",
    },
  },
  qrCode: {
    backgroundColor: "rgb(255, 255, 255)",
    borderRadius: "20px",
    padding: "21px",
    width: "160px",
    margin: "auto",
  },
  sectionContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "15px",
  },
  maxWidthMissionsContent: {
    width: "100%",
    maxWidth: "80.562659846547%",
  },
  storeButtons: {
    borderRadius: "40px",
    marginBottom: "10px",
    padding: "3% 0",
    [theme.breakpoints.down("xs")]: {
      padding: "0",
    },
  },
  contentButton: {
    display: "flex",
    alignContent: "center",
    justifyContent: "center",
    gap: "5px",
    minHeight: "25px",
  },
  closeIcon: {
    "&:hover": {
      cursor: "pointer",
      opacity: "0.7",
    },
  },
  infoDialog: {
    width: "80%",
    margin: "auto",
    lineHeight: "23px",
    [theme.breakpoints.down("xs")]: {
      lineHeight: "20px",
      width: "80%",
    },
  },
  titleBig: {
    color: "#070707",
    fontSize: "36px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "normal",
  },
  textContent: {
    textAlign: "left",
    color: "#696F74",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "24px",
  },
  textMuted: {
    color: "#777777",
  },
  disclamer: {
    fontSize: "15px",
    "& svg": {
      fontSize: "19px",
    },
  },
  dialogTitle: {
    [theme.breakpoints.down("xs")]: {
      padding: "19px 24px 18px",
    },
  },
  gap45: {
    gap: "45px",
  },
  gap10: {
    gap: "10px",
  },
  fullWidth: {
    width: "100%",
  },
  cardLanding: {
    display: "flex",
    alignItems: "center",
    gap: "5px",
  },
  bitybankButton: {
    color: "#101010",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "normal",
    padding: "12px 0",
    borderRadius: "8px",
    background: " #F7B733",
  },
});

const AsideDialog = (props) => {
  const {
    closeDialog,
    t,
    classes,
    dialog: { title, renderComponent, open, renderComponentProps },
  } = props;
  const ContentComponent = renderComponent
    ? dialogContentComponents[renderComponent]
    : null;
  const modalClass =
    renderComponent === "cashbackMissions"
      ? classNames(classes.dialogPaper, classes.bgFullWhite)
      : classNames(classes.dialogPaper);
  const modalContentClass =
    renderComponent === "cashbackMissions"
      ? classNames(classes.cardMissionsDialogContent)
      : classNames(classes.dialogContent);
  return (
    <Dialog
      open={open}
      color={"primary"}
      TransitionComponent={Transition}
      disableEscapeKeyDown={true}
      disableBackdropClick={true}
      className={classes.dialogContainer}
      //   maxWidth={'xs'}
      classes={{ paper: modalClass }}
    >
      <DialogTitle
        align={"center"}
        id="alert-dialog-slide-title"
        className={classNames(classes.flexColumn, classes.dialogTitle)}
      >
        <button className={classes.closeButton}>
          <CloseOutlined
            className={classes.closeIcon}
            onClick={() => closeDialog()}
          ></CloseOutlined>
        </button>
      </DialogTitle>
      <DialogContent classes={{ root: modalContentClass }}>
        {renderComponent && (
          <ContentComponent
            {...{ t, classes, renderComponentProps, closeDialog, title }}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

const mapStateToProps = (state) => {
  return {
    dialog: state.credentials.cardDialog,
  };
};

const mapDispatchToProps = (dispatch) => ({
  closeDialog: () => dispatch(closeCardDialog()),
  openDialog: () => dispatch(addCardDialog()),
});

export default compose(
  translate(),
  withRouter,
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(AsideDialog);
