import React from 'react';
import Typography from '@material-ui/core/Typography';
import FieldRow from './Forms/FieldRow'
import { translate } from 'react-i18next';
import { compose } from 'recompose';
import BrasilNumberFormat from '../utils/NumberFormat/BrasilNumberFormat';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  availableText:{
    color: 'green',
    [theme.breakpoints.down('sm')]: {
      fontSize: 15,
    },
  },
  blockText:{
    color: 'gray',
    [theme.breakpoints.down('sm')]: {
      fontSize: 15,
    },
  },
  totalText: {
    [theme.breakpoints.down('sm')]: {
      fontSize: 15
    },
  }
});

const getDecimalScale = currency =>{
  switch (currency){
    case 'BRL':
      return 2
    case 'USDT':
      return 2
    default: return 4;
  }
}

const LimitsIndicator = props => {
  const {
    limits: {total, used, total_annual, used_annual, available_used, available_annual_used },
    currency,
    t,
    type,
    classes,
    showLimitIndicator

  } = props;
  return (
    <div
      style={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
      }}
    >
      <Typography variant="caption">{t(`common.available_${type || 'withdrawal'}`)}</Typography>

      <Typography variant="headline">
        <BrasilNumberFormat
          value={available_used}
          decimalScale={getDecimalScale(currency)}
          renderText={val => (
            <span className={available_used <= 0 ? classes.blockText : classes.availableText}>
              {`${currency} ${val} `}
            </span>
          )}
        />
        <BrasilNumberFormat
          className={classes.totalText}
          prefix={`${t('common.of')} ${currency} `}
          value={total}
          decimalScale={getDecimalScale(currency)}
        />
      <br></br>
      { currency == 'BRL'&& showLimitIndicator && <Typography variant="headline">
      <Typography variant="caption">{t(`common.annual_limits`)}</Typography>
      <BrasilNumberFormat
          value={available_annual_used}
          decimalScale={getDecimalScale(currency)}
          renderText={val => (
            <span className={available_annual_used <= 0 ? classes.blockText : classes.availableText}>
              {`${currency} ${val} `}
            </span>
          )}
        />

        <BrasilNumberFormat
          className={classes.totalText}
          prefix={`${t('common.of')} ${currency} `}
          value={total_annual}
          decimalScale={getDecimalScale(currency)}
        /> </Typography>}

      </Typography>
    </div>
  );
};

LimitsIndicator.propTypes = {
  currency: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  limits: PropTypes.shape({
    total : PropTypes.number.isRequired,
    used: PropTypes.number.isRequired,
    total_annual : PropTypes.number.isRequired,
    used_annual: PropTypes.number.isRequired,
  }),
};

const enhance = compose(
  withStyles(styles),
  translate());

export default enhance(LimitsIndicator);
