import React, { Fragment } from 'react';
import { Typography } from '@material-ui/core';
import { compose } from 'recompose';
import { translate } from 'react-i18next';

import BrasilNumberFormat from '../utils/NumberFormat/BrasilNumberFormat';
import CurrencyFormat from './Currency/CurrencyFormat';
import { Currency } from '../models/Currency';

const rowStyle = {
	display: 'flex',
	flex: 1,
	flexDirection: 'row',
	justifyContent: 'space-between',
	borderBottom: '1px solid #F1F1F1',
	paddingTop: 5,
	alignItems: 'center',
	paddingBottom: 5
};
const FeeRow = (props) => {
	const { label, value, percentual, currency } = props;
	const currencyConfig = new Currency(currency);
	const isCripto = !currencyConfig.isFiat;

	if (!value && (isCripto || currencyConfig.symbol !=='BRL')) {
		return null;
	}
	return (
		<div style={rowStyle}>
			<Typography variant="body2" color="textPrimary">
				{label}
			</Typography>
			{percentual > 0 && (
				<Typography variant="caption" color="textSecondary">
					<BrasilNumberFormat value={percentual} suffix=" %" />
				</Typography>
			)}
			<Typography variant="body2" color="textSecondary">
				<CurrencyFormat value={value} isFiat={currencyConfig.isFiat} formatter={currencyConfig._defaultFormatter()} useMainScale />
			</Typography>
		</div>
	);
};

const FeesIndicator = (props) => {
  const { fees,
	 fees: { percent, fixed },
	 balance,
	 amount,
	 currency,
	 type,
	 t,
	 operationType = undefined,
	 isCripto = false, 
	 percentButtonsUsed /*Indicates the use of the percentage buttons(BRL)*/
	 } = props;
	 const currencyConfig = new Currency(currency);

	let totalFees = 0;
	let percentualFee = 0;
	if(percentButtonsUsed > 0 ){
		const balanceWithoutFixed = balance[currencyConfig.symbol] * (percentButtonsUsed / 100) - fixed;
		percentualFee = percent / 100 * balanceWithoutFixed;
		totalFees = percentualFee + fixed;
	}else{
		percentualFee = percent / 100 * amount;
		totalFees = percentualFee + fixed;
	}
	const labelFeesFixed = t(`transactions.${type}.fees.${operationType !== undefined ? operationType : 'fixed'}`)
	// const balanceWithoutFixed = balance[currency] - fixed;
	// const finalFee = percent / 100 * balanceWithoutFixed;
	// const percentualFee = percent / 100 * (Number(amount) + fees.fixed + finalFee * percentButtonsUsed/100 );

	const feesData = [
		{
			label: t(`transactions.${type}.amount`),
			value: Number(amount)
		},
		{
			label: labelFeesFixed,
			value: fees.fixed
		},
		{
			label: t(`transactions.${type}.fees.percent`),
			percentual: fees.percent,
			value: percentualFee
		},
		{
			label: t(`transactions.${type}.amountWithFees`),
			value: Number(amount) + totalFees
		}
	];

	return (
		<Fragment>
			<div style={{ display: 'flex', flexDirection: 'column' }}>
				{feesData.map((item) => <FeeRow isCripto={isCripto} key={item.label} currency={currency} {...item} />)}
			</div>
		</Fragment>
	);
};
const enhance = compose(translate());

export default enhance(FeesIndicator);
