import React from 'react';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';

const styles = theme => ({
  card: {
    // maxWidth: 400,
    width: '100%',
    paddingBottom: 16
  },
  cardContainer: {
    maxWidth: 600,
    margin: 'auto',
    marginTop: theme.spacing.unit * 2,
    marginBottom: 80,
  },
});

const TransferCard = props => {
  const {
    classes,
    children,
  } = props;

  return (
    <div className={classes.cardContainer}>
      <Card className={classes.card}>
        {children}
      </Card>
    </div>
  );
};

const enhance = compose(withStyles(styles));

export default enhance(TransferCard);
