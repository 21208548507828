/** @file  Credentials Actions */
import * as actions from './actionTypes';

/** OTP Validation */
const addOtpDialog = ({ type, ...action }) => ({
  type: actions.ADD_OTP_DIALOG,
  ...action,
});

const addOtpAppDialog = (action) => ({
  type: actions.ADD_OTP_APP_DIALOG,
  ...action
});

const closeOtpAppDialog = () => ({
  type: actions.CLOSE_OTP_APP_DIALOG,
});


/**  Resend register email validation */
const addResendEmailDialog = (action) => ({
  type: actions.ADD_RESEND_EMAIL_DIALOG,
  ...action,
});

/**  Resend register email validation */
const addCardDialog = (action) => ({
  type: actions.ADD_CARD_DIALOG,
  ...action,
});

/**  Resend register email validation */
const closeCardDialog = () => ({
  type: actions.CLOSE_CARD_DIALOG
});

/**  Resend register email validation */
const closeResendEmailDialog = () => ({
  type: actions.CLOSE_RESEND_EMAIL_DIALOG
});

const addCelcoinRegisterDialog = () => ({
  type: actions.ADD_CELCOIN_REGISTER_DIALOG,
});

const closeCelcoinRegisterDialog = () => ({
  type: actions.REMOVE_CELCOIN_REGISTER_DIALOG
});


const addRenewConnectionDialog = (action) => ({
  type: actions.ADD_RENEW_CONNECTION_DIALOG,
  ...action,
});


const closeRenewConnectionDialog = () => ({
  type: actions.CLOSE_RENEW_CONNECTION_DIALOG
});

/**  Two steps token validation */
const addValidationTokenDialog = action => ({
  type: actions.ADD_VALIDATION_TOKEN_DIALOG,
  ...action,
});

/**  Two steps token validation */
const closeValidationTokenDialog = () => ({
  type: actions.CLOSE_VALIDATION_TOKEN_DIALOG
});

/**  Sms token validation */
const addSmsTokenDialog = action => ({
  type: actions.ADD_SMS_TOKEN_DIALOG,
  ...action,
});

/**  Sms token validation */
const closeSmsTokenDialog = () => ({
  type: actions.CLOSE_SMS_TOKEN_DIALOG
});

const sendSmsPhoneValidation = form => ({
  type: actions.SEND_SMS_PHONE_VALIDATION,
  form
});

const validatePhoneSmsToken = form => ({
  type: actions.VALIDATE_PHONE_SMS_TOKEN,
  form
});

/** Captcha */
const verifyCaptcha = (captcha) => ({
  type: actions.VERIFY_CAPTCHA,
  captcha,
});


const updateCaptcha = (captcha) => ({
  type: actions.UPDATE_CAPTCHA,
  captcha,
});

// Session Credentials
const login = form => ({
  type: actions.LOGIN,
  form,
});

const resetOtpRequested = form => ({
  type: actions.RESET_OTP_REQUESTED,
  form,
});

const resetOtpRequestedSuccess = () => ({
  type: actions.RESET_OTP_REQUESTED_SUCCESS,
});

const updateCredentials = credentials => ({
  type: actions.UPDATE_CREDENTIALS,
  credentials,
});

const updateToken = response => ({
  type: actions.UPDATE_TOKEN,
  response,
});

const updateIntercomHash = () => ({
  type: actions.UPDATE_INTERCOM_HASH,
});

const removeIntercomHash = () => ({
  type: actions.REMOVE_INTERCOM_HASH,
})

const removeCredentials = () => ({
  type: actions.REMOVE_CREDENTIALS,
});

const endSession = () => ({
  type: actions.END_SESSION,
});

// Account validation
const requestValidation = (token) => ({
  type: actions.REQUEST_VALIDATION,
  token,
});

const resolveValidation = action => ({
  type: actions.RESOLVE_VALIDATION,
  ...action,
});

// Account Register
const requestRegister = (form) => ({
  type: actions.REQUEST_REGISTER,
  form,
});

const resolveRegister = ({ success, resolved }) => ({
  type: actions.RESOLVE_REGISTER,
  success,
  resolved,
});

const resendEmailValidation = form => ({
  type: actions.RESEND_VALIDATION,
  form
});

const addSessionExpiredDialog = () => ({
  type: actions.ADD_SESSION_EXPIRED_DIALOG,
});

const generateDeviceId = () => ({
  type: actions.GENERATE_DEVICE_ID,
});

const setUserLoggedInWithSavedToken = userLoggedInWithSavedToken => ({
  type: actions.SET_USER_LOGGED_IN_WITH_SAVED_TOKEN,
  userLoggedInWithSavedToken
});

const changeUserDeclarationDialog = (action) => ({
  type: actions.CHANGE_USER_DECLARATION_DIALOG,
  ...action
});

export {
  verifyCaptcha,
  updateCaptcha,

  addOtpDialog,
  addOtpAppDialog,
  closeOtpAppDialog,
  addResendEmailDialog,
  closeResendEmailDialog,
  resendEmailValidation,

  addCardDialog,
  closeCardDialog,

  login,
  updateCredentials,
  removeCredentials,
  endSession,
  addCelcoinRegisterDialog,
  closeCelcoinRegisterDialog,
  requestValidation,
  resolveValidation,

  requestRegister,
  resolveRegister,
  updateToken,
  addValidationTokenDialog,
  closeValidationTokenDialog,

  addSmsTokenDialog,
  closeSmsTokenDialog,
  sendSmsPhoneValidation,

  addRenewConnectionDialog,
  closeRenewConnectionDialog,

  addSessionExpiredDialog,
  validatePhoneSmsToken,
  generateDeviceId,
  removeIntercomHash,
  updateIntercomHash,
  setUserLoggedInWithSavedToken,
  changeUserDeclarationDialog,
  resetOtpRequested,
  resetOtpRequestedSuccess

};

export default {
  updateIntercomHash,
  verifyCaptcha,
  updateCaptcha,
  addOtpAppDialog,
  closeOtpAppDialog,
  updateCredentials,
  removeCredentials,
  endSession,

  requestValidation,
  resolveValidation,

  addSmsTokenDialog,
  closeSmsTokenDialog,
  sendSmsPhoneValidation,

  requestRegister,
  resolveRegister,
  resendEmailValidation,
  updateToken,
  addValidationTokenDialog,
  closeValidationTokenDialog,
  addRenewConnectionDialog,
  closeRenewConnectionDialog,
  addSessionExpiredDialog,
  validatePhoneSmsToken,
  generateDeviceId,
  removeIntercomHash,
  setUserLoggedInWithSavedToken,
  changeUserDeclarationDialog,
  resetOtpRequested,
  resetOtpRequestedSuccess
};

