import { compose, lifecycle } from 'recompose';
import { connect } from 'react-redux';

import { translate } from 'react-i18next';
import { Link } from 'react-router-dom';
import BankTransfers from './BankTransfers';
import {
  fetchBankAccount,
  fetchLimitsRequested,
  fetchBankList,
  fetchFeesRequested,
  addDialog
} from '../../redux/actions';

const mapDispatchToProps = dispatch => ({
  fetchBank: () => dispatch(fetchBankAccount()),
  fetchBankList: () => dispatch(fetchBankList()),
  fetchLimits: () => dispatch(fetchLimitsRequested()),
  fetchFees: () => dispatch(fetchFeesRequested()),
  addMissingKYCDialog : () => dispatch(
    addDialog({
      title: 'transactions.withdrawal.kycNeeded',
      disableBackdropClick:true,
      disableEscapeKeyDown:true,
      availableChoices: [
        {
          label: 'common.close',
          actionToTake:   '',
          component: Link,
          to: '/profile/operationalLimits',
          color: 'secondary',
          variant: 'raised',
          
        }
      ]
    })
  ),

});

const mapStateToProps = (state) =>{
  return {
    userProfile : state.user.profile,
    dialog : state.layout.dialog,
    bankAccount: state.user.bankAccount,
    bankList: state.exchange.bankList
  }
}
const enhance = compose(
  translate(),
  connect(mapStateToProps, mapDispatchToProps),

  lifecycle({
    componentDidMount() {
      const { userProfile } = this.props;
      this.props.fetchBank();
      this.props.fetchBankList();
      this.props.fetchLimits();
      this.props.fetchFees();
      if(Number(userProfile.kyc_level)===0 ){
        this.props.addMissingKYCDialog();
        return
      }
    },

  }),
);

export default enhance(BankTransfers);
