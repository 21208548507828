import React, { useEffect, useState } from "react";
import { Route, Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { pure, compose, lifecycle } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  setSelectedMarket,
  fetchMessageSucceed,
  fetchInitialPrivateData,
  updateCredentials,
  updateDataByType,
  setUserLoggedInWithSavedToken,
} from "../redux/actions";

import store from "../redux/store";
import ActivityIndicator from "../components/ActivityIndicator";

import WrappedRoute from "./WrappedRoute";

import { forbiddenPagesDemoAccount, whiteListPrivateRoutesWithDemoAccount } from "./config";
import Trade from "../pages/Trade";
import useNotifications from "../hooks/useNotifications";
import { getChartConfig } from "../components/TVChartContainer/utils";
import { isObjectNotEmpty } from "../utils/objectFunctions";
import { serviceUpdateTypes } from "../utils/constants";

const blockComponent = (component) => {
  return forbiddenPagesDemoAccount.includes(component);
};

const isDemoAccountAllowed = component => {
  return whiteListPrivateRoutesWithDemoAccount.includes(component);
}

const PrivateRoute = (props) => {
  const {
    component: Component,
    logged,
    computedMatch,
    path,
    location,
    demoAccount,
    token,
    handleNotification,
    loginWithURLToken,
    enableSyncNotifications,
    disableSyncNotifications,
    setNotifications,
    addNotification,
    updateAlert,
    loginWithDemoAccount,
    computedMatch: { params }, history, markets,
    ...rest
  } = props;
  const isDemoAccountAllowedComponent = isDemoAccountAllowed(Component);

  const [isStarted, setIsStarted] = useState(false);

  useNotifications(
    token,
    handleNotification,
    enableSyncNotifications,
    disableSyncNotifications,
    setNotifications,
    addNotification,
    updateAlert
  );

  useEffect(() => {
    const hasMarketsLoaded = isObjectNotEmpty(markets);
    if (Component === Trade && params.marketConfig) {
      if (hasMarketsLoaded) {
        const marketsList = Object.values(markets);
        const useMarket = marketsList.find((tokenOptions) => {
          const chartConfig = getChartConfig(
            tokenOptions.base,
            tokenOptions.quote
          );
          const isMarketAvailable =
            params.marketConfig.includes(chartConfig.symbol) ||
            params.marketConfig.includes(chartConfig.market);
          return isMarketAvailable;
        });
        if (useMarket) {
          store.dispatch(
            setSelectedMarket({
              market: `${useMarket.base}-${useMarket.quote}`,
              isInitialLoad: true,
            })
          );
        }
      }
    }
    if (!logged && isDemoAccountAllowedComponent) {
      loginWithDemoAccount();
    }
    if (!isStarted) {
      setIsStarted(true);
    }
  }, [markets]);

  return (
    <Route
      {...{ computedMatch, path, location }}
      render={(routeProps) => {
        const qs = new URLSearchParams(routeProps.location.search);
        const authToken = qs.get("loginAuthToken");
        qs.delete("loginAuthToken");
        if (authToken) {
          loginWithURLToken(authToken);
        }

        const locationUpdated = routeProps;
        locationUpdated.search = qs.toString();
        if (logged) {
          if (demoAccount && blockComponent(Component)) {
            return (
              <div>
                <Redirect
                  to={{
                    pathname: "/express-trade/buy",
                    state: { from: locationUpdated.location },
                  }}
                />
              </div>
            );
          }
          return (
            <WrappedRoute
              component={Component}
              appWrapperProps={rest}
              componentProps={routeProps}
            />
          );
        }
        else if (isDemoAccountAllowedComponent && !isStarted) {
          return <ActivityIndicator center fullHeight />
        }
        return (
          <div>
            <ActivityIndicator />
            <Redirect
              to={{
                pathname: "/login",
                state: { from: routeProps.location },
              }}
            />
          </div>
        );
      }}
    />
  );
};

PrivateRoute.defaultProps = {
  computedMatch: {},
  location: {},
};

PrivateRoute.propTypes = {
  component: PropTypes.func.isRequired,
  logged: PropTypes.bool.isRequired,
  email: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  computedMatch: PropTypes.shape({
    isExact: PropTypes.bool,
    params: PropTypes.object,
    path: PropTypes.string,
    url: PropTypes.string,
  }),
  location: PropTypes.shape({
    hash: PropTypes.string,
    key: PropTypes.string,
    pathname: PropTypes.string,
    search: PropTypes.string,
    state: PropTypes.object,
  }),
};

const mapStateToProps = ({
  credentials: {
    logged,
    loadingStore,
    demoAccount,
    token,
    userLoggedInWithSavedToken,
  },
  currencies: { markets },
}) => ({
  logged,
  token,
  loadingStore,
  demoAccount,
  markets,
  userLoggedInWithSavedToken,
});

const mapDispatchToProps = (dispatch) => ({
  loadInitialPrivateData: () => dispatch(fetchInitialPrivateData()),
  loginWithURLToken: auth_token => dispatch({ type: 'LOGIN_URL_TOKEN', payload: { auth_token } }),
  loginWithDemoAccount: () => dispatch(updateCredentials({ demoAccount: true })),
  updateDataByServiceType: (type) => dispatch(updateDataByType(type)),
  setUserLoggedInWithSavedToken: (userLoggedInWithSavedToken) =>
    dispatch(setUserLoggedInWithSavedToken(userLoggedInWithSavedToken)),

  handleNotification: (payload) => dispatch(fetchMessageSucceed(payload)),
  loginWithURLToken: (auth_token) =>
    dispatch({ type: "LOGIN_URL_TOKEN", payload: { auth_token } }),

  enableSyncNotifications: () =>
    dispatch({ type: "ENABLE_SYNC_NOTIFICATIONS" }),
  disableSyncNotifications: () =>
    dispatch({ type: "DISABLE_SYNC_NOTIFICATIONS" }),
  setNotifications: (notifications) =>
    dispatch({
      type: "SET_NOTIFICATIONS",
      notifications,
    }),
  addNotification: (notification) =>
    dispatch({
      type: "ADD_NOTIFICATION",
      notification,
    }),
  updateAlert: (alert) => dispatch({ type: "UPDATE_ALERT", alert }),
});

const enhance = compose(
  pure,
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  lifecycle({
    componentDidMount() {

      const {
        logged,
        updateDataByServiceType,
        userLoggedInWithSavedToken,
        setUserLoggedInWithSavedToken,
      } = this.props;
      if (logged && userLoggedInWithSavedToken) {
        updateDataByServiceType({ updateTypes: [serviceUpdateTypes.INITIAL] });
      } 
      if(!userLoggedInWithSavedToken) {
        setUserLoggedInWithSavedToken(true);
      }
    },
  })
);

export default enhance(PrivateRoute);
