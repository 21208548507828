import React, { useState, useEffect } from 'react'
import { compose } from 'recompose'
import { translate } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import classNames from 'classnames'
import Button from '@material-ui/core/Button'
import Hidden from '@material-ui/core/Hidden'
import { withStyles } from '@material-ui/core/styles'
import AmountInput from './AmountInput'
import TotalValueInput from './TotalValueInput'
import PriceInput from './PriceInput'

import OperationsDisabledCard from '../../../components/Cards/OperationsDisabledCard'
import SubmitButton from './SubmitButton'
import FieldRow from '../../../components/Forms/FieldRow'
import HorizontalFieldRow from '../../../components/Forms/HorizontalFieldRow'
import { getPrice } from './marketPriceCalculator'
import { MarketInfo } from '../../../models/MarketInfo';
import { ORDER_TYPES } from './constants'
import { Currency } from '../../../models/Currency';

const styles = theme => ({
  sell: theme.sellButton,
  buy: theme.buyButton,
  stoploss: theme.stoplossButton,
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: '50%',
  },
  depositButton: {
    backgroundColor: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: theme.palette.secondary.dark
    },
    color: 'white',
    width: '50%',
  },
  mobileButton: {
    width: '100%',
    margin: 0,
  },
  container: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    flexDirection: 'column',
    maxWidth: '100%',
    marginTop: 3 * theme.spacing.unit,
    paddingLeft: 3 * theme.spacing.unit,
    paddingRight: 3 * theme.spacing.unit,

  },
  root: {
    display: 'flex',
    justifyContent: 'center',
  },
  disabledCardInfoWrapper: {
    width: '100%',
  },
  disabledCardInfo: {
    display: 'block',
    marginLeft: 'auto',
    width: '50%',
  },
})

const getButtonClass = (orderType, classes) => {
  switch (orderType) {
    case ORDER_TYPES.BUY:
      return classes.buy;
    case ORDER_TYPES.SELL:
      return classes.sell;
    case ORDER_TYPES.STOPLOSS:
      return classes.stoploss;
  }
}

const TradeOrderForm = props => {
  const {
    t,
    history,
    market,
    classes,
    submitTitle,
    setFieldValue,
    handleSubmit,
    handleBlur,
    values,
    validateForm,
    isValidating,
    dirty,
    shouldDisplayAmountPercentageSwitch,
    shouldDisplayTotalValuePercentageSwitch,

    setIsMarketPriceSelected,
    handleTotalValuePercentChange,
    handleAmountPercentChange,
    currency,
    handleTotalValueChange,
    handleAmountChange,
    handlePriceChange,
    demoAccount,
    marketInfo,
    quoteCurrency
  } = props

  const [prevMarket, setPrevMarket] = useState(market);
  const marketInfoData = marketInfo ? new MarketInfo(marketInfo) : null;
  const currentCurrency = new Currency(currency);
  const hasDepositAndWithdrawal = currentCurrency.hasDeposit && currentCurrency.hasWithdrawal;
  let baseCurrencyFormatter = new Currency(currency)._defaultFormatter();
  let quoteCurrencyFormatter = new Currency(quoteCurrency)._defaultFormatter();
  let totalCurrencyFormatter = Object.assign({}, quoteCurrencyFormatter);
  quoteCurrencyFormatter.decimalScale = marketInfoData
    ? marketInfoData.quotePrecision
    : 8;
  baseCurrencyFormatter.decimalScale = marketInfoData
    ? marketInfoData.basePrecision
    : 8;

  const [disabledMarket, setDisabledMarket] = useState();

  useEffect(() => {
    if (marketInfo && marketInfoData && marketInfoData.orderbookDisable) {
      setDisabledMarket(true);
    } else {
      setDisabledMarket(false);
    }
  }, [marketInfo]);

  useEffect(() => {
    const containsValues = values.amount && values.totalValue
    const shouldValidateInitialValues = !isValidating && !dirty && containsValues
    if (!shouldValidateInitialValues) return
    validateForm()
  }, [values]);

  useEffect(() => {
    const containsValues = values.amount !== undefined && values.totalValue !== undefined;
    if (prevMarket !== market && containsValues) {
      validateForm();
      setPrevMarket(market);
    }
  }, [values]);

  useEffect(() => {
    const acumulator = values.orderType === ORDER_TYPES.BUY
      ? 'AMOUNTBTC'
      : 'TOTALVALUE'
    const price = getPrice(acumulator)(props)
    if (values.isMarketPriceSelected) {
      setFieldValue('price', price, false)
    }
  }, [values.isMarketPriceSelected, props.sellOrdersSerialized, props.buyOrdersSerialized])

  return (
    <section className={classes.root}>
      <form onSubmit={handleSubmit} className={classes.container}>
        <Hidden mdUp>
          <FieldRow>
            <PriceInput
              orderType={values.orderType}
              disabled={demoAccount || disabledMarket}
              isMarketPriceSelected={values.isMarketPriceSelected}
              setIsMarketPriceSelected={setIsMarketPriceSelected}
              onChange={handlePriceChange}
              onBlur={handleBlur}
              value={values.price}
              formatter={quoteCurrencyFormatter}
            />
          </FieldRow>
          <FieldRow>
            <TotalValueInput
              formatter={totalCurrencyFormatter}
              disabled={demoAccount || disabledMarket}
              shouldDisplayPercentageSwitch={
                shouldDisplayTotalValuePercentageSwitch
              }
              selectedPercentage={values.totalValuePercentage}
              setPercentage={handleTotalValuePercentChange}
              onChange={handleTotalValueChange}
              onBlur={handleBlur}
              value={values.totalValue}
            />
          </FieldRow>
          <FieldRow>
            <AmountInput
              formatter={baseCurrencyFormatter}
              disabled={demoAccount || disabledMarket}
              shouldDisplayPercentageSwitch={
                shouldDisplayAmountPercentageSwitch
              }
              selectedPercentage={values.amountPercentage}
              setPercentage={handleAmountPercentChange}
              onChange={handleAmountChange}
              value={values.amount}
              i18nContext={{
                tokenName: currency.symbol,
                decimals: "0".repeat(baseCurrencyFormatter.decimalScale),
              }}
            />
          </FieldRow>
          <FieldRow>
            <SubmitButton
              blockUserSubmit={demoAccount}
              disabled={demoAccount || disabledMarket}
              className={classNames(getButtonClass(values.orderType, classes))}
              submitText={submitTitle}
              classes={classes}
              t={t}
            />
          </FieldRow>
          <FieldRow>
            <Button
              variant="outlined"
              onClick={() => {
                history.goBack();
              }}
            >
              {t("forms.cancel")}
            </Button>
          </FieldRow>
        </Hidden>

        <Hidden smDown>
          <HorizontalFieldRow>
            <AmountInput
              formatter={baseCurrencyFormatter}
              disabled={demoAccount || disabledMarket}
              shouldDisplayPercentageSwitch={
                shouldDisplayAmountPercentageSwitch
              }
              selectedPercentage={values.amountPercentage}
              setPercentage={handleAmountPercentChange}
              onChange={handleAmountChange}
              value={values.amount}
              i18nContext={{
                tokenName: currency.symbol,
                decimals: "0".repeat(baseCurrencyFormatter.decimalScale),
              }}
              className={classes.textField}
            />
            <TotalValueInput
              formatter={totalCurrencyFormatter}
              disabled={demoAccount || disabledMarket}
              shouldDisplayPercentageSwitch={
                shouldDisplayTotalValuePercentageSwitch
              }
              selectedPercentage={values.totalValuePercentage}
              setPercentage={handleTotalValuePercentChange}
              onChange={handleTotalValueChange}
              onBlur={handleBlur}
              value={values.totalValue}
              className={classes.textField}
            />
          </HorizontalFieldRow>

          <HorizontalFieldRow>
            <PriceInput
              formatter={quoteCurrencyFormatter}
              orderType={values.orderType}
              disabled={demoAccount || disabledMarket}
              isMarketPriceSelected={values.isMarketPriceSelected}
              setIsMarketPriceSelected={setIsMarketPriceSelected}
              onChange={handlePriceChange}
              onBlur={handleBlur}
              value={values.price}
              className={classes.textField}
            />

            <SubmitButton
              blockUserSubmit={demoAccount}
              disabled={demoAccount || disabledMarket}
              className={classNames(
                classes.textField,
                getButtonClass(values.orderType, classes)
              )}
              submitText={submitTitle}
              classes={classes}
              t={t}
            />
          </HorizontalFieldRow>

          <div className={classes.disabledCardInfoWrapper}>
            {!hasDepositAndWithdrawal && (
              <OperationsDisabledCard
                currencyName={currency.symbol}
                customClass={classNames(
                  classes.operationsDisabledCard,
                  classes.disabledCardInfo,
                )}
              />
            )}
          </div>
        </Hidden>
      </form>
    </section>
  );
}

const enhance = compose(
  translate(),
  withRouter,
  withStyles(styles)
)
export default enhance(TradeOrderForm)
