import React from 'react';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column'
  },
  notification:{
    borderRadius: '50%',
    color: 'white',
    width: '17px',
    height:'17px',
    marginTop: '-15px',
    marginLeft:'auto',
    backgroundColor: '#d32f2f'
  }
});

const LabelNumberNotification = ({ quantity,
                                   label,
                                   classes}) => {
  return (
    <div className={classes.container}>
      <div className={classes.notification}>
        {quantity}
      </div>
      {label}
    </div>
  );
};

export default withStyles(styles)(LabelNumberNotification);
