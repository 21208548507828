import React from 'react';
import PropTypes from 'prop-types';
import { pure, compose, lifecycle } from 'recompose';
import { connect } from 'react-redux';
import { removeCredentials } from '../redux/actions';


import PublicRoute from './PublicRoute';
import PrivateRoute from './PrivateRoute';

const AppRoute = (props) => {
  const {
    component: Component,
    logged,
    path,
    computedMatch,
    location,
  } = props;

  if (logged) {
    return <PrivateRoute {...{ computedMatch, path, location }} component={Component} />;
  }
  return (
    <PublicRoute {...{ computedMatch, path, location }} component={Component} />
  );
};

AppRoute.defaultProps = {
  computedMatch: {},
  location: {},
};

AppRoute.propTypes = {
  component: PropTypes.func.isRequired,
  logged: PropTypes.bool.isRequired,
  path: PropTypes.string.isRequired,
  computedMatch: PropTypes.shape({
    isExact: PropTypes.bool,
    params: PropTypes.object,
    path: PropTypes.string,
    url: PropTypes.string,
  }),
  location: PropTypes.shape({
    hash: PropTypes.string,
    key: PropTypes.string,
    pathname: PropTypes.string,
    search: PropTypes.string,
    state: PropTypes.object,
  }),
};

const mapStateToProps = ({ credentials: { logged } }) => ({
  logged,
});

const mapDispatchToProps = dispatch => ({
  removeUserCredentials: () => dispatch(removeCredentials()),
});

const enhance = compose(
  pure,
  connect(mapStateToProps, mapDispatchToProps),
  lifecycle({
    componentDidMount() {
      const { logged, forcePublic, removeUserCredentials } = this.props;
      if (logged && forcePublic) {
        removeUserCredentials();
      }
    },
  }),
);


export default enhance(AppRoute);
