import privateService from '../privateService';

const sell = async form => {
  const options = {
    cmd: 'sell',
    method: 'POST',
  };
  const content = await privateService(options, form);

  return content;
};


export default sell;
