import React from 'react';
import { connect } from 'react-redux';
import { compose, withHandlers } from 'recompose';
import { translate } from 'react-i18next';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { reduxForm, formValueSelector } from 'redux-form';
import sendGAEvent from '../../utils/sendGAEvent';

import {
    closeValidationTokenDialog
} from '../../redux/credentials/actions';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { contactSupport } from "../../utils/contactSupport";
import TextField from '../../components/Forms/TextField';
import { normalizePIN } from '../../utils/NumberFormat/normalizers';
import privateService from '../../services/privateService';
import SubmitButton from '../../components/Forms/SubmitButton';
import { fetchProfile } from '../../redux/user/profile/actions';
import { addDialog } from '../../redux/layout/dialog/actions';
import { withRouter } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import { fetchBankAccount } from '../../redux/user/bankAccount/actions';
import { fetchUserCards } from '../../redux/actions';

const styles = theme => ({
    containerWrap: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        margin: 5
    },
    validationLoading: {
        marginTop: 5,
        marginLeft: 5
    },
    submitInnerContent:{
        display: 'flex',
        alignItems: 'center'
    },
    fieldSize: {
        width: '60%'
    },
});

const isEmailTokenValid = emailToken => {
    return emailToken !== undefined && emailToken.length === 6;
}

const extractFormValues = (validationCmd, form, dialogOptions) => {
    const emailToken = form.emailToken;
    if (dialogOptions.payload){
        const payloadData = dialogOptions.payload;
        if (validationCmd === 'confirm_update'){
            const authToken = payloadData.authToken;
            return {update_token : emailToken, auth_token: authToken }
        }
        else if (validationCmd === 'validate_acc'){
            const email = payloadData.email;
            return { email, token: emailToken }
        }
        return { token: emailToken, ...payloadData }
    }
    return {}
}

const validateToken = async (form, dispatch, props) => {

    const { validationCmd } = props.dialogOptions;
    const formValues = extractFormValues(validationCmd, form, props.dialogOptions);
    const options = {
        cmd: validationCmd,
        method: 'POST',
    };

    const content = await privateService(options, {
        ...formValues,
    });

    const { success } = content;
    if (success) {
        return content;
    }
    throw content;
};

const mapDispatchToProps = dispatch => ({
    closeDialog: () => dispatch(closeValidationTokenDialog()),
    updateProfileSucceed: ()=> {
        dispatch(fetchBankAccount());
        dispatch(fetchProfile());
        dispatch(fetchUserCards());
    },
    addEmailValidationSuccessDialog: ({ message_cod }, history) => dispatch(addDialog({
        title: message_cod,
        centerTitle: true,
        availableChoices: [
            {
                label: 'screens.login.signIn',
                actionToTake: 'goToLogin',
                color: 'secondary',
                variant: 'raised'
            }
        ],
        actionsToTake: {
            goToLogin: () => history.push('/login'),
        },
    })),
    addSuccessDialog: ({ message_cod }) => dispatch(addDialog({
        title: message_cod,
        centerTitle: true,
        availableChoices: [
            {
                label: 'common.close',
                actionToTake: '',
                color: 'secondary',
                variant: 'raised'
            }
        ],
    })),
    addFailDialog: (err, d, { remaining }) => dispatch(addDialog({
        title: 'common.attention',
        centerTitle: true,
        renderComponent: 'onValidationTokenFail',
        disableBackdropClick: true,
        componentProps: {
            remaining
        },
        availableChoices: [
            {
                label: 'common.understood',
                actionToTake: '',
                color: 'secondary',
                variant: 'raised'
            }
        ]
    })),
    addFinishedAttemptsDialog: () => dispatch(addDialog({
        title: 'TOO_MANY_AUTHENTICATION_ERRORS',
        centerTitle: true,
        disableBackdropClick: true,
        availableChoices: [
            {
                label: 'CONTACT_SUPPORT',
                actionToTake: 'openIntercomChat',
                color: 'primary',
                variant: 'raised'
            },
            {
                label: 'common.understood',
                actionToTake: '',
                color: 'secondary',
                variant: 'raised'
            }
        ],
        actionsToTake: {
            openIntercomChat: () => contactSupport(),
        },
    }))
});

const selector = formValueSelector('validateToken');
const mapStateToProps = (state) => {
    return {
        dialogOptions: state.credentials.validationTokenDialog,
        emailToken: selector(state, 'emailToken')
    };
};

const AppValidationTokenDialog = props => {
    const {
        handleSubmit,
        classes,
        handleClose,
        t,
        dialogOptions: { open },
        emailToken,
        submitting
    } = props;
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
            disableBackdropClick={true}
            disableEscapeKeyDown={true}
        >
            <form onSubmit={handleSubmit}>

                <DialogTitle id="form-dialog-title">
                    {t('forms.validationTokenDialog.title')}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <div className={classes.containerWrap}>
                            {`${t('forms.validationTokenDialog.content')}`}
                        </div>
                    </DialogContentText>
                    <TextField className={classes.fieldSize} normalize={normalizePIN} name="emailToken" i18nScope="forms.validationEmailToken" />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        {t('common.cancel')}
                    </Button>
                    <SubmitButton
                      disabled={!isEmailTokenValid(emailToken) || submitting}
                      submitText={
                          <div className={classes.submitInnerContent}>
                              {t('forms.validationTokenDialog.validate')}
                              {submitting && <Typography className={classes.validationLoading}>
                                  <CircularProgress size={15}/>
                              </Typography>}
                          </div>
                      }
                      color="primary"
                    />
                </DialogActions>
            </form>
        </Dialog>
    );
};

AppValidationTokenDialog.defaultProps = {
    dialogOptions: {
        open: false,
        validationCmd: ''
    }
};

AppValidationTokenDialog.propTypes = {
    dialogOptions: PropTypes.shape({
        open: PropTypes.bool,
        validationCmd: PropTypes.string
    }).isRequired
};

const enhance = compose(
    translate(),
    withRouter,
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps),
    withHandlers({
        onSubmitSuccess: ({
             closeDialog, updateProfileSucceed, addSuccessDialog, addEmailValidationSuccessDialog, history
        }) => async ( response ) => {
            const { message_cod } = response;
            closeDialog();
            if (message_cod === 'EMAIL_VALIDATED'){
                addEmailValidationSuccessDialog(response, history);
                sendGAEvent('Cadastro', 'Email validado')
            }
            else{
                addSuccessDialog(response);
                updateProfileSucceed();
            }
        },
        onSubmitFail: (props) => (err, d, { message_cod, remaining }) => {
            const { addFailDialog, closeDialog, addFinishedAttemptsDialog } = props;
            const hasRemaining = remaining !== undefined;
            if (message_cod === 'AUTHENTICATION_ERROR' && hasRemaining) {
                addFailDialog(err, d, { remaining });
            }
            else if(message_cod === 'TOO_MANY_AUTHENTICATION_ERRORS'){
                addFinishedAttemptsDialog();
                closeDialog();
            }
        },
        handleClose: ({ closeDialog }) => () => {
            closeDialog();
        },
    }),
  reduxForm({
      form: 'validateToken',
      onSubmit: validateToken,
  }),
);

export default enhance(AppValidationTokenDialog);
