import React from 'react'

import Typography from '@material-ui/core/Typography'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'

const PageTabs = ({ tabs, selectedTab, onChange, classes }) => (
  <Tabs
    fullWidth
    centered
    value={selectedTab}
    onChange={onChange}
    indicatorColor={"transparent"}
    className={classes.pageTabs}
  >
    {tabs.map((tab) => (
      <Tab
        disabled={tab.disabled !== undefined ? tab.disabled : false}
        key={tab.value}
        value={tab.value}
        color="secondary"
        className={[
          selectedTab === tab.value
            ? classes.tabSelected
            : classes.tabNotSelected,
          classes.tabStyle,
        ]}
        label={
          <Typography
            variant="subheading"
            style={{ fontWeight: 500 }}
            className={
              selectedTab === tab.value
                ? classes.colorWhite
                : classes.tabNotSelectedText
            }
          >
            {tab.label}
          </Typography>
        }
      />
    ))}
  </Tabs>
);

export default PageTabs
