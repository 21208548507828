import { connect } from 'react-redux'
import { compose, setDisplayName } from 'recompose'

import transactionsActions from '../../../../redux/transactions/actions'
import {isMobile} from '../../../../utils/checkScreenHelpers'


const sortMyOrders = (orders, type) => {
  return orders.sort(function (firstOrder, secondOrder) {
    const firstOrderPrice = firstOrder.price;
    const secondOrderPrice = secondOrder.price;
    if (firstOrderPrice < secondOrderPrice)
      return type === 'BUY' ? 1 : -1;
    else if (firstOrderPrice > secondOrderPrice)
      return type === 'BUY' ? -1 : 1;
    return 0;
  });
}

const mapStateToProps = (state, { ordersType, isShowAllSelected }) => {
  const updatingApplicationData = state.market.updatingApplicationData
    const orders = ordersType === 'BUY'
    ? isShowAllSelected
    ? state.orders.buyOrdersSerialized
    : sortMyOrders(state.orders.buyOpenOrders, ordersType)
    : isShowAllSelected
    ? state.orders.sellOrdersSerialized
    : sortMyOrders(state.orders.sellOpenOrders, ordersType)
  const myActiveOrders = ordersType === 'BUY'
    ? state.orders.buyOpenOrders.length
    : state.orders.sellOpenOrders.length
  return { orders, myActiveOrders, updatingApplicationData }
}


const mapDispatchToProps = dispatch => ({
  copyRow: (row, typeOp) => {
    if(isMobile()) return
    dispatch(transactionsActions.selectOrder(typeOp, row))
    window.scrollTo(0, 100)
  },
  confirmCancelOrder: (orders) => dispatch(transactionsActions.initCancelOrder(orders)),
})


export default compose (
  connect(mapStateToProps, mapDispatchToProps),
  setDisplayName('withOrders')
)
