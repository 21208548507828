import { connect } from 'react-redux';
import { compose } from 'recompose';
import { translate } from 'react-i18next';

import Profile from './Profile';
import { removeCredentials } from '../../redux/actions';
import { confirmUpdateProfileRequested } from '../../redux/user/profile/actions';

const mapDispatchToProps = dispatch => ({
  removeCredentials: () => dispatch(removeCredentials()),
  confirmUpdateProfile: token => dispatch(confirmUpdateProfileRequested(token)),

});

const enhance = compose(
  translate(),
  connect(null, mapDispatchToProps)
);

export default enhance(Profile);
