import { put, select, takeLatest } from 'redux-saga/effects';

import {
  FAIL_CREATING_PIX_ACCOUNT,
  CREATE_PIX_ACCOUNT,
  FETCH_PIX_ACCOUNT,
  GET_PIX_ACCOUNT
  // START_BANK_ACCOUNT_CHANGES_VALIDATION_TOKEN
} from './actionTypes';
import { addCelcoinRegisterDialog, addDialog, closeDialog, fetchProfile, fetchService } from '../../actions';
import { FETCH_PROFILE } from '../profile/actionTypes';

function* fetchPixAccountSaga(_action) {
  try {
    yield put(fetchService({
      requestType: 'fetchPixAccount',
      paramns: {
        privateService: true,
        options: {
          cmd: 'pix_has_account',
          method: 'POST',
        },
      },
    }));
  } catch (e) {
    console.error('fetchPixAccountSaga', e);
  }
}

function* failCreatePixAccountSaga(_action) {
  try {
    yield put(
      closeDialog({
        title: 'common.processing',
        centerTitle: true,
        disableBackdropClick: true,
        availableChoices: [],
      }),
    );
    yield put(fetchProfile())
    yield put(addCelcoinRegisterDialog())
  } catch (e) {
    console.error('fetchPixAccountSaga', e);
  }
}


function* createPixAccountSaga({motherName}) {
  const param = motherName ? {mother_name: motherName} : ''
  yield put(
    addDialog({
      title: 'common.processing',
      centerTitle: true,
      disableBackdropClick: true,
      availableChoices: [],
    }),
  );
  try {
    yield put(fetchService({
      requestType: 'createPixAccount',
      successAction: FETCH_PIX_ACCOUNT,
      failAction: FAIL_CREATING_PIX_ACCOUNT,
      responseToUser: {
        onSuccess: 'dialog',
        onFail: 'snack',
      },
      paramns: {
        privateService: true,
        form: param,
        options: {
          cmd: 'pix_create_account',
          method: 'POST',
        },
      },
    }));
  } catch (e) {
    console.error('createPixAccountSaga', e);
  }
}

function* getPixAccountSaga() {
  try {
    yield put(fetchService({
      requestType: 'getPixAccount',
      paramns: {
        privateService: true,
        options: {
          cmd: 'pix_get_account',
          method: 'POST',
      },
    }}))
  } catch (e) {
    console.error('getPixAccountSaga', e);
  }
}

function* watchPixAccount() {
  yield takeLatest(CREATE_PIX_ACCOUNT, createPixAccountSaga);
  yield takeLatest(FETCH_PIX_ACCOUNT, fetchPixAccountSaga);
  yield takeLatest(FETCH_PROFILE, fetchProfile);
  yield takeLatest(FAIL_CREATING_PIX_ACCOUNT, failCreatePixAccountSaga);
  yield takeLatest(GET_PIX_ACCOUNT, getPixAccountSaga);
  
}

export default watchPixAccount;
