import React, { useEffect, useState } from "react";
import { Dialog, withStyles } from "@material-ui/core";
import { connect } from "react-redux";
import { compose, withHandlers } from "recompose";
import { translate } from "react-i18next";
import { declarationTypes } from './_constants';
import FinancialDeclarationBody from "./components/FinancialDeclarationBody/FinancialDeclarationBody";
import PersonalDeclarationBody from "./components/PersonalDeclarationBody";
import UserAgreementBody from "./UserAgreementBody";
import { styles } from "./styles";


const UserDeclarationDialog = (props) => {
    const { dialogOptions: {open, declarationType}, classes } = props;
    const [declaration, setDeclaration] = useState(declarationType);
    useEffect(() => {
        setDeclaration(declarationType);
    }
    ,[declarationType]);

  return (
    <Dialog open={open} fullWidth classes={
      {
        paper: classes.dialogPaper
      }
    }>
      {declaration &&
        {
          [declarationTypes.FINANCIAL]: <FinancialDeclarationBody classes={classes} />,
          [declarationTypes.PERSONAL]: <PersonalDeclarationBody classes={classes} />,
          [declarationTypes.AGREEMENT]: <UserAgreementBody classes={classes} />,
        }[declaration]}
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  dialogOptions: state.credentials.userDeclarationDialog,
});


const enhance = compose(
  connect(mapStateToProps, null),
  withStyles(styles),
  withHandlers({
  }),
  translate()
);

export default enhance(UserDeclarationDialog);
