import React from "react";
import { INCOME_OPTION_VALUES } from "../../../_constants";
import RadioGroupField from "../../../../../../components/Fields/RadioGroupField";

import {
    FormControlLabel,
    Radio,
    FormControl,
  } from "@material-ui/core";

const FinancialDeclarationFormStep1 = (props) => {
  const { t } = props;
  return (
    <FormControl component={"fieldset"}>
      <RadioGroupField name="monthly_incomes">
        {Object.values(INCOME_OPTION_VALUES).map((value, index) => (
          <FormControlLabel
            key={`income-${value}`}
            value={`${value}`}
            control={<Radio />}
            label={t(
              `forms.incomeAndWealthValidation.incomeDialog.option${index + 1}`
            )}
          />
        ))}
      </RadioGroupField>
    </FormControl>
  );
};

export default FinancialDeclarationFormStep1;
