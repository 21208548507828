const colors = {
  red: {
    main: '#d32f2f',
    light: '#ff6659',
    dark: '#9a0007',
  },
  green: {
    main: '#00796b',
    light: '#48a999',
    dark: '#004c40',
  },
  blue: {
    main: '#005fac',
    light: '#409ae3',
    dark: '#064273',
  },
  primaryBase: '#101010',
  secondaryBase: 'rgb(249,168,37)',
  secondarOpaque: (opacity = 0.5) => `rgba(249,168,37,${opacity})`,
  gray: {
    main: '##bdbdbd',
    light: '#e0e0e0',
  },
};

const light = {
  colors,
};

export default light;
