import { put, takeLatest } from 'redux-saga/effects';

import {
  FETCH_LIMITS_REQUESTED,
} from './actionTypes';
import { fetchService } from '../../actions';

function* fetchLimitsSaga(_action) {
  try {
    yield put(fetchService({
      requestType: 'fetchLimits',
      paramns: {
        privateService: true,
        options: {
          cmd: 'get_limits',
          method: 'POST',
        },
      },
    }));
  } catch (e) {
    console.error('fetchLimits', e);
  }
}


function* watchLimits() {
  yield takeLatest(FETCH_LIMITS_REQUESTED, fetchLimitsSaga);
}

export default watchLimits;
