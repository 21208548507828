import React, { useEffect, useState } from "react";
import { compose } from "recompose";
import { Typography, IconButton, Radio, withStyles, FormControlLabel, Grid } from "@material-ui/core";
import { LaunchOutlined } from "@material-ui/icons";
import FieldRow from "../../../components/Forms/FieldRow";
import SubmitButton from "../../../components/Forms/SubmitButton";
import CriptoAmountField from "../../../components/Forms/CriptoAmountField";
import TextField from "../../../components/Forms/TextField";
import FeesIndicator from "../../../components/FeesIndicator";
import LimitsIndicator from "../../../components/LimitsIndicator";
import BalanceAvailableAfterTransaction from "../../../components/BalanceAvailableAfterTransaction";
import RadioGroupField from "../../../components/Fields/RadioGroupField";
import Select from "../../../components/FormsV2/Select";
import SelectItemChip from "../../../components/SelectItemChip";
import Form from "../../../components/Forms/Form";
import { CRIPTO_WITHDRAWAL_FEE_URL } from "../../../utils/constants";

import { USDT } from "../../../config";
import PriorityFeeChip from "../../../components/PriorityFeeChip";
import InfoTooltip from "../../../components/InfoTooltip";
import { getCurrencyBySymbol, Currency } from "../../../models/Currency";
import MessageCard from "../../../components/Cards/MessageCard";
import { fixScientificNotation } from "../../../utils/NumberFormat/roundValues";
import classNames from "classnames";

import { FIREBASE_CURRENCIES_ISSUES } from '../../../config'
import getCurrencyIssuesFoundBySymbolAndScreen from '../../../utils/firebaseUtils'
import { CurrencyIssue } from '../../../models/CurrencyIssue'

const getAddressLabel = (
  isOnlyBlockchainBep20,
  isOnlyBlockchainErc20,
  isOnlyBlockchainSol,
  isOnlyBlockchainAda,
  isOnlyBlockchainLuna,
  isOnlyBlockchainLunc
) => {
  if (isOnlyBlockchainBep20) {
    return "forms.walletAddressBep20";
  } else if (isOnlyBlockchainErc20) {
    return "forms.walletAddressErc20";
  } else if (isOnlyBlockchainSol) {
    return "forms.walletAddressSolana";
  } else if (isOnlyBlockchainAda) {
    return "forms.walletAddressAda";
  } else if (isOnlyBlockchainLuna) {
    return "forms.walletAddressLuna";
  } else if (isOnlyBlockchainLunc) {
    return "forms.walletAddressLuna";
  }

  return "forms.walletAddress";
};

const styles = (theme) => ({
  card: {
    padding: 10,
  },
  icon: {
    fontSize: 25,
  },
  iconContainer: {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    }
  },
  content: {
    [theme.breakpoints.down("xs")]: {
      fontSize: 12,
      textAlign: "center",
    },
  },
  messageCardContentContainer: {
    [theme.breakpoints.down("xs")]: {
      justifyContent: "center",
    },
  },
  knowMoreLink: {
    width: "24px",
    height: "24px",
  },
  singlePriorityContainer: {
    width: "100% !important",
    maxWidth: "400px",
  },
  singlePriorityControlLabel: {
    margin: "0 0 10px",
  },
  radioGroup: {
    display: "flex",
    flexDirection: "row",
    marginTop: "10px",
    marginBottom: 32,
    marginRight: 10,
    marginLeft: 10,
    justifyContent: "center",
    flexWrap: "wrap",
  },
  radioItem: {
    width: "40%",
    marginLeft: "3%",
    marginRight: 0,
  },
  radioGroupBlockchain: {
    display: "flex",
    flexDirection: "row",
    marginBottom: 32,
    justifyContent: "center",
    flexWrap: "nowrap",
  },
  radioGroupPriority: {
    display: "flex",
    flexDirection: "row",
    marginBottom: 32,
    marginLeft: 50,
    justifyContent: "center",
    flexWrap: "nowrap",
  },
  radioItemPriority: {
    width: "80%",
    marginLeft: "3%",
    marginRight: 0,
  },
  priorityRadio: { width: "80%", height: "60%", margin: "3px" },
  priorityRadioSingle: { width: "100%", height: "60%", margin: "3px 0" },
  priorityFeeLabel: {
    display: "flex",
    alignItems: "center",
  },
  priorityFeeLabelItem: {
    alignSelf: "center",
    color: "rgba(0, 0, 0, 0.54)",
    margin: 3,
  },
  bep20Label: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
    },
    display: "-webkit-inline-box",
  },
  informationStyle: {
    marginBottom: 10,
    alignSelf: "center",
    color: "rgba(0, 0, 0, 0.54)",
  },
  unavailableFeatureStyle: {
    margin: 10,
    alignSelf: "center",
    color: "#212121",
    fontWeight: 500,
  },
  currencyIssueWithdrawal: {
    margin: "0.5rem auto 0.125rem",
  }
});

const SendCoinsForm = (props) => {
  const {
    handleSubmit,
    onSubmit,
    t,
    currency,
    invalid,
    pristine,
    submitting,
    balance,
    valuesFromState,
    fees,
    limits,
    classes,
    handleCurrencyChange,
    currencyList,
    freeNetworkTransaction,
    priorityFeesBrl,
    priorityFees,
    handleLowPriorityFeeSelected,
    isOnlyBlockchainSol,
    isOnlyBlockchainAda,
    isOnlyBlockchainLuna,
    isOnlyBlockchainLunc,
    isOnlyBlockchainBep20,
    isOnlyBlockchainErc20,
    blockchain,
    handleBlockchainChange,
    blockchainOptions,
    selectedMarket,
    currencies,
    hasSameFees,
    setAnchorEl,
    anchorEl,
  } = props;
  const [currenciesIssuesFound, setCurrenciesIssuesFound] = useState();

  const coinSelected = selectedMarket.split("-")[0];
  const hasPriorityFee = priorityFees && Object.keys(priorityFees).length > 0;
  const brlCurrencyData = getCurrencyBySymbol(currencies, "BRL");
  const selectedCurrency =
    getCurrencyBySymbol(currencies, coinSelected) ||
    getCurrencyBySymbol(currencies, "BTC");

  const currencyObj = new Currency(selectedCurrency);
  const withdrawalInfo = currencyObj.getWithdrawalInfoByNetwork(blockchain)

  const transfer = "transfer";
  const title = t(`transactions.${transfer}.action`);
  const { minWithdrawal } = limits;
  const coinsSelectorExtraData = {
    hasStableCoin: true,
    stableCoinAction: "withdrawal",
  }
  const minAmountWarning = t("transactions.sendCoins.minAmountToWithdraw", {
    blockchain: blockchain,
    minAmount: fixScientificNotation(minWithdrawal),
  })
  const { firebaseCurrenciesIssues } = FIREBASE_CURRENCIES_ISSUES;

  useEffect(() => {
    if (firebaseCurrenciesIssues) {
      const currenciesIssuesData = getCurrencyIssuesFoundBySymbolAndScreen(
        firebaseCurrenciesIssues, 
        coinSelected, 
        'WITHDRAWAL'
      );
      const currenciesIssues = 
        currenciesIssuesData && 
        currenciesIssuesData.map(CurrencyIssue.fromJson);
      setCurrenciesIssuesFound(currenciesIssues);
    }
  }, [firebaseCurrenciesIssues]);

  return (
    <React.Fragment>
      <Form {...{ handleSubmit, onSubmit }}>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Typography variant="body2">
            {t("transactions.chooseCurrency")}
          </Typography>
        </div>
        <Select
          value={selectedCurrency}
          customHandleClick={(e) =>
            anchorEl
              ? setAnchorEl(null)
              : setAnchorEl(e.currentTarget, true, coinsSelectorExtraData)
          }
          placeholder={selectedCurrency.symbol}
          getLabel={(option) => {
            return (
              <SelectItemChip
                itemName={option.symbol}
                currency={option}
                itemCode={option}
              />
            );
          }}
        />

        <React.Fragment>
          <div>
            <Typography
              style={{
                alignSelf: "center",
                marginTop: 10,
              }}
              variant="body2"
            >
              {t("transactions.networkBlockchain")}
            </Typography>
          </div>
          <Select
            value={blockchain}
            options={blockchainOptions}
            placeholder={t("forms.deposit.select_bank")}
            onChange={handleBlockchainChange}
            getLabel={(option) => {
              if (option.label) return option.label;
              const networkDetails = blockchainOptions.find(
                (networkObject) => networkObject.value === option
              );
              return networkDetails ? networkDetails.label : "";
            }}
          />
        </React.Fragment>

        {currenciesIssuesFound &&
          currenciesIssuesFound.map((currencyIssue, index) => (
            <Grid
              key={`currencyIssue-${index}`}
              container
              xs={12}
              justify="center"
              alignItems="center"
              className={classes.currencyIssueWithdrawal}
            >
              <Typography variant="body2">{currencyIssue.title}</Typography>
              <InfoTooltip
                wrapperRootHeader={true}
                title={currencyIssue.description.join(" ")}
              />
            </Grid>
          ))}

        <FieldRow>
          <TextField
            name="address"
            i18nScope={getAddressLabel(
              isOnlyBlockchainBep20,
              isOnlyBlockchainErc20,
              isOnlyBlockchainSol,
              isOnlyBlockchainAda,
              isOnlyBlockchainLuna,
              isOnlyBlockchainLunc
            )}
          />
        </FieldRow>

        {withdrawalInfo && withdrawalInfo.memoEnabled && (
          <FieldRow>
            <TextField name="memo" i18nScope="forms.memoAddress" />
          </FieldRow>
        )}

        <FieldRow>
          <CriptoAmountField
            {...props}
            classes={{}}
            name="amount"
            withFees
            fees={fees}
            currency={currency}
            canUseCriptoBalance
            useCriptoBalanceButtonTitle={t("transactions.useBalance")}
          />
        </FieldRow>
        {blockchain && blockchain !== "" && (
          <div className={classNames(classes.singlePriorityContainer, classes.singlePriorityControlLabel)}>
            <MessageCard
              type="info"
              content={minAmountWarning}
              classes={{
                card: classes.card,
                icon: classes.icon,
                content: classes.content,
                messageCardContentContainer: classes.messageCardContentContainer,
              }}
            />
          </div>
        )}

        {hasPriorityFee && !hasSameFees && (
          <FieldRow>
            <div className={classes.priorityFeeLabel}>
              <Typography
                className={classes.priorityFeeLabelItem}
                variant="body2"
              >
                <div style={{ display: "-webkit-inline-box" }}>
                  {t("transactions.priorityFee")}:
                  <InfoTooltip title={t("transactions.WarningFee")} />
                </div>
              </Typography>
              <IconButton
                href={CRIPTO_WITHDRAWAL_FEE_URL}
                classes={{ root: classes.knowMoreLink }}
                target={"_blank"}
              >
                <LaunchOutlined scale={"primary"} />
              </IconButton>
              {freeNetworkTransaction && (
                <Typography
                  variant="body2"
                  className={classes.priorityFeeLabelItem}
                >
                  {t("transactions.priorityLabels.freeTransaction")}
                </Typography>
              )}
            </div>
          </FieldRow>
        )}
        {hasPriorityFee && (
          <div>
            {!freeNetworkTransaction && (
              <RadioGroupField
                name="priority"
                className={classes.radioGroupPriority}
              >
                {!hasSameFees && (
                  <FormControlLabel
                    value="high"
                    control={
                      <Radio
                        icon={
                          <PriorityFeeChip
                            baseCurrencySymbol={selectedCurrency.symbol}
                            active={false}
                            priorityType="high"
                            priorityFee={priorityFees.high}
                            priorityFeeBrl={priorityFeesBrl.high}
                            currencyData={brlCurrencyData}
                          />
                        }
                        checkedIcon={
                          <PriorityFeeChip
                            baseCurrencySymbol={selectedCurrency.symbol}
                            active={true}
                            priorityType="high"
                            priorityFee={priorityFees.high}
                            priorityFeeBrl={priorityFeesBrl.high}
                            currencyData={brlCurrencyData}
                          />
                        }
                        className={classes.priorityRadio}
                      />
                    }
                    label={""}
                  />
                )}
                {!hasSameFees && (
                  <FormControlLabel
                    value="medium"
                    control={
                      <Radio
                        icon={
                          <PriorityFeeChip
                            baseCurrencySymbol={selectedCurrency.symbol}
                            active={false}
                            priorityType="medium"
                            priorityFee={priorityFees.medium}
                            priorityFeeBrl={priorityFeesBrl.medium}
                            currencyData={brlCurrencyData}
                          />
                        }
                        checkedIcon={
                          <PriorityFeeChip
                            baseCurrencySymbol={selectedCurrency.symbol}
                            active={true}
                            priorityType="medium"
                            priorityFee={priorityFees.medium}
                            priorityFeeBrl={priorityFeesBrl.medium}
                            currencyData={brlCurrencyData}
                          />
                        }
                        className={classes.priorityRadio}
                      />
                    }
                    label={""}
                  />
                )}
                {!hasSameFees && (
                  <FormControlLabel
                    value="low"
                    control={
                      <Radio
                        icon={
                          <PriorityFeeChip
                            baseCurrencySymbol={selectedCurrency.symbol}
                            active={false}
                            priorityType="low"
                            priorityFee={priorityFees.low}
                            priorityFeeBrl={priorityFeesBrl.low}
                            currencyData={brlCurrencyData}
                          />
                        }
                        checkedIcon={
                          <PriorityFeeChip
                            baseCurrencySymbol={selectedCurrency.symbol}
                            active={true}
                            priorityType="low"
                            priorityFee={priorityFees.low}
                            priorityFeeBrl={priorityFeesBrl.low}
                            currencyData={brlCurrencyData}
                          />
                        }
                        className={classes.priorityRadio}
                      />
                    }
                    onChange={handleLowPriorityFeeSelected}
                    label={""}
                  />
                )}
              </RadioGroupField>
            )}
          </div>
        )}

        <FieldRow>
          <SubmitButton
            submitText={title}
            color="secondary"
            disabled={submitting || invalid || pristine}
          />
        </FieldRow>
        {valuesFromState.amount >= minWithdrawal && (
          <FieldRow>
            <FeesIndicator
              balance={balance}
              amount={valuesFromState.amount}
              fees={fees}
              type="sendCoins"
              currency={selectedCurrency}
            />
          </FieldRow>
        )}
        <FieldRow>
          <BalanceAvailableAfterTransaction
            amount={valuesFromState.amount}
            balance={balance}
            fees={fees}
            currency={selectedCurrency}
            t={t}
          />
        </FieldRow>
        <FieldRow>
          <LimitsIndicator
            type="withdrawal"
            limits={limits}
            currency={currency}
          />
        </FieldRow>
        <FieldRow>
          <div>
            <Typography className={classes.informationStyle} variant="body2">
              Atenção:
            </Typography>
            <Typography
              className={classes.informationStyle}
              variant="body2"
            >{`• ${t("info.blockPossibility")}`}</Typography>
            <Typography
              className={classes.informationStyle}
              variant="body2"
            >{`• ${t("info.limitCycle")}`}</Typography>
            {currency === USDT && (
              <Typography
                className={classes.informationStyle}
                variant="body2"
              >{`• ${t("info.sendUsdtWarning")}`}</Typography>
            )}
            {isOnlyBlockchainBep20 && (
              <Typography
                className={classes.informationStyle}
                variant="body2"
              >{`• ${t("info.sendOnlyBep20Warning", {
                currency,
              })}`}</Typography>
            )}
            {isOnlyBlockchainErc20 && (
              <Typography
                className={classes.informationStyle}
                variant="body2"
              >{`• ${t("info.sendOnlyErc20Warning", {
                currency,
              })}`}</Typography>
            )}
          </div>
        </FieldRow>
      </Form>
    </React.Fragment>
  );
};

export default compose(withStyles(styles))(SendCoinsForm);
