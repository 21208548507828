import React from 'react';
import { compose, pure, withState } from 'recompose';

import { translate } from 'react-i18next';

import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

import ProfileToolbar from '../components/ProfileToolbar';
import BankAccountForm from '../../../containers/BankAccountForm';
import GeneralInfoForm from './GeneralInfoForm';

const styles = theme => ({
  root: {
    flex: 1,
    paddingBottom: 100,
  },
  fieldItem: {
    padding: 0,
    ...theme.typography.body1,
    fontSize: theme.typography.pxToRem(13),
    color: theme.palette.text.secondary,
  },
});


const Account = props => {
  const {
    classes, t, isEditing,
  } = props;

  return (
    <div className={classes.root}>
      <ProfileToolbar title={t('account.generalInfo')} />
      <List dense>
        <GeneralInfoForm isEditing={isEditing} />
      </List>
      <ProfileToolbar title={t('account.bankAccount')} />
      <ListItem divider>
        <BankAccountForm />
      </ListItem>
    </div>
  );
};


const enhance = compose(
  withStyles(styles),
  withState('isEditing', 'setIsEditing', false),
  translate(),
  pure,
);

export default enhance(Account);
