import {
  compose, lifecycle
} from 'recompose';
import { connect } from 'react-redux';

import CardsInfo from './CardsInfo';

const mapStateToProps = ({ user: { extract: { extracts } } }) => ({
  extracts,
});

const mapDispatchToProps = dispatch => ({
})


const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),

  lifecycle({
  }),
);

export default enhance(CardsInfo);
