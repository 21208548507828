import React from 'react';
import { compose, pure } from 'recompose';
import Hidden from '@material-ui/core/Hidden';

import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import SnackBarComponent from './SnackBar';

const styles = theme => ({
  toolbar: theme.mixins.toolbar,
  '@media screen and (max-width: 600px)': {
    content: {
      paddingTop: '50px'
    }
  },
  content: {
    flex: '1 1 100%',
    maxWidth: '100%',
    margin: '0 auto',
  },
  contentWrapper: {
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.drawerWidth + 15,
      paddingRight: 15,
    }
  }
});

const AppContent = props => {
  const { children, logged, classes } = props;
  return (
    <main className={classNames(classes.content, { [classes.contentWrapper]: logged })}>
      {/* toolbars in the login page creates a undesirable whitespace in the screen */}
      {logged && (
        <React.Fragment>
          <div className={classes.toolbar} />
          <Hidden mdUp>
            <div className={classes.toolbar} />
          </Hidden>
        </React.Fragment>
      )}
      {children}
      <SnackBarComponent />
    </main>
  );
};

const enhance = compose(
  pure,
  withStyles(styles)
);

export default enhance(AppContent);
