import {
  compose,
  withState,
  withProps,
  withHandlers,
  lifecycle,
} from 'recompose';
import { translate } from 'react-i18next';
import { withFormik, yupToFormErrors } from 'formik';
import moment from 'moment';
import { connect } from 'react-redux';
import WAValidator from 'wallet-address-validator';
import { toFixedNoRound } from '../../../utils/toFixedNoRound.js';
import withBankList from './withBankList';
import withCriptoList from './withCriptoList';
import withBalanceAndLimits from './withBalanceAndLimits';
import withTransferFormSubmitHandler from './withTransferFormSubmitHandler';
import { addCelcoinRegisterDialog, addDialog } from '../../../redux/actions';
import { Currency, getCurrencyBySymbol } from '../../../models/Currency.js';

import TransferFormSchema from './TransferFormSchema';

import {
  TRANSFER_TYPES,
  TRANSFER_CURRENCY_TYPES,
  DEPOSIT_CRIPTO_TAX,
  CRIPTO, CBRL_EXCHANGE_OPTIONS,
  BLOCK_TRANSFER_OPTION_WARNING,
} from './constants';

import { normalizeBankAG } from '../../../utils/NumberFormat/normalizers';

import {
  getLimitAndBalanceFromProps,
  getSourceOptionsFromProps,
  getFeesFromProps,
  getDialogWithdrawalType,
  getDialogDepositType,
  getDialogOptions,
  getDialogStablecoinOptions,
  shouldResetForm,
} from './utils';
import { toChecksumAddress } from '../../../utils/checksumETH';
import { BSC } from '../../../config';

const initialValues = {
  source: {},
  destination: {},
  amount: undefined,
  operationType: 'TED',

  // BB
  agency: '',
  account: '',

  // withdrawal cripto
  address: '',
  agreeConvertToCripto: false,
  agreeConfirmAddress: false,
  agreeIN1888: false,
  agreeIN1888Deposit: false,
  agreeDepositCriptoTax: false,
  minimumStable: false,
  agreeDepositAboveLimit: false,
};

const ETH = 'ETH';

const validateETHWalletAddress = (form, currencyType) => {
  if (form.destination) {
    const { destination, blockchain } = form;
    const { address } = destination;
    const shouldValidate = currencyType === CRIPTO && blockchain !== 'SOL';
    if (shouldValidate
      && WAValidator.validate(address, ETH)) {
      const destinationFixAddress = {
        ...destination,
        address: toChecksumAddress(address),
      };
      return { ...form, destination: destinationFixAddress };
    }
    return form;
  }
  return form;
};

const mapStateToProps = (state) => {
  return {
    user: state.user.profile,
    pixAccount: state.user.account,
    bankInfo: state.exchange.bankList,
    currencies: state.currencies.currencies,
  };
};

const mapDispatchToProps = dispatch => ({
  createPixAccount: () => { dispatch(addCelcoinRegisterDialog()); },
  warningCripto: (formattedMessage) => {
    dispatch(
      addDialog({
        title: formattedMessage,
        availableChoices: [
          {
            label: 'common.understood',
            actionToTake: '',
            color: 'secondary',
            variant: 'raised',
          },
        ],
      }),
    );
  },
});

export default compose(
  translate(),
  withBalanceAndLimits,
  connect(mapStateToProps, mapDispatchToProps),
  withBankList,
  withCriptoList,
  withState('currencyType', 'setCurrencyType', TRANSFER_CURRENCY_TYPES.FIAT),
  withState('blockchain', 'setNetwork', 'ETH'),
  withTransferFormSubmitHandler,
  withFormik({
    mapPropsToValues: props => {
      return initialValues;
    },
    validate: (values, props) => {
      const { currencyType, blockchain, currencies } = props;
      const currency = (values.destination && values.destination.currency) || 'BRL';
      const selectedCurrencyConfig = getCurrencyBySymbol(currencies, currency);
      const { limitForOperation, currentBalance } = getLimitAndBalanceFromProps({ ...props, values }, selectedCurrencyConfig);
      const fees = getFeesFromProps({ ...props, values }, selectedCurrencyConfig);

      const availableLimit = (limitForOperation.total - limitForOperation.used);
      const { minWithdrawal } = limitForOperation;
      const transferType = props.transferType.toUpperCase();

      const isDeposit = transferType === TRANSFER_TYPES.DEPOSIT;
      const isBB = values.destination && values.destination.bank === '001';
      const isBankOthers = values.destination && values.destination.isBankOthers;
      const shouldValidateAgencyAndAccount = isDeposit && isBB && !isBankOthers;

      const isCripto = currencyType === TRANSFER_CURRENCY_TYPES.CRIPTO;
      const shouldValidateWithdrawalAddress = !isDeposit && isCripto;
      const shouldValidateWithdrawalCriptoDisclaimers = !isDeposit && isCripto;
      const shouldValidateWithdrawalCriptoIN1888 = !isDeposit && isCripto;
      const shouldValidateDepositCriptoTax = isDeposit && isCripto
        && DEPOSIT_CRIPTO_TAX[currency] && true;
      const shouldValidatePixAddress = !isDeposit && values.destination && values.destination.bankName === 'PIX';
      const currentBalanceWithFees = fees && fees.totalFees > 0
        ? currentBalance - fees.totalFees
        : currentBalance;
      const context = {
        availableLimit,
        minWithdrawal,
        currentBalance: currentBalanceWithFees,
        transferType,
        shouldValidateAgencyAndAccount,
        shouldValidateWithdrawalAddress,
        shouldValidateWithdrawalCriptoDisclaimers,
        shouldValidateWithdrawalCriptoIN1888,
        shouldValidateDepositCriptoTax,
        currency,
        shouldValidatePixAddress,
        blockchain,
      };
      return TransferFormSchema
        .validate(values, {
          abortEarly: false,
          context,
        })
        .catch(err => {
          throw yupToFormErrors(err);
        });
    },
    handleSubmit: (values, formikBag) => {
      const transferType = formikBag.props.transferType.toUpperCase();
      const {
        missingTaxId,
        alertUserAboutTaxIdMissing,
        currencyType,
        blockchain,
      } = formikBag.props;
      const blockchainNetwork = currencyType === TRANSFER_CURRENCY_TYPES.FIAT ? '' : blockchain;
      const valuesToSubmit = { ...values, userBankAccount: formikBag.props.userBankAccount, blockchain: blockchainNetwork };
      switch (transferType) {
        case TRANSFER_TYPES.WITHDRAWAL:
          if (missingTaxId) {
            alertUserAboutTaxIdMissing();
          } else {
            formikBag.props.withdrawalIntention(
              validateETHWalletAddress(valuesToSubmit, currencyType));
          }
          break;
        case TRANSFER_TYPES.DEPOSIT:
          formikBag.props.depositIntention(valuesToSubmit);
          break;
      }

      formikBag.resetForm();
    },
  }),
  withProps(props => {
    const {
      values, resetForm, blockchain, setFieldValue, criptoList, pixAccount, currencies,
    } = props;

    const { destination } = props.values;
    const currency = (destination && destination.currency) || 'BRL';

    const selectedCurrencyConfig = getCurrencyBySymbol(currencies, currency);

    const sourceOptions = getSourceOptionsFromProps(props);

    // if(!pixAccount.exists && pixAccount.required){
    //   const pixIndex = sourceOptions.findIndex(options => options.bankName === "PIX");
    //   if (pixIndex > -1) {
    //     sourceOptions.splice(pixIndex, 1);
    //   }
    // }

    if (sourceOptions !== undefined) {
      const sourceOptionsDestination = sourceOptions.find(options => options.isRegisteredBank);
      if (sourceOptionsDestination) {
        if (destination.isRegisteredBank && sourceOptionsDestination.address !== destination.address) {
          setFieldValue('destination', sourceOptionsDestination);
        }
      }
    }

    const fees = getFeesFromProps(props, selectedCurrencyConfig);
    const { limitForOperation, currentBalance } = getLimitAndBalanceFromProps(props, selectedCurrencyConfig);

    const isCripto = props.currencyType === TRANSFER_CURRENCY_TYPES.CRIPTO;

    const shouldShowNetworkOption = isCripto && (currency === 'CBRL' || currency === 'BRZ');
    const isCBRL = currency === 'CBRL';

    const showWithdrawalCriptoWarnings = props.transferType.toUpperCase() === TRANSFER_TYPES.WITHDRAWAL && isCripto;

    const showDepositCriptoWarnings = props.transferType.toUpperCase() === TRANSFER_TYPES.DEPOSIT && isCripto;

    const depositCurrency = blockchain !== '' ? blockchain : currency;

    const selectedCurrencyObj = new Currency(
      selectedCurrencyConfig,
    );

    const blockchainOptions = isCripto
      ? showDepositCriptoWarnings
        ? selectedCurrencyObj.getDepositBlockchainOptions
        : selectedCurrencyObj.getWithdrawalBlockchainOptions
      : [];

    if (shouldResetForm(values, sourceOptions)) {
      resetForm();
    }

    if (isCripto && !destination.currency && criptoList.length === 1) {
      setFieldValue('destination', criptoList[0]);
    }
    return {
      isCripto,
      isCBRL,
      sourceOptions,
      limitForOperation,
      currentBalance,
      fees,
      shouldShowNetworkOption,
      depositCurrency,
      showWithdrawalCriptoWarnings,
      showDepositCriptoWarnings,
      blockchainOptions,
      selectedCurrencyConfig,
    };
  }),
  lifecycle({
    componentDidMount() {
      const {
        location,
        setCurrencyType,
      } = this.props;
      if (location.state && location.state.stableCoinOp) {
        setCurrencyType(CRIPTO);
      }
    },
  }),
  withHandlers({
    handleAmountChange: props => amount => {
      const { setFieldValue } = props;
      setFieldValue('amount', amount);
      setFieldValue('selectedPercentage', undefined);
    },
    handleCurrencyTypeChange: props => e => {
      const {
        setCurrencyType,
        setFieldValue,
        criptoList,
      } = props;
      const { value } = e.target;
      setFieldValue('operationType', 'TED');
      setCurrencyType(value);
      if (value === TRANSFER_CURRENCY_TYPES.CRIPTO && criptoList.length === 1) {
        const destination = criptoList[0];
        setFieldValue('destination', destination);
      } else { props.resetForm(); }
    },
    handleNetworkChange: props => value => {
      const {
        setNetwork, alertBSCSelected,
      } = props;
      setNetwork(value.value);

      if (value.value === BSC) {
        alertBSCSelected(setNetwork);
      }
    },
    handlePixAddressChange: props => e => {
      const { handleChange, setFieldValue } = props;
      handleChange(e);
      const trimmedValue = e.target.value.trim();
      setFieldValue('destination.address', trimmedValue);
    },
    handleAgencyChange: props => e => {
      const { handleChange, setFieldValue } = props;
      handleChange(e);
      const { value } = e.target;

      const normalizedValue = normalizeBankAG(true)(value);
      setFieldValue('agency', normalizedValue);
    },

    handleAccountChange: props => e => {
      const { setFieldValue } = props;
      setFieldValue('account', e);
    },

    handleTransferDestinationChange: props => destination => {
      const {
        setFieldValue,
        warningOnSelectBank,
        limits,
        setNetwork,
        alertCBRLEndingSupport,
        pixAccount,
        createPixAccount,
      } = props;

      const { bankName } = destination;
      if (bankName === 'PIX') {
        setFieldValue('operationType', bankName);
        if (!pixAccount.exists && pixAccount.available) {
          createPixAccount();
        }
      } else {
        setFieldValue('operationType', 'TED');
      }
      const shouldDisplayTransferOptionWarning = props.currencyType === TRANSFER_CURRENCY_TYPES.FIAT
        && !BLOCK_TRANSFER_OPTION_WARNING.includes(destination.bankName);

      // Stable Withdrawal only
      const timeToNotify = moment(16, 'HH');
      const now = moment();
      const shouldNotifyWithdrawal = (props.transferType.toUpperCase() === TRANSFER_TYPES.WITHDRAWAL
        && props.currencyType === TRANSFER_CURRENCY_TYPES.FIAT)
        ? now.isSameOrAfter(timeToNotify)
        : true;

      if (shouldDisplayTransferOptionWarning
        && (shouldNotifyWithdrawal || CBRL_EXCHANGE_OPTIONS.includes(bankName))) {
        const warningComponentName = props.transferType.toUpperCase() === TRANSFER_TYPES.DEPOSIT
          ? getDialogDepositType(bankName)
          : getDialogWithdrawalType(bankName);
        const dialogConfig = getDialogOptions(bankName, props);
        warningOnSelectBank(warningComponentName, dialogConfig);
      } else if (props.transferType.toUpperCase() === TRANSFER_TYPES.DEPOSIT
        && props.currencyType === TRANSFER_CURRENCY_TYPES.CRIPTO
      ) {
        const { currency } = destination;
        const depositLimit = limits[currency].available_deposit;
        const limitToBRL = depositLimit.toLocaleString('pt-br', { minimumFractionDigits: 2 });
        const dialogConfig = getDialogStablecoinOptions(limitToBRL, currency);
        warningOnSelectBank('criptoStablecoinDepositWarning', dialogConfig);
      } else if (props.transferType.toUpperCase() === TRANSFER_TYPES.WITHDRAWAL
        && props.currencyType === TRANSFER_CURRENCY_TYPES.FIAT
        && !BLOCK_TRANSFER_OPTION_WARNING.includes(bankName)
      ) {
        const warningComponentName = getDialogWithdrawalType(bankName);
        const dialogConfig = getDialogOptions(bankName, props);
        warningOnSelectBank(warningComponentName, dialogConfig);
      }
      if (destination.currency === 'CBRL' && props.transferType.toUpperCase() === TRANSFER_TYPES.WITHDRAWAL) {
        alertCBRLEndingSupport();
      }
      setFieldValue('destination', destination);
      setNetwork('ETH');
    },

    handleAmountPercentageChange: props => percentage => {
      const { setFieldValue, fees, currentBalance } = props;

      const balanceWithoutFixed = currentBalance * (percentage / 100) - fees.fixed;
      const percentualFee = (fees.percent / 100) * balanceWithoutFixed;
      const totalFees = percentualFee + fees.fixed;

      const balanceWithFeesDiscount = currentBalance * (percentage / 100) - totalFees;

      setFieldValue('amount', toFixedNoRound(balanceWithFeesDiscount, 2));
      setFieldValue('selectedPercentage', percentage);
    },

  }),
);
