import { FETCH_PIX_ACCOUNT, UPDATE_PIX_ACCOUNT, CREATE_PIX_ACCOUNT, REMOVE_CREDENTIALS_ACCOUNT, GET_PIX_ACCOUNT } from "./actionTypes";

const updatePixAccount = (bankAccount) => ({
  type: UPDATE_PIX_ACCOUNT,
  bankAccount,
});

const fetchPixAccount = () => ({
  type: FETCH_PIX_ACCOUNT,
});

const createPixAccount = (motherName) => ({
  type: CREATE_PIX_ACCOUNT,
  motherName,
});

const getPixAccount = () => ({
  type: GET_PIX_ACCOUNT,
});

const removeCelcoinAccount = () => ({
  type: REMOVE_CREDENTIALS_ACCOUNT,
})

export default {
  updatePixAccount,
  fetchPixAccount,
  removeCelcoinAccount
};

export {
  updatePixAccount,
  fetchPixAccount,
  createPixAccount,
  removeCelcoinAccount,
  getPixAccount
};
