import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';

import { translate } from 'react-i18next';

import SwitchButton from '../Buttons/SwitchButton';

const enhance = compose(
  translate(),
);

const ShowMineButton = props => {
  const { showAll, setShowAll, changeShowAll, t } = props;

  return (
    <SwitchButton
      isActive={showAll}
      onClick={() => {setShowAll(true); if(changeShowAll) changeShowAll(true)/*Used get the state of button in orderBook(changes the subtitle)*/}}
    >
      {t('transactions.all')}
    </SwitchButton>
  );
};

ShowMineButton.propTypes = {
  showAll: PropTypes.bool.isRequired,
};

export default enhance(ShowMineButton);
