import React from 'react'
import { compose } from 'recompose'
import { withStyles } from '@material-ui/core/styles'

import InputAdornment from '@material-ui/core/InputAdornment'
import Typography from '@material-ui/core/Typography'

import SwitchButton from '../../../components/Buttons/SwitchButton'
import SwitchButtonWrapper from '../../../components/Buttons/SwitchButtonWrapper'
import InfoTooltip from '../../../components/InfoTooltip'

const styles = theme => ({
  switchButtonWrapper: {
    height: 20,
    width: 150,
    display: 'flex',
    justifyContent: 'space-between',
    border: '1px solid rgba(0, 0, 0, 0.23)'
  }
})

const PERCENTAGES = [25, 50, 75, 100]

const Button = props => <Typography noWrap {...props} />
const PercentageButton = props => {
  const {
    percentage,
    selectedPercentage,
    setPercentage,
    ...rest
  } = props

  return (
    <SwitchButton
      resetWidth={true}
      component={Button}
      isActive={percentage === selectedPercentage}
      onClick={() => setPercentage(percentage)}
      {...rest}
    >
      {percentage}%
    </SwitchButton>
  )
}

const PercentageSwitchButtons = ({
  classes,
  toolTipProps,
  selectedPercentage,
  setPercentage,
  disabled
}) => {
  return (
    <InputAdornment
      position="end"
      style={{ alignItems: 'flex-end', margin: '0' }}
    >
      <div style={{ display: 'flex', flexDirection: 'column' }}>

        {toolTipProps && (
          <InfoTooltip title={toolTipProps.toolTipTitle}>
            <Typography variant="caption" noWrap>
              {toolTipProps.toolTipCaption}
            </Typography>
          </InfoTooltip>
        )}

        <SwitchButtonWrapper className={classes.switchButtonWrapper}>
          { PERCENTAGES.map(percentage => (
            <PercentageButton
              disabled={disabled}
              selectedPercentage={selectedPercentage}
              setPercentage={setPercentage}
              percentage={percentage}
            />
          )) }
        </SwitchButtonWrapper>
      </div>
    </InputAdornment>
  )
}

export default compose(
  withStyles(styles)
)(PercentageSwitchButtons)
