import React, { Fragment, useCallback } from 'react'
import { reduxForm } from 'redux-form'
import { translate } from 'react-i18next'
import {
  compose,
  withProps
} from 'recompose'
import moment from 'moment'
import { withStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import AccountListDateField from '../../../Profile/Account/AccountListDateField'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import {Grid, ButtonBase} from '@material-ui/core'
import validate from '../../../../utils/validate'
import { connect } from 'react-redux'
import { downloadExtractHistoryBiscoint, downloadSimpleIR, downloadExtractHistoryCSV } from '../../../../redux/actions'
import classNames from 'classnames'
import { debounce } from 'lodash'
import styles from './styles'

const schemas = {
  from: {
    type: String,
    required: true
  },
  to: {
    type: String,
    required: true
  }
};

const ExportExtractForm = props => {
  const {
    t,
    classes,
    invalid,
    fetchIRBiscoint,
    downloadBityIR,
    downloadHistory,
    
  } = props

  const debouncedCompleteHistoryDownload = useCallback(debounce(({from, to}) => {
    downloadHistory({from, to, onlyBalance: false})
  }, 500), [])

  const debouncedSimpleHistoryDownload = useCallback(debounce(() => {
    downloadBityIR()
  } , 500), [])

  const debouncedFetchIRBiscoint = useCallback(debounce(() => {
    fetchIRBiscoint()
  } , 500), [])
  
  return (
    <Fragment>
      <form onSubmit={(event) =>{ 
        const form = new FormData(event.target)
        const from = moment(form.get('from'), 'DD/MM/YYYY').format('YYYY/MM/DD');
        const to = moment(form.get('to'), 'DD/MM/YYYY').format('YYYY/MM/DD');
        event.preventDefault()
        debouncedCompleteHistoryDownload({from, to})}}>      
        <div className={classNames(classes.row, classes.container)}>
          <List className={classNames(classes.column, classes.card)}>
            <Grid className={classNames(classes.column, classes.IRBackground, classes.bannerBackground)}>
              <Typography className={classes.IRTitle} dangerouslySetInnerHTML={{__html: t('screens.extract.howToDeclareIR')}}>
              </Typography>
              <Button
                className={classes.knowMore}
                variant='raised'
                href='https://www.bity.com.br/blog/como-declarar-bitcoin-e-outras-criptomoedas-no-imposto-de-renda/?utm_source=website&utm_medium=bitypreco&utm_campaign=blog_bitybank&utm_term=como_declarar_bitcoin_e_criptomoedas&utm_content=extrato' 
                target='_blank'
                rel="noopener noreferrer"
              >
                {t('common.knowMore')}
              </Button>
            </Grid>
            <Grid className={classNames(classes.row, classes.downloadIRButton)}>
              <Button
                className={classNames(classes.submitButton)}
                color='secondary'
                variant='raised'
                disabled={invalid}
                onClick={() => {
                  debouncedSimpleHistoryDownload()
                }}
              >
                {t('screens.extract.titleIR')}
              </Button>
            </Grid>
          </List>
        </div>
        <List className={classNames( classes.biscointIRContainer)}>
          <Typography>{t('screens.extract.hasBiscointHistory')}</Typography><ButtonBase className={classes.linkButton} onClick={debouncedFetchIRBiscoint}>{t("screens.extract.clickHereToDownload")}</ButtonBase>
        </List>
        <List className={classes.field}>
          <AccountListDateField
            label={t('screens.extract.from')}
            name='from'
            canEdit={true}
            placeholder={t('common.notIncluded')}
            pendingSecondary={t('common.notIncluded')}
            minDate={moment('2018-08-01')}
            maxDate={moment().toDate()}
          />
          <AccountListDateField
            label={t('screens.extract.to')}
            name='to'
            canEdit={true}
            placeholder={t('common.notIncluded')}
            pendingSecondary={t('common.notIncluded')}
            minDate={moment('2018-08-01')}
            maxDate={moment().toDate()}
          />
          <Button
            className={classes.submitButton}
            type='submit'
            color='secondary'
            variant='raised'
            disabled={invalid}
            style={{marginBottom:'15px'}}
          >
            Baixar Histórico Bitypreço
          </Button>
        </List>
      </form>
    </Fragment>
  )
}

const mapDispatchToProps = dispatch => ({
  fetchIRBiscoint: ()=> dispatch(downloadExtractHistoryBiscoint()),
  downloadBityIR: () => dispatch(downloadSimpleIR()),
  downloadHistory: (form) => dispatch(downloadExtractHistoryCSV(form)),
})

const enhance = compose(
  translate(),
  withStyles(styles),
  connect(null, mapDispatchToProps),
  withProps({
    initialValues: {
      from: moment()
        .subtract(3, 'month')
        .toDate(),
      to: moment()
        .toDate()
    }
  }),
  reduxForm({
    validate: validate(schemas),
    form: 'exportExtract',
    enableReinitialize: true
  })
)

export default enhance(ExportExtractForm)
