import React from 'react'

const SelectColumnFilter = ({
  t,
  filterLabelsFns,
  column: { filterValue, setFilter, preFilteredRows, id }
}) => {
  const options = React.useMemo(() => {
    const options = new Set()
    preFilteredRows.forEach(row => {
      options.add(row.values[id])
    })
    return [...options.values()]
  }, [id, preFilteredRows])

  return (
    <select
      value={filterValue}
      onChange={e => {
        setFilter(e.target.value || undefined)
      }}
    >
      <option value=''>{t('common.all')}</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {
            filterLabelsFns && filterLabelsFns[id]
              ? filterLabelsFns[id](option)
              : option
          }
        </option>
      ))}
    </select>
  )
}

export default SelectColumnFilter
