import React from 'react';
import TableRow from '@material-ui/core/TableRow';
import { withStyles } from '@material-ui/core';

const style = theme => ({
  rowOne: {
  },
  rowTwo: {
    backgroundColor: theme.palette.background.default,
  },
});

const StripedRow = ({ rowPosition = 0, classes, ...rest }) => {
  return (
    <TableRow className={rowPosition % 2 === 0 ? classes.rowOne : classes.rowTwo} {...rest} />
  );
};

export default withStyles(style)(StripedRow);
