
import Button from "@material-ui/core/Button";
import React from "react";
import {compose} from "recompose";
import {withStyles} from "@material-ui/core/styles";
import {translate} from "react-i18next";
import {buttonModels} from "./_constants";
import PropTypes from "prop-types";

const styles = theme => ({
        container: {
            display: 'flex',
            alignContent: 'center',
            gap: '10px',
            [theme.breakpoints.down('sm')]: {
                flexDirection: 'column',
                justifyContent: 'center',
                width: '100%'
            },
        },
        storeButtons: {
            display: 'flex',
            alignContent: 'center',
            borderRadius: '40px',
            [theme.breakpoints.down('sm')]: {
                width: '100%',
            },
        },
        contentButton: {
            display: 'flex',
            alignContent: 'center',
            justifyContent: 'center',
            gap: '5px',
        },
    });

const StoreLinks = (props) => {
    const { t, classes, buttonType, size, showIcon, flexDirection, width } = props;
    const buttonStyles = {
        padding: `${5 + (3 * size)}px 0`,
        fontSize: `${7 + (2 * size)}px`,
        minWidth: `${80 + (30 * size)}px`,
        width: width,
        

    };

    const containerStyles = {
        flexDirection: flexDirection,
        width: width && width == "100%" ? "100%" : "auto",
    };

    return (
        <div className={classes.container} style={containerStyles}>
            {buttonModels[buttonType].map(({ icon, text, color, link}) => {
                return (
                    <Button
                        key={"storeButton-" + text}
                        href={`https://tecle.me/bitybank@${link}`}
                        variant="raised"
                        target="_blank"
                        color={color}
                        className={classes.storeButtons}
                        style={buttonStyles}
                        classes={{ label: classes.contentButton }}
                    >
                        {showIcon && icon}
                        <span>{t(`${text}`)}</span>
                    </Button>
                )
            })}
        </div>
    );
}

const enhance = compose(
    withStyles(styles),
    translate(),
);

StoreLinks.propTypes = {
    buttonType: PropTypes.oneOf(['defaultButton', 'darkButton']),
    size: PropTypes.number,
    showIcon: PropTypes.bool,
    flexDirection: PropTypes.oneOf(['row', 'column']),
    width: PropTypes.string,
}

StoreLinks.defaultProps = {
    buttonType: 'defaultButton',
    size: 3,
    showIcon: true,
    flexDirection: 'row',

};

export default enhance(StoreLinks);
