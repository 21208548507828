import React from 'react';
import PropTypes from 'prop-types';
import { compose, pure } from 'recompose';

import { withStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import { Link } from 'react-router-dom';

import Hidden from '@material-ui/core/Hidden';

const styles = theme => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    height: theme.spacing.unit * 5,
    '&:hover': {
      backgroundColor: 'transparent',
      filter: `brightness(85%)`,
    },
  },
  activeContainer: {
    height: theme.spacing.unit * 5,
    '&:hover': {
      filter: `brightness(85%)`,
      backgroundColor: 'transparent',
      height: theme.spacing.unit * 5,
    },
  },
});

const DrawerLink = ({
  children,
  to,
  onClick,
  handleDrawerToggle,
  hidden,
  classes,
  scene,
  isActive,
  ...rest
}) => {
  const containerClassName = isActive ? classes.activeContainer : classes.container;
  return (
    <Hidden {...hidden}>
      <ListItem
        button
        {...to && {
          component: Link,
          to,
        }}
        className={containerClassName}
        onClick={() => {
          if (onClick) onClick();
          handleDrawerToggle(false);
        }}
        {...rest}
      >
        {children}
      </ListItem >

    </Hidden>

  );
};


DrawerLink.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

const enhance = compose(
  // withRouter,
  // translate(),
  pure,
  withStyles(styles),
);

export default enhance(DrawerLink);
