import React, { Fragment } from 'react';
import Typography from '@material-ui/core/Typography';
import CurrencyFormat from "./Currency/CurrencyFormat";
import { Currency } from "../models/Currency"

const BalanceAvailableAfterTransaction = props => {
  const {
    t,
    currency,
    balance,
    amount,
    fees,
  } = props;

  const currencyConfig = new Currency(currency)
  const balanceAfterTransaction = Number(balance[currencyConfig.symbol]) - (Number(amount) + Number(fees.totalFees))

  return (
    <Fragment>

      <div
        style={{
          display: 'flex',
          flex: 1,
          justifyContent: 'space-between',
        }}
      >
        <Typography variant="caption">
          {t('transactions.balanceAvailable')}
        </Typography>
        <Typography variant="caption">
          <CurrencyFormat value={balance[currencyConfig.symbol]} isFiat={currencyConfig.isFiat} formatter={currencyConfig._defaultFormatter()} hidePrefix useMainScale/>

        </Typography>
      </div>
      <div
        style={{
          display: 'flex',
          flex: 1,
          justifyContent: 'space-between',
        }}
      >
        <Typography variant="caption">
          {t('transactions.balanceAvailableAfterTransaction')}
        </Typography>
        <Typography variant="caption">
          <CurrencyFormat value={balanceAfterTransaction > 0 ? balanceAfterTransaction : 0} isFiat={currencyConfig.isFiat} formatter={currencyConfig._defaultFormatter()} hidePrefix useMainScale/>

        </Typography>
      </div>
    </Fragment>
  );
};

export default BalanceAvailableAfterTransaction;
