import React from 'react';
import * as R from 'ramda';
import { Field } from 'redux-form';

import SearchSelect from '../../components/Forms/SearchSelect';
import banksList from '../../assets/febraban_banks.json';

const SelectBankField = props => {
  const { classes, t, hasAccount, ...other } = props;

  return (
    <Field
      component={SearchSelect}
      keyName="code"
      labelName="name"
      onChange={e => {
        if (e.key === '001') {
          sessionStorage.setItem('bb', true);
        } else {
          sessionStorage.setItem('bb', false);
        }
      }}
      label={t('transactions.myBank')}
      selectOptions={banksList}
      disabled={hasAccount}
      format={(value, _name) => {
        if (R.is(String, value)) {
          const bank = R.find(R.propEq('code', value))(banksList);
          if (bank) {
            return { key: bank.code, label: bank.label };
          }
          return { key: value, label: 'BANCO NÃO CADASTRADO' };
        }
        return value;
      }}
      textFieldProps={{
        margin: 'dense',
        className: classes.selectField,
        fullWidth: true
      }}
      {...other}
    />
  );
};

export default SelectBankField;
