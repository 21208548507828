import React from 'react';
import { compose, pure } from 'recompose';
import {
  BrowserRouter as Router,
  Route,
} from 'react-router-dom';
import { Provider } from 'react-redux';
import { ErrorBoundary } from 'react-error-boundary';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faPencilAlt, faRobot, faKey, faUnlock, faClone,
} from '@fortawesome/free-solid-svg-icons';

import {
  GoogleReCaptchaProvider,
} from 'react-google-recaptcha-v3';
import ErrorHandler from './pages/ErrorBoundary/ErroBoundary';
import ThemeProvider from './layout/themes/ThemeProvider';
import Routes from './routes/Router';

import store from './redux/store';
import { GOOGLE_RECAPTCHA_SITE_KEY_V3 } from './config';

import { gtmSession } from './utils/dataLayer';
import './styles/global.css'


library.add(faPencilAlt, faRobot, faKey, faUnlock, faClone);

const App = () => {
  React.useEffect(() => gtmSession(), []);
  return (
    <ErrorBoundary
      FallbackComponent={ErrorHandler}
    >
      <GoogleReCaptchaProvider reCaptchaKey={GOOGLE_RECAPTCHA_SITE_KEY_V3}>
        <Provider store={store}>
          <ThemeProvider>
            <Router>
              <Route path="/" component={Routes} />
            </Router>
          </ThemeProvider>
        </Provider>
      </GoogleReCaptchaProvider>
    </ErrorBoundary>
  );
};

const enhance = compose(
  pure,
);

export default enhance(App);
