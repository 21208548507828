import React from 'react';
import { compose, pure, withProps } from 'recompose';
import { translate } from 'react-i18next';

import { withStyles } from '@material-ui/core/styles';
import AccountListItem from './AccountListItem';
import ListItemField from './ListItemField';

const styles = theme => ({
  root: {
    flex: 1,
    paddingBottom: 100,
  },
  fieldItem: {
    padding: 0,
    ...theme.typography.body1,
    fontSize: theme.typography.pxToRem(13),
    color: theme.palette.text.secondary,
  },
});

const AccountListItemField = props => {
  const {
    listItemSecondaryAction, tooltipTitle, name
  } = props;
  return (
    <AccountListItem
      style={name==='address' || name==='name' ? {width:300} : {}}
      primary={<ListItemField {...props} />}
      listItemSecondaryAction={listItemSecondaryAction}
      secondaryTypographyProps={{ component: 'div' }}
      tooltipTitle={tooltipTitle}
    />
  );
};

const enhance = compose(
  withStyles(styles),
  translate(),
  withProps(({
    primary, t, i18nScope, name,
  }) => {
    if (primary) {
      return { primary };
    }
    if (i18nScope) {
      return {
        primary: t(`${i18nScope}.${name}.label`),
      };
    }
    return {
      primary: t(`forms.${name}.label`),
    };
  }),
  pure,
);

export default enhance(AccountListItemField);
