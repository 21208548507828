export const styles = theme => ({
    root: {
      flexGrow: 1
    },
    price: {
      fontSize: 18,
      fontWeight: 500,
      color: theme.palette.secondary.main
    },
    hidden: {
      visibility: 'hidden'
    },
    sell: theme.sellButton,
    buy: theme.buyButton,
    buttons: {
      color: 'white'
    },
    depositButton:{
      backgroundColor: theme.palette.secondary.main,
      '&:hover': {
        backgroundColor: theme.palette.secondary.dark
      },
      color:'white',
      width: '100%',
      
    },
    ctaText:{
      textAlign: 'center',
        color: "rgba(0, 0, 0, 0.87)",
        fontSize: "0.875rem",
        fontWeight: 400,
        fontFamily: "Roboto,Helvetica,Arial sans-serif",
        lineHeight: "1.46429em",
    },
    center: {
      display: 'flex',
      justifyContent: 'center',
      padding: '0 16px !important'
    },
    relative:{
      position: 'relative'
    },
    progressBar:{
      position: 'absolute',
      right: '120px',
      top: '5px',
      fontFamily: 'Roboto',
      fontSize: '11pt',
      color: '#101010',
      '@media screen and (max-width: 550px)' : {
        right: '15%',
      },
      '@media screen and (max-width: 425px)' : {
        right: '10%',
      },
      '@media screen and (max-width: 375px)' : {
        right: '5%',
      },
      '@media screen and (max-width: 320px)' : {
        right: '-5%',
      },
    },
    currencyIssueSpacing: {
      marginTop: '1rem',
    }
  })