import { merge } from 'ramda';
import * as actions from './actionTypes';

const initialState = {
  SELL: [],
  BUY: [],
};

const updateOpenOrders = (state, openOrders) => {
  return merge(state, openOrders);
};

const openOrdersReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.UPDATE_OPEN_ORDERS:
      return updateOpenOrders(state, action.openOrders);

    default:
      return state;
  }
};

export default openOrdersReducer;
