export const DEPOSIT_INTENTION_REQUESTED = 'DEPOSIT_INTENTION_REQUESTED';
export const DEPOSIT_INTENTION_SUCCEED = 'DEPOSIT_INTENTION_SUCCEED';
export const DEPOSIT_INTENTION_FAILED = 'DEPOSIT_INTENTION_FAILED';

export const UPLOAD_DEPOSIT_REQUESTED = 'UPLOAD_DEPOSIT_REQUESTED';

export const WITHDRAWAL_INTENTION_REQUESTED = 'WITHDRAWAL_INTENTION_REQUESTED';
export const CONFIRM_WITHDRAWAL_INTENTION_REQUEST = 'CONFIRM_WITHDRAWAL_INTENTION_REQUEST';
export const WITHDRAWAL_INTENTION_SUCCEED = 'WITHDRAWAL_INTENTION_SUCCEED';
export const CRYPTO_WITHDRAWAL_INTENTION_SUCCEED = 'CRYPTO_WITHDRAWAL_INTENTION_SUCCEED';
export const ADD_WITHDRAWAL_CONFIRMATION_DIALOG = 'ADD_WITHDRAWAL_CONFIRMATION_DIALOG';
export const WITHDRAWAL_INTENTION_FAILED = 'WITHDRAWAL_INTENTION_FAILED';

export const CONFIRM_WITHDRAWAL_REQUESTED = 'CONFIRM_WITHDRAWAL_REQUESTED';

export const CONFIRM_CANCEL_DEPOSIT_INTENTION = 'CONFIRM_CANCEL_DEPOSIT_INTENTION';
export const CANCEL_DEPOSIT_INTENTION_REQUESTED = 'CANCEL_DEPOSIT_INTENTION_REQUESTED';
export const CANCEL_DEPOSIT_INTENTION_SUCCEED = 'CANCEL_DEPOSIT_INTENTION_SUCCEED';
export const CANCEL_DEPOSIT_INTENTION_FAILED = 'CANCEL_DEPOSIT_INTENTION_FAILED';

export const CANCEL_SEND_COINS_REQUESTED = 'CANCEL_SEND_COINS_REQUESTED';
export const CANCEL_SEND_COINS_SUCCEED = 'CANCEL_SEND_COINS_SUCCEED';
export const CANCEL_SEND_COINS_FAILED = 'CANCEL_SEND_COINS_FAILED';
export const CONFIRM_CANCEL_SEND_COINS = 'CONFIRM_CANCEL_SEND_COINS';
