import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { CURRENCY_ICONS_URL } from '../config';
import { Typography } from '@material-ui/core';
const styles = theme => ({
  iconContainer: {
    minWidth: 32,
    maxWidth: 32,
    height: 32,
    borderRadius: 50,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  icon: {
    maxWidth: 32,
    padding: 4,
    WebkitPaddingStart: 4,
    WebkitPaddingEnd: 4,
    objectFit: 'contain',
  },

  iconText: {
    marginTop: 4,
    padding: 0,
    lineHeight: 1,
    fontSize: 22,
    fontWeight: 700,
    color: '#FFF',
  },
});


const CurrencyIcon = ({ currency, classes, classIcon }) => {
  const symbol = currency.symbol.toLowerCase();
  const currencyBaseColor = currency.color;
  const currencyIcon = `${CURRENCY_ICONS_URL}-${symbol}.svg`;
  const [useGenericIcon, setUseGenericIcon] = useState(false);

  const handleCurrencyIconError = () => {
    setUseGenericIcon(true);
  };

  useEffect(() => {
    if (useGenericIcon) {
      setUseGenericIcon(false);
    }
  } , [currencyIcon]);

  return (
    <div
      className={classIcon ? classIcon : classes.iconContainer}
      style={{ backgroundColor: currencyBaseColor }}
    >
      {!useGenericIcon ? (
        <img src={currencyIcon} alt="" className={classes.icon} onError={handleCurrencyIconError} />
      ) : (
        <Typography component={"span"} className={classes.iconText}>{symbol.length > 0 ? symbol[0].toUpperCase() : "?"}</Typography>
      )}
    </div>
  );
}

CurrencyIcon.defaultProps = {
  currency: {
    symbol: "BTC",
    color: "#FF9500",
  },
};

CurrencyIcon.propTypes = {
  currency: PropTypes.object.isRequired,
};

export default withStyles(styles)(CurrencyIcon)
