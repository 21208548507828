import * as R from 'ramda';
import * as actions from './actionTypes';

const initialState = {};

const updateProfile = (state, { profile }) => {
  return R.merge(state, profile);
};


const userProfileReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.UPDATE_PROFILE:
      return updateProfile(state, action);
    default:
      return state;
    }

};

export default userProfileReducer;
