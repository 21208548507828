import { all } from 'redux-saga/effects';

/** Dados publicos e credenciais */
import watchAppData from './appData/sagas';

/** Controle de componentes de layout
 * Snackbar
 * Modal de confirmação
 * Controle de componentes responsivos
 */
import watchLayout from './layout/sagas';

/**
 * Dados do usuário
 * - Conta Bancária
 * - Documentos
 * - Limites Operacionais
 * - Perfil
 * - Balanço
 * - Carteiras
 */
import userSaga from './user/sagas';
import watchCredentials from './credentials/saga';
import exchangeSagas from './exchange/sagas';
import watchBtcTicker from './ticker/saga';
import watchMarket from './market/saga'
import watchOrderBook from './orderBook/saga';
import watchOpenOrders from './openOrders/saga';
import watchTradeHistory from './tradeHistory/saga';
import watchTransactions from './transactions/saga';
import watchOrders from './orders/saga';
import watchUserActivity from './keepAlive/saga';
import watchCurrencies from './currencies/saga';

function* rootSaga() {
  yield all([
    watchCredentials(),
    userSaga(),
    watchBtcTicker(),
    watchMarket(),
    watchOrderBook(),
    watchTradeHistory(),
    watchOpenOrders(),
    watchTransactions(),
    watchAppData(),
    watchLayout(),
    watchCurrencies(),
    watchOrders(),
    exchangeSagas(),
    watchUserActivity()
  ]);
}

export default rootSaga;
