import { replace } from 'ramda';
import privateService from '../privateService';

const cancelOrder = async ({ id }) => {
  const options = {
    cmd: 'order_cancel',
    method: 'POST',
  };
  const order_id = replace('==', '', id); // eslint-disable-line camelcase
  const content = await privateService(options, { order_id });

  return content;
};

export default cancelOrder;
