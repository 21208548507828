import React, { Fragment } from 'react';
import { Field } from 'redux-form';

import UploadingProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core';
import SentIcon from '@material-ui/icons/Timer';
import OkIcon from '@material-ui/icons/CheckCircle';
import IconButton from '@material-ui/core/IconButton';
import CancelIcon from '@material-ui/icons/Cancel';
import Typography from '@material-ui/core/Typography';
import ProcessingIcon from '@material-ui/icons/RotateRight';
import DocInput from './DocInput';

const DocInputStatus = props => {
  const {
    status,
    classes,
    address,
    allowReUpload
  } = props;
  const isGlobalCard = address === 'GC|ONLINE'

  // if the item is a "GLOBAL CARD" type, must show PAY URL
  if (status === 'PENDING' && isGlobalCard) {
    return (
      <IconButton href={address} target="_blank" classes={{ disabled: classes.statusOk }}>
        <Typography variant="button" gutterBottom>
          PAGAR
        </Typography>
      </IconButton>
    );
  }
  switch (status) {
    case 'SENT':
      return (
        <Fragment>
          <IconButton disabled classes={{ disabled: classes.statusSent }}>
            <SentIcon />
          </IconButton>
          {
            allowReUpload && <Field {...props} component={DocInput} type="file" />
          }
        </Fragment>
      );

    case 'OK':
      return (
        <IconButton disabled classes={{ disabled: classes.statusOk }}>
          <OkIcon />
        </IconButton>
      );

    case 'CANCELED':
      return (
        <IconButton disabled>
          <CancelIcon />
        </IconButton>
      );

    case 'UPLOADING':
      return (
        <IconButton disabled>
          <UploadingProgress size={22} className={classes.statusUploading} />
        </IconButton>
      );

    case 'PROCESSING':
      return (
        <IconButton disabled>
          <ProcessingIcon />
        </IconButton>
      )

    default:
      return <Field {...props} component={DocInput} type="file" />;
  }
};

const styles = theme => {
  return {
    statusOk: {
      '&:disabled': {
        color: theme.palette.green.main
      }
    },

    statusSent: {
      '&:disabled': {
        color: theme.palette.secondary.light
      }
    },
    statusUploading: {
      color: theme.palette.green.main
    }
  };
};

DocInputStatus.defaultProps = {
  allowReUpload: false
}

export default withStyles(styles)(DocInputStatus);
