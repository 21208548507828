import {
  put, takeLatest, takeEvery, call, select,
} from 'redux-saga/effects';
import * as R from 'ramda';
import actions from './actions';
import { CREATE_ORDER, CANCEL_ORDER, INIT_CANCEL_ORDER } from './actionTypes';
import fetchBuy from '../../services/cbtcService/buy';
import fetchSell from '../../services/cbtcService/sell';
import fetchCancelOrder from '../../services/cbtcService/cancelOrder';

import {
  addSnack,
  addDialog,
  optimisticAddOrder,
  optimisticCancelOrder,
  optimisticRemoveOrder,
  addSessionExpiredDialog,
  closeDialog,
  fetchOrdersRequested,
  fetchBalance
} from '../actions';
import { gtmTransaction } from "../../utils/dataLayer";
import { tradeTypes } from '../../utils/constants';
import { triggerTradeEvent } from '../../config';


function* createOrderSaga({ order: { id, type, ...order }, props: {props} }) {
  try {
    const fetchCreateOrder = type === 'SELL' ? fetchSell : fetchBuy;
    order['source'] = tradeTypes.orderbook;
    const cbRes = yield call(() => fetchCreateOrder(order));

    const { success, message_cod, timestamp } = cbRes; // eslint-disable-line camelcase
    yield put(optimisticAddOrder({ orderType: type, order: { time_stamp: timestamp, ...order } }));

    yield put(fetchBalance());
    yield put(fetchOrdersRequested());

    const parametersEvent = {
      'isBuy': cbRes?.type == 'BUY' ? 1 : 0,
      'price': cbRes?.price,
      'amount': cbRes?.amount,
      'market': cbRes?.market,
      'limited': order?.limited == true ? 1 : 0,
    }

    if (success) {
      triggerTradeEvent('trade', parametersEvent);
      gtmTransaction(type);
      yield put(actions.resolveOrder({ id, ...order }));
      if(type === 'BUY' && props){
        const {formik, valueEconomy,lastOrders, values} = props
        if(props.valueEconomy >= 0.1){
          yield put(addDialog({
            renderComponent: 'congratulationsOnEconomy',
            title: 'transactions.congratulationOnEconomy.successBuy',
            componentProps: {
              formik,
              valueEconomy,
              lastOrders,
              values
            },
            availableChoices: [
              {
                label: 'Fechar',
                actionToTake: 'closeDialog',
                color: 'secondary',
                variant: 'raised',
              },
            ],
            actionsToTake: {
              closeDialog: () => closeDialog()
            },}))
        }
        
      }
    } else if (message_cod === 'INVALID_TOKEN') { // eslint-disable-line camelcase
      yield put(addSessionExpiredDialog());
    } else {
      yield put(addSnack({ message: message_cod }));
    }
    yield put(actions.clearSelectedOrder());
  } catch (error) {
    const { message_cod } = error; // eslint-disable-line camelcase
    if(message_cod === 'INVALID_TOKEN'){
      yield put(addSessionExpiredDialog());
    }else{
      yield put(addSnack({ message: message_cod }));
    }
    yield put(fetchOrdersRequested());
    console.error('createOrderSaga', error);
  }
}


function* initCancelOrderSaga({ op, orders }) {
  const cancelOrdersActions = R.map(order => actions.cancelOrder({ op, ...order }));
  const title = R.length(orders) > 1
    ? `transactions.${op.toLowerCase()}.cancelMany`
    : `transactions.${op.toLowerCase()}.cancel`;

  try {
    yield put(addDialog({
      title,
      availableChoices: [
        {
          label: 'common.no',
          actionToTake: '',
          color: 'secondary',
          variant: 'raised',
        },
        {
          label: 'common.yes',
          actionToTake: 'cancel',
        },
      ],
      actionsToTake: {
        cancel: () => cancelOrdersActions(orders),
      },
    }));
  } catch (error) {
    console.error('endSessionSaga', error);
  }
}

function* cancelOrderSaga({ order: { op = '', ...order } }) {
  try {
    const retriedOrders = yield select(state => state.transactions.retriedOrders);
    const isRetry = retriedOrders.includes(order.id);
    if (!isRetry) yield put(optimisticCancelOrder({ orderType: op, order }));
    const cbRes = yield call(() => fetchCancelOrder(order));

    const { success, message_cod } = cbRes; // eslint-disable-line camelcase
    if (success) {
      yield put(optimisticRemoveOrder({ orderType: op, order }));
    } else if (message_cod === 'INVALID_TOKEN') { // eslint-disable-line camelcase
      yield put(addSessionExpiredDialog());

    } else {
      if (!isRetry) {
        yield put(actions.addRetryCancelOrder(order.id));
        yield put(actions.cancelOrder(order));
      }
      yield put(addSnack({ message: message_cod }));
    }
  } catch (error) {
    const { message_cod } = error; // eslint-disable-line camelcase
    if (message_cod === 'INVALID_TOKEN')
      yield put(addSessionExpiredDialog());
    else
      yield put(addSnack({ message: message_cod }));

    console.error('cancelOrderSaga', error);
  }
}

function* watchTransactions() {
  yield takeLatest(CREATE_ORDER, createOrderSaga);
  yield takeEvery(CANCEL_ORDER, cancelOrderSaga);

  yield takeLatest(INIT_CANCEL_ORDER, initCancelOrderSaga);

}

export default watchTransactions;
