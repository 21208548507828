import React from 'react';
import * as R from 'ramda';
import NumberFormat from 'react-number-format';

const DateMaskFormatInput = (props) => {
  const {
    inputRef, onChange, onBlur, value, ...other
  } = props;

  return (
    <NumberFormat
      {...other}
      value={value}
      ref={inputRef}
      onValueChange={values => {
        onChange(R.isNil(values.value) ? '' : values.value);
      }}
      onBlur={_event => {
        onBlur(value);
      }}
      format={'##/##/####'}
      type="tel"
    />
  );
};

export default DateMaskFormatInput;
