import { merge } from 'ramda';
import * as actions from './actionTypes';

const initialState = {
  BTC: {},
  ETH: {},
  USDT: {},
  BNB: {},
  CRZO: {},
  PAXG: {},
  SLP: {},
  AXS: {},
  CAKE: {},
  UNI: {},
  SOL: {},
  POLIS: {},
  USDC: {},
  USDBP: {},
  ATLAS: {},
  ABFY: {},
  ADA: {},
  LUNA: {},
  LUNC: {},
  GMT: {},
  BUSD: {},
  // ELLO: {},
  DOT: {},
  MATIC: {},
  LINK: {},
  XRP: {},
};

const updateTicker = (state, ticker) => {
  const newState = merge(state, ticker);

  return newState;
};

const tickerReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_SELECTED_MARKET':
      return initialState;
    case actions.UPDATE_TICKER:
      return updateTicker(state, action.ticker);

    default:
      return state;
  }
};

export default tickerReducer;
