import service from './service';
import store from '../redux/store';

const getToken = () => store.getState().credentials.token;

/* eslint-disable camelcase */
const privateService = (options, form) => {
  const auth_token = getToken();

  return service(options)({ auth_token, ...form });
};

export default privateService;
