import {
  put, takeLatest, take,
} from 'redux-saga/effects';

import {
  UPLOAD_DOCUMENT_REQUESTED,
  UPLOAD_DOCUMENT_SUCCEED,
  UPLOAD_DOCUMENT_FAILED,
} from './actionTypes';
import { fetchService, fetchProfile } from '../../actions';

import { uploadDocumentResolved } from './actions';
import { getFormat, getImageData } from '../../../utils/imageHelpers';
import sendGAEvent from '../../../utils/sendGAEvent';
import { gtmDocuments } from '../../../utils/dataLayer';
function* uploadDocSaga(action) {
  try {
    const { formType, opDoc: { mimetype, url } } = action;

    const form = {
      type: formType,
      format: getFormat(mimetype),
      data: getImageData(url),
    };

    yield put(fetchService({
      requestType: 'uploadDocument',
      successAction: UPLOAD_DOCUMENT_SUCCEED,
      failAction: UPLOAD_DOCUMENT_FAILED,
      responseToUser: {
        onSuccess: 'snack',
        onFail: 'dialog',
      },
      paramns: {
        privateService: true,
        form,
        options: {
          cmd: 'upload_document',
          method: 'POST',
        },
      },
    }));

    const res = yield take([UPLOAD_DOCUMENT_FAILED, UPLOAD_DOCUMENT_SUCCEED]);
    if (res.type === "KYC_APPROVED") sendGAEvent('Cadastro', 'Envio de Documentos');
    yield put(uploadDocumentResolved({
      resType: res.type,
      docType: form.type,
    }));

    gtmDocuments();
    yield put(fetchProfile());
  } catch (error) {
    console.error('uploadDocSaga', error);
  }
}

function* watchDocuments() {
  yield takeLatest(UPLOAD_DOCUMENT_REQUESTED, uploadDocSaga);
}

export default watchDocuments;
