import { Grid, Typography, ButtonBase } from "@material-ui/core";
import React from "react";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { translate } from "react-i18next";
import SectionCard from "../SectionCard";
import ValueBox from "../ValueBox";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import store from "../../../redux/store";
import { addSnack } from "../../../redux/actions";
import DangerouslySetInnerHTML from "../../../components/DangerouslySetInnerHTML";

const styles = (theme) => ({
  mainContainer: {
    maxWidth: "52.899022801303%",
    margin: "50px auto 0",
    [theme.breakpoints.down("lg")]: {
      maxWidth: "87%",
    },
    [theme.breakpoints.down("md")]: {
      maxWidth: "90%",
    },
    position: "relative",
  },
  shareLinkSection: {
    width: "100%",
    padding: "50px 12.315270935961%",
    [theme.breakpoints.down("md")]: {
      padding: "50px 10%",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "50px 20px",
    },
  },
  shareLinkContent: {
    margin: "0 auto",
    width: "60%",
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
  },
  defaultGap: {
    gap: "10px",
  },
  valueItemsContainer: {
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
  valueItemsRow: {
    display: "flex",
    gap: "10px",
    alignItems: "center",
    flex: 1,

    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  shareLinkButton: {
    background: "#FFF",
    borderRadius: "5px",
    border: "1px solid #D6D1B4",
    color: "#62560A",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "normal",
    gap: "10px",
    display: "flex",
    alignItems: "center",
    padding: "10px",
  },
  myIndicationsContainer: {
    marginTop: "20px",
    gap: "20px",

    [theme.breakpoints.down("lg")]: {
      flexDirection: "column",
    },
  },
  subtitle: {
    margin: 0,
    color: "#696F74",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "24px",
    textAlign: "center",
    fontFamily: "Roboto, sans-serif",
  },
  shareCard: {
    position: "relative",
    display: "flex",
    flex: 1,
    borderRadius: "5px",
    border: "1px solid #D6D1B4",
    padding: "15px 20px",
    background: "#FEF4D2",
    boxShadow: "none",

    "& p": {
      color: "#62560A",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "24px",
    },
  },
});

const copyToClipBoard = async (copyMe) => {
  try {
    await navigator.clipboard.writeText(copyMe);
    store.dispatch(addSnack({ message: "common.contentCopied" }));
  } catch (err) {
    store.dispatch(addSnack({ message: "common.failOnCopyContent" }));
  }
};

const Indication = (props) => {
  const { classes, t, userData } = props;
  const { referralsData, profile } = userData;
  const referId = profile.id ? profile.id : "";
  const referLink = `bity.com.br/mgm/${referId}`;
  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      className={classes.mainContainer}
    >
      <SectionCard
        className={classes.shareLinkSection}
        title={t("screens.mgm.cashback.cashbackSplit.referAndWin")}
      >
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          className={classNames(classes.defaultGap, classes.shareLinkContent)}
          dire
        >
          <Typography className={classes.subtitle}>
            {t(
              "screens.mgm.indications.referAndWin.referYourFriendsThroughLink"
            )}
          </Typography>
          <Grid
            container
            alignItems={"center"}
            className={classes.shareCard}
            justify="space-between"
          >
            <Typography>{referLink}</Typography>
            <ButtonBase
              className={classes.shareLinkButton}
              onClick={() => {
                copyToClipBoard(referLink);
              }}
            >
              {t("common.copyLink")}
              <FontAwesomeIcon size="lg" color={"#62560A"} icon="clone" />
            </ButtonBase>
          </Grid>
        </Grid>
      </SectionCard>
      <Grid
        container
        direction="row"
        className={classes.myIndicationsContainer}
      >
        <SectionCard
          title={t("screens.mgm.indications.myIndications.title")}
          titleChildrenGap={"20px"}
        >
          <Grid
            container
            direction="column"
            justifyContent="center"
            className={classes.defaultGap}
          >
            <div className={classes.subtitle}>
              <DangerouslySetInnerHTML
                title={t(
                  referralsData.totalReferrals > 1
                    ? "screens.mgm.indications.myIndications.pluralSubtitle"
                    : "screens.mgm.indications.myIndications.singularSubtitle",
                  { count: referralsData.totalReferrals }
                )}
              />
            </div>
            <Grid
              container
              direction="row"
              justifyContent="center"
              className={classNames(
                classes.defaultGap,
                classes.valueItemsContainer
              )}
            >
              <div className={classes.valueItemsRow}>
                <ValueBox
                  hasToolTip
                  value={referralsData.totalReferrals}
                  toolTipLabel={t(
                    "screens.mgm.indications.myIndications.madeRegister"
                  )}
                  toolTipText={t(
                    "screens.mgm.indications.myIndications.madeRegisterDescription"
                  )}
                />
                <ValueBox
                  hasToolTip
                  value={referralsData.cardReferrals}
                  toolTipLabel={t(
                    "screens.mgm.indications.myIndications.boughtOnCard"
                  )}
                  toolTipText={t(
                    "screens.mgm.indications.myIndications.boughtOnCardDescription"
                  )}
                />
              </div>
              <div className={classes.valueItemsRow}>
                <ValueBox
                  hasToolTip
                  value={referralsData.transactionReferrals}
                  toolTipLabel={t(
                    "screens.mgm.indications.myIndications.didNegotiation"
                  )}
                  toolTipText={t(
                    "screens.mgm.indications.myIndications.didNegotiationDescription"
                  )}
                />
                <ValueBox
                  hasToolTip
                  value={`R$ ${referralsData.totalEarned}`}
                  toolTipLabel={t(
                    "screens.mgm.indications.myIndications.totalValueEarned"
                  )}
                  toolTipText={t(
                    "screens.mgm.indications.myIndications.totalValueEarnedDescription"
                  )}
                />
              </div>
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="center"
              className={classes.defaultGap}
            ></Grid>
          </Grid>
        </SectionCard>
      </Grid>
    </Grid>
  );
};




const enhance = compose(withStyles(styles), translate());

export default enhance(Indication);
