const CREATE_ORDER = 'CREATE_ORDER';
const RESOLVE_ORDER = 'RESOLVE_ORDER';
const CANCEL_ORDER = 'CANCEL_ORDER';
const INIT_CANCEL_ORDER = 'INIT_CANCEL_ORDER';

const SET_ACTIVE_TAB = 'SET_ACTIVE_TAB';

const SELECT_ORDER = 'SELECT_ORDER'
const CLEAR_SELECTED_ORDER = 'CLEAR_SELECTED_ORDER'

const ADD_RETRY_CANCEL_ORDER = 'ADD_RETRY_CANCEL_ORDER'
const REMOVE_RETRY_CANCEL_ORDER = 'POP_RETRY_CANCEL_ORDER'

export {
  CREATE_ORDER,
  RESOLVE_ORDER,
  CANCEL_ORDER,
  INIT_CANCEL_ORDER,
  SET_ACTIVE_TAB,
  SELECT_ORDER,
  CLEAR_SELECTED_ORDER,
  ADD_RETRY_CANCEL_ORDER,
  REMOVE_RETRY_CANCEL_ORDER
};
