import { throttleMSInterval } from "../utils/constants";
import { getRequestHash } from "../utils/generalUtils";
import { ThrottleError } from "../models/ThrottleError";

export class ThrottleRequests {
  throttleRequestsController = {};

  throttleRequest({ cmd, form, customInterval }) {
    const hashRequest = getRequestHash(cmd, form);
    const intervalMSToUse = customInterval ?? throttleMSInterval;
    if (this.throttleRequestsController[hashRequest]) {
      const lastRequestTime = this.throttleRequestsController[hashRequest];
      const now = new Date().getTime();

      if (now - lastRequestTime < intervalMSToUse) {
        const timeRemaining = intervalMSToUse - (now - lastRequestTime);
        throw new ThrottleError("Throttle error", timeRemaining);
      }
    }
    this.throttleRequestsController[hashRequest] = new Date().getTime();
  }
}
