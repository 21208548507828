import { put, takeLatest, call, select } from 'redux-saga/effects';
import { FETCH_TRADE_HISTORY, HANDLE_TRADE_HISTORY_FROM_SOCKETS } from './actionTypes';

import { updateTradeHistory } from './actions';
import fetchTradeHistory from '../../services/cbtcService/tradeHistory';
import { addSnack, startKeepAliveCheck } from '../actions';

function* fetchTradeHistorySaga(action) {
  try {
    const market = yield select(state => state.market.selectedMarket)
    const tradeHistory = yield call(() => fetchTradeHistory(market))
    if (tradeHistory) {
      yield put(updateTradeHistory(tradeHistory));
      yield put(startKeepAliveCheck())
    }
  } catch (error) {
    console.error('fetchTradeHistorySaga', error);
    yield put(addSnack({ message: error.message }));
  }
}

function* handleTradeHistoryFromSocketsSaga({ tradeHistory } ) {
  yield put(updateTradeHistory(tradeHistory));
}

function* removeTradeHistorySaga() {
  try {
    yield put(updateTradeHistory([]));
  } catch (error) {
    console.error('removeTradeHistorySaga', error);

    yield put(addSnack({ message: error.message }));
  }
}

function* watchTradeHistory() {
  yield takeLatest(FETCH_TRADE_HISTORY, fetchTradeHistorySaga);
  yield takeLatest('REMOVE_CREDENTIALS', removeTradeHistorySaga);
  yield takeLatest(HANDLE_TRADE_HISTORY_FROM_SOCKETS, handleTradeHistoryFromSocketsSaga);
}

export default watchTradeHistory;

