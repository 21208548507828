import React from "react";
import { Field, } from "redux-form";
import CustomPinInput from "./components/CustomPinInput";


const PinField = (props) => {
  return (
    <Field
      {...props}
      component={CustomPinInput}
    />
  );
};



export default PinField;
