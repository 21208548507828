import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import NumberFormat from 'react-number-format';
import InputField from '../Forms/TextField';
import { cpfCnpjMask } from '../../utils/NumberFormat/cpfCnpjMask';

const DateMaskFormatInput = props => {
  const {
    inputRef, onChange, onBlur, value, ...other
  } = props;

  return (
    <NumberFormat
      {...other}
      value={value}
      ref={inputRef}
      onValueChange={values => {
        onChange(R.isNil(values.value) ? '' : values.value);
      }}
      onBlur={_event => {
        onBlur(value);
      }}
      format={cpfCnpjMask}
      type="tel"
    />
  );
};

DateMaskFormatInput.defaultProps = {
  value: null,
};

DateMaskFormatInput.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  inputRef: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
};

const DateField = ({ InputProps, ...props }) => {
  return (
    <InputField
      {...props}
      InputProps={{
        ...InputProps,
        inputComponent: DateMaskFormatInput,
      }}
    />
  );
};

DateField.defaultProps = {
  InputProps: {},
};

DateField.propTypes = {
  InputProps: PropTypes.objectOf(PropTypes.any),
};

export default DateField;
