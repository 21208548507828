import React, { useState } from "react";

import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import ExpressTradeForm from "../../components/ExpressTradeForm/ExpressTradeForm";
import UnavailableFeatureDialog from "../../../../components/UnavailableFeatureDialog";
import ConfirmTransactionExpressDialog from "../../../../layout/Dialogs/ConfirmTransactionExpressDialog";
import { styles } from "./styles";
import { translate } from "react-i18next";
import { compose } from "recompose";
import { connect } from "react-redux";
import ExecutedOrders from "../../../Trade/ExecutedOrders";
import * as R from "ramda";
import SwipeableViewsMobile from "../../../../components/SwipeableViewsMobile/SwipeableViewsMobile";

const mapStateToProps = (state) => ({
  market: state.market.selectedMarket,
  demoAccount: state.credentials.demoAccount,
  markets: state.currencies.markets,
});

const ExpressTradeCard = (props) => {
  const [showWarningDemoAccount, setShowWarningDemoAccount] = useState(false);
  const [showConfirmTransaction, setShowConfirmTransaction] = useState(false);
  const [remainingTime, setRemainingTime] = useState();

  const { classes, market, demoAccount, orderType, markets } = props;
  const hasMarkets = !R.isEmpty(markets);
  return (
    <>
      <SwipeableViewsMobile steps={2} direction={"rtl"} initialTabIndex={0}>
        <div className={classes.expressTrade}>
          <Grid container className={classes.transactionContainer}>
            <Grid justify="center" alignItems="center" item xs={12} md={12}>
              <Card
                className={demoAccount ? classes.cardFade : classes.card}
                onMouseEnter={
                  demoAccount
                    ? (event) => setShowWarningDemoAccount(true)
                    : null
                }
                onMouseLeave={
                  demoAccount
                    ? (event) => setShowWarningDemoAccount(false)
                    : null
                }
              >
                {demoAccount && showWarningDemoAccount && (
                  <UnavailableFeatureDialog
                    featureType={"tradeExpress"}
                    cardPlacement={classes.cardPlacement}
                  />
                )}
                <ExpressTradeForm
                  isDemoAccount={demoAccount}
                  key={market}
                  showConfirmTransaction={showConfirmTransaction}
                  confirmTransaction={() =>
                    setShowConfirmTransaction((value) => !value)
                  }
                  setRemainingTime={setRemainingTime}
                  orderType={orderType.toUpperCase()}
                />
              </Card>
            </Grid>
          </Grid>
          {showConfirmTransaction && (
            <ConfirmTransactionExpressDialog
              open={showConfirmTransaction}
              handleClose={() => setShowConfirmTransaction((value) => !value)}
              orderType={orderType.toUpperCase()}
              remainingTime={remainingTime}
            />
          )}
        </div>
        {hasMarkets && <ExecutedOrders onlyMineOrders />}
      </SwipeableViewsMobile>
    </>
  );
};

const enhance = compose(
  withStyles(styles),
  translate(),
  connect(mapStateToProps, null)
);

export default enhance(ExpressTradeCard);
