import React from 'react';
import PropTypes from 'prop-types';
import { compose, pure } from 'recompose';

import { withStyles } from '@material-ui/core/styles';

import ListItemText from '@material-ui/core/ListItemText';


const styles = theme => ({
  content: {
    color: theme.palette.grey[500],
    fontSize: 15,
    fontWeight: 500,
  },
  activeContent: {
    color: theme.palette.secondary.main,
    fontSize: 15,
    fontWeight: 500
  },
});

const DrawerLinkText = ({
  primary,
  classes,
  isActiveItem,
  // bottonNavigation = false,
}) => {
  const contentClassName = isActiveItem ? classes.activeContent : classes.content;
  return (
    <ListItemText
      classes={{ primary: contentClassName }}
      primary={primary}
    />
  );
};


DrawerLinkText.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

const enhance = compose(
  // withRouter,
  // translate(),
  pure,
  withStyles(styles),
);

export default enhance(DrawerLinkText);
