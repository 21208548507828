import * as R from 'ramda';
import { FETCH_USER_CARDS_SUCCEED, REMOVE_CARDS, FETCH_CASHBACK_INFO_SUCCEED, FETCH_CASHBACK_SUCCEED, FETCH_CARD_LIMITS_SUCCEED } from './actionsTypes';

const initialState = {
  plastic: null,
  virtual: null,
  hasCard: false,
  loading: true,
  cardLimits: null,
  cashback: [],
  cashbackInfo: {
    isLoading: true,
    user_id: null,
    card_volume: null,
    total_refs: null,
    total_activated_refs: null,
    activated_refs_90d: null,
    activated_refs_waitlist: null,
    cashback_percentage: null,
    cashback_composition: {
      card_volume: null,
      first_month: null,
      mgm: null,
      waitlist: null,
      total: null,
      marketing_event: null,
    },
    next_card_volume_level: {
      target_volume: null,
      missing_volume: null,
      next_percentage: null,
    },
    next_mgm_level: {
      target_references: null,
      missing_references: null,
      next_percentage: null,
    },
    last_update: null,
  },
};

const updateCard = (state, {response}) => {
  const data = response.data;
  if (data.length) {
    const plastic = data.findLast((card) => card.type === 'PLASTIC' && card.status !== 'CANCELED');
    const virtual = data.findLast((card) => card.type === 'VIRTUAL' && card.status !== 'CANCELED');
  
    return R.merge(state, {
      plastic: plastic,
      virtual: virtual,
      hasCard: true,
      loading: false,
    });
  } return R.merge(state, {
    loading: false,
  });
};

const updateCardLimits = (state, {response}) => {
  const data = response.data;
  return R.merge(state, {
    cardLimits: data,
  });
};

const removeCards = state => {
  return R.merge(state, {
    plastic:null,
    virtual:null,
    hasCard:false,
    loading: true,
  });
};

const updateCashback = (state, {response}) => {
  const data = response.data;
  return R.merge(state, {
    cashback: data,
  });
};

const updateCashbackInfo = (state, {response}) => {
  let data = response.data;
  if (data && data.cashback_percentage) {
    data.cashback_percentage = Number(data.cashback_percentage) > 10 ? "10" : data.cashback_percentage;
  }
  data['isLoading'] = false;
  return R.merge(state, {
    cashbackInfo: data,
  });
};


const cardsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_USER_CARDS_SUCCEED:
      return  updateCard(state, action)
    case REMOVE_CARDS:
      return removeCards(state, action);
    case FETCH_CASHBACK_SUCCEED:
      return updateCashback(state, action);
    case FETCH_CASHBACK_INFO_SUCCEED:
      return updateCashbackInfo(state, action);
    case FETCH_CARD_LIMITS_SUCCEED:
      return updateCardLimits(state, action);

    default:
      return state;
  }
};

export default cardsReducer;
