import { compose, lifecycle } from 'recompose';

import { connect } from 'react-redux';
import { translate } from 'react-i18next';

import SimpleSchema from 'simpl-schema';
import { reduxForm } from 'redux-form';
import { withRouter } from 'react-router-dom';
import { EmailSchema, TaxIdSchema } from '../../../utils/Schemas';
import validateDoc from '../../../components/Forms/asyncValidate';
import { valueToMd5 } from '../../../utils/valueToMd5';
import { addDialog } from '../../../redux/actions';
import resetPasswordService from '../../../services/cbtcService/resetPassword';
import ResetPasswordForm from './ResetPassword';
import {SUPPORT_ADDRESS} from "../../../config";
import {withGoogleReCaptcha} from 'react-google-recaptcha-v3';

const ResetPasswordSchema = new SimpleSchema({
  email: EmailSchema,
  taxId: TaxIdSchema
});

const validate = values => validateDoc({ values, schema: ResetPasswordSchema });

const mapDispatchToProps = dispatch => ({
  addMustReloadDialog: () => dispatch(
    addDialog({
      title: 'CREDENTIALS_GOT_ANY_USER',
      availableChoices: [
        {
          label: 'common.reloadPage',
          actionToTake: 'reloadPage',
          color: 'secondary',
          variant: 'raised',
        },
      ],
      actionsToTake: {
        reloadPage: () => window.location.reload(false)
      }
    })),
    addAlertMessageDialog: () => dispatch(
      addDialog({
        title: 'ALERT_RESET',
        availableChoices: [
          {
            label: 'common.back',
            actionToTake: 'relocationPage',
            color: 'secondary',
            variant: 'raised',
          },{
            label: 'common.understood',
            actionToTake: '',
            color: 'secondary',
            variant: 'raised',
          },
        ],
        actionsToTake: {
          relocationPage: () => window.location.href = '/login'
        }
      })),
  onSubmitFail: (err, d, { message_cod }) => {
    if (message_cod === 'ERROR_SENDING_EMAIL'){
      return dispatch(addDialog({
        title: message_cod, // eslint-disable-line camelcase
        availableChoices: [
          {
            label: 'CONTACT_SUPPORT',
            actionToTake: '',
            component: 'a',
            href: SUPPORT_ADDRESS,
            target: '_blank',
            color: 'secondary',
            variant: 'raised',
          },{
            label: 'common.close',
            actionToTake: '',
            color: 'secondary',
            variant: 'raised',
          },
        ],
      }));
    }
    return dispatch(addDialog({ // eslint-disable-line camelcase
      title: message_cod, // eslint-disable-line camelcase
      availableChoices: [
        {
          label: 'common.close',
          actionToTake: '',
          color: 'secondary',
          variant: 'raised',
        },
      ],
    }))
  },
  // onSubmitSuccess: ({ message_cod }) => {
  //   return (dispatch(addDialog({ // eslint-disable-line camelcase
  //   title: 'message_cod', // eslint-disable-line camelcase
  //   availableChoices: [
  //     {
  //       label: 'common.close',
  //       actionToTake: '',
  //       color: 'secondary',
  //       variant: 'raised',
  //     },
  //   ],
  // })))},
});

const enhanceForm = compose(
  translate(),
  withRouter,
  connect(null, mapDispatchToProps),
  lifecycle({
    componentDidMount() {
      this.props.addAlertMessageDialog();
    }
  }),
  withGoogleReCaptcha,
  reduxForm({
    form: 'resetPasswordForm',
    validate,
    onSubmit: async (values, dispatch, props) => {
      const { addMustReloadDialog, history } = props;
      const { executeRecaptcha } = props.googleReCaptchaProps;
      try{
        const taxid_md5 = valueToMd5(values.taxId);
        const captcha = await executeRecaptcha("resetPassword");
        const formValues =  {email: values.email, taxid_md5, captcha};

        const response = await resetPasswordService(formValues);
        if (response.success){
          const nextLocation = {
            pathname: '/recoverPassword',
            state: { email: values.email, taxId: values.taxId },
          };
          history.push(nextLocation);
          return response;
        }
      }catch (e) {
        console.error(e);
        addMustReloadDialog();
      }
    },
  }),
);

export default enhanceForm(ResetPasswordForm);
