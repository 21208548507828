import { put, takeLatest } from 'redux-saga/effects';
import { SET_SELECTED_MARKET } from './actionTypes';

import {
  fetchTicker,
  fetchOrdersRequested,
  fetchExecutedOrdersRequested,
  fetchTradeHistory,
} from '../actions';

import { setStartUpdatingData } from './actions';

function* setSelectedMarketSaga({ isInitialLoad = false}) {
  yield put(setStartUpdatingData());
  yield put(fetchTicker());
  yield put(fetchExecutedOrdersRequested());
  if (!isInitialLoad) {
    yield put(fetchTradeHistory());
    yield put(fetchOrdersRequested());
  }

}

function* watchMarketSaga() {
  yield takeLatest(SET_SELECTED_MARKET, setSelectedMarketSaga);
}

export default watchMarketSaga;
