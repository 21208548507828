import {
    compose, lifecycle, withProps
  } from 'recompose';
  import { connect } from 'react-redux';
  import { fetchCashback, fetchCashbackInfo, fetchReferrals, fetchCardLimits } from '../../redux/actions';

  import MGM from './MGM';
import { translate } from 'react-i18next';

const checkUserAcceptMgmTerm = (profile, referrals) => {
  let acceptedTerm = "notAcceptedTerm";
  const acceptTermsObjectLocalStorage = localStorage.getItem("mgmTermAccepted");
  const acceptTermsObject = acceptTermsObjectLocalStorage
    ? JSON.parse(acceptTermsObjectLocalStorage)
    : {};
  const userId = profile.id;

  const acceptedLocalStorage = acceptTermsObject[userId];
  const hasReferrals =
    referrals.length > 0;

  if (acceptedLocalStorage || hasReferrals) {
    acceptedTerm = "indications";
  }
  return acceptedTerm;
};
  
  const mapStateToProps = (state) => ({
    cashback: state.user.cards.cashback,
    cashbackInfo: state.user.cards.cashbackInfo,
    cardLimits : state.user.cards.cardLimits,
    hasCard: state.user.cards.hasCard,
    ticker: state.ticker,
    referrals: state.user.mgm.referrals,
    profile: state.user.profile,
    isCardsLoading: state.user.cards.loading,
    currencies: state.currencies.currencies
  });
  
  const mapDispatchToProps = (dispatch) => ({
    loadCashback: () => dispatch(fetchCashback()),
    loadCashbackInfo: () => dispatch(fetchCashbackInfo()),
    loadReferrals: () => dispatch(fetchReferrals()),
    loadCardLimits: () => dispatch(fetchCardLimits()),

  });

  const enhance = compose(
    translate(),
    connect(mapStateToProps, mapDispatchToProps),
    withProps(
      ({
        match: {
          params: { subRoute = 'indications' },
        },
        history: { replace },
      }) => ({
        subRoute,
        handleChange: (e, value) => replace(`/mgm/${value}`),
        handleRedirect: () => {
          replace(`/express-trade/buy`);
        },
      })
    ),

    lifecycle({
      componentDidMount() {
        const {
          loadCashback,
          loadCashbackInfo,
          loadReferrals,
          loadCardLimits,
        } = this.props;
        loadCashback();
        loadCashbackInfo();
        loadReferrals();
        loadCardLimits();
      },
      componentDidUpdate() {
        const {
          handleRedirect,
          profile,
          referrals,
        } = this.props;

        if (profile.id) {
          if (checkUserAcceptMgmTerm(profile, referrals) === "notAcceptedTerm") handleRedirect();
         }
      },
    })
  );
  
  export default enhance(MGM);
  