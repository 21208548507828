import React from 'react';
import PropTypes from 'prop-types';
import InputField from '../Forms/TextField';
import DateMaskFormatInput from '../FormsV2/DatePickerField/DateMaskInput';

const DateBasicField = ({ InputProps, ...props }) => {
  return (
    <InputField
      {...props}
      InputProps={{
        ...InputProps,
        inputComponent: DateMaskFormatInput,
      }}
    />
  );
};

DateBasicField.defaultProps = {
  InputProps: {},
};

DateBasicField.propTypes = {
  InputProps: PropTypes.objectOf(PropTypes.any),
};

export default DateBasicField;
