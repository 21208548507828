import React from "react";
import { Grid, Typography } from "@material-ui/core";
import Card from '@material-ui/core/Card'
import appMockupURL from '../../../assets/app-mockup.png'
import QRCode from 'qrcode.react';
import { translate } from 'react-i18next';

import {
    compose,
  } from "recompose";
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
    generalContainer: {
        position: 'relative',
        height: '135px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'end',
        margin: '10px auto 20px auto',
        width: '100%',
        maxWidth: 600,
        '@media screen and (max-width: 600px)': {
            width: '95%'
          },
    },
    card: {
        width: '100%',
        height: '120px',
    },
    container: {
      padding: '0 30px',
      height: '100%',
      width: '100%',
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      justifyContent: 'space-between',
      '@media screen and (max-width: 350px)': {
        padding: '0 10px'
      },
      
    },
    title: {
        fontSize: '20px',
        lineHeight: '23.44px',
        fontWeight: 500,
        color: '#101010'
    },
    buttonLink: {
        height: '30px',
        padding: '7px 14px',
        backgroundColor: '#101010',
        borderRadius: '5px',
        textDecorationLine: 'none',
        color: theme.palette.background.paper,
        fontWeight: 500,
    },
    ctaContainer: {
        display: 'flex',
        flexDirection:'column',
        gap: '15px'
    },
    ctaButtonContainer: {
        fontSize: '14px',
    },
    callToAction: {
        color: '#101010'
    },
    qrCodeContainer: {
        width: '80px',
        height: '80px',
        borderRadius: '5px',
        backgroundColor: theme.palette.background.paper,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        '@media screen and (max-width: 430px)': {
            display: 'none'
          },

    },
    imageMockup: {
        backgroundImage: `url(${appMockupURL})`,
        backgroundSize: 'cover',
        position: 'absolute',
        width: '167px',
        height: '135px',
        top:0,
        zIndex: 10,
        left: '53.115727002967%',
        '@media screen and (max-width: 600px)': {
            display: 'none'
          },
    }
  })

function AppBanner(props) {
    const { classes, t } = props;
    return ( <div className={classes.generalContainer}><Card style={{backgroundColor: '#F7B733'}} className={classes.card}>
        <Grid
        container
        className={classes.container}
        alignItems='center'
        >
            <Grid item className={classes.ctaContainer}>
                <Grid >
                    <Typography className={classes.title}>{t('screens.cards.appReinforcement.title')}</Typography>
                </Grid>
                <Grid spacing={8} className={classes.ctaButtonContainer} container alignItems="center">
                    <Grid item>
                    <Typography className={classes.callToAction}>{t('screens.cards.appReinforcement.callToAction')}</Typography>
                    </Grid>
                    <Grid item>
                        <a className={classes.buttonLink} href="https://tecle.me/bitybank@apple-store" target="_blank">{t('common.appStore')}</a>
                    </Grid>
                    <Grid item>
                        <a className={classes.buttonLink} href="https://tecle.me/bitybank@google-play" target="_blank">{t('common.googlePlay')}</a>
                    </Grid>
                </Grid>
            </Grid>
            <Grid className={classes.qrCodeContainer} item>
            <QRCode size={62} value={'https://link.bity.com.br/app'} aria-label={t("imageAlts.qrCodeAppBity")}/>
            </Grid>
        </Grid>
        <div className={classes.imageMockup}/>
        </Card>
        </div> );
}

const enhance = compose(
    translate(),
    withStyles(styles),
  );
  
  export default enhance(AppBanner);