import { FETCH_FEES_REQUESTED } from './actionTypes';

const fetchFeesRequested = () => ({
  type: FETCH_FEES_REQUESTED,
});

export {
  fetchFeesRequested,
};

export default {
  fetchFeesRequested,
};
