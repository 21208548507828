import { Typography } from "@material-ui/core";
import React from "react";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { translate } from "react-i18next";
import SvgIcon from "../../../../components/SvgIcon/SvgIcon";
import PropTypes from "prop-types";
import classNames from "classnames";

const styles = (theme) => ({
  valueStyle: {
    position: "absolute",
    color: "#101010",
    textAlign: "center",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "normal",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    top: "-8px",
    left: "0",
  },
  missionIndicatorStyle: {
    top: "-1px",
    fontSize: "14px",
  },
  cashbackIndicator: {
    position: "relative",
  },
});

const CashbackIndicator = (props) => {
  const { classes, size, content, bgColor, isContentText, isMissionIndicator } =
    props;
  const indicatorStyles = {
    transform: `scale(${size})`,
    filter: " drop-shadow(0px 5px 10px rgba(0, 0, 0, 0.10))",
  };

  const wrapperClass = isMissionIndicator ? classNames(classes.cashbackIndicator, classes.missionIndicatorStyle) : classes.cashbackIndicator;

  return (
    <div
      className={wrapperClass}
    >
      <div style={indicatorStyles}>
        {!isMissionIndicator ? (
          <SvgIcon icon={"CashbackIcon"} color={bgColor} />
        ) : (
          <SvgIcon icon={"MissionsHexagonal"} color={bgColor} />
        )}
      </div>
      {isContentText ? (
        <Typography
          className={`${classes.valueStyle} ${isMissionIndicator ? classes.missionIndicatorStyle : null
            }`}
        >
          {content}
        </Typography>
      ) : (
        content
      )}
    </div>
  );
};

const enhance = compose(withStyles(styles), translate());

CashbackIndicator.propTypes = {
  bgColor: PropTypes.string,
  content: PropTypes.node,
  size: PropTypes.number,
  isContentText: PropTypes.bool,
  isMissionIndicator: PropTypes.bool,
};

CashbackIndicator.defaultProps = {
  bgColor: "rgba(247, 183, 51, 1)",
  content: "",
  size: 1,
  isContentText: true,
  isMissionIndicator: false,
};

export default enhance(CashbackIndicator);
