import { compose, pure } from 'recompose';
import { connect } from 'react-redux';
import AppDrawer from './AppDrawer';

const mapStateToProps = ({ credentials: { token, loadingStore, demoAccount }}) => ({
  token,
  loadingStore,
  demoAccount
});

const enhance = compose(
  pure,
  connect(mapStateToProps),
);

export default enhance(AppDrawer);
