import isNilOrEmpty from "../../../utils/isNilOrEmpty";

export const INCOME_OPTION_VALUES = {
  LEVEL_1: 5000, // 5k
  LEVEL_2: 10000, // 10k
  LEVEL_3: 15000, // 15k
  LEVEL_4: 20000, // 20k
  LEVEL_5: 30000, // 30k
};

export const WEALTH_OPTION_VALUES = {
  LEVEL_1: 100000, // 100k
  LEVEL_2: 500000, // 500k
  LEVEL_3: 1000000, // 1m
  LEVEL_4: 5000000, // 5m
  LEVEL_5: 10000000, // 10m
};

export const declarationTypes = {
  FINANCIAL: "FINANCIAL",
  PERSONAL: "PERSONAL",
  PROFESSIONAL: "PROFESSIONAL",
  SUITABILITY: "SUITABILITY",
  AGREEMENT: "AGREEMENT",
};

export const pendingDocTypes = {
  PEP_DECLARATION: "PEP_DECLARATION",
  FINANCIAL_DECLARATION: "FINANCIAL_DECLARATION",
  USER_AGREEMENT: "USER_AGREEMENT",
};

export const getDeclarationTypeByDocPending = (docPendings) => {
  let declarationType = null;
  if (docPendings.includes("PEP_DECLARATION")) {
    declarationType = declarationTypes.PERSONAL;
  } else if (docPendings.includes("FINANCIAL_DECLARATION")) {
    declarationType = declarationTypes.FINANCIAL;
  } else if (docPendings.includes("USER_AGREEMENT")) {
    declarationType = declarationTypes.AGREEMENT;
  }
  return declarationType;
};

export const getDeclarationTypesList = (docPendings) => {
  if (isNilOrEmpty(docPendings)) return [];

  const declarationTypesList = [];
  if (docPendings.includes("PEP_DECLARATION")) {
    declarationTypesList.push(pendingDocTypes.PEP_DECLARATION);
  }
  if (docPendings.includes("FINANCIAL_DECLARATION")) {
    declarationTypesList.push(pendingDocTypes.FINANCIAL_DECLARATION);
  }
  if (docPendings.includes("USER_AGREEMENT")) {
    declarationTypesList.push(pendingDocTypes.USER_AGREEMENT);
  }
  return declarationTypesList;
};
