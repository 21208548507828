import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';

import { Link } from 'react-router-dom';
import { compose, pure } from 'recompose';

const styles = theme => {
  return {
    root: {
      color: theme.palette.grey[500],
      '&$selected': {
        color: theme.palette.secondary.main,
      },
    },
    selected: { color: theme.palette.secondary.main },
  };
};

const NavigationAction = ({
  to, classes, label, icon, ...rest
}) =>
  (<BottomNavigationAction
    classes={{
      root: classes.root,
      selected: classes.selected,
    }}
    {...to && {
      component: Link,
      to,
    }}
    label={label}
    icon={icon}
    {...rest}
  />);

const enhance = compose(
  pure,
  withStyles(styles),
);

NavigationAction.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default enhance(NavigationAction);
