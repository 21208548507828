import { connect } from 'react-redux';
import { withFormik, yupToFormErrors } from 'formik';
import { compose, withProps, withHandlers } from 'recompose';
import { translate } from 'react-i18next';

import { withRouter } from 'react-router-dom';
import transactionsActions from '../../../redux/transactions/actions';
import TradeOrderForm from './TradeOrderForm';
import TradeOrderSchema from './TradeOrderSchema';
import {toFixedNoRound} from '../../../utils/toFixedNoRound.js'
import { getPrice, getLastPrices, applyTotalValueMargin } from './marketPriceCalculator';
import { getDefaultLimit } from './constants';
import { addDialog } from '../../../redux/layout/dialog/actions';
import { toDecimalPrecision, cryptoRoundDown } from '../../../utils/NumberFormat/roundValues';
import { MarketInfo } from '../../../models/MarketInfo';

const ORDER_TYPES = {
  BUY: 'BUY',
  SELL: 'SELL',
  STOPLOSS: 'STOPLOSS',
};


const extractMinAmount = (marketSymbol, marketConfig) => {

  if (marketConfig) {
    const marketInfo = new MarketInfo(marketConfig);
    return marketInfo.minBaseAmount;
  }
  return getDefaultLimit(marketSymbol);
};

const shouldUseProgrammed = (orderType, marketLastPrice, values, market, markets) => {
  const exchangeCurrencies = Object.values(markets).filter(
    (market) => !market.is_marketplace
  ).map((market) => market.base);

  if (orderType === ORDER_TYPES.SELL){
    if (exchangeCurrencies.includes(market))
      return (marketLastPrice - values.price) >= marketLastPrice * 0.04;
    return (marketLastPrice - values.price) >= marketLastPrice * 0.02;
  }
  return false;
}

const withTradeOrderFormHandlers = compose(
  withFormik({
    enableReinitialize: false,
    validate: ((values, props) => {
      const currency = props.currency;
      const symbol = currency.symbol;
      const marketConfig = props.markets[props.market] || props.markets["BTC"]
      const minAmountLimit = extractMinAmount(symbol, marketConfig);
      const balance = props.orderType === 'BUY'
        ? props.balance.BRL
        : props.balance[symbol];
      return TradeOrderSchema.validate(values, {
        abortEarly: false,
        context: {
          balance,
          symbol,
          minAmountLimit,
        },
      }).catch((err) => {
        throw yupToFormErrors(err);
      });
    }),
    isInitialValid: true,
    mapPropsToValues: ({
      selectedOrder, marketPrice, orderType, balance,
    }) => ({
      amount: selectedOrder && selectedOrder.order
        ? selectedOrder.order.amount
        : undefined,
      totalValue: selectedOrder && selectedOrder.order
        ? selectedOrder.order.amount * selectedOrder.order.price
        : undefined,
      price: selectedOrder && selectedOrder.order
        ? selectedOrder.order.price
        : marketPrice,
      orderType,
      isMarketPriceSelected: orderType !== ORDER_TYPES.STOPLOSS,
      amountPercentage: undefined,
      totalValuePercentage: undefined,
    }),

    handleSubmit: (values, formikBag) => {
      const {
        orderType, setOrderBookSellShowAllSelected, ticker, warningShouldUseProgrammed, currency, markets
      } = formikBag.props;
      const { isMarketPriceSelected } = values;
      const symbol = currency.symbol;
      const marketLastPrice = ticker[symbol] ? ticker[symbol].last || 0 : 0;
      const totalValue = isMarketPriceSelected
        ? applyTotalValueMargin(values.totalValue, orderType)
        : values.totalValue;
      const orderTypeIsSell = orderType === ORDER_TYPES.STOPLOSS || orderType === ORDER_TYPES.SELL;
      const type = orderTypeIsSell ? ORDER_TYPES.SELL : ORDER_TYPES.BUY;

      const form = {
        type,
        amount: cryptoRoundDown(values.amount),
        price: values.price,
        volume: toDecimalPrecision(totalValue, 2),
        market: formikBag.props.market,
        programmed: orderType === ORDER_TYPES.STOPLOSS,
        limited: !isMarketPriceSelected,
      };

      if (shouldUseProgrammed(orderType, marketLastPrice, values, symbol, markets)) {
        warningShouldUseProgrammed(form, formikBag);
      } else {
        formikBag.props.createOrder(form);
        formikBag.resetForm();
        const { location, history } = formikBag.props;
        if (
          location.pathname === "/buy" ||
          location.pathname === "/sell" ||
          location.pathname === "/stoploss"
        ) {
          const nextLocation = {
            pathname: "/trade",
            state: { from: location.pathname },
          };
          history.push(nextLocation);
        }
        if (
          orderType === ORDER_TYPES.STOPLOSS &&
          setOrderBookSellShowAllSelected
        )
          setOrderBookSellShowAllSelected(false);
      }
    },
  }),

  withHandlers({  
    handleAmountChange: props => amount => {
      const {
        setFieldValue,
      } = props;

      const actualPrice = getPrice('AMOUNTBTC', amount)(props);
      setFieldValue('price', actualPrice);
      setFieldValue('amount', amount);
      if (amount !== undefined && actualPrice !== undefined && actualPrice > 0) {
        setFieldValue('totalValue', amount * actualPrice);
      }
      setFieldValue('amountPercentage', undefined);
      setFieldValue('totalValuePercentage', undefined);
    },
    handleTotalValueChange: props => totalValue => {
      const {
        setFieldValue,
      } = props;

      const actualPrice = getPrice('TOTALVALUE', totalValue)(props);
      setFieldValue('price', actualPrice);
      setFieldValue('totalValue', totalValue);
      if (totalValue !== undefined && actualPrice !== undefined && actualPrice > 0) {
        setFieldValue('amount', totalValue / actualPrice);
      }
      setFieldValue('amountPercentage', undefined);
      setFieldValue('totalValuePercentage', undefined);
    },
    handlePriceChange: props => price => {
      const {
        setFieldValue,
        values: {
          amount,
        },
      } = props;

      setFieldValue('price', price);
      if (amount !== undefined && price !== undefined && price > 0) {
        setFieldValue('totalValue', price * amount);
      }
      setFieldValue('isMarketPriceSelected', false);
      setFieldValue('amountPercentage', undefined);
      setFieldValue('totalValuePercentage', undefined);
    },
    handleTotalValuePercentChange: props => percent => {
      const {
        setFieldValue,
        balance,
      } = props;
      const totalValue = balance.BRL * (percent / 100);
      const actualPrice = getPrice('TOTALVALUE', totalValue)(props);
      const amount = totalValue / actualPrice;

      // workaround to handle rounded up balance.
      // balance from state may be more than the
      // actual balance on the backend

      setFieldValue('totalValuePercentage', percent);
      setFieldValue('amount', amount);
      setFieldValue('totalValue', toFixedNoRound(totalValue, 2));
      setFieldValue('price', actualPrice);
    },
    handleAmountPercentChange: props => percent => {
      const {
        setFieldValue,
        balance,
        currency
      } = props;
      const amount = isNaN(balance[currency.symbol]) ? 0 : balance[currency.symbol] * (percent / 100);
      const unverifiedPrice = getPrice('AMOUNTBTC', amount)(props);
      const actualPrice =  isNaN(unverifiedPrice) ? 0 : unverifiedPrice;
      const totalValue = actualPrice * amount;

      setFieldValue('amountPercentage', percent);
      setFieldValue('price', actualPrice);
      setFieldValue('totalValue',  toFixedNoRound(totalValue, 2));
      setFieldValue('amount', amount);

    },
    setIsMarketPriceSelected: props => isSelected => {
      const {
        marketPrice,
        setFieldValue,
        values: {
          isMarketPriceSelected,
        },
      } = props;
      setFieldValue('isMarketPriceSelected', !isMarketPriceSelected);
      const shouldSetPriceToMarketPrice = !isMarketPriceSelected;
      if (shouldSetPriceToMarketPrice) {
        setFieldValue('price', marketPrice);
      }
    },
  }),
);

const mapStateToProps = state => ({
  selectedOrder: state.transactions.selectedOrder,
  balance: state.user.balance,
  buyOrdersSerialized: state.orders.buyOrdersSerialized,
  sellOrdersSerialized: state.orders.sellOrdersSerialized,
  market: state.market.selectedMarket,
  limits: state.user.limits,
  demoAccount: state.credentials.demoAccount,
  ticker: state.ticker,
});

const mapDispatchToProps = dispatch => ({
  createOrder: order => dispatch(transactionsActions.createOrder(order)),
  clearSelectedOrder: () => dispatch(transactionsActions.clearSelectedOrder()),
  warningCripto: (info) => {
    dispatch(
      addDialog({
        title: (info),
        availableChoices: [
          {
            label: 'common.understood',
            actionToTake: '',
            color: 'secondary',
            variant: 'raised',
          },
        ],
      })
    )},
  warningShouldUseProgrammed: (form, formikBag) => dispatch(addDialog({
    title: 'info.sellCoinTooCloseLastPrice',
    availableChoices: [
      {
        label: 'Vender agora',
        actionToTake: 'createOrder',
        color: 'secondary',
        variant: 'raised',
      },
      {
        label: 'Programar Venda',
        actionToTake: 'goToProgrammedOrder',
        color: 'secondary',
        variant: 'raised',
      },
    ],
    actionsToTake: {
      createOrder: () => {
        dispatch(transactionsActions.createOrder(form));
        formikBag.resetForm();
      },
      goToProgrammedOrder: () => transactionsActions.selectOrder('STOPLOSS', {
        amount: form.amount,
        price: form.price,
        totalValue: form.totalValue,
      }),
    },
  })),
});

const withPropsFromState = compose(
  connect(mapStateToProps, mapDispatchToProps),
);

export default compose(
  translate(),
  withRouter,
  withPropsFromState,
  withProps(props => {
    const {
      buyOrdersSerialized,
      sellOrdersSerialized,
      orderType,
      t,
      marketInfo,
      currency,
      markets
    } = props;
    const orders = {
      buyOrdersSerialized,
      sellOrdersSerialized,
    };
    let marketPrice = 0;
    const { lastAsk, lastBid } = getLastPrices(orders, marketPrice);
     marketPrice = orderType === ORDER_TYPES.BUY
      ? lastAsk
      : lastBid;

    const tokenName = currency.name;
    const submitTitle = t(`transactions.${orderType.toLowerCase()}.action`, { tokenName });
    return {
      marketInfo,
      currency,
      marketPrice,
      submitTitle,
      shouldDisplayTotalValuePercentageSwitch: props.orderType === 'BUY',
      shouldDisplayAmountPercentageSwitch: props.orderType === 'SELL' || props.orderType === ORDER_TYPES.STOPLOSS,
    };
  }),
  withTradeOrderFormHandlers,
)(TradeOrderForm);
