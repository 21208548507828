import { valueToMd5 } from "./valueToMd5"

export const getRequestHash = (cmd, form) => {
    const newForm = { ...form }
    delete newForm.auth_token
    const formString = JSON.stringify(newForm)
    const hashKey = `${cmd}+${formString}`
    const hashRequest = valueToMd5(hashKey)
    return hashRequest
}
export const openNewTab = (url) => {
    window.open(url, '_blank');
};
