import { getCurrencyBySymbol } from "../../models/Currency"

export const getInitialToCurrency = (fromSymbol, currencies) => {
    let toSymbol = "ETH";
    if (fromSymbol === "ETH") {
        toSymbol = "BTC";
    }
    return getCurrencyBySymbol(currencies, toSymbol);
}

export const getInitialToCurrencySymbol = (fromSymbol) => {
    if (fromSymbol === "ETH") {
        return "BTC";
    }
    return "ETH";
}