import React from 'react';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';

import Form from '../../../components/Forms/Form';
import SubmitButton from '../../../components/Forms/SubmitButton';
import FieldRow from '../../../components/Forms/FieldRow';
import PasswordField from '../../../components/Forms/PasswordField';
import TextField from '../../../components/Forms/TextField';
import { normalizePIN } from '../../../utils/NumberFormat/normalizers';

const RecoverPassword = (props) => {
  const {
    handleSubmit, submitting, invalid, t, logged,
  } = props;

  return (
    <Form {...{ handleSubmit }}>
      <FieldRow>
        <PasswordField
          required
          forbidShowPassword
          name="new_pass"
        />
      </FieldRow>
      <FieldRow>
        <PasswordField
          required
          forbidShowPassword
          name="repeatNewPass"
        />
      </FieldRow>
      <FieldRow>
        <TextField normalize={normalizePIN} name="emailToken" i18nScope="forms.emailToken" />
      </FieldRow>
      <FieldRow>
        <SubmitButton
          submitText={t('recoverPassword.submit')}
          disabled={submitting || invalid}
        />
      </FieldRow>
      {
        !logged && (
          <FieldRow>
            <Button
              color="secondary"
              variant="raised"
              component={Link}
              to="/login"
            >
              {t('screens.login.hasAccount')}
            </Button>
          </FieldRow>
        )}
    </Form>
  );
};

export default RecoverPassword;
