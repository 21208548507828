import { useState, useEffect } from 'react';
import { Socket } from 'phoenix';
import { SOCKET_URL } from '../config';


const useOrderbook = (market, sync, stop) => {
  const [orderBook, setOrderBook] = useState({ asks: [], bids: [], timestamp: '' });

  useEffect(() => {
    const socket = new Socket(`${SOCKET_URL}/orderbook/socket`);
    socket.connect();
    socket.onOpen(() => {
      console.log('CONNECTED SUCCESSFULLY');
      stop();
    });
    socket.onError(e => {
      console.log('Failed to connect to socket');
      sync();
    });

    const channel = socket.channel(`orderbook:${market}`, {});
    channel.join()
      .receive('ok', resp => { console.log('Joined successfully', resp); })
      .receive('error', resp => { console.log('Unable to join', resp); });

    channel.on('snapshot', payload => {
      setOrderBook({
        asks: payload.asks,
        bids: payload.bids,
        timestamp: payload.timestamp
      });
    });

    return () => socket.disconnect();
  }, [market]);

  return orderBook;
};

export default useOrderbook;
