import React from 'react';
import { Currency } from '../../../models/Currency';

import CurrencyFormat from '../../../components/Currency/CurrencyFormat';

const CurrencyTableDataContent = ({ extract, currency, ...rest }) => {
  const formatter = currency.formatter;

  const { className } = rest;

  const isBRL = (extract.currency === 'BRL' || extract.currency === 'CBRL' || extract.currency === 'BRZ' || extract.currency === 'REALT' || extract.currency === 'RAS');
  const value = isBRL && checkValue(rest.value)
    ? roundDown(rest.value)
    : rest.value;

  return (
    <span className={className}>
      <CurrencyFormat value={value} isFiat={currency.isFiat} formatter={formatter}/>
    </span>
  );
};

const checkValue = (value) => {
  return value.toString().includes('.');
};

const roundDown = (number) => {
  return Number(number.toString().slice(0, number.toString().lastIndexOf('.') + 3));
};


export default CurrencyTableDataContent;
