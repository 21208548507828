import { MgmIcon } from "./MgmIcon";
import { CryptoConverterIcon } from "./CryptoConverterIcon";
import { CashbackIcon } from "./CashbackIcon";
import { MissionsHexagonal } from "./MissionsHexagonal";

const icons = {
  MgmIcon,
  CryptoConverterIcon,
  CashbackIcon,
  MissionsHexagonal,
};

export default icons;
