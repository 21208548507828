import React, { useState } from "react";
import classNames from 'classnames';
import { connect } from "react-redux";
import {
  compose,
  withProps,
  withState,
  withHandlers,
  lifecycle,
} from "recompose";
import { translate } from "react-i18next";
import { Hidden } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Lock from "@material-ui/icons/Lock"
import LockOpen from "@material-ui/icons/LockOpen"
import { addSnack, fetchUserCards, uploadStatusCard } from "../../../redux/actions";
import PinForm from "./PinForm";

const styles = (theme) => ({
  container: {
    display: "flex",
    flex: 1,
    alignItems: "center",
    flexDirection: "column",
    maxWidth: "100%",
    marginTop: 3 * theme.spacing.unit,
    paddingLeft: 3 * theme.spacing.unit,
    paddingRight: 3 * theme.spacing.unit,
  },
  rowItems: {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
    width: "90%",
    marginBottom: "20px",
  },
  infoRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "90%",
  },
  infoColumn: {
    display: "flex",
    alignItems: "start",
    justifyContent: "space-between",
    width: "100%",
    flexDirection: "column",
  },
  infoLabel: {
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    color: "#757575",
    fontSize: "14px",
  },
  infoValue: {
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    fontWeight: "500",
  },
  borderLine: {
    borderTop: "2px solid #e0e0e0",
    width: "100%",
    margin: "40px 0",
  },
  themeTitle: {
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    textAlign: "left",
    fontWeight: "500",
    fontSize: "20px",
  },
  shippingCodeContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: 10,
    width: '100%',
  },
  widthFirstColumn: {
    width: "90%",
  },
  marginBottom: {
    marginBottom: 20,
  },
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    maxWidth: "100%",
    padding: 3 * theme.spacing.unit,
  },
  mobileColumn: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'start',
      gap: '20px'
    },
  },
  unlock: {
    backgroundColor: theme.palette.green.main,
    '&:hover': {
      backgroundColor: '#00695d',
    },
  },

  lock: {
    backgroundColor: theme.palette.red.light,
    '&:hover': {
      backgroundColor: '#d9574c',
    },
  },
});


const copyToClipBoard = async (value) => {
  try {
    await navigator.clipboard.writeText(value);
    return true;
  } catch (err) {
    console.error(err);
    return false;
  }
};

const CopyCode = ({ t, setCopied, shippingCode, label, addInfoWithSnack }) => {
  return (
    <Button
    variant="raised"
      onClick={() => {
        const isCopySuccess = copyToClipBoard(shippingCode);
        if (isCopySuccess) {
          setCopied(true);
          addInfoWithSnack('screens.cards.postalCodeCopied');
        }
      }}
      style={{borderRadius: '6px' ,backgroundColor: "orange", color: "white", margin: 10 }}
    >
      {t(label)}
    </Button>
  );
};

const BlockCardButton = ({ t, classes, label, blocked, setBlockStatus, id, setOpenForm }) => {
  return (
    <Button
      variant="raised"
      onClick={() => {
        if(blocked){
          setOpenForm(true);
          return;
        }
        setBlockStatus(!blocked, id, '');
      }}
      style={{borderRadius: '6px', color: "white", margin: 10 }}
      className={blocked ? classes.unlock : classes.lock}
    >
      <Hidden mdUp>
        {blocked ?  <LockOpen /> : <Lock /> }
      </Hidden>
      <Hidden smDown>
        {t(label)}
      </Hidden>
    </Button>
  );
};



const CardData = (props) => {
  const { cardType, cardData, classes, copied, t, setOpenForm, openForm} = props;
  const isPhysical = cardType === "plastic";
  const isNewOrEmbossing = cardData && (cardData.status === "EMBOSSING" || cardData.status === "NEW");
  
  return (
    <div className={classes.root}>
      <div className={classes.rowItems}>
        <p className={classes.themeTitle}>
          {t("screens.cards.basicData")}
        </p>

          {!isNewOrEmbossing && <BlockCardButton
            label={cardData && cardData.blocked ? "screens.cards.unlockCard" : "screens.cards.lockCard"}
            blocked={cardData && cardData.blocked}
            id={cardData && cardData.id}
            {...props}
          />}
        
      </div>
      <div className={classes.rowItems}>
        <div className={classes.infoColumn}>
          <div className={classes.infoColumn}>
            <span className={classes.infoLabel}>
              {t("screens.cards.holderName")}
            </span>
            <span className={classes.infoValue}>{cardData && cardData.holder_name}</span>
          </div>
        </div>
      </div>

      <div className={classNames(classes.rowItems, classes.mobileColumn)}>
        <div className={classNames(classes.infoColumn, classes.widthFirstColumn)}>
          <span className={classes.infoLabel}>{t("screens.cards.number")}</span>
          <span className={classes.infoValue}>{cardData && `**** **** **** ${cardData.number}`}</span>
        </div>

        <div className={classes.infoColumn}>
          <span className={classes.infoLabel}>{t("screens.cards.type")}</span>
          <span className={classes.infoValue}>
            {cardData && cardData.type === "VIRTUAL"
              ? t("screens.cards.virtualCard")
              : t("screens.cards.physicalCard")}
          </span>
        </div>
      </div>

      <div className={classes.rowItems}>
        <div className={classNames(classes.infoColumn, classes.widthFirstColumn)}>
          <span className={classes.infoLabel}>{t("screens.cards.status")}</span>
          <span className={classes.infoValue}>
            {t(`screens.cards.statusType.${cardData && cardData.status.toLowerCase()}`)}
          </span>
        </div>

        <div className={classes.infoColumn}>
          <span className={classes.infoLabel}>
            {t("screens.cards.blocked")}
          </span>
          <span className={classes.infoValue}>
            {cardData && cardData.blocked ? t("screens.cards.yes") : t("screens.cards.no")}
          </span>
        </div>
      </div>

      <div className={classes.rowItems}>
        <div className={classNames(classes.infoColumn, classes.widthFirstColumn)}>
          <span className={classes.infoLabel}>
            {t("screens.cards.allowInternational")}
          </span>
          <span className={classes.infoValue}>
            {cardData && cardData.allow_international
              ? t("screens.cards.active")
              : t("screens.cards.inactive")}
          </span>
        </div>

        <div className={classes.infoColumn}>
          {isPhysical && (
            <React.Fragment>
              <span className={classes.infoLabel}>
                {t("screens.cards.contactless")}
              </span>
              <span className={classes.infoValue}>
                {cardData && cardData.contactless
                  ? t("screens.cards.active")
                  : t("screens.cards.inactive")}
              </span>
            </React.Fragment>
          )}
        </div>
      </div>

      {isNewOrEmbossing && (
        <React.Fragment>
          <span className={classes.borderLine}></span>
          <div className={classes.rowItems}>
            <p className={classes.themeTitle}>
              {t("screens.cards.shippingAddress.title")}
            </p>
          </div>
          <div className={classes.rowItems}>
            <div className={classes.infoColumn}>
              <div className={classes.infoColumn}>
                <span className={classes.infoLabel}>
                  {t("screens.cards.shippingAddress.street")}
                </span>
                <span className={classes.infoValue}>
                  {cardData && cardData.shipment.address.street}
                </span>
              </div>
            </div>
          </div>

          <div className={classes.rowItems}>
            <div className={classNames(classes.infoColumn, classes.widthFirstColumn)}>
              <div className={classes.infoColumn}>
                <span className={classes.infoLabel}>
                  {t("screens.cards.shippingAddress.number")}
                </span>
                <span className={classes.infoValue}>
                  {cardData && cardData.shipment.address.number}
                </span>
              </div>
            </div>

            <div className={classes.infoColumn}>
              <div className={classes.infoColumn}>
                <span className={classes.infoLabel}>
                  {t("screens.cards.shippingAddress.neighborhood")}
                </span>
                <span className={classes.infoValue}>
                  {cardData && cardData.shipment.address.neighborhood}
                </span>
              </div>
            </div>
          </div>

          <div className={classes.rowItems}>
            <div className={classNames(classes.infoColumn, classes.widthFirstColumn)}>
              <div className={classes.infoColumn}>
                <span className={classes.infoLabel}>
                  {t("screens.cards.shippingAddress.city")}
                </span>
                <span className={classes.infoValue}>
                  {cardData && cardData.shipment.address.city}
                </span>
              </div>
            </div>

            <div className={classes.infoColumn}>
              <div className={classes.infoColumn}>
                <span className={classes.infoLabel}>
                  {t("screens.cards.shippingAddress.state")}
                </span>
                <span className={classes.infoValue}>
                  {cardData && cardData.shipment.address.state}
                </span>
              </div>
            </div>
          </div>

          {cardData.shipment.address.complement &&
            cardData.shipment.address.complement.trim() !== "" && (
              <div className={classes.rowItems}>
                <div className={classes.infoColumn}>
                  <span className={classes.infoLabel}>
                    {t("screens.cards.shippingAddress.complement")}
                  </span>
                </div>
                <div className={classes.infoColumn}>
                  <span className={classes.infoValue}>
                    {cardData.shipment.address.complement}
                  </span>
                </div>
              </div>
            )}

          <div className={classNames(classes.rowItems, classes.mobileColumn)}>
                <div className={classNames(classes.infoColumn, classes.widthFirstColumn)}>
                  <span className={classes.infoLabel}>
                    {t("screens.cards.shippingAddress.postalCode")}
                  </span>
                  <span className={classes.infoValue}>
                    {cardData && cardData.shipment.address.postal_code}
                  </span>
                </div>



            <div className={classes.shippingCodeContainer}>
              <div className={classes.infoColumn}>
                <div className={classes.infoColumn}>
                  <span className={classes.infoLabel}>
                    {t("screens.cards.shippingCode")}
                  </span>
                  <span className={classes.infoValue}>
                    {cardData && cardData.shipment.shipping_code}
                  </span>
                </div>
              </div>

              <CopyCode
                {...props}
                label={copied ? "screens.cards.copiedCode": "screens.cards.copyCode"}
                shippingCode={cardData && cardData.shipment.shipping_code}
              />
            </div>
          </div>
        </React.Fragment>
      )}
      <PinForm openForm={openForm} handleClose={() => setOpenForm(false)} id={cardData && cardData.id} blocked={cardData && cardData.blocked} />
    </div>
  );
};

const mapStateToProps = (state, props) => {
  const { cardType } = props;
  return {
    cardData: state.user.cards[cardType],
  };
};

const mapDispatchToProps = dispatch => ({
  setBlockStatus: (status, id, pin) => {dispatch(uploadStatusCard(status, id, pin))},
  addInfoWithSnack: (message) => dispatch(addSnack({ message: message }))
});
const enhance = compose(
  translate(),
  withStyles(styles),
  withRouter,
  withState("openForm","setOpenForm", false),
  withState("copied", "setCopied", false),
  connect(mapStateToProps, mapDispatchToProps),
  lifecycle({
    componentDidMount() {},
  })
);

export default enhance(CardData);
