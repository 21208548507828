import React from 'react';
import PropTypes from 'prop-types';
import TextField from '../Forms/TextField';
import NumberMaskFormatInput from './NumberMaskFormatInput';


export const minLength = min => value =>
  value && value.length < min ? 'Informar o dígito verificador de sua agência BB' : undefined

export const minLength5 = minLength(5)

const BankNumberMaskField = ({ InputProps, removeMask, ...props }) => {
  return (
    <TextField
      {...props}
      validate={props.isBB === true ? minLength5 : null}
      InputProps={{
        inputComponent: removeMask ? null : NumberMaskFormatInput,
        ...InputProps,
      }}
    />
  );
};

BankNumberMaskField.defaultProps = {
  InputProps: {},
};

BankNumberMaskField.propTypes = {
  InputProps: PropTypes.objectOf(PropTypes.any),
};

export default BankNumberMaskField;
