import React from 'react';
import Button from '@material-ui/core/Button';

const SwitchButton = ({
  children, isActive, ...rest
}) => {
  return (
    <Button
      size="small"
      style={{
        borderRadius: 0,
        maxWidth: '100%',

        minWidth: '0',
        minHeight: 0,
        display: 'flex',
        padding: '4px 8px',
        flex: 1,
        boxShadow: 'none',
      }}

      variant={isActive ? 'contained' : 'flat'}
      color={isActive
        ? 'secondary'
        : 'primary'}
      {...rest}
    >
      {children}
    </Button>
  );
};


export default SwitchButton;
