import {
  DEPOSIT_INTENTION_REQUESTED,
  UPLOAD_DEPOSIT_REQUESTED,
  WITHDRAWAL_INTENTION_REQUESTED,
  ADD_WITHDRAWAL_CONFIRMATION_DIALOG,
  CONFIRM_WITHDRAWAL_INTENTION_REQUEST,
  CONFIRM_WITHDRAWAL_REQUESTED,
  CONFIRM_CANCEL_DEPOSIT_INTENTION,
  CONFIRM_CANCEL_SEND_COINS,
  CANCEL_DEPOSIT_INTENTION_REQUESTED,
  CANCEL_SEND_COINS_REQUESTED,
} from './actionTypes';

export const depositIntentionRequested = form => ({
  type: DEPOSIT_INTENTION_REQUESTED,
  form,
});

export const uploadDepositRequested = doc => ({
  type: UPLOAD_DEPOSIT_REQUESTED,
  ...doc,
});

export const confirmCancelSendCoins = form => ({
  type: CONFIRM_CANCEL_SEND_COINS,
  form,
});

export const cancelSendCoins = form => ({
  type: CANCEL_SEND_COINS_REQUESTED,
  form,
});

export const cancelDepositIntention = form => ({
  type: CANCEL_DEPOSIT_INTENTION_REQUESTED,
  form,
});

export const confirmCancelDepositIntention = form => ({
  type: CONFIRM_CANCEL_DEPOSIT_INTENTION,
  form,
});

export const withdrawalIntentionRequested = form => ({
  type: WITHDRAWAL_INTENTION_REQUESTED,
  form,
});

export const confirmWithdrawalIntentionRequest = form => ({
  type: CONFIRM_WITHDRAWAL_INTENTION_REQUEST,
  form,
});

export const addWithdrawalConfirmation2FADialog = (isCrypto, currency, blockchain) => ({
  type: ADD_WITHDRAWAL_CONFIRMATION_DIALOG,
  isCrypto,
  currency,
  blockchain,
});

export const confirmWithdrawalRequested = ({
  token, currency, timestamp, otp_token,
}) => ({
  type: CONFIRM_WITHDRAWAL_REQUESTED,
  token,
  currency,
  otp_token,
  timestamp,
});
