import * as R from 'ramda';

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

const customErrors = [
  'BELOW_MINIMUM_AMOUNT',
  'PASS_NOT_EQUAL'
];

const getErrorName = err => {
  if (R.contains(err.type, customErrors)) {
    return err.type;
  }
  return `forms.${err.name}.error`;
};

const validateDoc = ({ schema, values = {} }) => {
  if (!schema) {
    return {};
  }
  const validationContext = schema.newContext();
  validationContext.validate(values);
  const docErrors = validationContext.validationErrors();
  /**Tests if there is no erros in form
   * Also checks if both password fields are identical
   */
  if( values.new_pass && values.repeatNewPass ){
    if((values.new_pass !== values.repeatNewPass) /*&& (!docErrors || docErrors.length===0)*/){
      return {repeatNewPass: 'PASS_NOT_EQUAL'};
    }
    if((values.new_pass === values.repeatNewPass) && (!docErrors || docErrors.length===0)){
      return {};  
    }
  }
  const errors = R.fromPairs(docErrors.map((err) => (
    [[err.name], getErrorName(err)]
  )));

  return errors; 
};
const asyncValidate = schema => values => sleep(0).then(() => validateDoc({ values, schema }));
export { asyncValidate };
export default validateDoc;
