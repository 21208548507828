import React from 'react';
import * as R from 'ramda';
import {
  compose,
  setDisplayName,
  lifecycle,
  withProps,
} from 'recompose';
import { connect } from 'react-redux';

import { reduxForm } from 'redux-form';
import { withStyles } from '@material-ui/core/styles';
import { uploadDepositRequested } from '../../../../redux/actions';

import DocInputStatus from '../../../../components/DocumentInputStatus';
import sendGAEvent from '../../../../utils/sendGAEvent'

const styles = theme => ({
  form: {
    display: 'flex'
  }
})

const getTransferStatus = ({ status, proof, type }) => {
  if (status === 'COMPLETE') {
    return 'OK';
  }
  if ((type === 'WITHDRAWAL' && status === 'PENDING') || R.not(R.isEmpty(proof))) {
    return 'SENT';
  }

  if (status === 'PROCESSING') {
    return 'PROCESSING';
  }

  return status;
};

const TransferDocInput = props => {
  const {
    handleSubmit,
    transfer,
    classes
  } = props;
  return (
    <form className={classes.form} onSubmit={handleSubmit}>
      <DocInputStatus
        {...props}
        status={getTransferStatus(transfer)}
        name="transferDoc"
      />
    </form>
  );
};

TransferDocInput.propTypes = {
};


const mapDispatchToProps = dispatch => ({
  onSubmit: (doc, _, props) => {
    sendGAEvent('Depósito', 'Novo Depósito')
    const formValues = {
      ...doc,
      trans_id: props.form
    }
    dispatch(uploadDepositRequested(formValues))
  },
});
const enhance = compose(
  connect(null, mapDispatchToProps),
  withStyles(styles),
  withProps(
    ({ form }) => {
      return { initialValues: { trans_id: form } }
    }),
  reduxForm(),
  setDisplayName('TransferDocInput'),
  lifecycle({
    componentDidUpdate() {
      const {
        dirty,
        submit,
        submitting,
        submitSucceeded,
        submitFailed,
      } = this.props;
      if (dirty && (!submitting && !submitSucceeded && !submitFailed)) {
        submit();
      }
    },
  }),
);

export default enhance(TransferDocInput);
