import {
  FETCH_PUBLIC_DATA,
  UPDATE_PUBLIC_DATA,
  SYNC_PUBLIC_DATA,
  FETCH_PRIVATE_DATA,
  UPDATE_PRIVATE_DATA,
  FETCH_DATA_REQUESTED,
  FETCH_DATA_SUCCEED,
  FETCH_DATA_FAILED,
  FETCH_INITIAL_PRIVATE_DATA,
  UPDATE_DATA_BY_TYPE,
  FETCH_INITIAL_PUBLIC_DATA
} from './actionTypes';

const fetchDataRequested = ({ requestType }) => ({
  type: FETCH_DATA_REQUESTED,
  requestType,
});

const fetchDataSucceed = ({ requestType, response }) => ({
  type: FETCH_DATA_SUCCEED,
  requestType,
  response,
});

const fetchDataFailed = ({ requestType }) => ({
  type: FETCH_DATA_FAILED,
  requestType,
});

const fetchPublicData = () => ({
  type: FETCH_PUBLIC_DATA,
});


const updatePublicData = () => ({
  type: UPDATE_PUBLIC_DATA,
});

const syncPublicData = () => ({
  type: SYNC_PUBLIC_DATA,
});

const fetchPrivateData = () => ({
  type: FETCH_PRIVATE_DATA,
});

const updatePrivateData = () => ({
  type: UPDATE_PRIVATE_DATA,
});

const fetchInitialPrivateData = () => ({
  type: FETCH_INITIAL_PRIVATE_DATA,
});

export const fetchInitialPublicData = () => ({
  type: FETCH_INITIAL_PUBLIC_DATA,
});


export const updateDataByType = ({ updateTypes }) => ({
  type: UPDATE_DATA_BY_TYPE,
  updateTypes,
});

export {
  fetchDataRequested,
  fetchDataSucceed,
  fetchDataFailed,
  fetchPublicData,
  updatePublicData,
  syncPublicData,
  fetchPrivateData,
  updatePrivateData,
  fetchInitialPrivateData,
};

export default {
  fetchDataRequested,
  fetchDataSucceed,
  fetchDataFailed,
  fetchPublicData,
  updatePublicData,
  syncPublicData,
  fetchPrivateData,
  updatePrivateData,
  fetchInitialPrivateData,
};
