import React, { useEffect } from 'react'
import { compose, withProps } from 'recompose'
import { translate } from 'react-i18next'
import { Route } from 'react-router-dom'

import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'

import SendCoins from './SendCoins'
import ReceiveCoins from './ReceiveCoins'

import TransfersTable from '../../components/Tables/TransfersTable'
import TitledPage from '../../components/PageLayouts/TitledPage'
import PageTabs from '../../components/PageLayouts/PageTabs'
import withExtract from './withExtract'
import WarningContainer from '../../containers/WarningCoin/index'

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  card: {
    maxWidth: 600,
    margin: "auto",
    marginBottom: 80,
    marginTop: theme.spacing.unit * 2,
  },
  warningContainer: {
    paddingTop: "50px",
    maxWidth: 600,
    margin: "auto",
    marginBottom: 0,
    marginTop: theme.spacing.unit * 2,
    [theme.breakpoints.down("sm")]: {
      minHeight: 330,
      width: "80%",
      display: "flex",
    },
  },
  cardWarning: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  cardContent: {
    padding: "50px 0 25px 0 !important",
    width: "50%",
  },
  tabStyle: {
    borderRadius: "5px",
    marginLeft: "6px",
    marginRight: "6px",
    marginBottom: "6px",
    transition:
      "filter 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  },
  tabNotSelected: {
    borderRadius: "5px",
    border: `2px solid ${theme.palette.secondary.main}`,

    "&:hover": {
      backgroundColor: "#e6e6e7",
      boxShadow:
        "0px 1px 5px 0px rgba(0, 0, 0, 0.2),0px 2px 2px 0px rgba(0, 0, 0, 0.14),0px 3px 1px -2px rgba(0, 0, 0, 0.12)",
    },
  },
  tabSelected: {
    backgroundColor: theme.palette.secondary.main,
    borderRadius: "5px",

    "&:hover": {
      filter: "brightness(90%)",
      boxShadow:
        "0px 1px 5px 0px rgba(0, 0, 0, 0.2),0px 2px 2px 0px rgba(0, 0, 0, 0.14),0px 3px 1px -2px rgba(0, 0, 0, 0.12)",
    },
  },
  tabNotSelectedText: {
    color: "#696F74",
    textAlign: "center",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
  },
  colorWhite: {
    color: "#FFFFFF",
    textAlign: "center",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
  },
});

const getTabs = t => [
  {
    label: t('transactions.send'),
    value: 'send'
  },
  {
    label: t('transactions.receive'),
    value: 'receive'
  }
]

const SendReceiveCoins = props => {
  const {
    handleChange,
    t,
    classes,
    subRoute,
    setWithdrawalConfirm,
    extract,
    allowedToken,
    currencies,
    currencyConfig,
  } = props;

  
  return (
    <TitledPage title={t("screens.sendReceiveMoney.title")}>
      <Grid container>
        <Grid justify="center" alignItems="center" item xs={12} md={12}>
          <PageTabs
            tabs={getTabs(t)}
            selectedTab={subRoute}
            onChange={handleChange}
            classes={classes}
          />
          <Card
            className={allowedToken ? classes.card : classes.warningContainer}
          >
            <Route
              path="/sendReceiveCoins/send"
              render={() =>
                allowedToken ? (
                  <SendCoins
                    key="sendCoinsForm"
                    form="sendCoinsForm"
                    setWithdrawalConfirm={setWithdrawalConfirm}
                  />
                ) : (
                  <WarningContainer
                    locationPath={"sendReceiveCoins"}
                    {...props}
                    currency={currencyConfig}
                    coinsSelectorExtraData={{
                      hasStableCoin: true,
                      stableCoinAction: "withdrawal",
                    }}
                  />
                )
              }
            />
            <Route
              path="/sendReceiveCoins/receive"
              render={() =>
                allowedToken ? (
                  <ReceiveCoins key="receiveCoins" form="receiveCoinsForm" />
                ) : (
                  <WarningContainer
                    locationPath={"sendReceiveCoins"}
                    {...props}
                    currency={currencyConfig}
                    coinsSelectorExtraData={{
                      hasStableCoin: true,
                      stableCoinAction: "deposit",
                    }}
                  />
                )
              }
            />
          </Card>
        </Grid>
        <Grid item xs={12} md={12}>
          {allowedToken && (
            <TransfersTable transfers={extract || []} currencies={currencies} />
          )}
        </Grid>
      </Grid>
    </TitledPage>
  );
}

const enhance = compose(
  translate(),
  withStyles(styles),
  withExtract,
  withProps(({
    match: { params: { subRoute = 'send' } },
    history: { replace }
  }) => ({
    subRoute,
    handleChange: (e, value) => replace(`/sendReceiveCoins/${value}`)
  }))
)

export default enhance(SendReceiveCoins)
