import { all } from 'redux-saga/effects';

import watchDialogs from './dialog/saga';
import watchSnacks from './snacks/saga';
import watchNews from './newsDialog/saga';

function* userSaga() {
  yield all([
    watchDialogs(),
    watchSnacks(),
    watchNews()
  ]);
}

export default userSaga;
