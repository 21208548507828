import React from 'react';
import { Field } from 'redux-form';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const CheckBoxFieldComponent = props => {
  const {
    input: { onChange, value, name },
    label,
    disabled
  } = props;

  return (
    <FormControlLabel
      control={
        <Checkbox disabled={disabled} name={name} checked={value} onChange={onChange} color="primary" />
      }
      label={label}
    />
  );
};

const CheckBoxField = props => <Field {...props} component={CheckBoxFieldComponent} />;

export default CheckBoxField;
