import * as R from 'ramda';
import {
  UPLOAD_DOCUMENT_REQUESTED,
  UPLOAD_DOCUMENT_RESOLVED,
} from './actionTypes';
import kycToObj from '../../../utils/kycToObj';

const initialState = {
  TAXID: false,
  SELFIE: false,
  CONSTITUTION: false,
};


const updateDocuments = (state, { profile }) => {
  const kycStatus = R.propOr('', 'kyc_status', profile);
  if (R.isEmpty(kycStatus)) {
    return initialState;
  }

  const newState = kycToObj(kycStatus);

  return R.merge(initialState, {
    ...newState,
    // SELFIE: false,
    // TAXID: false,
  });
};

const uploadDocumentsRequested = (state, action) => {
  const { formType } = action;

  return R.merge(state, { [formType]: 'UPLOADING' });
};

const uploadDocumentsResolved = (state, action) => {
  const { resolution: { resType, docType } } = action;

  const res = R.last(R.split('_', resType));

  return R.merge(state, { [docType]: res });
};
const documentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'UPDATE_PROFILE':
      return updateDocuments(state, action);

    case UPLOAD_DOCUMENT_RESOLVED:
      return uploadDocumentsResolved(state, action);


    case UPLOAD_DOCUMENT_REQUESTED:
      return uploadDocumentsRequested(state, action);

    default:
      return state;
  }
};

export default documentsReducer;
