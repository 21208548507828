import privateService from "../privateService";

const requestUserDeclaration = async (form) => {
  const options = {
    cmd: "send_user_declaration",
    method: "POST",
  };

  try {
  const content = await privateService(options, form);
  return content;
  }
  catch (e) {
    console.log('requestUserDeclaration', e);
    throw e;
  }
};

export default requestUserDeclaration;