const FETCH_USER_CARDS = 'FETCH_USER_CARDS';
const FETCH_USER_CARDS_SUCCEED = 'FETCH_USER_CARDS_SUCCEED';
const UPLOAD_STATUS_CARD = 'UPLOAD_STATUS_CARD'
const FAIL_UPDATE_CARD = "FAIL_UPDATE_CARD"
const REMOVE_CARDS = "REMOVE_CARDS";
const FETCH_CASHBACK = "FETCH_CASHBACK";
const FETCH_CASHBACK_INFO = "FETCH_CASHBACK_INFO";
const FETCH_CASHBACK_SUCCEED = "FETCH_CASHBACK_SUCCEED";
const FETCH_CASHBACK_INFO_SUCCEED = "FETCH_CASHBACK_INFO_SUCCEED";
const FETCH_CARD_LIMITS = "FETCH_CARD_LIMITS";
const FETCH_CARD_LIMITS_SUCCEED = "FETCH_CARD_LIMITS_SUCCEED";

export {
  FETCH_USER_CARDS,
  FAIL_UPDATE_CARD,
  FETCH_USER_CARDS_SUCCEED,
  UPLOAD_STATUS_CARD,
  REMOVE_CARDS,
  FETCH_CASHBACK,
  FETCH_CASHBACK_INFO,
  FETCH_CASHBACK_SUCCEED,
  FETCH_CASHBACK_INFO_SUCCEED,
  FETCH_CARD_LIMITS,
  FETCH_CARD_LIMITS_SUCCEED,
};
