import React from 'react';
import { compose, pure } from 'recompose';

import { withStyles } from '@material-ui/core/styles';
import AccountListItem from '../components/AccountListItem';
import DateBasicField from '../../../components/Fields/DateBasicField';
const styles = theme => ({
  root: {
    flex: 1,
    paddingBottom: 100,
  },
  fieldItem: {
    padding: 0,
    ...theme.typography.body1,
    fontSize: theme.typography.pxToRem(13),
    color: theme.palette.text.secondary,
  },
});

const AccountListBasicDateField = props => {
  const {
    canEdit,
    classes,
    ...other
  } = props;
  return (
    <AccountListItem
      primary={(
        <DateBasicField
          required
          disabled={!canEdit}
          {...other}
        />
      )}
      secondaryTypographyProps={{ component: 'div' }}
    />
  );
};

const enhance = compose(
  withStyles(styles),
  pure,
);

export default enhance(AccountListBasicDateField);
