import ChatPage from "./ChatPage";
import { contactSupport } from "../../utils/contactSupport";
import { compose, lifecycle } from "recompose";
import { translate } from 'react-i18next';


const enhance = compose(
    translate(),
    lifecycle(
        {
            componentDidMount(){
                contactSupport();
            }
        }
    )
);

export default enhance(ChatPage);
