import {
  compose, pure, renderNothing, branch, withProps,
} from 'recompose';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';

import OtpDialog from '../../components/OtpDialog';
import { CLOSE_OTP_DIALOG } from '../../redux/actionTypes';


const mapStateToProps = (state) => {
  return {
    otpDialog: state.credentials.otpDialog,
  };
};

const enhance = compose(
  pure,
  translate(),
  connect(mapStateToProps),
  branch(({ otpDialog }) => !(otpDialog.open), renderNothing),
  withProps((
    {
      dispatch,
      otpDialog: { onOtpValidatedActionType, ...otpDialog },
    }) => ({
    onOtpValidated: formValues => dispatch({ type: onOtpValidatedActionType, ...formValues }),
    setIsOpen: () => dispatch({ type: CLOSE_OTP_DIALOG }),
    ...otpDialog,
  })),
);

export default enhance(OtpDialog);
