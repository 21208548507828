import React from "react";
import TextFieldComponent from "../../components/FormsV2/TextFieldComponent";
import { withStyles } from "@material-ui/core/styles";
import { Search } from "@material-ui/icons";

const styles = (theme) => ({
  inputContainer: {
    display: "flex",
    backgroundColor: "#FFF",
    alignItems: "center",
    gap: "10px",
    padding: "10px 15px",
    borderRadius: "50px",
  },
  input: {
    width: "100%",
    // Reset native input styles
    border: "none",
    outline: "none",
    background: "none",
    fontFamily: "Roboto",
    fontSize: "14px",
    fontStyle: "normal",
    fontweight: 400,
    lineheight: "normal",
    "&::placeholder": {
      color: "#AAB3BC",
    },
  },
});

const CurrencySearchInput = (props) => {
  const { classes, placeholder, onChange } = props;

  return (
    <div className={classes.inputContainer}>
      <Search />
      <input
        onChange={onChange}
        className={classes.input}
        placeholder={placeholder}
      />
    </div>
  );
};

export default withStyles(styles)(CurrencySearchInput);
