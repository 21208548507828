import { connect } from 'react-redux'
import { compose } from 'recompose'
import { addDialog } from '../../../redux/actions'

const mapStateToProps = state => ({
  userBankAccount: state.user.bankAccount,
  balance: state.user.balance,
  extract: state.user.extract.extracts || [],
  limits: state.user.limits,
  fees: state.user.fees,

  missingTaxId:
    state.user.profile.tax_id === '' ||
    state.user.profile.tax_id === null ||
    state.user.profile.tax_id === undefined
})

const mapDispatchToProps = dispatch => ({
  warningOnSelectBank: (warningComponentName, dialogOptions) => {
    dispatch(
      addDialog({
        title: 'common.attention',
        centerTitle: true,
        componentProps: dialogOptions.componentProps,
        renderComponent: warningComponentName,
        disableBackdropClick: true,
        disableEscapeKeyDown: true,
        availableChoices: dialogOptions.availableChoices,
        actionsToTake: dialogOptions.actionsToTake
      })
    )
  }
})

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)
