import {
  FETCH_TICKER,
  UPDATE_TICKER,
} from './actionTypes';

const fetchTicker = () => ({
  type: FETCH_TICKER,
});

const updateTicker = ticker => ({
  type: UPDATE_TICKER,
  ticker,
});


export default {
  fetchTicker,
  updateTicker,
};

export {
  fetchTicker,
  updateTicker,
};
