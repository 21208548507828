import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'; 

const AccountListItem = ({
  secondary, pendingSecondary, listItemSecondaryAction, tooltipTitle, ...props
}) => {
  const secondaryText = secondary || pendingSecondary;

  return (
    <div>
      <ListItem>
        <ListItemText secondary={secondaryText} {...props} />
          <ListItemSecondaryAction>
            {listItemSecondaryAction}
          </ListItemSecondaryAction>
      </ListItem>
    </div>
  );
};

export default AccountListItem;
