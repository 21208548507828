import React from 'react'
import TradingViewChart from '../../components/TradingViewChart'
import { Hidden, Grid } from '@material-ui/core'
import PageTabs from '../../components/PageLayouts/PageTabs'
import ExpressTradeCard from './components/ExpressTradeCard/ExpressTradeCard'
import { Fragment } from 'react'
import { getCurrencyBySymbol } from '../../models/Currency'
import { getChartConfig } from '../../components/TVChartContainer/utils'
import DemoAccountFixedCard from '../../components/Cards/DemoAccountFixedCard/'
import CryptoConverter from '../CryptoConverter/CryptoConverter'
import { getShowCryptoConverter } from '../../utils/firebaseUtils'
import useShowCryptoConverter from '../../hooks/useShowCryptoConverter'


const getTabs = (t, showCryptoConverter) => {
  const tabs = [
    {
      label: t("screens.expressTrade.buy"),
      value: "buy",
    },
    {
      label: t("screens.expressTrade.sell"),
      value: "sell",
    },
  ];
  if (showCryptoConverter) {
    tabs.push({
      label: t("screens.cryptoConverter.converter"),
      value: "converter",
    });
  }
  return tabs;
};


const ExpressTrade = props => {
  const {
    orderType,
    handleRouteChange,
    market,
    token,
    classes,
    currencies,
    t,
    demoAccount,
    isBetaTester,
  } = props

  const firebaseShowCryptoConverter = useShowCryptoConverter(demoAccount);
  const baseCurrencySymbol = market.split('-')[0];
  const marketConfig = getCurrencyBySymbol(currencies, baseCurrencySymbol);
  const quoteCurrencySymbol = "BRL";
  const chartConfig = getChartConfig(baseCurrencySymbol, quoteCurrencySymbol);
  const showCryptoConverter = getShowCryptoConverter(firebaseShowCryptoConverter, isBetaTester);
  return (
    <Fragment>
      <Hidden smDown>
        <div className={classes.chartContainer}></div>
        {marketConfig && (
          <TradingViewChart
            key={chartConfig.key}
            marketConfig={marketConfig}
            chartConfig={chartConfig}
            token={token}
          />
        )}
      </Hidden>
      <Grid container className={classes.tabContainer}>
        <Grid justify="center" alignItems="center" item xs={12} md={12}>
          <PageTabs
            tabs={getTabs(t, showCryptoConverter)}
            selectedTab={orderType}
            onChange={handleRouteChange}
            classes={classes}
          />
        </Grid>
      </Grid>
      {orderType !== "converter" && (
        <ExpressTradeCard
          orderType={orderType}
        />
      )}
      {(orderType === "converter" && showCryptoConverter) && <CryptoConverter />}
      {demoAccount && <DemoAccountFixedCard />}
    </Fragment>
  );
}

export default ExpressTrade
