import { all } from 'redux-saga/effects';

import watchBankTransfers from './bankTransfers/saga';
import watchBankList from './bankList/saga';


function* exchangeSagas() {
  yield all([
    watchBankTransfers(),
    watchBankList(),
  ]);
}

export default exchangeSagas;
