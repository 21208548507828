import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import CurrencyTableDataContent from './CurrencyTableDataContent';

const styles = theme => ({
  green: {
    color: theme.palette.green.main,
  },
  red: {
    color: theme.palette.red.main,
  },
});

const typeHasFee = type =>{
  return type.includes('FEE')
}

const AmountTableDataContent = ({ classes, extract, currency }) => {
  // const className = (extract.currency==='BRL' ? (extract.type === 'DEPOSIT' ? classes.green : classes.red) : (extract.type === 'SELL'  || extract.type === 'WITHDRAWAL-FEE'  ? classes.red : classes.green));
  /**
   * Choose the color
   * First condition: options to currency BRL,
   * Second Condition: options to currency BTC
   */
  const className = (extract.currency==='BRL' ?
                      ( typeHasFee(extract.type) || extract.type === 'WITHDRAWAL' || extract.type === 'BUY' ? classes.red : classes.green) :
                      ( typeHasFee(extract.type) || extract.type === 'SELL'  || extract.type === 'WITHDRAWAL'  ? classes.red : classes.green));

  const extractAmount = extract.currency === 'BRL' ? Number(extract.amount.toFixed(2)) : extract.amount;
  return (
    <CurrencyTableDataContent extract={extract} className={className} value={extractAmount} currency={currency} />
  );
};

export default withStyles(styles)(AmountTableDataContent);

