export const styles = (theme) => ({
    confirmAccountContainer: {
      display: "flex",
      justifyContent: "space-between",
      margin: "10px 0",
      [theme.breakpoints.down("sm")]: {
        justifyContent: "center",
        display: "inline",
        padding: "0 15%",
      },
    },
    confirmAccountContent: {
      [theme.breakpoints.down("sm")]: {
        marginBottom: "5px",
      },
    },
    warningContainer: {
      display: "flex",
      flexDirection: "column",
      backgroundColor: "#ffeebd",
      justifyContent: "center",
      padding: "16px",
      marginTop: "16px",
    },
    flexContainer: {
      display: "flex",
      justifyContent: "center",
      margin: 5,
    },
    congratulationsDialog: {
      backdropFilter: "blur(5px)",
      textAlign: "center",
    },
    congratulationsDialogInfo: {
      lineHeight: "1.575rem",
      color: theme.palette.secondary.main,
      fontFamily: "'Helvetica','Arial', 'sans-serif'",
    },
    congratulationsDialogText: {
      fontWeight: "bold",
      fontFamily: "'Switzer','Arial', 'sans-serif'",
    },
    congratulationsDialogExplanatory: {
      fontSize: "0.875rem",
      lineHeight: "1.2rem",
      fontWeight: "500",
      fontFamily: "'Roboto','Helvetica', 'Arial', 'sans-serif'",
      color: "#6c757d",
    },
    congratulationsDialogTitle: {
      backgroundColor: theme.palette.secondary.main,
      padding: "40px",
    },
    congratulationsDialogTitleText: {
      fontSize: "22px",
      textAlign: "left",
      maxWidth: "200px",
      lineHeight: "1.6rem",
      marginLeft: "10px",
      color: "white",
    },
    congratulationsDialogCenter: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    congratulationsDialogContent: {
      paddingTop: "50px",
      maxWidth: "480px",
    },
    congratulationsDialogButton: {
      width: "85%",
      marginBottom: "20px",
      borderRadius: "8px",
      color: "white",
    },
    warningText: {
      color: "#a97800",
      marginBottom: 8,
    },
    warningTextBold: {
      color: "#a97800",
      marginBottom: 8,
      fontWeight: "bold",
    },
    icon: {
      maxWidth: 70,
      borderRadius: 50,
      marginRight: "12px",
    },
    centeredTitle: {
      textAlign: "center",
    },
    innerImg: {
      "& img": {
        width: "100%",
      },
    },
    link: {
      "& a:link": {
        textDecoration: "none",
      },
    },
    longText: {
      overflowWrap: "break-word",
      wordWrap: "break-word",
      hyphens: "auto",
    },
    flexColumn: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    gapDefault: {
      gap: "15px",
    },
    marginTopDefault: {
      marginTop: "30px",
    },
    marginTop0: {
     marginTop: "0px",
    },
    marginTop10: {
      marginTop: "10px",
    },
    boldSubtitle: {
      color: "#101010",
  
      textAlign: "center",
  
      fontFamily: "Roboto",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "normal",
    },
    titleDefault: {
      textAlign: "center",
      color: "#141414",
      textAlign: "center",
      fontFamily: "Roboto, sans-serif",
      fontSize: "32px",
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "normal",
    },
    mgmTermsDialogIcon: {
      color: "#F7B733",
    },
    bitybankButton: {
      width: "100%",
      color: "#101010",
      fontSize: "12px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "normal",
      padding: "12px 0",
      borderRadius: "8px",
      background: " #F7B733",
      maxWidth: "315px",
  
      [theme.breakpoints.down("sm")]: {
        maxWidth: "84%",
      },
    },
    linkSubtitle: {
      color: "#656F79",
      textAlign: "center",
  
      /* bodySmall */
      fontSize: "12px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "normal",
  
      "& a": {
        color: "#656F79",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: "700",
        lineHeight: "20px",
        textDecorationLine: "underline",
      },
    },
    supportLink: {
      fontSize: "14px",
      textAlign: "left",
      color: theme.palette.primary.main,
  
      "& a": {
        fontSize: "14px",
      },
    },
    acceptMgmTermsPaper: {
      maxWidth: "600px",
      width: "100%",
      margin : "auto",
  
      [theme.breakpoints.down("sm")]: {
        maxWidth: "90%",
      },
    },
    storeLinksContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }
  });