import { compose, withProps, withHandlers } from 'recompose'
import { getPrice } from './marketPriceCalculator'
import { connect } from 'react-redux'
import { toFixedNoRound } from '../../../utils/toFixedNoRound.js'

const mapStateToProps = state => ({
  balance: state.user.balance,
  buyOrdersSerialized: state.orders.buyOrdersSerialized,
  sellOrdersSerialized: state.orders.sellOrdersSerialized
})

const withTradePercentageInputHandlers = compose(
  connect(mapStateToProps),
  withProps(props => ({
    shouldDisplayTotalValuePercentageSwitch: props.orderType === 'BUY',
    shouldDisplayAmountPercentageSwitch: props.orderType === 'SELL'
  })),
  withHandlers({
    handleTotalValuePercentChange: props => percent => {
      const {
        setFieldValue,
        balance,
      } = props
      const totalValue = balance['BRL'] * (percent / 100)
      const actualPrice = getPrice('TOTALVALUE', totalValue)(props)
      const amount = totalValue / actualPrice

      // workaround to handle rounded up balance.
      // balance from state may be more than the
      // actual balance on the backend

      setFieldValue('totalValuePercentage', percent)
      setFieldValue('amount', amount)
      setFieldValue('totalValue', toFixedNoRound(totalValue, 2))
      setFieldValue('price', actualPrice)
    },
    handleAmountPercentChange: props => percent => {
      const {
        setFieldValue,
        balance,
        values: { currency }
      } = props
      const amount = isNaN(balance[currency]) ? 0 : balance[currency] * (percent / 100)
      const actualPrice = getPrice('AMOUNTBTC', amount)(props)
      const totalValue = actualPrice * amount

      setFieldValue('amountPercentage', percent)
      setFieldValue('price', actualPrice)
      setFieldValue('totalValue', toFixedNoRound(totalValue, 2))
      setFieldValue('amount', amount)
    }
  })
)

export default withTradePercentageInputHandlers
