import React from 'react';
import { compose } from 'recompose';

import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';

const styles = theme => ({
  field: {
    width: '100%',

    maxWidth: 400,
    paddingBottom: theme.spacing.unit,
    // paddingLeft: 3 * theme.spacing.unit,
    // paddingRight: 3 * theme.spacing.unit,
  },
  fullWidth: {
    width: '100%',
    //paddingBottom: theme.spacing.unit,
  }
});


const FieldRow = ({ children, classes, fullWidth, ...rest }) => {
  return (
    <FormControl
      className={(fullWidth ? classes.fullWidth : classes.field)}
      {...rest}
    >
      {children}
    </FormControl>
  );
};

const enhance = compose(
  withStyles(styles),
);

export default enhance(FieldRow);
