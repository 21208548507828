import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClone} from '@fortawesome/free-regular-svg-icons';
import { withStyles } from '@material-ui/core/styles';
import QRCode from 'qrcode.react';

const styles = theme => ({
  root:{
    display: 'flex',
    flexDirection: 'column',
    ...theme.typography.body1,
    fontSize: theme.typography.pxToRem(13),
    color: theme.palette.text.secondary,
  },
  secret: {
    padding: 3,
    marginLeft: 'auto',
    marginRight: 'auto',
    fontSize:'0.5em'
  },
  copytoclipboard: {
    cursor: 'pointer',
    borderRadius: 50,
    fontSize: 25,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '15px'

  },
  copiedSpan: {
    padding: 3,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  copySpan: {
    padding: 3,
    marginLeft: 'auto',
    marginRight: 'auto',
    fontSize:'1em'
  }
});

const QRimageRender = ({qrUrl, textCode, setCopied, copied, t, classes}) => {
  return (
    <div>
      <div className={classes.root}>
        <QRCode size={200} value={qrUrl} />
        {setCopied && <CopyToClipboard
            className={classes.copytoclipboard}
            text={textCode}
            onCopy={() => setCopied(true)}
          >
            <FontAwesomeIcon icon={faClone} />
          </CopyToClipboard>}
        {copied ? <span className={classes.copiedSpan}>{t('wallet.copied')}</span> :
        <span className={classes.copySpan}>Clique para copiar</span>}
      </div>
    </div>
  );
};

export default withStyles(styles)(QRimageRender);
