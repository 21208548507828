export const styles = (theme) => ({
  ctaText: {
    color: theme.palette.primary.main,
    fontFamily: "Roboto",
    width: "400px",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "24px",
    textAlign: "center",
    border: "none",

    [theme.breakpoints.down("xs")]: {
      width: "100%"
    }
  },
  cardStyle: {
    backgroundColor: theme.colors.gray.light,
    position: "fixed",
    bottom: "0",
    left: "300px",
    borderRadius: "8px 8px 0 0",
    width: "calc(100% - 300px)",
    zIndex: "1",

    [theme.breakpoints.down("sm")]: {
      width: "100%",
      left: "0",
      bottom: 30,
    },
  },
  contentWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "20px",
    borderRadius: "8px",
    gap: "15px",

    [theme.breakpoints.down("xs")]: {
      padding:0
    }
  },
  buttonsWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "30px",
    justifyContent: "center",
    width: "100%",
    maxWidth: "420px",

    "& button": {
        width: "100%",
        boxShadow: "none"
      },

    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      gap: "15px",
      padding: "0 20px 20px",
    }
  },
});
