import React from "react";
import IntercomChat from "../../layout/IntercomChat";
import LinkButton  from "../../components/Buttons/LinkButton";
import Hidden from '@material-ui/core/Hidden';


const ChatPage = (props) => {
    const { match: { params : { intercom_hash } } } = props;
    const {t} = props;
    return (
        <div style={{display: "flex"}}>
            <Hidden mdUp>
                <LinkButton style={{margin: 'auto', marginTop: '85%'}}
                            color="secondary"
                            variant="raised" to={'/trade'}>
                    {t('screens.trade.title')}
                </LinkButton>
            </Hidden>
        <IntercomChat intercomHashFromParams={intercom_hash}/>

        </div>
    )
}

export default ChatPage;
