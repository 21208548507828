import React from 'react';
import Typography from '@material-ui/core/Typography';
import SwitchButton from '../Buttons/SwitchButton';

const MyButton = props => <Typography noWrap {...props} />;

const UseBalanceButton = ({ children, ...rest }) => {
  return (
    <SwitchButton
      component={MyButton}
      {...rest}
    >
      {children}
    </SwitchButton>
  );
};

export default UseBalanceButton;
