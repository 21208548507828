import * as React from 'react';
import './index.css';
import { DATA_FEED_URL, CHARTS_STORAGE_URL } from '../../config';
import ExpandableWrapper from './ExpandableWrapper';
import { isMobile } from '../../utils/checkScreenHelpers';

function getLanguageFromURL() {
  const regex = new RegExp('[\\?&]lang=([^&#]*)');
  const results = regex.exec(window.location.search);
  return results === null ? null : decodeURIComponent(results[1].replace(/\+/g, ' '));
}

class TVChartContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {expanded: false};
  }

  static defaultProps = {
    interval: '60',
    container: 'tv_chart_container',
    datafeedUrl: DATA_FEED_URL,
    library_path: '/charting_library/',
    chartsStorageUrl: CHARTS_STORAGE_URL,
    chartsStorageApiVersion: '1.1',
    clientId: 'bitpreco.com',
    userId: 'public_user_id',
    fullscreen: false,
    autosize: true,
    studiesOverrides: {},
  };
  

  componentDidMount() {
    if (isMobile()) {
      const widgetOptions = {
        debug: true, // uncomment this line to see Library errors and warnings in the console
        symbol: this.props.chartConfig.symbol,
        // BEWARE: no trailing slash is expected in feed URL
        datafeed: new window.Datafeeds.UDFCompatibleDatafeed(
          this.props.datafeedUrl,
          60000
        ), //60 seconds update
        interval: this.props.interval,
        container: this.props.container,
        library_path: this.props.library_path,
        locale: getLanguageFromURL() || "en",
        timezone: "exchange",
        disabled_features: ["use_localstorage_for_settings"],
        enabled_features: ["study_templates"],
        charts_storage_url: this.props.chartsStorageUrl,
        charts_storage_api_version: this.props.chartsStorageApiVersion,
        client_id: this.props.clientId,
        user_id: this.props.token ? this.props.token : this.props.userId,
        fullscreen: this.props.fullscreen,
        autosize: this.props.autosize,
        studies_overrides: this.props.studiesOverrides,
      };

      const event = () => {
        const widget = new window.TradingView.widget(widgetOptions);

        widget.onChartReady(() => {
          const button = widget
            .createButton()
            .attr('title', 'Click to show a notification popup')
            .addClass('apply-common-tooltip')
            .on('click', () =>
              widget.showNoticeDialog({
                title: 'Notification',
                body: 'TradingView Charting Library API works correctly',
                callback: () => {
                  console.log('Noticed!');
                },
              }),
            );

          button[0].innerHTML = 'Check API';
        });
      };

      event();
      
      // this onready event is not working
      // so I'm calling event right after it. I'm not sure if it will
      // cause a problem or not, but I think the component will
      // always be fully loaded on it reaches this part here.
      // window.TradingView.onready(event);
    }
  }

  componentDidUpdate() {
    if (this.state.expanded) {
      const widgetOptions = {
        debug: true, // uncomment this line to see Library errors and warnings in the console
        symbol: this.props.chartConfig.symbol,
        // BEWARE: no trailing slash is expected in feed URL
        datafeed: new window.Datafeeds.UDFCompatibleDatafeed(
          this.props.datafeedUrl,
          60000
        ), //60 seconds update
        interval: this.props.interval,
        container: this.props.container,
        library_path: this.props.library_path,

        locale: getLanguageFromURL() || "en",
        timezone: "exchange",
        disabled_features: ["use_localstorage_for_settings"],
        enabled_features: ["study_templates"],
        charts_storage_url: this.props.chartsStorageUrl,
        charts_storage_api_version: this.props.chartsStorageApiVersion,
        client_id: this.props.clientId,
        user_id: this.props.token ? this.props.token : this.props.userId,
        fullscreen: this.props.fullscreen,
        autosize: this.props.autosize,
        studies_overrides: this.props.studiesOverrides,
      };

      const event = () => {
        const widget = new window.TradingView.widget(widgetOptions);

        widget.onChartReady(() => {
          const button = widget
            .createButton()
            .attr('title', 'Click to show a notification popup')
            .addClass('apply-common-tooltip')
            .on('click', () =>
              widget.showNoticeDialog({
                title: 'Notification',
                body: 'TradingView Charting Library API works correctly',
                callback: () => {
                  console.log('Noticed!');
                },
              }),
            );

          button[0].innerHTML = 'Check API';
        });
      };

      event();

      // this onready event is not working
      // so I'm calling event right after it. I'm not sure if it will
      // cause a problem or not, but I think the component will
      // always be fully loaded on it reaches this part here.
      // window.TradingView.onready(event);
    }

  }

  componentWillUnmount() {
    if ((isMobile() || this.state.expanded) && window.tvWidget)
      window.tvWidget.remove();
  }

  render() {
    const className = isMobile() ? 'TVChartContainerMobile': 'TVChartContainer';
    return this.props.hideExpandable ? (
      <div id={this.props.container} className={className} />
    ) : (
      <ExpandableWrapper
        expanded={this.state.expanded}
        title={`Visualizar Gráfico ${this.props.chartConfig.market}`}
        onChange={(_state, expanded) => {
          this.setState({ expanded });
        }}
      >
        <div id={this.props.container} className={className} />
      </ExpandableWrapper>
    );
  }
}



export default TVChartContainer
