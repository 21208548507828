import * as R from 'ramda';

const unSerialize = value => {
  return value.key;
};

const unSerializeBankAccount = bankAccount => {
  return R.merge(bankAccount, { bank: unSerialize(bankAccount.bank) });
};

export default unSerializeBankAccount;
