import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';

import user from './user/reducer';
import exchange from './exchange/reducer';
import credentials from './credentials/reducer';
import appData from './appData/reducer';
import currencies from './currencies/reducer';
import layout from './layout/reducer';
import orderBook from './orderBook/reducer';
import orders from './orders/reducer';
import openOrders from './openOrders/reducer';
import tradeHistory from './tradeHistory/reducer';

import transactions from './transactions/reducer';
import ticker from './ticker/reducer';

import market from './market/reducer';

import keepAlive from './keepAlive/reducer';

export default combineReducers({
  credentials,
  exchange,
  user,
  appData,
  form,
  orderBook,
  transactions,
  openOrders,
  tradeHistory,
  currencies,
  ticker,
  layout,
  orders,
  market,
  keepAlive
});
