import React from "react";
import { connect } from "react-redux";
import { FIREBASE_LINKS } from "../../../../config";
import {
  DialogTitle,
  DialogContent,
  Typography,
  Grid,
  FormControlLabel,
  Radio,
  FormControl,
} from "@material-ui/core";
import { compose } from "recompose";
import { translate } from "react-i18next";
import { reduxForm, formValueSelector, isSubmitting } from "redux-form";
import SubmitButton from "../../../../components/Forms/SubmitButton";
import RadioGroupField from "../../../../components/Fields/RadioGroupField";
import {
  changeUserDeclarationDialog,
  addSnack,
} from "../../../../redux/actions";
import { declarationTypes, pendingDocTypes, getDeclarationTypeByDocPending } from "../_constants";
import requestUserDeclaration from "../../../../services/cbtcService/userDeclaration";

const pepSubmit = async (form, dispatch, props) => {
  const { handleClose, pendingSends, openAnotherDeclarationDialog } = props;
  const pepValue = form.is_pep === "true";
  try {
    const newForm = {
      data: JSON.stringify({ is_pep: pepValue }),
      type: declarationTypes.PERSONAL,
    };
    await requestUserDeclaration(newForm);
    const newPendingSends = pendingSends.filter(
      (item) => item !== pendingDocTypes.PEP_DECLARATION
    );
    if (newPendingSends.length > 0) {
      openAnotherDeclarationDialog({
        declarationType: getDeclarationTypeByDocPending(newPendingSends[0]),
        pendingSends: newPendingSends,
      });
    } else {
      handleClose();
    }
  } catch (error) {
    dispatch(addSnack({ message: "GENERIC_ERROR" }));
    return;
  }
};

const PersonalDeclarationBody = (props) => {
  const { t, classes, isPepChecked, handleSubmit, submitting } = props;

  return (
    <>
      <DialogTitle className={classes.dialogTitle}>
        {t("forms.pepValidation.dialog.title")}
      </DialogTitle>
      <DialogContent>
        <Typography className={classes.descriptionLabel}>
          {t("forms.pepValidation.dialog.descriptionPrefix")}{" "}
          <a
            className={classes.linkStyle}
            href={FIREBASE_LINKS.urlBitybankPEPTerms}
            target="_blank"
            rel="noopener noreferrer"
          >
            {t("forms.pepValidation.dialog.terms")}
          </a>
          {t("forms.pepValidation.dialog.descriptionSuffix")}
        </Typography>
        <Typography>{t("forms.pepValidation.dialog.chooseOption")}</Typography>
        <Grid container>
          <Grid direction="row">
            <form onSubmit={handleSubmit}>
              <FormControl component={"fieldset"}>
                <RadioGroupField name="is_pep">
                  <FormControlLabel
                    value={"false"}
                    control={<Radio />}
                    label={
                      <Typography component="span">
                        {t("forms.pepValidation.dialog.option1Prefix")}{" "}
                        <a
                          className={classes.linkStyle}
                          href={FIREBASE_LINKS.urlBitybankPEPTerms}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {t("forms.pepValidation.dialog.terms")}
                          {"."}
                        </a>
                      </Typography>
                    }
                  />
                  <FormControlLabel
                    value={"true"}
                    control={<Radio />}
                    label={t("forms.pepValidation.dialog.option2")}
                  />
                  {/* </RadioGroup> */}
                </RadioGroupField>
              </FormControl>
              <SubmitButton
                disabled={!isPepChecked || submitting}
                className={classes.submitButton}
                isSubmitting={submitting}
                color="primary"
                variant="raised"
                submitText={t("common.toContinue")}
              />
            </form>
          </Grid>
        </Grid>
      </DialogContent>
    </>
  );
};

const selector = formValueSelector("userDeclarationPersonal");

const mapDispatchToProps = (dispatch) => ({
  handleClose: () =>
    dispatch(
      changeUserDeclarationDialog({ open: false, declarationType: null, pendingSends: []})
    ),
  openAnotherDeclarationDialog: (data) =>
    dispatch(
      changeUserDeclarationDialog({
        open: true,
        declarationType: data.declarationType,
        pendingSends: data.pendingSends,
      })
    ),
});

const mapStateToProps = (state) => {
  return {
    isPepChecked: selector(state, "is_pep"),
    submitting: isSubmitting("userDeclarationPersonal")(state),
    pendingSends: state.credentials.userDeclarationDialog.pendingSends,
  };
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  translate(),
  reduxForm({
    form: "userDeclarationPersonal",
    onSubmit: pepSubmit,
  })
);

export default enhance(PersonalDeclarationBody);
