import React, { Fragment, useState, useEffect } from 'react';
import { compose, pure, withState, lifecycle } from "recompose";
import Account from './Account';
import Security from './Security';
import OperationalLimits from './OperationalLimits';
import TabPage from '../../components/PageLayouts/TabPage';
import { VARIANT } from '../../config';
import { fetchLimitsRequested } from '../../redux/actions';
import { connect } from "react-redux";

const EXCHANGE = VARIANT === 'EXCHANGE';

const mapDispatchToProps = dispatch => ({
  fetchLimits: () => dispatch(fetchLimitsRequested()),
});

const Profile = (props) => {
  const { t } = props;

  const [actualTab, setActualTab] = useState('');

  useEffect(() => {
    setActualTab(props.match.params.subRoute);
  });

  if (EXCHANGE) {
    return (
      <div className="profile">
        <Fragment>
          <TabPage>
            <Account route="account" label={t('account.title')} />
            <OperationalLimits route="operationalLimits" label={t('operationalLimits.title')} />
            <Security route="security" label={t('security.title')} actualTab={actualTab} />
          </TabPage>
        </Fragment>
      </div>
    );
  }
  return (
    <Account route="account" label={t('account.title')} />
  );
};

const enhance = compose(
  pure,
  withState('tabIndex', 'setTabIndex', 0),
  connect(null, mapDispatchToProps),
  lifecycle({
    componentDidMount() {
      this.props.fetchLimits();
    },
  }),
);

export default enhance(Profile);
