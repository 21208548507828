import React, { Fragment, useEffect } from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import Hidden from '@material-ui/core/Hidden';
import { compose } from 'recompose'
import { translate } from 'react-i18next'
import TradeDesktop from './DesktopWrapper';
import TransactionsMobile from './MobileWrapper';
import useOrderbook from '../../hooks/useOrderbook';
import useTradeHistory from '../../hooks/useTradeHistory';
import { addDialog } from '../../redux/layout/actions';
import { getCurrencyBySymbol, Currency } from '../../models/Currency';
import { checkNoTransferOperationsWarning, setNoTransferOperationsWarning } from '../../utils/cookies';
import DemoAccountFixedCard from '../../components/Cards/DemoAccountFixedCard/'



const Trade = ({
  startSyncOrders, stopSyncOrders, market, handleOrderBook, handleTradeHistory, token, history, currencies, hasNoWithdrawalOrDepositWarning, userId, demoAccount
}) => {
  const orderbook = useOrderbook(market, startSyncOrders, stopSyncOrders);
  const tradeHist = useTradeHistory(market);
  const currencyConfig = getCurrencyBySymbol(currencies, market.split("-")[0]) || getCurrencyBySymbol(currencies, "BTC");
  const currency = new Currency(currencyConfig);
  const hasDepositAndWithdrawal = currency.hasDeposit && currency.hasWithdrawal;


  useEffect(() => {
    if (!hasDepositAndWithdrawal) {
      const tradeType = 'advancedTrade';
      const hasViewedWarning = checkNoTransferOperationsWarning(userId, currency.symbol, tradeType);
      if (!hasViewedWarning) {
      hasNoWithdrawalOrDepositWarning(currency.symbol);
      setNoTransferOperationsWarning(userId, currency.symbol, tradeType);
      }
    }
  }
  , [hasDepositAndWithdrawal]);

  useEffect(() => {
    if (R.isEmpty(orderbook.asks)) {
      return
    }
    handleOrderBook(orderbook);
  }, [orderbook]);

  useEffect(() => {
    handleTradeHistory(tradeHist);
  }, [tradeHist])

    useEffect(() => {
      history.replace(`/trade/${market}`);
    }, [market]);

  return (
    <Fragment>
      <Hidden mdUp>
        <TransactionsMobile />
      </Hidden>
      <Hidden smDown>
        <TradeDesktop token={token} currencyConfig={currencyConfig} />
      </Hidden>
      {demoAccount && <DemoAccountFixedCard />}
    </Fragment>
  );
};

const mapStateToProps = state => ({
  userId: state.user.profile.id,
  demoAccount: state.credentials.demoAccount,
});

const mapDispatchToProps = dispatch => ({
  hasNoWithdrawalOrDepositWarning: (currencyName) => dispatch(addDialog({
    title: 'common.attention',
    renderComponent: 'hasNoWithdrawalOrDepositWarning',
    componentProps: {
      currencyName,
    },
    availableChoices: [
      {
        label: 'common.understood',
        color: 'secondary',
        variant: 'raised',
        
      }
    ]
  })),
});

const enhance = compose (
  connect(mapStateToProps, mapDispatchToProps),
  translate(),
)

export default enhance(Trade)
