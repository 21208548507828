import { Link } from 'react-router-dom'

const GLOBAL_CARD_URL = 'https://globalcard.com.br/'
const ALTERBANK_URL = 'https://www.alterbank.com.br/'

const getOnlineBankUserNotFoundOptions = url => ({
  availableChoices: [
    {
      label: 'common.clickHere',
      actionToTake: 'createAccount',
      variant: 'raised',
      color: 'secondary'
    },
    {
      label: 'common.close',
      actionToTake: '',
      variant: 'raised',
      color: 'primary'
    }
  ],
  actionsToTake: {
    createAccount: () => window.open(url, '_blank')
  }
})

const regularTransferOptions = {
  availableChoices: [
    {
      label: 'common.close',
      actionToTake: '',
      color: 'secondary',
      variant: 'raised'
    }
  ],
  actionsToTake: {}
}

const invalidBankOptions = (label, route) => ({
  availableChoices: [
    {
      label: 'common.close',
      actionToTake: '',
    },
    {
      label: `common.${label}`,
      actionToTake:   '',
      component: Link,
      to: `/profile/${route}`,
      color: 'secondary',
      variant: 'raised'
    }
  ]
})

export const getBankTransferFailedDialogOptions = responseTitle => {
  switch (responseTitle) {
    case 'GLOBALCARD_USER_NOT_FOUND':
      return {
        title: responseTitle,
        ...getOnlineBankUserNotFoundOptions(GLOBAL_CARD_URL)
      }
    case 'ALTERBANK_USER_NOT_FOUND':
      return {
        title: responseTitle,
        ...getOnlineBankUserNotFoundOptions(ALTERBANK_URL)
      }
    case 'INVALID_BANK_ADDRESS':
      return {
        title: responseTitle,
        ...invalidBankOptions('updateProfile', 'account')
      }
    case 'INVALID_BANK_NUMBER':
      return {
        title: responseTitle,
        ...invalidBankOptions('updateProfile', 'account')
      }
    case 'USER_DOCS_NOT_APPROVED':
      return {
        title: responseTitle,
        ...invalidBankOptions('uploadDocs', 'operationalLimits')
      }
    default:
      return {
        title: responseTitle,
        ...regularTransferOptions
      }
  }
}
