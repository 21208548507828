import {
  put, takeLatest, call,
} from 'redux-saga/effects';
import { FETCH_TICKER } from './actionTypes';
import { updateTicker, addSnack } from '../actions';
import fetchTicker from '../../services/cbtcService/ticker';

function* fetchTickerSaga() {
  try {
    const allTickers = yield call(() => fetchTicker());
    if (allTickers) {
      const ticker = {
        BTC: allTickers['BTC-BRL'],
        ETH: allTickers['ETH-BRL'],
        USDT: allTickers['USDT-BRL'],
        BNB: allTickers['BNB-BRL'],
        CRZO: allTickers['CRZO-BRL'],
        PAXG: allTickers['PAXG-BRL'],
        SLP: allTickers['SLP-BRL'],
        AXS: allTickers['AXS-BRL'],
        CAKE: allTickers['CAKE-BRL'],
        UNI: allTickers['UNI-BRL'],
        SOL: allTickers['SOL-BRL'],
        POLIS: allTickers['POLIS-BRL'],
        USDC: allTickers['USDC-BRL'],
        USDBP: {
          "market":"USDBP-BRL",
          "last":0,
          "high":0,
          "low":0,
          "vol":0,
          "avg":0,
          "var":0,
          "buy":0,
          "sell":0,
          "timestamp":"2023-05-05 07:50:12"
        },
        DOT: allTickers['DOT-BRL'],
        MATIC: allTickers['MATIC-BRL'],
        LINK: allTickers['LINK-BRL'],
        XRP: allTickers['XRP-BRL'],
        ATLAS: allTickers['ATLAS-BRL'],
        ABFY: allTickers['ABFY-BRL'],
        ADA: allTickers['ADA-BRL'],
        LUNA: allTickers['LUNA-BRL'],
        LUNC: allTickers['LUNC-BRL'],
        GMT: allTickers['GMT-BRL'],
        BUSD: allTickers['BUSD-BRL'],
        // ELLO: allTickers['ELLO-BRL'],
        // ...allTickers[market],
      };
  
      yield put(updateTicker(ticker));
    }

  } catch (error) {
    console.error('fetchTickerSaga', error);

    yield put(addSnack({ message: error.message }));
  }
}

function* watchTicker() {
  yield takeLatest(FETCH_TICKER, fetchTickerSaga);
}

export default watchTicker;
