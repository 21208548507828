import React from 'react';
import { compose, pure } from 'recompose';

import { withStyles } from '@material-ui/core/styles';

import SelectField from '../../../components/Forms/SelectField';

const styles = theme => ({
  root: {
    flex: 1,
    paddingBottom: 100,
  },
  fieldItem: {
    padding: 0,
    ...theme.typography.body1,
    fontSize: theme.typography.pxToRem(13),
    color: theme.palette.text.secondary,
    display: 'flex',
    maxWidth: 300,
    '@media only screen and (max-width: 500px)' : {
      padding: 0,
      ...theme.typography.body1,
      fontSize: theme.typography.pxToRem(13),
      color: theme.palette.text.secondary,
      display: 'flex',
      maxWidth: 210
    }
  }
});

const SelectItemField = props => {
  const {
    canEdit, name, classes, i18nScope, tooltipTitle, normalize
  } = props;
  return (
    <SelectField
        disabled={!canEdit}
        classes={{
          root: classes.fieldItem,
        }}
        i18nScope={i18nScope}
        name={name}
        tooltipTitle={tooltipTitle}
        normalize={normalize}
        />
  );
};

const enhance = compose(
  withStyles(styles),   
  pure,
);

export default enhance(SelectItemField);
