import { Card, Typography, CardContent } from "@material-ui/core";
import React from "react";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { translate } from "react-i18next";

const styles = (theme) => ({
  sectionCard: {
    overflow: "visible",
    position: "relative",
    maxWidth: "100%",
    display: "flex",
    flex: 1,
    flexDirection: "column",
    padding: "30px",

    [theme.breakpoints.down("md")]: {
      padding: "30px 20px",
    },
  },
  cardContent: {
    padding: "0",
    "&:last-child": {
      paddingBottom: "0",
    },
  },
  title: {
    color: "#101010",
    fontSize: 20,
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
    textAlign: "center",
  },
});

const SectionCard = (props) => {
  const { classes, title, children, titleChildrenGap, className } = props;
  const gapStyle = { marginBottom: titleChildrenGap };
  return (
    <Card className={className != "" ? className : classes.sectionCard}>
      <CardContent className={classes.cardContent}>
        <Typography style={gapStyle} className={classes.title}>
          {title}
        </Typography>
        {children}
      </CardContent>
    </Card>
  );
};

const enhance = compose(withStyles(styles), translate());

SectionCard.propTypes = {
  padding: PropTypes.string,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  titleChildrenGap: PropTypes.string,
  className: PropTypes.string,
};

SectionCard.defaultProps = {
  padding: "30px",
  title: "",
  titleChildrenGap: "10px",
  className: "",
};
export default enhance(SectionCard);
