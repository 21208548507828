import { compose, withHandlers } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";

import { addDialog, closeDialog, removeCredentials, closeRenewConnectionDialog } from '../../redux/actions';
import Login from '../../pages/User/Login';
import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import { translate } from 'react-i18next';
import { DialogContent } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

const RenewConnectionDialog = props => {
  const {
    handleClose,
    logged,
    renewConnectionDialog: { open },
    t,
} = props;

  return (
    <Dialog
      open={open && logged}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
    >
      <DialogTitle
        align={'center'}
        id="alert-dialog-slide-title"
      >
        {t('screens.login.renewConnectionTitle')}
      </DialogTitle>
      <DialogContent>
        <Login {...props}/>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="raised"  color="secondary">
          {t('common.close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const mapStateToProps = ({ credentials: { logged, loadingStore, renewConnectionDialog }}) => ({
  logged,
  loadingStore,
  renewConnectionDialog
})

const mapDispatchToProps = dispatch => ({
  displayRenewConnectionDialog: props => dispatch(addDialog(props)),
  closeDialog: () => dispatch(closeDialog()),
  removeCredentials: () => dispatch(removeCredentials()),
  closeRenewConnectionDialog: () => dispatch(closeRenewConnectionDialog())
})

export default compose(
  translate(),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withHandlers({
    handleClose: ({closeRenewConnectionDialog, removeCredentials}) => _ => {
      closeRenewConnectionDialog();
      removeCredentials();
    }
  }),
)(RenewConnectionDialog)
