import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import Button from '@material-ui/core/Button';

const LinkButton = (props) => {
  const {
    history,
    to,
    stateData,
    onClick,
    ...rest
  } = props;
  return (
    <Button
      {...rest}
      onClick={(event) => {
        onClick && onClick(event);
        history.push({
            pathname: to,
            state: stateData
          })
      }}
    />
  )
};

LinkButton.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
};

export default withRouter(LinkButton)
