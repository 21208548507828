import {
  UPDATE_PROFILE,
  FETCH_PROFILE,

  ENABLE_SYNC_PROFILE,
  DISABLE_SYNC_PROFILE,

  UPDATE_PROFILE_REQUESTED,
  CONFIRM_UPDATE_PROFILE_REQUESTED,

  GENERATE_OTP_REQUESTED,
  ACTIVATE_OTP_REQUESTED,
  GENERATE_API_TOKEN_REQUESTED,
  GENERATE_API_TOKEN,

} from './actionTypes';

const fetchProfile = () => ({
  type: FETCH_PROFILE,
});

const enableSyncProfile = () => ({
  type: ENABLE_SYNC_PROFILE,
  sync: true,
});

const disableSyncProfile = () => ({
  type: DISABLE_SYNC_PROFILE,
  sync: false,
});


const updateProfile = profile => ({
  type: UPDATE_PROFILE,
  profile,
});

const generateOtpRequested = () => ({
  type: GENERATE_OTP_REQUESTED,
});

const generateApiTokenRequested = (form) =>({
  form,
  type: GENERATE_API_TOKEN_REQUESTED,
});

const generateApiToken = (form) =>({
  form,
  type: GENERATE_API_TOKEN,
});

const activateOtpRequested = otpParamns => ({
  type: ACTIVATE_OTP_REQUESTED,
  ...otpParamns,
});

const confirmUpdateProfileRequested = token => ({
  type: CONFIRM_UPDATE_PROFILE_REQUESTED,
  token,
});

const updateProfileRequested = profile => ({
  type: UPDATE_PROFILE_REQUESTED,
  profile,
});


export default {
  fetchProfile,

  enableSyncProfile,
  disableSyncProfile,

  updateProfile,
  updateProfileRequested,

  confirmUpdateProfileRequested,

  generateOtpRequested,
  activateOtpRequested,
};

export {
  fetchProfile,
  
  enableSyncProfile,
  disableSyncProfile,

  updateProfile,
  updateProfileRequested,

  confirmUpdateProfileRequested,

  generateOtpRequested,
  activateOtpRequested,
  generateApiTokenRequested,
  generateApiToken,


};
