const UPDATE_BANK_ACCOUNT = 'UPDATE_BANK_ACCOUNT';

const GET_BANK_ACCOUNT = 'GET_BANK_ACCOUNT';
const FETCH_BANK_ACCOUNT_REQUESTED = 'FETCH_BANK_ACCOUNT_REQUESTED';
const FETCH_BANK_ACCOUNT_SUCCEED = 'FETCH_BANK_ACCOUNT_SUCCEED';
const FETCH_BANK_ACCOUNT_FAILED = 'FETCH_BANK_ACCOUNT_FAILED';
const START_BANK_ACCOUNT_CHANGES_VALIDATION_TOKEN = 'START_BANK_ACCOUNT_CHANGES_VALIDATION_TOKEN';

export {
  UPDATE_BANK_ACCOUNT,

  GET_BANK_ACCOUNT,

  FETCH_BANK_ACCOUNT_REQUESTED,
  FETCH_BANK_ACCOUNT_SUCCEED,
  FETCH_BANK_ACCOUNT_FAILED,
  START_BANK_ACCOUNT_CHANGES_VALIDATION_TOKEN
};
