import { CountdownCircleTimer } from 'react-countdown-circle-timer'
import React from 'react';
import { useState } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';

const CircleTimer = (props) =>{
    const {
        isPlaying,
        duration,
        colors,
        size,
        strokeWidth,
        keyCounter,
        onComplete,
        initialRemainingTime,
        setRemainingTime,
    } = props;
    return (
        <CountdownCircleTimer
            isPlaying={isPlaying}
            duration={duration}
            colors={colors}
            size={size}
            strokeWidth={strokeWidth}
            key={keyCounter}
            onComplete={onComplete}
            initialRemainingTime={initialRemainingTime}
            onUpdate={(time) => {setRemainingTime && setRemainingTime(time)}}
        >
            {({ remainingTime }) => remainingTime}
        </CountdownCircleTimer>
    )
}


const enhance = compose(
)
  
  export default enhance(CircleTimer)
  
  