const FETCH_PUBLIC_DATA = 'FETCH_PUBLIC_DATA';
const FETCH_DATA_REQUESTED = 'FETCH_DATA_REQUESTED';
const FETCH_DATA_SUCCEED = 'FETCH_DATA_SUCCEED';
const FETCH_DATA_FAILED = 'FETCH_DATA_FAILED';
const UPDATE_PUBLIC_DATA = 'UPDATE_PUBLIC_DATA';
const SYNC_PUBLIC_DATA = 'SYNC_PUBLIC_DATA';

const FETCH_PRIVATE_DATA = 'FETCH_PRIVATE_DATA';
const UPDATE_PRIVATE_DATA = 'UPDATE_PRIVATE_DATA';
const FETCH_INITIAL_PRIVATE_DATA = 'FETCH_INITIAL_PRIVATE_DATA';
const FETCH_INITIAL_PUBLIC_DATA = 'FETCH_INITIAL_PUBLIC_DATA';
const UPDATE_DATA_BY_TYPE = 'UPDATE_DATA_BY_TYPE';

export {
  FETCH_PUBLIC_DATA,
  UPDATE_PUBLIC_DATA,
  SYNC_PUBLIC_DATA,
  FETCH_PRIVATE_DATA,
  UPDATE_PRIVATE_DATA,
  FETCH_DATA_REQUESTED,
  FETCH_DATA_SUCCEED,
  FETCH_DATA_FAILED,
  FETCH_INITIAL_PRIVATE_DATA,
  FETCH_INITIAL_PUBLIC_DATA,
  UPDATE_DATA_BY_TYPE,
};
