import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Hidden from '@material-ui/core/Hidden';

import enhance from './enhance';
import SumaryContent from '../../components/Summary/SummaryContent';
import PrefixedContent from '../../components/Summary/PrefixedContent';
import { Currency, getCurrencyBySymbol } from '../../models/Currency';


const styles = theme => ({
  root: {
    flex: 1,
    display: 'flex',
    height: theme.spacing.unit * 4,
    justifyContent: 'space-between',
    padding: theme.spacing.unit,
    paddingRight: 2 * theme.spacing.unit,
    paddingLeft: 2 * theme.spacing.unit,
    color: theme.palette.primary.contrastText,
    [theme.breakpoints.up('md')]: {
      left: theme.drawerWidth,
      width: `calc(100% - ${theme.drawerWidth}px)`,
    },
  },
  flex: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      left: theme.drawerWidth,
      width: `calc(100% - ${theme.drawerWidth}px)`,

    },
    zIndex: 2,
    position: 'fixed',
    left: 'auto',
    width: '100%',
    flexShrink: 0,
    height: theme.spacing.unit * 4,

    right: 0,
    justifyContent: 'space-around',
    padding: theme.spacing.unit,
    paddingRight: 2 * theme.spacing.unit,
    paddingLeft: 2 * theme.spacing.unit,
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.drawerBackgroundColor,
  },
  toolbar: theme.mixins.toolbar,
});


const UserBalanceToolbar = (props) => {
  const { classes, userBalance, market, t, currencies } = props;
  const currencyConfig = new Currency(getCurrencyBySymbol(currencies, market.split("-")[0]) || getCurrencyBySymbol(currencies, "BTC"))
  const label = `${t('balance.label')}:`;
  const decimalScale = currencyConfig.exhibitPrecision
  return (
    <Hidden mdUp>
      <div className={classes.flex}>
        <SumaryContent gutterBottom={false}>
          {label.toLocaleUpperCase()}
        </SumaryContent>
        <PrefixedContent
          value={userBalance[currencyConfig.symbol] || 0}
          decimalScale={decimalScale}
          prefix={`${currencyConfig.symbol} `}
        />

        <PrefixedContent value={userBalance.BRL} prefix="BRL " />
      </div>
      <div className={classes.root} />
    </Hidden>
  );
};

export default enhance(withStyles(styles)(UserBalanceToolbar));
