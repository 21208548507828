import { FETCH_MESSAGE_SUCCEED, FETCH_MESSAGE_FAILED } from './actionTypes';

const fetchMessageRequested = () => ({
  type: 'FETCH_SERVICE_REQUESTED',
  requestType: 'fetchMessage',
  successAction: FETCH_MESSAGE_SUCCEED,
  paramns: {
    privateService: true,
    options: {
      cmd: 'get_message',
      method: 'POST',
    },
  },
});

const fetchMessageSucceed = response => ({
  type: FETCH_MESSAGE_SUCCEED,
  response,
});

const fetchMessageFailed = () => ({
  type: FETCH_MESSAGE_FAILED,
});

export {
  fetchMessageRequested,
  fetchMessageSucceed,
  fetchMessageFailed,
};


export default {
  fetchMessageRequested,
  fetchMessageSucceed,
  fetchMessageFailed,
};
