import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  title: {
    flex: 1,
    display: 'flex',
    color: theme.palette.grey[500],
    fontWeight: 500,
    fontSize: 12
  },
});

const SummaryLabel = props => {
  const {
    classes,
    sumaryTitle,
  } = props;

  return (
    <Typography variant="caption" color="inherit" align="left" className={classes.title} noWrap>
      {sumaryTitle}
    </Typography>
  );
};

SummaryLabel.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  sumaryTitle: PropTypes.node.isRequired,
};

const enhance = compose(
  withStyles(styles),
);

export default enhance(SummaryLabel);
