import { FETCH_BANK_LIST_REQUESTED } from './actionTypes';


const fetchBankList = () => ({
  type: FETCH_BANK_LIST_REQUESTED,
});

export {
  fetchBankList,
};
