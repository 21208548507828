import { 
    put, takeLatest, 
} from 'redux-saga/effects'
import { contactSupport } from '../../../utils/contactSupport';
import { addDialog, } from '../../actions';
import { fetchService } from '../../actions';

import {
  FETCH_USER_CARDS_SUCCEED,
  FETCH_USER_CARDS,
  UPLOAD_STATUS_CARD,
  FAIL_UPDATE_CARD,
  FETCH_CASHBACK_SUCCEED,
  FETCH_CASHBACK_INFO_SUCCEED,
  FETCH_CASHBACK,
  FETCH_CASHBACK_INFO,
  FETCH_CARD_LIMITS,
  FETCH_CARD_LIMITS_SUCCEED,
} from "./actionsTypes";

function* fetchUserCards(_action) {
  try {
    yield put(fetchService({
      requestType: 'fetchUserCards',
      successAction: FETCH_USER_CARDS_SUCCEED,
      paramns: {
        privateService: true,
        options: {
          cmd: 'get_cards',
          method: 'POST',
        },
      },
    }));
  } catch (e) {
    console.error('fetchUserCards', e);
  }
}

function* fetchCardLimits (_action) {
  try {
    yield put(fetchService({
      requestType: 'fetchCardLimits',
      successAction: FETCH_CARD_LIMITS_SUCCEED,
      paramns: {
        privateService: true,
        options: {
          cmd: 'get_card_limits',
          method: 'POST',
        },
      },
    }));
  } catch (e) {
    console.error('fetchCardLimits', e);
  }
}

function* fetchCashback(_action) {
  try {
    yield put(
      fetchService({
        requestType: "fetchCashback",
        successAction: FETCH_CASHBACK_SUCCEED,
        paramns: {
          privateService: true,
          options: {
            cmd: "get_user_cashback",
            method: "POST",
          },
        },
      })
    );
  } catch (e) {
    console.error("fetchCashback error", e);
  }
}

function* fetchCashbackInfo(_action) {
  try {
    yield put(
      fetchService({
        requestType: "fetchCashbackInfo",
        successAction: FETCH_CASHBACK_INFO_SUCCEED,
        paramns: {
          privateService: true,
          options: {
            cmd: "get_user_cashback_info",
            method: "POST",
          },
        },
      })
    );
  } catch (e) {
    console.error("fetchCashbackInfo error", e);
  }
}
function* failUpdateCard(params) {
  const { response: { message_cod, remaining }} = params;

  if(message_cod === "PIN_AUTHENTICATION_ERROR"){
    yield put(
      addDialog({
        title: 'common.attention',
        centerTitle: true,
        renderComponent: 'onValidationPINFail',
        disableBackdropClick: true,
        componentProps: {
            remaining
        },
        availableChoices: [
            {
                label: 'common.understood',
                actionToTake: '',
                color: 'secondary',
                variant: 'raised'
            }
        ]
    } )
    );
  }

  else if(message_cod === "TOO_MANY_PIN_AUTHENTICATION_ERRORS"){
    yield put(
      addDialog({
        title: 'TOO_MANY_AUTHENTICATION_ERRORS',
        centerTitle: true,
        disableBackdropClick: true,
        availableChoices: [
            {
                label: 'CONTACT_SUPPORT',
                actionToTake: 'openIntercomChat',
                color: 'primary',
                variant: 'raised'
            },
            {
                label: 'common.understood',
                actionToTake: '',
                color: 'secondary',
                variant: 'raised'
            }
        ],
        actionsToTake: {
            openIntercomChat: () => contactSupport(),
        },
      })
    )
  }
  else {
    yield put(
      addDialog({
        title: message_cod,
        centerTitle: true,
        disableBackdropClick: true,
        availableChoices: [
            {
                label: 'common.close',
                actionToTake: '',
                color: 'secondary',
                variant: 'raised'
            }
        ],
      })
    )

  }
}

function* uploadStatusCard(params) {
  const {id, status, pin} = params;
  yield put(
    addDialog({
      title: 'common.processing',
      centerTitle: true,
      disableBackdropClick: true,
      availableChoices: [],
    }),
  );
  try {
    yield put(fetchService({
      requestType: 'fetchUserCards',
      successAction: FETCH_USER_CARDS,
      responseToUser: {
        onSuccess: 'dialog',
      },
      failAction: 'FAIL_UPDATE_CARD',
      paramns: {
        privateService: true,
        form: {
          card_id: id,
          blocked: status,
          pin: pin,
        },
        options: {
          cmd: 'set_card_block',
          method: 'POST',
        },
      },
    }));
  } catch (e) {
    console.error('fetchUserCards', e);
  }
}

function* watchCards() {
    yield takeLatest(FETCH_USER_CARDS, fetchUserCards);
    yield takeLatest(UPLOAD_STATUS_CARD, uploadStatusCard);
    yield takeLatest(FAIL_UPDATE_CARD, failUpdateCard);
    yield takeLatest(FETCH_CASHBACK, fetchCashback);
    yield takeLatest(FETCH_CASHBACK_INFO, fetchCashbackInfo);
    yield takeLatest(FETCH_CARD_LIMITS, fetchCardLimits);
}
  
export default watchCards;
  