import React from 'react';
import TVChartContainer from '../components/TVChartContainer/index';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
})

const Chart = (props) => {
  return  <TVChartContainer {...props} /> 
};


const enhance = compose(
  withStyles(styles)
)

export default enhance(Chart)
