export const ORDER_TYPES = {
  BUY: 'BUY',
  SELL: 'SELL',
  STOPLOSS: 'STOPLOSS'
}


const MIN_BTC_AMOUNT = 0.0001
const MIN_ETH_AMOUNT = 0.01
const MIN_USDT_AMOUNT = 5
const MIN_BNB_AMOUNT = 0.001
const MIN_CRZO_AMOUNT = 0.001
const MIN_PAXG_AMOUNT = 0.001

export {
  MIN_USDT_AMOUNT,
  MIN_BTC_AMOUNT,
  MIN_ETH_AMOUNT,
  MIN_BNB_AMOUNT,
  MIN_CRZO_AMOUNT,
  MIN_PAXG_AMOUNT
}

export const getDefaultLimit = marketSymbol =>{
  switch (marketSymbol) {
    case 'BTC':
      return MIN_BTC_AMOUNT;
    case 'USDT':
      return MIN_USDT_AMOUNT;
    case 'ETH':
      return MIN_ETH_AMOUNT;
    case 'BNB':
      return MIN_BNB_AMOUNT;
    case 'CRZO':
      return MIN_CRZO_AMOUNT;
    case 'PAXG':
      return MIN_PAXG_AMOUNT;
    default:
      return MIN_ETH_AMOUNT;
  }
}

export const ORDER_MARGIN_TO_APPLY = 0.02;
