import React from 'react';
import { compose, withHandlers, withState } from 'recompose';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import grey from '@material-ui/core/colors/grey';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp'

import { translate } from 'react-i18next';

import {
  handleAppBarMenu,
} from '../../redux/actions';

const styles = theme => ({
  root: {
    position: 'relative',
    zIndex: 3,
    marginLeft: '-15px'
  },
  icon: {
    color: '#bdbdbd'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',

  },
  listOverflow: {
    maxHeight: '70vh',
    overflow: 'auto'
  },
  paper: {
    backgroundColor: theme.drawerBackgroundColor,
    color: theme.palette.grey[400],
    position: 'absolute',
    marginRight: 'auto'
  },
  listItemText: {
    color: theme.palette.grey[400]
  },
  listItemIcon: {
    marginLeft: '-8px',
    width: 32
  },
  listGroup:{
    zIndex: 3
  },
  fake: {
    backgroundColor: grey[200],
    height: theme.spacing.unit,
    margin: theme.spacing.unit * 2,
    // Selects every two elements among any group of siblings.
    '&:nth-child(2n)': {
      marginRight: theme.spacing.unit * 3
    }
  }
});

const AppBarTokenMobile = props => {
  const {
    classes,
    setIsOpen,
    isLogged,
    anchorEl,
    handleClick,
  } = props;
  return (
    <div className={classes.root}>
      <div className={classes.row}>
        <ClickAwayListener onClickAway={() => setIsOpen(false)}>
          <div>
            <List dense>
              <ListItem
                button
                onClick={(e) => handleClick(anchorEl ? null : e.currentTarget, !isLogged)}
              >
                {
                  Boolean(anchorEl)
                    ? <KeyboardArrowUp fontSize={32} />
                    : <KeyboardArrowDown fontSize={32} />
                }
              </ListItem>
            </List>
          </div>
        </ClickAwayListener>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  market: state.market.selectedMarket,
  taxID: state.user.profile.tax_id,
  kycLevel: state.user.profile.kyc_level,
  anchorEl: state.layout.appBar.anchorEl,
  currencies: state.currencies.currencies,
  markets: state.currencies.markets
});

const mapDispatchToProps = (dispatch) => ({
  setAnchorEl: (anchorEl, isSelectorCurrencyFilter) =>
    {
      dispatch(handleAppBarMenu(anchorEl, isSelectorCurrencyFilter))},
});

const enhance = compose(
  withStyles(styles),
  withState("open", "setIsOpen", false),
  translate(),
  connect(mapStateToProps, mapDispatchToProps),
  withHandlers({
    handleClick:
      ({ setAnchorEl }) =>
      (value, isSelectorCurrencyFilter) =>
        setAnchorEl(value, isSelectorCurrencyFilter),
    handleClose:
      ({ setAnchorEl }) =>
      () =>
        setAnchorEl(null),
  })
);

export default enhance(AppBarTokenMobile);
