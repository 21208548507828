import * as R from 'ramda';
import { put, takeLatest } from 'redux-saga/effects';
import snackActions, { addSnack } from './actions';
import { ADD_SNACK, ADD_RESPONSE_SNACK } from './actionTypes';

const messagesToIgnore = [
  'Throttle error'
];

function* addSnackSaga(action) {
  try {
    if (R.contains(action.message, messagesToIgnore)) {
      return;
    }
    yield put(snackActions.readSnack());

    yield put(snackActions.openSnack(action));
  } catch (error) {
    console.error('addSnackSaga', error);
  }
}

function* addResponseSnack({ response = {} }) {
  const message = R.prop('message_cod', response);
  try {
    yield put(addSnack({ message }));
  } catch (error) {
    console.error('addResponseSnack', error);
  }
}


function* watchSnacks() {
  yield takeLatest(ADD_SNACK, addSnackSaga);
  yield takeLatest(ADD_RESPONSE_SNACK, addResponseSnack);
}

export default watchSnacks;
