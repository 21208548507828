import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { withStyles } from '@material-ui/core/styles';
import {
  compose
} from 'recompose';

import OpDocInput from './OpDocInput';

const styles = _theme => ({
  root: {
   fontWeight: '500',
    color: 'rgba(0, 0, 0, 0.87)',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', 'sans-serif'",
  }
});

const ReadableLabel = props => {
  const { status, t } = props;

  const statusString = (typeof status !== 'string' && typeof status.toString === 'function')
    ? status.toString()
    : status;

  switch (statusString) {
    case 'SENT':
      return t('operationalLimits.SENT');

    case 'OK':
      return t('operationalLimits.OK');

    case 'UPLOADING':
      return t('operationalLimits.UPLOADING');

    default:
      return t('operationalLimits.false');
  }
}

const DocListItem = (props) => {
  const { label, classes } = props;

  return (
    <ListItem>
      <ListItemText classes={{root: classes.root}} disableTypography={true} primary={label}  />
      <ListItemText secondary={ReadableLabel(props)} style={{ textAlign: 'right' }} />
      <ListItemIcon>
        <OpDocInput {...props} />
      </ListItemIcon>
    </ListItem>
  );
};

const enhance = compose(
  withStyles(styles),
);


export default enhance(DocListItem);
