import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';

const SummaryContent = props => {
  const {
    children,
    ...rest
  } = props;

  return (
    <Typography variant="caption" color="inherit" gutterBottom {...rest}>
      {children}
    </Typography>
  );
};

SummaryContent.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SummaryContent;
