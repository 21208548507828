import React from 'react';
import { render } from 'react-dom';
import App from './App';
import { unregister } from './registerServiceWorker';

import './i18n';
import "./custom-classes.css"

render(<App className="grecaptcha-badge" />,
  document.getElementById('root'));
unregister();
