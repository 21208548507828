import React from 'react';
import * as R from 'ramda'
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';

const BrasilNumberFormat = (props) => {
  const { value } = props

  const hasNumericInput = R.not(R.isEmpty(value))
  const valueAsString = hasNumericInput
    ? Number(value).toFixed(2)
    : ''
  const decimalScale = props.decimalScale ?  props.decimalScale : 2;
  return (
    <NumberFormat
      displayType="text"
      fixedDecimalScale
      decimalScale={decimalScale}
      decimalSeparator=","
      thousandSeparator="."
      isNumericString={true}
      value={valueAsString}
      {...props}
    />
  );
};

BrasilNumberFormat.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default BrasilNumberFormat;
