import React from 'react';
import { translate } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import { DEPOSIT_BANK_INFO } from '../config';

const styles = theme => ({
    container: {
       marginTop: 13,
       marginBottom: 13
    },
    textStyle: {
        fontSize: 13,
        marginTop: '-3px',
        marginBottom: '-3px',
        color: "#757575",
        letterSpacing: '-0.5px'
    },

    textStylePix: {
        fontSize: 15,
        marginTop: '0px',
        marginBottom: '-3px',
        color: "black",
        letterSpacing: '-0.5px'
    },

        textStyleHeader: {
        fontSize: 15,
        marginTop: '10px',
        marginBottom: '-3px',
        color: "#757575",
        letterSpacing: '-0.5px'
    }
})

const BankInfo = ({ bankInformation, classes }) => {
    return (
        <div className={classes.container}>
            <Typography className={classes.textStyle}> {bankInformation.bankName} ({bankInformation.bank})</Typography>
            <Typography className={classes.textStyle}> Agência: {bankInformation.agency} </Typography>
            <Typography className={classes.textStyle}> Conta Corrente: {bankInformation.account} </Typography>
            <Typography className={classes.textStyle}> CNPJ: 29.738.313/0001-23 </Typography>
            <Typography styles={{fontWeight: 'bold'}} className={classes.textStyleHeader}>CHAVE PIX:<Typography styles={{fontWeight: 'bold'}} className={classes.textStylePix}>{bankInformation.pix}</Typography></Typography>
        </div>
    )
}

BankInfo.propTypes = {
    bankInformation: PropTypes.shape({
        bankName : PropTypes.string.isRequired,
        agency: PropTypes.string.isRequired,
        account: PropTypes.string.isRequired,
        bank: PropTypes.string.isRequired
    }),
};

export default translate()(withStyles(styles)(BankInfo))
