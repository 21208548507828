import * as R from 'ramda';

export const cpfCnpjMask = (val = '') => {
  const valLength = val.length;

  if (valLength <= 3) {
    return val;
  }

  if (valLength <= 9) {
    const values = R.splitEvery(3, val);
    return R.join('.', values);
  }

  if (valLength <= 11) {
    const [cpf, digito] = R.splitAt(9, val);
    const values = R.splitEvery(3, cpf);
    return `${R.join('.', values)}-${digito}`;
  }

  const [cnpj1, resto] = R.splitAt(2, val);
  const [cnpj2, rest2] = R.splitAt(6, resto);

  const cnpj2Val = R.join('.', R.splitEvery(3, cnpj2));

  const rest2Val = rest2.length <= 4 ? rest2 : R.join('-', R.splitAt(4, rest2));

  const agoraVai = `${cnpj1}.${cnpj2Val}/${rest2Val}`;

  if (valLength <= 14) {
    return agoraVai;
  }
  return R.tail(agoraVai);
};
