import { connect } from 'react-redux';
import { compose } from 'recompose';
import { translate } from 'react-i18next';

const mapStateToProps = ({ user: { balance }, market, currencies }) => ({
  userBalance: balance,
  market: market.selectedMarket,
  currencies: currencies.currencies
});


const enhance = compose(
  // pure,
  translate(),
  connect(mapStateToProps),
);

export default enhance;
