import { useEffect } from "react";
import { Socket } from "phoenix";
import { SOCKET_URL } from "../config";
import { debounce } from "lodash";

const filterNotifications = (notifications, type = undefined) => {
  return notifications.filter((n) => {
    const payload = JSON.parse(n.payload);
    return payload && payload.representation === type;
  });
};

const parseNotification = (receivedNotification) => {
  return {
    ...receivedNotification,
    payload: JSON.parse(receivedNotification.payload),
  };
};

const parseAlert = (receivedAlert) => {
  const payload = JSON.parse(receivedAlert.payload);
  return {
    message: payload.subtitle,
    variant: "warning",
    createdAt: receivedAlert.createdAt,
  };
};

const useNotifications = (
  authToken,
  handleNotification,
  sync,
  stop,
  setNotifications,
  addNotification,
  updateAlert
) => {
  const debouncedHandleNotification = debounce(handleNotification, 1200);
  useEffect(() => {
    if (!authToken) return;
    const socket = new Socket(`${SOCKET_URL}/notifications/socket`);
    socket.connect();
    socket.onOpen(() => {
      stop();
    });
    socket.onError((e) => {
      sync();
    });

    const alertChannel = socket.channel("notifications:alerts", {});
    alertChannel.join().receive("ok", (resp) => {
      // update alert
      console.log("UPDATE ALERT", resp);
      updateAlert(resp);
    });

    alertChannel.on("new", (alert) => {
      // update alert
      console.log("new ALERT", alert);
      updateAlert(alert);
    });

    alertChannel.on("remove", () => {
      console.log("REMOVE ALERT");
      updateAlert(null);
    });

    function setNotificationsReadForUser() {
      alertChannel.push("setNotificationsRead", { authToken });
    }
    window.setNotificationsRead = setNotificationsReadForUser;

    const channel = socket.channel(`notifications:${authToken}`, {});
    channel
      .join()
      .receive("ok", (resp) => {
        const notifications = resp.notifications || [];
        const parsedNotifications = filterNotifications(notifications).map(
          (n) => parseNotification(n)
        );
        const parsedAlerts = filterNotifications(notifications, "alert")
          .map((alert) => parseAlert(alert))
          .sort((prev, current) => {
            const timestampPrev = Date.parse(prev.createdAt);
            const timestampCurrent = Date.parse(current.createdAt);
            return timestampPrev < timestampCurrent ? -1 : 1;
          });
        setNotifications(parsedNotifications);
        if (parsedAlerts.length > 0)
          updateAlert(parsedAlerts[parsedAlerts.length - 1]);
      })
      .receive("error", (resp) => {
        console.log("Unable to join Notifications", resp);
      });

    channel.on("flash", (notification) => {
      if (
        notification.payload &&
        notification.payload.representation === "alert"
      ) {
        updateAlert({
          message: notification.payload.subtitle,
          variant: "warning",
        });
      } else {
        debouncedHandleNotification(notification);
        if (notification && notification.persist) {
          addNotification(notification);
        }
      }
    });

    return () => socket.disconnect();
  }, [authToken]);
};

export default useNotifications;
