import React, { useState, useEffect } from 'react';
import { compose, withState } from 'recompose';
import { connect } from 'react-redux';

import { withStyles } from '@material-ui/core/styles';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Paper from '@material-ui/core/Paper';
import grey from '@material-ui/core/colors/grey';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp'
import CurrencyIcon from '../../components/CurrencyIcon';
import InfoTooltip from '../../components/InfoTooltip';
import { Currency, getCurrenciesByType, getCurrencyBySymbol } from '../../models/Currency';
import CurrencySearchInput from '../../containers/AppDashboard/CurrencySearchInput';
import { isObjectNotEmpty } from '../../utils/objectFunctions';
import ActivityIndicator from '../../components/ActivityIndicator';

import { translate } from 'react-i18next';

import {
  setSelectedMarket
} from '../../redux/actions';

const styles = theme => ({
  root: {
    position: 'relative',
  },
  searchInputContainer: {
    padding: "20px 0 10px",
    width:"95%",
    margin: "0 auto"
  },
  listItemSecondaryVariant: {
    height: 33,
    paddingLeft : 10,
    paddingRight : 10
  },
  listItem: {
    paddingLeft : 10,
    paddingRight : 10
  },
  icon: {
    color: '#bdbdbd'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  rowSecondaryVariant: {
    alignItems: 'center',
    backgroundColor: '#616161',
    borderRadius: 5,
    marginRight: '4%',
    marginLeft: '6%',
    zIndex: 10
  },
  paper: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.grey[400],
    position: 'absolute',
    top: 55,
    right: 0,
    left: 0
  },
  paperSecondaryVariant: {
    backgroundColor: '#373737',
    color: theme.palette.grey[400],
    position: 'absolute',
    top: 38,
    width: '90%',
    zIndex: 1
  },
  listItemText: {
    color: theme.palette.grey[400],
  },
  dividerText: {
    display: 'flex',
    color: theme.palette.grey[400],
    alignItems: 'center',
    flexDirection: 'column'
  },
  textSizing:{
    fontSize: '0.875rem'
  },
  paperSpacing:{
    paddingLeft : 10,
    paddingRight : 10
  },
  fake: {
    backgroundColor: grey[200],
    height: theme.spacing.unit,
    margin: theme.spacing.unit * 2,
    // Selects every two elements among any group of siblings.
    '&:nth-child(2n)': {
      marginRight: theme.spacing.unit * 3
    }
  },
  listDivider: {
    color: theme.palette.grey[400],
    borderBottom: '1px solid',
    borderColor: theme.palette.grey[400]
  },
  dividerHelp: {
    display: 'flex',
  },
  listOverflow: {
    maxHeight: '85vh',
    overflow: 'auto'
  },
  loaderContainer: {
    padding: '10px 0',
  }
});

const getClassVariant = (variant, classes) => {
  return {
    row: variant === 'secondary' ? classes.rowSecondaryVariant : classes.row,
    listItem: variant === 'secondary' ? classes.listItemSecondaryVariant : classes.listItem,
    paper: variant === 'secondary' ? classes.paperSecondaryVariant : classes.paper
  }
}

const AppBarMenuItems = ({ classes, tokens, markets, ...props }) =>
  tokens.map(token => (
    <ListItem
      classes={{ root: classes.paperSpacing } }
      button
      key={token.market}
      onClick={() => {
        props.setSelectedMarket({market:`${token.symbol}-BRL`})
        props.setIsOpen(false)
      }}
    >
      <ListItemIcon>
        <CurrencyIcon currency={token}/>
      </ListItemIcon>
      <ListItemText
        classes={{
          root: classes.textSizing,
          primary: classes.listItemText }}
      >
        {`${token.name} (${token.symbol})`}
      </ListItemText>
    </ListItem>
  ))

const AppBarTokenMenu = props => {
  const {
    market,
    classes,
    open,
    setIsOpen,
    setSelectedMarket,
    variant,
    user,
    t,
    currencies,
    markets
  } = props;
  
const symbol = market.split('-')[0];
const currency = new Currency(getCurrencyBySymbol(currencies, symbol) || getCurrencyBySymbol(currencies, "BTC"));
const [selectedCurrencies, setSelectedCurrencies] = useState([]);
const marketsHasLoaded = isObjectNotEmpty(markets);

useEffect(() => {
  if (marketsHasLoaded || (marketsHasLoaded && !open)) {
    setSelectedCurrencies(currencies);
  }
}, [markets, open]);

const filterCurrencies = (event) => {
  const value = event.target.value;
  const filteredCurrencies = currencies.filter(currency => {
    return currency.name.toLowerCase().includes(value.toLowerCase()) || currency.symbol.toLowerCase().includes(value.toLowerCase());
  }
  );
  setSelectedCurrencies(filteredCurrencies);
}

const distinctTokenTypes = ["marketplace", "exchange"];
  let typeUser = ''
  return (
    <div className={classes.root}>
      <div className={getClassVariant(variant, classes).row}>
        <ClickAwayListener onClickAway={() => setIsOpen(false)}>
          <div>
            <List dense>
              <ListItem
                button
                classes={{ root: getClassVariant(variant, classes).listItem }}
                onClick={() => setIsOpen(!open)}
              >
                <ListItemIcon classes={{ root: classes.listItemText }}>
                  <CurrencyIcon currency={currency} />
                </ListItemIcon>
                <ListItemText
                  primary={currency.currencyLabel}
                  classes={{
                    root: classes.textSizing,
                    primary: classes.listItemText,
                  }}
                />
                {open ? (
                  <KeyboardArrowUp fontSize={32} />
                ) : (
                  <KeyboardArrowDown fontSize={32} />
                )}
              </ListItem>
            </List>
            {open ? (
              <Paper
                elevation={0}
                className={getClassVariant(variant, classes).paper}
              >
                <div className={classes.searchInputContainer}>
                  <CurrencySearchInput
                    placeholder={t(
                      "screens.coinsSelect.coinsSearchInputPlaceholderShort"
                    )}
                    onChange={filterCurrencies}
                  />
                </div>
                {marketsHasLoaded ? (
                <List dense classes={{ root: classes.listOverflow }}>
                  {distinctTokenTypes.map((tokenType, index) => {
                    if (tokenType === "marketplace") {
                      typeUser = user.kyc_level <= 3 ? "0.2%" : "Zero";
                    } else {
                      typeUser = "0.2%";
                    }

                    const tokensByType = getCurrenciesByType(
                      selectedCurrencies,
                      markets,
                      tokenType === "marketplace"
                    );

                    return tokensByType.length > 0 ? (
                      <React.Fragment key={index}>
                        <ListItem>
                          <ListItemText
                            primary={
                              <div className={classes.dividerHelp}>
                                {t(`common.${tokenType}`)}
                                <InfoTooltip
                                  wrapperRootHeader={true}
                                  title={t(`info.${tokenType}`, {
                                    fee: typeUser,
                                  })}
                                />
                              </div>
                            }
                            classes={{
                              root: classes.listDivider,
                              primary: classes.dividerText,
                            }}
                          />
                        </ListItem>
                        <AppBarMenuItems
                          tokens={tokensByType}
                          variant={variant}
                          setIsOpen={setIsOpen}
                          setSelectedMarket={setSelectedMarket}
                          classes={classes}
                        />
                      </React.Fragment>
                    ) : (
                      <></>
                    );
                  })}
                </List>
                ) : (
                  <div className={classes.loaderContainer}>
                  <ActivityIndicator color="secondary" size={25} />
                  </div>
                )}
              </Paper>
            ) : null}
          </div>
        </ClickAwayListener>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  market: state.market.selectedMarket,
  user: state.user.profile,
  currencies: state.currencies.currencies,
  markets: state.currencies.markets
})

const mapDispatchToProps = dispatch => ({
  setSelectedMarket: market => dispatch(setSelectedMarket(market))
});

const enhance = compose(
  withStyles(styles),
  withState('open', 'setIsOpen', false),
  translate(),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
);

export default enhance(AppBarTokenMenu);
