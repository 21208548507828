import privateService from '../privateService'

const fetchWalletAddress = async (blockchain) => {
  const options = {
    cmd: 'deposit_addr',
    method: 'POST'
  }
  const form = {
    blockchain
  }

  const content = await privateService(options, form)
  return content
}

export default fetchWalletAddress

