import React from 'react';
import { v4 } from 'uuid';
import { withStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const styles = theme => ({
  close: {
    width: theme.spacing.unit * 4,
    height: theme.spacing.unit * 4,
  },
});

const SnackBarFactory = withStyles(styles)(
  ({
    open, handleClose, message, handleAction, actionMessage = false, classes,
  }) =>
    (
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        ContentProps={{
          'aria-describedby': message,
        }}
        message={<span id={message}>{message}</span>}
        action={[
          actionMessage &&
          <Button key="undo" color="primary" size="small" onClick={handleAction}>
            {actionMessage}
          </Button>,
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            className={classes.close}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>,
        ]}
      />
    ));

const SnackBarComponent = props => {
  const {
    snacks = {}, readSnack, t,
  } = props;

  const { message, i18nScope, ...snack } = snacks;
  return (
    <div>
      <SnackBarFactory
        key={`${message}_${v4()}`}
        message={t(i18nScope ? `${i18nScope}.${message}` : message)}
        {...{
          ...snack,
          handleClose: () => readSnack(message),
          handleAction: () => {
            snack.action();
            readSnack(message);
          },
        }}
      />
    </div>
  );
};

export default SnackBarComponent;
