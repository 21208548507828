import { merge } from 'ramda';
import * as actions from './actionTypes';

const initialState = {
  open: false,
};

const openSnack = (state, action) => {
  return merge(state, {
    open: true,
    ...action,
  });
};


const snackRead = (state, _action) => {
  return merge(state, { open: false });
};


const modalReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.ADD_SNACK:
      return snackRead(state, action);

    case actions.OPEN_SNACK:
      return openSnack(state, action);

    case actions.READ_SNACK:
      return snackRead(state, action);

    default:
      return state;
  }
};

export default modalReducer;
