import {
  put,
  takeLatest,
  call,
  cancel,
  fork,
  take,
  all,
} from "redux-saga/effects";
import { delay } from "redux-saga";
import {
  FETCH_PRIVATE_DATA,
  FETCH_INITIAL_PRIVATE_DATA,
  UPDATE_DATA_BY_TYPE,
  FETCH_INITIAL_PUBLIC_DATA,
} from "./actionTypes";

import fetchTicker from "../../../services/cbtcService/ticker";
import * as R from "ramda";

import {
  updateTicker,
  fetchOrdersRequested,
  fetchBalance,
  fetchProfile,
  fetchMessageRequested,
  fetchUserCards,
  fetchMarket,
  fetchCurrencies,
  fetchReferrals,
  fetchPixAccount,
  updateIntercomHash,
  fetchExtract,
  fetchExecutedOrdersRequested,
} from "../../actions";

import { SHORT_SYNC_INTERVAL, LONG_SYNC_INTERVAL } from "../../../config";
import { serviceUpdateTypes } from "../../../utils/constants";

// Atualiza a cada 60 segundos, somente se a pagina estiver visivel
function* longPublicLazySync() {
  while (true) {
    try {
      if (!document.hidden) {
        const allTickers = yield call(() => fetchTicker());
        const ticker = {
          BTC: allTickers["BTC-BRL"],
          ETH: allTickers["ETH-BRL"],
          USDT: allTickers["USDT-BRL"],
          BNB: allTickers["BNB-BRL"],
          CRZO: allTickers["CRZO-BRL"],
          PAXG: allTickers["PAXG-BRL"],
          SLP: allTickers["SLP-BRL"],
          AXS: allTickers["AXS-BRL"],
          CAKE: allTickers["CAKE-BRL"],
          UNI: allTickers["UNI-BRL"],
          SOL: allTickers["SOL-BRL"],
          POLIS: allTickers["POLIS-BRL"],
          USDC: allTickers["USDC-BRL"],
          USDBP: allTickers["USDBP-BRL"],
          ATLAS: allTickers["ATLAS-BRL"],
          ABFY: allTickers["ABFY-BRL"],
          ADA: allTickers["ADA-BRL"],
          LUNA: allTickers["LUNA-BRL"],
          LUNC: allTickers["LUNC-BRL"],
          GMT: allTickers["GMT-BRL"],
          BUSD: allTickers["BUSD-BRL"],
          DOT: allTickers["DOT-BRL"],
          MATIC: allTickers["MATIC-BRL"],
          LINK: allTickers["LINK-BRL"],
          XRP: allTickers["XRP-BRL"],
        };

        yield put(updateTicker(ticker));
        yield call(delay, LONG_SYNC_INTERVAL);
      } else {
        yield call(delay, SHORT_SYNC_INTERVAL);
      }
    } catch (error) {
      console.error("longPublicLazySync", error);
      yield call(delay, SHORT_SYNC_INTERVAL);
    }
  }
}

function* shortPrivateSync() {
  while (true) {
    try {
      yield put(fetchMessageRequested());
      yield call(delay, SHORT_SYNC_INTERVAL);
    } catch (err) {
      console.error("startPrivateSync", err);
      yield call(delay, SHORT_SYNC_INTERVAL);
    }
  }
}

// Atualiza quando o usuário inicia a sessão ou faz alguma transação
function* restPrivateFetch() {
  try {
    yield put(fetchBalance());
    yield put(fetchProfile());
  } catch (error) {
    console.error("restPrivateFetch", error);
  }
}

// Atualiza somente quando o usuário inicia a sessão
function* fetchInitialPrivateData() {
  try {
    yield put(fetchUserCards());
    yield put(fetchReferrals());
    yield put(fetchPixAccount());
    yield put(updateIntercomHash());
    yield put(fetchMarket());
    yield put(fetchCurrencies());
  } catch (error) {
    console.error("fetchInitialPrivateData", error);
  }
}

// Atualiza somente quando o usuário inicia a sessão
function* fetchInitialPublicData() {
  try {
    yield put(fetchMarket());
    yield put(fetchCurrencies());
  } catch (error) {
    console.error("fetchInitialPrivateData", error);
  }
}

// function* startPublicSync() {
//   try {
//     yield all([
//       call(() => longPublicLazySync()),
//       // call(() => shortPublicLazySync()),
//     ]);
//   } catch (err) {
//     console.error('startPublicSync', err);
//   }
// }

function* fetchPrivateDataSaga() {
  try {
    yield call(() => restPrivateFetch());
  } catch (error) {
    console.error("fetchPrivateDataSaga", error);
  }
}

function* toggleSyncTicker() {
  while (yield take("ENABLE_SYNC_TICKER")) {
    const bgTickerTask = yield fork(longPublicLazySync);
    yield take("DISABLE_SYNC_TICKER");
    yield cancel(bgTickerTask);
  }
}

function* toggleSyncNotifications() {
  while (yield take("ENABLE_SYNC_NOTIFICATIONS")) {
    const bgNotificationsTask = yield fork(shortPrivateSync);
    yield take("DISABLE_SYNC_NOTIFICATIONS");
    yield cancel(bgNotificationsTask);
  }
}

function* fetchDataByType({ updateTypes }) {
  if (
    R.intersection(updateTypes, [
      serviceUpdateTypes.PROFILE,
      serviceUpdateTypes.USER,
      serviceUpdateTypes.INITIAL,
    ]).length > 0
  ) {
    yield put(fetchProfile());
  }

  if (
    R.intersection(updateTypes, [
      serviceUpdateTypes.INITIAL,
      serviceUpdateTypes.LOGIN,
    ]).length > 0
  ) {
    yield put(fetchReferrals());
    yield put(updateIntercomHash());
    yield put(fetchMarket());
    yield put(fetchCurrencies());
    yield put(fetchOrdersRequested());
  }

  if (
    R.intersection(updateTypes, [serviceUpdateTypes.TRANSACTIONS]).length > 0
  ) {
    yield put(fetchExtract());
  }

  if (
    R.intersection(updateTypes, [
      serviceUpdateTypes.BALANCE,
      serviceUpdateTypes.TRANSACTIONS,
      serviceUpdateTypes.LOGIN,
      serviceUpdateTypes.INITIAL,
    ]).length > 0
  ) {
    yield put(fetchBalance());
  }

  if (
    R.intersection(updateTypes, [
      serviceUpdateTypes.USER,
      serviceUpdateTypes.INITIAL,
      serviceUpdateTypes.LOGIN,
    ]).length > 0
  ) {
    yield put(fetchUserCards());
    yield put(fetchPixAccount());
  }

  if (R.intersection(updateTypes, [serviceUpdateTypes.ORDER]).length > 0) {
    yield put(fetchOrdersRequested());
  }
  if(R.intersection(updateTypes, [serviceUpdateTypes.EXECUTED_ORDERS]).length > 0) {
    yield put(fetchExecutedOrdersRequested());
  }
}

function* watchAppData() {
  // yield takeLatest(SYNC_PUBLIC_DATA, startPublicSync);
  yield takeLatest(FETCH_PRIVATE_DATA, fetchPrivateDataSaga);
  yield takeLatest(FETCH_INITIAL_PRIVATE_DATA, fetchInitialPrivateData);
  yield takeLatest(FETCH_INITIAL_PUBLIC_DATA, fetchInitialPublicData);
  yield takeLatest(UPDATE_DATA_BY_TYPE, fetchDataByType);

  yield all([
    call(() => toggleSyncTicker()),
    call(() => toggleSyncNotifications()),
  ]);
}

export default watchAppData;
