import { connect } from 'react-redux';
import { compose, lifecycle } from 'recompose';
import { fetchExtract } from '../../../redux/actions';

const formRequest = {
  currency: 'BRL,CBRL,BRZ,REALT,RAS',
  type: 'WITHDRAWAL,DEPOSIT',
};

const mapStateToProps = state => ({
  extract: state.user.extract.extracts,
});

const mapDispatchToProps = dispatch => ({
  fetchExtractData: () => dispatch(fetchExtract(formRequest)),
});

const enhance = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  lifecycle({
    componentDidMount() {
      this.props.fetchExtractData();
    },
  }),
);

export default enhance;
