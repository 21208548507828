export const styles = (theme) => ({
  switchInputButton: {
    width: 30,
    height: 30,
  },
  editIcon: {
    width: 22,
    height: 22,
  },
  exhibitionAmountContainer: {
    [theme.breakpoints.down("xs")]: {
      justifyContent: "center",
      marginTop: theme.spacing.unit ,
    },
  },
});
