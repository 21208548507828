import { compose } from 'recompose';
import { connect } from 'react-redux';

import { translate } from 'react-i18next';
import AppBar from './AppBar';
import { removeCredentials } from '../../redux/actions';


const mapDispatchToProps = dispatch => ({
  removeCredentials: () =>
    dispatch(removeCredentials()),
});

const enhance = compose(
  translate(),
  connect(null, mapDispatchToProps),
);

export default enhance(AppBar);
