import { useState, useEffect } from 'react';
import { FIREBASE_FEATURE_FLAGS, FirebaseService } from '../config';

const useShowCryptoConverter = (demoAccount) => {
    const [firebaseShowCryptoConverter, setFirebaseShowCryptoConverter] = useState(false);

    useEffect(() => {
        if (FIREBASE_FEATURE_FLAGS.showCryptoConverter === undefined && demoAccount) {
            FirebaseService.fetchAndActiveRemoteConfig().then(() => {
                setFirebaseShowCryptoConverter(FirebaseService.showCryptoConverter());
            });
        } else {
            setFirebaseShowCryptoConverter(FIREBASE_FEATURE_FLAGS.showCryptoConverter);
        }
    }, [FIREBASE_FEATURE_FLAGS.showCryptoConverter, demoAccount]);

    return firebaseShowCryptoConverter;
};

export default useShowCryptoConverter;
