import React, { useMemo } from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { translate } from "react-i18next";
import moment from "moment";

import IconButton from "@material-ui/core/IconButton";
import CheckIcon from "@material-ui/icons/CheckCircleOutlined";
import TransferDocInput from "../../pages/BankTransfers/components/TransferHistoryTable/TransferDocInput";

import Table from "./Table";
import CurrencyFormat from "../Currency/CurrencyFormat";

import getTranslatedStatus from "./TransfersTable/getTranslatedStatus";
import { INTERNAL } from "../../config";
import { withStyles, Grid } from "@material-ui/core";
import { styles } from "./styles";
import BankInfo from "../BankInfo";
import {
  addWithdrawalConfirmation2FADialog,
  addDialog,
} from "../../redux/actions";
import Tooltip from "@material-ui/core/Tooltip";
import getBlockExplorer from "../../utils/getBlockExplorer";
import InfoTooltip from "../InfoTooltip";
import { Currency, getCurrencyBySymbol } from "../../models/Currency";
import CancelTransfer from "../../pages/BankTransfers/components/TransferHistoryTable/CancelTransfer";
import CancelSendCoins from "../../pages/SendReceiveCoins/SendCoins/SendCoinsTable/CancelSendCoins";
import { checkIsRobotAccount } from "../../utils/accountUtils";

const getFilterLabelFns = (t) => ({
  status: getTranslatedStatus(t),
});

const statusCustomComponents = (t, taxId) => ({
  "REVERSED-DEPOSIT": (
    <Grid container direction="row">
      <p>{getTranslatedStatus(t)("REVERSED")}</p>
      <InfoTooltip
        wrapperRootHeader
        title={`${t(
          "screens.sendReceiveCoins.depositReversedWarning"
        )}<br><br>${t("screens.sendReceiveCoins.sameTitularityWarning", {taxId: taxId})}`}
      />
    </Grid>
  ),
  "REVERSING-DEPOSIT": (
    <Grid container direction="row">
      <p>{getTranslatedStatus(t)("REVERSING")}</p>
      <InfoTooltip
        wrapperRootHeader
        title={`${t("screens.sendReceiveCoins.depositReversingWarning")}
        <br><br>
        ${t("screens.sendReceiveCoins.sameTitularityWarning", {taxId: taxId})}
        `}
      />
    </Grid>
  ),
  "PENDING-DEPOSIT": (depositBank) => (
    <>
      <p>{getTranslatedStatus(t)("PENDING")}</p>
      <BankInfo bankInformation={depositBank} />
    </>
  ),
});

const TransfersTable = (props) => {
  const {
    t,
    transfers,
    bankList,
    addWithdrawalConfirmation,
    currencies,
    classes,
    hasPin,
    pinIsRequiredToWithdrawalDialog,
    taxId,
    isCompany
  } = props;

  const canConfirWithdrawals = hasPin || checkIsRobotAccount(taxId);
  const taxIdType = Number(isCompany) > 0 ? "CNPJ" : "CPF";
  const getColumns = useMemo(() => [
    {
      Header: t("transactions.historyTable.state"),
      accessor: "status",
      Cell: (props) => {
        const { value } = props.cell;
        const {
          values: {
            actions: { address, type, proof },
          },
        } = props.row;
        let statusValue = getTranslatedStatus(t)(value);
        const customStatusComponents = statusCustomComponents(t, taxIdType);
        const transactionCustomKey = `${value}-${type}`;
        if (customStatusComponents[transactionCustomKey]) {
          switch (transactionCustomKey) {
            case "PENDING-DEPOSIT":
              if (bankList) {
                const depositBankKey = Object.keys(bankList).find(
                  (key) => bankList[key].address === address
                );
                if (depositBankKey && proof === "") {
                  const depositBank = bankList[depositBankKey];
                  statusValue =
                    customStatusComponents[transactionCustomKey](depositBank);
                }
              }
              break;
            case "REVERSED-DEPOSIT":
            case "REVERSING-DEPOSIT":
              const currency = props.cell.row.values.currency;
              if (currency === "BRL") {
                statusValue = customStatusComponents[transactionCustomKey];
              }
              break;
            default:
              statusValue = customStatusComponents[transactionCustomKey];
          }
        }
        return statusValue;
      },
      filter: "includes",
      disableSortBy: true,
    },
    {
      Header: t("transactions.historyTable.actions"),
      accessor: (row) => row,
      id: "actions",
      Cell: ({ cell: { value } }) => {
        const { currency, blockchain, type, status, proof } = value;
        const currencyConfig = new Currency(
          getCurrencyBySymbol(currencies, currency) ||
            getCurrencyBySymbol(currencies, "BTC")
        );
        const isCrypto = !currencyConfig.isFiat;
        const canConfirm = isCrypto && status === "WAITING_2FA";
        const canExpired =
          isCrypto && status === "EXPIRED" && type === "WITHDRAWAL";
        const CancelTransferComponent = isCrypto
          ? CancelSendCoins
          : CancelTransfer;
        const canDisplayUploadActions = isCrypto
          ? false
          : value.status !== "ERROR" &&
            value.status !== "REVERSED" &&
            value.status !== "REVERSING";
        const canCancel = isCrypto
          ? status === "WAITING_2FA"
          : type === "DEPOSIT" && status === "PENDING" && proof === "";

        const handleInfoTooltipClick = () => {
          window.open(
            "https://suporte.bity.com.br/pt-BR/collections/2727119-perguntas-frequentes"
          );
        };

        return (
          <div style={{ display: "flex" }}>
            {canDisplayUploadActions && (
              <TransferDocInput
                address={value.address}
                type={value.type}
                transfer={value}
                form={value.id}
              />
            )}
            {canCancel && (
              <CancelTransferComponent transId={value.id} currency={currency} />
            )}
            {canConfirm && (
              <Tooltip
                placement="top"
                disableFocusListener
                title="Confirmar Saque"
              >
                <IconButton
                  onClick={() => {
                    canConfirWithdrawals
                      ? addWithdrawalConfirmation(currency, blockchain)
                      : pinIsRequiredToWithdrawalDialog();
                  }}
                >
                  <CheckIcon style={{ cursor: "pointer", color: "green" }} />
                </IconButton>
              </Tooltip>
            )}
            {canExpired && (
              <Tooltip
                placement="top"
                disableFocusListener
                title={t("errorBoundary.contactSupport")}
              >
                <IconButton onClick={handleInfoTooltipClick}>
                  <InfoTooltip
                    classes={{ infoIconRoot: classes.canExpiredTooltip }}
                  />
                </IconButton>
              </Tooltip>
            )}
          </div>
        );
      },
      disableFilters: true,
    },
    {
      Header: t("tables.headers.currency"),
      accessor: "currency",
      disableFilters: true,
    },
    {
      Header: t("transactions.historyTable.blockchainTransaction"),
      accessor: (row) => row,
      id: "blockchainTransaction",
      Cell: ({ cell: { value } }) => {
        const isInternal = value.tx_id && value.tx_id.includes(INTERNAL);
        const currencyConfig = new Currency(
          getCurrencyBySymbol(currencies, value.currency) ||
            getCurrencyBySymbol(currencies, "BTC")
        );

        const isCripto = !currencyConfig.isFiat;

        const showExternalLink = isCripto ? true : false;

        const shouldRenderLink =
          !!value.tx_id && showExternalLink && !isInternal;
        if (!shouldRenderLink)
          return isInternal ? value.tx_id.split("-")[1] : "-";

        const baseUrl = currencyConfig.blockExplorerUrl;
        const explorerUrl = value.blockchain
          ? getBlockExplorer(value.tx_id, value.blockchain)
          : baseUrl(value.tx_id);
        return (
          <a target="_blank" href={explorerUrl}>
            {`${String(value.tx_id).slice(0, 12)}...`}
          </a>
        );
      },
      disableFilters: true,
    },
    {
      Header: t("transactions.historyTable.created"),
      accessor: "created",
      Cell: ({ cell: { value } }) =>
        moment(value).format("HH:mm:ss DD/MM/YYYY"),
      sortType: (rowA, rowB, columnId, isDesc) => {
        const a = moment(rowA.values[columnId]);
        const b = moment(rowB.values[columnId]);
        return a.isAfter(b) ? 1 : -1;
      },
      disableFilters: true,
    },
    {
      Header: t("transactions.historyTable.type"),
      accessor: (row) => t(row.type),
      id: "type",
      disableFilters: true,
    },
    {
      Header: t("transactions.historyTable.value"),
      accessor: "amount",
      Cell: ({ cell }) => {
        const currencyConfig = new Currency(
          getCurrencyBySymbol(currencies, cell.row.original.currency) ||
            getCurrencyBySymbol(currencies, "BTC")
        );
        return (
          <CurrencyFormat
            value={cell.column.accessor(cell.row.original)}
            isFiat={currencyConfig.isFiat}
            formatter={currencyConfig._defaultFormatter()}
          />
        );
      },
      disableFilters: true,
    },
    {
      Header: t("transactions.historyTable.fee"),
      accessor: "fee",
      Cell: ({ cell }) => {
        const currencyConfig = new Currency(
          getCurrencyBySymbol(currencies, cell.row.original.currency) ||
            getCurrencyBySymbol(currencies, "BTC")
        );
        return (
          <CurrencyFormat
            value={cell.column.accessor(cell.row.original)}
            isFiat={currencyConfig.isFiat}
            formatter={currencyConfig._defaultFormatter()}
          />
        );
      },
      disableFilters: true,
    },
    {
      Header: t("transactions.historyTable.toReceive"),
      accessor: (row) => row.amount - row.fee,
      id: "toReceive",
      Cell: ({ cell }) => {
        const currencyConfig = new Currency(
          getCurrencyBySymbol(currencies, cell.row.original.currency) ||
            getCurrencyBySymbol(currencies, "BTC")
        );
        return (
          <CurrencyFormat
            value={cell.column.accessor(cell.row.original)}
            isFiat={currencyConfig.isFiat}
            formatter={currencyConfig._defaultFormatter()}
          />
        );
      },
      disableFilters: true,
    },
  ]);

  const filteredTransfers = useMemo(() => {
    return transfers.filter((transfer) => {
      if (
        transfer.status === "PENDING" &&
        transfer.type === "DEPOSIT" &&
        bankList
      ) {
        return transfer;
      }

      if (transfer.status === "PENDING") {
        return transfer.type !== "DEPOSIT";
      }

      return transfer;
    });
  }, [transfers]);

  return (
    <Table
      columns={getColumns}
      data={filteredTransfers}
      filterLabelsFns={getFilterLabelFns(t)}
    />
  );
};

const mapStateToProps = ({user}) => ({
  hasPin: user.profile.has_pin,
  taxId: user.profile.tax_id,
  isCompany: user.profile.company
});

const mapDispatchToProps = (dispatch) => ({
  pinIsRequiredToWithdrawalDialog: () => {
    dispatch(
      addDialog({
        title: "common.attention",
        centerTitle: true,
        renderComponent: "pinIsRequiredToWithdrawalWarning",
        disableBackdropClick: true,
        availableChoices: [
          {
            label: "common.understood",
            actionToTake: "",
            color: "secondary",
            variant: "raised",
          },
        ],
      })
    );
  },
  addWithdrawalConfirmation: (currency, blockchain) =>
    dispatch(addWithdrawalConfirmation2FADialog(true, currency, blockchain)),
});
export default compose(
  withStyles(styles),
  translate(),
  connect(mapStateToProps, mapDispatchToProps)
)(TransfersTable);
