export const stableBrzCoins = ["CBRL", "REALT", "BRZ", "RAS"];

export const getUserCurrencies = (balance, currencies, ticker, {includeFiat = false} = {}) =>  {
    const currenciesList =  currencies.filter(currency => {
        return balance[currency.symbol] > 0 && (includeFiat ? true : !currency.is_fiat);
    }); 
    currenciesList.sort((a,b) => {
        const tickerA = ticker[a.symbol] ? ticker[a.symbol].sell : 0;
        const tickerB = ticker[b.symbol] ? ticker[b.symbol].sell : 0;
        return (balance[b.symbol] * tickerB) - ( balance[a.symbol] * tickerA);
    });
    return currenciesList;
}

export const getCurrenciesBySymbols = (currencies, symbols) => {
  return currencies.filter((currency) => symbols.includes(currency.symbol));
};