
import {
  HIDE_BOTTOM_NAVIGATION,
  SHOW_BOTTOM_NAVIGATION,
} from './actionTypes';

export const hideBottomNavigation = () => ({
  type: HIDE_BOTTOM_NAVIGATION,
});

export const showBottomNavigation = () => ({
  type: SHOW_BOTTOM_NAVIGATION,
});

export default {
  hideBottomNavigation,
  showBottomNavigation,
};
