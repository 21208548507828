import React from "react";
import { Grid, IconButton, Icon } from "@material-ui/core";
import { Edit } from "@material-ui/icons";
import { styles } from "./styles";
import MoneyInput from "../../../../components/FormsV2/MoneyInput";
import { CRYPTO_CONVERTION_AMOUNT_TYPES } from "../../constants";
import CriptoFormat from "../../../../utils/NumberFormat/CriptoFormat";
import { withStyles } from "@material-ui/core";
import { compose } from "recompose";

const FromVolumeAmountInput = (props) => {
  const {
    fromAmount,
    volumeAmount,
    handleFromInputChange,
    disabled,
    fromCurrency,
    brlCurrency,
    classes,
    fromAmountType,
    handleSwitchFromAmountType,
  } = props;
  const isUsingVolumeInput =
    fromAmountType === CRYPTO_CONVERTION_AMOUNT_TYPES.VOLUME;
  const formatter = isUsingVolumeInput
    ? brlCurrency.formatter
    : fromCurrency.formatter;
  const inputAmount = isUsingVolumeInput ? volumeAmount : fromAmount;
  const exhibitionAmount = isUsingVolumeInput ? fromAmount : volumeAmount;
  const exhibitionCurrency = isUsingVolumeInput ? fromCurrency : brlCurrency;
  const inputCurrency = isUsingVolumeInput ? brlCurrency : fromCurrency;

  return (
    <div>
      <MoneyInput
        disabled={disabled}
        currency={"BRL_PRECISION_PRICE"}
        fullWidth
        name={isUsingVolumeInput ? "volumeAmount" : "fromAmount"}
        value={inputAmount}
        onChange={handleFromInputChange}
        i18nScope="screens.cryptoConverter.fromVolumeInput"
        i18nContext={{
          tokenName: inputCurrency.getExhibitionName(7),
        }}
        formatter={formatter}
      />

      <Grid container className={classes.exhibitionAmountContainer} spacing={8} justify="flex-end" alignItems="center">
        <Grid item>
          <CriptoFormat
            decimalSeparator={isUsingVolumeInput ? "." : ","}
            thousandSeparator={isUsingVolumeInput ? false : "."}
            prefix={`${exhibitionCurrency.monetarySymbol} `}
            value={exhibitionAmount}
            decimalScale={exhibitionCurrency.exhibitPrecision}
          />
        </Grid>

        <Grid item>
          <IconButton
            disabled={disabled}
            classes={{ root: classes.switchInputButton }}
            onClick={handleSwitchFromAmountType}
          >
            <Edit classes={{ root: classes.editIcon }} />
          </IconButton>
        </Grid>
      </Grid>
    </div>
  );
};

const enhance = compose(withStyles(styles));
export default enhance(FromVolumeAmountInput);
