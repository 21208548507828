import React, { useEffect } from 'react'

import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import { compose } from 'recompose'

import Typography from '@material-ui/core/Typography'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import Hidden from '@material-ui/core/Hidden'

import TradeCard from '../../../components/Cards/TradeCard'
import StripedRow from '../../../components/Tables/StripedRow'
import InfoTooltip from '../../../components/InfoTooltip'
import SwitchButtonWrapper from '../../../components/Buttons/SwitchButtonWrapper'
import ShowMineButton from '../../../components/Transactions/ShowMineButton'
import ShowAllButton from '../../../components/Transactions/ShowAllButton'

import BrasilNumberFormat from '../../../utils/NumberFormat/BrasilNumberFormat'

import HeaderCell from '../../../components/Tables/TradeTableHeaderCell'
import CriptoFormat from '../../../utils/NumberFormat/CriptoFormat';
import { Link } from 'react-router-dom';
import { MarketInfo } from '../../../models/MarketInfo'
import { getCurrencyBySymbol, Currency } from '../../../models/Currency'



const styles = theme => ({
  root: {
    width: '100%',
    height: '100%',
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 4,
    overflowX: 'auto',
  },
  row: {
    [theme.breakpoints.down('sm')]:{
      height: '32px'
    }
  },
  table: {
    minWidth: 300,
    width: '100%',
  },
  button: {
    margin: theme.spacing.unit,
  },
  sell: {
    color: theme.sell,
  },
  buy: {
    color: theme.buy,
  },
  cardLabel: {
    display: 'flex',
    flexDirection: 'column'
  },
  cardDescription: {
    width:"100%",
    display: 'flex',
    flexWrap:'noWrap',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.1rem'
    }
  },
  cardSubtitle:{
    fontSize: 11,
    marginTop: '-5px',
    color: 'rgba(0, 0, 0, 0.87)',
    '& a:link': {
      color: 'rgba(0, 0, 0, 0.87)',
      textDecoration: 'none'
    },
    '& a:visited': {
      color: 'rgba(0, 0, 0, 0.87)'
    }
  },
  dense: {
    paddingLeft: theme.spacing.unit * 0.75,
    paddingRight: theme.spacing.unit * 0.75,
    '&:first-child': {
      paddingLeft: theme.spacing.unit * 1.5,
      paddingRight: theme.spacing.unit * 0.75,
    },
    '&:last-child': {
      paddingLeft: theme.spacing.unit * 0.75,
      paddingRight: theme.spacing.unit * 1.5,
    }
  }
})

const ExecutedOrders = props => {
  const {
    t,
    classes,
    isShowAllSelected,
    setIsShowAllSelected,
    orders,
    parseDateAndTime,
    market,
    getOrderTimestamp,
    getOrderCreatedAt,
    sortOrdersByConcluded,
    fetchExecutedOrders,
    onlyMineOrders,
    markets,
    currencies,
  } = props

  const marketInfo = new MarketInfo(markets[market]);
  const currency = new Currency(getCurrencyBySymbol(currencies,market.split('-')[0]));
  const baseCurrencyDecimalScale = currency._defaultFormatter().decimalScale;
  const quoteCurrencyDecimalScale = marketInfo.quotePrecision;
  const sortedOrders = sortOrdersByConcluded(orders);


  useEffect(() => {
    if (!isShowAllSelected) {
      fetchExecutedOrders();
    }
  }, [isShowAllSelected]);

  return (
    <TradeCard
      action={
        !onlyMineOrders && (
          <SwitchButtonWrapper className={classes.button}>
            <ShowMineButton
              showAll={isShowAllSelected}
              setShowAll={setIsShowAllSelected}
            />
            <ShowAllButton
              showAll={isShowAllSelected}
              setShowAll={setIsShowAllSelected}
            />
          </SwitchButtonWrapper>
        )
      }
      cardLabel={
        <div className={classes.cardLabel}>
          <div className={classes.cardDescription}>
            {t("tradeHistory.title", { tokenName: currency.symbol })}
            <InfoTooltip
              wrapperRootHeader={true}
              title={t("info.tradeHistory")}
            />
          </div>
          <Hidden smDown>
            {!isShowAllSelected && (
              <div className={classes.cardSubtitle}>
                <Link to="/extract">
                  {t("transactions.executedOrdersHeaderSubtitle")}
                </Link>
              </div>
            )}
          </Hidden>
        </div>
      }
    >
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <HeaderCell>
              {t(
                isShowAllSelected
                  ? "transactions.createdAt"
                  : "transactions.concludedAt"
              )}
            </HeaderCell>
            {!isShowAllSelected && (
              <Hidden smDown>
                <HeaderCell>{t("transactions.createdAt")}</HeaderCell>
              </Hidden>
            )}
            <HeaderCell>{t("transactions.amount")}</HeaderCell>
            <HeaderCell>{t("transactions.type")}</HeaderCell>
            <HeaderCell numeric>{t("transactions.price")} R$</HeaderCell>
            <Hidden smDown>
              <HeaderCell numeric>{t("transactions.total")} R$</HeaderCell>
            </Hidden>
          </TableRow>
        </TableHead>

        <TableBody>
          {sortedOrders.map((trade, i) => {
            const timestamp = getOrderTimestamp(trade);
            const createdAt = getOrderCreatedAt(trade);
            const [date, time] = parseDateAndTime(timestamp);
            const [createdAtDate, createdAtTime] = parseDateAndTime(createdAt);

            return (
              <StripedRow
                className={classes.row}
                key={`${trade.id || i}_${trade.amount}_${trade.price}_${
                  trade.timestamp
                }`}
              >
                <TableCell
                  className={classes.dense}
                  padding="dense"
                  noWrap={false}
                >
                  <Hidden mdUp>
                    {date} <br /> {time}
                  </Hidden>
                  <Hidden smDown>{`${date}  ${time}`}</Hidden>
                </TableCell>
                {!isShowAllSelected && (
                  <Hidden smDown>
                    <TableCell
                      className={classes.dense}
                      padding="dense"
                      noWrap={false}
                    >
                      {`${createdAtDate}  ${createdAtTime}`}
                    </TableCell>
                  </Hidden>
                )}

                <TableCell className={classes.dense} padding="dense">
                  <CriptoFormat
                    value={isShowAllSelected ? trade.amount : trade.exec_amount}
                    decimalScale={baseCurrencyDecimalScale}
                  />
                </TableCell>

                <TableCell
                  className={classes.dense}
                  padding="dense"
                  noWrap={false}
                >
                  <Typography
                    variant="caption"
                    align="left"
                    className={
                      trade.type === "SELL" ? classes.sell : classes.buy
                    }
                  >
                    {trade.type === "SELL" ? "Venda" : "Compra"}
                  </Typography>
                </TableCell>

                <TableCell
                  numeric
                  className={classes.dense}
                  padding="dense"
                  noWrap={false}
                >
                  <BrasilNumberFormat
                    decimalScale={quoteCurrencyDecimalScale}
                    style={{ marginLeft: "auto" }}
                    value={trade.price}
                  />
                </TableCell>

                <Hidden smDown>
                  <TableCell
                    numeric
                    className={classes.dense}
                    padding="dense"
                    noWrap={false}
                  >
                    <BrasilNumberFormat
                      value={
                        isShowAllSelected
                          ? trade.price * trade.amount
                          : trade.price * trade.exec_amount
                      }
                    />
                  </TableCell>
                </Hidden>
              </StripedRow>
            );
          })}
        </TableBody>
      </Table>
    </TradeCard>
  );
}

const mapStateToProps = state => ({
  market: state.market.selectedMarket,
  markets: state.currencies.markets,
  currencies: state.currencies.currencies,
})

const enhancer = compose(
  withStyles(styles),
  connect(mapStateToProps)
  )

export default enhancer(ExecutedOrders)
