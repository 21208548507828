import React from 'react'
import NumberFormat from 'react-number-format'
import PropTypes from 'prop-types'
import * as R from 'ramda'

const CLIPBOARD_DATA_TYPE = 'text/plain';
const EMPTY = "";

const onPasteMask = (e, formatter) => {
  const {target, clipboardData} = e;
  const clipValue = clipboardData.getData(CLIPBOARD_DATA_TYPE);
  const withComma = clipValue.replace('.', ',');
  const formattedValue = removeWrongDots(withComma);

  const decPlaces = Number(formattedValue)
      .toFixed(formatter.decimalScale);
  target.value = decPlaces.replace('.', ',');
};

const removeWrongDots = (commaNumber) =>{
  const cleanEntry = commaNumber.split(".").join(EMPTY);
  return cleanEntry.replace(',', '.');
};


const MoneyInputFormat = props => {
  const {
    formatter,
    value,
    inputRef,
    onChange,
    ...custom
  } = props;

  // NumberFormat lib cant handle really small numbers
  // when value is javascript`s Number primitive so
  // the value is parsed as String and
  // isNumericString MUST be true
  const hasNumericInput = R.not(R.isEmpty(value)) && value !== undefined
  const valueAsString = hasNumericInput
    ? Number(value).toFixed(formatter.decimalScale)
    : ''

	return (
		<NumberFormat
      {...custom}
      {...formatter}
			value={valueAsString}
			ref={inputRef}
            onPasteCapture={ (e) =>
                              {
                                onPasteMask(e, formatter)
                              }}
			onValueChange={values => {
        const { floatValue } = values
        onChange(floatValue)
			}}
      type='tel'
      isNumericString={true}
		/>
	)
}

MoneyInputFormat.propTypes = {
  value: PropTypes.number, // can be undefined
  currency: PropTypes.string.isRequired,
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
}

export default MoneyInputFormat
