import React from "react";
import ReactApexChart from "react-apexcharts";
import PropTypes from "prop-types";
import chartDonutEmpty from "../../assets/donut-chart-empty.svg";
import { withStyles } from "@material-ui/core";
import { compose } from "recompose";
import classNames from "classnames";
import { translate } from "react-i18next";


const styles = (theme) => ({
  emptyChart: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& img": {
      width: "100%",
      height: "100%",
    },
  },
});

const DonutChart = (props) => {
  const { chartItems, classes, emptyChartCustomClass, toolTipFormatter, t } = props;
  let chartLabels = [];
  let chartValues = [];
  let chartColors = [];
  for (let chartItem of chartItems) {
    chartLabels.push(chartItem.title);
    chartValues.push(chartItem.value);
    chartColors.push(chartItem.color);
  }
  const options = {
    labels: chartLabels,
    colors: chartColors,
    tooltip: {
      y: {
        formatter: toolTipFormatter,
      },
    },
  };

  const series = chartValues;

  return chartItems.length ? (
    <div className="app">
      <div className="row">
        <div className="mixed-chart">
          <ReactApexChart options={options} series={series} type="donut" />
        </div>
      </div>
    </div>
  ) : (
    <div className={classNames(classes.emptyChart, emptyChartCustomClass)}>
      <img src={chartDonutEmpty} alt={t("imageAlts.emptyChart")} />
    </div>
  );
};

DonutChart.propTypes = {
  chartItems: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
    })
  ),
  emptyChartCustomClass: PropTypes.string,
  toolTipFormatter: PropTypes.func,
};

DonutChart.defaultProps = {
  chartItems: [],
  toolTipFormatter: (value) => value,
};

const enhance = compose(translate(),withStyles(styles));

export default enhance(DonutChart);
