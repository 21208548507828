import React from 'react';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  container: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    flexDirection: 'column',
    maxWidth: '100%',
    marginTop: 3 * theme.spacing.unit,
    paddingLeft: 3 * theme.spacing.unit,
    paddingRight: 3 * theme.spacing.unit,

  },
  root: {
    display: 'flex',
    justifyContent: 'center',
    // marginBottom: 5 * theme.spacing.unit,
  },
});
const Form = ({
  children, handleSubmit, onSubmit, classes,
}) => {
  return (
    <div className={classes.root}>
      <form
        onSubmit={
          handleSubmit && onSubmit
            ? handleSubmit(onSubmit)
            : handleSubmit && !onSubmit
              ? handleSubmit : onSubmit
        }
        className={classes.container}
      >
        {children}
      </form>
    </div>
  );
};

const enhance = compose(
  withStyles(styles),
);

export default enhance(Form);
