import React from "react";
import PropTypes from "prop-types";
import { compose, withState } from "recompose";
import { translate } from "react-i18next";

import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";

import GridItem from "./GridItem";

import OrderBook from "./OrderBook/index";
import TradeOrderView from "./TradeOrderView";
import ExecutedOrders from "./ExecutedOrders";

const styles = (theme) => ({
  root: {
    margin: "0 auto 10px",
  },
  rootLuna: {
    margin: "0 auto",
    marginTop: "170px",
  },
});

const enhance = compose(
  translate(),
  withStyles(styles),
  withState(
    "isOrderBookSellShowAllSelected",
    "setOrderBookSellShowAllSelected",
    true
  ),
  withState(
    "isOrderBookBuyShowAllSelected",
    "setOrderBookBuyShowAllSelected",
    true
  )
);

const DesktopWrapper = (props) => {
  const {
    classes,
    isOrderBookSellShowAllSelected,
    setOrderBookSellShowAllSelected,
    isOrderBookBuyShowAllSelected,
    setOrderBookBuyShowAllSelected,
    currencyConfig,
  } = props;
  return (
    <div className={classes.root}>
      <Grid container>
        <GridItem sm={12}>
          <TradeOrderView
            setOrderBookSellShowAllSelected={setOrderBookSellShowAllSelected}
            marketConfig={currencyConfig}
          />
        </GridItem>

        <GridItem sm={6}>
          <OrderBook
            ordersType="BUY"
            isShowAllSelected={isOrderBookBuyShowAllSelected}
            setIsShowAllSelected={setOrderBookBuyShowAllSelected}
          />
        </GridItem>
        <GridItem sm={6}>
          <OrderBook
            ordersType="SELL"
            isShowAllSelected={isOrderBookSellShowAllSelected}
            setIsShowAllSelected={setOrderBookSellShowAllSelected}
          />
        </GridItem>

        <GridItem>
          <ExecutedOrders />
        </GridItem>
      </Grid>
    </div>
  );
};

DesktopWrapper.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default enhance(DesktopWrapper);
