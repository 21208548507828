import React from 'react'

const currencyFormatter = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' })

const calculateExchangeFees = (price, execution, withdrawal, deposit, op)=>{
  const nPrice = Number(price);
  if(op==='SELL')
    return nPrice + ((nPrice*Number(execution)/100) /*+ (nPrice*Number(withdrawal)/100)*/ + (nPrice*Number(deposit)/100));
  else
    return nPrice - ((nPrice*Number(execution)/100) + (nPrice*Number(withdrawal)/100) /*+ (nPrice*Number(deposit)/100)*/);
}

const calculateDiscount = (finalPrice, localPrice, op)=>{
  if(op === 'SELL')
    return (((Number(finalPrice) / Number(localPrice))-1)*100);
  else
    return (((Number(localPrice) / Number(finalPrice))-1)*100);
}

const OrderBookOrderDetailsRow = (t, order, ordersType, feeType) => {
  const isProgrammed = order.programmed && order.programmed === "1";
  if (!isProgrammed && (!order.exchange || !order.exchange.name)) return t('transactions.notApplyedFees', {fee: feeType})
  else if(isProgrammed) return t('transactions.programmedFees')
  const bitPrecoDiscount = ( order.exchange && order.exchange.name ?
    calculateDiscount(calculateExchangeFees(order.exchange.price,
      order.exchange.execution,
      order.exchange.withdrawal,
      order.exchange.deposit, ordersType),
      order.price, ordersType)
    : null);

  const tooltipText = `
      ${t('transactions.exchange')}: <b>${order.exchange.name}</b>
      <br />
      ${t('transactions.exchangePrice')}: ${currencyFormatter.format(order.exchange.price)}
      <br />
      ${
      bitPrecoDiscount > 0
      ?
          `<b style="color: green">
          ${ordersType === 'SELL' ? t('transactions.discount') : t('transactions.gain') }
      : ${bitPrecoDiscount.toFixed(2)}%</b>
      <br />`
        :
      `${t('transactions.intermediation')}
      ${bitPrecoDiscount.toFixed(2)*-1}%
      <br />`
      }
      <br />
      ${
        ordersType === 'SELL'
          ?
          `
          ${t('transactions.fee.fees')}:
              <br />
              ${order.exchange.execution}%
              (${t('transactions.execution')})
              <br />
              ${order.exchange.deposit}%
              (${t('transactions.deposit.title')})
              <br />
              <br />
              <b>
              ${t('transactions.youPay')}: <br />
                ${currencyFormatter.format(calculateExchangeFees(
                  order.exchange.price,
                  order.exchange.execution,
                  order.exchange.withdrawal,
                  order.exchange.deposit, 'SELL'
                ))}
              </b>
      <br />
      <br />
      <b>
        ${t('transactions.youPayBitpreco')}: <br />
        ${currencyFormatter.format(order.price)}
      </b>
              `
          : `

          ${t('transactions.fee.fees')}:
              <br />
              ${order.exchange.execution}%
              (${t('transactions.execution')})
              <br />
              ${order.exchange.withdrawal}%
              (${t('transactions.withdrawal.title')})
              <br />
              <br />
              <b>
              ${t('transactions.youGet')}: <br />
                ${currencyFormatter.format(calculateExchangeFees(
                  order.exchange.price,
                  order.exchange.execution,
                  order.exchange.withdrawal,
                  order.exchange.deposit, 'BUY'
                ))}
              </b>
      <br />
      <br />
      <b>
        ${t('transactions.youGetBitpreco')}: <br />
        ${currencyFormatter.format(order.price)}
      </b>
          `
      }
  `
  return tooltipText
}

export default OrderBookOrderDetailsRow
