import * as R from 'ramda';
import banksList from '../assets/febraban_banks.json';

const removeFormatting = val => R.join('',
  R.reject(R.isEmpty,
    R.split('_',
      R.join('',
        R.split('-', val)))));


const serializeBankAccount = value => {
  if (R.is(String, value)) {
    const bank = R.find(R.propEq('code', value))(banksList);
    if (bank) {
      return bank.name;
    }
  }
  return null
};

const serializeBank = address => {
  if (!address) return {};
  const [bank, agency, account, is_CC] = R.split('|', address);
  const bankData = {
    bank: removeFormatting(bank),
    agency: removeFormatting(agency),
    account: removeFormatting(account),
    is_CC: (is_CC!==undefined && is_CC!== '') ? is_CC === 'CC' : true
  };
  const bankName = serializeBankAccount(bank);

  return bankName
    ? {
      ...bankData,
      address,
      bankName,
    }
    : {}
};

export default serializeBank;
