import { UPDATE_BANK_ACCOUNT, FETCH_BANK_ACCOUNT_REQUESTED } from './actionTypes';

const updateBankAccount = (bankAccount) => ({
  type: UPDATE_BANK_ACCOUNT,
  bankAccount,
});

const fetchBankAccount = () => ({
  type: FETCH_BANK_ACCOUNT_REQUESTED,
});

export default {
  updateBankAccount,
  fetchBankAccount,
};

export {
  updateBankAccount,
  fetchBankAccount,
};
