import Profile from '../pages/Profile';
import BankTransfers from '../pages/BankTransfers';
import SendReceiveCoins from '../pages/SendReceiveCoins';
import Extract from '../pages/Extract';
import ExpressTrade from '../pages/ExpressTrade';

import Trade from '../pages/Trade';

export const forbiddenPagesDemoAccount = [
  Profile,
  BankTransfers,
  SendReceiveCoins,
  Extract
];

export const whiteListPrivateRoutesWithDemoAccount = [
  Trade,
  ExpressTrade
]