import { SUPPORT_ADDRESS } from "../config";

const isIntercomAvailable = () => {
    return window.Intercom !== undefined;
}

const openInNewTab = ( address ) => {
    const win = window.open(address, '_blank');
    win.focus();
}

export const contactSupport = () => {
    if (isIntercomAvailable())
        window.Intercom("show");
    else
        openInNewTab(SUPPORT_ADDRESS);
}
