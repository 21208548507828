import React from "react";
import { Card, CardContent, Grid } from "@material-ui/core";
import {
  InfoOutlined,
  WarningOutlined,
  ErrorOutlined,
} from "@material-ui/icons";
import DangerouslySetInnerHTML from "../DangerouslySetInnerHTML";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";

const styles = (_theme) => ({
  iconContainer: {
    maxWidth: "35px",
  },
  content: {
    fontFamily: "Roboto, sans-serif",
    fontSize: "14px",
    fontWeight: "normal",
    color: "rgba(0, 0, 0, 0.87)",
    margin: "0",

    "& a": {
      fontWeight: "600",
      textDecoration: "none",
      color: "rgba(0, 0, 0, 0.87)",
    },
  },
  card: {
    boxShadow: "none",
    width: "100%",
    border: "1px solid rgba(0, 0, 0, 0.1)",
    padding: "20px",
    borderRadius: "10px",
    backgroundColor: "rgba(0, 0, 0, 0.05)",
    "& :last-child": {
      paddingBottom: "0",
    },
  },
  cardContent: {
    padding: "0",
  },
  icon: {
    color: _theme.palette.primary.main,
    fontSize: "35px",
    float: "right",
  },
  iconWarning: {
    color: _theme.colors.secondaryBase,
  },
  iconError: {
    color: _theme.palette.error.main,
  },
});

const iconTypes = ["info", "warning", "error"];

const MessageCard = ({ type, customCardClass, content, classes }) => {
  let icon;

  switch (type) {
    case "info":
      icon = (
        <InfoOutlined
          classes={{
            root: classes.icon,
          }}
        />
      );
      break;
    case "warning":
      icon = (
        <WarningOutlined
          classes={{
            root: classNames(classes.icon, classes.iconWarning),
          }}
        />
      );
      break;
    case "error":
      icon = (
        <ErrorOutlined
          classes={{
            root: classNames(classes.icon, classes.iconError),
          }}
        />
      );
      break;
    default:
      icon = null;
  }

  const hasIcon = iconTypes.includes(type);
  const cardClass = customCardClass
    ? classNames(customCardClass, classes.card)
    : classes.card;
  return (
    <Card
      classes={{
        root: cardClass,
      }}
    >
      <CardContent
        classes={{
          root: classes.cardContent,
        }}
      >
        <Grid container spacing={2} className={classes.messageCardContentContainer}  wrap="nowrap" justify="space-between" alignItems="center">
          <Grid>
            <DangerouslySetInnerHTML
              className={classes.content}
              title={content}
              Component="p"
            />
          </Grid>
          <Grid
            item
            xs={3}
            className={classes.iconContainer}
            classes={{
              root: classes.iconContainer,
            }}
            md={1}
          >
            {hasIcon && icon}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

const enhance = compose(withStyles(styles));

export default enhance(MessageCard);
