import Cookies from 'js-cookie';
import store from '../redux/store';
import { DEV } from '../config';

const getUserId = () => {
  try {
    return store.getState().user.profile.id;
  } catch (_e) {
    return undefined;
  }
};

const getUserDocuments = () => {
  try {
    return store.getState().user.documents;
  } catch (_e) {
    return undefined;
  }
}

export const setUserIdCookie = () => {
  Cookies.set('userID', getUserId(), {path:'', domain: '.bitpreco.com'});
};

const addToDataLayer = params => {
  if (DEV) return;
  const userID = params.event === 'SESSION' ? Cookies.get('userID') : getUserId();
  const defaultParams = {
    landChannel: 'BP-PROD-Market',
    userID,
  };
  try {
    window.dataLayerCustom.push({ ...defaultParams, params });
  } catch (e) {
    console.error('DATA LAYER ERROR: ', e);
  }
};

export const gtmSession = () => addToDataLayer({
  event: 'SESSION',
});

export const gtmLogin = () => addToDataLayer({
  event: 'LOGIN',
});

export const gtmOpenRegister = () => addToDataLayer({
  event: 'OPEN_REGISTER',
});

export const gtmRegisterSuccess = () => addToDataLayer({
  event: 'REGISTER_SUCCESS',
});

export const gtmTransaction = transactionType => addToDataLayer({
  event: 'TRANSACTION',
  transactionType,
});

export const gtmDocuments = () => {
  const documents = getUserDocuments();
  const sentDocuments = Object.keys(documents).filter(key =>{
    return documents[key] && documents[key] !== 'SUCCEED';
  })
  if (sentDocuments.length === 2)
    gtmRegisterSuccess();

}
