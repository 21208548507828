import React from "react";
import { connect } from "react-redux";
import {
  DialogTitle,
  DialogContent,
  Grid,
  Stepper,
  IconButton,
  Button,
  Step,
  Typography,
  StepLabel,
} from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import { compose } from "recompose";
import { translate } from "react-i18next";
import { reduxForm, getFormValues, isSubmitting } from "redux-form";
import SubmitButton from "../../../../../components/Forms/SubmitButton";
import FinancialDeclarationFormStep1 from "./components/FinancialDeclarationFormStep1";
import FinancialDeclarationFormStep2 from "./components/FinancialDeclarationFormStep2";
import {
  changeUserDeclarationDialog,
  addSnack,
} from "../../../../../redux/actions";
import {
  declarationTypes,
  pendingDocTypes,
  getDeclarationTypeByDocPending,
} from "../../_constants";
import requestUserDeclaration from "../../../../../services/cbtcService/userDeclaration";

const financialSubmit = async (form, dispatch, props) => {
  const { handleClose, openAnotherDeclarationDialog, pendingSends } = props;

  try {
    let dataFormated = {};
    for (const key in form) {
      dataFormated[key] = Number(form[key]);
    }
    const newForm = {
      data: JSON.stringify(dataFormated),
      type: declarationTypes.FINANCIAL,
    };
    await requestUserDeclaration(newForm);
    const newPendingSends = pendingSends.filter(
      (item) => item !== pendingDocTypes.FINANCIAL_DECLARATION
    );
    if (newPendingSends.length > 0) {
      openAnotherDeclarationDialog({
        declarationType: getDeclarationTypeByDocPending(newPendingSends[0]),
        pendingSends: newPendingSends,
      });
    } else {
      handleClose();
    }
  } catch (error) {
    dispatch(addSnack({ message: "GENERIC_ERROR" }));
    return;
  }
};

const getTitleByStep = (step) => {
  switch (step) {
    case 0:
      return "forms.incomeAndWealthValidation.incomeDialog.title";
    case 1:
      return "forms.incomeAndWealthValidation.wealthDialog.title";
    default:
      return "";
  }
};

const verifyStep = (step, values = {}) => {
  switch (step) {
    case 0:
      return false;
    case 1:
      return !Number(values.monthly_incomes);
    case 2:
      return !Number(values.assets);
    default:
      return false;
  }
};

const steps = [
  "forms.incomeAndWealthValidation.educativeDialog.title",
  "forms.incomeAndWealthValidation.incomeDialog.stepperLabel",
  "forms.incomeAndWealthValidation.wealthDialog.stepperLabel",
];

const stepComponents = [
  (props) => {
    const { t } = props;
    return (
      <div>
        <Typography component={"p"} variant="title">
          {t("forms.incomeAndWealthValidation.educativeDialog.description")}
        </Typography>
      </div>
    );
  },
  FinancialDeclarationFormStep1,
  FinancialDeclarationFormStep2,
];

const FinancialDeclarationBody = (props) => {
  const { t, classes, handleSubmit, submitting, formValues } = props;

  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const Component = stepComponents[activeStep];

  return (
    <>
      <Grid
        container
        alignItems="center"
        justify="space-between"
        className={classes.financialHeaderContainer}
      >
        <Grid item>
          <IconButton onClick={handleBack} disabled={activeStep === 0}>
            <ArrowBack />
          </IconButton>
        </Grid>

        <Grid item className={classes.financialStepperContainer}>
          <Stepper
            className={classes.financialHeaderStepper}
            activeStep={activeStep}
          >
            {steps.map((label, index) => {
              const stepProps = {};
              const labelProps = {};
              if (isStepSkipped(index)) {
                stepProps.completed = false;
              }
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel classes={
                    {iconContainer: classes.stepperLabel}
                  } {...labelProps}>{t(label)}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
        </Grid>
        <Grid item className={classes.minWidth50} height={50} />
      </Grid>
      {activeStep > 0 && (
        <DialogTitle className={classes.dialogTitle}>
          {t(getTitleByStep(activeStep))}
        </DialogTitle>
      )}
      <DialogContent>
        <Grid container>
          <form className={classes.w100} onSubmit={handleSubmit}>
            <Component {...props} />
            {activeStep === steps.length - 1 ? (
              <SubmitButton
                className={classes.submitButton}
                isSubmitting={submitting}
                disabled={verifyStep(activeStep, formValues) || submitting}
                submitText={t("common.toConfirm")}
              />
            ) : (
              <Button
                disabled={verifyStep(activeStep, formValues)}
                className={classes.submitButton}
                color="primary"
                variant="raised"
                onClick={handleNext}
              >
                {t("common.toContinue")}
              </Button>
            )}
          </form>
        </Grid>
      </DialogContent>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  handleClose: () =>
    dispatch(
      changeUserDeclarationDialog({ open: false, declarationType: null, pendingSends: []})
    ),
  openAnotherDeclarationDialog: (data) =>
    dispatch(
      changeUserDeclarationDialog({
        open: true,
        declarationType: data.declarationType,
        pendingSends: data.pendingSends,
      })
    ),
});

const mapStateToProps = (state) => {
  return {
    formValues: getFormValues("financialUserDeclaration")(state),
    submitting: isSubmitting("financialUserDeclaration")(state),
    pendingSends: state.credentials.userDeclarationDialog.pendingSends,
  };
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  translate(),
  reduxForm({
    form: "financialUserDeclaration",
    onSubmit: financialSubmit,
  })
);

export default enhance(FinancialDeclarationBody);
