
const validateLunaAddress = address => {

    const lunaRule = 'terra1[a-z0-9]{38}$';

    const lunaRegex = new RegExp(lunaRule);

    return lunaRegex.test(address);
}

export default validateLunaAddress;
