import * as Yup from 'yup'
import {
  ORDER_TYPES
} from './constants'

const TradeOrderSchema = Yup.object().shape({
  amount: Yup.number()
    .required('REQUIRED')
    .when(['$minAmountLimit', '$marketSymbol'], (minAmountLimit, marketSymbol, schema) => {
      return schema.min(minAmountLimit,
       { error: 'BELOW_MINIMUM_AMOUNT_FIELD',
         min: minAmountLimit, symbol: marketSymbol })
    })
    // When orderType is SELL or STOPLOSS, balance must be BTC
    .when('orderType', {
      is: ORDER_TYPES.SELL,
      then: Yup.number()
        .when('$balance', (balance, schema) =>
          schema.max(balance, 'NOT_ENOUGH_BALANCE')
        )
    })
    .when('orderType', {
      is: ORDER_TYPES.STOPLOSS,
      then: Yup.number()
        .when('$balance', (balance, schema) =>
          schema.max(balance, 'NOT_ENOUGH_BALANCE')
        )
    }),
  totalValue: Yup.number()
    .required('REQUIRED')
    .positive('BELOW_MINIMUM_AMOUNT')
    .when('orderType', {
      // When orderType is BUY, balance must be BRL (or other user currecy)
      is: ORDER_TYPES.BUY,
      then: Yup.number()
        .when('$balance', (balance, schema) =>
          schema.max(balance, 'TO_RESTOCK_BALANCE')
        )
    }),

  price: Yup.number()
    .required('REQUIRED')
    .positive('BELOW_MINIMUM_AMOUNT')
})

export default TradeOrderSchema
