import privateService from "../privateService";
import { CRYPTO_CONVERTION_AMOUNT_TYPES } from "../../pages/CryptoConverter/constants";
import { tradeTypes } from "../../utils/constants";

const getConvertionQuote = async (requestData) => {
  const { amount, from, to, amountType } = requestData;
  const form = {
    from,
    to,
    source: tradeTypes.converter,
  };
  switch (amountType) {
    case CRYPTO_CONVERTION_AMOUNT_TYPES.FROM:
      form["from_amount"] = amount;
      break;
    case CRYPTO_CONVERTION_AMOUNT_TYPES.TO:
      form["to_amount"] = amount;
      break;
    case CRYPTO_CONVERTION_AMOUNT_TYPES.VOLUME:
      form["volume"] = amount;
      break;
  }

  const options = {
    cmd: "get_convertion_quote",
    method: "POST",
  };

  const content = await privateService(options, form);
  const { success, quote_id, expiration, from_amount, to_amount, fee } = content;
  if (success) {
    return { success, quoteId: quote_id, expiration: new Date(expiration).getTime(), fromAmount: from_amount, toAmount: to_amount, fee };
  }
  console.error("ConvertionQuoteService", content);
  throw content;
};

export default getConvertionQuote;
