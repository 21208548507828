import { UPLOAD_DOCUMENT_REQUESTED, UPLOAD_DOCUMENT_RESOLVED } from './actionTypes';

export const uploadDocumentRequested = doc => ({
  type: UPLOAD_DOCUMENT_REQUESTED,
  ...doc,
});

export const uploadDocumentResolved = resolution => ({
  type: UPLOAD_DOCUMENT_RESOLVED,
  resolution,
});

export default {
  uploadDocumentRequested,
  uploadDocumentResolved,
};
