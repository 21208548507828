import { compose, withHandlers, withState } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import ErrorOutline from '@material-ui/icons/ErrorOutline';
import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import { translate } from 'react-i18next';
import { DialogContent, DialogActions, Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import { closeCelcoinRegisterDialog, createPixAccount } from '../../redux/actions';
import DangerouslySetInnerHTML from '../../components/DangerouslySetInnerHTML';
import MotherName from '../../components/Forms/MotherName';
import { Form, reduxForm } from 'redux-form';
import FieldRow from '../../components/Forms/FieldRow';
import validate from '../../utils/validate';

const schemas = {
  motherName: {
    type: String,
    required: true,
    regEx: /^((\b[a-zA-Z]{2,40}\b)\s*){2,}$/,
  },
};

const styles = theme => ({
    dialogContent: {
      padding: '0 50px',
      justifyContent: 'center',
      display: 'flex',
      flexDirection: 'column',
      fontFamily:"'Helvetica','Arial', 'sans-serif'",
      [theme.breakpoints.down('sm')]: {
        padding: '0 24px',
      }
    }, 
    inputNotfielded: {
      marginTop: '-10px',
      "&:focus-within": {
        margin: '5px 0 -15px 0',
      },
      transition: '0.3s',
    },
    inputFilded:{
      margin: '5px 0 -15px 0',
    },
    box:{
      backgroundColor: '#f3f3f3 !important',
      borderRadius: '5px',
      padding: '20px',
      margin: '10px 0',
      alignSelf: 'center'
    },
    disclaimer: {
      display: 'flex',
      alignItems: 'center',
      alignContent: 'center',  
      justifyContent: 'center',
      gap: '5px',
      color: '#6c757d',
      fontSize: '10pt',
      margin: '8px 0 15px 0',
      textDecoration: 'none'
    },
    textMuted: {
      fill: '#6c757d',
      fontSize: '15pt',
    },
    dialogActions: {
      '@media only screen and (max-width: 500px)' : {
        flexDirection: 'column-reverse'
      },
    },
    button: {
      '@media only screen and (max-width: 500px)' : {
        width:'90%',
        marginBottom: '10px',
        fontSize: '12px'
      },
    }
  });

const validateRegister = (values, props) => {
  const {setHasValue, setHasError, mother_name} = props
  const validation = validate(schemas)(values, props);
  if(!values.motherName){
    setHasValue(false)
    return validation;
  }
  setHasValue(true)
  if(validation.motherName !== ""){
    setHasError(true)
    return validation;
  }
  setHasError(false)
  return validation;
};

const CreatePixAccountDialog = props => {
  const {
    open,
    t,
    handleSubmit,
    classes,
    handleClose,
    onSubmit,
    mother_name,
    hasValue,
    hasError
  } = props;
  return (
      <Dialog
        maxWidth={'sm'}
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
      >
      <Form {...{ handleSubmit, onSubmit }}>
        <DialogTitle
          align={'center'}
          id="alert-dialog-slide-title"
        >
          {t('screens.bankTransfer.dialog.title')}
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <Grid style={{marginBottom: '15px'}}>
            <DangerouslySetInnerHTML title={t('screens.bankTransfer.dialog.firstContent')} />
          </Grid>
          <Grid style={{marginBottom: '15px'}}>
            <DangerouslySetInnerHTML title={t('screens.bankTransfer.dialog.secondContent')} />
          </Grid>
          <Grid style={{marginBottom: '10px'}}>
            <DangerouslySetInnerHTML title={t('screens.bankTransfer.dialog.thirdContent')} />
          </Grid>
          {!mother_name && 
            <React.Fragment>
              <FieldRow className={classes.box}>
                <DangerouslySetInnerHTML title={t('screens.bankTransfer.dialog.motherNameRequest')} />
                <MotherName
                  name={'motherName'}
                  classes={{root: !hasValue && classes.inputNotfielded}}
                ></MotherName>
              </FieldRow>
            </React.Fragment>
          }

          <Grid>
            <a className={classes.disclaimer} target='_blank' href="http://www.bity.com.br/assets/doc/Termos-de-uso-bitybank.pdf">
              <ErrorOutline  className={classes.textMuted}/>
              <DangerouslySetInnerHTML title={t('screens.bankTransfer.dialog.disclaimer')} />
            </a>
          </Grid>

        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button className={classes.button} onClick={handleClose} variant="raised" color="primary">
            {t('common.close')}
          </Button>
          <Button  className={classes.button} onClick={handleSubmit} disabled={!mother_name && !hasValue || hasError} variant="raised" color="secondary">
            {t('screens.bankTransfer.dialog.createAccountButton')}
          </Button>
        </DialogActions>
      </Form>
    </Dialog>
  );
}

const mapStateToProps = (state) => {
    return {
        open: state.credentials.createPixAccountDialog.open,
        mother_name: state.user.profile.mother_name,
        registerCelcoinForm: state.form.registerCelcoin,
        account: state.user.account,
    };
};

const mapDispatchToProps = dispatch => ({
    closeDialog: () => dispatch(closeCelcoinRegisterDialog()),
    createAccount: (motherName) => dispatch(createPixAccount(motherName)),
});


export default compose(
  translate(),
  withRouter,
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
  withState('hasValue', 'setHasValue', false),
  withState('hasError', 'setHasError', false),
  withHandlers({
    handleClose: ({closeDialog}) => _ => {
      closeDialog();
    },
    handleSubmit: (props) => _ => {
      const {registerCelcoinForm, createAccount, closeDialog} = props
      const {values} = registerCelcoinForm;
      const motherName = values ? values.motherName : undefined;
      createAccount(motherName);
      closeDialog();
      // resetForm();
    },
  }),
  reduxForm({
    form: 'registerCelcoin',
    enableReinitialize: true,
    validate: validateRegister,
  }),
)(CreatePixAccountDialog)
