import { connect } from 'react-redux';
import * as R from 'ramda';
import { withRouter, Link } from 'react-router-dom';
import WAValidator from 'wallet-address-validator';

import {
  withState,
  compose,
  defaultProps,
  withProps,
  branch,
  renderComponent,
  withHandlers,
} from 'recompose';
import { reduxForm, formValueSelector, change } from 'redux-form';

import { translate } from 'react-i18next';
import { toChecksumAddress } from '../../../utils/checksumETH';
import SendCoinsForm from './SendCoinsForm';
import Loader from './Loader';
import validate from '../../../utils/validate';
import validateAdaAddress from '../../../utils/validateAdaAddress';
import validateLunaAddress from '../../../utils/validateLunaAddress';
import { validateTronAddress } from '../../../utils/validateAddress';
import {
  confirmWithdrawalIntentionRequest,
  addDialog, setSelectedMarket, handleAppBarMenu,
} from '../../../redux/actions';
import MissingInfoIndicator from './MissingInfoIndicator';
import { getDefaultLimit } from '../../Trade/TradeOrderForm/constants';
import {
  BSC, BTC, SOL, ADA, LUNA,
} from '../../../config';
import { getCriptoCurrencies, getCurrencyBySymbol, Currency } from '../../../models/Currency';

const ETH = 'ETH';

const schemas = {
  currency: {
    type: String,
    required: true,
    min: 2,
    max: 5,
  },
  address: {
    type: String,
    required: true,
    regEx: /^[A-Za-z0-9 _]+$/,
    min: 26,
    max: 4296,
  },
  amount: {
    type: Number,
    required: true,
    min: 0.0,
  },
};

const validateAmount = ({
  values, limits, balance, currency, fees: { totalFees }, minAmountToSend,
}) => {
  const minWithdrawal = minAmountToSend || getDefaultLimit(currency);
  if (Number(values.amount) < minWithdrawal && (values.amount) > balance[currency] - totalFees) {
    const formattedMinWithdrawal = minWithdrawal.toString().includes('e') ? minWithdrawal.toLocaleString('fullwide', { useGrouping: false }) : minWithdrawal;
    return {
      amount: [
        'NOT_ENOUGH_BALANCE_MIN_WITHDRAWAL_REQUIRED',
        { min: formattedMinWithdrawal },
      ],
    };
  }

  if ((values.amount) > balance[currency] - totalFees) {
    return { amount: 'NOT_ENOUGH_BALANCE' };
  }

  const available = limits.total - limits.used;

  if (values.amount > available) {
    return { amount: 'NOT_ENOUGH_LIMIT' };
  }
  if (Number(values.amount) < minWithdrawal) {
    const formattedMinWithdrawal = minWithdrawal.toString().includes('e')
      ? minWithdrawal.toLocaleString('fullwide', { useGrouping: false })
      : minWithdrawal;
    return {
      amount: ['MIN_VALUE_REQUIRED', { min: formattedMinWithdrawal }],
    };
  }
  return {};
};

const validateAddress = ({
  values,
  currency,
  isOnlyBlockchainAda,
  isOnlyBlockchainLuna,
}) => {
  const { blockchain } = values;
  const isBlockchainSol = blockchain === 'SOL';
  const isBlockhainBsc = blockchain === 'BSC';
  const isBlockchainErc20 = blockchain === 'ETH';
  const isBlockchainTron = blockchain === 'TRON';
  const shouldValidateEthAddress = (currency === BTC && blockchain === 'BSC') ||isBlockchainErc20 || isBlockhainBsc;   
  if (shouldValidateEthAddress && !WAValidator.validate(values.address, ETH)) {
    return { address: 'INVALID_ADDRESS' };
  } if (isBlockchainSol && values.address) {
    if (!(values.address.length >= 32 && values.address.length <= 44)) return { address: 'INVALID_ADDRESS' };
  } else if (isOnlyBlockchainAda && values.address) {
    if (!validateAdaAddress(values.address)) return { address: 'INVALID_ADDRESS' };
  } else if (isOnlyBlockchainLuna && values.address) {
    if (!validateLunaAddress(values.address)) return { address: 'INVALID_ADDRESS' };
  } else if (isBlockchainTron && values.address) {
    if (!validateTronAddress(values.address)) return { address: 'INVALID_ADDRESS' }; 
  }
  return {};
};

const validateETHWalletAddress = (form) => {
  const { address, currency, blockchain } = form;
  const shouldValidateCurrency = !(currency === BTC && blockchain !== 'BSC') && currency !== SOL && currency !== ADA && currency !== LUNA;
  if (shouldValidateCurrency && WAValidator.validate(address, ETH)) {
    return { ...form, address: toChecksumAddress(address) };
  }
  return form;
};

const validateSend = (values, props) => {
  const validations = validate(schemas)(values, props);
  const amountValidations = R.merge(validations, validateAmount(props));
  return R.merge(amountValidations, validateAddress(props));
};

const getPriorityFee = (priority, currencyFees) => {
  if (currencyFees[priority]) { return currencyFees[priority]; }
  return 0;
};

const resetForm = (untouch, changeForm) => {
  const fields = ['address', 'amount', 'memo'];
  const formName = 'sendCoinsForm';
  const newValue = '';
  for (const fieldName of fields) {
    untouch(formName, fieldName);
    changeForm(formName, fieldName, newValue);
  }
};

const mapStateToProps = (state, { form, initialValues }) => {
  const selector = formValueSelector('sendCoinsForm');
  const currency = selector(state, 'currency') || 'BTC';
  const blockchain = selector(state, 'blockchain') || '';
  return {
    balance: state.user.balance,
    limits: state.user.limits,
    fees: state.user.fees,
    valuesFromState: state.form[form] ? state.form[form].values : initialValues,
    kycLevel: state.user.profile.kyc_level,
    taxID: state.user.profile.tax_id,
    otp_active: state.user.profile.otp_active,
    currency,
    selectedMarket: state.market.selectedMarket,
    ticker: state.ticker,
    blockchain,
    currencies: state.currencies.currencies,
    anchorEl: state.layout.appBar.anchorEl,
  };
};

const mapDispatchToProps = (dispatch) => ({
  changeForm: (formName, field, value) => dispatch(change(formName, field, value)),
  sendCoins: (payload) => dispatch(confirmWithdrawalIntentionRequest(payload)),
  setMarket: (market) => dispatch(setSelectedMarket(market)),
  setAnchorEl: (anchorEl, currencySelectorFilter, extraData) => dispatch(handleAppBarMenu(anchorEl, currencySelectorFilter, extraData)),
  alertBSCSelected: () => {
    dispatch(
      addDialog({
        title: 'info.infoNetworkBSCSelectedTitle',
        disableBackdropClick: true,
        disableEscapeKeyDown: true,
        renderComponent: 'alertBSCSelected',
        availableChoices: [
          {
            label: 'common.cancel',
            actionToTake: 'setNetworkToBTC',
          },
          {
            label: 'common.ok',
            actionToTake: '',
            color: 'secondary',
            variant: 'raised',
          },
        ],
        actionsToTake: {
          setNetworkToBTC: () => dispatch(change('sendCoinsForm', 'blockchain', '')),
        },
      }),
    );
  },
  warningCripto: (info) => {
    dispatch(
      addDialog({
        title: info,
        availableChoices: [
          {
            label: 'common.understood',
            actionToTake: '',
            color: 'secondary',
            variant: 'raised',
          },
        ],
      }),
    );
  },
  alertLowPrioritySelected: () => {
    dispatch(
      addDialog({
        title: 'common.attention',
        disableBackdropClick: true,
        disableEscapeKeyDown: true,
        renderComponent: 'alertLowPriorityFeeSelected',
        availableChoices: [
          {
            label: 'common.cancel',
            actionToTake: 'setPriorityToHigh',
          },
          {
            label: 'common.ok',
            actionToTake: '',
            color: 'secondary',
            variant: 'raised',
          },
        ],
        actionsToTake: {
          setPriorityToHigh: () => dispatch(change('sendCoinsForm', 'priority', 'high')),
        },
      }),
    );
  },
  alertUserMissingOpt: () => {
    dispatch(
      addDialog({
        title: 'transactions.withdrawal.missingOPT',
        availableChoices: [
          {
            label: 'common.close',
            actionToTake: '',
            component: Link,
            to: '/profile/security',
            color: 'secondary',
            variant: 'raised',
          },
        ],
      }),
    );
  },
  alertMissingMemo: (props, form) => {
    dispatch(
      addDialog({
        title: 'transactions.withdrawal.alertMissingMemo',
        availableChoices: [
          {
            label: 'common.sendAnyway',
            actionToTake: 'sendCoins',
          },
          {
            label: 'common.fillMemo',
            actionToTake: '',
            color: 'secondary',
            variant: 'raised',
          },
        ],
        actionsToTake: {
          sendCoins: () => {
            const { sendCoins, untouch, changeForm } = props;
            sendCoins(form);
            resetForm(untouch, changeForm);
          },
        },
      }),
    );
  },
  alertLowKycMustWaitForWithdrawal: () => {
    dispatch(
      addDialog({
        title: 'transactions.withdrawal.alertLowKycMustWaitForWithdrawal',
        availableChoices: [
          {
            label: 'common.understood',
            actionToTake: '',
            color: 'secondary',
            variant: 'raised',
          },
        ],
      }),
    );
  },
});

const isLoading = props => {
  const {
    fees,
    limits,
    currency,
    ticker,
  } = props;

  const anyEmpty = R.any(R.isEmpty, [
    limits[currency],
    fees[currency],
    ticker[currency],
  ]);

  return anyEmpty;
};

const isFreeNetworkTransaction = (priorityFees) => {
  return priorityFees.high === 0
    && priorityFees.medium === 0
    && priorityFees.low === 0;
};

const getDefaultBlockchainValue = blockchainOptions => {
  return blockchainOptions[0] ? blockchainOptions[0].value : '';
};

const setFormDefaultBlockchain = (blockchainOptions, blockchain, changeForm) => {
  const containsBlockchainOption = blockchainOptions.some(optionDetails => optionDetails.value === blockchain);
  if (blockchainOptions.length === 1 || !containsBlockchainOption) {
    const defaultBlockchain = getDefaultBlockchainValue(blockchainOptions);
    if (blockchain !== defaultBlockchain) { changeForm('sendCoinsForm', 'blockchain', defaultBlockchain); }
  }
};

const withSendProps = props => {
  const {
    currency,
    limits,
    valuesFromState: { amount },
    match: { params: { address = '' } },
    selectedMarket,
    changeForm,
    valuesFromState,
    setCurrency,
    ticker,
    currencies,
  } = props;

  const currencyList = getCriptoCurrencies(currencies);
  const currencyConfig = getCurrencyBySymbol(currencyList, currency);
  const currencyConfigObj = new Currency(currencyConfig);

  const blockchainOptions = currencyConfigObj.getWithdrawalBlockchainOptions;
  const isOnlyBlockchainSol = currencyConfigObj.hasWithdrawalNetwork('SOL') && currencyConfigObj.withdrawal.length === 1;
  const isOnlyBlockchainAda = currencyConfigObj.hasWithdrawalNetwork('ADA') && currencyConfigObj.withdrawal.length === 1;
  const isOnlyBlockchainLuna = currencyConfigObj.hasWithdrawalNetwork('TERRA') && currencyConfigObj.withdrawal.length === 1;
  const isOnlyBlockchainLunc = currencyConfigObj.hasDepositNetwork('TERRAC') && currencyConfigObj.withdrawal.length === 1;
  const isOnlyBlockchainBep20 = currencyConfigObj.hasWithdrawalNetwork('BSC') && currencyConfigObj.withdrawal.length === 1;
  const isOnlyBlockchainErc20 = currencyConfigObj.hasWithdrawalNetwork('ETH') && currencyConfigObj.withdrawal.length === 1;
  const shouldShowBlockchainOptions = blockchainOptions.length > 1;

  const priority = valuesFromState.priority || 'high';
  const blockchain = valuesFromState.blockchain || getDefaultBlockchainValue(blockchainOptions);
  const currencyLimits = limits[currency];
  const [total, used, available_used] = R.props(
    ['total_withdrawal', 'min_withdrawal', 'available_withdrawal'],
    currencyLimits);

  const withdrawalInfo = currencyConfigObj.getWithdrawalInfoByNetwork(blockchain);
  const priorityFees = withdrawalInfo ? withdrawalInfo.fixedFeesJson : {};

  const hasSameFees = withdrawalInfo ? withdrawalInfo.fixedFeesArray.every((fee, index) => fee.fee === withdrawalInfo.fixedFees.low) : false;
  const minAmountToSend = withdrawalInfo ? withdrawalInfo.minAmount : null;
  const percent = withdrawalInfo ? withdrawalInfo.percentFee : 0;

  const fixed = getPriorityFee(priority, priorityFees);

  const totalFees = percent * Number(amount) + fixed;
  const currencyMarket = selectedMarket.split('-')[0];
  if (currencyMarket !== currency) {
    changeForm('sendCoinsForm', 'currency', currencyMarket);
    setCurrency(currencyMarket);
  }

  setFormDefaultBlockchain(blockchainOptions, blockchain, changeForm);

  return {
    currencyList,
    isOnlyBlockchainSol,
    isOnlyBlockchainAda,
    isOnlyBlockchainLuna,
    isOnlyBlockchainLunc,
    isOnlyBlockchainBep20,
    isOnlyBlockchainErc20,
    currencyConfig,
    shouldShowBlockchainOptions,
    blockchainOptions,
    priorityFees,
    hasSameFees,
    minAmountToSend,
    priorityFeesBrl: {
      high: priorityFees.high * ticker[currency].last,
      medium: priorityFees.medium * ticker[currency].last,
      low: priorityFees.low * ticker[currency].last,
      lowest: priorityFees.lowest * ticker[currency].last,
    },
    freeNetworkTransaction: isFreeNetworkTransaction(priorityFees),
    limits: {
      total,
      used,
      minWithdrawal: minAmountToSend || 0,
      available_used,
    },
    fees: {
      percent,
      fixed,
      totalFees,
    },
    address,
    initialValues: {
      address,
      amount: '',
      memo: '',
      currency: currencyMarket,
      priority: 'high',
      blockchain: getDefaultBlockchainValue(blockchainOptions),
    },
  };
};

const sendLunaForm = (props, form) => {
  const { memo, address } = form;
  const {
    sendCoins, untouch, changeForm, alertMissingMemo,
  } = props;

  const shouldSendMemo = memo.length > 0;

  if (shouldSendMemo) {
    const addressWithMemo = `${address}|${memo}`;
    const formWithMemo = { ...form, address: addressWithMemo };
    sendCoins(formWithMemo);
    resetForm(untouch, changeForm);
  } else {
    alertMissingMemo({ sendCoins, untouch, changeForm }, form);
  }
};

const enhance = compose(
  translate(),
  withRouter,
  defaultProps({
    initialValues: {
      amount: '',
      memo: '',
    },
  }),
  connect(mapStateToProps, mapDispatchToProps),
  withState('open', 'setIsOpen', false),
  withState('lowKycWarningDismissed', 'setLowKycWarningDismissed', false),
  withState('currency', 'setCurrency', 'BTC'),
  branch(isLoading, renderComponent(Loader)),
  branch(({ kycLevel }) => Number(kycLevel) === 0, renderComponent(MissingInfoIndicator)),
  withProps(withSendProps),
  reduxForm({
    form: 'sendCoinsForm',
    validate: validateSend,
  }),
  withHandlers({
    onSubmit: (
      {
        sendCoins,
        otp_active,
        alertUserMissingOpt,
        changeForm,
        untouch,
        currency,
        alertMissingMemo,
      }) => form => {
      if (Number(otp_active) === 0) {
        alertUserMissingOpt();
      } else if (Number(otp_active) === 1) {
        if (currency === LUNA) {
          sendLunaForm({
            sendCoins, untouch, changeForm, alertMissingMemo,
          }, form);
        } else {
          sendCoins(validateETHWalletAddress(form));
          resetForm(untouch, changeForm);
        }
      }
    },
    onRequestSent: ({ setIsOpen }) => () => {
      setIsOpen(false);
    },
    onSuccessRequest: ({ reset }) => () => {
      reset();
    },
    handleCurrencyChange: ({
      setCurrency, setMarket, changeForm, history,
    }) => currency => {
      if (currency.name === 'BRZ') {
        const nextLocation = {
          pathname: '/bankTransfers/withdrawal',
          state: { stableCoinOp: true },
        };
        history.push(nextLocation);
      } else {
        setCurrency(currency.symbol);
        changeForm('sendCoinsForm', 'priority', 'high');
        changeForm('sendCoinsForm', 'currency', currency.symbol);
        setMarket({market: `${currency.symbol}-BRL`});
      }
    },
    handleBlockchainChange: ({ changeForm, alertBSCSelected }) => blockchainOptions => {
      changeForm('sendCoinsForm', 'blockchain', blockchainOptions.value);
      if (blockchainOptions.value === BSC) { alertBSCSelected(); }
    },
    handleLowPriorityFeeSelected: ({ alertLowPrioritySelected }) => () => {
      alertLowPrioritySelected();
    },
    handleLowKycWarning: ({
      alertLowKycMustWaitForWithdrawal, kycLevel, limits, lowKycWarningDismissed, setLowKycWarningDismissed,
    }) => () => {
      const userHasLimits = limits.total > 0;
      if (Number(kycLevel) <= 1 && !userHasLimits && !lowKycWarningDismissed) {
        alertLowKycMustWaitForWithdrawal();
        setLowKycWarningDismissed(true);
      }
    },
  }),
);

export default enhance(SendCoinsForm);
