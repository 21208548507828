import React from 'react'
import * as R from 'ramda'
import { connect, getIn } from 'formik'
import SubmitButton from '../../../components/Forms/SubmitButton'
import LinkButton from '../../../components/Buttons/LinkButton'


const WrappedSubmitButton = props => {
 
  const blockUserSubmit = props.blockUserSubmit;
  const errors = getIn(props.formik.errors)
  const isValid = blockUserSubmit ? false : R.isEmpty(errors)
  if(!isValid && errors.totalValue === "TO_RESTOCK_BALANCE"){
    return (
       <LinkButton
        to={'/bankTransfers/deposit'}
        variant='raised'
        className={[
          props.classes.textField,
          props.classes.depositButton,
          props.classes.mobileButton,
          ]}
        
        >
          {props.t('TO_DEPOSIT')}
        </LinkButton>
    )
  }
  return (<SubmitButton disabled={!isValid} {...props} />)
}

export default connect(WrappedSubmitButton)
