import React from "react";

export const MissionsHexagonal = (props) => {
    const { color, width = 56, height = 52 } = props;

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 56 52"
            fill="none"
        >
            <path
                d="M53.8453 30C55.2744 27.5248 55.2744 24.4752 53.8453 22L44.3868 5.61731C42.9577 3.1421 40.3167 1.61731 37.4585 1.61731L18.5415 1.61731C15.6833 1.61731 13.0423 3.1421 11.6133 5.61731L2.1547 22C0.725638 24.4752 0.725638 27.5248 2.1547 30L11.6132 46.3827C13.0423 48.8579 15.6833 50.3827 18.5414 50.3827L37.4585 50.3827C40.3167 50.3827 42.9577 48.8579 44.3867 46.3827L53.8453 30Z"
                fill={color}
                stroke="white"
                stroke-width="2"
            />
        </svg>
    );
}