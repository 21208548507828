import React from "react";
import PropTypes from 'prop-types';
import { compose } from "recompose";
import { Typography, withStyles } from "@material-ui/core";
import classNames from "classnames";
import qrCode from '../../assets/QR-Code-black.svg'
import appImage from '../../assets/simplified-home.png'
import { translate } from "react-i18next";

const styles = (theme) => ({
  default: {
    "& h2": {
      margin: 0,
      fontWeight: 400,
    },
    position: "relative",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', 'sans-serif'",
    margin: "auto",
  },
  highlights: {
    "& span": {
      fontWeight: 600,
    },
  },
  card: {
    backgroundColor: "#F7B733",
    borderRadius: "8px",

    "@media screen and (max-width: 640px)": {
      padding: "30px 45px !important",
    },
  },
  flex: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "15px",
  },
  title: {
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    maxWidth: "450px",

    "@media screen and (max-width: 640px)": {
      alignItems: "center",
      textAlign: "center",
    },
  },
  qrCodeLogo: {
    backgroundColor: "#fafafa",
    padding: "12.5px",
    borderRadius: "10px",
  },
  container: {
    maxWidth: "350px",
    gap: "30px",

    "@media screen and (max-width: 640px)": {
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
      gap: "10px",
    },
  },
  app: {
    position: "absolute",
    transform: "translate(-50%, -50%)",
  },
  contentWrapper: {
    "@media screen and (max-width: 640px)": {
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
  },
});

const BannerBitybank = (props) => {
  const { classes, title, content, t } = props;
  const maxWidth = content ? { maxWidth: 1500 } : { maxWidth: 800 };
  const padding = content ? { padding: '30px 45px' } : { padding: '10px 45px' };
  const app = content ? {top: '25px', left: '52%'} : {top: '40px', left: '60%'};
  const sizeApp = content ?  {height:'235px', width:'285px'} :  {height: '165px', width: '235px'};

  return (
    <div className={classes.card} style={padding}>
      <div className={classNames(classes.default, classes.highlights, classes.flex, classes.contentWrapper)} style={maxWidth}>
        <div className={classNames(classes.flex, classes.title)}>{title}</div>
        <div className={classes.app} style={app}>
            <img src={appImage} style={sizeApp} className={"app-image"} alt={t("imageAlts.phoneOpenInApp")}/>
        </div>
        <div className={classNames(classes.container, classes.flex)}>
          <Typography>{content}</Typography>
          <div className={classes.containerDownload} style={{ position: 'relative', width: 110, height: 110, zIndex: 10 }}>
              <img src={qrCode} className={classes.qrCodeLogo} height={110} width={110} alt={t("imageAlts.qrCodeAppBity")}/>
          </div>
        </div>
      </div>
    </div>
  )
}

BannerBitybank.propTypes = {
  title: PropTypes.node.isRequired,
  content: PropTypes.node,
  classes: PropTypes.objectOf(PropTypes.string),
}

const enhance = compose(
  translate(), 
  withStyles(styles)
)

export default enhance(BannerBitybank);