import React from 'react'

import Hidden from '@material-ui/core/Hidden'
import Typography from '@material-ui/core/Typography'

import InfoTooltip from '../../../../components/InfoTooltip'


const OrderBookHeaderTitle = (t, classes, ordersType, isShowAllSelected, currencyName) => {
  const titleClass = ordersType === 'BUY'
    ? classes.buyLabel
    : classes.sellLabel
  const type = ordersType.toLowerCase()
  return (
    <Typography
      variant="title"
      className={titleClass}
    >
      <div>
        <div className={classes.cardLabel}>
          {t(`transactions.${type}.${type}Offers`)}
          <InfoTooltip wrapperRootHeader={true} title={t(`info.${type}Orders`)} />
        </div>
        <Hidden smDown>
          <div style={{fontSize: 11, marginTop: '-7px', color: 'rgba(0, 0, 0, 0.87)' }}>
            {
              (isShowAllSelected
                ? t(`transactions.${type}.${type}OffersShowAllSub`, { currencyName })
                : t(`transactions.${type}.${type}OffersShowMineSub`, { currencyName }) )
            }

          </div>
        </Hidden>
      </div>
    </Typography>
  )
}

export default OrderBookHeaderTitle
