import ExpressTrade from './ExpressTrade'
import { compose, withHandlers, withProps, withState, lifecycle } from 'recompose'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import { fetchNewsData } from '../../redux/actions'
import {styles} from './styles';
import { translate } from 'react-i18next';


const mapdispatchToProps = dispatch => ({
  fetchNews: (frameName) => dispatch(fetchNewsData(frameName)),
})

const mapStateToProps = state => ({
  market: state.market.selectedMarket,
  currencies: state.currencies.currencies,
  token: state.credentials.token,
  demoAccount: state.credentials.demoAccount,
  isBetaTester: state.user.profile.beta_tester,
})

const enhance = compose(
  connect(mapStateToProps, mapdispatchToProps),
  translate(),
  withStyles(styles),
  withState("activeTabIndex", "setActiveTabIndex", 0),
  withHandlers({
    setActiveTabIndexSwipeable: ( ) => (setActiveTabIndex, val) => {
      setActiveTabIndex(val);
    },
  }),
  lifecycle({
    componentDidMount() {
      const { fetchNews} = this.props;
      fetchNews('WEB_BANNER_1');
    },
  }),
  withProps(({
    match: { params: { orderType = 'buy' } },
    history: { replace }
  }) => ({ 
    orderType,
    handleRouteChange: (_, value) => replace(`/express-trade/${value}`)
  }))
)
export default enhance(ExpressTrade)
