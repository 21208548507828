import React, { useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { translate } from 'react-i18next';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { reduxForm, formValueSelector } from 'redux-form';

import { sendSmsPhoneValidation, validatePhoneSmsToken, closeSmsTokenDialog
} from '../../redux/credentials/actions';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { contactSupport } from "../../utils/contactSupport";
import TextField from '../../components/Forms/TextField';
import { normalizeSMSToken } from '../../utils/NumberFormat/normalizers';
import PhoneField from "../../components/Fields/PhoneField";
import InputAdornment from "@material-ui/core/InputAdornment";
import CountdownSeconds from "../../components/CountdownSeconds";

const styles = theme => ({
    containerWrap: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        margin: 5
    },
    validationLoading: {
        marginTop: 5,
        marginLeft: 5
    },
    submitInnerContent:{
        display: 'flex',
        alignItems: 'center'
    },
    fieldSize: {
        width: '100%'
    },
    endAdornmentButton: {
        fontSize: '10px',
        fontWeight: 500,
        marginBottom:'5px',
        padding: '6px',
        textAlign: 'center',
        width: 90,
        backgroundColor: theme.palette.secondary.main,
        minHeight: 30,
        color: theme.palette.primary.dark,
        "&:disabled": {
            backgroundColor: theme.palette.grey[200]
        },
        "&:hover": {
            backgroundColor: theme.palette.secondary.main,
            filter: `brightness(85%)`
        },
    },
    resendSmsLabel: {
        display: 'flex',
        gap: '2px'
    }
});

const validate = (values, _props) => {
    const errors = {};
    if (values.phone && values.phone.length < 11)
        errors.phone = 'PHONE_ERROR';
    if (values.smsToken && !isSmsTokenValid(values.smsToken))
        errors.smsToken = 'SMS_TOKEN_ERROR';
    return errors
}

const isSmsTokenValid = emailToken => {
    return emailToken !== undefined && emailToken.length === 6;
}

const mapDispatchToProps = dispatch => ({
    sendSmsPhoneValidation: form => dispatch(sendSmsPhoneValidation(form)),
    validatePhoneSmsToken: form => dispatch(validatePhoneSmsToken(form)),
    closeDialog: () => dispatch(closeSmsTokenDialog()),
});

const selector = formValueSelector('validateSmsToken');
const mapStateToProps = (state) => {
    return {
        dialogOptions: state.credentials.smsTokenDialog,
        phone: selector(state, 'phone'),
        smsToken: selector(state, 'smsToken')
    };
};

const withPhonePrefix = phone => {
    return "+55".concat(phone);
}


const AppSmsTokenDialog = props => {
    const {
        handleSubmit,
        classes,
        t,
        sendSmsPhoneValidation,
        validatePhoneSmsToken,
        dialogOptions: { open, payload },
        phone,
        smsToken,
        closeDialog
    } = props;


    const [smsSent, setSmsSent] = useState(false);
    const [retry, setRetry] = useState(false);
    const phoneIsValid = phone && phone.length === 11;

    const handleClose = () => closeDialog();

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
            disableBackdropClick={true}
            disableEscapeKeyDown={true}
        >
            <form onSubmit={handleSubmit}>

                <DialogTitle id="form-dialog-title">
                    {t('forms.validationSmsToken.dialog.title')}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <div className={classes.containerWrap}>
                            {`${t('PHONE_NOT_VALIDATED')}`}
                        </div>
                        <div className={classes.containerWrap}>
                            {`${t('TYPE_SMS_TOKEN')}`}
                        </div>
                    </DialogContentText>
                    <PhoneField disabled={smsSent} name="phone" i18nScope="forms.phone"
                                classes={{root: classes.fieldSize}}
                                InputProps={{
                                    endAdornment: (
                                            <InputAdornment position="end">
                                                <Button disabled={smsSent || !phoneIsValid}
                                                        className={classes.endAdornmentButton}
                                                        onClick={(_e) => {
                                                            sendSmsPhoneValidation({...payload, phone: withPhonePrefix(phone), retry});
                                                            setSmsSent(true);
                                                            setRetry(true);
                                                        }}
                                                >
                                                     { !smsSent ? t('forms.validationSmsToken.sendSms') :
                                                         <div className={classes.resendSmsLabel}>
                                                             {t('common.resendIn')}
                                                             <CountdownSeconds totalSeconds={90}
                                                                               onFinish={() => {
                                                                                   setSmsSent(false);
                                                                               }} />
                                                         </div>}
                                                </Button>
                                            </InputAdornment>
                                    )}}
                                InputLabelProps={{style: {fontSize: 13}}}
                                inputProps={{style: {fontSize: 13}}}
                    />
                    <TextField normalize={normalizeSMSToken} name="smsToken" i18nScope="forms.validationSmsToken"
                               classes={{root: classes.fieldSize}}
                               InputProps={{
                                   endAdornment: (
                                       <InputAdornment position="end">
                                           <Button disabled={!isSmsTokenValid(smsToken)}
                                                   className={classes.endAdornmentButton}
                                                   onClick={() => validatePhoneSmsToken({...payload, phone: withPhonePrefix(phone), sms_token: smsToken})}>
                                               {t('forms.validationSmsToken.validateSms')}
                                           </Button>
                                       </InputAdornment>
                                   )}}
                               InputLabelProps={{style: {fontSize: 13}}}
                               inputProps={{style: {fontSize: 13}}}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        {t('common.cancel')}
                    </Button>
                    <Button color="primary"
                            onClick={()=>{
                                contactSupport();
                            }}
                    >
                        {t('CONTACT_SUPPORT')}
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

AppSmsTokenDialog.defaultProps = {
    dialogOptions: {
        open: false,
    }
};

AppSmsTokenDialog.propTypes = {
    dialogOptions: PropTypes.shape({
        open: PropTypes.bool,
    }).isRequired
};

const enhance = compose(
    translate(),
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm({
      form: 'validateSmsToken',
      validate: validate
    }),
);

export default enhance(AppSmsTokenDialog);
