import privateService from "../privateService";

const executeConvertionQuote = async (quoteId) => {
  const form = {
    quote_id: quoteId,
  };

  const options = {
    cmd: "execute_convertion_quote",
    method: "POST",
  };

  const content = await privateService(options, form);
  const { success } = content;
  if (success) return;
  console.error("ConvertionQuoteService", content);
  throw content;
};

export default executeConvertionQuote;
