import * as R from 'ramda';
import * as actions from './actionTypes';
import {valueToMd5} from "../../utils/valueToMd5";
// import { DEV } from '../../../config';

export const initialState = {
  deviceId: null,
  captcha: null,
  loadingStore: true,
  token: null,
  logged: false,
  userLoggedInWithSavedToken: true,
  demoAccount: false,
  intercom_hash: null,
  isRequestingResetOtp: false,
  otpDialog: {
    open: false,
  },
  otpAppDialog: {
    open: false,
    title: '',
  },
  resendEmailDialog: {
    open: false,
  },
  cardDialog: {
    open: false,
  },
  validationTokenDialog: {
    open: false,
    validationCmd: '',
    payload: {}
  },
  smsTokenDialog: {
    open: false,
    payload: {}
  },
  renewConnectionDialog: {
    open: false,
  },
  createPixAccountDialog: {
    open: false,
  },
  userDeclarationDialog: {
    open: false,
    declarationType: null,
    pendingSends: []
  }
};

const updateCaptcha = (state, { captcha }) => {
  return R.merge(state, { captcha });
};

const updateCredentials = (state, { credentials: {demoAccount, userLoggedInWithSavedToken} }) => {
  const newState = R.merge(state, {
    otpDialog: {
      open: false,
    },
    otpAppDialog: {
      open: false,
      title: '',
    },
    logged: true,
    userLoggedInWithSavedToken: typeof userLoggedInWithSavedToken === 'boolean' ? userLoggedInWithSavedToken : true,
    demoAccount,
    intercom_hash: null,
    createPixAccountDialog: {
      open: false,
    },
  });

  return newState;
};

const removeCredentials = state => {
  return R.merge(state, {
    token: null,
    logged: false,
    captcha: null,
    demoAccount: false,
    intercom_hash: null,
    createPixAccountDialog: {
      open: false,
    },
  });
};

const removeIntercomHash = state => {
  return R.merge(state, {
    intercom_hash: null,
  });
};

const removeCelcoinRegisterDialog = state => {
  return R.merge(state, {
    createPixAccountDialog: {
      open: false,
    },
  });
};

const resolveValidation = (state, { type, ...action }) => {
  return R.merge(state, {
    validation: action,
  });
};

const requestValidation = (state, { token }) => {
  return R.merge(state, {
    validation: {
      resolved: false,
      token,
    },
  });
};


const resolveRegister = (state, { type, ...action }) => {
  return R.merge(state, {
    register: action,
  });
};

const requestRegister = (state, { form }) => {
  return R.merge(state, {
    register: {
      resolved: false,
      form,
    },
  });
};

const closeTopDialog = (state, _action) => {
  return R.merge(state, {
    otpDialog: {
      open: false,
    },
  });
};

const addTopDialog = (state, { type, ...action }) => {
  return R.merge(state, {
    otpDialog: {
      open: true,
      ...action,
    },
  });
};

const addResendEmailDialog = (state, { type, ...action }) => {
  return R.merge(state, {
    resendEmailDialog: {
      open: true,
      ...action,
    },
  });
};

const closeResendEmailDialog = (state, _action) => {
  return R.merge(state, {
    resendEmailDialog: {
      open: false,
    },
  });
};

const addCardDialog = (state, { type, ...action }) => {
  return R.merge(state, {
    cardDialog: {
      open: true,
      ...action,
    },
  });
};

const closeCardDialog = (state, _action) => {
  return R.merge(state, {
    cardDialog: {
      open: false,
      renderComponent: null,
    },
  });
};

const addValidationTokenDialog = (state, { type, ...action }) => {
  return R.merge(state, {
    validationTokenDialog: {
      open: true,
      ...action,
    },
  });
};

const closeValidationTokenDialog = (state, _action) => {
  return R.merge(state, {
    validationTokenDialog: {
      open: false,
    },
  });
};

const addSmsTokenDialog = (state, { type, ...action }) => {
  return R.merge(state, {
    smsTokenDialog: {
      open: true,
      ...action,
    },
  });
};

const closeSmsTokenDialog = (state, _action) => {
  return R.merge(state, {
    smsTokenDialog: {
      open: false,
    },
  });
};

const addRenewConnectionDialog = (state, { type, ...action }) => {
  return R.merge(state, {
    renewConnectionDialog: {
      open: true,
      ...action,
    },
  });
};

const closeRenewConnectionDialog = (state, _action) => {
  return R.merge(state, {
    renewConnectionDialog: {
      open: false,
    },
  });
};

const addCreatePixAccount = (state, _action) => {
  return R.merge(state, {
    createPixAccountDialog: {
      open: true,
    },
  });
};

const addOtpAppDialog = (state, action) => {
  return R.merge(state, {
    otpAppDialog: {
      open: true,
      ...action
    },
  });
};

const closeOtpAppDialog = (state, _action) => {
  return R.merge(state, {
    otpAppDialog: {
      open: false,
      title: '',
    },
  });
};

const changeUserDeclarationDialog = (state, action) => {
  const { open, declarationType, pendingSends } = action;
  const pendingSendsArray = pendingSends ? pendingSends : [];
  return R.merge(state, {
    userDeclarationDialog: {
      open,
      declarationType,
      pendingSends: pendingSendsArray
    }
  });
};


const updateToken = (state, { response }) => {
  const token = R.prop('auth_token', response);

  const newState = R.merge(state, {
    token,
  });

  return newState;
};

const updateDeviceId = (state) => {

  const stateDeviceId = state.deviceId;
  if (!stateDeviceId){
    const currentDate = new Date();
    const timestamp = currentDate.getTime();

    const deviceId = valueToMd5( `${timestamp}${Math.random()}`);

    return R.merge(state, {
      deviceId
    });
  } else {
    return state;
  }

};

const credentialsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.UPDATE_CAPTCHA:
      return updateCaptcha(state, action);

    case actions.UPDATE_CREDENTIALS:
      return updateCredentials(state, action);

    case actions.REMOVE_CREDENTIALS:
      return removeCredentials(state);

    case actions.REMOVE_INTERCOM_HASH: 
      return removeIntercomHash(state)

    case actions.REMOVE_CELCOIN_REGISTER_DIALOG: 
      return removeCelcoinRegisterDialog(state)
      
    case actions.REQUEST_VALIDATION:
      return requestValidation(state, action);

    case actions.RESOLVE_VALIDATION:
      return resolveValidation(state, action);

    case actions.ADD_CELCOIN_REGISTER_DIALOG:
      return addCreatePixAccount(state, action);

    case actions.REQUEST_REGISTER:
      return requestRegister(state, action);

    case actions.RESOLVE_REGISTER:
      return resolveRegister(state, action);

    case actions.OTP_VALIDATE_LOGIN:
      return updateToken(state, action);

    case actions.UPDATE_TOKEN:
      return updateToken(state, action);

    case actions.ADD_OTP_DIALOG:
      return addTopDialog(state, action);

    case actions.CLOSE_OTP_DIALOG:
      return closeTopDialog(state, action);

    case actions.ADD_RESEND_EMAIL_DIALOG:
      return addResendEmailDialog(state, action);

    case actions.ADD_CARD_DIALOG:
      return addCardDialog(state, action);

    case actions.CLOSE_CARD_DIALOG:
      return closeCardDialog(state, action);

    case actions.CLOSE_RESEND_EMAIL_DIALOG:
      return closeResendEmailDialog(state, action);
      
    case actions.ADD_VALIDATION_TOKEN_DIALOG:
      return addValidationTokenDialog(state, action);

    case actions.CLOSE_VALIDATION_TOKEN_DIALOG:
      return closeValidationTokenDialog(state, action);

    case actions.ADD_RENEW_CONNECTION_DIALOG:
      return addRenewConnectionDialog(state, action);

    case actions.CLOSE_RENEW_CONNECTION_DIALOG:
      return closeRenewConnectionDialog(state, action);

    case actions.ADD_OTP_APP_DIALOG:
      return addOtpAppDialog(state, action);

    case actions.CLOSE_OTP_APP_DIALOG:
      return closeOtpAppDialog(state, action);

    case actions.ADD_SMS_TOKEN_DIALOG:
      return addSmsTokenDialog(state, action);

    case actions.CLOSE_SMS_TOKEN_DIALOG:
      return closeSmsTokenDialog(state, action);

    case actions.GENERATE_DEVICE_ID:
      return updateDeviceId(state);

    case actions.ADD_INTERCOM_HASH:
      return R.merge(state, { intercom_hash: action.response.intercom_hash});

    case 'REDUX_STORAGE_LOAD':
      return R.merge(state, { loadingStore: false,
        userDeclarationDialog: {
          open: false,
          declarationType: null,
          pendingSends: []
        }
      });

    case actions.SET_USER_LOGGED_IN_WITH_SAVED_TOKEN:
      return R.merge(state, { userLoggedInWithSavedToken: action.userLoggedInWithSavedToken });
    
    case actions.RESET_OTP_REQUESTED:
      return R.merge(state, { isRequestingResetOtp: true });

    case actions.RESET_OTP_REQUESTED_SUCCESS:
      return R.merge(state, { isRequestingResetOtp: false });

    case actions.CHANGE_USER_DECLARATION_DIALOG:
      return changeUserDeclarationDialog(state, action);

    default:
      return state;
  }
};

export default credentialsReducer;
