import {
    getBtcBlockExplorer,
    getEthBlockExplorer,
    getSolBlockExplorer,
    getBscBlockExplorer,
    getAdaBlockExplorer,
    getLunaBlockExplorer,
    getLuncBlockExplorer,
    getTronBlockExplorer } from "../config";


const AVAILABLE_EXPLORERS = {
    'SOL': getSolBlockExplorer,
    'ETH': getEthBlockExplorer,
    'BSC': getBscBlockExplorer,
    'BTC': getBtcBlockExplorer,
    'ADA': getAdaBlockExplorer,
    'TERRA': getLunaBlockExplorer,
    'TERRAC': getLuncBlockExplorer,
    'TRON': getTronBlockExplorer,
};

const getBlockExplorer = (id, blockchain) => {
    if (blockchain in AVAILABLE_EXPLORERS)
        return AVAILABLE_EXPLORERS[blockchain](id);
    return AVAILABLE_EXPLORERS['ETH'](id);
}

export default getBlockExplorer;
