import * as R from 'ramda';

const mapStatus = rawStatus => {
  switch (rawStatus) {
    case 'ID':
      return 'TAXID';
    case 'SELF':
      return 'SELFIE';
    case 'CONST':
      return 'CONSTITUTION';
    default:
      return rawStatus;
  }
};


const _kycToObj = R.pipe(
  R.split(','),
  R.map(R.split('_')),
  R.map(R.adjust(mapStatus, 0)),
  R.fromPairs,
);

const kycToObj = kyc => {
  if (R.isEmpty(kyc) || R.isNil(kyc)) {
    return kyc;
  }
  return _kycToObj(kyc);
}

export default kycToObj;
