import React from 'react';
import PropTypes from 'prop-types';

import AppWrapper from '../layout';

const WrappedRoute = ({
  component: Component,
  children,
  appWrapperProps,
  componentProps,
}) => {
  return (
    <AppWrapper
      history={componentProps.history}
      navigationOptions={Component.navigationOptions}
      location={componentProps.location}
      {...appWrapperProps}
    >
      {children}
      <Component {...componentProps} />
    </AppWrapper>
  );
};


WrappedRoute.defaultProps = {
  computedMatch: {},
  location: {},
};

WrappedRoute.propTypes = {
  component: PropTypes.func.isRequired,
  computedMatch: PropTypes.shape({
    isExact: PropTypes.bool,
    params: PropTypes.object,
    path: PropTypes.string,
    url: PropTypes.string,
  }),
  location: PropTypes.shape({
    hash: PropTypes.string,
    key: PropTypes.string,
    pathname: PropTypes.string,
    search: PropTypes.string,
    state: PropTypes.object,
  }),
};

export default WrappedRoute;
