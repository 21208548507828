import React from 'react'

import PercentageSwitchButtons from './PercentageSwitchButtons'
import MoneyInput from '../../../components/FormsV2/MoneyInput'
import { USDT, USDC, USDBP, ATLAS } from '../../../config';

const usdtCurrencyContext = [USDT, USDC, USDBP, ATLAS];

const AmountInput = props => {
  const {
    className,
    shouldDisplayPercentageSwitch,
    selectedPercentage,
    setPercentage,
    i18nContext,
    currency,
    formatter,

    onChange,
    value
  } = props

  const useUsdtContext = usdtCurrencyContext.includes(currency);

  return (
    <MoneyInput
      formatter={formatter}
      name={useUsdtContext ? 'amount-usdt' : 'amount'}
      currency={currency}
      onChange={onChange}
      value={value}
      className={className}
      i18nContext={i18nContext}
      InputProps={{
        endAdornment: shouldDisplayPercentageSwitch && (
          <PercentageSwitchButtons
            {...props}
            selectedPercentage={selectedPercentage}
            setPercentage={setPercentage}
          />
        )
      }}
      {...props}
    />
  )
}

export default AmountInput
