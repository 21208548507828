import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

const PageTabComponent = (props) => {
  const { tabIndex, onTabChange, tabLabels } = props;
  return (
    <AppBar position="static" color="default">
      <Tabs
        value={tabIndex}
        onChange={(event, value) => onTabChange(value)}
        indicatorColor="secondary"
        textColor="primary"
        centered
        fullWidth
      >
        {tabLabels.map((tabLabel, i) => (
          <Tab
            key={`${i}_${tabLabel}`}
            label={tabLabel}
            style={{
              maxWidth: `calc(100% / ${tabLabels.length})`,
            }}
          />))}
      </Tabs>
    </AppBar>
  );
};

export default PageTabComponent;
