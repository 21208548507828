import { connect } from 'react-redux'
import { compose, withProps } from 'recompose'
import * as R from 'ramda'

import { HIDDEN_BANKS } from './constants'

const mapStateToProps = state => ({
  bankList: state.exchange.bankList
})


const banksObjectToList = R.values
const isBankHidden = R.propSatisfies(R.flip(R.contains)(HIDDEN_BANKS), 'bank')
const rejectHiddenBanks = R.reject(isBankHidden)
const addBankOthers = (bankList) => {
  let bankListWithOthers = bankList;
  if (bankList.length > 0) {
    const depositBankInfo = bankList[0];
    const BANK_OTHERS = {
      bank: depositBankInfo.bankCode,
      agency: depositBankInfo.branch,
      account: depositBankInfo.account,
      address: depositBankInfo.address,
      bankName: "FAÇA UM PIX",
      isBankOthers: true,
    };
    bankListWithOthers = R.append(BANK_OTHERS, bankList);
  }
  return bankListWithOthers;
} 


const getBankList = R.pipe(
  banksObjectToList,
  rejectHiddenBanks,
  addBankOthers
)

export default compose(
  connect(mapStateToProps, null),
  withProps(({ bankList }) => {
    const bankListList = getBankList(bankList)
    return {
      bankList: bankListList
    }
  })
)
