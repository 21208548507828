import React from 'react'
import PropTypes from 'prop-types';
import * as R from 'ramda';
import NumberFormat from 'react-number-format';
import InputField from '../Forms/TextField';


const PostalCodeMaskFormatInput = props => {
  const {
    inputRef, onChange, onBlur, value, ...other
  } = props;

  return (
    <NumberFormat
      {...other}
      value={value}
      ref={inputRef}
      onValueChange={values => {
        onChange(R.isNil(values.value) ? '' : values.value);
      }}
      onBlur={_event => {
        onBlur(value);
      }}
      format='#####-###'
      type='text'
    />
  );
};

PostalCodeMaskFormatInput.defaultProps = {
  value: null,
};

PostalCodeMaskFormatInput.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  inputRef: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
};

const PostalCodeField = ({ InputProps, ...props }) => {
  return (
    <InputField
      {...props}
      InputProps={{
        ...InputProps,
        inputComponent: PostalCodeMaskFormatInput,
      }}
    />
  );
};

PostalCodeField.defaultProps = {
  InputProps: {},
};

PostalCodeField.propTypes = {
  InputProps: PropTypes.objectOf(PropTypes.any),
};

export default PostalCodeField;
