import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import NumberFormat from 'react-number-format';
import TextFieldComponent from '../TextFieldComponent';
import { cpfCnpjMask } from '../../../utils/NumberFormat/cpfCnpjMask';

const TaxIDFormatInput = props => {
  const {
    inputRef, onChange, onBlur, value, ...other
  } = props;

  return (
    <NumberFormat
      {...other}
      value={value}
      ref={inputRef}
      onValueChange={values => {
        onChange(R.isNil(values.value) ? '' : values.value);
      }}
      onBlur={_event => {
        onBlur(value);
      }}
      format={cpfCnpjMask}
      type="tel"
    />
  );
};

TaxIDFormatInput.defaultProps = {
  value: null,
};

TaxIDFormatInput.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  inputRef: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
};

const TaxIDField = ({ InputProps, onChange, ...props }) => {
  return (
    <TextFieldComponent
      {...props}
      InputProps={{
        ...InputProps,
        onChange: onChange,
        inputComponent: TaxIDFormatInput,
      }}
    />
  );
};

TaxIDField.defaultProps = {
  InputProps: {},
};

TaxIDField.propTypes = {
  InputProps: PropTypes.objectOf(PropTypes.any),
};

export default TaxIDField;
