import { UPDATE_KEEP_ALIVE_TIMESTAMP } from './actionTypes';

const initialState = {
  timestamp: 0
};

const keepAliveReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_KEEP_ALIVE_TIMESTAMP:
      return {timestamp: action.timestamp};
    default:
      return state;
  }
};

export default keepAliveReducer;
