import {
  FETCH_TRADE_HISTORY,
  UPDATE_TRADE_HISTORY,
  HANDLE_TRADE_HISTORY_FROM_SOCKETS
} from './actionTypes';

const fetchTradeHistory = () => ({
  type: FETCH_TRADE_HISTORY,
});

const updateTradeHistory = tradeHistory => ({
  type: UPDATE_TRADE_HISTORY,
  tradeHistory,
});

const handleTradeHistoryFromSockets = tradeHistory => ({
  type: HANDLE_TRADE_HISTORY_FROM_SOCKETS,
  tradeHistory
});

export default {
  fetchTradeHistory,
  updateTradeHistory,
  handleTradeHistoryFromSockets
};

export {
  fetchTradeHistory,
  updateTradeHistory,
  handleTradeHistoryFromSockets
};
