import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { isNil } from 'ramda';
import { compose } from 'recompose';

import SummaryContent from './SummaryContent';
import BrasilNumberFormat from '../../utils/NumberFormat/BrasilNumberFormat';
import ActivityIndicator from '../ActivityIndicator';

const prefixStyles = theme => ({
  prefix: {
    color: theme.palette.secondary.main,
    marginRight: 3
  },
  component:{
    display: 'flex',
    color: 'white',
    fontWeight: 500
  }
});

const PrefixedValue = ({ prefix, children, classes }) => {
  return (
    <div className={classes.component}>
      <span className={classes.prefix}>
        {prefix}
      </span>
      {children}
    </div>
  );
};

const enhancePrefix = compose(
  withStyles(prefixStyles),
);

const RenderPrefix = enhancePrefix(PrefixedValue);

const PrefixedContent = ({ value, prefix, decimalScale = 2 }) => {
  return (
    <SummaryContent gutterBottom={false}>
      {!isNil(value)
        ? (
          <BrasilNumberFormat
            decimalScale={decimalScale}
            value={Number(value)}
            renderText={val => (
              <RenderPrefix prefix={prefix}>
                {val}
              </RenderPrefix>
            )}
          />
        )
        : <ActivityIndicator color="secondary" size={16} />
      }
    </SummaryContent>
  );
};

export default PrefixedContent;
