import * as React from 'react';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  panelDetails: {
    display: 'block',
    padding: '8px 24px 24px 24px',
  },
});

const ExpandableWrapper = props => {
  const { children, classes, title, expanded, onChange } = props;
  return (
    <ExpansionPanel onChange={onChange}
                    expanded={expanded}
                    style={{backgroundColor : 'rgb(224, 224, 224)', borderRadius: 6}}>
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
        <Typography style={{fontWeight: 550}} >{title}</Typography>
      </ExpansionPanelSummary>

      <div className={classes.panelDetails}>{children}</div>
    </ExpansionPanel>
  );
};

export default withStyles(styles)(ExpandableWrapper);
