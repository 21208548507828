import * as R from 'ramda';
import { combineReducers } from 'redux';
import shouldShowBottomNavigation from './bottomNavigation/reducer';
import appBar from './appBar/reducer';
import notifications from './notifications/reducer';
import alerts from './alerts/reducer';
import dialog from './dialog/reducer';
import snacks from './snacks/reducer';
import newsDialog from './newsDialog/reducer';
import transactionsForm from './transactionsForm/reducer';

const initialState = {
  shouldShowBottomNavigation: true,
  appBar: {
    anchorEl: null,
    open: false,
    hasSound: true,
    currencySelectorFilter: false,
  },
  newsDialog: {
    dismissedNews: [],
  },
  notifications: [],
  alerts: [],
  snacks: { open: false },
  dialog: {
    open: false,
  },
  transactionsForm: {
    offsetTop: null,
    formData: {},
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'REMOVE_CREDENTIALS':
      return {
        ...initialState,
        newsDialog: state.newsDialog,
      };
    case 'DISABLE_APP_SOUND':
      return {
        ...state,
        appBar: {
          ...state.appBar,
          hasSound: false,
        },
      };
    case 'ENABLE_APP_SOUND':
      return {
        ...state,
        appBar: {
          ...state.appBar,
          hasSound: true,
        },
      };
    // case 'SET_NOTIFICATIONS':
    //   return {
    //     ...state,
    //     notifications: action.notifications || [],
    //   };
    default:
      return R.merge(
        state,
        combineReducers({
          shouldShowBottomNavigation,
          newsDialog,
          appBar,
          alerts,
          notifications,
          dialog,
          snacks,
          transactionsForm,
        })(state, action),
      );
  }
};

export default reducer;
