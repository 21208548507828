import * as actions from './actionTypes';

const initialState = {
  trades: [],
};

const updateTradeHistory = (state, tradeHistory) => {
  return { trades: tradeHistory };
};

const orderBookReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.UPDATE_TRADE_HISTORY:
      return updateTradeHistory(state, action.tradeHistory);

    default:
      return state;
  }
};

export default orderBookReducer;
