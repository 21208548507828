import * as R from 'ramda';
import config from '../config';
import {valueToMd5} from '../utils/valueToMd5';
import {valueToSha256} from '../utils/valueToSha256'
import getDefaultReqHeaders from "../utils/getDefaultReqHeaders";
import { contactSupport } from '../utils/contactSupport';
import { addDialog } from '../redux/actions';
import store from '../redux/store';
import { ThrottleRequests } from '../controllers/ThrottleRequests';
import { FIREBASE_FEATURE_FLAGS } from '../config';
import { throttleMsIntervalByCmd } from '../utils/constants';
// Argumentos que devem estar criptografados na requisição para a API.
const criptoArguments = ['pass', 'new_pass'];

const throttleRequestsController = new ThrottleRequests();
const formMiddleware = form => {
  return R.mapObjIndexed(
    (value, key) => {
      if (R.contains(key, criptoArguments)) {
        return valueToMd5(value);
      }
      return value;
    }, form);
};

const service = R.curry(async ({ cmd, isCSV=false, isXLSX=false, downloadInPDF=false, ...rest }, form) => {
  try {
    const isThrottlerDisabled = FIREBASE_FEATURE_FLAGS.disableThrottler;
    if (!isThrottlerDisabled) {
      throttleRequestsController.throttleRequest({
        cmd,
        form,
        customInterval: throttleMsIntervalByCmd[cmd],
      });
    }
    const data = new FormData(); // eslint-disable-line
    const formCripto = formMiddleware(form);
    R.mapObjIndexed((value, key) => {
      if (R.contains(key, 'pass')) {
        data.append('sha256pass', valueToSha256(form.pass))
      }
      data.append(key, value);
    }, formCripto);

    const headers = getDefaultReqHeaders();

    const rawRes = await fetch(`${config.API_URL}/${cmd}`, { // eslint-disable-line
      headers,
      body: data,
      ...rest,
    });

    if (isCSV ) {
      const documentFormat = '.csv'
      const content = await rawRes.json();
      const data = Buffer.from(content.data, 'base64').toString('ascii');
      const fileName = rest.filename + documentFormat;

      const dataEncode = encodeURI(`data:text/csv;charset=utf-8,${data}`);
      const link = document.createElement('a');
      link.setAttribute('href', dataEncode);
      link.setAttribute('download', fileName || `export.${documentFormat}`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      return content;
    } 
    else if(downloadInPDF) {
      const content = await rawRes.json();
      const data = content.data;
      const cleanedBase64 = data.replace(/^data:application\/pdf;base64,/, '');
      const binaryString = Buffer.from(cleanedBase64, 'base64');
      const byteArray = new Uint8Array(binaryString);
      const blob = new Blob([byteArray], { type: 'application/pdf' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", rest.filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      return content;
    }
    else if (isXLSX) {
      const content = await rawRes.json();
      if(content.success){
        const data = Buffer.from(content.data, 'base64');
        const fileDocument = require('js-file-download');
        fileDocument(data, rest.filename);
      }
      return content;
    } else {
   
      const content = await rawRes.json()
      const success = R.propOr(true, 'success', content);
      if (success) {
        if(content.message_cod === 'VALIDATION_EMAIL_SENT'){
          sessionStorage.setItem('taxIdAlreadyUsed', 'notUsed')
        }
        return content;
      }
      throw content;
    }

  } catch (e) {
    if(e.message_cod === 'TAXID_ALREADY_USED') {
      sessionStorage.setItem('taxIdAlreadyUsed', 'used')
    }else if (e.message_cod === "MUST_UPDATE_APP_VERSION"){
      store.dispatch(
        addDialog({
          title: "MUST_UPDATE_APP_VERSION",
          centerTitle: true,
          disableBackdropClick: true,
          availableChoices: [
            {
              label: 'CONTACT_SUPPORT',
              actionToTake: 'openIntercomChat',
              color: 'primary',
              variant: 'raised'
            },
            {
              label: 'common.reloadPage',
              actionToTake: 'reloadPage',
              color: 'secondary',
              variant: 'raised',
            }
          ],
          actionsToTake: {
            openIntercomChat: () => contactSupport(),
            reloadPage: () => window.location.reload(true),
          },
        })
      )
    }
    console.log('serviceRoot', e);
    throw e;
  }
});

const serviceGet = cmd => async form => {
  try {
    const params = R.join('&', R.values(R.mapObjIndexed((value, key) => `${key}=${value}`, form)));
    const rawRes = await fetch(`${config.API_URL}/${cmd}?${params}`); // eslint-disable-line

    const content = await rawRes.json();
    return content;
  } catch (e) {
    return console.error('serviceRootGet', e);
  }
};

export { serviceGet };

export default service;
