import React from 'react';
import PropTypes from 'prop-types';
import TextFieldComponent from '../../FormsV2/TextFieldComponent';
import  DateMaskFormatInput  from './DateMaskInput';
import { compose, withProps } from 'recompose';

const DatePickerField = (props) => {
  const {onChange, value, name} = props;
  return (
    <TextFieldComponent
      value={value}
      name={name}
      {...props}
      InputProps={{
        inputComponent: DateMaskFormatInput,
        onChange: onChange,
      }}
    />
  );
};

DatePickerField.defaultProps = {
  InputProps: {},
};

DatePickerField.propTypes = {
  InputProps: PropTypes.objectOf(PropTypes.any),
};

const enhance = compose(
  withProps(props => ({
    ...props,
    disableFuture: true,
  })),
);

export default enhance(DatePickerField);
