import React from 'react'
import * as R from 'ramda'
import { compose, withProps } from 'recompose'
import { translate } from 'react-i18next'
import TextFieldComponent from './TextFieldComponent'
import { connect } from 'formik'


const getProps = props => {
  const {
    t,
    name,
    value,
    formik: { errors },
    disabled,
    i18nScope,
    i18nContext
  } = props
  const clearedName = name && name.includes('-') ? name.split('-')[0] : name;
  const error = R.path(R.split('.', clearedName))(errors)
  const shouldDisplayError = !!error && !disabled
  const labelText = t(i18nScope ? `${i18nScope}.label` : `forms.${name}.label`, i18nContext)
  const placeholder = t(i18nScope ? `${i18nScope}.placeholder` : `forms.${name}.placeholder`, i18nContext)
  const helperText = shouldDisplayError
    ? R.is(String, error)
      ? t(error)
      : t(error.error, error)
    : ' ' // ensure same row's height

  // value MUST be String/Number
  // undefined will break MUI's layout
  const value_ = value === undefined
    ? ''
    : value

  return {
    labelText,
    placeholder,
    shouldDisplayError,
    helperText,
    value: value_
  }
}

export default compose(
  translate(),
  connect,
  withProps(getProps)
)(TextFieldComponent)
