import React from 'react';
import { compose, pure } from 'recompose';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import Card from '@material-ui/core/Card';

const styles = _theme => ({
    card: {
      display: 'flex',
      alighItens: 'center',
      justifyContent:'center',
      width: '100%',
      borderTop: 15,
      overflow: 'visible',
      minHeight: '50px',
      borderRadius: 0
    },
    button: {
      margin: _theme.spacing.unit,
      marginLeft: 'auto',
      marginRight: 'auto',
      fontSize: '5'
    },
    buttonWrapper: {

    }
  });

  const AppFooter = (props)=>{
        const {
            siteButtonTitle,
            feesButtonTitle,
            blogButtonTitle,
            faqButtonTitle,
            centralHelpTitle,
            companyDetail,
            version,
            centralize,
            classes,
            scene
        } = props;
        return (
            <div >
                { scene !== 'recoverPassword' && <Card className={classes.card}>
                <div style={(!centralize ? {paddingLeft: '20%'} : {paddingLeft : 0})}>
                    <Button
                    color="primary"
                    href="https://bitpreco.com/"
                    rel="noopener noreferrer"
                    target="_blank"
                    className={classes.button}>
                    {siteButtonTitle}
                    </Button>
                </div>
                <div className={classes.buttonWrapper}>
                    <Button
                    href="https://suporte.bity.com.br/pt-BR/articles/6967815-taxas"
                    rel="noopener noreferrer"
                    target="_blank"
                    color="primary"
                    className={classes.button}>{feesButtonTitle}</Button>
                </div>
                <div className={classes.buttonWrapper}>
                    <Button
                    href="https://blog.bitpreco.com/"
                    color="primary" rel="noopener noreferrer"
                    target="_blank"
                    className={classes.button}>{blogButtonTitle}</Button>
                </div>
                <div className={classes.buttonWrapper}>
                    <Button
                        href="https://bitpreco.com/#faq"
                        rel="noopener noreferrer"
                        target="_blank"
                        color="primary"
                        className={classes.button}>{faqButtonTitle}</Button>
                </div>
                <div className={classes.buttonWrapper}>
                    <Button
                        href="https://suporte.bity.com.br/pt-BR/"
                        rel="noopener noreferrer"
                        target="_blank"
                        color="primary"
                        className={classes.button}>{centralHelpTitle}</Button>
                </div>
                <div className={classes.buttonWrapper}>
                    <Button
                    color="primary"
                    disabled
                    className={classes.button}>{companyDetail}</Button>
                    <Button
                    color="primary"
                    disabled
                    className={classes.button}>{version}</Button>
                </div>
            </Card>  }
            </div>

        );
  }

  const enhance = compose(
    pure,
    withStyles(styles, { withTheme: true })
  );


  export default enhance(AppFooter);

