import React from 'react'
import {
  compose,
  pure,
  branch,
  renderNothing
} from 'recompose'
import { connect } from 'react-redux'
import * as R from 'ramda'
import Intercom from 'react-intercom'
import { INTERCOM_APP_ID } from '../config'

const mapStateToProps = ({
	user: { profile },
    credentials: { demoAccount, intercom_hash }
}) => ({
	profile,
    demoAccount,
    intercom_hash
})

const getCustomAttrs = R.reject(R.isEmpty)

const IntercomChat = props => {
  const {
    profile: {
      email,
      name,
      tax_id,
      otp_active,
      kyc_level
    }, 
    intercom_hash,
    intercomHashFromParams,
  } = props

  const user = name && email
    ? {
      cpf: tax_id,
      email,
      name
    }
    : {}

  const customAttrs = getCustomAttrs({
    kycLevel: kyc_level,
    otpActive: otp_active
  })
  
  return (
    <Intercom
      appID={INTERCOM_APP_ID}
      user_hash={intercom_hash?intercom_hash:intercomHashFromParams}
      { ...user }
      { ...customAttrs }
    />
  )
}

const enhance = compose(
  pure,
	connect(mapStateToProps, null),
  branch(({ demoAccount }) => {
    return demoAccount 
  }, renderNothing)
)

export default enhance(IntercomChat)
