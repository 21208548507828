import React from 'react';
import Paper from '@material-ui/core/Paper';

const SwitchButtonWrapper = props => {
  const {
    children,
    ...rest
  } = props;
  return (

    <Paper
      elevation={0}
      style={{ height: 26, display: 'flex', justifyContent: 'space-between',
        border: '1px solid rgba(0, 0, 0, 0.23)', background: 'rgba(0, 0, 0, 0.0)'}}
      {...rest}
    >
      {children}
    </Paper>
  );
};


export default SwitchButtonWrapper;
