const ADD_DIALOG = 'ADD_DIALOG';
const ADD_RESPONSE_DIALOG = 'ADD_RESPONSE_DIALOG';
const MAKE_DIALOG_CHOICE = 'MAKE_DIALOG_CHOICE';
const CLOSE_DIALOG = 'CLOSE_DIALOG';

export {
  ADD_DIALOG,
  CLOSE_DIALOG,
  MAKE_DIALOG_CHOICE,
  ADD_RESPONSE_DIALOG
};
