import React, { useState } from 'react'

import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Button from '@material-ui/core/Button'
import KeyboardArrowUp from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";

const SelectButton = props => {
  const {
    value,
    placeholder,
    options,
    style,
    onChange,
    getLabel,
    color,
    customHandleClick,
    className
  } = props
  const [anchor, setAnchor] = useState(null)

  const handleClick = customHandleClick
    ? (e) => customHandleClick(e)
    : (e) => setAnchor(e.currentTarget);
  const handleClose = () => setAnchor(null)

  return (
    <React.Fragment>
      <Button
        style={style}
        className={className}
        variant="raised"
        color={color ? color : "secondary"}
        aria-owns={anchor ? "bankList-menu" : null}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          {value === ""
            ? getLabel(value) || placeholder
            : (value && getLabel(value)) || placeholder}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginRight: "-6px",
            }}
          >
            {!anchor ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
          </div>
        </div>
      </Button>
      {options && (
        <Menu
          id="bankList-menu"
          anchorEl={anchor}
          open={Boolean(anchor)}
          onClose={handleClose}
        >
          {options.map((option, i) => (
            <MenuItem
              key={i}
              onClick={() => {
                handleClose();
                onChange(option);
              }}
            >
              {getLabel(option)}
            </MenuItem>
          ))}
        </Menu>
      )}
    </React.Fragment>
  );
}

export default SelectButton
