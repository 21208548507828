import React from 'react'

import Typography from '@material-ui/core/Typography';
import InputAdornment from '@material-ui/core/InputAdornment';
import InfoTooltip from '../../../components/InfoTooltip';
import SwitchButtonWrapper from '../../../components/Buttons/SwitchButtonWrapper';
import SwitchButton from '../../../components/Buttons/SwitchButton';
import { ORDER_TYPES } from './constants';

const OrderPriceTypeButton = ({
  children,
  forOrderType,
  isMarketPriceSelected,
  setIsMarketPriceSelected,
  disabled
}) => {
  const isActive =
    (isMarketPriceSelected && forOrderType === 'market') ||
    (!isMarketPriceSelected && forOrderType === 'limited')
  return (
    <SwitchButton
      onClick={() => {
        if (isActive) return;
        setIsMarketPriceSelected(!isMarketPriceSelected)
      }}
      isActive={isActive}
      disabled={disabled}
    >
      {children}
    </SwitchButton>
  )
}

const MarketButton = ({...rest }) => (
  <OrderPriceTypeButton forOrderType='market' {...rest} />
)


const LimitedButton = ({ children, ...rest }) => (
  <OrderPriceTypeButton forOrderType='limited'  {...rest}>
    {children}
  </OrderPriceTypeButton>
)

const OrderPriceTypeSwitch = props => {
  const {
    t,
    orderType,
    ...switchHandlers
  } = props
  return (
    <InputAdornment
      position="end"
      style={{ alignItems: 'flex-end' }}
    >
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        
        { orderType !== ORDER_TYPES.STOPLOSS && <InfoTooltip title={t('info.orderType')}>
          <Typography variant="caption" noWrap>
            {t('transactions.orderType')}
          </Typography>
        </InfoTooltip> }

        { orderType === ORDER_TYPES.STOPLOSS && <InfoTooltip title={t('info.orderTypeStopLoss')}>
          <Typography variant="caption" noWrap>
            {t('transactions.orderType')}
          </Typography>
        </InfoTooltip> }

        <SwitchButtonWrapper>
          { orderType !== ORDER_TYPES.STOPLOSS && <MarketButton {...switchHandlers} {...props}>
            {t('market.marketPrice')}
            </MarketButton>}
          <LimitedButton {...switchHandlers} {...props}>
            {t('market.limited')}
          </LimitedButton>
        </SwitchButtonWrapper>
      </div>
    </InputAdornment>
  )
}

export default OrderPriceTypeSwitch
