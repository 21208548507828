export const styles = (theme) =>{
    return ({
    cardContainer: {
        padding: 20,
        backgroundColor: theme.palette.background.light,
        borderRadius: 8,

        [theme.breakpoints.down("xs")]: {
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
        },
    },
    fontWeightBold: {
        fontWeight: "bold",
    },
    selectButtonWrapper: {
        [theme.breakpoints.down("xs")]: {
            justifyContent: "center",
            marginBottom: 15,
        },
    },
    selectButton: {
        backgroundColor: (props) => props.currency.color,
    },

})};