import React from "react";
import PropTypes from "prop-types";
import * as R from "ramda";
import { translate } from "react-i18next";
import { compose, withState, withHandlers } from "recompose";
import moment from "moment";

import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import StepConnector from "@material-ui/core/StepConnector";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";

import ExtractListItemContent from "./components/ExtractListItemContent";
import MiniButton from "../../components/Buttons/MiniButton";
import { connect } from "react-redux";
import { getCurrencyBySymbol } from "../../models/Currency";
import { Currency } from "../../models/Currency";
import { MarketInfo } from "../../models/MarketInfo";

const brlCurrencies = ["BRL", "CBRL", "REALT", "BRZ", "RAS"];
const operationTypesToUseMarketPrecision= ["CARD-CASHBACK", "BUY-FEE", "SELL-FEE", "DEPOSIT-FEE", "WITHDRAWAL-FEE"]

const styles = (theme) => ({
  root: {
    width: "100%",
  },
  mainStepperRoot: {
    padding: 0,
    paddingTop: 8,
  },
  mainStepConnector: {
    paddingBottom: 0,
    paddingLeft: 12,
  },
  mainStepContentRoot: {
    margin: 0,
    border: "none",
    paddingBottom: 0,
    paddingLeft: 12,
    paddingRight: 0,
    paddingTop: 0,
  },
  stepperRoot: {
    padding: 6,
    paddingTop: 0,
    paddingBottom: 0,
  },
  stepContentRoot: {
    margin: 0,
    marginLeft: 6,
    paddingBottom: 8,
    paddingLeft: 12,
    paddingRight: 0,
    paddingTop: 8,
  },
  stepContentLast: {
    borderLeft: `1px solid ${
      theme.palette.type === "light"
        ? theme.palette.grey[400]
        : theme.palette.grey[600]
    }`,
  },
});

const ExtractList = (props) => {
  const { classes, t, extracts, inactives, setInactive, currencies, markets } = props;

  const parseDateGroup = (date) =>
    date.isValid() ? date.format("DD/MM/YYYY") : t("common.pending");

  const extractsByDate = R.groupBy((extract) => {
    const date = moment(extract.concluded);
    return parseDateGroup(date);
  }, extracts);

  const stepContentClasses = {
    root: classes.stepContentRoot,
    last: classes.stepContentLast,
  };

  const mainStepContentClasses = {
    root: classes.mainStepContentRoot,
  };

  const extractsComponentsByDate = R.values(
    R.mapObjIndexed((extractByDate, date) => {
      const isInactive = inactives[date];
      
      const sortExtracts = R.sortWith([
        R.descend(R.pipe(R.prop('created'), dateStr => new Date(dateStr).getTime()))
      ]);

      const sorted = sortExtracts(extractByDate);
      return (
        <Step key={date} active={!isInactive}>
          <StepLabel
            icon={
              <StepConnector
                orientation="vertical"
                classes={{ vertical: classes.mainStepConnector }}
              />
            }
          >
            <Typography align="center">
              <MiniButton
                variant="outlined"
                size="small"
                onClick={() => setInactive(date, isInactive)}
              >
                {date}
              </MiniButton>
            </Typography>
          </StepLabel>
          <StepContent classes={mainStepContentClasses}>
            <Stepper
              orientation="vertical"
              classes={{ root: classes.stepperRoot }}
              connector={null}
            >
              {R.map((extract) => {
                const currency = new Currency(
                  getCurrencyBySymbol(currencies, extract.currency) ||
                    getCurrencyBySymbol(currencies, "BTC")
                );
                if (markets[`${currency.symbol}-BRL`] && operationTypesToUseMarketPrecision.includes(extract.type)) {
                  const market = new MarketInfo(markets[`${currency.symbol}-BRL`]);
                  const currencyFormatter = currency._defaultFormatter();
                  currencyFormatter.decimalScale = market.basePrecision;
                  currency.setFormatter = currencyFormatter;
                }

                const isFiat =
                  currency.isFiat || brlCurrencies.includes(extract.currency);
                const currencySymbol = isFiat ? "BRL" : extract.currency;
                let label = t(
                  `screens.extract.extractTypes.${extract.type.toLowerCase()}.${
                    isFiat ? "fiat" : "cripto"
                  }`,
                  {
                    currency: currencySymbol,
                  }
                );

                if (extract.extra_data && extract.type === "CREDIT") {
                  const extraData = JSON.parse(extract.extra_data);
                  if (extraData["credit_type"]) {
                    label =
                      label +
                      " | " +
                      t(`${extraData.credit_type.toUpperCase()}`);
                  }
                }
                return (
                  <Step key={extract.id} active>
                    <StepLabel
                      icon={
                        <MonetizationOnIcon
                          color="secondary"
                          style={{ fontSize: 12 }}
                        />
                      }
                    >
                      {label}
                    </StepLabel>
                    <StepContent classes={stepContentClasses}>
                      <ExtractListItemContent
                        extract={extract}
                        currency={currency}
                      />
                    </StepContent>
                  </Step>
                );
              }, sorted)}
            </Stepper>
          </StepContent>
        </Step>
      );
    }, extractsByDate)
  );

  return (
    <div className={classes.root}>
      <Stepper
        nonLinear
        orientation="vertical"
        classes={{ root: classes.mainStepperRoot }}
        connector={null}
      >
        {extractsComponentsByDate}
      </Stepper>
    </div>
  );
};

ExtractList.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  currencies: state.currencies.currencies,
  markets: state.currencies.markets,
});

const enhance = compose(
  translate(),
  withStyles(styles),
  withState("inactives", "setInactives", {}),
  connect(mapStateToProps, null),
  withHandlers({
    setInactive:
      ({ setInactives, inactives }) =>
      (date, isInactive) => {
        const newInactives = R.merge(inactives, { [date]: !isInactive });
        setInactives(newInactives);
      },
  })
);

export default enhance(ExtractList);
