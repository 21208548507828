import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.secondary.main,
    minHeight: theme.spacing.unit * 4,
    color: theme.palette.common.white,
  },
  flex: {
    flexGrow: 1,
  },
});

const ProfileToolbar = props => {
  const { classes, title, action } = props;
  return (
    <Toolbar
      classes={{
        root: classes.root,
      }}
    >
      <Typography className={classes.flex} variant="subheading" color="inherit" >
        {title}
      </Typography>
      {action}
    </Toolbar>
  );
};

ProfileToolbar.propTypes = {
  title: PropTypes.string.isRequired,
};

export default withStyles(styles)(ProfileToolbar);
