import regEx from 'simpl-schema/dist/regExp';
import { validateCNPJ, validateCPF } from './validateTaxId';


export const LoginPasswordSchema = {
  type: String,
  min: 6,
};

export const TokenSchema = {
  type: String,
  min: 6,
  max: 6
};

export const RegisterPasswordSchema = {
  type: String,
  regEx: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-.;:_=<>~`{}|()[\]\/'"\\]).{8,512}$/,
  min: 8,
  max: 512,
};

export const EmailSchema = {
  type: String,
  regEx: regEx.EmailWithTLD,
};


function validateTaxId(){

  if(this.value.length <= 11 && !validateCPF(this.value)){
    return 'CPF_ERROR';
  }
  else if(this.value.length === 14 && !validateCNPJ(this.value)){
    return 'CNPJ_ERROR';
  }
  return undefined;
}

export const TaxIdSchema = {
  type: String,
  custom: validateTaxId
};
