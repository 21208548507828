import { append, remove } from 'ramda';
import * as actions from './actionTypes';
import moment from 'moment'

const initialState = {
  pendingOrders: {},
  retriedOrders: [],
  selectedOrder: {
    orderType: null,
    order: null
  }
}

const transactionReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.SET_ACTIVE_TAB:
      return {...state , activeTab : action.activeTab};
    case actions.SELECT_ORDER:
      return {
        ...state,
        selectedOrder: {
          orderType: action.orderType,
          order: action.order,
          // in case user select for a second time,
          // TradeForm needs to be rerendered
          timestamp: moment().format('x')
        }
      }
    case actions.CLEAR_SELECTED_ORDER:
      return {
        ...state,
        selectedOrder: initialState.selectedOrder
      }
    case actions.ADD_RETRY_CANCEL_ORDER:
      return {
        ...state,
        retriedOrders: append(action.orderId, state.retriedOrders)
      }
    case actions.REMOVE_RETRY_CANCEL_ORDER:
      return {
        ...state,
        retriedOrders: remove(action.orderId, state.retriedOrders)
      }
    default:
      return state;
  }
};

export default transactionReducer;
