export const styles = (theme) => ({
  paper: {
    borderRadius: "15px",
    padding: "30px",
    maxWidth: "600px",
    width: "100%",
    "@media  screen and (max-width: 500px)": {
      margin: "auto 15px",
      padding: "20px",
    },
  },
  dialogTitleContainer: {
    paddingTop: "0px",
    marginBottom: "5px",
    padding: "24px 24px 20px",
    "@media  screen and (max-width: 500px)": {
      padding: "0px !important",
    },
  },
  dialogTitle: {
    color: "#101010 important!",
    fontSize: "25px",
    fontWeight: "bold",
  },
  closeButton: {
    border: "none",
    backgroundColor: "transparent",
    display: "flex",
    flexDirection: "row",
    cursor: "pointer",
    justifyContent: "end",
    width: "100%",
    margin: "0",
    padding: "0",
  },
  dialogContent: {
    padding: "0",
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    fontFamily: "'Helvetica','Arial', 'sans-serif'",
    width: "100%",

    "@media  screen and (max-width: 500px)": {
      padding: "0px  !important",
    },
  },
  informationRowsContainer: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    width: "100%",
    gap: "10px",
    marginBottom: "20px",
  },
  informationRow: {
    width: "90%",
  },
  icon: {
    minWidth: 50,
    maxWidth: 50,
    height: 50,
    borderRadius: 50,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: 4,
    WebkitPaddingStart: 4,
    WebkitPaddingEnd: 4,
  },
  span: {
    color: "#101010",
    fontSize: "12px",
    fontWeight: "300 !important",
    marginTop: "4px",
  },
  timer: {
    color: "#858585",
    fontSize: "12px",
    fontWeight: "300 !important",
  },
  dialogActions: {
    flexDirection: "column-reverse",
    marginTop: "10px",
    gap: "8px",
  },
  button: {
    width: "90%",
    fontSize: "18px",
    textTransform: "capitalize",
    borderRadius: "10px",
    padding: "12px !important",
    "@media only screen and (max-width: 500px)": {
      fontSize: "14px",
      margin: 0,
    },
  },
  buttonClose: {
    backgroundColor: "#ffffff",
    border: "1px solid rgba(16, 16, 16, 0.3);",
    "&:hover": {
      backgroundColor: "#ffffff",
    },
  },
  buttonConfirm: {
    color: "#fff",
    fontWeight: "400;",
    backgroundColor: "#101010",
    border: "1px solid black",
    "&:hover": {
      backgroundColor: "#000000",
    },
  },
  subtitle: {
    fontSize: "14px",
  },
});
