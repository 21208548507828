import React from 'react'
import TextFieldComponent from '../TextFieldComponent';


const onKeyPressed = (e) => {
  if(e.charCode === 42) {
    e.preventDefault();
  }
}

const disableEvent = (e) => {
    e.preventDefault();
}

const EmailField = ({
  className,
  onChange,
  value,
  name,
  ...props
}) => {

  return (
    <TextFieldComponent InputProps={{inputProps: {onChange}}}
                        autoComplete="off"
                        name={name}
                        value={value}
                        onKeyPress={onKeyPressed}
                        onPasteCapture={disableEvent}
                        onCopy={disableEvent}
                        {...props}
    />
  )
}

export default EmailField
