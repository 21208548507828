import React from "react";

export const MgmIcon = (props) => {
  const { color, width = 20, height = 20 } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.7417 9.25016C9.25837 8.7585 8.54171 8.45016 8.54171 7.66683C8.54171 6.81683 9.46671 6.5085 10.05 6.5085C11.1417 6.5085 11.5417 7.3335 11.6334 7.62516L12.95 7.06683C12.825 6.69183 12.2667 5.46683 10.8334 5.20016V4.16683H9.16671V5.21683C7.10004 5.6835 7.09171 7.60016 7.09171 7.6835C7.09171 9.57516 8.96671 10.1085 9.88337 10.4418C11.2 10.9085 11.7834 11.3335 11.7834 12.1335C11.7834 13.0752 10.9084 13.4752 10.1334 13.4752C8.61671 13.4752 8.18337 11.9168 8.13337 11.7335L6.75004 12.2918C7.27504 14.1168 8.65004 14.6085 9.16671 14.7585V15.8335H10.8334V14.8002C11.1667 14.7252 13.25 14.3085 13.25 12.1168C13.25 10.9585 12.7417 9.94183 10.7417 9.25016ZM2.50004 17.5002H0.833374V12.5002H5.83337V14.1668H3.76671C5.10837 16.1752 7.40004 17.5002 10 17.5002C14.1417 17.5002 17.5 14.1418 17.5 10.0002H19.1667C19.1667 15.0668 15.0667 19.1668 10 19.1668C6.90004 19.1668 4.15837 17.6252 2.50004 15.2752V17.5002ZM0.833374 10.0002C0.833374 4.9335 4.93337 0.833496 10 0.833496C13.1 0.833496 15.8417 2.37516 17.5 4.72516V2.50016H19.1667V7.50016H14.1667V5.8335H16.2334C14.8917 3.82516 12.6 2.50016 10 2.50016C5.85837 2.50016 2.50004 5.8585 2.50004 10.0002H0.833374Z"
        fill={color}
      />
    </svg>
  );
};
