import React from 'react'
import { compose } from 'recompose'
import { translate } from 'react-i18next'
import { connect } from 'react-redux'

import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'

import OrderBookHeaderTitle from './OrderBookHeaderTitle'
import OrderBookHeaderActions from './OrderBookHeaderActions'

import { Currency, getCurrencyBySymbol } from "../../../../models/Currency";

import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  card: {
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      height: `calc(100vh - ${theme.spacing.unit * 19}px)`,
    },
  },
  cardHeader: {
    backgroundColor: theme.palette.background.default,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      position: 'fixed',
      top: theme.spacing.unit * 4.5,
      zIndex: 2,
      '@media only screen and (min-height: 800px)' : {
        minHeight: '70px'
      },
    },
    "@media only screen and (max-width: 325px)" : {
      top: theme.spacing.unit * 3.5,
    }
  },
  cardLabel: {
    width:"100%",
    display: 'flex',
    flexWrap:'noWrap',
    fontSize: '1.3vw',
    [theme.breakpoints.down('sm')]: {
     fontSize: '1.1rem'
    }
  },
  sellLabel: {
    color: theme.sell,
  },
  buyLabel: {
    color: theme.buy,
  },
  cardAction: {
    alignSelf: 'center',
    marginRight: 'auto'
  },
  cardContent: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: '60vh',
      padding: theme.spacing.unit,
      marginTop: theme.spacing.unit * 10,
      overflowX: 'auto',
    },
  },
  actionsContent:{
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down(1370)]: {
      flexDirection: 'column-reverse',
      marginTop: 6,
    }
  },
  switchContent:{
    marginRight: '-20px',
    [theme.breakpoints.down(1370)]: {
      marginRight: '-16px',
      marginLeft: 'auto'
    }
}
})

const OrderBookFrame = ({ children, ...props }) => {
  const {
    t,
    classes,
    ordersType,
    isShowAllSelected,
    market,
    currencies
  } = props

  const currency =  new Currency(getCurrencyBySymbol(currencies, market.split('-')[0]));
  const currencyName = currency.name;

  return (
    <Card className={classes.card}>
      <CardHeader
        title={OrderBookHeaderTitle(
          t,
          classes,
          ordersType,
          isShowAllSelected,
          currencyName
        )}
        action={
          <div className={classes.actionsContent}>
            {OrderBookHeaderActions(props)}
          </div>
        }
        classes={{
          root: classes.cardHeader,
          action: classes.cardAction,
        }}
      />
      <CardContent className={classes.cardContent}>{children}</CardContent>
    </Card>
  );
}

const mapStateToProps = state => ({
  market: state.market.selectedMarket,
  currencies: state.currencies.currencies
})

const enhancer = compose(
  translate(),
  withStyles(styles),
  connect(mapStateToProps)
)

export default enhancer(OrderBookFrame)
