export const styles = (theme) => ({
  input: {
    textAlign: "right",
    fontWeight: "bold",
    fontSize: "20px",

    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
    },
  },
  helperTextContainer: {
    display: "flex",
    justifyContent: "flex-end",

    [theme.breakpoints.down("xs")]: {
      justifyContent: "center",
    },
  },
});
