import React from 'react';
import { compose } from 'recompose';

import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';

const styles = _theme => ({
  field: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
});


const HorizontalFieldRow = ({ children, classes, ...rest }) => {
  return (
    <FormControl
      className={classes.field}
      {...rest}
    >
      {children}
    </FormControl>
  );
};

const enhance = compose(
  withStyles(styles),
);

export default enhance(HorizontalFieldRow);
