import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import FieldRow from '../../../components/Forms/FieldRow';
import TaxIDField from '../../../components/FormsV2/TaxIDField';
import CPFField from '../../../components/FormsV2/CpfField';
import DatePickerField from '../../../components/FormsV2/DatePickerField';
import PostalCodeField from '../../../components/FormsV2/PostalCodeField';
import TextFieldComponent from '../../../components/FormsV2/TextFieldComponent';
import MenuItem from '@material-ui/core/MenuItem';
import getStatesSymbols from '../../../utils/getStatesSymbols';
import getGenderSymbols from '../../../utils/getGenderSymbols';


const styles = theme => ({
  flexContainer: {
    display: 'flex'
  },
  fullItem: {
    width: '100%'
  },
  mediumFirstItem: {
    marginRight: 5,
    width: '55%'
  },
  mediumSecondItem:{
    width: '45%'
  },
  majorItem:{
    marginRight: 5,
    width: '80%'
  },
  smallItem:{
    width: '20%'
  }

});

const AccountPersonalDataForm = props =>{
  const {
    values,
    handleTaxIDChange,
    handleBirthDayChange,
    handlePostalcodeChange,
    handleChange,
    handleAddressChange,
    onSearchPostalCode,
    showBirth,
    classes,
    handleCompanyDirectorChange
  } = props;
  return (<React.Fragment>
    <FieldRow>
      <div className={classes.flexContainer}>
        <TaxIDField onChange={handleTaxIDChange}
                    name="taxId"
                    value={values.taxId}
                    className={showBirth ? classes.mediumFirstItem : classes.fullItem}/>
        {showBirth && <DatePickerField onChange={handleBirthDayChange}
                          name="birth"
                          value={values.birth}
                          className={classes.mediumSecondItem}/>}

      </div>
    </FieldRow>
    {!showBirth && <FieldRow>
      <div className={classes.flexContainer}>
        <CPFField onChange={handleCompanyDirectorChange}
                    name="companyDirector"
                    value={values.companyDirector}
                    className={classes.fullItem}/>
      </div>
    </FieldRow>}
    <FieldRow>
      <div className={classes.flexContainer}>
        <PostalCodeField onChange={handlePostalcodeChange}
                         name="postalcode"
                         value={values.postalcode}
                         onSearchPostalCode={onSearchPostalCode}
                         className={classes.mediumFirstItem}/>

       <TextFieldComponent name="city"
                           value={values.city}
                           className={classes.mediumSecondItem}
                           onChange={handleAddressChange}
        />
      </div>
    </FieldRow>
    <FieldRow>
      <div className={classes.flexContainer}>
        <TextFieldComponent
          name="street"
          value={values.street}
          onChange={handleAddressChange}
          className={classes.majorItem}/>
        <TextFieldComponent
          onChange={handleAddressChange}
          name="addressNumber"
          value={values.addressNumber}
          className={classes.smallItem}
        />
      </div>
    </FieldRow>
    <FieldRow>
      <div className={classes.flexContainer}>
        <TextFieldComponent
          onChange={handleAddressChange}
          name="addressComplement"
          value={values.addressComplement}
          className={classes.majorItem}/>
        <TextFieldComponent
          name="state"
          value={values.state}
          onChange={handleChange}
          className={classes.smallItem}
          select
        >
          {getStatesSymbols().map(option =>
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          )}
        </TextFieldComponent>
      </div>
    </FieldRow>
    {showBirth &&
    <FieldRow>
      <div className={classes.flexContainer}>
        <TextFieldComponent
          name="gender"
          value={values.gender}
          onChange={handleChange}
          className={classes.fullItem}
          select
        >
          {getGenderSymbols().map(option =>
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          )}
        </TextFieldComponent>
      </div>
    </FieldRow>}

  </React.Fragment>)
}

export default withStyles(styles)(AccountPersonalDataForm);
