import React from 'react';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core/styles';
import { translate } from 'react-i18next';


import PrefixedContent from '../../components/Summary/PrefixedContent';
import SummaryLabel from '../../components/Summary/SummaryLabel';
import InfoTooltip from '../../components/InfoTooltip';
import { MarketInfo } from '../../models/MarketInfo';
import ActivityIndicator from '../../components/ActivityIndicator';

const styles = _theme => ({
  flex: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
  },
});

const BtcTicker = props => {
  const {
    volume,
    t,
    classes,
    marketConfig
  } = props;

  const market = marketConfig ? new MarketInfo(marketConfig) : null;
  const decimalScale = market ? market.basePrecision : 0;

  if (!market) {
    return <ActivityIndicator color="secondary" size={16} />;
  }

  return (
    
    <div>
      <div className={classes.flex}>
        <SummaryLabel sumaryTitle={t("balance.volume")} />
        <InfoTooltip
          title={t("info.volume")}
          gutterBottom
          iconProps={{ style: { fontSize: 14 } }}
        />
      </div>
      <div className={classes.flex}>
        <PrefixedContent
          prefix={`${market.base} `}
          decimalScale={decimalScale}
          value={Number(volume)}
        />
      </div>
    </div>
  );
};

const enhance = compose(
  translate(),
  withStyles(styles),

);

export default enhance(BtcTicker);
