import React, { Fragment } from 'react';
import { compose } from 'recompose';
import UploadDocumentPreview from './UploadDocumentPreview';
import InfoTooltip from '../InfoTooltip'
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { addDialog } from '../../redux/actions';


const handleFiles = (onChange, addInfoDialog, t) => event => {
  event.preventDefault();
  // eslint-disable-next-line no-undef
  const reader = new FileReader();
  const file = event.target.files[0];
  const isJPEG = file.name.substring(file.name.length-5, file.name.length)
  const isOther = file.name.substring(file.name.length-4, file.name.length)

  if(file.size <=5000000){
    if(isJPEG === '.jpeg' || isOther === '.png' || isOther === '.pdf' || isOther === '.jpg') {
      reader.onloadend = () => {
        const docWithNewLogo = {
          fileName: file.name,
          mimetype: file.type,
          size: file.size,
          url: reader.result,
        };
        onChange(docWithNewLogo);
      };
    } else {
      reader.onloadend = () => {
        const docWithNewLogo = {
          fileName: file.name,
          mimetype: 'image/png',
          size: file.size,
          url: reader.result,
        };
        onChange(docWithNewLogo);
      };
    }
    if (event.target.files.length > 0) {
      reader.readAsDataURL(file);
    }
  }else{
    addInfoDialog(t('info.exceedLimitDocument'));
    const btnFile = document.getElementById(event.target.id);
    btnFile.value = '';
  }
  
};

const DocInput = (props) => {
  const {
    input: { onChange, value, name },
    meta: { form },
    clearFields,
    status,
    t,
    addInfoDialog
  } = props;
  return (
    <Fragment>
      <input
        accept={form === 'CONSTITUTION' ? '.pdf' : 'image/png, image/jpeg, .pdf'}
        id={`input-${form}-${name}`}
        type="file"
        style={{ display: 'none' }}
        onInput={handleFiles(onChange, addInfoDialog, t)}
        name={name}
      />
      <UploadDocumentPreview
        value={value}
        name={name}
        form={form}
        status={status}
        clearField={
          () => {
            const btnFile = document.getElementById(`input-${form}-${name}`);
            btnFile.value = '';
            clearFields(form, false, false, name);
          }
        }
      />
      <InfoTooltip wrapperRootHeader title={t('info.upload')}/>
    </Fragment>
  );
};

const mapDispatchToProps = dispatch => ({
  addInfoDialog: (message_cod ) => dispatch(addDialog({
    title: message_cod,
    availableChoices: [
      {
        label: 'common.understood',
        actionToTake: '',
        color: 'secondary',
        variant: 'raised',
      },
    ],
  })),
});

export default compose(
  translate(),
  connect(null, mapDispatchToProps),
)(DocInput)
