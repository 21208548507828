import { compose, withProps } from 'recompose';
import { connect } from 'react-redux';

import { translate } from 'react-i18next';
import BtcTicker from './BtcTicker';

const mapStateToProps = ({ ticker, market, currencies }) => ({
  allTickers: ticker,
  market: market.selectedMarket,
  currencies: currencies.currencies,
  markets: currencies.markets,
});



const enhance = compose(
  connect(mapStateToProps),
  withProps(props => {
    const markets = props.markets;
    const market = props.market;
    const marketConfig = markets[market] || markets["BTC-BRL"];
    return {
      ticker: marketConfig ?  props.allTickers[marketConfig.base] : null,
      marketConfig,
    };
  }),
  // pure,
  translate(),
);

export default enhance(BtcTicker);
