import React from 'react';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import ResendValidationMail from './components/ResendValidationMail';
import { Helmet } from 'react-helmet'


const validate = props => {
  const { validationStatus, validation } = props;
  // TODO: Fix translations
  switch (validationStatus) {
    case 'success':
      return 'Seu email foi validado';

    case 'resolving' || 'init':
      return 'Validando seu token';

    case 'failed':
      if (validation.message_cod) {
        return validation.message_cod;
      }
      return 'Não conseguimos validar';

    default:
      return 'Essa página é exclusiva para confirmação de email';
  }
};

const getAction = props => {
  const { validationStatus, validation } = props;
  // TODO: Fix translations
  switch (validationStatus) {
    case 'failed':
      if (validation.message_cod) {
        if (validation.message_cod === 'EMAIL_ALREADY_VALIDATED') {
          return (
            <Button to="/login" component={Link}>
              Login
            </Button>
          );
        }
        return (
          <ResendValidationMail />
        );
      }
      return 'Não conseguimos validar';

    default:
      return (
        <Button to="/login" component={Link}>
          Login
        </Button>
      );
  }
};

const styles = theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 3 * theme.spacing.unit,
  },
});

const ValidateLayout = ({ classes, t, ...props }) => {
  return (
    <React.Fragment>
      <Helmet>
        <script>
          {`
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                n.callMethod.apply(n,arguments):n.queue.push(arguments)};
              if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
              n.queue=[];t=b.createElement(e);t.async=!0;
              t.src=v;s=b.getElementsByTagName(e)[0];
              s.parentNode.insertBefore(t,s)}(window, document,'script',
              'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '2460189007626899');
            fbq('track', 'PageView');
          `}
        </script>
        <noscript>
          {`
          <img
            height="1"
            width="1"
            style="display:none"
            src="https://www.facebook.com/tr?id=2460189007626899&ev=PageView&noscript=1"
          />
            `}
        </noscript>
      </Helmet>
      <div className={classes.root}>
        <Card>
          <CardContent>
            <Typography>
              {t(validate(props))}
            </Typography>

          </CardContent>
          <CardActions>
            {getAction(props)}
            {/* <Button to="/login" component={Link}>
              Login
            </Button> */}
          </CardActions>
        </Card>
      </div>
    </React.Fragment>
  );
};

export default withStyles(styles)(ValidateLayout);
