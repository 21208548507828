import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { Field } from 'redux-form';
import MuiPickersUtilsProvider from 'material-ui-pickers/utils/MuiPickersUtilsProvider';
import MomentUtils from 'material-ui-pickers/utils/moment-utils';

import { DatePicker } from 'material-ui-pickers';
import getHelperText from '../../utils/getHelperText';

const dateMask = val => {
  if (val) {
    return [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
  }
  return [];
};

const DateMaskFormatInput = (props) => {
  const {
    inputRef,
    input: { value, ...input },
    classes,
    InputProps,
    meta: { active, touched, error },
    t,
    ...other
  } = props;

  const shouldDisplayError = Boolean(touched && error)
  const helperText = shouldDisplayError
    ? getHelperText(t)(error)
    : ' ';

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <DatePicker
        clearable
        mask={dateMask}
        format="DD/MM/YYYY"
        minDateMessage={t('account.minDateMessage')}
        maxDateMessage={t('account.maxDateMessage')}
        value={R.isEmpty(value) && active ? null : value}
        InputProps={{ classes }}
        keyboard
        // invalidDateMessage={active ? null : 'Formato Inválido'}
        invalidDateMessage={'Formato Inválido'}
        {...other}
        {...input}
        error={shouldDisplayError}
        helperText={helperText}
      />
    </MuiPickersUtilsProvider>
  );
};

const DatePickerField = (props) => {
  return (
    <Field
      {...props}
      component={DateMaskFormatInput}
    // inputProps={{
    //   ...InputProps,
    // }}
    />
  );
};

DatePickerField.defaultProps = {
  InputProps: {},
};

DatePickerField.propTypes = {
  InputProps: PropTypes.objectOf(PropTypes.any),
};

export default DatePickerField;
