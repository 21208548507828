import {
  FETCH_SERVICE_REQUESTED,
  FETCH_SERVICE_SUCCEED,
  FETCH_SERVICE_FAILED,
} from './actionTypes';

export const fetchService = ({
  type, ...action
}) => ({
  type: FETCH_SERVICE_REQUESTED,
  ...action,
});

export const fetchServiceSucceed = ({ requestType, response }) => ({
  type: FETCH_SERVICE_SUCCEED,
  requestType,
  response,
});

export const fetchServiceFailed = ({ requestType, response }) => ({
  type: FETCH_SERVICE_FAILED,
  requestType,
  response,
});
