function getCurrencyIssuesFoundBySymbolAndScreen(
    currencyIssues,
    currencySymbol,
    screen
  ) {
    return currencyIssues.filter(
      (issue) =>
        issue.currencySymbol === currencySymbol &&
        issue.operationScreens.includes(screen)
    );
  }
  
  export default getCurrencyIssuesFoundBySymbolAndScreen;
  
export const getShowCryptoConverter = (showCryptoConverterFirebase, isBetaTester) => {
  return showCryptoConverterFirebase || isBetaTester;
};