import { SET_SELECTED_MARKET, UPDATING_DATA_STARTED, UPDATING_DATA_ENDED } from './actionTypes'

export const setSelectedMarket = ({ market, isInitialLoad }) => ({
  type: SET_SELECTED_MARKET,
  market,
  isInitialLoad
})

export const setStartUpdatingData = () => ({
  type: UPDATING_DATA_STARTED
})

export const setEndUpdatingData = () => ({
  type: UPDATING_DATA_ENDED
})

export default {
  setSelectedMarket
}
