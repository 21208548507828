import React from 'react';
import { Field } from 'redux-form';
import TextFieldComponent from './TextFieldComponent';

function onKeyPressed(e) {
	if(e.charCode === 42) {
		e.preventDefault();
	}
}

const EmailField = (props) => {

  return (
    <Field {...props} 
    	type="email"
    	id='emailfield'
    	onKeyPress={onKeyPressed}
    	component={TextFieldComponent} />
  );


};

export default EmailField;
