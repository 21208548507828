import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';

import { translate } from 'react-i18next';

import SwitchButton from '../Buttons/SwitchButton';
import LabelNumberNotification from '../Buttons/LabelNumberNotification';
const enhance = compose(
  translate(),
);

const ShowMineButton = props => {
  const { showAll, setShowAll, myActiveOrders,
          changeShowAll, ordersType, t } = props;

  return (
    <SwitchButton
      isActive={!showAll}
      onClick={() => {setShowAll(false); if(changeShowAll) changeShowAll(false)/*Used get the state of button in orderBook(changes the subtitle)*/}}
    >
      { myActiveOrders > 0 ? <LabelNumberNotification
        quantity={myActiveOrders}
        label={t('transactions.mine')}
        type={ordersType}
      /> : t('transactions.mine')}
    </SwitchButton >
  );
};

ShowMineButton.propTypes = {
  showAll: PropTypes.bool.isRequired,
};

export default enhance(ShowMineButton);
