import * as R from 'ramda';
import {validateCPF, validateCNPJ} from './validateTaxId';
import moment from 'moment';

const validateString = (schema, value, name) => {
  if (schema.regEx){
    if (name === 'pass'){
      const regExTest = R.test(schema.regEx, value);
      if (!regExTest) {
        return 'INVALID_FORMAT';
      }
    }else if (name === 'address'){
      const regExTest = R.test(schema.regEx, value);
      if (!regExTest) {
        return 'INVALID_ADDRESS';
      }
    }
    else if (name === 'email') {
      const regExTest = R.test(schema.regEx, value);
      if (!regExTest) {
        return 'INVALID_EMAIL';
      }
    }
    else if (name === 'name') {
      const regExTest = R.test(schema.regEx, value);
      if (!regExTest) {
        return 'INVALID_NAME';
    }
  }
    else if (name === 'motherName') {
      const regExTest = R.test(schema.regEx, value);
      if (!regExTest) {
        return 'MOTHER_NAME_INVALID';
      }
    }
  }
  if (schema.min) {
    if (value.length < schema.min) {
      return ['MIN_LENGHT_REQUIRED', { min: schema.min }];
    }
  }
  if (schema.max) {
    if (value.length > schema.max) {
      return ['MAX_LENGHT_REQUIRED', { max: schema.max }];
    }
  }
  return null;
};

const validatePostalcode = value => {
  const postalcodeRegex = /^[0-9]{8}$/
    const regExTest = R.test(postalcodeRegex, value);
    if (!regExTest) {
      return 'INVALID_POSTAL_CODE';
    }
}

const isDateValid = value => {
  const date = moment(value, 'DD-MM-YYYY');
  const today = moment();
  const minDate = moment().subtract(100, 'year');
  return date.isValid() && date < today && date > minDate;
}

const isTooYoung = value => {
  const date = moment(value, 'DD-MM-YYYY');
  const minimumAge = moment()
    .subtract(18, 'year')
    .subtract(1, 'day');
  return date.isValid() && date >= minimumAge;
}

const validatePassWord = (values)=>{
  const {pass, repeatPass} = values;
  if((pass && repeatPass) && (pass !== repeatPass) )
    return 'PASS_NOT_EQUAL';
  return null;
}

const validate = (schemas) => (values, _props) => {
  const validations = R.mapObjIndexed((schema, name) => {
    const value = R.propOr(false, name, values);
    if (value) {
      if (R.not(R.is(schema.type, value))&&
        name !== 'amount'&&
        name !== 'birth' &&
        name !== 'from' &&
        name !== 'to' && 
        name !== 'motherName') {
        return 'INVALID_TYPE';
      }
      if (R.equals(String, schema.type)) {
        if(name === 'tax_id'){
          if(value.length === 11 && !validateCPF(value)){
            return ['CPF_ERROR']
          }
          else if(value.length === 14 && !validateCNPJ(value)){
            return ['CNPJ_ERROR']
          }
          else if(value.length<11 || (value.length>11 && value.length<14)){
            return ['TYPE_VALID_VALUE']
          }
        }
        else if(name === 'phone'){
          if(value.length < 11){
            return ['PHONE_ERROR']
          }
        }
        else if(name === 'birth'){
          if(value.toString().length < 8){
            return ['MIN_LENGTH_REQUIRED']
          }else if(!isDateValid(value)){
            return ['INVALID_DATE']
          }else if(isTooYoung(value)){
            return ['TOO_YOUNG']
          }
        }
        else if(name === 'postalcode'){
          return validatePostalcode(value)
        }
        const stringValidated = validateString(schema, value, name);
        if (stringValidated) return stringValidated;
        if (name === 'repeatPass'){
          const passValidated = validatePassWord(values);
          if (passValidated) return passValidated;
        }
      }
      if (R.equals(Number, schema.type)) {
        if (schema.min) {
          if (value < schema.min) {
            return ['MIN_VALUE_REQUIRED', { min: schema.min }]
          }
        }
        if (schema.validateDecimals && name === 'amount') {
          const numberStr = String(value)
          const decimalIndex = numberStr.indexOf('.')
          const containsDecimal = decimalIndex !== -1
          const decimal = numberStr.substring(decimalIndex + 1, numberStr.length)
          const isDecimalZero = Number(decimal) === 0

          if (!containsDecimal || isDecimalZero) {
            return 'forms.amount.invalidDepositDecimals'
          }
        }
      }
      return '';
    }
    if (schema.required) {
      return 'REQUIRED';
    }
    return '';
  }, schemas);

  return validations;
};

export default validate;
