import React, {useState, useEffect} from 'react';
import { translate } from 'react-i18next';
import { compose, withState } from 'recompose';

import { withStyles } from '@material-ui/core/styles';

import SubmitButton from '../../components/Forms/SubmitButton';
import BankNumberMaskField from '../../components/Fields/BankNumberMaskField';
import RadioGroupField from '../../components/Fields/RadioGroupField';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import SelectBankField from './SelectBankField';
import Typography from '@material-ui/core/Typography';
import {normalizeBankAccount} from '../../utils/NumberFormat/normalizers';
import store from './../../redux/store';
import LinkButton from '../../components/Buttons/LinkButton';

function checkInfo() {
  return store.getState().user.profile.address === "" ? true :
    store.getState().user.profile.city === "" ? true :
    store.getState().user.profile.email === "" ? true :
    store.getState().user.profile.name === "" ? true :
    store.getState().user.profile.postalcode === "" ? true :
    store.getState().user.profile.state === "" ? true :
    store.getState().user.profile.tax_id === ""
}

const styles = theme => ({
  root: {
    flex: 1,
  },
  container: {
    display: 'flex',
    // flex: 1,
    flexWrap: 'wrap',
    // width: '100%',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      display: 'inline',
      width: '100%'
    },
  },
  textField: {
    marginRight: theme.spacing.unit,
    // display: 'flex',
    flex: 1,
    maxWidth: 170,
    // width: '100%',
    // flexWrap: 'wrap',
  },
  selectField: {
    flex: 1,
    marginRight: theme.spacing.unit,
    maxWidth: 300,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      maxWidth: '100%'
    },
  },
  button: {
    maxHeight: theme.spacing.unit * 4,
    alignSelf: 'center',
  },
  radioGroup: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '10px'
  }
});

const BankAccountField = props => {
  const {
    classes,
    t,
    invalid,
    anyTouched,
    submitting,
    hasAccount,
    formWithdrawal,
    setMissingInfo,
    hasMissingInfo,
    isBB,
    setIsBB,
    disableSubmit,
    classEditBank
  } = props;
  
  const [emptyInput, setEmptyInput] = useState(false);
  const validateBankAccount = store.getState().form.bankAccount.values

  const agency = validateBankAccount ? store.getState().form.bankAccount.values.agency : '';
  const account = validateBankAccount ? store.getState().form.bankAccount.values.account : '';
  const is_CC = validateBankAccount ? store.getState().form.bankAccount.values.is_CC : '';
  const bank = validateBankAccount ? store.getState().form.bankAccount.values.bank.key : '';

  useEffect(()=>{
    if(agency && account && is_CC && bank){
      setEmptyInput(false)
    }else{
      setEmptyInput(true)
    }
  },[agency, account, is_CC, bank])

  const waitAndCheckMissingInfo = () => {
      setTimeout(() => setMissingInfo(checkInfo()), 4200)
  }

  let hasSyncErrors = false;

  if(store.getState().form.bankAccount.syncErrors){ 
    hasSyncErrors = store.getState().form.bankAccount.syncErrors.account !== '' ||
    store.getState().form.bankAccount.syncErrors.agency !== ''
  }
 
  const [hasError, setHasError] = useState(hasSyncErrors);

  useEffect(() => {
    if(store.getState().form.bankAccount.syncErrors){ 
      setHasError(store.getState().form.bankAccount.syncErrors.account !== '' ||
                  store.getState().form.bankAccount.syncErrors.agency !== '');
    }
  })

  return (
    <div>
      {waitAndCheckMissingInfo()}
      {hasMissingInfo && formWithdrawal &&
      <React.Fragment>
        <Typography color="error" variant="body2">
         {t('common.registerDatasProfile')}
        </Typography>
        <LinkButton
          to={'/profile/account'}
          color='secondary'
          variant='raised'
        >
          {t('common.to_registerDatasProfile')}
        </LinkButton>
      </React.Fragment>
      }
      {hasMissingInfo && !formWithdrawal &&
        <Typography color="error" variant="body2">
          *TODOS OS CAMPOS ACIMA SÃO OBRIGATÓRIOS.
        </Typography>
      }
      <div className={formWithdrawal? classEditBank.containerEditBank : classes.container}>
        <SelectBankField
          name="bank"
          t={t}
          classes={classes}
          onChange={(e) => {
            e.key === '001' ? setIsBB(true) : setIsBB(false)
          }}
          disabled={hasAccount || hasMissingInfo}
        />
        <BankNumberMaskField
          disabled={hasAccount || hasMissingInfo}
          margin="dense"
          className={classes.textField}
          removeMask="true"
          name="agency"
          isBB={isBB}
          placeholder={isBB ? '00000-0' : '0000-0'}
        />
        <BankNumberMaskField
          disabled={hasAccount || hasMissingInfo}
          margin="dense"
          removeMask="true"
          normalize={normalizeBankAccount}
          className={classes.textField}
          name="account"
          placeholder='00000-0'
        />
        <RadioGroupField
          name='is_CC'
          className={formWithdrawal? classEditBank.radioGroupEditBank : classes.radioGroup}
        >
          <FormControlLabel
            value='CC'
            control={
              <Radio
                style={{ color: 'rgba(0, 0, 0, 0.54)' }}
                disabled={hasAccount || hasMissingInfo}
              />}
            label={t('account.bankAccountTypeCC')}
            labelPlacement='top'
          />
          <FormControlLabel
            value='PP'
            control={
              <Radio
                style={{ color: 'rgba(0, 0, 0, 0.54)' }}
                disabled={hasAccount || hasMissingInfo}
                />}
            label={t('account.bankAccountTypePP')}
            labelPlacement='top'
          />
        </RadioGroupField>
        <SubmitButton
          submitText={t('common.save')}
          color="secondary"
          size="small"
          disabled={(hasAccount || (submitting || (anyTouched && invalid))) || hasMissingInfo || hasError || disableSubmit || emptyInput}
          classes={{
            root: classes.button,
          }}
        />
      </div>
    </div>
  );
};


const enhance = compose(
  translate(),
  withState('isBB', 'setIsBB', false),
  withState('hasMissingInfo', 'setMissingInfo', () => checkInfo()),
  withStyles(styles),
);

export default enhance(BankAccountField);
