
import { HANDLE_APP_BAR_MENU } from './actionTypes';

export const handleAppBarMenu = (anchorEl, currencySelectorFilter, extraData = {}) => ({
  type: HANDLE_APP_BAR_MENU,
  anchorEl,
  currencySelectorFilter,
  extraData,
});

export default {
  handleAppBarMenu,
};
