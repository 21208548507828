import * as R from 'ramda';

const initialState = {
  BTC: 0,
  BRL: 0,
  USDT: 0,
  ETH: 0,
  BNB: 0,
  CRZO: 0,
  PAXG: 0,
  SLP: 0,
  CAKE: 0,
  UNI: 0,
  SOL: 0,
  POLIS: 0,
  USDC: 0,
  USDBP: 0,
  ATLAS: 0,
  AXS: 0,
  ABFY: 0,
  ADA: 0,
  LUNA: 0,
  LUNC: 0,
  GMT: 0,
  BUSD: 0,
  // ELLO: 0,
  DOT: 0,
  MATIC: 0,
  LINK: 0,
  XRP: 0,
  BRL_debt: 0,
  BTC_locked: 0,
  BRL_locked: 0,
  USDT_locked: 0,
  ETH_locked: 0,
  BNB_locked: 0,
  CRZO_locked: 0,
  USDBP_locked: 0,
  PAXG_locked: 0,
  SLP_locked: 0,
  AXS_locked: 0,
  UNI_locked: 0,
  CAKE_locked: 0,
  SOL_locked: 0,
  POLIS_locked: 0,
  USDC_locked: 0,
  ATLAS_locked: 0,
  ABFY_locked: 0,
  ADA_locked: 0,
  LUNA_locked: 0,
  LUNC_locked: 0,
  GMT_locked: 0,
  BUSD_locked: 0,
  ELLO_locked: 0,
  DOT_locked: 0,
  MATIC_locked: 0,
  LINK_locked: 0,
  XRP_locked: 0,
};

const updateBalance = (state, response) => {
  return R.merge(state, response);
};

const balanceReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_SERVICE_SUCCEED':
      switch (action.requestType) {
        case 'balance':

          return updateBalance(state, action.response);

        default:
          return state;
      }
    default:
      return state;
  }
};

export default balanceReducer;
