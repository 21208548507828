import React from 'react'
import { translate } from 'react-i18next'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import InfoTooltip from './InfoTooltip';
import CurrencyFormat from './Currency/CurrencyFormat';
import { Currency } from '../models/Currency'

const styles = theme => ({
  containerActive: {
    display: 'flex',
    alignItems: 'center',
    padding: '5px 5px 5px 5px',
    borderRadius: 5,
    flexDirection: 'column',
    backgroundColor: theme.palette.secondary.main,
    [theme.breakpoints.down('sm')]: {
      width: '80px',
    },
    width: '100px'
  },
  singlePriority: {
    width: '100% !important',
    flexDirection: 'row !important',
    justifyContent: 'center !important',
    gap : '10px !important',
    height: '50px !important',
    alignItems: 'center !important',

    "& p:nth-of-type(n+2)" : {
      marginTop: '3px !important'
    }
  },
  containerInactive: {
    display: 'flex',
    alignItems: 'center',
    padding: '5px 5px 5px 5px',
    borderRadius: 5,
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      width: '80px',
    },
    width: '100px',
  },
  label: {
    fontWeight: 500
  },
  fee:{
    fontSize: 12,
    fontWeight: 400,
  },
  toolTipComponent: {
    backgroundColor: 'white',
    borderRadius: '100%',
    zIndex:1000
  }
})

const TokenChip = ({ t, classes, priorityType, priorityFeeBrl, priorityFee, active, currencyData, isSingleChip , baseCurrencySymbol}) => {
  const currency = new Currency(currencyData);
  return (
    <Card
      className={
        active
          ? isSingleChip
            ? [classes.containerActive, classes.singlePriority]
            : classes.containerActive
          : classes.containerInactive
      }
    >
      <Typography className={classes.label}>
        {t(`transactions.priorityLabels.${priorityType}`)}
      </Typography>
      <Typography className={classes.fee}>{priorityFee}</Typography>
      <Typography className={classes.fee}>
        {!isNaN(priorityFeeBrl) && (
          <CurrencyFormat
            value={priorityFeeBrl}
            isFiat={currency.isFiat}
            formatter={currency._defaultFormatter()}
          />
        )}
      </Typography>
      {baseCurrencySymbol === "BTC" && (
        <div className={classes.toolTipComponent}>
          <InfoTooltip
            iconProps={{ style: { marginLeft: 0 } }}
            title={t(`transactions.priorityWarning.${priorityType}`)}
          />
        </div>
      )}
    </Card>
  );
}

export default translate()(withStyles(styles)(TokenChip))
