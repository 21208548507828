import React, { Fragment } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';

import Hidden from '@material-ui/core/Hidden';
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';
import MaterialAppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { withStyles } from '@material-ui/core/styles';
import BtcTicker from '../../containers/BtcTicker';
import UserBalanceToolbar from '../../containers/UserBalance/Toolbar';
import AppBarLogo from './AppBarLogo';
import AppBarMenu from './AppBarMenu';
import NotificationsMenu from './NotificationsMenu'
import AppBarTokenMenu from './AppBarTokenMenu';
import AppBarTokenMobile from './AppBarTokenMobile';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';

const styles = theme => ({
  root: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
  },
  flexCenter: {
    justifyContent: 'center',
  },
  flexStart: {
    justifyContent: 'flex-start',
  },
  toolbarRoot:{
    '@media only screen and (min-height: 800px)' : {
      minHeight: '10px'
    },
  },
  appBar: {
    marginLeft: theme.drawerWidth,
  },
  appBarShift: {
    backgroundColor: theme.drawerBackgroundColor,
    marginLeft: theme.drawerWidth,
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${theme.drawerWidth}px)`,
      "&:before": {
        content: '""',
        position: 'absolute',
        bottom: '-60px',
        height: 60,
        width: 30,
        borderTopLeftRadius: 50,
        boxShadow: `0 -32px 0 0 ${theme.drawerBackgroundColor}`
      }
    },
  },
  // criptoOptions: {
  //   marginLeft: 'auto'
  // },
  // tickerOrientation:{
  //   marginRight: 'auto'
  // }
});

const AppBar = props => {
  const {
    classes,
    logged,
    handleDrawerToggle,
    mobileOpen,
    name,
    market,
    history,
    demoAccount
  } = props;

  const isLogged = logged || demoAccount;
  return (
    <MaterialAppBar className={isLogged ? classes.appBarShift : classes.appBar}>
      <Hidden smDown={!isLogged}>
        <Toolbar>
          <div className={classNames(classes.root, isLogged ? classes.flexStart : classes.flexCenter) }>
            {isLogged && <AppBarLogo market={market} history={history} />}


            {!isLogged && <div>
              <Hidden smDown>
                <AppBarTokenMenu />
              </Hidden>
            </div>}


            {<Hidden smDown>
              <div><BtcTicker /></div>
            </Hidden>}
          </div>
          {isLogged && (
            <NotificationsMenu />
          )}
          {isLogged &&
            <Hidden mdUp>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={() => handleDrawerToggle(!mobileOpen)}
                className={classes.navIconHide}
              >
                <MenuIcon />
              </IconButton>
            </Hidden >
          }
          {isLogged && (
            <Hidden smDown>
              <AppBarMenu name={name} />
            </Hidden>
          )}
        </Toolbar>
      </Hidden >

      <Hidden mdUp>
        <Toolbar classes={{root: classes.toolbarRoot}}>
            <div className={classes.root}>
              
                <Hidden mdUp>
                  <AppBarTokenMobile name={name} isLogged={isLogged} />
                </Hidden>
              <BtcTicker />
            </div>

        </Toolbar>

        {isLogged &&
        <div className={classes.root}>
          <UserBalanceToolbar />
        </div>}

      </Hidden >
    </MaterialAppBar>
  );
};

const mapStateToProps = (state) => {
  const { user: { profile: { name } }, credentials : { demoAccount } } = state;

  return {
    name,
    market: state.market.selectedMarket,
    demoAccount,
  };
};

const enhance = compose(
  withRouter,
  withStyles(styles),
  connect(mapStateToProps),
);

export default enhance(AppBar);
