import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  compose
} from 'recompose';
import cardMockURL from '../../../../assets/documents-limits/card-exclusive-classic.png'
import qrCode from '../../../../assets/QR-Code.svg'
import classNames from 'classnames';
import StoreLinks from "../../../../components/Buttons/StoreLinks";
import InfoIcon from '@material-ui/icons/Info';
import { translate } from 'react-i18next';

const styles = _theme => ({
  root: {
    witdh: '100%',
    backgroundColor: '#101010',
    borderRadius: '25px',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '25px 40px',
    position: 'relative',
    margin: '10px 0',
    overflow: 'hidden',
    '@media screen and (max-width: 10000px)': {
      margin: '10px 10px',
      padding: '30px 50px',
    },
    '@media screen and (max-width: 600px)': {
      flexDirection: 'column',
      gap: '20px'
    },
  },
  flex: {
    display: 'flex',
  },
  bannerTextItems: {
    flexDirection: 'column'
  },
  item: {
    justifyContent: 'space-around',
    maxWidth: '700px',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    gap: '12px',
    flexDirection: 'column',
    zIndex: 10,
  },
  itemAccountNotVerified: {
    alignItems: 'center',
    gap: '4px',
    marginBottom: '12px',
    zIndex: 10,
  },
  textWhite: {
    color: '#FAFAFA',
    fontWeight: 700,
    fontSize: '20px',
    lineHeight: '25px',
  },
  textYellow: {
    color: '#FFCF74',
    fontWeight: 700,
    fontSize: '20px',
    lineHeight: '25px',
  },
  textGray: {
    color: '#C8C8C8',
    fontSize: '14px',
    fontWeight: 500,
  },
  iconAccountNotVerified: {
    display: 'grid',
    placeItems: 'center',
    color: '#FF6800',
    fontSize: '14px',
  },
  textOrange: {
    color: '#FF6800',
    fontWeight: 600,
    fontSize: '16px',
    textTransform: 'uppercase',
    fontFamily: "'Arial', sans-serif",
  },
  imageMockup: {
    backgroundImage: `url(${cardMockURL})`,
    backgroundSize: '85%',
    backgroundRepeat: 'no-repeat',
    position: 'absolute',
    width: '375px',
    height: '375px',
    top: -105,
    zIndex: 1,
    left: '60%',
    '@media screen and (max-width: 1350px)': {
        top: -40,
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${cardMockURL})`,
    },
    '@media screen and (max-width: 1000px)': {
      left: '70%',
    },
    '@media screen and (max-width: 600px)': {
      backgroundSize: '100%',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '475px',
      height: '475px',
    }
  },
  buttons: {
    display: 'none',
    '@media screen and (max-width: 1000px)': {
        display: 'flex',
      }
  },

  containerDownload: {
    '@media screen and (max-width: 1000px)': {
        display: 'none',
    },
  },
  qrCodeLogo: {
    backgroundColor: '#101010'
  }
});

const BannerKYCNotOk = (props) => {
  const { t, classes } = props;

  return (
    <div className={classNames(classes.root, classes.flex)}>
        <div className={classNames(classes.bannerTextItems, classes.flex)}>
          <div className={classNames(classes.itemAccountNotVerified, classes.flex)}>
            <InfoIcon className={classes.iconAccountNotVerified} />
            <span className={classes.textOrange}>Sua conta ainda não foi verificada!</span>
          </div>
          <div className={classNames(classes.item, classes.flex)}>
              <div className={classes.textWhite}>
                  Melhore a segurança da sua conta, aumente seus limites e tenha cartões disponíveis! 
                  <span className={classes.textYellow}> Acesse o app Bitybank e verifique sua identidade.</span>
              </div>
              <div className={classes.textGray}>Caso já tenha enviado seus documentos, acompanhe a validação pelo seu aplicativo.</div>
              <div className={classes.buttons}>
                <StoreLinks />
              </div>
          </div>
        </div>
        <div className={classes.imageMockup}/>
        <div className={classes.containerDownload} style={{ position: 'relative', width: 100, height: 100, zIndex: 10 }}>
          <img src={qrCode} className={classes.qrCodeLogo} height={100} width={100} alt={t("imageAlts.qrCodeAppBity")}/>
        </div>
    </div>
  );
};

const enhance = compose(
  withStyles(styles),
  translate(),
);


export default enhance(BannerKYCNotOk);
