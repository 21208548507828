import { 
    put, takeLatest, 
} from 'redux-saga/effects'
import { fetchService } from '../../actions';

import { FETCH_REFERRALS_SUCCEED,  FETCH_REFERRALS} from './actionsTypes';
function* fetchReferrals(_action) {
  try {
    yield put(fetchService({
      requestType: 'fetchReferrals',
      successAction: FETCH_REFERRALS_SUCCEED,
      paramns: {
        privateService: true,
        options: {
          cmd: 'get_referrals',
          method: 'POST',
        },
      },
    }));
  } catch (e) {
    console.error('fetchUserCards', e);
  }
}

function* watchMgm() {
    yield takeLatest(FETCH_REFERRALS, fetchReferrals);
}
  
export default watchMgm;
  