import React from 'react';
import { compose, setDisplayName, setPropTypes } from 'recompose';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Help';
import { withStyles } from '@material-ui/core/styles';
import DangerouslySetInnerHTML from './DangerouslySetInnerHTML';

const styles = theme => ({
  tooltip: {
    fontSize: theme.typography.pxToRem(15),
  },
  light: {
    fontSize: theme.typography.pxToRem(13),
    opacity: 1,
    backgroundColor: '#F4F4F4',
    color: theme.palette.text.secondary,
    boxShadow: theme.shadows[1],
  },
  popper: {
    display: 'flex',
    marginRight: theme.spacing.unit,
  },
  lightOpacity: {
    opacity: 1,
  },
  open: {
    display: 'flex',
    marginRight: theme.spacing.unit,
  },
  wrapperRoot: {
    display: 'flex',
    alignItems: 'center',
    alignSelf: 'flex-end',
  },
  wrapperRootHeader: {
    display: 'flex',
    alignItems: 'center'
  },
  listWrapper: {
    alignSelf: 'center',
    marginRight: theme.spacing.unit * 0.5,
    [theme.breakpoints.up('md')]: {
      marginRight: theme.spacing.unit * 2,
    },
  },
  infoIconRoot: {
    marginLeft: 2,
    fontSize: 16,
  },
  listItemVariant: {
    fontSize: 24,
  },
  gutterBottom: {
    marginBottom: 2,
  },
});

const TooltipWrapper = props => {
  const {
    iconProps: { style, ...iconProps },
    classes,
    listItemVariant,
    children,
    gutterBottom,
    Component = 'div',
    wrapperProps,
    wrapperRootHeader,
    hideIcon,
    ...rest
  } = props;
  return (
    <Component
      className={
        (!wrapperRootHeader ?
          classNames(classes.wrapperRoot, listItemVariant && classes.listWrapper) :
          classNames(classes.wrapperRootHeader, listItemVariant && classes.listWrapper)
        )}
        {...wrapperProps}
        {...rest}
      >
        {children}
        {
          !hideIcon &&
            <InfoIcon
              color="secondary"
              style={style}
              className={
                classNames(
                  classes.infoIconRoot,
                  listItemVariant && classes.listItemVariant,
                  gutterBottom && classes.gutterBottom,
                )}
                {...iconProps}
              />
        }
      </Component>
  );
};

const InfoTooltipComponent = props => {
  const {
    title,
    iconProps,
    classes,
    children,
    gutterBottom,
    component,
    listItemVariant,
    wrapperProps,
    wrapperRootHeader,
    hideIcon,
    light,
    ...rest
  } = props;
  const hasTitle = title && title !== '';
  return (
    <Tooltip
      id={title}
      title={hasTitle ? <DangerouslySetInnerHTML title={title} /> : ''}
      placement="top"
      classes={{
        tooltip: classNames(classes.tooltip, light ? classes.light : null),
        popper: classNames(classes.popper, light ? classes.lightOpacity : null),
      }}
      enterTouchDelay={1}
      enterDelay={300}
      {...rest}
    >

      <TooltipWrapper
        gutterBottom={gutterBottom}
        listItemVariant={listItemVariant}
        iconProps={iconProps}
        classes={classes}
        Component={component}
        wrapperProps={wrapperProps}
        wrapperRootHeader={wrapperRootHeader}
        hideIcon={hideIcon}
      >
        {children}
      </TooltipWrapper>
    </Tooltip>
  );
};

InfoTooltipComponent.defaultProps = {
  iconProps: { style: {} },
};

InfoTooltipComponent.propTypes = {
  title: PropTypes.string,
};

const enhace = compose(
  setPropTypes({
    title: PropTypes.string.isRequired,
    iconProps: PropTypes.shape({
      style: PropTypes.obj,
    }),
  }),
  setDisplayName('InfoTooltip'),
  withStyles(styles),
);

const InfoTooltip = enhace(InfoTooltipComponent);

export default InfoTooltip;
