import React from "react";
import { translate } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import CurrencyIcon from "./CurrencyIcon";
import Typography from "@material-ui/core/Typography";
import BBLogo from "../assets/LOGO_BB.png";
import LOGO_INTER from "../assets/LOGO_INTER.png";
import LOGO_BRASIL_PLURAL from "../assets/LOGO_BRASIL_PLURAL.png";
import LOGO_OUTROS from "../assets/LOGO_OUTROS.png";
import LOGO_BRZ from "../assets/logo_BRZ.svg";
import LOGO_CBRL from "../assets/logo_CBRL.jpg";
import LOGO_RAS from "../assets/logo_RAS.png";
import LOGO_PIX_ICON from "../assets/logo_pix_icon.png";

const styles = (theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
  },
  iconWrapper: {
    width: 32,
    height: 32,
    borderRadius: 16,
  },
  label: {
    paddingLeft: 10,
    paddingRight: 5,
    fontWeight: 500,
  },
});

const getTokenLogo = (market) => {
  switch (market) {
    case "077":
      return LOGO_INTER;
    case "125":
      return LOGO_BRASIL_PLURAL;
    case "001":
      return BBLogo;
    case "OTHER_BANKS":
      return LOGO_PIX_ICON;
    case "CBRL":
      return LOGO_CBRL;
    case "BRZ":
      return LOGO_BRZ;
    case "RAS":
      return LOGO_RAS;
    case "STABLECOIN":
      return LOGO_BRZ;
    default:
      return LOGO_OUTROS;
  }
};

const SelectItemChip = ({ classes, itemName, itemCode, currency }) => {
  return (
    <div className={classes.container}>
      <div className={classes.iconWrapper}>
        {!currency ? (
          <img
            alt=""
            src={getTokenLogo(itemCode)}
            className={classes.iconWrapper}
          />
        ) : (
          <CurrencyIcon currency={currency} />
        )}
      </div>
      <Typography className={classes.label}>{itemName}</Typography>
    </div>
  );
};

export default translate()(withStyles(styles)(SelectItemChip));
