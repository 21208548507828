import { compose, withProps, defaultProps } from 'recompose';
import moment from 'moment';
import DatePickerField from './DatePickerField';
import { translate } from 'react-i18next';


const enhance = compose(
  translate(),
  defaultProps({
    value: moment()
      .subtract(18, 'year')
      .subtract(1, 'day')
      .toDate(),
    minDate: moment()
    .subtract(100, 'year')
    .toDate(),
    maxDate: moment().subtract(18, 'year').toDate()
  }),
  withProps(props => ({
    ...props,
    disableFuture: true,
  })),
);

const BornDatePickerField = enhance(DatePickerField);

export default BornDatePickerField;
