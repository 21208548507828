import React from "react";
import PropTypes from "prop-types";
import * as R from "ramda";
import NumberFormat from "react-number-format";
import { withStyles } from "@material-ui/core";
import { styles } from "./styles";
import { compose } from "recompose";
import classNames from "classnames";

const removeFormatting = (val) =>
  R.join("", R.reject(R.isEmpty, R.split("_", R.join("", R.split("-", val)))));

const CriptoMaskedInput = (props) => {
  const {
    inputRef,
    onChange,
    onBlur,
    value,
    prefix,
    decimalScale,
    fixedDecimalScale,
    classes,
    placeholder,
    isAllowed,
    helperText,
    customClasses,
    disabled,
    ...other
  } = props;
  return (
    <div>
      <NumberFormat
        {...other}
        className={classNames(
          classes.input,
          customClasses && customClasses.input
        )}
        disabled={disabled}
        placeholder={placeholder}
        decimalScale={decimalScale || 8}
        fixedDecimalScale={fixedDecimalScale}
        prefix={prefix ? `${prefix} ` : ""}
        value={value}
        decimalSeparator="."
        ref={inputRef}
        isAllowed={isAllowed}
        onValueChange={(values) => {
          onChange(R.isNil(values.value) ? "" : values.value);
        }}
        removeFormatting={(val) => {
          const cleanVal = removeFormatting(val);

          return cleanVal;
        }}
      />
      {helperText && (
        <div
          className={classNames(
            classes.helperTextContainer,
            customClasses && customClasses.helperTextContainer
          )}
        >
          <p
            className={classNames(
              classes.helperText,
              customClasses && customClasses.helperText
            )}
          >
            {helperText}
          </p>
        </div>
      )}
    </div>
  );
};

CriptoMaskedInput.defaultProps = {
  value: null,
};

CriptoMaskedInput.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  inputRef: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
};

const enhance = compose(withStyles(styles));

export default enhance(CriptoMaskedInput);
