import React from 'react';
import { connect } from 'react-redux';
import { compose, withHandlers, withState } from 'recompose';
import { translate } from 'react-i18next';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import {
    resendEmailValidation,
    closeResendEmailDialog,
    addValidationTokenDialog
} from '../../redux/credentials/actions';
import CountdownSeconds from '../../components/CountdownSeconds';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { contactSupport } from "../../utils/contactSupport";

const styles = theme => ({
    flexContainer: {
        display: 'flex',
        justifyContent: 'center',
        marginRight: 'auto',
        marginTop: 15
    },
    containerWrap: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        margin: 5
    },
    countdown:{
        marginRight: 5,
        marginLeft: 5,
        fontSize: 15,
        fontWeight: 550

    }
});

const mapDispatchToProps = dispatch => ({
    resendValidation: options => dispatch(resendEmailValidation(options)),
    addValidationTokenDialog : data => dispatch(addValidationTokenDialog(data)),
    closeDialog: () => dispatch(closeResendEmailDialog())
});

const mapStateToProps = (state) => {
    return {
        dialogOptions: state.credentials.resendEmailDialog,
    };
};

const AppResendEmailDialog = props => {
    const {
        sendEmailValidation,
        onEmailCountdownFinish,
        allowResendEmail,
        classes,
        handleClose,
        t,
        validateToken,
        dialogOptions: { open, email },
    } = props;
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
            disableBackdropClick={true}
            disableEscapeKeyDown={true}
        >
            <DialogTitle id="form-dialog-title">
                {t('CONFIRMATION_MAIL_SENT')}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <div className={classes.containerWrap}>
                        <Typography variant='body2'>
                            {`${t('CONFIRMATION_MAIL_CHECK', { email })}`}
                        </Typography>
                        <Typography classes={{root: classes.flexContainer}} variant='body2' >
                            Você pode reenviar o email em
                            <div className={classes.countdown}>
                                { !allowResendEmail ?
                                  <CountdownSeconds onFinish={onEmailCountdownFinish} totalSeconds={60}/>
                                  : 0 }
                              </div>
                            segundos.
                        </Typography>
                    </div>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button color="secondary"
                        variant="raised"
                        onClick={()=>{
                            contactSupport();
                        }}
                >
                    {t('CONTACT_SUPPORT')}
                </Button>
                <Button onClick={validateToken}
                  color="secondary"
                  variant="raised"
                >
                    {t('CONFIRM_EMAIL_RECEIVED')}
                </Button>
                <Button onClick={sendEmailValidation}
                        disabled={!allowResendEmail}
                        color="secondary"
                        variant="raised"
                >
                    {t('RESEND_CONFIRMATION_EMAIL')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

AppResendEmailDialog.defaultProps = {
    dialogOptions: {
        open: false, email: ''
    }
};

AppResendEmailDialog.propTypes = {
    dialogOptions: PropTypes.shape({
        open: PropTypes.bool,
        email: PropTypes.string
    }).isRequired
};

const enhance = compose(
    translate(),
    withStyles(styles),
    withState("allowResendEmail", "setAllowResendEmail", false),
    connect(mapStateToProps, mapDispatchToProps),
    withHandlers({
        handleClose: ({ closeDialog, setAllowResendEmail }) => () => {
            setAllowResendEmail(false);
            closeDialog();
        },
        onEmailCountdownFinish: ({ setAllowResendEmail }) => () => setAllowResendEmail(true),
        sendEmailValidation: ({ resendValidation, setAllowResendEmail, dialogOptions: { email } }) => () => {
            resendValidation({ email });
            setAllowResendEmail(false);
        },
        validateToken: ({ closeDialog, addValidationTokenDialog, dialogOptions: { email } }) => () => {
            closeDialog();
            addValidationTokenDialog({validationCmd: 'validate_acc', payload:{ email }});
        }

    })
);

export default enhance(AppResendEmailDialog);
