import React, { Fragment } from 'react';
import { pure, compose, withState, withHandlers, withProps } from 'recompose';
import * as R from 'ramda';
import TextField from '@material-ui/core/TextField';
import Menu from '@material-ui/core/Menu';
import { withStyles } from '@material-ui/core/styles';
import OptionsList from './OptionsList';


const styles = theme => ({
  paper: {
    position: 'relative',
    width: '100%',
    maxWidth: 360,
    overflow: 'auto',
    maxHeight: 300,
    backgroundColor: theme.palette.background.paper,
  },
  margin: {
    margin: theme.spacing.unit,
  },
});

const SearchSelect = (props) => {
  const {
    input: { name, ...input },
    label,
    setAnchorEl,
    anchorEl,
    classes,
    textFieldProps,
    disabled,
  } = props;
  
  const value = R.pathOr('', ['value', 'label'], input);
  const key = R.pathOr('', ['value', 'key'], input);
  return (
    <Fragment>
      <TextField
        fullWidth
        value={`${key} - ${value}`}
        aria-owns={anchorEl ? `${name}-menu` : null}
        aria-haspopup="true"
        onClick={e => setAnchorEl(e.currentTarget)}
        label={label}
        disabled={disabled}
        inputProps={{
          style: { cursor: disabled ? 'default' : 'pointer' },
        }}
        {...textFieldProps}
      />
      <Menu
        anchorEl={anchorEl}
        id={`${name}-menu`}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <div className={classes.paper}>
          <OptionsList {...props} />
        </div>
      </Menu>
    </Fragment>
  );
};

const isNumber = (value) =>{
  const number = Number(value);
  return !isNaN(number) && typeof number === 'number';
} 

const enhance = compose(
  pure,
  withStyles(styles),
  withState('anchorEl', 'setAnchorEl', null),
  withState('search', 'setSearch', ''),
  withProps(({ selectOptions: allSelectOptions }) => ({ allSelectOptions })),
  withState('selectOptions', 'setSelectOptions', ({ allSelectOptions }) => allSelectOptions),

  withHandlers({
    setAnchorEl: ({ setAnchorEl, disabled }) => e => disabled ? null : setAnchorEl(e),
    setSearch: (props) => value => {
      const {
        setSearch, allSelectOptions, setSelectOptions, labelName, keyName
      } = props;
      
      /* Verify if the user is searching by number or text
      */
      if(isNumber(value)){
        const selectOptionsResult = R.filter(
          R.propSatisfies(R.test(new RegExp(value, 'i')), keyName), allSelectOptions);
          setSelectOptions(selectOptionsResult);  
      }
      else{
        const selectOptionsResult = R.filter(
          R.propSatisfies(R.test(new RegExp(value, 'i')), labelName), allSelectOptions);
          setSelectOptions(selectOptionsResult);
        }  
      setSearch(value);
    },
  }),
);

export default enhance(SearchSelect);
