import React from "react";

export const CashbackIcon = (props) => {
    const { color, width = 81, height = 81 } = props;

    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 81 81"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g filter="url(#filter0_d_3066_5461)">
                <mask id="path-1-outside-1_3066_5461" maskUnits="userSpaceOnUse" x="10" y="5" width="61" height="61" fill="black">
                    <rect fill="white" x="10" y="5" width="61" height="61" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M69 7H12V49.3846H26.6154V64H69V49.3846V26V7Z" />
                </mask>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M69 7H12V49.3846H26.6154V64H69V49.3846V26V7Z" fill={color} />
                <path d="M12 7V5H10V7H12ZM69 7H71V5H69V7ZM12 49.3846H10V51.3846H12V49.3846ZM26.6154 49.3846H28.6154V47.3846H26.6154V49.3846ZM26.6154 64H24.6154V66H26.6154V64ZM69 64V66H71V64H69ZM12 9H69V5H12V9ZM14 49.3846V7H10V49.3846H14ZM26.6154 47.3846H12V51.3846H26.6154V47.3846ZM28.6154 64V49.3846H24.6154V64H28.6154ZM69 62H26.6154V66H69V62ZM67 49.3846V64H71V49.3846H67ZM67 26V49.3846H71V26H67ZM67 7V26H71V7H67Z" fill="white" mask="url(#path-1-outside-1_3066_5461)" />
            </g>
            <defs>
                <filter id="filter0_d_3066_5461" x="0" y="0" width={width} height={height} filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="5" />
                    <feGaussianBlur stdDeviation="5" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3066_5461" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3066_5461" result="shape" />
                </filter>
            </defs>
        </svg>
    )
}