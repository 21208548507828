import service from '../service';


const fetchTradeHistory = async (market) => {
  const options = {
    cmd: 'trade_hist',
    method: 'POST',
  };
  const content = await service(options)({ market });

  return content;
};

export default fetchTradeHistory;
