import { onlyNumbers } from "../../../../utils/regex";
import OTPInput from "react-otp-input";
import React from "react";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { styles } from "./styles";
import{ translate } from "react-i18next";

const CustomPinInput = (props) => {
    const inputSize = 4;
    const { input, classes, t } = props;
    const handlePinChange = (otp) => {
      if (onlyNumbers.test(otp)) {
        input.onChange(otp);
      }
    };
  
    const handleOnPaste= (event) => {
      const data = event.clipboardData.getData('text');
      if (onlyNumbers.test(data) && data.length === inputSize) {
        input.onChange(data);
      }
    };
  
    const renderInputOnChange = (e, inputProps) => {
      const { value } = e.target;
      if (onlyNumbers.test(value)) {
        inputProps.onChange(e);
      }
    };
  
    const renderInputOnPaste = (e, inputProps) => {
      const clipboardData = e.clipboardData || window.Clipboard;
      const pastedData = clipboardData.getData("text");
      if (onlyNumbers.test(pastedData) && pastedData.length >= inputSize) {
        inputProps.onPaste(e);
      } else {
        e.preventDefault();
      }
    }
  
    return (
      <OTPInput
        inputType={"text"}
        inputStyle={classes.pinInput}
        containerStyle={classes.pinInputContainer}
        renderInput={(inputProps, index) => <input {...inputProps} onChange={(e) => {renderInputOnChange(e, inputProps)}} onPaste={(e) => {renderInputOnPaste(e, inputProps)}} aria-label={t("forms.pin.ariaLabel", {index: index + 1})} />}
        value={input.value}
        onChange={handlePinChange}
        numInputs={4}
        onPaste={handleOnPaste}
      />
    );
  };

  const enhance = compose(withStyles(styles), translate());
  
export default enhance(CustomPinInput);