import React, { useEffect } from 'react';
import { compose, withProps } from 'recompose';

import Grid from '@material-ui/core/Grid'; 
import TransfersTable from '../../components/Tables/TransfersTable';
import TransferCard from './components/TransferCard';
import NewTransferForm from '../Transfers/TransferForm';
import TitledPage from '../../components/PageLayouts/TitledPage';
import PageTabs from '../../components/PageLayouts/PageTabs';
import { withStyles } from '@material-ui/core/styles';
import withExtract from './hoc/withExtract';
import { connect } from 'react-redux';
import CreatePixAccountDialog from '../../layout/Dialogs/CreatePixAccountDialog';
import {getPixAccount} from '../../redux/actions';

const getTabs = t => [
  {
    label: t('transactions.deposit.title'),
    value: 'deposit'
  },
  {
    label: t('transactions.withdrawal.title'),
    value: 'withdrawal'
  }
]

const styles = (theme) => ({
  tabStyle: {
    borderRadius: "5px",
    marginLeft: "6px",
    marginRight: "6px",
    marginBottom: "6px",
    transition:
      "filter 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  },
  tabNotSelected: {
    borderRadius: "5px",
    border: `2px solid ${theme.palette.secondary.main}`,

    "&:hover": {
      backgroundColor: "#e6e6e7",
      boxShadow:
        "0px 1px 5px 0px rgba(0, 0, 0, 0.2),0px 2px 2px 0px rgba(0, 0, 0, 0.14),0px 3px 1px -2px rgba(0, 0, 0, 0.12)",
    },
  },
  tabSelected: {
    backgroundColor: theme.palette.secondary.main,
    borderRadius: "5px",

    "&:hover": {
      filter: "brightness(90%)",
      boxShadow:
        "0px 1px 5px 0px rgba(0, 0, 0, 0.2),0px 2px 2px 0px rgba(0, 0, 0, 0.14),0px 3px 1px -2px rgba(0, 0, 0, 0.12)",
    },
  },
  tabNotSelectedText: {
    color: "#696F74",
    textAlign: "center",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
  },
  colorWhite: {
    color: "#FFFFFF",
    textAlign: "center",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
  },
});

const BankTransfers = props => {
  const {
    t,
    extract,
    handleChange,
    transactionRoute,
    location,
    bankList,
    classes,
    account,
    getPixAccount,
    currencies
  } = props
    useEffect(() => {
      if (account.exists && account.info === null) {
        getPixAccount();
      }
    }, [account]);
  return (
    <React.Fragment>
      {transactionRoute === "withdrawal" && <CreatePixAccountDialog />}
      <TitledPage title={t('screens.bankTransfers.title')}>
        <Grid container>
          <Grid
            justify='center'
            alignItems='center'
            item
            xs={12}
            md={12}
          >
            <PageTabs
              tabs={getTabs(t)}
              selectedTab={transactionRoute}
              onChange={handleChange}
              classes={classes}
            />
            <TransferCard>
              <NewTransferForm
                transferType={transactionRoute}
                location={location}
              />
            </TransferCard>
          </Grid>
          <Grid item xs={12} md={12}>
            <TransfersTable bankList={bankList} transfers={extract || []} currencies={currencies} />
          </Grid>
        </Grid>
      </TitledPage>
    </React.Fragment>
  )
}

const mapStateToProps = (state) => {
  return {
    account: state.user.account,
    currencies: state.currencies.currencies
  };
};

const mapDispatchToProps = (dispatch) => ({
  getPixAccount: (motherName) => dispatch(getPixAccount(motherName)),
});

const enhance = compose(
  withExtract,
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
  withProps(
    ({
      match: {
        params: { transactionRoute = "deposit" },
      },
      history: { replace },
    }) => ({
      transactionRoute,
      handleChange: (e, value) => {
        replace(`/bankTransfers/${value}`);
      },
    })
  )
);

export default enhance(BankTransfers)
