import React from 'react';
import { compose, pure, withProps } from 'recompose';
import { translate } from 'react-i18next';

import { withStyles } from '@material-ui/core/styles';

import AccountListItem from '../components/AccountListItem';
import SelectItemField from '../components/SelectItemField';


const styles = theme => ({
  root: {
    flex: 1,
    paddingBottom: 100,
  },
  fieldItem: {
    padding: 0,
    ...theme.typography.body1,
    fontSize: theme.typography.pxToRem(13),
    color: theme.palette.text.secondary,
  },
});



const AccountListStateField = props => {

  const {
    listItemSecondaryAction, tooltipTitle,
  } = props;


  return (
	<AccountListItem
      primary={<SelectItemField {...props} />}
      listItemSecondaryAction={listItemSecondaryAction}
      secondaryTypographyProps={{ component: 'div' }}
      tooltipTitle={tooltipTitle}
    />

  );
};

const enhance = compose(
  withStyles(styles),
  translate(),
  withProps(({
    primary, t, i18nScope, name,
  }) => {
    if (primary) {
      return { primary };
    }
    if (i18nScope) {
      return {
        primary: t(`${i18nScope}.${name}.label`),
      };
    }
    return {
      primary: t(`forms.${name}.label`),
    };
  }),
  pure,
);

export default enhance(AccountListStateField);
