import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { compose } from 'recompose'
import * as R from 'ramda'

import config from '../config'

const endpoint = config.EXCHANGE_ICONS_ENDPOINT;
const localExchange = config.LOCAL_EXCHANGE_NAME;


const styles = theme => ({
  img: {
    maxWidth: 26,
    marginRight: 15
  },
  imgOrderProgrammed: {
    maxWidth: 26,
    marginRight: 15,
    filter: "invert(13%) sepia(82%) saturate(2969%) hue-rotate(194deg) brightness(93%) contrast(95%)",
  },
  [theme.breakpoints.down('sm')]: {
    img: {
      maxWidth: '20%',
      marginRight: 5
    }
  },
})

const formatExchangeNameToPath = R.pipe(
  R.replace(' ', ''),
  R.toLower,
  R.concat(R.__, '.svg')
)

const ExchangeIcon = props =>{
  const { classes, exchangeName, programmed } = props

  const validExchangeName = exchangeName || localExchange
  const path = formatExchangeNameToPath(validExchangeName)
  const exchangeIconUrl = R.concat(endpoint, path)

  return (
    <img className={programmed ? classes.imgOrderProgrammed : classes.img} src={exchangeIconUrl}  alt=""/>
  )
}

const enhance = compose(
  withStyles(styles)
)

export default enhance(ExchangeIcon)
