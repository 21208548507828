import React from "react";
import { styles } from "./styles";
import {
  withStyles,
  Card,
  CardContent,
  Button,
  Typography,
} from "@material-ui/core";
import { compose } from "recompose";
import { translate } from "react-i18next";
import { redirectToRegister } from "../../../pages/User/Login/utils";
import { connect } from "react-redux";
import { removeCredentials } from "../../../redux/actions";

const DemoAccountFixedCard = (props) => {
  const { classes, t, goToLogin } = props;
  return (
    <Card
      classes={{
        root: classes.cardStyle,
      }}
    >
      <CardContent>
        <div className={classes.contentWrapper}>
          <Typography className={classes.ctaText}>
            {t("info.demoAccount.loginRegisterCta")}
          </Typography>
          <div className={classes.buttonsWrapper}>
            <Button color="secondary" variant="contained" onClick={goToLogin}>
              {t("screens.login.signIn")}
            </Button>
            <Button onClick={redirectToRegister} variant="outlined">
              {t("common.clickHere")}
            </Button>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

const mapDispatchToProps = (dispatch) => ({
  goToLogin: () => dispatch(removeCredentials()),
});

const enhance = compose(
  withStyles(styles),
  translate(),
  connect(null, mapDispatchToProps)
);

export default enhance(DemoAccountFixedCard);
