import React, { Fragment } from 'react';
import { compose } from 'recompose';
import { translate } from 'react-i18next';

import { Link } from 'react-router-dom';
import Divider from '@material-ui/core/Divider';

import { withStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';

const styles = theme => ({
  listItemText: {
    color: theme.palette.grey[400],
  },
  divider: {
    height: 2,
    backgroundColor: theme.palette.grey[600],
  },
});

const AppBarMenuItem = props => {
  const {
    primary,
    classes,
    to,
    setIsOpen,
    icon,
    t,
    divider,
    onClick,
    tReady,
    ...rest
  } = props;
  return (
    <Fragment>
      <ListItem
        button
        {...to && {
          component: Link,
          to,
        }}
        onClick={() => {
          if (onClick) onClick();
          setIsOpen(false);
        }}
        {...rest}
      >
        {icon && <ListItemIcon classes={{ root: classes.listItemText }}>{icon}</ListItemIcon>}
        <ListItemText
          classes={{ primary: classes.listItemText }}
          primary={t(primary)}
        />
      </ListItem>
      {divider && <Divider classes={{ root: classes.divider }} />}
    </Fragment>
  );
};


const enhance = compose(
  withStyles(styles),
  translate(),

);

export default enhance(AppBarMenuItem);
