import React from 'react';
import { Field } from 'redux-form';
import TextFieldComponent from './TextFieldComponent';

const MotherName = (props) => {

  return (
    <Field {...props} 
    	type="text"
    	id='motherNameField'
    	component={TextFieldComponent} />
  );


};

export default MotherName;
