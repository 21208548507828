import React from 'react'
import { compose } from 'recompose'
import { translate } from 'react-i18next'
import {
  useTable,
  usePagination,
  useSortBy,
  useFilters
} from 'react-table'

import Paper from '@material-ui/core/Paper'
import MUITable from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import TablePagination from '@material-ui/core/TablePagination'
import { withStyles } from '@material-ui/core'

import SelectColumnFilter from './SelectColumnFilter'

const style = theme => {
  return {
    dense: {
      paddingLeft: theme.spacing.unit * 0.75,
      paddingRight: theme.spacing.unit * 0.75,
      '&:first-child': {
        paddingLeft: theme.spacing.unit * 1.5,
        paddingRight: theme.spacing.unit * 0.75
      },
      '&:last-child': {
        paddingLeft: theme.spacing.unit * 0.75,
        paddingRight: theme.spacing.unit * 1.5
      }
    }
  }
}

const Table = props => {
  const {
    t,
		classes,
    columns,
		data,
    filterLabelsFns
  } = props

  const defaultColumn = React.useMemo(
    () => ({
      Filter: SelectColumnFilter
    }),
    []
  )

  const {
    getTableProps,
    headerGroups,
    rows, // When supplied data is already paginated
    prepareRow,

    page, // Only the rows for the active page (computed values)
    // canPreviousPage,
    // canNextPage,
    // pageOptions,
    // pageCount,
    gotoPage,
    // nextPage,
    // previousPage,
    setPageSize,
    state: { pageIndex, pageSize }
  } = useTable(
    {
      t,
      columns,
      data,
      initialState: { pageIndex: 0 },
      autoResetPage: false,
      autoResetSortBy: false,
      defaultColumn,
      filterLabelsFns,
      autoResetFilters: false
    },
    useFilters,
    useSortBy,
    usePagination
  )

  return (
    <Paper style={{ overflowX: 'auto' }}>
      <MUITable {...getTableProps()}>
        <TableHead>
          {headerGroups.map(headerGroup => (
            <TableRow {...headerGroup.getHeaderGroupProps()}> {/* eslint-disable-line */}
              {headerGroup.headers.map(column => (
                <TableCell {...column.getHeaderProps(column.getSortByToggleProps())} className={classes.dense}> {/* eslint-disable-line */}
                  <TableSortLabel
                    active={column.isSorted}
                    direction={column.isSortedDesc ? 'desc' : 'asc'}
                  >
                    {column.render('Header')}
                    <div style={{ marginLeft: 12 }}>
                      {column.canFilter ? column.render('Filter') : null}
                    </div>
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody>
          {page.map((row, i) => {
            prepareRow(row)
            return (
              <TableRow {...row.getRowProps()}>{/* eslint-disable-line */}
                {row.cells.map(cell => {
                  return (
                    <TableCell {...cell.getCellProps()} className={classes.dense}>{/* eslint-disable-line */}
                      {cell.render('Cell')}
                    </TableCell>
                  )
                })}
              </TableRow>
            )
          })}
        </TableBody>
      </MUITable>
      <TablePagination
        component='div'
        count={rows.length}
        rowsPerPage={pageSize}
        page={pageIndex}
        labelRowsPerPage={t('common.rowsPerPage')}
        onChangePage={(e, page) => gotoPage(page)}
        onChangeRowsPerPage={e => setPageSize(e.target.value)}
      />
    </Paper>
  )
}

const enhance = compose(
  translate(),
	withStyles(style)
)

export default enhance(Table)
