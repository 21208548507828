import { valueToMd5 } from '../../../utils/valueToMd5';

// [{
//  variant: success | error | warning | info
//  message: string
// }]

const initialState = [];

const updateAlert = alert  => {
  if (alert.message){
    return { ...alert , hash: valueToMd5(alert.message) }
  }
  return alert;
}

const alertsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'UPDATE_ALERT': {
      return action.alert ? [updateAlert(action.alert)] : [];
    }
    default: return state;
  }
};

export default alertsReducer;
