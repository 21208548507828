import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';

import Grid from '@material-ui/core/Grid';

import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  gridItem: {
    padding: theme.spacing.unit * 1.1
  },
});

const enhance = compose(
  // pure,
  withStyles(styles),
);

const GridItem = props => {
  const { classes, children, ...rest } = props;

  return (
    <Grid item xs={12} className={classes.gridItem} {...rest}>
      {children}
    </Grid>
  );
};

GridItem.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default enhance(GridItem);
