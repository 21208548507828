import { connect } from 'react-redux';
import { compose } from 'recompose';
import { translate } from 'react-i18next';

import { readSnack } from '../../redux/actions';

import SnackBarComponent from './SnackBar';


const mapStateToProps = (state) => {
  return {
    snacks: state.layout.snacks,
  };
};

const mapDispatchToProps = dispatch => ({
  readSnack: (message) => dispatch(readSnack(message)),
});

const enhance = compose(
  translate(),
  connect(mapStateToProps, mapDispatchToProps),
);

export default enhance(SnackBarComponent);
