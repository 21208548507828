import { Typography } from "@material-ui/core";
import React from "react";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { translate } from "react-i18next";
import InfoTooltip from "../../components/InfoTooltip";
import { Grid, IconButton } from "@material-ui/core";
import { InfoOutlined, LaunchOutlined } from "@material-ui/icons";

const styles = (theme) => ({
  toolTipCard: {
    display: "flex",
    flex: 1,
    height: "150px",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "start",
    padding: "20px",
    borderRadius: "5px",
    backgroundColor: "#F3F3F3",

    [theme.breakpoints.down("xs")]: {
      width: "100%",
      flexDirection: "column",
    },

    [theme.breakpoints.down("sm")]: {
      aspectRatio: "2 / 3 ",
      maxHeight: "100px",
      padding: "10px",
    },
  },
  card: {
    display: "flex",
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    padding: "20px",
    borderRadius: "5px",
    backgroundColor: "#F3F3F3",
  },
  toolTipLabel: {
    color: "#696F74",
    fontFamily: "Roboto",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
  },
  valueStyle: {
    color: "#101010",
    fontfamily: "Roboto",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
  },
  infoIcon: {
    color: "#696F74",
    fontSize: "16px",
    marginLeft: "2px",
  },
  knowMoreLink: {
    width: "24px",
    height: "24px",
  },
});

const ValueBox = (props) => {
  const {
    value,
    toolTipLabel,
    classes,
    toolTipText,
    knowMoreLink,
    hasToolTip,
  } = props;
  return (
    <Grid className={hasToolTip ? classes.toolTipCard : classes.card}>
      <Typography className={classes.valueStyle}>{value}</Typography>
      {toolTipLabel !== "" && (
        <Grid container alignItems="center">
          <InfoTooltip hideIcon title={toolTipText} wrapperRootHeader={true}>
            <Grid container alignItems="center">
              <Typography className={classes.toolTipLabel}>
                {toolTipLabel}
              </Typography>
              <InfoOutlined className={classes.infoIcon} />
            </Grid>
          </InfoTooltip>
          {knowMoreLink && (
            <IconButton
              href={knowMoreLink}
              className={classes.knowMoreLink}
              target={"_blank"}
            >
              <LaunchOutlined className={classes.infoIcon} />
            </IconButton>
          )}
        </Grid>
      )}
    </Grid>
  );
};

const enhance = compose(withStyles(styles), translate());

ValueBox.propTypes = {
  value: PropTypes.string.isRequired,
  toolTipLabel: PropTypes.string,
  toolTipText: PropTypes.string,
  knowMoreLink: PropTypes.string,
  hasToolTip: PropTypes.bool,
};

ValueBox.defaultProps = {
  padding: "30px",
  toolTipLabel: "",
  hasToolTip: false,
};
export default enhance(ValueBox);
