import { combineReducers } from 'redux';
import bankAccount from './bankAccount/reducer';
import documents from './documents/reducer';
import balance from './userBalance/reducer';
import profile from './profile/reducer';
import limits from './limits/reducer';
import extract from './extract/reducer';
import fees from './fees/reducer';
import account from './account/reducer'
import cards from './card/reducer';
import mgm from './mgm/reducer'


const initialState = {
  profile: { email: "" },
  extract: { extracts: [] },
  cards: {
    plastic: null,
    virtual: null,
    hasCard: false,
    loading: true,
    cardLimits: null,
    cashback: [],
    cashbackInfo: {
      isLoading: true,
      user_id: null,
      card_volume: null,
      total_refs: null,
      total_activated_refs: null,
      activated_refs_90d: null,
      activated_refs_waitlist: null,
      cashback_percentage: null,
      cashback_composition: {
        card_volume: null,
        first_month: null,
        mgm: null,
        waitlist: null,
        total: null,
        marketing_event: null
      },
      next_card_volume_level: {
        target_volume: null,
        missing_volume: null,
        next_percentage: null,
      },
      next_mgm_level: {
        target_references: null,
        missing_references: null,
        next_percentage: null,
      },
      last_update: null,
    },
  },
  mgm: { referrals: [] },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'REMOVE_CREDENTIALS':
      return initialState;

    default:
      return combineReducers({
        account,
        profile,
        cards,
        mgm,
        extract,
        balance,
        fees,
        limits,
        bankAccount,
        documents,
      })(state, action);
  }
};

export default reducer;
