import * as Yup from 'yup';
import WAValidator from 'wallet-address-validator';
import { isBBAccountDigitValid } from '../../../utils/validateBankAccount';

import {
  TRANSFER_TYPES,
} from './constants';

const TransferFormSchema = Yup.object().shape({
  amount: Yup.number()
    .required('REQUIRED')
    .when('$transferType', {
      is: TRANSFER_TYPES.WITHDRAWAL,
      then: Yup.number()
        .when(['$currentBalance', '$availableLimit'], (balance, limit, schema) => {
          return balance < limit
            ? schema.max(balance, 'NOT_ENOUGH_BALANCE')
            : schema.max(limit, 'NOT_ENOUGH_LIMIT');
        })
        .when('$minWithdrawal', (minWithdrawal, schema) => {
          return schema.min(minWithdrawal, { error: 'MIN_WITHDRAWAL', min: minWithdrawal });
        }),
      otherwise: Yup.number()
        .when('$availableLimit', (limit, schema) => schema.max(limit, 'NOT_ENOUGH_LIMIT')),
    })
    .when('$transferType', {
      is: TRANSFER_TYPES.DEPOSIT,
      then: Yup.number().min(10, 'MIN_VALUE_10'),
    }),

  // Withdrawal cripto
  destination: Yup.object().shape({
    address: Yup.string()
      .when('$shouldValidateWithdrawalAddress', {
        is: true,
        then: Yup.string()
          .when(['$currency', '$blockchain'],
            (currency, blockchain, schema) => {
              return schema.test('isAddressValid',
                { error: 'STABLECOIN_INVALID_ADDRESS', currency },
                value => {
                  if (blockchain === 'SOL') { return value.length >= 32 && value.length <= 44; }
                  return WAValidator.validate(value, 'ETH');
                });
            }),
      })
      .when('$shouldValidatePixAddress', {
        is: true,
        then: Yup.string()
          .test('isPixAddressValid', 'INVALID_PIX_ADDRESS', value => {
            const regexTest = new RegExp(/[^a-zA-Z0-9_\-\–\—@.]/g);
            return !regexTest.test(value);
          })
          .min(11, { error: 'MIN_PIX_LENGTH_REQUIRED', min: 11 }),
      }).required('REQUIRED'),
  }),

  // Confirm disclaimers withdrawal cripto
  agreeConvertToCripto: Yup.boolean()
    .when('$shouldValidateWithdrawalCriptoDisclaimers ', {
      is: true,
      then: Yup.boolean().oneOf([true]),
    }),
  agreeConfirmAddress: Yup.boolean()
    .when('$shouldValidateWithdrawalCriptoDisclaimers ', {
      is: true,
      then: Yup.boolean().oneOf([true]),
    }),
  agreeIN1888: Yup.boolean()
    .when('$shouldValidateWithdrawalCriptoIN1888', {
      is: true,
      then: Yup.boolean().oneOf([true]),
    }),
  agreeDepositCriptoTax: Yup.boolean()
    .when('$shouldValidateDepositCriptoTax', {
      is: true,
      then: Yup.boolean().oneOf([true]),
    }),

  // BB
  agency: Yup.string()
    .when('$shouldValidateAgencyAndAccount', {
      is: true,
      then: Yup.string().required('REQUIRED'),
    }),
  account: Yup.string()
    .when('$shouldValidateAgencyAndAccount', {
      is: true,
      then: Yup.string()
        .required('REQUIRED')
        .test('isDigitValid', 'INVALID_DIGIT', value => {
          return isBBAccountDigitValid(value);
        }),
    }),
});

export default TransferFormSchema;
