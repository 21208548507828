import React from 'react';
import PropTypes from 'prop-types';
import { compose, pure } from 'recompose';

import { translate } from 'react-i18next';

import { withStyles } from '@material-ui/core/styles';
import ListItemText from '@material-ui/core/ListItemText';
import { Typography } from '@material-ui/core';
import DrawerListItem from './DrawerListItem';

const styles = theme => {
  return {
    label: {
      color: theme.palette.secondary.main,
      fontWeight: 500,
    },
    textRoot: {
      paddingRight: 0,

    },
    content: {
      color: theme.palette.grey[400],
      // display: 'flex',
      flex: 1,
      textTransform: 'uppercase',
      marginLeft: 16,
      paddingRight: 0,

      '&:first-child': {
        marginLeft: 0,

      },
    },
  };
};

const DrawerItem = ({
  label, leftText, classes, rightText, onClick, button, active, customLeftTextClass, customRightTextClass
}) => {
  return (
    <DrawerListItem
      onClick={onClick}
      button={button}
      active={active}
    >
      <ListItemText
        classes={{
          root: classes.textRoot,
          primary: classes.content ,
          secondary:classes.content,
        }}
        primary={
          <Typography
            variant="subheading"
            className={classes.label}
          >
            {label}
          </Typography>
        }
        disableTypography
        secondary={
          <div style={{
            display: 'flex',
            flex: 1,
            // justifyContent: 'space-between',
          }}
          >
            <Typography
              variant="body1"
              align="left"
              noWrap
              className={customLeftTextClass ? customLeftTextClass : classes.content }
            >
              {leftText}
            </Typography>
            <Typography
              variant="body1"
              align="left"
              noWrap
              className={customRightTextClass ? customRightTextClass : classes.content}
            >
              {rightText}
            </Typography>

          </div>
        }
      />
    </DrawerListItem>
  );
};


DrawerItem.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

const enhance = compose(
  // withRouter,
  translate(),
  pure,
  withStyles(styles),
);

export default enhance(DrawerItem);
