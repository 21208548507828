import * as R from 'ramda';
import serializeBank from '../../../utils/serializeBankAccount';
import { DEPOSIT_BANK_INFO } from '../../../config';

const initialState = {};

const updateBankList = (state, { response }) => {
  const { bankCode, branch, account, defaultPixKey } = response[0];
  const address = `${bankCode}|${branch}|${account}`
  const bankList = { address: address}
  const formattedAddress = R.reject(R.isEmpty, R.map(serializeBank, bankList));
  const bankInfo = {
    address: {
      pix:defaultPixKey,
      ...formattedAddress.address,
    }
  }
  return R.merge(state, bankInfo);
};


const bankListReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_SERVICE_SUCCEED':
      switch (action.requestType) {
        case 'fetchBankList':
          return updateBankList(state, action);

        default:
          return state;
      }
    default:
      return state;
  }
};

export default bankListReducer;
