import * as R from 'ramda';
import * as actions from './actionTypes';

const initialState = {
  anchorEl: null,
  open: false,

};

const handleAppBarMenu = (state, anchorEl, currencySelectorFilter, extraData) => {
  return R.merge(state, { anchorEl, open: Boolean(anchorEl), currencySelectorFilter: currencySelectorFilter || false, extraData: extraData || {} },);
};

const layoutReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.HANDLE_APP_BAR_MENU:
      return handleAppBarMenu(state, action.anchorEl, action.currencySelectorFilter, action.extraData);

    default:
      return state;
  }
};

export default layoutReducer;
