import React, {useState, useEffect} from 'react';
import Chart from "react-apexcharts";
import {withStyles} from "@material-ui/core/styles";
import { Currency } from '../../models/Currency';
import { getUserCurrencies } from '../../utils/currencies';
import { isObjectMapEmpty } from '../../utils/objectFunctions';
import ActivityIndicator from "../../components/ActivityIndicator";


const styles = _theme => ({
    pie: {
        "& svg foreignObject > div": {
            overflow: 'visible',
            width: '125px',
            flexWrap: 'wrap',
            display: 'flex',
        }
    }
})

const PieChart = ({ userBalance, ticker, classes, currencies }) => {
    const [series, setSeries] = useState([]);
    const [labels, setLabels] = useState([]);
    const [colors, setColors] = useState([]);
    const criptoCurrencies = getUserCurrencies(userBalance, currencies, ticker, { includeFiat: true});
    const isLoading = isObjectMapEmpty(ticker);


    useEffect(() => {
      setSeries((_series) => []);
      setLabels((_labels) => []);
      setColors((_colors) => []);
      for (let index in criptoCurrencies) {
        const currency = new Currency(criptoCurrencies[index]);
        const currencySymbol = currency.symbol;
        const currencyAmount = userBalance[currencySymbol] || 0;
        const currencyLocked = userBalance[`${currencySymbol}_locked`] || 0;
        const totalCurrency = currencyAmount + currencyLocked;
        const lastBrlTicker = ticker[currencySymbol] && ticker[currencySymbol].last
          ? ticker[currencySymbol].last
          : 0;
        const totalBrl = currency.symbol === "BRL" ? totalCurrency : Number((totalCurrency * lastBrlTicker).toFixed(2));
        const color = currency.color;
        if (totalCurrency > 0) {
          setSeries((series) => [...series, totalBrl]);
          setLabels((labels) => [...labels, currencySymbol]);
          setColors((colors) => [...colors, color]);
        }
      }
    }, [userBalance, ticker, currencies]);

    const options = {
        dataLabels: {
            enabled: true
        },
        colors: colors,
        labels,
        responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                    width: 200
                },
                legend: {
                    show: false,
                }
            }
        }],
        legend: {
            labels: {colors:'#fafafa'},
            position: 'right',
            offsetY: 0,
            height: 190,
            fontSize: 12
        },
        plotOptions: {
            pie: {
                donut: {
                    labels: {
                        show: true,
                        name: {
                            show: true,
                            fontSize: '16px',
                            color: '#fafafa',
                        },
                        value: {
                            show: true,
                            fontSize: '12px',
                            color: '#fafafa',
                            formatter: function (w) {
                                return `R$ ${w}`
                            }
                        }
                    }
                }
            }
        }
    };

    return !isLoading ? (
        
        <div className={classes.pie}>
            <Chart options={options} series={series} type="donut" width={380}/>
        </div>
    ) : <ActivityIndicator color="secondary" size={40}/>
}

export default withStyles(styles)(PieChart);
