import React from 'react'
import ActivityIndicator from "../../../../components/ActivityIndicator";

// HOC
const OrderBookFactory = (
  OrderBookFrame,
  OrderBookHeader,
  OrderBookTable,
  OrderBookTableRow
) => props => {
  const {
    t,
    ordersType,
    orders,
    minRows,
    copyRow,
    isShowAllSelected,
    setIsShowAllSelected,
    confirmCancelOrder,
    myActiveOrders,
    updatingApplicationData
  } = props

  return (
    <OrderBookFrame
      ordersType={ordersType}
      isShowAllSelected={isShowAllSelected}
      setIsShowAllSelected={setIsShowAllSelected}
      myActiveOrders={myActiveOrders}
    >
      { updatingApplicationData ? <ActivityIndicator color="secondary" size={16}/> :
          <OrderBookTable
              updatingApplicationData={updatingApplicationData}
              t={t}
              ordersType={ordersType}
              orders={orders}
              minRows={minRows}
              OrderBookTableRow={OrderBookTableRow}
              copyRow={copyRow}
              confirmCancelOrder={confirmCancelOrder}
              isShowAllSelected={isShowAllSelected}
          />
      }

    </OrderBookFrame>
  )
}

export default OrderBookFactory
