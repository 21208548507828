import React from 'react';
import { compose, pure } from 'recompose';
import { translate } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';

import DocumentsForm from './components/DocumentsForm';
import OpLimitsList from './OpLimitsList';
import { connect } from 'react-redux';
import BannerKYCNotOk from './components/BannerKYCNotOk';
import BannerKYCOk from './components/BannerKYCOk';

const styles = _theme => ({
  root: {
    flexGrow: 1,
  },
  flex: {
    flexGrow: 1,
  },
});

const OperationalLimits = props => {
  const {
    classes,
    user,
  } = props;
  const { profile: { company, kyc_level } } = user;

  return (
    <div className={classes.root}>
      {kyc_level && (
        kyc_level < 2 ? (
          company === 1 ? (
          <DocumentsForm /> 
          ) : (
            <BannerKYCNotOk />  
          )
        ) : (
          <BannerKYCOk/>
        )
      )}
      
      <OpLimitsList />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

const enhance = compose(
  connect(mapStateToProps),
  translate(),
  withStyles(styles),
  pure,
);

export default enhance(OperationalLimits);
