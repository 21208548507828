import React from "react";
import PropTypes from "prop-types";
import LinearProgress from "@material-ui/core/LinearProgress";
import withStyles from "@material-ui/core/styles/withStyles";
import { Grid } from "@material-ui/core";

const bityProgressBarStyle = {
  stepper: {
    display: "flex",
    flexFlow: "row nowrap",
    justifyContent: "space-around",
    padding: 0,
    width: "100%",
    margin: "5px 0",
  },

  stepper__step: {
    position: "relative",
    display: "flex",
    flexFlow: "row nowrap",
    justifyContent: "space-around",
    alignItems: "center",
    width: "100%",
  },

  stepper__step__label: {
    color: "#62560A",
    textAlign: "center",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    letterSpacing: "-0.22px",
    margin: 0,
    opacity: "0.5",
  },

  labelContainer: {
    display: "flex",
    flexFlow: "column nowrap",
    alignItems: "center",
  },

  linearProgressBorderRadiusLeft: {
    flex: "1 1 auto",
    borderRadius: "20px 0px 0px 20px",
    height: "5px",
    borderRight: "1px solid white",
  },

  linearProgressBorderRadiusRight: {
    flex: "1 1 auto",
    borderRadius: "0px 20px 20px 0px",
    height: "5px",
  },

  borderedLinearProgress: {
    flex: "1 1 auto",
    borderRadius: "0px",
    height: "5px",
    borderRight: "1px solid white",
  },

  bar: {
    background: "#F7B733",
  },
};

function BityProgressBar(props) {
  const { progress, classes, levelsAmount, steps, max } = props;

  const ProgressBar = ({
    step,
    progress,
    hasLeftBorderRadius,
    hasRightBorderRadius,
  }) => {
    return (
      <LinearProgress
        variant="determinate"
        value={progress}
        classes={{
          root: hasRightBorderRadius
            ? classes.linearProgressBorderRadiusRight
            : hasLeftBorderRadius
            ? classes.linearProgressBorderRadiusLeft
            : classes.borderedLinearProgress,
          bar: classes.bar,
        }}
      />
    );
  };

  function renderProgressBarLevel(key, { barProgress, levelsAmount }) {
    const done = key + 1 === levelsAmount;

    return (
      <ProgressBar
        step={key}
        key={key}
        progress={barProgress}
        hasLeftBorderRadius={key === 0}
        hasRightBorderRadius={done}
      />
      // </li>
    );
  }

  function renderStep(label, key) {
    return (
      <div className={classes.labelContainer} key={key}>
        <p className={classes.stepper__step__label}>{label}</p>
      </div>
    );
  }

  const progressBarValue = max / levelsAmount;
  let filledLevels = progress / progressBarValue;

  let barLevels = [];
  for (let i = 0; i < levelsAmount; i++) {
    let barProgress = 0;
    if (filledLevels > 0) {
      barProgress =200;
      filledLevels--;
    }
    barLevels.push(renderProgressBarLevel(i, { barProgress, levelsAmount }));
  }

  return (
    <Grid container direction="column">
      <ul className={classes.stepper}>{barLevels}</ul>
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
      >
        {steps.map(renderStep, { progress, progressBarValue })}
      </Grid>
    </Grid>
  );
}

BityProgressBar.propTypes = {
  classes: PropTypes.object.isRequired,
  steps: PropTypes.array.isRequired,
  max: PropTypes.number.isRequired,
  progress: PropTypes.number,
  levelsAmount: PropTypes.number,
  hasRightBorderRadius: PropTypes.bool,
  hasLeftBorderRadius: PropTypes.bool,
};

export default withStyles(bityProgressBarStyle)(BityProgressBar);
