import React from 'react'

import ShowMineButton from '../../../../components/Transactions/ShowMineButton'
import ShowAllButton from '../../../../components/Transactions/ShowAllButton'
import SwitchButtonWrapper from '../../../../components/Buttons/SwitchButtonWrapper'


const OrderBookHeaderActions = ({ isShowAllSelected, setIsShowAllSelected, myActiveOrders }) => (
  <SwitchButtonWrapper>
    <ShowMineButton
      showAll={isShowAllSelected}
      setShowAll={setIsShowAllSelected}
      myActiveOrders={myActiveOrders}
    />
    <ShowAllButton
      showAll={isShowAllSelected}
      setShowAll={setIsShowAllSelected}
    />
  </SwitchButtonWrapper>
)

export default OrderBookHeaderActions
