import {
  AVAILABLE_NETWORK,
  getSolBlockExplorer,
  getEthBlockExplorer,
  getBtcBlockExplorer,
  getAdaBlockExplorer,
  getLunaBlockExplorer,
  getTronBlockExplorer,
} from "../config";

const stableBrzCoinsExplorer = ["CBRL", "REALT", "BRZ", "RAS"];
const solCoinsExplorer = ['SOL', 'POLIS', 'ATLAS', 'USDC', 'USDBP'];
const lunaCoinsExplorer = ["LUNA", "LUNC"];

export class Currency {
  constructor({
    symbol,
    name,
    plural_name,
    monetary_symbol,
    color,
    exhibit_precision,
    is_fiat,
    active,
    withdrawal,
    deposit,
    formatter,
    type,
    isPublic,
  }) {
    this.symbol = symbol;
    this.name = name;
    this.pluralName = plural_name;
    this.monetarySymbol = monetary_symbol.split("").join("");
    this.color = color;
    this.exhibitPrecision = exhibit_precision;
    this.isFiat = is_fiat;
    this.active = active;
    this.withdrawal = getWithdrawalObjects(withdrawal);
    this.deposit = getDepositObjects(deposit);
    this.formatter = formatter || this._defaultFormatter();
    this.type = type;
    this.isPublic = isPublic;
  }

  get currencyLabel() {
    return `${this.name} (${this.symbol})`;
  }

  get hasDeposit() {
    return this.deposit.length > 0;
  }

  get hasWithdrawal() {
    return this.withdrawal.length > 0;
  }

  hasWithdrawalNetwork(network) {
    return this.withdrawal.some((withdrawal) => withdrawal.network == network);
  }

  hasDepositNetwork(network) {
    return this.deposit.some((deposit) => deposit.network == network);
  }

  get getWithdrawalBlockchainOptions() {
    let options = [];
    this.withdrawal.forEach((withdrawal) => {
      options.push({
        value: withdrawal.network,
        label: AVAILABLE_NETWORK[withdrawal.network] || withdrawal.network,
      });
    });
    return options;
  }

  getWithdrawalInfoByNetwork(network) {
    return this.withdrawal.find((withdrawal) => withdrawal.network == network);
  }

  getDepositInfoByNetwork(network) {
    return this.deposit.find((deposit) => deposit.network == network);
  }

  getExhibitionName(maxLength = 10) {
    return this.name.length > maxLength ? this.symbol : this.name;
  }

  get getDepositBlockchainOptions() {
    let options = [];
    this.deposit.forEach((deposit) => {
      options.push({
        value: deposit.network,
        label: AVAILABLE_NETWORK[deposit.network] || deposit.network,
      });
    });
    return options;
  }

  get blockExplorerUrl() {
    const symbol = this.symbol;
    let url = getEthBlockExplorer;
    if (symbol === "BRL") url = null;
    else if (stableBrzCoinsExplorer.includes(symbol)) url = getEthBlockExplorer;
    else if (symbol === "BTC") url = getBtcBlockExplorer;
    else if (symbol === "ADA") url = getAdaBlockExplorer;
    else if (solCoinsExplorer.includes(symbol)) url = getSolBlockExplorer;
    else if (lunaCoinsExplorer.includes(symbol)) url = getLunaBlockExplorer;
    else if (symbol === "TRON") url = getTronBlockExplorer;

    return url;
  }

  get placeholder() {
    return `0,${"0".repeat(this._defaultFormatter().decimalScale)}`;
  }

  set setFormatter(formatter) {
    this.formatter = formatter;
  }

  _defaultFormatter() {
    if (this.symbol === "BRL") {
      return {
        prefix: "R$ ",
        decimalSeparator: ",",
        thousandSeparator: ".",
        decimalScale: 2,
        fixedDecimalScale: true,
      };
    } else if (this.symbol === "CBRL") {
      return {
        prefix: `${this.monetarySymbol} `,
        decimalSeparator: ",",
        thousandSeparator: ".",
        decimalScale: 2,
        fixedDecimalScale: true,
      };
    } else if (this.symbol === "USD") {
      return {
        prefix: `${this.monetarySymbol} `,
        decimalSeparator: ",",
        thousandSeparator: ".",
        decimalScale: 2,
        fixedDecimalScale: true,
      };
    } else if (["USDT", "BUSD", "USDC"].includes(this.symbol)) {
      return {
        prefix: `${this.monetarySymbol} `,
        decimalSeparator: ",",
        thousandSeparator: ".",
        decimalScale: 4,
        fixedDecimalScale: true,
      };
    } else if (this.is_fiat) {
      return {
        prefix: `${this.monetarySymbol} `,
        decimalSeparator: ",",
        thousandSeparator: ".",
        decimalScale: 2,
        fixedDecimalScale: true,
      };
    } else {
      return {
        prefix: `${this.monetarySymbol} `,
        decimalSeparator: ",",
        thousandSeparator: ".",
        decimalScale: 8,
        fixedDecimalScale: true,
      };
    }
  }

  toJson() {
    return {
      symbol: this.symbol,
      name: this.name,
      plural_name: this.pluralName,
      monetary_symbol: this.monetary_symbol,
      color: this.color,
      exhibit_precision: this.exhibitPrecision,
      is_fiat: this.is_fiat,
      active: this.active,
      withdrawal: this.withdrawal.map((v) => v.toJson()),
      deposit: this.deposit.map((v) => v.toJson()),
    };
  }
}

class WithdrawalInfo {
  constructor({
    network,
    fixed_fees,
    fixed_fee,
    percent_fee,
    min_amount,
    disabled,
    memo_enabled,
  }) {
    this.network = network;
    this.fixedFees = fixed_fees ?  new FixedFees(fixed_fees) : null
    this.fixedFee = !isNaN(fixed_fee) ? fixed_fee : 0;
    this.percentFee = percent_fee;
    this.minAmount = min_amount;
    this.disabled = disabled;
    this.memoEnabled = memo_enabled;
  }

  toJson() {
    return {
      network: this.network,
      fixed_fees: this.fixedFees.toJson(),
      percent_fee: this.percentFee,
      min_amount: this.minAmount,
      disabled: this.disabled,
      memo_enabled: this.memoEnabled,
    };
  }

  get fixedFeesArray() {
    return this.fixedFees
      ? [
          new WithdrawalFee("high", this.fixedFees.high),
          new WithdrawalFee("medium", this.fixedFees.medium),
          new WithdrawalFee("low", this.fixedFees.low),
        ]
      : [];
  }

  get hasSameFixedFees() {
    return (
      this.fixedFees && this.fixedFeesArray.every((fee) => fee.fee == this.fixedFeesArray[0].fee)
    );
  }

  get fixedFeesJson() {
    return this.fixedFees
      ? {
          high: this.fixedFees.high,
          medium: this.fixedFees.medium,
          low: this.fixedFees.low,
        }
      : {};
  }
}
export class DepositInfo {
  constructor({
    network,
    fixed_fees,
    fixed_fee,
    percent_fee,
    min_amount,
    disabled,
    memo_enabled,
  }) {
    this.network = network;
    this.fixedFees = fixed_fees ? new FixedFees(fixed_fees) : !isNaN(fixed_fee) ? fixed_fee : 0;
    this.percentFee = percent_fee;
    this.minAmount = min_amount;
    this.disabled = disabled;
    this.memoEnabled = memo_enabled;
  }

  toJson() {
    return {
      network: this.network,
      fixed_fees: this.fixedFees,
      percent_fee: this.percentFee,
      min_amount: this.minAmount,
      disabled: this.disabled,
      memo_enabled: this.memoEnabled,
    };
  }
}

class FixedFees {
  constructor({ high, medium, low }) {
    this.high = high;
    this.medium = medium;
    this.low = low;
  }

  toJson() {
    return {
      high: this.high,
      medium: this.medium,
      low: this.low,
    };
  }
}

class WithdrawalFee {
  constructor(priority, fee) {
    this.priority = priority;
    this.fee = fee;
  }
}

export const getCriptoCurrencies = (currencies) => {
  return currencies.filter((currency) => !currency.is_fiat);
};

export const getCurrencyBySymbol = (currencies, symbol) => {
  return currencies.find((currency) => currency.symbol === symbol);
};

export const getCurrenciesByType = (currencies, markets, isMarketPlace) => {
  let currenciesByType = [];
  for (let market in markets) {
    const currency = currencies.find(
      (currency) => currency.symbol === markets[market].base
    );
    if (currency && markets[market].is_marketplace === isMarketPlace) {
      currenciesByType.push(currency);
    }
  }
  return currenciesByType;
};

const getWithdrawalObjects = (withdrawalsList) => {
  let withdrawalArray = [];
  if(Array.isArray(withdrawalsList)) {
  withdrawalsList.forEach((withdrawalItem) => {
    withdrawalArray.push(new WithdrawalInfo(withdrawalItem));
  });
}
  return withdrawalArray;
}

const getDepositObjects = (depositArray) => {
  let depositObjects = [];
  if (Array.isArray(depositArray))
    depositArray.forEach((deposit) => {
      depositObjects.push(new DepositInfo(deposit));
    });
  return depositObjects;
}
