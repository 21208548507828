import React from 'react';
import { compose, pure } from 'recompose';

import { withStyles } from '@material-ui/core/styles';
import AccountListItem from '../components/AccountListItem';
import BornDatePickerField from '../../../components/Fields/BornDatePickerField';

const styles = theme => ({
  root: {
    flex: 1,
    paddingBottom: 100,
  },
  fieldItem: {
    padding: 0,
    ...theme.typography.body1,
    fontSize: theme.typography.pxToRem(13),
    color: theme.palette.text.secondary,
  },
});

const AccountListDateField = props => {
  const {
    primary,
    canEdit,
    name,
    secondary,
    classes,
    pendingSecondary,
    ...other
  } = props;
  return (
    <AccountListItem
      primary={(
        <BornDatePickerField
          required
          // disableUnderline
          // inputProps={{ style: { padding: 0 } }}
          disabled={!canEdit}
          // classes={{
          //   input: classes.fieldItem,
          // }}
          {...other}
          name={name}
        />
      )}
      secondaryTypographyProps={{ component: 'div' }}
    />
  );
};

const enhance = compose(
  withStyles(styles),
  pure,
);

export default enhance(AccountListDateField);
