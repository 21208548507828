import React from 'react';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core/styles';
import { translate } from 'react-i18next';
import DangerouslySetInnerHTML from '../DangerouslySetInnerHTML';
import icon from '../../assets/icon-antiphishing.svg'

const styles = theme => ({
    container: {
        backgroundColor: theme.palette.secondary.main,
        maxWidth: '380px',
        borderRadius: '10px',
        margin: '30px auto 0 auto',
        display: 'flex',
        alignContent: 'center',
        padding: '23px 34px',
        gap: '15px',
        [theme.breakpoints.down('sm')]: {
            maxWidth: '340px',
            padding: '19px 27px',
        },
    },
    content: {
        textAlign: "left",
        fontFamily: "'Roboto','Helvetica', 'Arial', 'sans-serif'",
        fontSize: '10pt',
        letterSpacing: "0px",
        color: "#101010",
        [theme.breakpoints.down('sm')]: {
            fontSize: '9pt',
        },
    },
    span:{
        fontWeight: 600,
    }
  });

  const BannerAntiPhishing = (props) => {
        const {
           classes,
           t,
        } = props;

        const title = window.location.host === 'market.bitypreco.com' ?
            t('bannerAntiPhishing.bitypreco') : t('bannerAntiPhishing.bitpreco');

        return (
            <div className={classes.container}>
               <div>
                <img src={icon} alt="" />
               </div>
               <DangerouslySetInnerHTML className={classes.content} title={title} />
            </div>

        );
  }

  const enhance = compose(
    translate(),
    withStyles(styles, { withTheme: true })
  );


  export default enhance(BannerAntiPhishing);

