import React from 'react';
import * as R from 'ramda';
import { compose, pure, withHandlers, withProps, withState } from 'recompose';
import { connect } from 'react-redux';
import moment from 'moment';
import { avoidDangerouslyChars } from '../../../utils/regex';

import { translate } from 'react-i18next';
import { reduxForm } from 'redux-form';

import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';

import AccountListItemField from '../components/AccountListItemField';
import AccountListBasicDateField from './AccountListBasicDateField';
import AccountListTaxidField from './AccountListTaxidField';
import AccountListPhoneField from './AccountListPhoneField';
import AccountListStateField from './AccountListStateField';
import AccountListPostalCodeField from './AccountListPostalCodeField';
import CheckBoxField from '../../../components/Fields/CheckBoxField'
import DangerouslySetInnerHTML from '../../../components/DangerouslySetInnerHTML';

import validate from '../../../utils/validate';
import AccountListItem from '../components/AccountListItem';

import SubmitButton from '../../../components/Forms/SubmitButton';
import { VARIANT } from '../../../config';

import { updateProfileRequested } from '../../../redux/actions';
import isNilOrEmpty from '../../../utils/isNilOrEmpty';

const EXCHANGE = VARIANT === 'EXCHANGE';

const styles = theme => ({
  '@media screen and (min-width: 600px)': {
    list: {
        display: 'flex',
        flexDirection: 'column',
        padding: 0,
    },
  },
  list: {
    flexDirection: 'row',
  },
  root: {
    flex: 1,
    paddingBottom: 100,
  },
  fieldItem: {
    padding: 0,
    ...theme.typography.body1,
    fontSize: theme.typography.pxToRem(13),
    color: theme.palette.text.secondary
  },
  submitDiv: {
    marginLeft: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 2
  }
});

const getPep = (pep = false) => {
  return Boolean(Number(pep))
}

const getBirthFormatted = (birthString = '') => {
  const isValid = moment(birthString).isValid();
  if (!isValid) {
    return null;
  }
  return moment(birthString, 'YYYY-MM-DD').format('DD/MM/YYYY');
};

const getRequestBirthFormatted = (birthString = '') => {
  return moment(birthString, 'DD-MM-YYYY').format('YYYY-MM-DD');
};

const getFormattedPhone = (phoneString = '')=>{
  if(phoneString && phoneString.length>11)
    return phoneString.substring(3,phoneString.length);
  return ''
}

const GeneralInfoForm = props => {
  const {
    t,
    classes,
    profile,
    handleSubmit,
    edited,
    invalid,
    birth,
    kycLevelOk,
    initialValues,
    disableSave
  } = props;

  return (
    <form onSubmit={handleSubmit}>

          <List className={classes.list}>

            <AccountListItemField
              primary={t('account.holder')}
              secondary={profile.name}
              name="name"
              canEdit={isNilOrEmpty(profile.name)}
            />

            <AccountListBasicDateField
              name="birth"
              secondary={birth}
              canEdit={isNilOrEmpty(profile.birth) || !kycLevelOk}
            />

            <AccountListTaxidField
              name="tax_id"
              canEdit={isNilOrEmpty(profile.tax_id) || !kycLevelOk}
              placeholder={t('common.notIncluded')}
            />
          </List>

          <List className={classes.list}>
            <AccountListPhoneField
              name="phone"
              canEdit={false}
              placeholder={t('common.notIncluded')}
            />

            <AccountListItemField
              secondary={profile.email}
              name="email"
              canEdit={isNilOrEmpty(profile.email)}
            />
          </List>

          <List className={classes.list}>
            <AccountListItemField
              secondary={profile.address}
              name="address"
              canEdit={isNilOrEmpty(profile.address) || !kycLevelOk}
            />

            <AccountListItemField
              secondary={profile.city}
              name="city"
              canEdit={isNilOrEmpty(profile.email) || !kycLevelOk}

            />

            <AccountListStateField
              secondary={profile.state}
              name="state"
              canEdit={isNilOrEmpty(profile.state) || !kycLevelOk}
            />

            <AccountListPostalCodeField
              secondary={profile.postalcode}
              name="postalcode"
              canEdit={isNilOrEmpty(profile.postalcode) || !kycLevelOk}
            />
          </List>
          {/* <List className={classes.list}>
            <div style={{marginLeft: 24}}>
              <CheckBoxField
                disabled={initialValues.pep}
                name="pep"
                label={<DangerouslySetInnerHTML title={t('forms.politicallyExposedPerson.label')} />}
              />
            </div>
          </List> */}

          {EXCHANGE && (
            <AccountListItem
              primary={t('account.kycLevel')}
              secondary={kycLevelOk ? t('common.validated') : t('common.pending')}
            />
          )}
          <div className={classes.submitDiv}>
            <SubmitButton
            submitText={t('common.save')}
            disabled={edited || invalid || disableSave}
            />
          </div>

    </form>
  );
};

const fieldsList = ['name', 'birth', 'tax_id','phone', 'email', 'address', 'city', 'state', 'postalcode', 'pep'];



const mapStateToProps = state => {
  return ({
  profile: state.user.profile
});}

const schema = {
  name: {
    type: String,
    regEx: avoidDangerouslyChars,
  },
  email: {
    type: String,
    regEx: avoidDangerouslyChars,
  },
  tax_id: {
    type: String,
    required: true,
    regEx: /^(?=[0-9]*$)(?:.{11}|.{14})$/,
  },
  phone: {
    type: String,
    required: false,
  },
  birth: {
    required: true,
    type: String,
  },
  address: {
    type: String,
    required: true,
    regEx: avoidDangerouslyChars,
  },
  city: {
    type: String,
    required: true,
    regEx: avoidDangerouslyChars,
  },
  state: {
    type: String,
    required: true,
  },
  postalcode: {
    type: String,
    required: true,
  },
};

const mapDispatchToProps = dispatch =>{
  return ({
  updateProfile: profile => dispatch(updateProfileRequested(profile))
})};

const checkCompany = values => {
  if (!isNilOrEmpty(values.tax_id)) {
    return R.merge(values, { company: values.tax_id.length === 14 ? '1' : '0' });
  }
  return values;
};

const rejectEmptyValues = R.reject(isNilOrEmpty, R.__);

const pickNewValues = ({ values, initialValues }) => {
  const newValues = R.pickBy((val, key) => {
    return R.not(R.equals(initialValues[key], val));
  }, values);

  const formBirth = newValues['birth'];
  const withBirth = formBirth ?
    {...newValues, birth: getRequestBirthFormatted(formBirth)} : newValues;

  return {
    ...withBirth,
    'pep': newValues['pep'] ? '1' : '0'
  }
};

const enhance = compose(
  withStyles(styles),
  withState('edited', 'setEdited', false),
  withState('disableSave','setDisableSave', false),
  translate(),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withProps(({ profile, profile: { kyc_status } }) => {
    return {
      birth: getBirthFormatted(profile.birth),
      kycLevelOk: Number(profile.kyc_level) >= 1,
      kycDocs: {
        tax_id: /(?:ID_OK)/.test(kyc_status),
        selfie: /(?:SELF_OK)/.test(kyc_status)
      },
      initialValues: {
        ...R.pick(fieldsList, profile),
        pep: getPep(profile.pep),
        birth: getBirthFormatted(profile.birth),
        phone: getFormattedPhone(profile.phone)
      }
    };
  }),
  withHandlers({
    onSubmit: props => values => {
      // props.setEdited(true)

      const { updateProfile, initialValues, setDisableSave } = props;
      setDisableSave(true);
      const newValues = pickNewValues({ values: checkCompany(rejectEmptyValues(values)), initialValues });

      // guarantees that update profile will be executed before the button the released
      const handleSubmitWithTaxID = () => {
        return new Promise((resolve, reject) => {
          updateProfile(newValues)
        });
      }

      // checks if the session storage has updated if the tax id submited already exists
      // service.js stores it
      const checkTAXID = () => {
        if(sessionStorage.getItem("taxIdAlreadyUsed") === 'used') {
            return true
        } else if (sessionStorage.getItem("taxIdAlreadyUsed") === 'notUsed') {
            return false
        }
      }

      // release the button
      const liberaBotao = () => {
        if (checkTAXID()) {
          props.setEdited(false)
        } else {
          props.setEdited(true)
        }
      }

      if(newValues['phone']){
        updateProfile({...newValues, phone : `+55${newValues['phone']}` });
      } else {
        // Promise that updates profile, than wait to service.js to check if the TAXID is ok, than release the button
        handleSubmitWithTaxID()
        .then(setTimeout(() => liberaBotao(), 2000))
      }
      setTimeout(() => setDisableSave(false), 5000);
      // updateProfile(pickNewValues({ values: checkCompany(rejectEmptyValues(values)), initialValues }));
    },
    checkCanEdit: props => fieldChecker => {
      const { kycLevelOk } = props;

      if (!kycLevelOk && fieldChecker) {
        return true;
      }
      return fieldChecker;
    }
  }),
  reduxForm({
    validate: validate(schema),
    form: 'generalInfo',
    enableReinitialize: true
  }),
  pure
);

export default enhance(GeneralInfoForm);
