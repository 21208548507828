export const styles = (theme) => ({
  input: {
    width: "100%",
    height: "100%",
    background: "transparent",
    border: "none",
    fontSize: "1.5rem",
    color: theme.palette.text.primary,
    "&:focus": {
      outline: "none",
    },
  },
  helperText: {
    color: theme.palette.error.main,
    margin: 0,
    fontSize: "0.75rem",
    textAlign: "left",
    marginTop: "8px",
    minHeight: "1em",
    fontFamily: "Roboto,Helvetica,Arial,sans-serif",
    lineHeight: "1em",
  },
  helperTextContainer: {
    display: "flex",
  },
});
