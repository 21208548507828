import React from 'react';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  root: {
    minHeight: theme.spacing.unit * 2,
    padding: '3px 4px',
  },
});

const MiniButton = ({ children, classes, ...props }) => {
  return (<Button classes={classes} {...props} >{children}</Button>);
};

export default withStyles(styles)(MiniButton);
