import {
  ADD_SNACK,
  OPEN_SNACK,
  READ_SNACK,
} from './actionTypes';

export const addSnack = (action) => ({
  type: ADD_SNACK,
  ...action,
});

export const openSnack = (action) => ({
  ...action,
  type: OPEN_SNACK,
});

export const readSnack = (message) => ({
  type: READ_SNACK,
  message,
});

export default {
  addSnack,
  readSnack,
  openSnack,
};
