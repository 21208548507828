import React from 'react';
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import config from '../../config';
import { getCurrencyBySymbol } from "../../models/Currency"

const themeVariant = config.VARIANT === 'EXCHANGE'
  ? require('./light').default
  : require('./blue').default


const getTheme = (market, currencies, {colors, ...themeCustom }) => {

  const currencyConfig = getCurrencyBySymbol(currencies, market.split('-')[0]) ||  getCurrencyBySymbol(currencies, "BTC");
  return createMuiTheme({
    ...themeCustom,
    colors,
    typography: {
      fontFamily: ['Roboto','Helvetica', 'Arial', 'sans-serif'].join(','),
    },
    palette: {
      primary: {
        main: colors.primaryBase,
      },
      secondary: { main: currencyConfig ? currencyConfig.color : "rgb(249,168,37)" },
      error: { main: colors.red.main },
      background: {
        light: '#f3f3f3'
      },
      text: {
        main: '#000000'
      },
      ...colors,
    },
    drawerWidth: 300,
    drawerBackgroundColor: '#101010',
    buy: colors.green.main,
    sell: colors.red.main,
    stoploss: colors.blue.main,
    sellButton: {
      '&:hover': {
        backgroundColor: colors.red.dark,
      },
      '&:disabled': {
        color: colors.red.main,
      },
      backgroundColor: colors.red.main,
    },
    sellButtonTextPrimary: {
      color: colors.red.light,
    },
    buyButton: {
      '&:hover': {
        backgroundColor: colors.green.dark,
      },
      '&:disabled': {
        color: colors.green.light,
      },
      backgroundColor: colors.green.main,
    },
    buyButtonTextPrimary: {
      color: colors.green.light,
    },
    stoplossButton: {
      '&:hover': {
        backgroundColor: colors.blue.dark,
      },
      '&:disabled': {
        color: colors.blue.light,
      },
      backgroundColor: colors.blue.main,
    },
    stoplossButtonTextPrimary: {
      color: colors.blue.light,
    },
  });
}

const ThemeProvider = props => (
  <MuiThemeProvider theme={getTheme(props.market, props.currencies, themeVariant)}>
    <CssBaseline />
    {props.children}
  </MuiThemeProvider>
)

const mapStateToProps = state => ({
  market: state.market.selectedMarket,
  currencies: state.currencies.currencies
})

export default compose(
  connect(mapStateToProps)
)(ThemeProvider)
