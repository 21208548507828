import { SET_SELECTED_MARKET, UPDATING_DATA_STARTED, UPDATING_DATA_ENDED } from './actionTypes'

const initialState = {
  selectedMarket: 'BTC-BRL',
  updatingApplicationData: false
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SELECTED_MARKET:
      return {
        ...state,
        selectedMarket: action.market
      }
    case UPDATING_DATA_STARTED:
      return {
        ...state,
        updatingApplicationData: true
      }
    case UPDATING_DATA_ENDED:
      return {
        ...state,
        updatingApplicationData: false
      }
    case 'REMOVE_CREDENTIALS':
      return initialState
    default:
      return state
  }
}

export default reducer
