import {
  compose, withProps, lifecycle,
} from 'recompose';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import SendReceiveCoins from './SendReceiveCoins';


import {
  addDialog, confirmWithdrawalRequested, fetchFeesRequested, fetchLimitsRequested,
} from '../../redux/actions';
import { getCurrencyBySymbol } from '../../models/Currency';

const getValidationToken = (tabSelected, currencyConfig) => {
  if(tabSelected === 'send'){
    const allowedTokenDeposit = currencyConfig.deposit.length > 0;
    return allowedTokenDeposit;
  } else if(tabSelected === 'receive'){
    const allowedTokenWithdrawal = currencyConfig.withdrawal.length > 0;
    return allowedTokenWithdrawal;
  }
}

const mapDispatchToProps = dispatch => ({
  confirmWithdrawal: token => dispatch(confirmWithdrawalRequested(token)),
  fetchLimits: () => dispatch(fetchLimitsRequested()),
  fetchFees: () => dispatch(fetchFeesRequested()),
  warning2FA: () => dispatch(addDialog({
    title: 'forms.otpToken.activateInfo',
    centerTitle: true,
    availableChoices: [
      {
        label: 'common.close',
        actionToTake: '',
      },
      {
        label: 'forms.otpToken.activate',
        actionToTake: '',
        color: 'secondary',
        variant: 'raised',
        component: Link,
        to: '/profile/security',
      },
    ],
  })),
  addMissingKYCDialog: () => dispatch(
    addDialog({
      title: 'transactions.withdrawal.kycNeeded',
      disableBackdropClick:true,
      disableEscapeKeyDown:true,
      availableChoices: [
        {
          label: 'common.close',
          actionToTake: '',
          component: Link,
          to: '/profile/operationalLimits',
          color: 'secondary',
          variant: 'raised',
        },
      ],
    }),
  ),
  addWarningDialog: () => dispatch(addDialog({
    title: 'transactions.withdrawal.otpValidationNeeded',
    availableChoices: [
      {
        label: 'common.close',
        actionToTake: '',
        color: 'secondary',
        variant: 'raised',
      },
    ],
  })),
});

const mapStateToProps = (state) => {
  return {
    userProfile: state.user.profile,
    market: state.market.selectedMarket,
    currencies: state.currencies.currencies,
  };
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withProps((props) => {
    const currencyConfig =  getCurrencyBySymbol(props.currencies, props.market.split('-')[0]) || getCurrencyBySymbol(props.currencies, "BTC");
    return {
      objectId: props.match.params.objectId,
      allowedToken: getValidationToken(props.match.params.subRoute, currencyConfig),
      currencyConfig,
    }}),
  lifecycle({
    componentDidMount() {
      const {
        userProfile,
        fetchLimits,
        fetchFees,
        warning2FA
      } = this.props;

      if (Number(userProfile.kyc_level) === 0) {
        this.props.addMissingKYCDialog();
      } else {
        if(Number(userProfile.otp_active) === 0){
          warning2FA();
        }
        fetchLimits();
        fetchFees();
      }
    },
  }),
);

export default enhance(SendReceiveCoins);
