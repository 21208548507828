import React from 'react';
import { Field } from 'redux-form';

import RadioGroup from '@material-ui/core/RadioGroup';

const RadioFieldComponent = props => {
  const {
    input: { onChange, value, name },
    children,
    className
  } = props;

  return <div><RadioGroup
                value={value}
                name={name}
                onChange={onChange}
                children={children}
                className={className}
        /></div>

}


const RadioGroupField = props => <Field {...props} component={RadioFieldComponent} />;

export default RadioGroupField;
