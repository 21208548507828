import { connect } from 'react-redux';
import {
  compose, pure, setDisplayName, withHandlers, withProps, withState,
} from 'recompose';
import { translate } from 'react-i18next';
import Cookies from 'js-cookie';

import { withFormik, yupToFormErrors } from 'formik';
import { withGoogleReCaptcha } from 'react-google-recaptcha-v3';
import moment from 'moment';
import {addDialog, addResendEmailDialog, closeDialog} from '../../../redux/actions';
import registerService from '../../../services/cbtcService/register';

import Register from './RegisterForm';
import RegisterSchema from './RegisterSchema';
import service from '../../../services/service';
import getFailDialogInfo from './failDialogOptions';

const editBirthString = birth => {
  if (birth.includes('/')) return birth;
  return `${birth.substring(0, 2)}/${birth.substring(2, 4)}/${birth.substring(4, birth.length)}`;
};

const getBirthFormatted = (birthString = '', format) => {
  const birth = editBirthString(birthString);
  const isValid = moment(birth, 'DD-MM-YYYY').isValid();
  if (!isValid) {
    return birth;
  }
  return moment(birth, 'DD-MM-YYYY').format(format);
};

const getFormData = formikValues => {
  const address = formikValues.street
    .concat(', ', formikValues.addressNumber, ', ', formikValues.addressComplement);
  const filteredAddress = address.replace(/[`~!@#$%^&*()_|+\-=?;:'".<>\{\}\[\]\\\/]/gi, '');
  const company = formikValues.taxId.length === 11 ? 0 : 1;

  // formats values.birth only when is "Pessoa Fisica"
  // when is "Pessoa Juridica", birth will be empty
  const urlParams = new URLSearchParams(window.location.search)
  let productQuery = urlParams.get('product')
  const referQuery = urlParams.get('r')

  let referURL = 'plataform=web';
  if(Cookies.get('ref') && !referQuery){
    referURL = `product=bitypreco&` + referURL + `&r=${Cookies.get('ref')}`;
  }else if(referQuery && productQuery){
    referURL = referURL + `&${window.location.search.substring(1)}`
  }else if(!productQuery){
    referURL = 'product=bitypreco&' + referURL + `&${window.location.search.substring(1)}`
  }else{
    referURL = 'product=bitypreco&' + referURL
  }
  const birth = company === 0
    ? getBirthFormatted(formikValues.birth, 'YYYY-MM-DD')
    : null;
  return {
    ...(birth && { birth }),
    email: formikValues.email,
    pass: formikValues.pass,
    address: filteredAddress,
    city: formikValues.city,
    state: formikValues.state,
    postalcode: formikValues.postalcode,
    taxid: formikValues.taxId,
    gender: formikValues.gender,
    company_director: formikValues.companyDirector,
    company,
    referrer_url: referURL
  };
};

const mapDispatchToProps = dispatch => ({
  warningPep:() => {
    dispatch(
    addDialog({
      title: 'forms.pep.warning',
      disableEscapeKeyDown: true,
      disableBackdropClick: true,
      availableChoices: [
        {
          label: 'common.understood',
          color: 'secondary',
          variant: 'raised',
          
        }
      ]
    })
  )},
  submitInProgress: () => dispatch(
    addDialog({
      title: 'REGISTERING',
      disableBackdropClick: true,
      disableEscapeKeyDown: true,
      renderComponent: 'registerAccountInProgress',
      availableChoices: []
    }),
  ),
  submitFinished: () => dispatch(closeDialog()),
  openResendEmailDialog: (data) => dispatch(addResendEmailDialog(data)),
  onSubmitFail: (err, d, { message_cod }) => dispatch(
    addDialog(getFailDialogInfo(message_cod))
  ),
});

const enhance = compose(
  withGoogleReCaptcha,
  translate(),
  pure,
  withState('enableEditingStreet', 'setEnableEditingStreet', false),
  connect(
    null,
    mapDispatchToProps,
  ),
    withFormik({
    enableReinitialize: false,
    mapPropsToValues: (props) => ({
      email: undefined,
      repeatEmail: undefined,
      taxId: undefined,
      company_director: '',
      birth: undefined,
      pass: undefined,
      repeatPass: undefined,
      postalcode: undefined,
      city: '',
      state: '',
      address: '',
      street: '',
      addressComplement: '',
      addressNumber: undefined,
      terms: false,
      privacy: false,
      pep: false,
      gender: '',
    }),
    validate: ((values, props) => {
      const isPhysicalPerson = values.taxId && values.taxId.length <= 11;
      return RegisterSchema
        .validate(values, {
          abortEarly: false,
          context: { isPhysicalPerson },
        })
        .catch(err => {
          throw yupToFormErrors(err);
        });
    }),
    isInitialValid: false,

    handleSubmit: async (values, formikBag) => {
      const {
        submitInProgress, submitFinished, onSubmitFail, openResendEmailDialog
      } = formikBag.props;
      const { setSubmitting } = formikBag;
      const { executeRecaptcha } = formikBag.props.googleReCaptchaProps;
      try {
        submitInProgress();
        const submitForm = getFormData(values);
        const captcha = await executeRecaptcha('register');
        const response = await registerService({ captcha, ...submitForm });
        submitFinished();
        if (response.success) openResendEmailDialog({ email: submitForm.email });
        formikBag.resetForm();
      } catch (e) {
        console.error(e);
        setSubmitting(false);
        submitFinished();
        onSubmitFail(e, '', e);
      }
    },
  }),
  withHandlers({
    handleChange: props => e => {
      const { setFieldValue } = props;
      const { name, value } = e.target;
      const formattedValue = value.trim() || value;
        setFieldValue(name, formattedValue);
    },
    handleAddressChange: props => e => {
      const { setFieldValue } = props;
      const { name, value } = e.target;
      const regexHasSpecialChars = new RegExp(/[!@#$%^&*()_+\-=\[\]{};,.¨':"\\|<>\/?]/);
      if (!regexHasSpecialChars.test(value))
        setFieldValue(name, value);
    },
    handleTaxIDChange: props => value => {
      const { setFieldValue } = props;
      setFieldValue('taxId', value);
    },
    handleCompanyDirectorChange: props => value => {
      const { setFieldValue } = props;
      setFieldValue('companyDirector', value);
    },
    handlePostalcodeChange: props => value => {
      const { setFieldValue } = props;
      setFieldValue('postalcode', value);
    },
    handleBirthDayChange: props => e => {
      const { setFieldValue } = props;
      if (e !== null && !e.target) setFieldValue('birth', e);
      else if (e && e.target) {
        const { value } = e.target;
        setFieldValue('birth', getBirthFormatted(value, 'DD/MM/YYYY'));
      }
    },
    onSearchPostalCode: props => async cep => {
      const { setFieldValue } = props;
      const { executeRecaptcha } = props.googleReCaptchaProps;
      try {
        const captcha = await executeRecaptcha('searchPostalCode');
        const addressByPostalCode = await service({ cmd: 'cep_decode', method: 'POST' })({ cep, captcha });
        const street = addressByPostalCode.logradouro;
        setFieldValue('city', addressByPostalCode.localidade);
        setFieldValue('street', street);
        setFieldValue('state', addressByPostalCode.uf);
      } catch (error) {
        console.error(error);
      }
    }
  }),
  withProps((props) => {
    const { values } = props;
    const showBirth = (!values.taxId || values.taxId.length <= 11);
    return { showBirth };
  }),
  setDisplayName('Register'),
);

export default enhance(Register);
