import React, { useState, useEffect } from 'react'
import { compose } from 'recompose'
import { translate } from 'react-i18next'
import { withStyles } from '@material-ui/core/styles'
import { connect } from "react-redux";
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import CompareArrowsIcon from '@material-ui/icons/ArrowForward'
import Button from '@material-ui/core/Button';
import { warningSettings } from './constants'
import QRCode from "qrcode.react";
import { QrCodePix } from "qrcode-pix";

const styles = theme => ({
  root:  {
    flexGrow: 1
  },
  bankInfoBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  bankToBankHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    textAlign: 'center',
    padding: '16px 32px'
  },
  bankToBankContainer: {
    width: '100%',
    maxWidth: 300,
    margin: '0 auto 24px auto',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around'
  },
  iconsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  icon: {
    width: 74
  },

  iconPix: {
    width: 250
  },
  receiptIcon: {
    color: '#f9e9ad'
  }
})

const TransferFiatDetails = props => {
  const {
    t,
    classes,
    destination,
    bankInfo: { address },
    account,
    userData
  } = props;
  const userPixKey = account.info
    ? account.info.keys.find((key) => key.type === "EVP")
    : undefined;
  const warning =
    warningSettings[
      destination.isBankOthers ? "OTHER_BANKS" : destination.bank
    ];
  
  const mainPixKey = userPixKey ? userPixKey.key : address.pix;
  const pixReceiverName = userPixKey ? userData.name : 'Bity';
  const pixReceiverCity = userPixKey ? userData.city : "São Paulo";

  const qrCodePix = QrCodePix({
    version: "01",
    key: mainPixKey,
    name: pixReceiverName,
    city: pixReceiverCity,
  }).payload();

  const [copySuccess, setCopySuccess] = useState("");

  const copyToClipBoard = async (copyMe) => {
    try {
      await navigator.clipboard.writeText(copyMe);
      setCopySuccess("Copiado!");
    } catch (err) {
      setCopySuccess("Falha ao copiar!");
    }
  };

  return (
    <React.Fragment>
      {/* NAO MOSTRA SE FOR GLOBAL CARD?????? */}
      {!destination.isBankOthers && (
        <div>
          <Grid
            alignItems="center"
            justify="center"
            container
            spacing={3}
            className={classes.root}
          >
            <Grid
              direction="column"
              item
              className={classes.bankInfoBox}
              xs={12}
            >
              <Typography
                style={{ marginLeft: 5, marginRight: 5, fontWeight: 570 }}
              >
                Faça seu depósito na conta abaixo:
              </Typography>
            </Grid>

            <Grid
              direction="column"
              item
              className={classes.bankInfoBox}
              xs={4}
            >
              <Typography variant="body2">Banco:</Typography>
              <Typography color="textSecondary" variant="body2">
                {destination.bankName}
              </Typography>
            </Grid>

            <Grid
              direction="column"
              item
              className={classes.bankInfoBox}
              xs={4}
            >
              <Typography variant="body2">
                {t("forms.agency.label")}:
              </Typography>
              <Typography color="textSecondary" variant="body2">
                {destination.agency}
              </Typography>
            </Grid>
            <Grid
              direction="column"
              item
              className={classes.bankInfoBox}
              xs={4}
            >
              <Typography variant="body2">
                {t("forms.account.label")}:
              </Typography>
              <Typography color="textSecondary" variant="body2">
                {destination.account}
              </Typography>
            </Grid>
          </Grid>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Typography
              style={{
                marginLeft: 5,
                marginRight: 5,
                marginTop: 10,
                fontWeight: 570,
              }}
            >
              Razão social: {t("RAZAO_SOCIAL")}
            </Typography>
            <Typography
              style={{ marginLeft: 5, marginRight: 5, fontWeight: 570 }}
            >
              CNPJ: 29.738.313/0001-23
            </Typography>

            <Typography
              variant="body2"
              style={{
                color: "red",
                fontSize: 18,
                textAlign: "center",
                marginTop: 10,
              }}
            >
              A conta de origem do depósito
            </Typography>
            <Typography
              variant="body2"
              style={{
                color: "red",
                fontSize: 18,
                textAlign: "center",
                marginBottom: 10,
              }}
            >
              deve pertencer a você.
            </Typography>
          </div>
          {!!warning && (
            <React.Fragment>
              <div className={classes.bankToBankHeader}>
                <Typography variant="body2">Atenção</Typography>
                <Typography color="textSecondary" variant="body2">
                  {warning.title}
                </Typography>
              </div>
              <div className={classes.bankToBankContainer}>
                <div className={classes.iconsContainer}>
                  <img className={classes.icon} src={warning.fromLogo} alt={t("imageAlts.bankFromLogo")} />
                  <Typography variant="body2">{warning.from}</Typography>
                </div>
                {warning.to && (
                  <React.Fragment>
                    <CompareArrowsIcon fontSize={48} />
                    <div className={classes.iconsContainer}>
                      <img
                        className={classes.icon}
                        src={warning.toLogo}
                        alt={t("imageAlts.bankToLogo")}
                      />
                      <Typography variant="body2">{warning.to}</Typography>
                    </div>
                  </React.Fragment>
                )}
              </div>
            </React.Fragment>
          )}
        </div>
      )}

      {destination.isBankOthers && (
        <div>
          <Grid
            alignItems="center"
            justify="center"
            container
            spacing={3}
            className={classes.root}
            style={{ marginBottom: 10 }}
          >
            <Grid
              direction="column"
              item
              className={classes.bankInfoBox}
              xs={12}
            >
              <Typography variant="body2" color="textSecondary">
                Faça um PIX para UMA DAS CHAVES:
              </Typography>
              <Typography
                color="black"
                style={{ fontSize: 30, textAlign: "center" }}
                variant="body2"
              >
                {mainPixKey}
              </Typography>
              <div>
                <Button onClick={() => copyToClipBoard(mainPixKey)}>
                  Copiar Chave
                </Button>
                {copySuccess}
              </div>
              {userPixKey && (
                <React.Fragment>
                  <Typography
                    color="black"
                    style={{ fontSize: 20 }}
                    variant="body2"
                  >
                    OU
                  </Typography>
                  <Typography
                    color="black"
                    style={{ fontSize: 20 }}
                    variant="body2"
                  >
                    {address.pix}
                  </Typography>
                </React.Fragment>
              )}
            </Grid>
          </Grid>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Typography
              color="textSecondary"
              style={{
                marginLeft: 5,
                marginRight: 5,
                fontWeight: 570,
                marginBottom: 10,
              }}
            >
              OU UTILIZE O QR CODE ABAIXO:
            </Typography>
            <div className={classes.bankToBankContainer}>
              <div className={classes.iconsContainer}>
                <QRCode size={210} value={qrCodePix} />
                <Typography
                  variant="body2"
                  style={{
                    color: "red",
                    fontSize: 18,
                    textAlign: "center",
                    marginTop: 10,
                  }}
                >
                  A conta de origem do depósito
                </Typography>
                <Typography
                  variant="body2"
                  style={{ color: "red", fontSize: 18, textAlign: "center" }}
                >
                  deve pertencer a você.
                </Typography>
              </div>
            </div>
          </div>
          {!!warning && (
            <React.Fragment>
              <div className={classes.bankToBankHeader}>
                <Typography variant="body2">
                  Não esqueça de criar a intenção!
                </Typography>
              </div>
              <div className={classes.bankToBankContainer}>
                <div className={classes.iconsContainer}>
                  <img className={classes.icon} src={warning.pixLogo} alt={t("imageAlts.pixLogo")} />
                  <Typography variant="body2">
                    Deposite via PIX 24/7!
                  </Typography>
                </div>
              </div>
            </React.Fragment>
          )}
        </div>
      )}
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    account: state.user.account,
    userData: state.user.profile
  };
};

const enhance = compose(
  translate(),
  connect(mapStateToProps, null),
  withStyles(styles)
);

export default enhance(TransferFiatDetails)
