import { compose, withState, withHandlers, lifecycle } from 'recompose'
import { connect } from 'react-redux'
import { translate } from 'react-i18next'
import { createSelector } from 'reselect'
import moment from 'moment'

import { fetchExecutedOrdersRequested, fetchTradeHistory } from '../../../redux/actions'


const getOrders = (state, isShowAllSelected) => isShowAllSelected
  ? state.tradeHistory.trades
  : state.orders.executedOrders

const getIsShowAllSelected = (state, isShowAllSelected) => isShowAllSelected


const getOrdersSanitized = createSelector(
  [getOrders, getIsShowAllSelected],
  (orders, isShowAllSelected) => {
    return isShowAllSelected
      ? orders
      : orders.map(o => ({...o, timestamp: o.time_stamp}))
  }
)

const mapStateToProps = (state, props) => ({
  orders: getOrdersSanitized(state, props.isShowAllSelected)
})

const mapDispatchToProps = dispatch => ({
  fetchTradeHistory: () => dispatch(fetchTradeHistory()),
  fetchExecutedOrders: () => dispatch(fetchExecutedOrdersRequested()),
})

const withExecutedOrders = compose(
  withState('isShowAllSelected', 'setIsShowAllSelected', (props) => props.onlyMineOrders ? false : true),
  connect(mapStateToProps, mapDispatchToProps),
  withHandlers({
    getOrderTimestamp: ({ isShowAllSelected }) => order => {
      if (isShowAllSelected)
        return order.timestamp;
      else
        return order.concluded ? order.concluded : order.timestamp;
    },
    getOrderCreatedAt: () => order => {
      return order.timestamp;
  },
    sortOrdersByConcluded: ({ isShowAllSelected }) => orders => {
     if (!isShowAllSelected) {
       return orders.sort(function (firstOrder, secondOrder) {
         const firstOrderTime = new Date(firstOrder.concluded).getTime();
         const secondOrderTime = new Date(secondOrder.concluded).getTime();
         if (firstOrderTime < secondOrderTime)
           return 1;
         else if (firstOrderTime > secondOrderTime)
           return -1;
         return 0;
       });
     }
     return orders;
    },
    parseDateAndTime: () => timestamp => {
      const timeObj = moment(timestamp)
      return [
        timeObj.format('D/M/YYYY'),
        timeObj.format('HH:mm[h]')
      ]
    },
  }),
  lifecycle({
    componentDidMount() {
      if (!this.props.onlyMineOrders) {
      this.props.fetchTradeHistory()
    }
  }
  }),
  translate()
)

export default withExecutedOrders
