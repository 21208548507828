import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

const ActivityIndicator = ({ disableWrapper = false, center = false, fullHeight = false, ...props }) => {
  if (disableWrapper) {
    return <CircularProgress {...props} />;
  }
  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        height: fullHeight ? '100vh' : 'auto',
        alignItems: center ? 'center' : 'flex-start',
      }}
    >
      <CircularProgress {...props} />
    </div>
  );
};

export default ActivityIndicator;
