import service from '../service';

const getMetaMapId = async form => {
  const options = {
    cmd: 'get_metamap_id',
    method: 'POST',
  };

  const content = await service(options)(form);
  const { success, metamap_id } = content;
  if (success) {
    return { success, metamap_id  };
  }
  console.error('metaMapIdService', content);
  throw content;
};

export default getMetaMapId;
