import React from 'react'
import NumberFormat from 'react-number-format'
import * as R from 'ramda'


const limitForChangeScale = 0.009
const CurrencyFormat = props => {
  const {
    value,
    hidePrefix,
    className,
    formatter,
    isFiat
  } = props

  let {
    prefix,
    decimalScale,
    decimalSeparator,
    thousandSeparator,
    fixedDecimalScale
  } = formatter;

  if (isFiat) {
    const numberValue = Number(value);
    if (
      numberValue <= limitForChangeScale &&
      numberValue !== 0 &&
      numberValue >= -0.001
    ) {
      decimalScale = 6;
    }
  }





  // NumberFormat lib cant handle really small numbers
  // when value is javascript`s Number primitive so
  // the value is parsed as String and
  // isNumericString MUST be true

  const currencyPrefix = hidePrefix ? '' : prefix;
  const hasNumericInput = R.not(R.isEmpty(value)) && value !== undefined
  const valueAsString = hasNumericInput
    ? Number(value).toFixed(decimalScale)
    : ''

  return (
    <NumberFormat
      displayType="text"
      isNumericString
      value={valueAsString}
      className={className}
      prefix={currencyPrefix}
      decimalScale={decimalScale}
      decimalSeparator={decimalSeparator}
      thousandSeparator={thousandSeparator}
      fixedDecimalScale={fixedDecimalScale}
    />
  );
}

export default CurrencyFormat
