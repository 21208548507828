import { compose } from 'recompose'
import { connect } from 'react-redux'
import { translate } from 'react-i18next'
import * as R from 'ramda'

import { addDialog, setNewsRead } from '../../redux/actions'

const removeInvalidNews = (news) => {
  let newsID = []
  for (let key in news){
    if(news[key].hasOwnProperty('banner_id')){
      newsID.push(news[key].banner_id)
    }
  };
  return newsID;
}

const NewsDialog = props => {
  const {
    t,
    logged,
    dismissedNews,
    displayNews,
    newsFromState
  } = props
  
  if (!logged || !newsFromState) return null
  const newsFromStateIds = removeInvalidNews(newsFromState)
  const availableNewsToDisplay = R.difference(newsFromStateIds, dismissedNews)

  if (R.isEmpty(availableNewsToDisplay)) return null

  const newsToDisplay = R.find(R.propEq('banner_id', R.head(availableNewsToDisplay)))(newsFromState)

  displayNews({
    title: newsToDisplay.title,
    centerTitle: true,
    componentProps: {
      img: newsToDisplay.image,
      desc: newsToDisplay.description,
      link: newsToDisplay.link
    },
    renderComponent: 'newsComponent',
    availableChoices: [{
      label: 'common.close',
      color: 'secondary',
      variant: 'raised',
      actionToTake: 'setNewsRead'
    }],
    actionsToTake: {
      setNewsRead: () => setNewsRead(newsToDisplay.banner_id)
    }
  })

  return null
}

const mapStateToProps = ({ credentials: { logged, loadingStore }, layout: { newsDialog: { dismissedNews, news } } }) => ({
  logged,
  loadingStore,
  dismissedNews,
  newsFromState: news
})

const mapDispatchToProps = dispatch => ({
  displayNews: props => dispatch(addDialog(props)),
})

export default compose(
  translate(),
  connect(mapStateToProps, mapDispatchToProps)
)(NewsDialog)
