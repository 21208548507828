import React from 'react'
import { compose }  from 'recompose'
import { translate } from 'react-i18next'

import PercentageSwitchButtons from './PercentageSwitchButtons'
import MoneyInput from '../../../components/FormsV2/MoneyInput'

const TotalValueInput = props => {
  const {
    t,
    shouldDisplayPercentageSwitch,
    selectedPercentage,
    setPercentage,
    className,
    formatter,
    onChange,
    onBlur,
    value
  } = props

  const toolTipProps = {
    hasToolTip: true,
    toolTipCaption: 'Saldo',
    toolTipTitle: t('info.useBalance'),
  }

  return (
    <MoneyInput
      name='totalValue'
      formatter={formatter}
      onChange={onChange}
      onBlur={onBlur}
      value={value}
      className={className}
      InputProps={{
        endAdornment: shouldDisplayPercentageSwitch && (
          <PercentageSwitchButtons
            toolTipProps={toolTipProps}
            selectedPercentage={selectedPercentage}
            setPercentage={setPercentage}
            {...props}
          />
        )
      }}
      {...props}
    />
  )
}

export default compose(
  translate(),
)(TotalValueInput)
