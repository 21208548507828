import { useEffect } from 'react';
import { Socket } from 'phoenix';
import { SOCKET_URL } from '../config';

const getPayloadData = ( payload, market ) => {
  if (market in payload)
    return payload[market]
  return {}
}


/*
* Ticker object format
* {
*    BTC: {...}, ETH: {...}, ...
* }
* */
const buildTickerObject = (payload) => {
  const tickerObject = {}
  for(let key in payload ) {
    if (key === "success") continue;
    const currency = key.split("-")[0];
    tickerObject[currency] = payload[key]
  }
  return tickerObject
}

const useTicker = (updateTicker, sync, stop) => {
  useEffect(() => {
    const socket = new Socket(`${SOCKET_URL}/orderbook/socket`);
    socket.connect();
    socket.onOpen(() => {
      console.log('CONNECTED SUCCESSFULLY');
      stop();
    });
    socket.onError(e => {
      console.log('Failed to connect to socket');
      sync();
    });

    const channel = socket.channel('ticker:ALL-BRL', {});
    channel.join()
      .receive('ok', resp => { console.log('Joined successfully', resp); })
      .receive('error', resp => { console.log('Unable to join', resp); });

    channel.on('price', payload => {
      updateTicker({...buildTickerObject(payload), USDBP: {
        "market":"USDBP-BRL",
        "last":0,
        "high":0,
        "low":0,
        "vol":0,
        "avg":0,
        "var":0,
        "buy":0,
        "sell":0,
        "timestamp":"2023-05-05 07:50:12"
      },
      });
    });

    return () => socket.disconnect();
  }, []);
};

export default useTicker;
