import React from 'react';
import { pure, compose } from 'recompose';
import { translate } from 'react-i18next';

import { withStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import getHelperText from '../../utils/getHelperText';
import FormHelperText from '@material-ui/core/FormHelperText';
import store from  '../../redux/store';

const styles = theme => ({
  paper: {
    position: 'relative',
    width: '100%',
    maxWidth: 360,
    overflow: 'auto',
    maxHeight: 300,
    backgroundColor: theme.palette.background.paper,
  },
  margin: {
    margin: theme.spacing.unit,
  },
});


const checkKYCandAddress = () => {
    return store.getState().user.profile.state === '' ? false :
    store.getState().user.profile.kyc_level === '0' ? false : true
}

const states = [
  'AC',
  'AL',
  'AP',
  'AM',
  'BA',
  'CE',
  'DF',
  'ES',
  'GO',
  'MA',
  'MS',
  'MT',
  'MG',
  'PA',
  'PB',
  'PI',
  'PR',
  'PE',
  'RN',
  'RJ',
  'RS',
  'RO',
  'RR',
  'SC',
  'SP',
  'SE',
  'TO'
]

const SelectStateFieldComponent = (props) => {

  const {
    input: { name, ...input },
    t,
    meta: { touched, error },
    custom
  } = props;

  const shouldDisplayError = Boolean(touched && error)
  const helperText = shouldDisplayError
    ? getHelperText(t)(error)
    : ' ';

  return (
    <div>
      <FormControl style={{width:'75px'}}>
        <InputLabel htmlFor='state'>Estado</InputLabel>
        <Select
          disabled={checkKYCandAddress()}
          error={shouldDisplayError}
          {...input}
          {...custom}
        >
          {states.map(x => (<MenuItem value={x}>{x}</MenuItem>))}
        </Select>
        { shouldDisplayError &&
            <FormHelperText
              style={{color: '#d32f2f'}}
            >
              {helperText}
            </FormHelperText> }
          </FormControl>
        </div>
  );

};

const enhance = compose(
  pure,
  translate(),
  withStyles(styles),
);

export default enhance(SelectStateFieldComponent);
