import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import Slide from '@material-ui/core/Slide';
import CloseButton from '@material-ui/icons/CloseOutlined';
import { closeOtpAppDialog } from '../redux/actions';
import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import { translate } from 'react-i18next';
import { DialogContent } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import QRCode from 'qrcode.react';
import classNames from 'classnames';
import Hidden from '@material-ui/core/Hidden';
import DangerouslySetInnerHTML from './DangerouslySetInnerHTML';
import StoreLinks from "../components/Buttons/StoreLinks";

function Transition(props) {
  const windowWidth = window.innerWidth;
  return <Slide direction={`${windowWidth}` > 600 ? 'left' : 'up'} {...props} />;
}

const styles = theme => ({
    dialogContainer: {
      backdropFilter: 'blur(1.5px)',
      display: 'flex',
      justifyContent: 'right',
      fontFamily:"'Helvetica','Arial', 'sans-serif'",
      [theme.breakpoints.down('xs')]: {
        justifyContent: 'center',
      },
      [theme.breakpoints.down('md')]:{
        fontSize: '14px',
      }
    },
    dialogPaper: {
        margin: 'auto 0 auto 0',
        height: '100%',
        maxHeight: '100%',
        borderRadius: '55px 0 0 55px',
        width: '32%',
        minWidth: '470px',
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'rgb(247, 247, 247)',
        [theme.breakpoints.down('xs')]: {
          borderRadius: '25px 25px 0 0',
          height: '60%',
          maxHeight: '70%',
          minHeight: '520px',
          margin: 'auto 0 0 0',
          width: '100%',
          minWidth: '320px',
        },
    },
    flexColumn: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%'
    },
    dialogTitleContent: {
        lineHeight: '32px',
        margin: 'auto',
        marginBottom: '13px',
        fontSize: '25px'
    },
    closeButton: {
        border: 'none',
        backgroundColor: 'transparent',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'end',
        width: '97%',
    },
    dialogContent: {
        maxWidth: '90%',
        margin: 'auto',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        alignItems: 'center',
        [theme.breakpoints.down('md')]:{
          padding: '14px'
        }
        
    },
    qrCode:{
        backgroundColor: 'rgb(255, 255, 255)',
        borderRadius: '20px',
        padding: '21px',
        width: '40%',
        maxWidth: '160px',
        margin: 'auto',
        [theme.breakpoints.down('md')]:{
          width: '130px',
          padding: '16px',
        }
    },
    qrCodeSize: {
      width: '100% !important',
      height: '100% !important',
    },
    downloadQrCode: {
        fontSize: "14px !important"
    },
    qrCodeContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: '15px',
    },
    storeButtons: {
      display: 'flex',
      alignContent: 'center',
      borderRadius: '40px',
      marginBottom: '10px',
      padding: '3% 0',
      [theme.breakpoints.down('xs')]: {
        padding: '0',
      },
    },
    contentButton: {
      display: 'flex',
      alignContent: 'center',
      justifyContent: 'center', 
      gap: '5px',
      minHeight: '25px'
    },
    closeIcon: {
      "&:hover": {
        cursor: 'pointer',
        opacity: '0.7'
      }
    },
    infoDialog: {
      width: '100%',
      margin: 'auto',
      lineHeight: '23px',
      [theme.breakpoints.down('xs')]: {
        lineHeight: '20px',
      },
    },
    textMuted:{
      color: '#777777',
    },
    disclamer:{
      fontSize: '13px',
    },
    dialogTitle: {
      padding: '19px 20px',
      [theme.breakpoints.down('xs')]: {
        padding: '18px'
      },
    }
  });


const OtpAppDialog = props => {
  const {
    open,
    closeDialog,
    title,
    t,
    classes,
} = props;
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      disableEscapeKeyDown={true}
      disableBackdropClick={true}
      className={classes.dialogContainer}
    //   maxWidth={'xs'}
      classes={{paper: classes.dialogPaper}}
    >
      <DialogTitle
        align={'center'}
        id="alert-dialog-slide-title"
        className={classNames(classes.flexColumn, classes.dialogTitle)}
      >
        <button className={classes.closeButton}>
          <CloseButton className={classes.closeIcon} onClick={() => closeDialog()}></CloseButton>
        </button>
      </DialogTitle>
      <DialogContent classes={{root: classes.dialogContent}}>
        <div className={classes.qrCodeContainer}>
          <div>
            <h2 className={classes.dialogTitleContent}><DangerouslySetInnerHTML title={t(title)} /></h2>
            
          </div>
          
          <DangerouslySetInnerHTML 
                className={
                  classNames(classes.infoDialog, classes.textMuted)
                } 
                title={t('forms.appOtpToken.dialog.securityMeasure')}
               />
               <DangerouslySetInnerHTML 
                className={
                  classNames(classes.infoDialog, classes.textMuted)
                } 
                title={t('forms.appOtpToken.dialog.toAccess')}
               />
          <Hidden smDown> 
               <DangerouslySetInnerHTML 
                className={
                  classNames(classes.infoDialog, classes.textMuted, classes.downloadQrCode)
                } 
                title={t('forms.appOtpToken.dialog.downloadQrCode')}
               />
              <div className={classes.qrCode}>
                <QRCode className={classes.qrCodeSize} value={'https://link.bity.com.br/app'} />
              </div>
           </Hidden>
        </div>

        <div className={classNames(classes.flexColumn, classes.textMuted, classes.downloadQrCode)}>
          <Hidden smDown> 
            <p>{t('forms.appOtpToken.dialog.downLoadLinks')}</p>
          </Hidden>
            <StoreLinks width={"100%"} flexDirection={"column"}></StoreLinks>
        </div>        
      </DialogContent>
    </Dialog>
  );
}

const mapStateToProps = (state) => {
    return {
        open: state.credentials.otpAppDialog.open,
        title: state.credentials.otpAppDialog.title,
    };
};

const mapDispatchToProps = dispatch => ({
    closeDialog: () => dispatch(closeOtpAppDialog()),
    // openDialog: () => dispatch(addCardDialog())
});


export default compose(
  translate(),
  withRouter,
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(OtpAppDialog)
