import React from 'react';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import { compose, pure, lifecycle, withProps, withState } from 'recompose';
import { Link } from 'react-router-dom';

import { uploadDocumentRequested, addDialog } from '../../../../redux/actions';
import DocInputStatus from '../../../../components/DocumentInputStatus';

const styles = theme => ({
  form: {
    display: 'flex'
  }
})

const getProfileStatus = profile => {
  const { address, tax_id, postalcode, valid_email, birth, name } = profile;
  let status = 'valid';

  if (name === '') status = 'invalid';
  if (address === '') status = 'invalid';
  if (tax_id === '') status = 'invalid';
  if (postalcode === '') status = 'invalid';
  if (birth === '') status = 'invalid';
  if (valid_email !== '1') status = 'invalid';

  return status;
};

const OpDocInput = props => {
  const { handleSubmit, classes } = props;
  return (
    <form className={classes.form} onSubmit={handleSubmit}>
      <DocInputStatus
        {...props}
        allowReUpload={true}
        name="opDoc"
      />
    </form>
  );
};

const mapDispatchToProps = dispatch => ({
  onSubmit: doc => {
    if(doc.formType==='CONSTITUTION'){
      if(doc.opDoc.mimetype === 'application/pdf'){
        dispatch(uploadDocumentRequested(doc));
      }else{
        dispatch(
          addDialog({
          title: 'ONLY_PDF',
            availableChoices: [
              {
                label: 'common.close',
                actionToTake: '',
                color: 'secondary',
                variant: 'raised'
              }
            ]
        }));
      }
    }else{
      dispatch(uploadDocumentRequested(doc));
    }
  },
});

const mapStateToProps = state => {
  const { profile } = state.user;
  const profileFirstStepDone = getProfileStatus(profile) === 'valid';
  return {
    profileFirstStepDone
  };
};

const enhance = compose(
  withStyles(styles),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),

  withState('fileLoaded', 'setFileLoaded', false),
  withProps(({ form }) => ({ initialValues: { formType: form } })),
  reduxForm(),
  lifecycle({
    componentDidUpdate() {
      const {
        dirty,
        submit,
        fileLoaded,
        setFileLoaded,
        reset,
        submitSucceeded,
        form,
        dispatch,
        profileFirstStepDone,
        clearFields,
      } = this.props;
      if (dirty && !fileLoaded) {
        setFileLoaded(false);
        if (!profileFirstStepDone) {
          dispatch(
            addDialog({
              title: 'INVALID_PROFILE_INFORMATION', // eslint-disable-line camelcase
              availableChoices: [
                {
                  label: 'common.close',
                  actionToTake: '',
                  component: Link,
                  to: '/profile/account',
                  color: 'secondary',
                  variant: 'raised'
                }
              ]
            })
          );

          const btnFile = document.getElementById(`input-${form}-opDoc`);
          btnFile.value = '';
          clearFields(form, false, false, 'opDoc');
        } else {
          setFileLoaded(true);
          submit();
        }
      }
      if (submitSucceeded) {
        setFileLoaded(false);
        reset();
      }
    }
  }),
  pure
);

export default enhance(OpDocInput);
