import React from 'react';
import { Field } from 'redux-form';
import SelectStateFieldComponent from './SelectStateFieldComponent';


const SelectField = (props) => {

  return (
    <Field {...props} component={SelectStateFieldComponent}/>
  );
};

export default SelectField;