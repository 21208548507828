import React from 'react';
import { Link, withRouter } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import InfoTooltip from '../../../components/InfoTooltip';
import { withStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import Cookies from 'js-cookie';
import { Typography } from '@material-ui/core';
import SubmitButton from '../../../components/Forms/SubmitButton';
import Form from '../../../components/Forms/Form';
import FieldRow from '../../../components/Forms/FieldRow';
import DangerouslySetInnerHTML from '../../../components/DangerouslySetInnerHTML';
import sendGAEvent from '../../../utils/sendGAEvent';
import CredentialsForm from './CredentialsForm';
import AccountPersonalDataForm from './AccountPersonalDataForm';
import { gtmOpenRegister } from '../../../utils/dataLayer';
import IntercomChat from '../../../layout/IntercomChat';
import Hidden from '@material-ui/core/Hidden';
import { red } from '@material-ui/core/colors';
import { useEffect } from 'react';

const styles = theme => ({
  formContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  flexRow: {
    display: 'flex',
    flexDirection:'row',
    maxWidth: '400px',
    width: '100%',
  },
  buttonStepperContent: {
    display: 'flex',
    alignItems: 'center',
  },
  arrowIcon: {
    marginBottom: 1,
  },
  buttonStepperTextNext: {
    marginLeft: 12,
  },
  buttonStepperTextBack: {
    marginRight: 12,
  },
  warningPep: {
    color: '#d32f2f',
  }
});

const RegisterForm = (props) => {
  const cookie = Cookies.get('ref');
  const referrer = cookie ? `&r=${Cookies.get('ref')}` : '';

  useEffect(() => {
    window.location.href = `https://bity.com.br/register/?product=bitypreco` + referrer;
  },[])
  const {
    handleSubmit, isSubmitting, onSubmit, t, values,
    setFieldValue,
    isValid,
    classes,
    warningPep
  } = props;

  React.useEffect(() => {
    gtmOpenRegister()
  }, []);

  React.useEffect(() => {
    if(values.pep){
      warningPep()
    }
  }, [values.pep]);

  const [activeStep, setActiveStep] = React.useState(0);

  const components = [<CredentialsForm {...props} />, <AccountPersonalDataForm {...props} />];
  return (
    <React.Fragment>
        <Form {...{ handleSubmit, onSubmit }}>

          {components[activeStep]}

          {Cookies.get('ref') && (
            <Typography style={{
              backgroundColor: '#ededed',
              padding: 8,
              marginBottom: 12,
              width: '100%',
              borderRadius: 8,
              color: 'darkslategrey',
              maxWidth: 400,
              textAlign: 'center',
            }}
            >
    Você foi indicado pelo usuário:&nbsp;
              {Cookies.get('ref')}
            </Typography>
          )}

          {activeStep === 1
            ? (
              <Button
                color="secondary"
                variant="raised"
                onClick={() => setActiveStep(0)}
              >
                <div className={classes.buttonStepperContent}>
                  <KeyboardArrowLeft className={classes.arrowIcon} />
                  <div className={classes.buttonStepperTextBack}>
                    {t('screens.register.back')}
                  </div>
                </div>
              </Button>
            )
            : (
              <Button
                color="secondary"
                variant="raised"
                onClick={() => setActiveStep(1)}
              >
                <div className={classes.buttonStepperContent}>
                  <div className={classes.buttonStepperTextNext}>
                    {t('screens.register.next')}
                  </div>
                  <KeyboardArrowRight className={classes.arrowIcon} />
                </div>
              </Button>
            )}
          <FieldRow className={classes.flexRow}>
            <FormControlLabel
              style={{marginRight: '0'}}
              control={
                <Checkbox name="pep" className={values.pep ? classes.warningPep : ''} onChange={() => setFieldValue('pep', !values.pep)} color="error"/>
              }
              label={<DangerouslySetInnerHTML className={values.pep ? classes.warningPep : ''}  title={String(values.taxId).length > 11 ? t('forms.politicallyExposedPerson.labelCNPJ') : t('forms.politicallyExposedPerson.label')}/>}
            />
            <InfoTooltip title={t('forms.pep.info')} wrapperRootHeader={true}/>

          </FieldRow>
          <FieldRow>
            <FormControlLabel
              control={
                <Checkbox name="terms" checked={values.terms} onChange={() => setFieldValue('terms', !values.terms)} color="primary" />
              }
              label={<DangerouslySetInnerHTML title={t('forms.terms.label')} />}
            />
          </FieldRow>
          <FieldRow>
            <FormControlLabel
              control={
                <Checkbox name="privacy" checked={values.privacy} onChange={() => setFieldValue('privacy', !values.privacy)} color="primary" />
              }
              label={<DangerouslySetInnerHTML title={t('forms.policy.label')} />}
            />
          </FieldRow>
          <FieldRow>
            <SubmitButton
              submitText={t('screens.register.submit')}
              disabled={isSubmitting || !isValid || values.pep}
              onClick={() => {
                sendGAEvent('Cadastro', 'Novo Cadastro');
              }}
            />
          </FieldRow>
          <FieldRow>
            <Button
              color="secondary"
              variant="raised"
              component={Link}
              to="/login"
            >
              {t('screens.login.hasAccount')}
            </Button>
          </FieldRow>
        </Form>
        <Hidden smDown>
          <IntercomChat/>
        </Hidden>
    </React.Fragment>
);
};

export default withStyles(styles)(RegisterForm);
