import React from 'react';
import PropTypes from 'prop-types';
import { compose, pure, branch, renderNothing } from 'recompose';
import { translate } from 'react-i18next';


import { withStyles } from '@material-ui/core/styles';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import Hidden from '@material-ui/core/Hidden';

import BottomNavigationAction from './BottomNavigationAction';
import { navItems } from '../appBarMenuItems';


const styles = theme => ({
  root: {
    display: 'flex',
    position: 'fixed',
    bottom: 0,
    left: 'auto',
    right: 0,
    width: '100%',
    boxSizing: 'border-box', // Prevent padding issue with the Modal and fixed positioned AppBar.
    zIndex: theme.zIndex.appBar,
    flexShrink: 0,
    backgroundColor: theme.palette.primary.main,
  },
  toolbar: theme.mixins.toolbar,
});

const renderItems = t => navItems.map(
  ({
    primary, bottonNavigation, alignEnd, drawerNavigation, scene, ...drawerItem
  }) => {
    if (bottonNavigation) {
      return (
        <BottomNavigationAction
          key={`bottom_navigation_item_${primary}`}
          label={t(`screens.${primary}.title`)}
          value={scene}
          {...drawerItem}
        />
      );
    }
    return null;
  });


const SimpleBottomNavigation = ({
  scene, classes, t,
}) => {
  return (
    <Hidden mdUp>
      <div className={classes.toolbar} />
      <BottomNavigation
        value={scene}
        showLabels
        // onChange={(event, value) => setActiveNavigation(value)}
        className={classes.root}
      >
        {renderItems(t)}
        {/* <NavigationItems navItems={navItems} t={t} /> */}
      </BottomNavigation>
    </Hidden>
  );
};

const enhance = compose(
  pure,
  translate(),
  withStyles(styles),
  branch(props => !(props.logged), renderNothing),
);

SimpleBottomNavigation.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string.isRequired,
  }).isRequired,
  scene: PropTypes.string.isRequired,
};

export default enhance(SimpleBottomNavigation);
