import React, { Fragment } from 'react';
import { compose } from 'recompose';
import { translate } from 'react-i18next';

import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';

const styles = theme => ({
  root : {
    display: 'flex',
    alignContent: 'center',
    alignItems: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '60%'
  }
});

const MissingInfoIndicator = props => {
  const { t, classes } = props;

  return (
    <Fragment >
      <CardHeader
            title={t('common.missingKYC')}
            className={classes.root}
            />
        <CardContent
          className={classes.root}
        >
            <Button
            component={Link}
            to="/profile/operationalLimits"
            >
            Validar documentos
            </Button>
        </CardContent>
      </Fragment>
  );
};
const enhance = compose(
  translate(),
  withStyles(styles)
  );

export default enhance(MissingInfoIndicator);
