import { compose, lifecycle, withState } from 'recompose';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';

import * as R from 'ramda';
import Validate from './Validate';
import { requestValidation, resolveValidation } from '../../../redux/actions';

const mapStateToProps = state => ({
  validation: state.credentials.validation,
});

const mapDispatchToProps = dispatch => ({
  validate: token => dispatch(requestValidation(token)),
  validateSucceed: action => dispatch(resolveValidation(action)),

});

const enhance = compose(
  translate(),
  connect(mapStateToProps, mapDispatchToProps),
  withState('validationStatus', 'setValidationStatus', 'init'),

  // withState('redirectCountdown', 'setRedirectCountdown', 5),
  // withHandlers({
  //   setRedirectCountdown: ({ setRedirectCountdown, redirectCountdown }) => () => {
  //     setRedirectCountdown(redirectCountdown - 1);
  //   },
  // }),
  // withHandlers({
  //   startRedirectCountdown: ({ setRedirectCountdown }) => () => {
  //     setInterval(setRedirectCountdown, 1000);
  //   },
  // }),
  lifecycle({
    componentDidMount() {
      const { validate, history, setValidationStatus } = this.props;

      const token = R.replace('?token=', '',
        R.path(['location', 'search'], this.props));

      if (R.isEmpty(token)) {
        history.replace('/login');
      } else {
        setValidationStatus('resolving');
        validate(token);
      }
    },
    componentDidUpdate() {
      const {
        validation,
        validationStatus,
        setValidationStatus,
      } = this.props;

      if (validation.resolved && validationStatus === 'resolving') {
        switch (validation.success) {
          case true:
            setValidationStatus('success');
            break;

          default:
            setValidationStatus('failed');
            break;
        }
      }
    },
    //   const {
    //     validation, startRedirectCountdown, redirectCountdown, history,
    //   } = this.props;
    //   // if (redirectCountdown === 0) {
    //   //   return history.replace('/login');
    //   // }
    //   // if ((validation.resolved && validation.success) && redirectCountdown === 5) {
    //   //   return startRedirectCountdown();
    //   // }
    //   return null;
    // },
  }),
);


export default enhance(Validate);
