
import React, { useState } from 'react';
import moment from 'moment-shortformat';
import { compose } from 'recompose';
import { connect } from 'react-redux';

import { withStyles } from '@material-ui/core/styles';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Paper from '@material-ui/core/Paper';
import grey from '@material-ui/core/colors/grey';
import ListItemText from '@material-ui/core/ListItemText';
import NotificationIcon from '@material-ui/icons/Notifications';
import  Tooltip from '@material-ui/core/Tooltip';

import Badge from '@material-ui/core/Badge';
import Button from '@material-ui/core/Button';
import Grow from '@material-ui/core/Grow';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';

import { translate } from 'react-i18next';

import { Typography, Divider } from '@material-ui/core';
import { isMobile } from '../../utils/checkScreenHelpers';

const MAX_NOTIFICATION_LENGTH = 50;

const styles = theme => ({
  root: {
    position: 'relative',
  },
  icon: {
    color: '#bdbdbd',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  paper: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.grey[400],
    position: 'absolute',
    top: 55,
    right: 0,
    left: 0,
  },
  menu: {
    backgroundColor: theme.drawerBackgroundColor,
    color: 'white',
  },
  menuItemUnread: {
    backgroundColor: 'rgb(206 201 201 / 22%)',
  },
  menuText: {
    color: 'white',
  },
  menuTextPrimary: {
    color: 'white',
    fontWeight: 700,
  },
  menuTextSecondary: {
    color: theme.palette.grey[400],
    fontSize: 12,
  },
  menuTextTooltip: {
    fontSize: 15,
  },
  menuTextDate: {
    color: 'white',
    fontSize: 10,
  },
  divider: {
    backgroundColor: theme.palette.grey[800],
  },
  badgeEmpty: { display: 'none' },
  badge: {
    backgroundColor: 'red',
    top: 0,
    right: 7,
    height: 18,
    width: 18,
  },
  listItemText: {
    color: theme.palette.grey[400],
  },
  fake: {
    backgroundColor: grey[200],
    height: theme.spacing.unit,
    margin: theme.spacing.unit * 2,
    '&:nth-child(2n)': {
      marginRight: theme.spacing.unit * 3,
    },
  },
});

const treatNotification = (body, isTooBig) =>{
  return isTooBig ? `${body.substring(0, MAX_NOTIFICATION_LENGTH)}...` : body;
}

const NotificationRow = ({ notification, classes, t }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const payloadHasData = notification.payload && notification.payload.subtitle;
  const newPayloadHasData = notification.payload && (notification.payload.body || notification.payload.title);
  const subtitleIsTooBig = payloadHasData ? notification.payload.subtitle.length > MAX_NOTIFICATION_LENGTH : false;
  const bodyIsTooBig = newPayloadHasData && notification.payload.body? notification.payload.body.length > MAX_NOTIFICATION_LENGTH : false;

  const message = {
    title: newPayloadHasData ? notification.payload.title : notification.type,
    body: newPayloadHasData ? treatNotification(notification.payload.body, bodyIsTooBig) : treatNotification(notification.payload.subtitle, subtitleIsTooBig),
    tooltip: newPayloadHasData ? notification.payload.body : notification.payload.subtitle,
    needsTooltip: newPayloadHasData ? bodyIsTooBig : subtitleIsTooBig,
  }
  if (message.needsTooltip) {
    return <Tooltip title={message.tooltip}
               enterTouchDelay={0}
               open={tooltipOpen}
               classes={{ tooltip: classes.menuTextTooltip }}
               placement={'bottom-start'}>
                <ListItemText
                  classes={{
                    primary: classes.menuTextPrimary,
                    secondary: classes.menuTextSecondary,
                  }}
                  primary={message.title}
                  secondary={message.body}
                  onMouseOver={() => {
                    if (!isMobile()) setTooltipOpen(true);
                  }}
                  onMouseLeave={() => {
                    if (!isMobile()) setTooltipOpen(false);
                  }}
                  onClick={() => setTooltipOpen(!tooltipOpen)}
                />
          </Tooltip>
  }
  return <ListItemText
            classes={{
              primary: classes.menuTextPrimary,
              secondary: classes.menuTextSecondary,
            }}
            primary={message.title}
            secondary={message.body}
          />
}

const NotificationsMenu = props => {
  const {
    notifications,
    setRead,
    classes,
    t,
  } = props;

  const [anchorEl, setAnchorEl] = useState();
  const [open, setOpen] = useState(false);
  const handleToggle = () => {
    setOpen(!open);
    if (typeof setNotificationsRead === 'function') {
      window.setNotificationsRead();
    }
  };
  const handleClose = e => {
    setRead();
    if (anchorEl.contains(e.target)) return;
    setOpen(false);
  };

  const pendingCount = notifications.filter(n => !n.read).length;

  return (
    <div>
      <Badge
        badgeContent={pendingCount}
        classes={{ badge: pendingCount > 0 ? classes.badge : classes.badgeEmpty }}
      >
        <Button
          buttonRef={node => {
            setAnchorEl(node);
          }}
          aria-owns={open ? 'menu-list-grow' : null}
          aria-haspopup="true"
          onClick={handleToggle}
          classes={{ root: classes.menu }}
        >
          <NotificationIcon classes={{ root: classes.icon }}/>
        </Button>
      </Badge>
      <Popper style={{ zIndex : 10 }} open={open} anchorEl={anchorEl} transition disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            id="menu-list-grow"
            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
          >
            <Paper classes={{ root: classes.menu }}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList>
                  {notifications.map((n, i) => (
                    <div key={n.id}>
                      <MenuItem
                        classes={{
                          root: n.read
                            ? classes.menuItemRead
                            : classes.menuItemUnread,
                        }}
                      >
                        <NotificationRow classes={classes}
                                         notification={n}
                                         t={t} />
                        <Typography classes={{ root: classes.menuTextDate }}>
                          {moment(n.createdAt).local().short()}
                        </Typography>
                      </MenuItem>
                      {notifications.length > i + 1
                        && <Divider classes={{ root: classes.divider }} />
                      }
                    </div>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};

const mapStateToProps = ({
  layout: {
    appBar,
    notifications,
  },
  credentials: { demoAccount },
}) => ({
  appBar,
  demoAccount,
  notifications,
});

const mapDispatchToProps = dispatch => ({
  setRead: () => dispatch({ type: 'SET_NOTIFICATIONS_READ' }),
});

const enhance = compose(
  withStyles(styles),
  translate(),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
);

export default enhance(NotificationsMenu);
