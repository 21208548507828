import { put, takeLatest } from 'redux-saga/effects';
import {FETCH_NEWS_DATA, FETCH_NEWS_SUCCEED} from "./actionTypes";
import { fetchService } from "../../appData/service/actions";
import { addNewsData } from "./actions";


function* fetchNewsData({frameName='WEB_BANNER_1'}) {
    try {
        yield put(fetchService({
            requestType: 'fetchWallchart',
            successAction: FETCH_NEWS_SUCCEED,
            paramns: {
                options: {
                    cmd: 'get_banners',
                    method: 'POST',
                },
                form: {
                    'frame_name': `${frameName}`,
                    'lang': 'PT_BR'
                }
            },
        }));
    } catch (e) {
        console.error('fetchWallchart', e);
    }
}

function* fetchNewsSucceed({ response }) {
    try {
        yield put(addNewsData(response));
    } catch (e) {
        console.error('fetchLimits', e);
    }
}

function* watchNews() {
    yield takeLatest(FETCH_NEWS_DATA, fetchNewsData);
    yield takeLatest(FETCH_NEWS_SUCCEED, fetchNewsSucceed);
}

export default watchNews;
