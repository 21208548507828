import { useState, useEffect } from 'react';
import { Socket } from 'phoenix';
import { SOCKET_URL } from '../config';


const useTradeHistory = market => {
  const [tradeHistory, setTradeHistory] = useState([] );

  useEffect(() => {
    const socket = new Socket(`${SOCKET_URL}/orderbook/socket`);
    socket.connect();
    socket.onOpen(() => {
      console.log('CONNECTED SUCCESSFULLY');
    });
    socket.onError(e => {
      console.log('Failed to connect to socket');
    });

    const channel = socket.channel('trade-hist:ALL-BRL', {});
    channel.join()
      .receive('ok', resp => { console.log('Joined successfully', resp); })
      .receive('error', resp => { console.log('Unable to join', resp); });

    channel.on('snapshot', payload => {
      if (market in payload && payload[market])
        setTradeHistory(payload[market]);
    });

    return () => socket.disconnect();
  }, [market]);

  return tradeHistory;
};

export default useTradeHistory;
