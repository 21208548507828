import { all } from 'redux-saga/effects';

import watchData from './data/saga';
import watchMessage from './message/saga';
import watchService from './service/saga';


function* appSagas() {
  yield all([
    watchData(),
    watchMessage(),
    watchService(),
  ]);
}

export default appSagas;
