import { UPDATE_TRANSACTION_FORM_OFFSET_TOP, UPDATE_FORM_DATE } from './actionTypes'

const initialState = {
  offsetTop: 0,
  formData: {},
}

const formReducer = (state=initialState, action) => {
  switch (action.type) {
    case UPDATE_TRANSACTION_FORM_OFFSET_TOP:
      return {
        ...state,
        offsetTop: action.offsetTop
      }
    case UPDATE_FORM_DATE:
      return {
        ...state,
        formData: action.payload,
    };
    default:
      return state
  }
}

export default formReducer
