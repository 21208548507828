import React from 'react';
import PropTypes from 'prop-types';
import { compose, pure } from 'recompose';
import { useLocation } from 'react-router-dom';

import { withStyles, withTheme } from '@material-ui/core/styles';

import Divider from '@material-ui/core/Divider';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import InfoTooltip from '../../components/InfoTooltip';
import DrawerLinkText from './DrawerLinkText';
import DrawerLink from './DrawerLink';
import SvgIcon from '../../components/SvgIcon/SvgIcon';

const styles = theme => ({
  toolbar: theme.mixins.toolbar,
  container: {
    color: theme.palette.secondary.main,
    backgroundColor: theme.palette.primary.dark,

  },
  activeContainer: {
    backgroundColor: theme.palette.grey[700],
    '&:hover': {
      backgroundColor: theme.palette.grey[900],
    },
  },
  content: {
    color: theme.palette.grey[500],
    marginRight: '2px'
  },
  activeContent: {
    fill: theme.palette.secondary.main,
    color: theme.palette.secondary.main,
    marginRight: '2px'
  },
});

const isActiveScene = (scene, subRoutes, fullRoute) => {
  if (subRoutes) {
    const fullRouteParts = fullRoute.split('/');
    return fullRouteParts.some(part => subRoutes.includes(part));
  }
  return scene === fullRoute.split('/')[1];
}


const DrawerLinkItem = ({
  primary,
  icon,
  classes,
  scene,
  currentScene,
  drawerNavigation,
  theme,
  tooltip,
  fullRoute,
  location,
  subRoutes,
  ...rest
}) => {
  const isActive = isActiveScene(scene, subRoutes, fullRoute);
  const contentClassName = isActive ? classes.activeContent : classes.content;
  const IconColor = isActive ? theme.palette.secondary.main : theme.palette.grey[500];
  return (
    <DrawerLink isActive={isActive} {...{ ...rest }}>
      {typeof icon === "string" ? (
        <SvgIcon icon={icon} color={IconColor} />
      ) : (
        <ListItemIcon classes={{ root: contentClassName }}>{icon}</ListItemIcon>
      )}

      <DrawerLinkText
        isActiveItem={isActive}
        primary={primary}
      />
      <ListItemSecondaryAction>
        {tooltip && <InfoTooltip listItemVariant {...tooltip} />}
      </ListItemSecondaryAction>
      <Divider />
    </DrawerLink>
  );
};


DrawerLinkItem.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

const enhance = compose(
  // withRouter,
  // translate(),
  pure,
  withStyles(styles),
  withTheme(),
);

export default enhance(DrawerLinkItem);
