import { compose, withHandlers } from 'recompose';
import { connect } from 'react-redux';

import { translate } from 'react-i18next';
import { handleAppBarMenu } from '../../redux/actions';
import WarningContainer from './WarningContainer';
import { withStyles } from '@material-ui/core';


const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  cardWarning: {
    width: '100%',
    maxWidth: '674px',
    margin: 'auto',
    boxShadow: 'rgba(0, 0, 0, 0.15) 0px 3px 3px 0px',
    [theme.breakpoints.down('sm')]: {
      minHeight: 330,
      width: '80%',
      display: 'flex',
    }
  },
  cardHeader: {
    position: 'absolute',
    top: '0%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 10,
  },
  cardContent: {
    padding: '70px 0px !important',
    width: '40%',
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    gap: '10px',
    '& .title':{
      fontSize: '20px',
      color: '#101010',
      lineHeight: '26px',
      padding: '0 8px',
    },
   '& .content': {
      fontSize: '13px',
      lineHeight: '18px',
    },
   '& button, a': {
      border: `2px solid ${theme.palette.secondary.main} !important` ,
      
      fontWeight: '500 !important',
      boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;',
      '&:hover': {
        borderColor: theme.palette.secondary.main,
      },
   },
   '& .first-button': {
      color: 'white',
      padding: '11px 12px !important',
   },
   '& .second-button': {
      padding: '11px 12px !important',
      '&:hover': {
       backgroundColor: 'rgba(250, 250, 250, 1)',
      },
   },
    [theme.breakpoints.down('sm')]: {
      padding: '40px 0px !important',
      width: '81%',
      height: '100%',
      margin: 'auto',
      gap: '20px',
      '& .title':{
      padding: '0 20px',
      },
    }
  },
  text: {
    color: '#696F74',
    fontSize: '14px',
    fontWeight: '400',
  },
  buttonContainer:{
    gap: '10px',
    [theme.breakpoints.down('sm')]: {
        gap: '12px',
    }
  },
  gridContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '85%',
    [theme.breakpoints.down('sm')]: {
        height: '70%',
        width: '100%',
    }
  },
})

const mapStateToProps = state => ({
    anchorEl: state.layout.appBar.anchorEl
  });
  
  const mapDispatchToProps = (dispatch) => ({
    setAnchorEl: (anchorEl, isCurrencySelectorFilter, coinsSelectorExtraData) =>
      dispatch(handleAppBarMenu(anchorEl, isCurrencySelectorFilter, coinsSelectorExtraData)),
  });
  
  const enhance = compose(
    withStyles(styles),
    translate(),
    connect(mapStateToProps, mapDispatchToProps),
    withHandlers({
      handleClick: ({ setAnchorEl }) => (value, coinsSelectorExtraData) => { 
        setAnchorEl(value, true, coinsSelectorExtraData)},
    }),
  );
  
export default enhance(WarningContainer);
