import React from 'react';
import {
  compose,
  withProps,
} from 'recompose';

import { connect } from 'react-redux';
import { translate } from 'react-i18next';

import { reduxForm } from 'redux-form';

import Button from '@material-ui/core/Button';
import ListItem from '@material-ui/core/ListItem';
import { generateApiTokenRequested } from '../../../redux/actions';
import validate from '../../../utils/validate';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

  import renderBranch from '../../../components/renderBranch';

const ApiToken = (props) => {
  const {
    handleSubmit,
    submitting,
    invalid,
    token,
    t,
  } = props;
  return (
    <ListItem>
        <Button
          onClick={(e) => handleSubmit(e)(token)}
          variant='raised'
          color='primary'
          style={{width:'100%', minHeight: '80% !important', height: '80%'}}
          disabled={(submitting || invalid)}
        >{t('common.requestApiToken')}&nbsp;<FontAwesomeIcon icon='robot' style={{marginBottom:'4px', marginLeft:'8px'}}/></Button>
    </ListItem>
  );
};


const mapDispatchToProps = dispatch => ({
  handleSubmit: (e) => (token) => {
    e.preventDefault();
    dispatch(generateApiTokenRequested({auth_token: token}))
  },
});

const mapStateToProps = ({ credentials: { token } }) => ({
  token,
});

const schemas = {
  token: {
    type: String,
    required: true,
  }
};

const enhance = compose(
  translate(),
  connect(mapStateToProps, mapDispatchToProps),
  renderBranch(['token']),
  withProps(({ token }) => ({
    initialValues: {
      auth_token : token,
    },
  })),
  reduxForm({
    form: 'generateTradingKey',
    validate: validate(schemas),
  }),
);

export default enhance(ApiToken);
