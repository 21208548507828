import * as R from 'ramda';
import serializeBank from '../../../utils/serializeBankAccount';

const initialState = {
  hasAccount: false,
  isLoaded: false
};


const updateBankAccount = (state, { response: { address } }) => {
  if (R.isEmpty(address)) {
    return {...state, isLoaded: true}
  }

  return R.merge(state, {
    ...serializeBank(address),
    hasAccount: true,
    isLoaded: true
  });
};


const bankAccountReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_SERVICE_SUCCEED':
      switch (action.requestType) {
        case 'fetchBankAccount':
          return updateBankAccount(state, action);

        default:
          return state;
      }

    // When user does not have a bank account, API fails
    // throwing "ERROR_CREATING_ADDRESS"
    case 'FETCH_SERVICE_FAILED':
      switch (action.requestType) {
        case 'fetchBankAccount':
          return {...state, hasAccount: false, isLoaded: true}
        default:
          return state;
      }
    default:
      return state;
  }
};

export default bankAccountReducer;
