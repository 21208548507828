import React, { useEffect, useState } from 'react';
import { pure, compose } from 'recompose';
import * as R from 'ramda';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
  fixedSearchInput: {
    position: 'fixed',
    background: '#fff',
    zIndex: 1,
  },
});

const OptionsList = props => {
  const {
    input: { name, ...input },
    selectOptions,
    keyName = 'key',
    labelName = 'label',
    setAnchorEl,
    setSearch,
    search,
  } = props;

  const isValidBank = selectOptions && selectOptions.length > 0;

  return (
    <div>
      <div style={{ paddingLeft: 10, paddingRight: 10 }}>
        <TextField
          id={`search-${name}`}
          fullWidth
          value={search}
          label="Search"
          onChange={e => setSearch(e.target.value)}
        />
      </div>
      <List>
        {isValidBank
          ? selectOptions.map(selectOption => {
              const [key, itemLabel] = R.props([keyName, labelName], selectOption);
              if (selectOption) {
                return (
                  <ListItem
                    key={key}
                    button
                    onClick={() => {
                      input.onChange({ key, label: itemLabel });
                      setAnchorEl(null);
                    }}
                  >
                    <ListItemText primary={`${key} - ${itemLabel}`} />
                  </ListItem>
                );
              }
            })
          : (
              <ListItem button disabled>
                <ListItemText primary={`Banco não encontrado`} />
              </ListItem>
            )
          }
      </List>
    </div>
  );
};

const enhance = compose(
  pure,
  withStyles(styles),
);

export default enhance(OptionsList);
