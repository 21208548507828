import React from 'react'; 
import Typography from '@material-ui/core/Typography';

const OtpInstructions = ({isMobile}) => {
  return (
    <div>
      <Typography style={{color: '#101010',
                          fontSize: 13,
                          fontWeight: 500,
                          width: (isMobile ? '90%' : '75%'),
                          marginLeft: 15,
                          marginTop: 'auto',
                          marginBottom: 'auto'
                          }} variant="body2">
          <h3>Instruções:</h3>
          <ul>
            <li>Baixe o aplicativo de autenticação <a href="https://authy.com/download/" rel="noopener noreferrer" target="_blank">Authy</a> ou o Google Authenticator</li>
            <li>Escaneie ou copie o código QR mostrado { isMobile ? 'acima' : 'ao lado' }</li>
            <li>{isMobile ? 'Logo abaixo,' :'Do lado direito da tela,' } digite o código mostrado no aplicativo de autenticação e clique em "Salvar"</li>
          </ul>
      </Typography>
    </div>
  );
};

export default OtpInstructions;
