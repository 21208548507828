import service from '../service';

/* eslint-disable camelcase */
const resetPassword = async ({ ...form }) => {
  const options = {
    cmd: 'send_rstpass',
    method: 'POST',
  };
  const content = await service(options)(form);

  return content;
};


export default resetPassword;
