import * as R from 'ramda';
import * as actions from './actionTypes';

const initialState = {
  sellOrdersSerialized: [],
  buyOrdersSerialized: [],
  sellOpenOrders: [],
  buyOpenOrders: [],
  executedOrders: [],
  RFQ:{}
};


const mapNewOrders = (state, action) => (r, orderKey) => {
  const prevOrders = state[orderKey]
  const mapped = R.map(
    (newOrder) => {
      const index = R.findIndex(
        R.pipe(
          R.omit(['isNew']),
          R.equals(newOrder)
        )
      )(prevOrders)
       return index === -1
        ? { ...newOrder, isNew: true }
        : newOrder
    }
  )(action[orderKey])

  return {
    ...r,
    [orderKey]: mapped
  }
}

const updateOrders = (state, { type, ...action }) => {

  const keys = R.pipe(
    R.pickBy(Array.isArray),
    R.keys
  )(action)

  const _mapNewOrders = mapNewOrders(state, action)

  const updatedOrders = R.reduce(_mapNewOrders, {}, keys)

  return R.merge(state, { ...action, ...updatedOrders });
};

const updateQuote = (state, { response }) => {
  if (R.isEmpty(response)) {
    return {...state}
  }
  return R.merge(state, 
   { RFQ: response });
};


const getOptimisticProps = orderType => {
  const orderTypeLower = orderType.toLowerCase();

  return {
    openOrdersName: `${orderTypeLower}OpenOrders`,
    ordersSerializedName: `${orderTypeLower}OrdersSerialized`,
  };
};

const getSortAlgorithm = orderType => {
  return orderType === "BUY" ? R.descend(R.prop('price')) : R.ascend(R.prop('price'));
}

const getOptimisticOrderToCancel = order => orderTypeState => {
  return R.findIndex(R.propEq('id', order.id), orderTypeState);
};

const optimisticCancelOrder = (state, action) => {
  const { orderType = '', order } = action;
  const { openOrdersName, ordersSerializedName } = getOptimisticProps(orderType);

  const openOrderToCancel = R.find(R.propEq('id', order.id), state[openOrdersName]);
  const orderSerializedToCancel = R.find(R.propEq('id', order.id), state[ordersSerializedName]);

  const optimisticOrderToCancel = getOptimisticOrderToCancel(order);

  const openOrderToCancelId = optimisticOrderToCancel(state[openOrdersName]);
  const orderSerializedToCancelId = optimisticOrderToCancel(state[ordersSerializedName]);

  return R.merge(state, {
    [openOrdersName]: R.update(openOrderToCancelId, { ...openOrderToCancel, saving: true }, state[openOrdersName]),
    [ordersSerializedName]: R.update(orderSerializedToCancelId,
      { ...orderSerializedToCancel, saving: true }, state[ordersSerializedName]),
  });
};

const optimisticAddOrder = (state, action) => {
  const { orderType = '', order } = action;

  const { openOrdersName, ordersSerializedName } = getOptimisticProps(orderType);

  const openOrders = state[openOrdersName];
  const ordersSerialized = state[ordersSerializedName];
  const optimisticOrder = {
    ...order,
    saving: true,
    exec_amount: 0,
    isMine: true
  };

   return R.merge(state, {
       [openOrdersName]: R.sort(getSortAlgorithm(orderType), R.prepend(optimisticOrder, openOrders)),
       [ordersSerializedName]: R.sort(getSortAlgorithm(orderType), R.prepend(optimisticOrder, ordersSerialized)),
     });
};

const optimisticRemoveOrder = (state, action) => {
  const { orderType = '', order } = action;

  const { openOrdersName, ordersSerializedName } = getOptimisticProps(orderType);

  const optimisticOrderToCancel = getOptimisticOrderToCancel(order);

  const openOrderToCancelId = optimisticOrderToCancel(state[openOrdersName]);
  const orderSerializedToCancelId = optimisticOrderToCancel(state[ordersSerializedName]);


  const newState = R.merge(state, {
    [openOrdersName]: R.remove(openOrderToCancelId, 1, state[openOrdersName]),
    [ordersSerializedName]: R.remove(orderSerializedToCancelId, 1, state[ordersSerializedName]),
  });
  return newState;
  // return R.merge(state, {
  //   [openOrdersName]: R.prepend(optimisticOrder, openOrders),
  //   [ordersSerializedName]: R.prepend(optimisticOrder, ordersSerialized),
  // });
};

const ordersReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.UPDATE_ORDERS:
      return updateOrders(state, action);

    case actions.UPDATE_QUOTE:
      return updateQuote(state, action);

    case actions.FAIL_ON_QUOTE:
        return updateQuote(state, action);

    case actions.OPTIMISTIC_ADD_ORDER:
      return optimisticAddOrder(state, action);

    case actions.OPTIMISTIC_REMOVE_ORDER:
      return optimisticRemoveOrder(state, action);

    case actions.OPTIMISTIC_CANCEL_ORDER:
      return optimisticCancelOrder(state, action);

    default:
      return state;
  }
};

export default ordersReducer;
