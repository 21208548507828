const FETCH_PROFILE = 'FETCH_PROFILE';
const ENABLE_SYNC_PROFILE = 'ENABLE_SYNC_PROFILE';
const DISABLE_SYNC_PROFILE = 'DISABLE_SYNC_PROFILE';

const FETCH_PROFILE_SUCCEED = 'FETCH_PROFILE_SUCCEED';
const UPDATE_PROFILE = 'UPDATE_PROFILE';

const CONFIRM_UPDATE_PROFILE_REQUESTED = 'CONFIRM_UPDATE_PROFILE_REQUESTED';
const CONFIRM_UPDATE_PROFILE_SUCCEED = 'CONFIRM_UPDATE_PROFILE_SUCCEED';
const CONFIRM_UPDATE_PROFILE_FAILED = 'CONFIRM_UPDATE_PROFILE_FAILED';

const UPDATE_PROFILE_REQUESTED = 'UPDATE_PROFILE_REQUESTED';
const UPDATE_PROFILE_SUCCEED = 'UPDATE_PROFILE_SUCCEED';
const UPDATE_PROFILE_FAILED = 'UPDATE_PROFILE_FAILED';


const GENERATE_OTP_REQUESTED = 'GENERATE_OTP_REQUESTED';
const GENERATE_OTP_SUCCEED = 'GENERATE_OTP_SUCCEED';
const GENERATE_OTP_FAILED = 'GENERATE_OTP_FAILED';


const ACTIVATE_OTP_REQUESTED = 'ACTIVATE_OTP_REQUESTED';
const ACTIVATE_OTP_SUCCEED = 'ACTIVATE_OTP_SUCCEED';
const ACTIVATE_OTP_FAILED = 'ACTIVATE_OTP_FAILED';
const GENERATE_API_TOKEN_REQUESTED = 'GENERATE_API_TOKEN_REQUESTED';
const GENERATE_API_TOKEN = 'GENERATE_API_TOKEN';

const START_PROFILE_CHANGES_VALIDATION_TOKEN = 'START_PROFILE_CHANGES_VALIDATION_TOKEN';

export {
  FETCH_PROFILE,
  ENABLE_SYNC_PROFILE,
  DISABLE_SYNC_PROFILE,

  FETCH_PROFILE_SUCCEED,

  UPDATE_PROFILE,

  CONFIRM_UPDATE_PROFILE_REQUESTED,
  CONFIRM_UPDATE_PROFILE_SUCCEED,
  CONFIRM_UPDATE_PROFILE_FAILED,

  UPDATE_PROFILE_REQUESTED,
  UPDATE_PROFILE_SUCCEED,
  UPDATE_PROFILE_FAILED,

  GENERATE_OTP_REQUESTED,
  GENERATE_OTP_SUCCEED,
  GENERATE_OTP_FAILED,

  ACTIVATE_OTP_REQUESTED,
  ACTIVATE_OTP_SUCCEED,
  ACTIVATE_OTP_FAILED,

  GENERATE_API_TOKEN_REQUESTED,
  GENERATE_API_TOKEN,
  START_PROFILE_CHANGES_VALIDATION_TOKEN,

};
