export const CRYPTO_CONVERTION_AMOUNT_TYPES = {
    VOLUME: "VOLUME",
    FROM: "FROM",
    TO: "TO",
}

export const cryptoConvertionAmountTypesToInputNames = {
    [CRYPTO_CONVERTION_AMOUNT_TYPES.VOLUME]: "volumeAmount",
    [CRYPTO_CONVERTION_AMOUNT_TYPES.FROM]: "fromAmount",
    [CRYPTO_CONVERTION_AMOUNT_TYPES.TO]: "toAmount",
}
