export const styles = theme => ({
    row: {
      display: 'flex',
      alignItems: 'center',
    },
    field: {
      display: 'flex',
      alignItems: 'center',
      width:'100%',

      '@media screen and (max-width: 1100px)': {
        justifyContent: 'center',
        flexDirection:'column',
        alignItems:'center',
        width:'80%',
        margin: 'auto',
      },
    },
    paper: {
      overflowX: 'auto',
      margin: theme.spacing.unit * 3,
    },
    nothingToShow: {
      display: 'flex',
      alignContent: 'center',
      justifyContent: 'center',
      padding: '24px 0',
    },
    tabs: {
      width: '100%'
    },
    optionsContainer: {
      width: '100%',
      display: 'grid',
      alignItems: 'center',
      rowGap: '12px',
      paddingBottom: '16px',

      '@media screen and (max-width: 1100px)': {
        justifyContent: 'center',
      }
    },
    containerSelects: {
      gap: '24px',
      width: '100%',
      paddingLeft: '24px',

      '@media screen and (max-width: 768px)': {
        flexDirection: 'column',
        gap: '16px',
        paddingLeft: '0px',
      }
    },
    selectContent: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: '15px',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      }  
    },
    selectButton: {
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        maxWidth: '100px',
      },
    },
    selectContentCurrency: {
      minWidth: '150px',
    },
    submitButton: {
      '@media screen and (max-width: 500px)': {
        width: '100%',
      },
    },
    resetFilterButton: {
      color: theme.palette.secondary.main,
      fontWeight: '500',
      textDecoration: 'underline',
      cursor: 'pointer',

      [theme.breakpoints.down('sm')]: {
        textAlign: 'left'
    }
    },
  });