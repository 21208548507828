import { FETCH_USER_CARDS, REMOVE_CARDS, UPLOAD_STATUS_CARD, FETCH_CASHBACK, FETCH_CASHBACK_INFO, FETCH_CARD_LIMITS } from "./actionsTypes";

export const fetchUserCards = () => ({
  type: FETCH_USER_CARDS,
});

export const fetchCardLimits = () => ({
  type: FETCH_CARD_LIMITS,
});

export const uploadStatusCard = ( status, id, pin ) => ({
  type: UPLOAD_STATUS_CARD,
  status,
  id,
  pin
});

export const removeCards = () => ({
  type: REMOVE_CARDS,
})

export const fetchCashback = () => ({
  type: FETCH_CASHBACK,
  
});

export const fetchCashbackInfo = () => ({
  type: FETCH_CASHBACK_INFO,
});