import {
  FETCH_CURRENCIES_REQUESTED,
  FETCH_MARKET_REQUESTED
} from './actionTypes';

const fetchCurrencies = () => ({
  type: FETCH_CURRENCIES_REQUESTED,
});

const fetchMarket = () => ({
  type: FETCH_MARKET_REQUESTED,
});

export default {
  fetchCurrencies,
  fetchMarket
};

export {
  fetchCurrencies,
  fetchMarket
};
  