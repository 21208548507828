import service from '../service';

/* eslint-disable camelcase */
const resendValidationMail = async ({ ...form }) => {
  const options = {
    cmd: 'resend_validation',
    method: 'POST',
  };
  const content = await service(options)(form);

  if (content) {
    return { message_cod: 'EMAIL_SENT' };
  }
  const err = { message_cod: 'ERROR_SENDING_EMAIL' };
  throw err;
};


export default resendValidationMail;
