import { put, takeLatest } from 'redux-saga/effects';
import { FETCH_BALANCE } from './actionTypes';
import { fetchService } from '../../actions';
import { updateBalance } from './actions';


function* fetchBalanceSaga(_action) {
  try {
    yield put(fetchService({
      requestType: 'balance',
      paramns: {
        privateService: true,
        options: {
          cmd: 'balance',
          method: 'POST',
        },
      },
    }));
  } catch (error) {
    console.error('fetchBalanceSaga', error);
  }
}

function* removeBalanceSaga() {
  try {
    yield put(updateBalance({}));
  } catch (error) {
    console.error('removeBalanceSaga', error);
  }
}

function* watchBalance() {
  yield takeLatest(FETCH_BALANCE, fetchBalanceSaga);
  yield takeLatest('REMOVE_CREDENTIALS', removeBalanceSaga);
}

export default watchBalance;
