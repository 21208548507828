import React from 'react';
import { compose, pure} from 'recompose';
import { translate } from 'react-i18next';

import CredentialsFormAndOtpButton from './CredentialsFormAndOtpButton';

const ExpandingOptions = props => {

  const {
    actualTab
  } = props;

  return(
    <div>
      <CredentialsFormAndOtpButton actualTab={actualTab}/>
    </div>
  );

}

const enhance = compose(
  translate(),
  pure,
);

export default enhance(ExpandingOptions);
