export const styles = (theme) => ({
  chartContainer: {
    margin: "19.40px 9px",
  },
  tabStyle: {
    borderRadius: "5px",
    marginLeft: "6px",
    marginRight: "6px",
    marginBottom: "6px",
    transition:
      "filter 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  },
  tabNotSelected: {
    borderRadius: "5px",
    border: `2px solid ${theme.palette.secondary.main}`,

    "&:hover": {
      backgroundColor: "#e6e6e7",
      boxShadow:
        "0px 1px 5px 0px rgba(0, 0, 0, 0.2),0px 2px 2px 0px rgba(0, 0, 0, 0.14),0px 3px 1px -2px rgba(0, 0, 0, 0.12)",
    },
  },
  tabSelected: {
    backgroundColor: theme.palette.secondary.main,
    borderRadius: "5px",

    "&:hover": {
      filter: "brightness(90%)",
      boxShadow:
        "0px 1px 5px 0px rgba(0, 0, 0, 0.2),0px 2px 2px 0px rgba(0, 0, 0, 0.14),0px 3px 1px -2px rgba(0, 0, 0, 0.12)",
    },
  },
  tabNotSelectedText: {
    color: "#696F74",
    textAlign: "center",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px",
    },
  },
  colorWhite: {
    color: "#FFFFFF",
    textAlign: "center",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px",
    },
  },
  tabContainer: {
    margin: "30px 0",
  },
});
