import { connect } from 'react-redux';
import * as R from 'ramda';
import { compose, mapProps, lifecycle, withHandlers, withState } from 'recompose';

import { updateBankAccount, fetchBankAccount } from '../../redux/user/bankAccount/actions';
import unSerializeBankAccount from '../../utils/unserializeBankAccount';

const mapStateToProps = ({ user: { bankAccount } }) => ({
  bankAccount,
});


const mapDispatchToProps = dispatch => ({
  onSubmit: bankAccount => dispatch(updateBankAccount(unSerializeBankAccount(bankAccount))),
  fetchBankAccount: () => dispatch(fetchBankAccount()),
});

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withState('disableSubmit','setDisableSubmit', false),
  mapProps(
    (
      { bankAccount, ...props }) => {
      if (R.not(R.isEmpty(bankAccount))) {
        const {
          bankName,
          bank,
          hasAccount,
          agency,
          account,
          is_CC
        } = bankAccount;
        return R.merge(props, {
          // initialValues: serialize(bankAccount),
          initialValues: {
            bank: {
              key: bank,
              label: bankName,
            },
            agency,
            account,
            is_CC: is_CC === 'PP' || is_CC === false ? 'PP' : 'CC'
          },
          hasAccount,
        });
      }
      return props;
    }),
    withHandlers({
      onSubmit: ({ onSubmit, setDisableSubmit }) => (form) => {
          setDisableSubmit(true);
          onSubmit(form);
          setTimeout(() => setDisableSubmit(false), 5000);
      }
    }),
  lifecycle({
    componentDidMount() {
      this.props.fetchBankAccount();
    },
  }),
);

export default enhance;
