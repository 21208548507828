import service from '../service';

/* eslint-disable camelcase */
const recoverPassword = async form => {
  const options = {
    cmd: 'reset_pass',
    method: 'POST',
  };

  const content = await service(options)(form);
  return content;
};

export default recoverPassword;
