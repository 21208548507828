import * as R from 'ramda';

export const getFormat = mimetype => {
  const format = R.toUpper(R.split('/', mimetype)[1]);
  if (format === 'JPEG') {
    return 'JPG';
  }
  return format;
};

export const getImageData = url => R.split('base64,', url)[1];
