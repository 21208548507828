import React from 'react';
import PropTypes from 'prop-types';
import { compose, pure } from 'recompose';
import { withStyles } from '@material-ui/core/styles';

import Hidden from '@material-ui/core/Hidden';
import Volume from './Volume';
import Variation from './Variation';
import Price from './Price'

const styles = theme => ({
  root: {
    flex: 1,
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.up('md')]: {
      justifyContent: 'flex-start',
    },

  },
  content: {
    [theme.breakpoints.up('md')]: {
      marginRight: theme.spacing.unit * 3,
    },
  },
});

const BtcTicker = props => {
  const {
    market,
    ticker,
    classes,
    marketConfig,
  } = props;
  // const lastPrice = Number(ticker.ticker.last);
  // const lastDayPrice = ticker.lastDaySummary.closing;
  // const diff = lastPrice - lastDayPrice;
  // const variation = (diff / lastDayPrice) * 100;
  const useTicker = ticker ? getTicker(ticker) : null;
  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Volume
          marketConfig={marketConfig}
          volume={useTicker ? useTicker.vol : null}
        />
      </div>
      <div className={classes.content}>
        <Price
          marketConfig={marketConfig}
          priceType="last"
          price={useTicker ? useTicker.last : null}
        />
      </div>
      <Hidden smDown>
        <div className={classes.content}>
          <Price
            marketConfig={marketConfig}
            priceType="low"
            price={useTicker ? useTicker.low : null}
          />
        </div>
        <div className={classes.content}>
          <Price
            marketConfig={marketConfig}
            priceType="high"
            price={useTicker ? useTicker.high : null}
          />
        </div>
      </Hidden>
      <div className={classes.content}>
        <Variation variation={useTicker ? useTicker.var : null} />
      </div>
    </div>
  );
};

const getTicker = ticker =>  {
  if (ticker) {
    return ticker;
  }
  /*Using NaN to activate loading*/
  return {
    last: NaN,
    low: NaN,
    high: NaN,
    var: NaN,
    vol: NaN,
  }
}

const enhance = compose(
  pure,
  withStyles(styles),

);

BtcTicker.propTypes = {
  ticker: PropTypes.shape({
    vol: PropTypes.number,
    last: PropTypes.number,
    var: PropTypes.number,
  }).isRequired,
};

export default enhance(BtcTicker);
