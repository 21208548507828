import React from 'react'
import { translate } from 'react-i18next'
import { withStyles } from '@material-ui/core/styles'
import { Typography, Button } from '@material-ui/core'
import Card from '@material-ui/core/Card'
import classNames from 'classnames';
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { removeCredentials } from '../redux/actions'

const styles = theme => ({
  fadeBack:{
    position: 'absolute',
    background: 'rgb(252,252,252)',
    borderRadius:'10px',
  },
  cardPlacement:{
    zIndex: 1050,
    width: 220,
    height: 130,
    marginTop: 18,
    left: '53%',
  },
    cardHeader:{
    textAlign: 'center',
    margin: 6,
    fontWeight: 500,
    borderBottom: '1px solid rgba(0,0,0,0.2)',
    fontSize: 18
  },
  cardBlockedContent:{
    textAlign: 'center',
    margin: 6,
    fontSize: 15
  },
  linkButton:{
    marginTop: 5,
    marginBottom: 5
  }
})

const UnavailableFeatureDialog = ({ t, classes, featureType, cardPlacement, goToLogin }) => {
  const customContentMessage = t(`openUserFeature.dialogContent.${featureType}`)
  return (
        <Card
          className={classNames(cardPlacement ? cardPlacement : classes.cardPlacement,
            classes.fadeBack)}>
          <Typography className={classes.cardHeader}>
            {t(`openUserFeature.dialogHeader.${featureType}`)}
          </Typography>
          <Typography className={classes.cardBlockedContent}>
            {customContentMessage !== `openUserFeature.dialogContent.${featureType}` ? t(`openUserFeature.dialogContent.${featureType}`) : t('openUserFeature.dialogContent.default')}
            <Button
              onClick={() => goToLogin()}
              className={classes.linkButton}
              color='secondary'
              variant='raised'
            >
              {t('openUserFeature.dialogContent.loginOrRegister')}
            </Button>
          </Typography>
        </Card>
  )
}

const mapDispatchToProps = (dispatch) => ({
  goToLogin: () => dispatch(removeCredentials())
})

const enhance = compose(
  connect(null, mapDispatchToProps),
  withStyles(styles),
  translate()
)

export default enhance(UnavailableFeatureDialog)
