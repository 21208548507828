import React, { useState, useEffect } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import classNames from 'classnames';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import green from '@material-ui/core/colors/green';
import amber from '@material-ui/core/colors/amber';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import WarningIcon from '@material-ui/icons/Warning';
import { withStyles } from '@material-ui/core/styles';
import { translate } from 'react-i18next';

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const styles1 = theme => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.dark,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing.unit * 3,
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
  marginTop: {
    marginTop: 65,
  },
  noWrap: {
    flexWrap: 'nowrap',
  },
});

// Used to avoid showing alert many times mainly when page is reloaded
const isDismissedAlert = alert => {
  const dismissedAlert = localStorage.getItem("dismissed-alert");
  if (dismissedAlert && alert) {
    const dismissedAlertObject = JSON.parse(dismissedAlert);
    return dismissedAlertObject.hash === alert.hash;
  }
  return false;
}

const updateDismissedAlert = alert => {
  if (alert && !isDismissedAlert(alert))
    localStorage.setItem("dismissed-alert", JSON.stringify(alert));
}

function MySnackbarContent(props) {
  const {
    classes, className, message, onClose, variant, ...other
  } = props;
  const Icon = variantIcon[variant];

  return (
    <SnackbarContent
      className={classNames(classes[variant], className, classes.marginTop, classes.noWrap)}
      aria-describedby="client-snackbar"
      message={(
        <span id="client-snackbar" className={classes.message}>
          <Icon className={classNames(classes.icon, classes.iconVariant)} />
          {message}
        </span>
)}
      action={[
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          className={classes.close}
          onClick={onClose}
        >
          <CloseIcon className={classes.icon} />
        </IconButton>,
      ]}
      {...other}
    />
  );
}

const MySnackbarContentWrapper = withStyles(styles1)(MySnackbarContent);

const styles2 = theme => ({
  margin: {
    margin: theme.spacing.unit,
  },
});

function Alerts(props) {
  const { alerts, t } = props;
  const [open, setOpen] = useState(true);
  const alertSnack = alerts.length > 0 ? alerts[0] : null;

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    updateDismissedAlert(alertSnack);
    setOpen(false);
  };

  useEffect(() => {
    if (alertSnack && alertSnack.message && !isDismissedAlert(alertSnack)) {
      setOpen(true);
    }
  }, [alertSnack]);

  return alertSnack && alertSnack.message && !isDismissedAlert(alertSnack) ? (
    <div>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={open}
        onClose={handleClose}
      >
        <MySnackbarContentWrapper
          onClose={handleClose}
          variant={alertSnack.variant || 'info'}
          message={alertSnack.message.includes(':') ?  alertSnack.message : t(alertSnack.message)}
        />
      </Snackbar>
    </div>
  ) : null;
}

const mapStateToProps = (state) => {
  return {
    alerts: state.layout.alerts,
  };
};


export default compose(
  translate(),
  withStyles(styles2),
  connect(mapStateToProps),
)(Alerts);
