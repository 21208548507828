export class CurrencyIssue {
  constructor({ currencySymbol, title, description, operationScreens }) {
    this.currencySymbol = currencySymbol;
    this.title = title;
    this.description = description;
    this.operationScreens = operationScreens;
  }

  static fromJson(json) {
    return new CurrencyIssue({
      currencySymbol: json["currencySymbol"],
      title: json["title"],
      description: json["description"],
      operationScreens: json["operationScreens"],
    });
  }
}
