import React from 'react';
import { compose, withState } from 'recompose';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Visibility from '@material-ui/icons/Visibility';

import TextFieldComponent from '../TextFieldComponent';


const PasswordField = ({
  showPassword,
  setShowPassword,
  forbidShowPassword = false,
  ...props
}) => {
  return (
    <TextFieldComponent
      {...props}
      type={showPassword ? 'text' : 'password'}
      InputProps={{
        endAdornment: !forbidShowPassword && (
          <InputAdornment position="end">
            <IconButton
              aria-label="Toggle password visibility"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

const enhance = compose(
  withState('showPassword', 'setShowPassword', false)
);


export default enhance(PasswordField);
