import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import * as R from 'ramda'

// TODO: Check if this is still necessary in trade
// const getDynamicDecimalScale = (currencyTickerLast, defaultValue) => {
//   if (currencyTickerLast <= 100) return 2;
//   else if (currencyTickerLast > 100 && currencyTickerLast <= 10000) return 4;
//   else return defaultValue ? defaultValue : BTC_SATOSHI_SCALE;
// };

const CriptoFormat = (props) => {
  const { value, decimalScale, className, prefix, decimalSeparator, thousandSeparator} = props


  const hasNumericInput = R.not(R.isEmpty(value))
  const valueAsString = hasNumericInput
    ? Number(value).toFixed(decimalScale)
    : ''

  return (
    <NumberFormat
      className={className}
      displayType="text"
      fixedDecimalScale
      prefix={prefix}
      decimalScale={decimalScale}
      decimalSeparator={decimalSeparator !== undefined ? decimalSeparator : ","}
      thousandSeparator={thousandSeparator !== undefined ? thousandSeparator : "."}
      isNumericString={true}
      value={valueAsString}
    />
  );
};

CriptoFormat.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  decimalScale: PropTypes.number.isRequired,
};

export default CriptoFormat;
