import { connect } from 'react-redux';
import { compose, lifecycle, withState } from 'recompose';
import { fetchExtract } from '../../redux/actions';
import { getCriptoCurrencies } from '../../models/Currency';



const mapStateToProps = state => ({
  extract: state.user.extract.extracts,
  hasFetchedCurrencies: state.currencies.hasFetchedCurrencies
});

const mapDispatchToProps = (dispatch) => ({
  fetchExtractData: (currencyList) => {
    const formRequest = {
      currency: `${currencyList.join()},BRZ,CBRL,REALT,RAS`,
      status:
        "PENDING,COMPLETE,WAITING_2FA,ERROR,BLOCKED, WAITING_OTP, WAITING_FACEMATCH, WAITING_LIVENESS,EXPIRED",
      type: "DEPOSIT,WITHDRAWAL,DEBIT,CREDIT",
    };
    dispatch(fetchExtract(formRequest))}
});

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withState("hasFetchedExtract", "setHasFetchedExtract", false),
  lifecycle({
    componentDidMount() {
      const currencies = this.props.currencies;
      const currenciesSymbolList = getCriptoCurrencies(currencies).map(
        (currency) => currency.symbol
      );
      this.props.fetchExtractData(currenciesSymbolList);
      if (this.props.hasFetchedCurrencies) {
        this.props.setHasFetchedExtract(true);
      }
    },
    componentWillReceiveProps(nextProps) {
      const {
        hasFetchedCurrencies,
        currencies,
        hasFetchedExtract,
        setHasFetchedExtract,
      } = nextProps;
      if (hasFetchedCurrencies && !hasFetchedExtract) {
        const currenciesSymbolList = getCriptoCurrencies(currencies).map(
          (currency) => currency.symbol
        );
        this.props.fetchExtractData(currenciesSymbolList);
        setHasFetchedExtract(true);
      }
    },
  })
);

export default enhance;
