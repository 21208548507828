import React from 'react';
import Grid from '@material-ui/core/Grid';

import Typography from '@material-ui/core/Typography';


const ExtractTypography = ({ children, ...props }) => {
  return (
    <Typography {...props}>{children}</Typography>
  );
};

const ExtractTableData = ({ caption, content, small }) => {
  return (
    <Grid item xs={small ? 5 : 8}>
      <div style={{ display: 'flex', flexDirection: 'column' }} >
        <ExtractTypography variant="caption" >{caption}</ExtractTypography>
        <ExtractTypography noWrap >{content}</ExtractTypography>
      </div>
    </Grid>

  );
};

export default ExtractTableData;
