import { locale } from 'moment';
import 'moment/locale/pt';
import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { reactI18nextModule } from 'react-i18next';
import resourcesToBackend from 'i18next-resources-to-backend';
import { TRANSLATION_ERRORS_URL } from './config';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(reactI18nextModule)
  .use(resourcesToBackend((language, namespace) => import(`./assets/locales/pt/translations.json`)))
  .init({
    fallbackLng: 'pt',
    load: 'languageOnly',
    // have a common namespace used around the full app
    ns: ['translations'],
    defaultNS: 'translations',
    // whitelist: ['pt', 'en'],
    // nonExplicitWhitelist: true,
    // debug: __DEV__,
    // debug: true,
    
    interpolation: {
      escapeValue: false, // not needed for react!!
    },
    react: {
      wait: true,
    },
  });

const getTranslationErrors = async () => {
  let errorsTranslationsPt = {
    expires: 0,
    errors: {},
  };
  try {
    if (typeof localStorage !== "undefined") {
      const cookieValue = localStorage.getItem("error_message_translates");
      if (cookieValue !== null) {
        const translationsJson = JSON.parse(cookieValue);
        const nowTimestamp = new Date().setDate(new Date().getDate());
        if (translationsJson.expires > nowTimestamp) {
          errorsTranslationsPt.errors = translationsJson.errors;
        }
      }
    }
    const hasNoErrors = Object.keys(errorsTranslationsPt.errors).length === 0;

    if (hasNoErrors) {
      const response = await fetch(TRANSLATION_ERRORS_URL);
      const errors = await response.json();
      for (const error in errors) {
        errorsTranslationsPt.errors[error] = errors[error].message.pt_br;
      }

      let date = new Date();
      errorsTranslationsPt.expires = date.setDate(date.getDate() + 7);

      localStorage.setItem(
        "error_message_translates",
        JSON.stringify(errorsTranslationsPt)
      );
    }

    i18n.addResourceBundle(
      "pt",
      "translations",
      errorsTranslationsPt.errors,
      false,
      false
    );
  } catch (error) {
    console.error("Erro ao adicionar traduções de erro:", error);
  }
};

i18n.on('languageChanged', languageChanged => {
  locale(languageChanged);
});

i18n.on('initialized', _ => {
  getTranslationErrors();
});

export default i18n;
