import moment from "moment";

function totalPurchasesLastNinetyDays(referrals) {
  const lastNinetyDays = moment().subtract(90, "days");

  return referrals.reduce((acc, curr) => {
    if (curr.card_target && moment(curr.card_target).isAfter(lastNinetyDays)) {
      acc += 1;
    }

    return acc;
  }, 0);
}

function getTotalPurchasesLastThirtyDays(cardLimits, t) {
  if (cardLimits.month_spent > 0) {
    return t(
      "screens.mgm.cashback.dailyCashback.modalFirstContentCardCollapse",
      {
        value: cardLimits.month_spent.toLocaleString("pt-BR", {
          minimumFractionDigits: 2,
        }),
      }
    );
  }

  return t(
    "screens.mgm.cashback.dailyCashback.modalNoPurchasesInLastThirtyDays"
  );
}

function getBuyMoreToEarnCashback(hasFirstMonthBonus, missionsData, t) {
  if (hasFirstMonthBonus) {
    return t("screens.mgm.cashback.dailyCashback.earnCashbackInFirstMonth");
  }

  if (missionsData.nextCardLevel) {
    return t(
      "screens.mgm.cashback.dailyCashback.modalSecondContentCardCollapse",
      {
        value: missionsData.missingCard,
        percentage: 0.1,
      }
    );
  }

  return t("screens.mgm.cashback.dailyCashback.completeCashbackCardLevels");
}

function getTotalPurchasesLastNinetyDays(referralsData, t) {
  const purchasesLastNinetyDays = totalPurchasesLastNinetyDays(
    referralsData.referrals
  );

  if (purchasesLastNinetyDays > 1) {
    return t(
      "screens.mgm.cashback.dailyCashback.modalFirstContentReferCollapsePlural",
      { count: purchasesLastNinetyDays }
    );
  }

  if (purchasesLastNinetyDays == 1) {
    return t(
      "screens.mgm.cashback.dailyCashback.modalFirstContentReferCollapseSingular"
    );
  }

  return t("screens.mgm.cashback.dailyCashback.noReferralsInLastNinetyDays");
}

function getMissingReferralsToEarnCashback(
  missionsData,
  winValuePerIndication,
  t
) {
  if (missionsData.nextMgmLevel) {
    if (missionsData.missingMgm > 1) {
      return t(
        "screens.mgm.cashback.dailyCashback.modalSecondContentReferCollapsePlural",
        {
          count: missionsData.missingMgm,
          cashback: missionsData.nextMgmLevel,
          value: winValuePerIndication,
        }
      );
    }

    return t(
      "screens.mgm.cashback.dailyCashback.modalSecondContentReferCollapseSingular",
      {
        cashback: missionsData.nextMgmLevel,
        value: winValuePerIndication,
      }
    );
  }

  return t(
    "screens.mgm.cashback.dailyCashback.completeCashbackIndicationLevels"
  );
}

export const conditionals = {
  getTotalPurchasesLastThirtyDays,
  getBuyMoreToEarnCashback,
  getTotalPurchasesLastNinetyDays,
  getMissingReferralsToEarnCashback,
};
