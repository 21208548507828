import * as R from 'ramda';

const initialState = {
  BTC: {},
  BRL: {},
  ETH: {},
  USDT: {},
  BNB: {},
  CRZO: {},
  PAXG: {},
  SLP: {},
  AXS: {},
  CAKE: {},
  UNI: {},
  SOL: {},
  POLIS: {},
  ATLAS: {},
  ABFY: {},
  ADA: {},
  LUNA: {},
  LUNC: {},
  GMT: {},
  BUSD: {},
  // ELLO: {},
  USDC: {},
  USDBP: {},
  BRZ: {},
  DOT: {},
  MATIC: {},
  LINK: {},
  XRP: {},
};

const updateLimits = (state, { response }) => {
  return R.merge(state, response);
};

const limitsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_SERVICE_SUCCEED':
      switch (action.requestType) {
        case 'fetchFees':
          return updateLimits(state, action);

        default:
          return state;
      }
    default:
      return state;
  }
};

export default limitsReducer;
