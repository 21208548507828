import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import {
  compose, pure, withProps, lifecycle,
} from 'recompose';
import { translate } from 'react-i18next';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import { enableSyncProfile, disableSyncProfile } from '../../../../redux/actions';
import { withStyles } from '@material-ui/core/styles';

import ProfileToolbar from '../../components/ProfileToolbar';
import DocListItem from './DocListItem';

const styles = _theme => ({
  root: {
    marginTop: '-18px',
    marginBottom: '-5px'
  },
  rootItem: {
    fontSize: 11,
    marginRight: '25%'
  }
});

const DocumentsForm = props => {
  const {
    t,
    clearFields,
    documents,
    classes
  } = props;

  return (
    <div>
      <ProfileToolbar title={t('documents.documentation')} />
      <List dense>
      <Typography color="gray" variant="body2" style={{paddingLeft: '10px'}}>
          Lembrete: Para que seus documentos sejam adicionados na lista de verificação é necessário que envie todos os documentos solicitados!
      </Typography>
        {R.values(R.mapObjIndexed((userDoc, userDocKey) => {
          return (
            <React.Fragment>
              <DocListItem
                key={userDocKey}
                status={userDoc}
                t={t}
                clearFields={clearFields}
                form={userDocKey}
                label={t(`documents.${userDocKey}`)}
              />
              <ListItem classes={{root: classes.root}} >
                <ListItemText classes={{root: classes.rootItem}} primary={t(`documents.subs.${userDocKey}`)} />
              </ListItem>
            </React.Fragment>  
            );
        }, documents))}
      </List>
    </div>
  );
};

const mapStateToProps = ({ user: { documents, profile: { company } } }) => ({
  documents,
  company,
});

// const mapDispatchToProps = dispatch => ({
//   onSubmit: doc => dispatch(uploadDocumentRequested(doc)),
// });

const mapDispatchToProps = dispatch => ({
  startSyncProfile: () => dispatch(enableSyncProfile()),
  stopSyncProfile: () => dispatch(disableSyncProfile()),
});

const enhance = compose(
  translate(),
  connect(mapStateToProps, mapDispatchToProps),
  withProps(({
    documents, company,
  }) => {
    if (company !== '1') {
      return { documents: R.omit(['CONSTITUTION', "RES"], documents) };
    }
    return { documents: R.omit(["RES"], documents) };

  }),
  lifecycle({
    componentDidMount() {
      const { startSyncProfile } = this.props;
      startSyncProfile();
    },
    componentWillUnmount() {
      const { stopSyncProfile } = this.props;
      stopSyncProfile();
    },
  }),
  withStyles(styles),
  pure,
);

export default enhance(DocumentsForm);
