import { v4 } from 'uuid';

import {
  CREATE_ORDER,
  RESOLVE_ORDER,
  CANCEL_ORDER,
  INIT_CANCEL_ORDER,
  SET_ACTIVE_TAB,
  SELECT_ORDER,
  CLEAR_SELECTED_ORDER,
  ADD_RETRY_CANCEL_ORDER,
  REMOVE_RETRY_CANCEL_ORDER
} from './actionTypes';


const createOrder = ({ id = v4(), ...order }, props) => {
  return {
    type: CREATE_ORDER,
    order: {
      id,
      ...order,
    },
    props:{
      props
    },
  }
};

const resolveOrder = ({ id = v4(), ...order }) => ({
  type: RESOLVE_ORDER,
  order: {
    id,
    ...order,
  },
});

const cancelOrder = order => ({
  type: CANCEL_ORDER,
  order,
});

const initCancelOrder = ({ op, orders }) => ({
  type: INIT_CANCEL_ORDER,
  op,
  orders
});

const setActiveTab = activeTab => ({
  type: SET_ACTIVE_TAB,
  activeTab,
});

const selectOrder = (orderType, order) => ({
  type: SELECT_ORDER,
  orderType,
  order
})

const clearSelectedOrder = () => ({
  type: CLEAR_SELECTED_ORDER
})

const addRetryCancelOrder = orderId => ({
  type: ADD_RETRY_CANCEL_ORDER,
  orderId
})

const removeRetryCancelOrder = orderId => ({
  type: REMOVE_RETRY_CANCEL_ORDER,
  orderId
})

export default {
  createOrder,
  resolveOrder,
  initCancelOrder,
  cancelOrder,
  setActiveTab,
  selectOrder,
  clearSelectedOrder,
  addRetryCancelOrder,
  removeRetryCancelOrder
};
