import { put, select, takeLatest } from 'redux-saga/effects';
import * as R from 'ramda';

import {
  UPDATE_BANK_ACCOUNT,
  FETCH_BANK_ACCOUNT_REQUESTED,
  START_BANK_ACCOUNT_CHANGES_VALIDATION_TOKEN
} from './actionTypes';
import { addValidationTokenDialog, fetchService } from '../../actions';


function* updateBankAccountSaga({ bankAccount }) {
  const bank_addr = R.join('|', R.values(bankAccount));
  const data = JSON.stringify({ bank_addr });

  try {
    // eslint-disable-next-line camelcase
    yield put(fetchService({
      requestType: 'updateBankAccount',
      successAction: START_BANK_ACCOUNT_CHANGES_VALIDATION_TOKEN,
      failAction: 'UPDATE_PROFILE_FAILED',
      responseToUser: {
        onFail: 'dialog',
      },
      paramns: {
        privateService: true,
        form: {
          data,
        },
        options: {
          cmd: 'update_profile',
          method: 'POST',
        },
      },
    }));
  } catch (e) {
    console.error('updateBankAccountSaga', e);
  }
}

function* startBankAccountChangesValidationToken( ) {
  const auth_token = yield select(state => state.credentials.token);
  yield put (addValidationTokenDialog({ validationCmd: 'confirm_update', payload: {authToken: auth_token}}));
}


function* fetchBankAccountSaga(_action) {
  try {
    yield put(fetchService({
      requestType: 'fetchBankAccount',
      paramns: {
        privateService: true,
        form: {
          currency: 'BRL',
        },
        options: {
          cmd: 'deposit_addr',
          method: 'POST',
        },
      },
    }));
  } catch (e) {
    console.error('fetchBankAccountSaga', e);
  }
}

function* watchBankAccount() {
  yield takeLatest(UPDATE_BANK_ACCOUNT, updateBankAccountSaga);
  yield takeLatest(FETCH_BANK_ACCOUNT_REQUESTED, fetchBankAccountSaga);
  yield takeLatest('CONFIRM_UPDATE_PROFILE_SUCCEED', fetchBankAccountSaga);
  yield takeLatest(START_BANK_ACCOUNT_CHANGES_VALIDATION_TOKEN, startBankAccountChangesValidationToken);
}

export default watchBankAccount;
