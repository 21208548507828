const ADD_SNACK = 'ADD_SNACK';
const ADD_RESPONSE_SNACK = 'ADD_RESPONSE_SNACK';
const OPEN_SNACK = 'OPEN_SNACK';
const READ_SNACK = 'READ_SNACK';

export {
  ADD_SNACK,
  OPEN_SNACK,
  READ_SNACK,
  ADD_RESPONSE_SNACK,
};
