import React from 'react';
import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';
import DenseMobileCell from './DenseMobileCell';

const TradeTableHeaderCell = ({ children, noWrap = true, ...rest }) => {
  return (
    <DenseMobileCell
      style={{
        position: 'sticky',
        backgroundColor: '#fff',
        top: 0,
      }}
      {...rest}
    >
      <Hidden mdUp>
        <Typography variant="caption" noWrap={noWrap}>
          {children}
        </Typography>
      </Hidden>
      <Hidden smDown>
        {children}
      </Hidden>
    </DenseMobileCell>
  );
};

TradeTableHeaderCell.propTypes = {
  children: PropTypes.node.isRequired,
};

export default TradeTableHeaderCell;
