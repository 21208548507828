const MgmRewardValues = {
  prize1: 10,
  prize2: 25,
  prize3: 45,
  prize4: 70,
  prize5: 100,
  card: 15,

  get prizes() {
    return [this.prize1, this.prize2, this.prize3, this.prize4, this.prize5];
  },
};

const prizeKeys = ["prize5", "prize4", "prize3", "prize2", "prize1"];

export { MgmRewardValues, prizeKeys };
