import {SvgIcon} from "@material-ui/core";
import React from "react";

export const buttonModels = {
    defaultButton: [{
        icon: (
            <SvgIcon width={18} height={18} viewBox="0 0 38.084 32.272" fill='secondary'>
                <g id="Grupo_741" data-name="Grupo 741" transform="translate(-13.048 -12.002)">
                    <path id="Caminho_26" data-name="Caminho 26"
                          d="M95.689,17.5h.054a2.079,2.079,0,0,1,1.423.591,2.014,2.014,0,0,1,.591,1.4v.054a1.9,1.9,0,0,1-.591,1.4,1.966,1.966,0,0,1-1.423.591h-2.9l1.96,3.329a1.913,1.913,0,0,1,.242.967,1.575,1.575,0,0,1-.107.618v-.027a2.191,2.191,0,0,1-.913,1.208h0a1.95,1.95,0,0,1-.967.3,1.4,1.4,0,0,1-.564-.107h.027a1.924,1.924,0,0,1-1.208-.913h0L83.956,14.059A5.6,5.6,0,0,1,83.07,11a5.1,5.1,0,0,1,.107-1.1v.054a4.581,4.581,0,0,1,1.45-2.631l5.88,10.2h5.182Z"
                          transform="translate(-52.624 12.36)" fill="secondary"/>
                    <path id="Caminho_27" data-name="Caminho 27"
                          d="M81.356,17.559a2.58,2.58,0,0,1-1.262.94h-.027a1.785,1.785,0,0,1-.564.081,1.713,1.713,0,0,1-.967-.3h0a1.881,1.881,0,0,1-.913-1.208v-.027a1.739,1.739,0,0,1-.054-.537,2.331,2.331,0,0,1,.268-1.047v.027l1.477-2.443a3.159,3.159,0,0,1,1.155-.188,3.485,3.485,0,0,1,2.765,1.369h0l-1.853,3.329Z"
                          transform="translate(-61.891 21.694)" fill="secondary"/>
                    <path id="Caminho_28" data-name="Caminho 28"
                          d="M93.478,5.453a1.973,1.973,0,0,1,1.208-.913h.027a1.785,1.785,0,0,1,.564-.081,1.65,1.65,0,0,1,.967.3h0a2.132,2.132,0,0,1,.913,1.208V5.99a1.852,1.852,0,0,1-.242,1.584L88.377,22.368h6.229a2.386,2.386,0,0,1,2.148,1.369v.027a2.55,2.55,0,0,1,.376,1.369,2.322,2.322,0,0,1-.349,1.289V26.4H78.6a1.966,1.966,0,0,1-1.423-.591,1.9,1.9,0,0,1-.591-1.4v-.054a1.9,1.9,0,0,1,.591-1.4,1.966,1.966,0,0,1,1.423-.591h5.155l6.471-11.276-1.96-3.517a1.852,1.852,0,0,1-.242-1.584v.027a2.191,2.191,0,0,1,.913-1.208h.027a1.65,1.65,0,0,1,.967-.3,1.32,1.32,0,0,1,.564.107h0a2,2,0,0,1,1.208.913h0l.886,1.557.886-1.584Z"
                          transform="translate(-63.542 7.542)" fill="secondary"/>
                </g>
            </SvgIcon>
        ),
        text: 'common.appStore',
        color: 'secondary',
        link: 'apple-store'
    }, {
        icon: (
            <SvgIcon viewBox="0 0 33.577 35.996" width={15} height={15} fill='secondary'>
                <path id="Caminho_31" data-name="Caminho 31" d="M26.627,23.463l-3.463-3.41-12.7,12.538,16.163-9.129Zm0-9.263L10.464,5.072l12.7,12.538ZM31.7,20.537a2.219,2.219,0,0,0-.081-3.437l-3.383-1.933-3.786,3.651,3.786,3.651L31.7,20.537ZM7.162,33.316l14.767-14.5L7.162,4.32A2.174,2.174,0,0,0,5.9,6.307V31.276a2.174,2.174,0,0,0,1.262,1.987Z" transform="translate(-5.9 -4.32)" fill="secondary"/>
            </SvgIcon>
        ),
        text: 'common.googlePlay',
        color: 'secondary',
        link: 'google-play'
    }],
    darkButton: [{
        icon: (
            <SvgIcon width={18} height={18} viewBox="0 0 38.084 32.272" fill='primary'>
                <g id="Grupo_741" data-name="Grupo 741" transform="translate(-13.048 -12.002)">
                    <path id="Caminho_26" data-name="Caminho 26"
                          d="M95.689,17.5h.054a2.079,2.079,0,0,1,1.423.591,2.014,2.014,0,0,1,.591,1.4v.054a1.9,1.9,0,0,1-.591,1.4,1.966,1.966,0,0,1-1.423.591h-2.9l1.96,3.329a1.913,1.913,0,0,1,.242.967,1.575,1.575,0,0,1-.107.618v-.027a2.191,2.191,0,0,1-.913,1.208h0a1.95,1.95,0,0,1-.967.3,1.4,1.4,0,0,1-.564-.107h.027a1.924,1.924,0,0,1-1.208-.913h0L83.956,14.059A5.6,5.6,0,0,1,83.07,11a5.1,5.1,0,0,1,.107-1.1v.054a4.581,4.581,0,0,1,1.45-2.631l5.88,10.2h5.182Z"
                          transform="translate(-52.624 12.36)" fill="primary"/>
                    <path id="Caminho_27" data-name="Caminho 27"
                          d="M81.356,17.559a2.58,2.58,0,0,1-1.262.94h-.027a1.785,1.785,0,0,1-.564.081,1.713,1.713,0,0,1-.967-.3h0a1.881,1.881,0,0,1-.913-1.208v-.027a1.739,1.739,0,0,1-.054-.537,2.331,2.331,0,0,1,.268-1.047v.027l1.477-2.443a3.159,3.159,0,0,1,1.155-.188,3.485,3.485,0,0,1,2.765,1.369h0l-1.853,3.329Z"
                          transform="translate(-61.891 21.694)" fill="primary"/>
                    <path id="Caminho_28" data-name="Caminho 28"
                          d="M93.478,5.453a1.973,1.973,0,0,1,1.208-.913h.027a1.785,1.785,0,0,1,.564-.081,1.65,1.65,0,0,1,.967.3h0a2.132,2.132,0,0,1,.913,1.208V5.99a1.852,1.852,0,0,1-.242,1.584L88.377,22.368h6.229a2.386,2.386,0,0,1,2.148,1.369v.027a2.55,2.55,0,0,1,.376,1.369,2.322,2.322,0,0,1-.349,1.289V26.4H78.6a1.966,1.966,0,0,1-1.423-.591,1.9,1.9,0,0,1-.591-1.4v-.054a1.9,1.9,0,0,1,.591-1.4,1.966,1.966,0,0,1,1.423-.591h5.155l6.471-11.276-1.96-3.517a1.852,1.852,0,0,1-.242-1.584v.027a2.191,2.191,0,0,1,.913-1.208h.027a1.65,1.65,0,0,1,.967-.3,1.32,1.32,0,0,1,.564.107h0a2,2,0,0,1,1.208.913h0l.886,1.557.886-1.584Z"
                          transform="translate(-63.542 7.542)" fill="primary"/>
                </g>
            </SvgIcon>
        ),
        text: 'common.appStore',
        color: 'primary',
        link: 'apple-store'
    }, {
        icon: (
            <SvgIcon viewBox="0 0 33.577 35.996" width={15} height={15} fill='primary'>
                <path id="Caminho_31" data-name="Caminho 31" d="M26.627,23.463l-3.463-3.41-12.7,12.538,16.163-9.129Zm0-9.263L10.464,5.072l12.7,12.538ZM31.7,20.537a2.219,2.219,0,0,0-.081-3.437l-3.383-1.933-3.786,3.651,3.786,3.651L31.7,20.537ZM7.162,33.316l14.767-14.5L7.162,4.32A2.174,2.174,0,0,0,5.9,6.307V31.276a2.174,2.174,0,0,0,1.262,1.987Z" transform="translate(-5.9 -4.32)" fill="primary"/>
            </SvgIcon>
        ),
        text: 'common.googlePlay',
        color: 'primary',
        link: 'google-play'
    }],
};