import React from 'react';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextFieldComponent from '../TextFieldComponent';
import NumberFormat from "react-number-format";
import * as R from 'ramda';
import { compose, withState } from 'recompose';

const PostalCodeMaskFormatInput = props => {
  const {
    inputRef, onChange, onBlur, value, ...other
  } = props;

  return (
    <NumberFormat
      {...other}
      value={value}
      ref={inputRef}
      onValueChange={values => {
        onChange(R.isNil(values.value) ? '' : values.value);
      }}
      onBlur={_event => {
        onBlur(value);
      }}
      format='#####-###'
      type='text'
    />
  );
};

PostalCodeMaskFormatInput.defaultProps = {
  value: null,
};

const PostalCodeField = ({
  onSearchPostalCode,
  value,
  loading,
  setLoading,
  onChange,
  ...props
}) => {
  return (
    <TextFieldComponent
      {...props}
      onChange={ async (value)=>{
        onChange(value)
        if(value && value.length === 8){
          setLoading(true);
          await onSearchPostalCode(value)
          setLoading(false);
        }
      }}
      value={value}
      type='text'
      InputProps={{
        inputComponent: PostalCodeMaskFormatInput,
        endAdornment:  (
          <InputAdornment position="end">
            {loading && <IconButton
                disabled={loading}
                aria-label="Toggle password visibility"
              >
                 <CircularProgress size={20}/>
              </IconButton>}
          </InputAdornment>
        ),
      }}
    />
  );
};

const enhance = compose(
  withState('loading', 'setLoading', false)
);


export default enhance(PostalCodeField);
