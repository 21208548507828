import * as R from 'ramda';
import * as actions from './actions';
import config from '../config';

const whitelist = [
  // 'orderBook',
  // 'openOrders',
  // 'orders',
  // 'service',
  // 'transactions',
];

const actionWhitelist = R.groupBy(acc => {
  return R.contains(acc[0], whitelist) ? 'whiteListDoMal' : 'blackListDoMal';
}, R.toPairs(actions));
const { whiteListDoMal, blackListDoMal } = R.map(R.fromPairs, actionWhitelist);

const getActions = R.pipe(
  R.values,
  R.chain(R.values),
);

const actionsWhitelist = [
  '@@redux-form/INITIALIZE',
  ...getActions(whiteListDoMal)];

const actionsBlacklist = [
  'REDUX_STORAGE',
  // '@@redux-form/BLUR',
  // '@@redux-form/FOCUS',
  '@@redux-form/REGISTER_FIELD',
  ...getActions(blackListDoMal)];

const uncollapsedActionsList = [];

const getRegExList = list => R.map(a => new RegExp(a), list)

const loggingWhitelist = getRegExList(actionsWhitelist);
const loggingActionsBlacklist = getRegExList(actionsBlacklist);
const uncollapsedList = getRegExList(uncollapsedActionsList);

const getLogger = () => {
  if (config.DEV) {
    const { createLogger } = require('redux-logger');
    return createLogger({
      // predicate: (getState, action) => {
      //   const testType = R.test(R.__, action.type);
      //   return R.and(
      //     R.any(testType, loggingWhitelist),
      //     R.none(testType, loggingActionsBlacklist));
      // },
      collapsed: (getState, action) => {
        const testType = R.test(R.__, action.type);
        return R.none(testType, uncollapsedList);
      },
    });
  }
  return undefined;
};

const logger = getLogger();
export {
  logger,
  loggingActionsBlacklist,
  actionsWhitelist,
  actionsBlacklist,
  loggingWhitelist,
};
