import React from "react"
import icons from "./components"

const SvgIcon = ({ icon, ...props }) => {
    const Icon = icons[icon]
    return Icon ? (<Icon {...props} />) : (<div></div>)
    }

export default SvgIcon

    