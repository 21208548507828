import React, { useEffect, useRef, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Menu, ButtonBase } from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";
import { setSelectedMarket } from "../../redux/market/actions";
import { compose, withHandlers } from "recompose";
import { translate } from "react-i18next";
import { connect } from "react-redux";
import Typography from "@material-ui/core/Typography";
import CurrencyBalanceDetails from "./CurrencyBalanceDetails";
import PieChart from "./PieChart";
import { isObjectNotEmpty } from "../../utils/objectFunctions";
import { withRouter } from "react-router-dom";
import ActivityIndicator from '../../components/ActivityIndicator';
import {
  getUserCurrencies,
  getCurrenciesBySymbols,
  stableBrzCoins,
} from "../../utils/currencies";
import CurrencySearchInput from "./CurrencySearchInput";

import { handleAppBarMenu } from "../../redux/actions";
import { getCurrencyBySymbol } from "../../models/Currency";

const styles = (theme) => ({
  popoverPaper: {
    display: "flex",
    backgroundColor: "rgba(16, 16, 16, 0.9)",
    borderRadius: 20,
    height: "90%",
    maxHeight: "unset",
    left: `${theme.drawerWidth + 16}px !important`,
    width: `calc(98% - ${theme.drawerWidth}px)`,
    right: 0,
    padding:
      "4.7961630695444% 5.8536585365854% 4.7961630695444% 5.8536585365854%",

    "& > ul": {
      width: "100%",
    },

    [theme.breakpoints.down("sm")]: {
      left: "0 !important",
      padding:"17px 15px",
      margin: "auto",
      width: "100%",
      maxWidth: "calc(100% - 16px)",
    },
  },
  inputSearch: {
    width: "50%",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  buttonMenu: {
    align: "center",
  },
  container: {
    display: "flex",
    padding: "10%",
  },
  groupTitle: {
    color: "white",
    marginBottom: 20,
    marginTop: 20,
    fontWeight: 500,
  },
  groupTitleFlexItem: {
    color: "white",
    marginBottom: 10,
    marginTop: 20,
    fontWeight: 500,
  },
  groupItems: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    gap: "10px",
    flex: 1,
    width: "100%",
  },
  allCoinsContainer: {
    paddingBottom: "30px",
    display: "grid",
    gridTemplateColumns: "50% 50%",
    // flexWrap: "wrap",
    gap: "10px",
    width: "100%",

    [theme.breakpoints.down("md")]: {
      gridTemplateColumns: "100%",
    },
  },

  coinsSelectorContainer: {
    paddingBottom: "30px",
    display: "grid",
    gridTemplateColumns: "25% 25% 25% 25%",
    // flexWrap: "wrap",
    gap: "10px",
    width: "100%",

    [theme.breakpoints.down("md")]: {
      gridTemplateColumns: "33.33% 33.33% 33.33%",
    },
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "50% 50%",
    },
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "100%",
    },
  },
  groupItemsMarket: {
    display: "flex",
    flex: 1,
    gap: "10px",
    flexWrap: "wrap",
    justifyContent: "space-between",
    alignItems: "start",
  },
  flexWrapOrganize: {
    width: "100%",
  },
  pieChartContainer: {
    flex: "1",
    width: "100%",
    margin: "auto 0",
  },
  closeButton: {
    color: "white",
    position: "absolute",
    top: "0%",
    right: "0%",
    cursor: "pointer",
  },
  bityButton: {
    padding: "12px 0",
    borderRadius: "90px",
    border: "1px solid var(--Primary-primaryColor, #F7B733)",
    color: "#F0AE3E",
    fontFamily: "Roboto",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "normal",
  },
  activityIndicatorContainer: {
   margin: "10px auto",
  },
});

const AppDashboard = (props) => {
  const {
    classes,
    market,
    userBalance,
    ticker,
    setSelectedMarket,
    t,
    anchorEl,
    setAnchorEl,
    currencies,
    open,
    markets,
    currencySelectorFilter,
    extraData,
    history,
  } = props;
  const [componentLoading, setComponentLoading] = useState(false);
  const [selectedCoins, setSelectedCoins] = useState([]);
  const [allCriptoCurrencies, setAllCriptoCurrencies] = useState([]);
  const [myCoinsFilter, setMyCoinsFilter] = useState(false);
  const [coinSelectorFilter, setCoinSelectorFilter] = useState(false);
  const [hasStableCoins, setHasStableCoins] = useState(false);
  const [isHighlight, setIsHighlight] = useState(true);
  const [isClosing, setIsClosing] = useState();
  
  const totalCurrenciesLength = useRef(0);

  const userCurrencies = getUserCurrencies(userBalance, currencies, ticker);

  const mainCurrenciesCount = 3;
  const mainCurrencies =
    userCurrencies.length > mainCurrenciesCount
      ? userCurrencies.slice(0, 3)
      : userCurrencies;
  const requireStableCoins = extraData && extraData.hasStableCoin;
  const marketsIsLoaded = isObjectNotEmpty(markets);
  const hasTickersLoaded = isObjectNotEmpty(ticker.BTC);
  const hasFiat = extraData && extraData.hasFiat;

  const searchCoins = (event) => {
    const { value } = event.target;
    const coinsToFilter = myCoinsFilter ? userCurrencies : allCriptoCurrencies;
    const filteredCoins = coinsToFilter.filter(
      (currency) =>
        currency.name.toLowerCase().includes(value.toLowerCase()) ||
        currency.symbol.toLowerCase().includes(value.toLowerCase())
    );
    setSelectedCoins(filteredCoins);
  };

  const fillMarketSymbols = (haveToLoadStableCoins) => {
   const marketSymbols = Object.keys(markets).map(
      (market) => market.split("-")[0]
    );
    if (haveToLoadStableCoins) {
      marketSymbols.push(...stableBrzCoins);
      setHasStableCoins(true);
    }
    if (hasFiat) {
      marketSymbols.push("BRL");
    }
    return marketSymbols;
  }

  useEffect(() => {
    if (open) {
    setIsClosing(false);
    }
  }, [open]);

  useEffect(() => {
    setComponentLoading(true);
    setTimeout(() => setComponentLoading(false), 4000);
  }, [market]);

  useEffect(() => {
    const haveToLoadStableCoins = requireStableCoins && !hasStableCoins;
    
    if (marketsIsLoaded) {
      if (myCoinsFilter) {
        setSelectedCoins(userCurrencies);
      } else {
        let allCriptoCurrenciesList = allCriptoCurrencies;
        
        const totalCurrenciesLengthHasChanged = totalCurrenciesLength.current !== currencies.length;
        if (haveToLoadStableCoins || totalCurrenciesLengthHasChanged) {
          const marketSymbols = fillMarketSymbols(haveToLoadStableCoins);
          allCriptoCurrenciesList = getCurrenciesBySymbols(
            currencies,
            marketSymbols,
          );
          setAllCriptoCurrencies(allCriptoCurrenciesList);
          totalCurrenciesLength.current = currencies.length;
        }
        setSelectedCoins(allCriptoCurrenciesList);
      }
    }
  }, [currencies, markets, myCoinsFilter, requireStableCoins]);

  useEffect(() => {
    const haveToHideStableCoins = hasStableCoins;
    const isToDisableHightlight = extraData && extraData.disableHighlight;
    setIsHighlight(!open && isHighlight ? true : isToDisableHightlight);
    if (isClosing) {
      let allCriptoCurrenciesList = allCriptoCurrencies;
      if (haveToHideStableCoins) {
        const marketSymbols = Object.keys(markets).map(
          (market) => market.split("-")[0]
        );
        allCriptoCurrenciesList = getCurrenciesBySymbols(
          currencies,
          marketSymbols
        );
        setHasStableCoins(false);
        setAllCriptoCurrencies(allCriptoCurrenciesList);
      }
      setAnchorEl(null);
      setTimeout(() => {
        setSelectedCoins(allCriptoCurrenciesList);
      }, 300);
      if (myCoinsFilter || coinSelectorFilter) {
        setTimeout(() => {
          setMyCoinsFilter(false);
          setCoinSelectorFilter(false);
        }, 300);
      }
    }
  }, [isClosing]);

  useEffect(() => {
    if (currencySelectorFilter) {
      setCoinSelectorFilter(true);
    }
  }, [currencySelectorFilter]);

  const quoteCurrency = getCurrencyBySymbol(currencies, "BRL");

  const customActionOnClick = extraData && extraData.customActionOnClick;


  const handleClose = () => {
    setIsClosing(true);
  };

  const handleCurrencyClick = (currencyConfig) => {
    if (anchorEl) {
    const market = `${currencyConfig.symbol}-BRL`
    if (customActionOnClick) {
      customActionOnClick(currencyConfig);
    }
   else if (stableBrzCoins.includes(market.split("-")[0])) {
      const nextLocation = {
        pathname: `/bankTransfers/${extraData && extraData.stableCoinAction}`,
        state: { stableCoinOp: true },
      };
      history.push(nextLocation);
    } else {
      setSelectedMarket({market});
    }
    handleClose();
  }
  };

const handleQuoteCurrencyClick = () => {
  const nextLocation = {
    pathname: `/bankTransfers/deposit`,
    state: { stableCoinOp: false },
  };
  history.push(nextLocation);
  handleClose();
}

  return (
    <div>
      <Menu
        PopoverClasses={{ paper: classes.popoverPaper }}
        id="customized-menu"
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "center" }}
        transformOrigin={{ vertical: "right" }}
        getContentAnchorEl={null}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <div onClick={handleClose} className={classes.closeButton}>
          <CloseIcon />
        </div>
        {!myCoinsFilter && !coinSelectorFilter && (
          <div className={classes.groupItemsMarket}>
            <div className={classes.pieChartContainer}>
              <PieChart
                userBalance={userBalance}
                ticker={ticker}
                currencies={currencies}
              />
            </div>
            <div className={classes.groupItems}>
              <Typography className={classes.groupTitleFlexItem}>
                {t("screens.coinsSelect.myCurrencies")}
              </Typography>
              {marketsIsLoaded && hasTickersLoaded ? (
                <>
                  <CurrencyBalanceDetails
                    isFiat
                    loading={componentLoading}
                    selected={isHighlight ? false : quoteCurrency.symbol === market.split("-")[0]}
                    currencyInfo={quoteCurrency}
                    currencyBalance={userBalance[quoteCurrency.symbol] || 0}
                    currencyLocked={
                      userBalance[`${quoteCurrency.symbol}_locked`] || 0
                    }
                    quoteCurrencyInfo={quoteCurrency}
                    onClick={handleQuoteCurrencyClick}
                  />
                  {mainCurrencies.map((currencyInfo) => {
                    return (
                      <CurrencyBalanceDetails
                        key={`currency-balance-details-${currencyInfo.symbol}`}
                        loading={componentLoading}
                        selected={isHighlight ? false : currencyInfo.symbol === market.split("-")[0]}
                        currencyInfo={currencyInfo}
                        currencyTicker={ticker[currencyInfo.symbol]}
                        currencyBalance={userBalance[currencyInfo.symbol] || 0}
                        currencyLocked={
                          userBalance[`${currencyInfo.symbol}_locked` || 0]
                        }
                        quoteCurrencyInfo={quoteCurrency}
                        onClick={() =>
                          handleCurrencyClick(currencyInfo)
                        }
                      />
                    );
                  })}
                </>
              ) : (
                <div className={classes.activityIndicatorContainer}>
                  <ActivityIndicator color="secondary" size={40} />
                </div>
              )}
              {userCurrencies.length > mainCurrenciesCount && (
                <ButtonBase
                  onClick={() => {
                    setMyCoinsFilter(true);
                  }}
                  className={classes.bityButton}
                >
                  {t("screens.coinsSelect.sellMyAllCoins")}
                </ButtonBase>
              )}
            </div>
          </div>
        )}

        <div className={classes.inputSearch}>
          <Typography className={classes.groupTitle}>
            {t("screens.coinsSelect.searchCoins")}
          </Typography>
          <CurrencySearchInput
            placeholder={t("screens.coinsSelect.coinsSearchInputPlaceholder")}
            onChange={searchCoins}
          />
        </div>

        <Typography className={classes.groupTitle}>
          {t(
            `screens.coinsSelect.${myCoinsFilter ? "myCurrencies" : "allCoins"}`
          )}
        </Typography>
        {marketsIsLoaded ? (
          <div
            className={
              coinSelectorFilter
                ? classes.coinsSelectorContainer
                : classes.allCoinsContainer
            }
          >
            {selectedCoins.map((currencyInfo) => (
              <div className={classes.flexWrapOrganize} key={`selected-coins-${currencyInfo.symbol}`}>
                <CurrencyBalanceDetails
                  hasBalanceInfo={!coinSelectorFilter}
                  loading={componentLoading}
                  selected={isHighlight ? false : currencyInfo.symbol === market.split("-")[0]}
                  currencyInfo={currencyInfo}
                  currencyTicker={ticker[currencyInfo.symbol]}
                  currencyBalance={userBalance[currencyInfo.symbol] || 0}
                  currencyLocked={
                    userBalance[`${currencyInfo.symbol}_locked`] || 0
                  }
                  quoteCurrencyInfo={quoteCurrency}
                  onClick={() => {
                     handleCurrencyClick(currencyInfo);
                  }}
                />
              </div>
            ))}
          </div>
        ) : (
          <>
            <ActivityIndicator color="secondary" size={40} />;
          </>
        )}
      </Menu>
    </div>
  );
};

const mapStateToProps = ({ layout: { appBar }, currencies }) => {
  return {
    anchorEl: appBar.anchorEl,
    open: appBar.open,
    currencySelectorFilter: appBar.currencySelectorFilter,
    currencies: currencies.currencies,
    markets: currencies.markets,
    extraData: appBar.extraData,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setSelectedMarket: (market) => dispatch(setSelectedMarket(market)),
  setAnchorEl: (anchorEl) => dispatch(handleAppBarMenu(anchorEl)),
});

const enhance = compose(
  withStyles(styles),
  translate(),
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  withHandlers({
    handleClick:
      ({ setAnchorEl }) =>
      (event) =>
        setAnchorEl(event.currentTarget),
    handleClose:
      ({ setAnchorEl }) =>
      () =>
        setAnchorEl(null),
  })
);

export default enhance(AppDashboard);
