import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { compose, withHandlers, withState } from 'recompose';
import { translate } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { normalizeCardPIN } from '../../../utils/NumberFormat/normalizers'; 
import { withGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { addDialog, uploadStatusCard } from '../../../redux/actions';
import { reduxForm } from 'redux-form';
import SubmitButton from '../../../components/Forms/SubmitButton';
import TextField from '../../../components/Forms/TextField';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { IconButton, InputAdornment } from '@material-ui/core';

const mapDispatchToProps = dispatch => ({
    setBlockStatus: (status, id, pin) => {dispatch(uploadStatusCard(status, id, pin))},
});

const styles = theme => ({
  link2FA:{
    color: "#ff6900",
    fontSize: "12px"
  },
  form:{
    width: '20%',
    minWidth: '380px',
  },
})

const validatePin = (pin, setIsValidPin) => {
  const onlyNums = pin.replace(/[^\d]/g, '');
  if(onlyNums.length >= 4){
    setIsValidPin(true)
    return;
  }
  setIsValidPin(false);

};


const mapStateToProps = (state) => {
  return {
    form: state.form.PinForm,
  };
};

const submitPin = async (form, dispatch, props) => {
    const {id, blocked, setBlockStatus, handleClose, reset } = props;
    const { pin } = form;
    setBlockStatus(!blocked, id, pin);
    reset();
    handleClose();
};


const PinForm = props => {
  const {
    handleSubmit,
    handleClose,
    openForm,
    classes,
    isValidPin,
    setIsValidPin,
    passwordIsMasked, 
    setPasswordIsMasked,
    t,
    form: {
      pinForm
    },
  } = props;

  const togglePasswordMask = () => {
  setPasswordIsMasked((value) => !value)
  }

  
  return (
    <Dialog
      disableBackdropClick
      open={openForm}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      classes={{paper: classes.form}}

    >
      <form onSubmit={handleSubmit}>
        <DialogTitle id="form-dialog-title">
          {t('forms.otpToken.dialog.title')}
        </DialogTitle>
        <DialogContent>

          <DialogContentText>
            {t('forms.cards.typeYourPin')}
          </DialogContentText>

          <div className={classes.iconVisibility}>
            
            <TextField 
              normalize={normalizeCardPIN} 
              name="pin" 
              autocomplete="new-password"
              i18nScope="forms.cards"  
              id="pin-input"
              type={"text"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                    color='primary'
                    aria-label="Toggle password visibility"
                    onClick={togglePasswordMask}
                  >
                    {!passwordIsMasked ? <Visibility /> : <VisibilityOff />}
                </IconButton>
                  </InputAdornment>
                ),
                inputProps: {
                  inputMode: 'numeric',
                  style: {
                    textSecurity: `${passwordIsMasked ? 'disc' : ''} `,
                    WebkitTextSecurity: `${passwordIsMasked ? 'disc' : ''} `,
                    mozTextSecurity: `${passwordIsMasked ? 'disc' : ''} `,
                  },
                },
              }}
              onChange={(e) => validatePin(e.target.value, setIsValidPin)} 
              />
            </div>

          <DialogContentText >
          <a target="_blank" className={classes.link2FA} href="https://suporte.bity.com.br/pt-BR">{t('forms.cards.forgotPin')}</a>
          </DialogContentText>

        </DialogContent>
        <DialogActions>
          <Button onClick={() => {
            handleClose()
            }}
            color="primary">
            {t('common.cancel')}
          </Button>
          <SubmitButton
            disabled={!isValidPin}
            submitText={t('forms.otpToken.dialog.validate')}
            color="primary"
          />
        </DialogActions>
      </form>
    </Dialog>
  );
};

const enhance = compose(
  withGoogleReCaptcha,
  translate(),
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
  withState('passwordIsMasked', 'setPasswordIsMasked', true),
  withState('isValidPin', 'setIsValidPin', false),
  reduxForm({
    form: 'pinForm',
    onSubmit: submitPin,
  }),
);

export default enhance(PinForm);
