import React, { useEffect } from "react";
import { compose, withProps } from "recompose";
import { translate } from "react-i18next";
import { Route } from 'react-router-dom'

import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import store from '../../redux/store';

import CardData from './components/CardData'

import TitledPage from '../../components/PageLayouts/TitledPage'
import PageTabs from '../../components/PageLayouts/PageTabs'
import { connect } from "react-redux";
import AppBanner from "./components/AppBanner";

const mapStateToProps = state => ({
  cards: state.user.cards,
})

const getFirstCardAvailable = () => {
  const cards = store.getState().user.cards;
  if (cards.virtual) {
    return 'virtual';
  } else {
    return 'physical';
  }
}

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  card: {
    maxWidth: 600,
    margin: "auto",
    marginBottom: 80,
    "@media screen and (max-width: 600px)": {
      width: "95%",
    },
  },
  pageTabs: {
    "@media screen and (max-width: 600px)": {
      margin: "auto",
      width: "95%",
    },
  },
  tabStyle: {
    borderRadius: "5px",
    marginLeft: "6px",
    marginRight: "6px",
    marginBottom: "6px",
    transition:
      "filter 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  },
  tabNotSelected: {
    borderRadius: "5px",
    border: `2px solid ${theme.palette.secondary.main}`,

    "&:hover": {
      backgroundColor: "#e6e6e7",
      boxShadow:
        "0px 1px 5px 0px rgba(0, 0, 0, 0.2),0px 2px 2px 0px rgba(0, 0, 0, 0.14),0px 3px 1px -2px rgba(0, 0, 0, 0.12)",
    },
  },
  tabSelected: {
    backgroundColor: theme.palette.secondary.main,
    borderRadius: "5px",

    "&:hover": {
      filter: "brightness(90%)",
      boxShadow:
        "0px 1px 5px 0px rgba(0, 0, 0, 0.2),0px 2px 2px 0px rgba(0, 0, 0, 0.14),0px 3px 1px -2px rgba(0, 0, 0, 0.12)",
    },
  },
  tabNotSelectedText: {
    color: "#696F74",
    textAlign: "center",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
  },
  colorWhite: {
    color: "#FFFFFF",
    textAlign: "center",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
  },
});

const getTabs = (t, cards) => {
  
  return [
  {
    label: t('screens.cards.virtualCard'),
    value: 'virtual',
    disabled: !(cards && cards.virtual)
  },
  {
    label: t('screens.cards.physicalCard'),
    value: 'physical',
    disabled: !(cards && cards.plastic)
  }
]}

const CardsInfo = props => {
  const {
    handleChange,
    t,
    classes,
    subRoute,
    cards,
    handleRedirect
  } = props

  useEffect(() => {
    if (cards && !cards.hasCard)
    handleRedirect()
  }, [cards])

  return (
    <TitledPage title={t('screens.cards.title')}>
      <Grid container>
        <Grid
          justify='center'
          alignItems='center'
          item
          xs={12}
          md={12}
        >
          <PageTabs
            tabs={getTabs(t, cards)}
            selectedTab={subRoute}
            onChange={handleChange}
            classes={classes}
          />
          <AppBanner/>
          <Card className={classes.card}>
            <Route
              path='/card/virtual'
              render={() => (
                <CardData cardType={"virtual"}/>
              )}
            />
            <Route
              path='/card/physical'
              render={() => (
                <CardData cardType={"plastic"}/>
              )}
            />
          </Card>
        </Grid>
        <Grid item xs={12} md={12}>
        </Grid>
      </Grid>
    </TitledPage>
  );
};

const enhance = compose(
  withStyles(styles),
  translate(),
  connect(mapStateToProps, null),
  withProps(({
    match: { params: { subRoute = getFirstCardAvailable() } },
    history: { replace }
  }) => ({
    subRoute,
    handleChange: (e, value) => {
      replace(`/card/${value}`)
    },
    handleRedirect: () => {
      replace(`/express-trade/buy`)
    }
  }))
);

export default enhance(CardsInfo);
