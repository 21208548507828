import React from 'react'
import { translate } from 'react-i18next'
import { compose } from 'recompose'

import MoneyInput from '../../../components/FormsV2/MoneyInput'
import OrderPriceTypeSwitch from './OrderPriceTypeSwitch'
import { USDT, BRL, SLP, USDC, USDBP, ATLAS, ABFY, ADA, LUNC, GMT, BUSD} from '../../../config';
import { ORDER_TYPES } from './constants';

const precisionBrlCurrencies = [USDT, SLP, USDC, USDBP, ATLAS, ABFY, ADA, GMT, BUSD];
const sixDecimalsCurrencies = [LUNC];

const getFieldName = (currency, orderType) => {
  const showPrecisionName = precisionBrlCurrencies.includes(currency);
  if (orderType === ORDER_TYPES.STOPLOSS){
    return showPrecisionName ? 'price-precision-stoploss' : 'pricestoploss';
  }else{
    return showPrecisionName ? 'price-precision' : 'price';
  }
}

const PriceInput = props => {
  const {
    t,
    isMarketPriceSelected,
    setIsMarketPriceSelected,
    formatter,
    className,
    onChange,
    onBlur,
    value,
    currency,
    disabled,
    orderType
  } = props;

  const showPrecisionInput = precisionBrlCurrencies.includes(currency);
  const showPrecisionInputSixDecimals = sixDecimalsCurrencies.includes(currency);
  return (
    <MoneyInput
      formatter={formatter}
      name={getFieldName(currency, orderType)}
      currency={showPrecisionInput ? 'BRL_PRECISION_PRICE' : showPrecisionInputSixDecimals ? 'BRL_PRECISION_PRICE_SIX' : BRL }
      onChange={onChange}
      onBlur={onBlur}
      value={value}
      className={className}
      InputProps={{
        endAdornment: (
          <OrderPriceTypeSwitch
            {...props}
            t={t}
            isMarketPriceSelected={isMarketPriceSelected}
            setIsMarketPriceSelected={setIsMarketPriceSelected}
          />
        )
      }}
      disabled={disabled}
    />
  )
}

export default compose(
  translate()
)(PriceInput)
