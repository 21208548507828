export const styles = (theme) => ({
  boxContainer: {
    width: "100%",
    backgroundColor: "#F8F8F8",
    borderRadius: "10px",
    display: "flex",
    justifyContent: "space-between",
    padding: "20px 30px",
    alignItems: "center",

    "@media  screen and (max-width: 500px)": {
      padding: "20px",
    },

    "& .label": {
      color: "#101010",
      fontSize: "15px",
    },
    "& .value": {
      color: "#101010",
      fontSize: "19px",
      fontWeight: "700",
    },
    "& .estimated-quote": {
      textAlign: "right",
      display: "flex",
      gap: "15px",
      alignItems: "center",
    },
    "& .coin": {
      display: "flex",
      alignItems: "center",
      gap: "15px",
    },
    "& .quote": {
      color: theme.palette.secondary.main,
      fontSize: "16px",
      fontWeight: "600",
    },
    "& .market": {
      fontWeight: "600",
      "@media  screen and (max-width: 500px)": {
        display: "none",
      },
    },
  },
});
