import * as R from 'ramda'
import {ORDER_MARGIN_TO_APPLY, ORDER_TYPES} from './constants';
import { IS_RFQ_ACTIVE } from '../../../config';
export const getLastPrices = (orders, marketPrice) => {
  return {
    lastBid: getLastPrice(marketPrice, orders.buyOrdersSerialized),
    lastAsk: getLastPrice(marketPrice, orders.sellOrdersSerialized),
  }
}

const getLastPrice = (marketPrice, orders) =>
  R.propOr(marketPrice, 'price', R.head(orders))


export const calculateMarketPrice = (orders, amount, type) => {
  /** When not finding a limit index just return the last
   * if there is no orders just return 0
   *
   * Returns the index when satisfies the passed amount
   */
  const indexLimit = getLimitRowIndex(orders, amount, type);
  if (orders.length > 0) {
    return indexLimit > -1
      ? orders[indexLimit].price
      : orders[orders.length - 1].price
  }
  return 0
}

const getLimitRowIndex = (orders, amount, type) => {
  return orders.findIndex((value, index) => {
    if (getValueAccumulatedByType(orders, index, type) >= amount)
      return true
  })
}
/**
 * Returns te accumulated value, basically use
 * reduce to read the complete array of orders depending of the accumulator operation
 * type can be AMOUNTBTC (accumulate BTC amount) or totalValue(acumulate the Price*amount)
 * indexLimit is used to limitate the used values
 */
const getValueAccumulatedByType = (orders, indexLimit, type) => {
  switch (type) {
    case 'AMOUNTBTC':
      return orders.slice(0, indexLimit + 1).reduce((a, b) => a + b.amount, 0)
    case 'TOTALVALUE':
      return orders.slice(0, indexLimit + 1).reduce((a, b) => a + b.price * b.amount, 0)
    default:
      return 0
  }
}


export const getPrice = (acumulator, valueToAcumulate) => props => {
  const {
    orderType,
    values: {
      price,
      amount,
      totalValue,
      isMarketPriceSelected
    }
  } = props

    if (!isMarketPriceSelected) return price
    const orders = orderType === 'BUY'
      ? props.sellOrdersSerialized
      : props.buyOrdersSerialized
    if (orders.length === 0) return 1
    const valuesToAccumlate = valueToAcumulate || (acumulator === 'AMOUNTBTC' ? amount : totalValue) || 0

    const marketPrice = calculateMarketPrice(orders, valuesToAccumlate, acumulator);
    return marketPrice

}

export const applyTotalValueMargin = (totalValue, orderType) => {
  if (orderType === ORDER_TYPES.SELL)
    return totalValue - (totalValue * ORDER_MARGIN_TO_APPLY);
  return totalValue;
};
