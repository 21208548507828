import * as Yup from 'yup';
import moment from 'moment';
import { validateCPF, validateCNPJ } from '../../../utils/validateTaxId';

const PW_REGEX = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-.;:_=<>~`{}|()[\]\/'"\\]).{8,30}$/;

const RegisterSchema = Yup.object().shape({
  email: Yup.string().email('INVALID_EMAIL_FORMAT')
    .required('REQUIRED'),
  repeatEmail: Yup.string().email('INVALID_EMAIL_FORMAT')
    .required('REQUIRED')
    .test('email-match', 'EMAIL_NOT_EQUAL', function (value) {
      return this.parent.email === value;
    }),
  taxId: Yup.string().required('REQUIRED')
    .test('taxId-valid', 'INVALID_TAXID', (value) => {
      if (value) {
        if (value.length === 11) return validateCPF(value);
        return validateCNPJ(value);
      }
    }),
  companyDirector: Yup.string()
  .when('$isPhysicalPerson', {
    is: false,
    then: Yup.string()
    .required('REQUIRED')
    .test('company_director-valid', 'INVALID_CPF_INDICATOR', (value) => {
      if (value) {
        return validateCPF(value);
      }
    }),
  }),
  birth: Yup.string()
    .when('$isPhysicalPerson', {
      is: true,
      then: Yup.string()
        .required('REQUIRED')
        .min(8, { error: 'MIN_LENGTH_REQUIRED', min: 8 })
        .test('is-valid', 'INVALID_DATE', (value) => {
          const date = moment(value, 'DD-MM-YYYY');
          const today = moment();
          const minDate = moment().subtract(100, 'year');
          return date.isValid() && date < today && date > minDate;
        })
        .test('is-too-yang', 'TOO_YOUNG', (value) => {
          const date = moment(value, 'DD-MM-YYYY');
          const minimumAge = moment()
            .subtract(18, 'year')
            .subtract(1, 'day');
          return date.isValid() && date <= minimumAge;
        }),
    }),
  street: Yup.string()
    .required('REQUIRED'),
  pass: Yup.string()
    .required('REQUIRED')
    .min(8, { error: 'MIN_LENGTH_REQUIRED', min: 8 })
    .max(30, ['MAX_LENGTH_REQUIRED', 30])
    .matches(PW_REGEX, 'INVALID_PASSWORD_FORMAT'),
  repeatPass: Yup.string()
    .required('REQUIRED')
    .test('pw-match', 'PASS_NOT_EQUAL', function (value) {
      return this.parent.pass === value;
    }),
  postalcode: Yup.string()
    .required('REQUIRED')
    .min(8, { error: 'MIN_LENGTH_REQUIRED', min: 8 }),
  city: Yup.string()
    .required('REQUIRED'),
  state: Yup.string()
    .required('REQUIRED'),
  addressNumber: Yup.string()
    .required('REQUIRED'),
  gender: Yup.string()
  .when('$isPhysicalPerson', {
    is: true,
    then: Yup.string()
      .required('REQUIRED')
  }),
  terms: Yup.boolean()
    .oneOf([true], 'REQUIRED'),
  privacy: Yup.boolean()
    .oneOf([true], 'REQUIRED'),
});

export default RegisterSchema;
