import React from 'react';
import { Typography } from '@material-ui/core';
import { translate } from 'react-i18next';
import FieldRow from '../../components/Forms/FieldRow'
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames';

const styles = theme => ({
    hasNoWithdrawalOrDeposit: {
        backgroundColor: 'rgba(0, 0, 0, 0.05)',
        border: '1px solid rgba(0, 0, 0, 0.1)',
        borderRadius: '10px',
        padding: '20px 30px',
        width: '100%',
        marginTop: "20px"
      },
    });

const OperationsDisabledCard = ({ currencyName, t, classes, customClass }) => {

    const cardClass = classNames(classes.hasNoWithdrawalOrDeposit, customClass);


    return (
        <FieldRow className={cardClass} >
            <div>
                <Typography className={classes.informationStyle} variant="body2">
                    Atenção:
                </Typography>
                <Typography className={classes.informationStyle} variant="body2">
                    {`• ${t("info.hasNoWithdrawalOrDeposit", {
                        token: currencyName,
                    })}`}
                </Typography>
            </div>
        </FieldRow>
    );
};

const enhance = compose(withStyles(styles),translate());

export default enhance(OperationsDisabledCard);
