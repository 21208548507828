import React from 'react'

import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import CancelIcon from '@material-ui/icons/Cancel'
import Tooltip from '@material-ui/core/Tooltip'
import { connect } from 'react-redux';
import { compose } from 'recompose';
import InfoTooltip from '../../../../components/InfoTooltip'
import ExchangeIcon from '../../../../components/ExchangeIcon'
import BrasilNumberFormat from '../../../../utils/NumberFormat/BrasilNumberFormat'
import OrderBookOrderDetailsRow from './OrderBookOrderDetailsRow'
import CriptoFormat from '../../../../utils/NumberFormat/CriptoFormat';
import AccessAlarmIcon from '@material-ui/icons/AccessAlarm';
import { getAdvancedTradePriceDecimalPlacesPrecision } from '../../../../utils/getAdvancedTradePriceDecimalPlacesPrecision'
import { MarketInfo } from '../../../../models/MarketInfo';
import { Currency } from '../../../../models/Currency';

const avoidScientificNotation = (value) =>  {
  if (Math.abs(value) < 1.0) {
    var e = parseInt(value.toString().split('e-')[1]);
    if (e) {
        value *= Math.pow(10,e-1);
        value = '0.' + (new Array(e)).join('0') + value.toString().substring(2);
    }
  } else {
    var e = parseInt(value.toString().split('+')[1]);
    if (e > 20) {
        e -= 20;
        value /= Math.pow(10,e);
        value += (new Array(e+1)).join('0');
    }
  }
  return value;
}

const OrderBookOrderTableRow = props => {
  const {
    t,
    classes,
    user,
    order,
    ordersType,
    copyRow,
    confirmCancelOrder,
    isShowAllSelected,
    marketConfig,
    currency
  } = props
  const baseDecimalScale = new Currency(currency)._defaultFormatter().decimalScale ;
  const marketData = new MarketInfo(marketConfig);
  const isMarketPlace = marketData.isMarketplace;
  const quoteCurrencyDecimalScale = marketData.quotePrecision;
  const feeType = user.kyc_level <= 3 ? '0.2%' : 'Zero'
  const details = isMarketPlace ? OrderBookOrderDetailsRow(t, order, ordersType, feeType) : ''
  const isProgrammed = order.programmed && order.programmed === "1";
  let totalValue = order.price * order.amount;
  const minLimit = 1e-6;

  if(totalValue <= minLimit){
    totalValue = avoidScientificNotation(totalValue);
  }
  const columnWidth =  isShowAllSelected
    ? '25%'
    : '21%';
  const marginAmount = '-17%';
  return (
    <TableRow className={classes.tableRow}>
      <TableCell
        style={{ cursor: "pointer" }}
        onClick={() => copyRow(order, ordersType)}
        className={classes.subCell}
        colSpan={3}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <InfoTooltip
            hideIcon
            light
            title={details}
            wrapperProps={{
              style: {
                display: "flex",
                alignItems: "center",
                width: columnWidth,
              },
            }}
          >
            {isMarketPlace && (
              <ExchangeIcon
                programmed={isProgrammed}
                exchangeName={order.exchange && order.exchange.name}
              />
            )}
            <BrasilNumberFormat
              decimalScale={quoteCurrencyDecimalScale}
              value={order.price}
            />
          </InfoTooltip>

          <InfoTooltip
            hideIcon
            light
            title={details}
            wrapperProps={{
              style: {
                display: "block",
                width: columnWidth,
                textAlign: "right",
                alignSelf: "center",
              },
            }}
          >
            <div
              style={{
                display: "block",
                marginRight: marginAmount,
                textAlign: "right",
              }}
            >
              <CriptoFormat
                value={order.amount}
                decimalScale={baseDecimalScale}
              />
            </div>
          </InfoTooltip>
          <InfoTooltip
            hideIcon
            light
            title={details}
            wrapperProps={{
              style: {
                display: "block",
                width: columnWidth,
                textAlign: "right",
                alignSelf: "center",
              },
            }}
          >
            <BrasilNumberFormat
              decimalScale={getAdvancedTradePriceDecimalPlacesPrecision(
                totalValue
              )}
              value={totalValue}
            />
          </InfoTooltip>
          {!isShowAllSelected && (
            <InfoTooltip
              hideIcon
              light
              title={details}
              wrapperProps={{
                style: {
                  display: "block",
                  width: columnWidth,
                  textAlign: isProgrammed ? "center" : "right",
                  alignSelf: "center",
                },
              }}
            >
              {isProgrammed ? (
                <AccessAlarmIcon style={{ color: "#005fac" }} />
              ) : (
                <BrasilNumberFormat
                  value={(order.exec_amount / order.amount) * 100}
                />
              )}
            </InfoTooltip>
          )}
        </div>
      </TableCell>
      <TableCell classes={{ root: classes.actionCell }} padding="none">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {order.isMine || !isShowAllSelected ? (
            <Tooltip
              classes={{ tooltip: classes.tooltip }}
              placement="top"
              title={t("common.cancel")}
            >
              <CancelIcon
                classes={{ root: classes.cancelIcon }}
                color="error"
                onClick={() =>
                  confirmCancelOrder({ op: ordersType, orders: [order] })
                }
              />
            </Tooltip>
          ) : (
            " "
          )}
        </div>
      </TableCell>
    </TableRow>
  );
}

const mapStateToProps = state => ({
  market: state.market.selectedMarket,
  user: state.user.profile
})

const enhance = compose(
  connect(
    mapStateToProps,
  )
);

export default enhance(OrderBookOrderTableRow)
