const sendGAEvent = (field, value) => {
  try {
    if ('ga' in window) {
      const tracker = window.ga.getAll()[0]
      if (tracker) {
        tracker.send('event', field, 'click', value)
      }
    }
  } catch (e) {
    console.error('GA error', e)
  }
}

export default sendGAEvent
