import { put, takeLatest } from 'redux-saga/effects';

import {
  FETCH_FEES_REQUESTED,
} from './actionTypes';
import { fetchService } from '../../actions';

function* fetchFeesSaga(_action) {
  try {
    yield put(fetchService({
      requestType: 'fetchFees',
      paramns: {
        privateService: true,
        options: {
          cmd: 'get_fees',
          method: 'POST',
        },
      },
    }));
  } catch (e) {
    console.error('fetchFees', e);
  }
}


function* watchFees() {
  yield takeLatest(FETCH_FEES_REQUESTED, fetchFeesSaga);
}

export default watchFees;
