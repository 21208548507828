
import React from 'react'
import { compose }  from 'recompose'
import { translate } from 'react-i18next'

import PercentageSwitchButtons from './PercentageSwitchButtons'
import MoneyInput from '../../../components/FormsV2/MoneyInput'

const MoneyWithPercentageButtonsInput = props => {
  const {
    name,
    className,
    shouldDisplayPercentageSwitch,
    selectedPercentage,
    setPercentage,
    i18nContext,
    formatter,
    fullWidth,
    disabled,
    onChange,
    value
  } = props

  return (
    <MoneyInput
      name={name}
      formatter={formatter}
      onChange={onChange}
      value={value}
      className={className}
      i18nContext={i18nContext}
      fullWidth={fullWidth}
      disabled={disabled}
      InputProps={{
        endAdornment: shouldDisplayPercentageSwitch && (
          <PercentageSwitchButtons
            {...props}
            selectedPercentage={selectedPercentage}
            setPercentage={setPercentage}
          />
        )
      }}
      {...props}
    />
  )
}

export default compose(
  translate(),
)(MoneyWithPercentageButtonsInput)
