import {
  all, put, takeLatest, select,
} from 'redux-saga/effects';
import * as R from 'ramda';
import { closeDialog, addDialog } from './actions';
import { MAKE_DIALOG_CHOICE, ADD_RESPONSE_DIALOG } from './actionTypes';

function* closeDialogSaga(action) {
  try {
    const { layout: { dialog: { actionsToTake = {} } } } = yield select(R.identity);
    const { actionToTake } = action;
    const chosenAction = actionsToTake[actionToTake];
    yield put(closeDialog());
    if (chosenAction) {
      const effect = chosenAction();
      const hasManyActions = Array.isArray(effect);
      if (hasManyActions) {
        const effects = R.map(action => put(action), effect);
        yield all(effects);
      } else {
        yield put(effect);
      }
    }
  } catch (error) {
    console.error('closeDialogSaga', error);
    // yield put(layoutActions.addDialog({ message: error.message }));
  }
}

function* addResponseDialog({ response = {} }) {
  const message = R.prop('message_cod', response);
  const isToShowSupportChat = R.prop('is_error_to_show_support_chat', response);
  try {
    yield put(addDialog({
      title: message,
      renderComponent: isToShowSupportChat ? 'supportChat' : null,
      availableChoices: [
        {
          label: 'common.close',
          actionToTake: '',
          color: 'secondary',
          variant: 'raised',
        },
      ],
    }));
  } catch (error) {
    console.error('addResponseDialog', error);
  }
}

function* watchDialog() {
  yield takeLatest(MAKE_DIALOG_CHOICE, closeDialogSaga);
  yield takeLatest(ADD_RESPONSE_DIALOG, addResponseDialog);
}

export default watchDialog;
