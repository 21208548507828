import PropTypes from "prop-types";
import { styles } from "./styles";
import { withStyles } from "@material-ui/core/styles";
import { translate } from "react-i18next";
import { compose } from "recompose";
import CriptoFormat from "../../../../utils/NumberFormat/CriptoFormat";
import { ButtonBase } from "@material-ui/core";

import React from "react";

const AvailableBalanceDisplay = ({
  availableAmount,
  currencyPrefix,
  onMaxButtonClick,
  t,
  classes,
}) => {
  return (
    <div className={classes.rowContainer}>
      <p className={classes.availableBalanceText}>
        {t("balance.available")}{" "}
        <CriptoFormat
          decimalSeparator={"."}
          thousandSeparator={false}
          prefix={`${currencyPrefix} `}
          className={classes.availableBalanceAmount}
          value={availableAmount}
          decimalScale={8}
        />
        <span className={classes.availableBalanceAmount}>{}</span>
      </p>
      <ButtonBase className={classes.maxButton} onClick={onMaxButtonClick}>
        {t("screens.cryptoConverter.max").toUpperCase()}
      </ButtonBase>
    </div>
  );
};

AvailableBalanceDisplay.propTypes = {
  availableAmount: PropTypes.string.isRequired,
  onMaxButtonClick: PropTypes.func.isRequired,
};

const enhance = compose(withStyles(styles), translate());

export default enhance(AvailableBalanceDisplay);
