import * as actions from './actionTypes';

const initialState = true;

const toggleBottomNavigation = (shouldShowBottomNavigation) => {
  return shouldShowBottomNavigation;
};

const layoutReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.HIDE_BOTTOM_NAVIGATION:
      return toggleBottomNavigation(false);
    case actions.SHOW_BOTTOM_NAVIGATION:
      return toggleBottomNavigation(true);

    default:
      return state;
  }
};

export default layoutReducer;
