import React from 'react';
import { compose, pure } from 'recompose';
import { translate } from 'react-i18next';

import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

import ProfileToolbar from '../components/ProfileToolbar';
import ResetPassword from './ResetPassword';
import ApiToken from './ApiToken';
import ExpandingOptions from './ExpandingOptions';
import {isMobile} from  '../../../utils/checkScreenHelpers';

const styles = theme => ({
  root: {
    flex: 1,
    paddingBottom: 100,
  },
  '@media screen and (min-width: 600px)': {
    list: {
        display: 'flex',
        flexDirection: 'column',
        padding: 0,
    },
  },
  list: {
    flexDirection: 'row',
  },
  fieldItem: {
    padding: 0,
    ...theme.typography.body1,
    fontSize: theme.typography.pxToRem(13),
    color: theme.palette.text.secondary,
  },
});

const Security = props => {
  const {
    classes, t, actualTab
  } = props;

  return (
    <div className={classes.root}>
      <ProfileToolbar title={t('security.credentials')} />
      <List dense>

          <List style={{height:'100px'}} className={classes.list}>
            <ResetPassword/>
            <ApiToken />
          </List>
          {isMobile() ? <div style={{marginTop:'20px'}}></div>: <div></div>}
          <ExpandingOptions actualTab={actualTab}/>

          {/*<List style={{height:'100px'}} className={classes.list}>
            <OtpForm/>
            <CredentialsForm  show='false' />
          </List>*/}

        <ListItem> <div style={{height: 500}}></div></ListItem>
      </List>
    </div>
  );
};

const enhance = compose(
  withStyles(styles),
  translate(),
  // connect(mapStateToProps, maspDispatchToProps),

  pure,
);

export default enhance(Security);
