const getTranslatedStatus = t => status => {
  switch (status) {
    case 'ERROR':
      return t('PENDING')
    default:
      return t(status)
  }
}

export default getTranslatedStatus
