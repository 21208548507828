import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { compose, pure } from 'recompose';

import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';

import DrawerList from './DrawerList';
import { getNavItems } from '../appBarMenuItems';
import { connect } from 'react-redux';
import { MarketInfo } from '../../models/MarketInfo';
import { getShowCryptoConverter } from '../../utils/firebaseUtils';
import useShowCryptoConverter from '../../hooks/useShowCryptoConverter';

const styles = theme => ({
  drawerPaper: {
    backgroundColor: theme.drawerBackgroundColor,
    width: theme.drawerWidth,
    [theme.breakpoints.up('md')]: {
      position: 'fixed',
    },
  },
});

const ResponsiveDrawer = props => {
  const {
    classes,
     theme,
     mobileOpen,
     handleDrawerToggle,
     logged,
     scene,
     fullRoute,
     market,
     demoAccount,
     cards,
     markets,
     isBetaTester,
  } = props;

  const isMarketsEmpty = Object.keys(markets).length === 0;
  const marketConfig = !isMarketsEmpty ?  new MarketInfo(markets[market]) : null;
  const allowedToken = marketConfig ? marketConfig.orderbookVisible : true;
  const firebaseShowCryptoConverter = useShowCryptoConverter(demoAccount);
  const showCryptoConverter = getShowCryptoConverter(firebaseShowCryptoConverter, isBetaTester);
  const conditionalData = { isDemoAccount: demoAccount, cards, allowedToken, showCryptoConverter };
  const navItems = getNavItems(conditionalData);
  const drawer = <DrawerList {...{ navItems, handleDrawerToggle, scene, fullRoute }} />;
  
  return (
    <Fragment>
      <Hidden mdUp>
        <Drawer
          variant="temporary"
          anchor={theme.direction === 'rtl' ? 'left' : 'right'}
          open={logged && mobileOpen}
          onClose={() => handleDrawerToggle(!mobileOpen)}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
        {drawer}
        </Drawer>
      </Hidden>
      {logged &&
        <Hidden smDown implementation="css">
          <Drawer
            variant="permanent"
            open
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
      }
    </Fragment>
  );
};


ResponsiveDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  logged: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
  return {
    cards: state.user.cards,
    market: state.market.selectedMarket,
    markets: state.currencies.markets,
    isBetaTester: state.user.profile.beta_tester,
  };
};

const enhance = compose(
  pure,
  withStyles(styles, { withTheme: true }),
  connect(mapStateToProps, null)
);

export default enhance(ResponsiveDrawer);
