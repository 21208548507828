import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types';

const CountdownSeconds = ({ totalSeconds, onFinish }) => {

    const [secondsLeft, setSecondsLeft] = useState(totalSeconds);

    useEffect(()=>{
        const interval = setInterval(() => {
            const updateValue = secondsLeft === 0 ? 0 : secondsLeft - 1;
            setSecondsLeft(updateValue);
            if (secondsLeft === 0) {
                onFinish();
                clearInterval(interval);
            }
        }, 1000)
        return ()=> {
            clearInterval(interval);
        };
    });
    return (
        <div>
            {secondsLeft}
        </div>
    )
}

CountdownSeconds.defaultProps = {
    totalSeconds : 60,
    onFinish: () => console.log('Finish')
};

CountdownSeconds.propTypes = {
    totalSeconds: PropTypes.number,
    onFinish: PropTypes.func
};

export default CountdownSeconds;
