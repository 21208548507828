// Normalizers that format the user input in certain fields

export const normalize2FA = value => {
  if (!value) {
    return value;
  }
  const onlyNums = value.replace(/[^\d]/g, '');
  if (onlyNums.length <= 6) {
    return onlyNums;
  }
};

const regexPIN = /^[0-9a-zA-Z]+$/;

export const normalizePIN = value => {
  if (!value) {
    return value;
  }
  const regex = new RegExp(regexPIN);
  if (regex.test(value) && value.length <= 6) {
    return value.toUpperCase();
  }
};

export const normalizeCardPIN = value => {
  if (!value) {
    return value;
  }
  const onlyNums = value.replace(/[^\d]/g, '');
  if (onlyNums.length <= 4) {
    return onlyNums;
  }
};

const regexSMSToken = /^[0-9]+$/;

export const normalizeSMSToken = value => {
  if (!value) {
    return value;
  }
  const regex = new RegExp(regexSMSToken);
  if (regex.test(value) && value.length <= 6) {
    return value.toUpperCase();
  }
};

// formats the field of the bank agency
export const normalizeBankAG = isBB => val => {
  if (!val) {
    return val;
  }

  let value = String(val).replace(/^0+/, '');

  if (value.length <= 6 && (!value.match(/[^\d|X]/g) || !value.match(/[^\d|-]/g) || !value.match(/[^\d|x]/g))) {
    if (value.length <= 4) {
      value = value.replace(/[^\d]/g, '');
    } else if (value.length === 5) {
      if (value[4] === '-') {
        value = value.replace('-', '');
      }
      const aux = value;
      const initial = value.substring(0, value.length - 1);
      value = value.replace(/^\d{5}/g, `${initial}-${aux[aux.length - 1]}`).replace(/[X]/g, '-X').replace(/[x]/g, '-x');
    }
  }

  const padSize = isBB ? 5 : 4;
  value = value.padStart(padSize, '0');
  return value;
};

// formats the field of the bank account
export const normalizeBankAccount = value => {
  if (!value) {
    return value;
  }

  // checks if the input is lower than 16 character(including '-') and adds the '-' before the last character
  // if the input has more than 5 digit, it also limit the character to [0-9], 'x' and 'X'.
  if (value.length <= 16) {
    if (value.length <= 5) {
      return value.replace(/[^\d]/g, '');
    } if (value.length >= 6) {
      const len = value.length;
      if (value[len - 1] === '-') {
        return value.replace('-', '');
      }
      return value.replace(/[^0-9x]/gi, '')
        .replace(/x(?=.)/gi, '')
        .replace(/^\d{5,14}(?=[0-9x]$)/i, '$&-');
    }
  }
};
