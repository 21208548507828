import React from 'react';
import { compose } from 'recompose';
import { translate } from 'react-i18next';

import MaterialTextField from '@material-ui/core/TextField';

import capitalizeFirstLetter from '../../utils/capitalizeFirstLetter';
import getHelperText from '../../utils/getHelperText';

import InfoTooltip from '../InfoTooltip';

const TextField = (props) => {
  const {
    input,
    label,
    meta: { error, dirty },
    i18nScope,
    t,
    tReady,
    i18n,
    tooltipTitle,
    ...custom
  } = props;

  const shouldDisplayError = Boolean(dirty && error)
  const helperText = shouldDisplayError
    ? getHelperText(t)(error)
    : ' ';

  return (
    <MaterialTextField
      label={( !tooltipTitle ? capitalizeFirstLetter(
        t(i18nScope ? `${i18nScope}.label` : `forms.${input.name}.label`)) :
        (<InfoTooltip title={tooltipTitle}>
          { capitalizeFirstLetter(
              t(i18nScope ? `${i18nScope}.label` : `forms.${input.name}.label`))
          }
          </InfoTooltip>)
      )}
      placeholder={capitalizeFirstLetter(
        t(i18nScope ? `${i18nScope}.placeholder` : `forms.${input.name}.placeholder`),
      )}
      error={shouldDisplayError}
      helperText={helperText}
      autoComplete="off"
      {...input}
      {...custom}
    />
  );
};

const enhance = compose(
  translate(),
);

export default enhance(TextField);
