import * as R from 'ramda';
import * as actions from './actionTypes';

const initialRequestState = {
  status: 'init',
  counter: {
    requested: 0,
    succeed: 0,
    failed: 0,
  },
  previousResponse: null,
  response: null,
};

const initialState = {
  serviceCounter: {
    requested: 0,
    succeed: 0,
    failed: 0,
  },
};


const fetchService = (state, { requestType }) => {
  const { counter: { requested, ...counter }, ...lastRequestState } =
    R.propOr(initialRequestState, requestType, state);
  return R.merge(state, {
    [requestType]: {
      ...lastRequestState,
      status: 'fetching',
      counter: R.merge(counter, {
        requested: requested + 1,
      }),
    },
  });
};

const fetchServiceSucceed = (state, { requestType, response }) => {
  const {
    counter: { succeed, ...counter },
    response: previousResponse, ...lastRequestState
  } = R.propOr(initialRequestState, requestType, state);

  return R.merge(state, {
    [requestType]: {
      ...lastRequestState,
      status: 'succeed',
      response,
      previousResponse,
      counter: R.merge(counter, {
        succeed: succeed + 1,
      }),
    },
  });
};

const fetchServiceFailed = (state, { requestType }) => {
  const {
    counter: {
      failed,
      ...counter
    }, ...lastRequestState
  } = R.propOr(initialRequestState, requestType, state);

  return R.merge(state, {
    [requestType]: {
      ...lastRequestState,
      status: 'failed',
      counter: R.merge(counter, {
        failed: failed + 1,
      }),
    },
  });
};

const appServiceReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.FETCH_SERVICE_REQUESTED:
      return fetchService(state, action);

    case actions.FETCH_SERVICE_SUCCEED:
      return fetchServiceSucceed(state, action);

    case actions.FETCH_SERVICE_FAILED:
      return fetchServiceFailed(state, action);

    default:
      return state;
  }
};

export default appServiceReducer;
