import { connect } from 'react-redux';
import {
  compose, pure, setDisplayName, lifecycle, withProps,
} from 'recompose';
import {
    enableSyncOrders,
    disableSyncOrders,
    fetchLimitsRequested,
    handleTradeHistoryFromSockets,
} from '../../redux/actions';
import transactionsActions from '../../redux/transactions/actions';
import { addDialog } from '../../redux/actions';
import OrderBookPage from './OrderBookPage';

const mapDispatchToProps = dispatch => ({
  startSyncOrders: () => dispatch(enableSyncOrders()),
  stopSyncOrders: () => dispatch(disableSyncOrders()),
  resetTabIndex: () => dispatch(transactionsActions.setActiveTab(null)),
  fetchLimits: () => dispatch(fetchLimitsRequested()),
  warningCripto: (info) => {
    dispatch(
      addDialog({
        title: (info),
        availableChoices: [
          {
            label: 'common.understood',
            actionToTake: '',
            color: 'secondary',
            variant: 'raised',
          },
        ],
      })
    )},
  handleOrderBook: orderBook => dispatch({
    orderBook,
    type: 'HANDLE_ORDERBOOK_FROM_SOCKETS',
  }),
  handleTradeHistory: tradeHistory => dispatch(handleTradeHistoryFromSockets(tradeHistory)),
});

const mapStateToProps = state => ({
  market: state.market.selectedMarket,
  token: state.credentials.token,
});

const enhance = compose(
  setDisplayName('Trade'),
  connect(mapStateToProps, mapDispatchToProps),
  lifecycle({
    componentDidMount() {
      const { resetTabIndex, fetchExecutedOrders, fetchLimits, fetchNews } = this.props;
      window.scrollTo(0, 0);/** Scroll to top */
      fetchLimits();
      resetTabIndex();
    },
    componentWillUnmount() {
      const { resetTabIndex } = this.props;
      resetTabIndex();
    },
  }),
  pure,
);

export default enhance(OrderBookPage)
