import {
  FETCH_EXTRACT,
  FETCH_EXTRACT_SUCCEED,
  FETCH_EXTRACT_FAILED,
  ENABLE_SYNC_EXTRACT,
  DISABLE_SYNC_EXTRACT,
  DOWNLOAD_EXTRACT_HISTORY_CSV,
  DOWNLOAD_EXTRACT_HISTORY_BISCOINT
} from './actionTypes';


const fetchExtract = ( form ) => ({
  type: FETCH_EXTRACT,
  sync: false,
  form
});

const fetchExtractRequested = (form) => ({
  type: 'FETCH_SERVICE_REQUESTED',
  requestType: 'fetchExtract',
  paramns: {
    privateService: true,
    form,
    options: {
      cmd: 'transactions',
      method: 'POST',
    },
  },
});

const enableSyncExtract = (form) => ({
  type: ENABLE_SYNC_EXTRACT,
  sync: true,
  form
});

const disableSyncExtract = () => ({
  type: DISABLE_SYNC_EXTRACT,
  sync: false,
});

const fetchExtractSucceed = response => ({
  type: FETCH_EXTRACT_SUCCEED,
  response,
});

const fetchExtractFailed = () => ({
  type: FETCH_EXTRACT_FAILED,
});

const downloadExtractHistoryCSV = ({ from, to, onlyBalance }) => ({
  type: DOWNLOAD_EXTRACT_HISTORY_CSV,
  from,
  to, 
  onlyBalance
})

const downloadExtractHistoryBiscoint = () => ({
  type: DOWNLOAD_EXTRACT_HISTORY_BISCOINT,
})

const downloadCompleteIR = () => ({
  type: 'DOWNLOAD_COMPLETE_IR',
  onlyBalance: false
})

const downloadSimpleIR = () => ({
  type: 'DOWNLOAD_SIMPLE_IR',
  onlyBalance: true
})

export {
  fetchExtract,
  enableSyncExtract,
  disableSyncExtract,
  fetchExtractRequested,
  fetchExtractSucceed,
  fetchExtractFailed,
  downloadExtractHistoryCSV,
  downloadCompleteIR,
  downloadSimpleIR,
  downloadExtractHistoryBiscoint
};


export default {
  fetchExtract,
  enableSyncExtract,
  disableSyncExtract,
  fetchExtractRequested,
  fetchExtractSucceed,
  fetchExtractFailed,
  downloadExtractHistoryCSV,
  downloadCompleteIR,
  downloadSimpleIR,
  downloadExtractHistoryBiscoint
};
