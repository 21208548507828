import React from "react";
import * as R from "ramda";
import { compose, pure } from "recompose";
import { translate } from "react-i18next";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import ProfileToolbar from "../components/ProfileToolbar";
import MessageCard from "../../../components/Cards/MessageCard";
import classNames from "classnames";

const styles = (theme) => ({
  container: {
    margin: "15px 15px 0 15px",
    display: "flex",
    flexDirection: "column",
    alignItems: "left",
  },
  messageCardContainer: {
    margin: "15px 15px 0",
    display: "flex",
    justifyContent: "center",
    width: "500px",

    [theme.breakpoints.down("sm")]: {
      width: "96%",
      margin: "15px auto 0",
    },
  },
  limitsTableContainer: {
    maxHeight: "60vh",
    marginBottom: "30px",
    overflowY: "scroll",
    width: "520px",

    [theme.breakpoints.down("md")]: {
      maxHeight: "35vh",
    },
    
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      maxHeight: "50vh",
    },
  },
  limitTableHeader: {
    position: "sticky",
    top: "0",
    left: "0",
    backgroundColor: "white",
  },
  table: {
    position: "relative",
    margin: 0,
  },
  root: {
    [theme.breakpoints.down("sm")]: {
      ...theme.typography.body1,
      fontSize: 13,
      color: "#1C1C1C",
      marginRight: "15px",
      width: "96%",
    },
    width: "500px",
    borderCollapse: "collapse",
    ...theme.typography.body1,
    fontSize: 13,
    color: "#1C1C1C",
  },
  dataCol: {
    [theme.breakpoints.down("sm")]: {
      width: "1000px",
    },
    color: "#2E7A6B",
    paddingRight: "10px",
    textAlign: "left",
  },
  headCol: {
    [theme.breakpoints.down("sm")]: {
      width: "10px",
    },
    fontWeight: 570,
    textAlign: "left",
    paddingLeft: "0px",
  },
  fullRow: {
    borderBottom: "1px dotted grey",
  },
  subtitle: {
    [theme.breakpoints.down("sm")]: {
      marginLeft: "15px",
      marginTop: "5px",
    },
    marginLeft: "25px",
    marginTop: "6px",
    ...theme.typography.body1,
    color: "black",
    fontSize: theme.typography.pxToRem(11),
  },
  subtitleBold: {
    fontWeight: 500,
  },
  firstCol: {
    [theme.breakpoints.down("sm")]: {
      width: "1000px",
      paddingRight: "0px",
    },
    paddingRight: "40px",
    width: "130px",
  },
});

const formatLimitNumber = (limit) => {
  let limitFormatted = "";

  if (limit >= 1000) {
    limit = limit.toString().split("").reverse().join("");
    for (var i = limit.length - 1; i >= 0; i--) {
      if (i % 3 === 0 && i > 0) {
        limitFormatted += limit[i] + ".";
      } else {
        limitFormatted += limit[i];
      }
    }
    return limitFormatted;
  } else {
    return limit;
  }
};

const OpLimitsList = (props) => {
  const {
    user: { kyc_level },
    t,
    limits,
    classes,
    currencies,
  } = props;
  let showLimit = false;

  if (kyc_level >= 1 && kyc_level < 4) {
    showLimit = true;
  }

  const label = t("operationalLimits.titleBar");
  const subtitleMonthly = t("operationalLimits.monthly");
  const subtitleAnnual = t("operationalLimits.annual");
  const filteredCurrencies = currencies.filter((token_info) => {
    return (
      limits[token_info.symbol] !== undefined && token_info.symbol !== "BRL"
    );
  });

  return (
    <div>
      <ProfileToolbar title={label} />
      <div className={classes.messageCardContainer}>
        <MessageCard
          type="info"
          content={t("operationalLimits.thirtyDaysLimitRenewWarning")}
        />
      </div>
      <div className={classes.container}>
        <Typography variant="body2" style={{ alignContent: "center" }}>
          {subtitleMonthly}
        </Typography>
        <div className={classes.limitsTableContainer}>
          <table className={classNames(classes.root, classes.table)}>
            <tr
              className={classNames(classes.fullRow, classes.limitTableHeader)}
            >
              <th className={classes.headCol}>Moeda</th>
              <th className={classes.headCol}>Depósitos</th>
              <th className={classes.headCol}>Saques</th>
            </tr>
            <tr className={classes.fullRow}>
              <td className={classes.firstCol}>Reais (BRL)</td>
              <td className={classes.dataCol}>
                R$ {formatLimitNumber(limits.BRL.total_deposit)},00
              </td>
              <td className={classes.dataCol}>
                R$ {formatLimitNumber(limits.BRL.total_withdrawal)},00
              </td>
            </tr>
            {filteredCurrencies.map((token_info) => {
              return (
                <tr className={classes.fullRow}>
                  <td className={classes.firstCol}>{token_info.name}</td>
                  <td className={classes.dataCol}>
                    {limits[token_info.symbol].total_deposit === -1
                      ? t("transactions.noLimit")
                      : limits[token_info.symbol].total_deposit}{" "}
                    {token_info.symbol}
                  </td>
                  <td className={classes.dataCol}>
                    {limits[token_info.symbol].total_withdrawal}{" "}
                    {token_info.symbol}
                  </td>
                </tr>
              );
            })}
          </table>
        </div>
      </div>
      {showLimit && (
        <div className={classes.container}>
          <Typography variant="body2" style={{ alignContent: "center" }}>
            {subtitleAnnual}
          </Typography>
          <table className={classes.root} style={{ marginBottom: "15px" }}>
            <tr className={classes.fullRow}>
              <th className={classes.headCol}>Moeda</th>
              <th className={classes.headCol}>Depósitos</th>
              <th className={classes.headCol}>Saques</th>
            </tr>
            <tr className={classes.fullRow}>
              <td className={classes.firstCol}>Reais (BRL)</td>
              <td className={classes.dataCol}>
                R$ {formatLimitNumber(limits.BRL.annual_total_deposit)},00
              </td>
              <td className={classes.dataCol}>
                R$ {formatLimitNumber(limits.BRL.annual_total_withdrawal)},00
              </td>
            </tr>
          </table>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    limits: R.omit(["success"], state.user.limits),
    user: state.user.profile,
    currencies: state.currencies.currencies,
  };
};

const enhance = compose(
  translate(),
  withStyles(styles),
  connect(mapStateToProps, null),
  pure
);

export default enhance(OpLimitsList);
