import * as R from 'ramda'

const getMaxValue = (digits, multiplier = 2, totalValue = 0) => {
  if (R.isEmpty(digits)) return totalValue
  return getMaxValue(
    R.dropLast(1, digits),
    multiplier + 1,
    totalValue + R.last(digits) * multiplier
  )
}

const getValidDigitFromAccount = account => {
  const digits = R.dropLast(1, account.replace('-', '').split(''))
  const totalValueSum = getMaxValue(digits)
  const digit = 11 - (totalValueSum % 11)
  return digit
}

const getDigitFromInputtedAccount = account => {
  const containsSeparator = account.split('').indexOf('-') !== -1
  const getDigit = R.pipe(
    R.split('-'),
    R.last
  )

  return containsSeparator
    ? getDigit(account)
    : R.last(R.split('', account))
}

const getValidDigitEdgeCases = validDigit => {
  switch (Number(validDigit)) {
    case 10: return 'X'
    case 11: return '0'
    default: return validDigit
  }
}

export const isBBAccountDigitValid = account => {
  if (R.isEmpty(account) || R.isNil(account)) return false
  const digitFromInputtedAccount = getDigitFromInputtedAccount(account)
  const validDigit = getValidDigitFromAccount(account)
  const actualValidDigit = getValidDigitEdgeCases(validDigit)

  return R.toUpper(String(digitFromInputtedAccount)) === R.toUpper(String(actualValidDigit))
}
