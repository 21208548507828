import { Grid, Typography, ButtonBase } from "@material-ui/core";
import React from "react";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { translate } from "react-i18next";
import PropTypes from "prop-types";
import CashbackIndicator from "./CashbackIndicator";
import ArrowForwardIos from "@material-ui/icons/ArrowForwardIos";
import store from "../../../../redux/store";
import { addCardDialog } from "../../../../redux/actions";
import { formatPercentage } from "../../../../utils/NumberFormat/percentage";
import {
  CheckCircleOutline,
} from "@material-ui/icons";
import DangerouslySetInnerHTML from "../../../../components/DangerouslySetInnerHTML";
import { withRouter } from "react-router-dom";

const styles = (theme) => ({
  titleStyle: {
    color: "#249B7F",
    fontSize: "11px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "normal",
    letterSpacing: "-0.22px",
  },
  subtitleStyle: {
    color: "#101010",
    textAlign: "left",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
  },
  card: {
    marginTop: "10px",
    position: "relative",
    display: "flex",
    width: "100%",
    flex: 1,
    boxShadow: "none",
    borderRadius: "8px",
    border: "1px solid #BEE7DD",
    background: "#E6FDF7",
    padding: "15px 15px 15px 20px",
    [theme.breakpoints.down("sm")]: {
      padding: "10px",
    },
  },
  textContentColumn: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    [theme.breakpoints.down("xs")]: {
      maxWidth: "130px",
    },
  },
  contentRow: {
    display: "flex",
    flexDirection: "row",
    gap: "15px",
    alignContent: "center",
  },
  arrowForwardIcon: {
    color: "#60CEB4",
    fontSize: "16px",
  },
  checkIcon: {
    position: "absolute",
    color: "#101010",
    left: "23.214285714286%",
    top: "22.321428571429%",
    fontSize: "30px",
  },
});

const showMissionModal = (userData, redirectToMgm, neverPurchaseInCard) =>
  store.dispatch(
    addCardDialog({
      title: neverPurchaseInCard
        ? "screens.mgm.cashback.dailyCashback.noCardMissionModalTitle"
        : "",
      renderComponent: neverPurchaseInCard ? "cardSell" : "cashbackMissions",
      renderComponentProps: {
        userData,
        redirectToMgm,
      },
    })
  );

const MissionCard = (props) => {
  const { classes, t, userData, history } = props;
  const neverPurchaseInCard = !userData.hasCard;
  const value = userData.missionsData.nextLevelSum;
  const formatedValue =
    value === "" ? (
      ""
    ) : neverPurchaseInCard ? (
      "1%"
    ) : value ? (
      formatPercentage(value)
    ) : (
      <CheckCircleOutline classes={{ root: classes.checkIcon }} />
    );
  const ctaMessage = value === "" ? "" : neverPurchaseInCard
    ? "screens.mgm.cashback.dailyCashback.missionFirstCardPurchaseCtaPhrase"
    : value
      ? "screens.mgm.cashback.dailyCashback.missionsCtaPhrase"
      : "screens.mgm.cashback.dailyCashback.missionsCompletedCtaPhrase";

  const redirectToMgm = () => {
    history.push("/mgm/indications");
  };

  return (
    <ButtonBase
      className={classes.card}
      onClick={() => {
        showMissionModal(userData, redirectToMgm, neverPurchaseInCard);
      }}
    >
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
      >
        <Grid className={classes.contentRow}>
          <CashbackIndicator
            content={formatedValue}
            isContentText={value ? true : false}
            bgColor="#60CEB4"
            isMissionIndicator
          />
          <Grid className={classes.textContentColumn}>
            <Typography className={classes.titleStyle}>
              {t("screens.mgm.cashback.dailyCashback.missions")}
            </Typography>
            <DangerouslySetInnerHTML className={classes.subtitleStyle} title={t(ctaMessage)} />
          </Grid>
        </Grid>
        <ArrowForwardIos classes={{ root: classes.arrowForwardIcon }} />
      </Grid>
    </ButtonBase>
  );
};

const enhance = compose(withStyles(styles), translate(), withRouter);

MissionCard.propTypes = {
  userData: PropTypes.object,
};

MissionCard.defaultProps = {
  userData: {},
};

export default enhance(MissionCard);
