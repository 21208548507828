import { compose, withHandlers, withState } from 'recompose'
import { withFormik, yupToFormErrors } from 'formik'
import { connect } from 'react-redux'
import * as Yup from 'yup'
import {
  ORDER_TYPES,
} from '../../../Trade/TradeOrderForm/constants';
import withTradePercentageInputHandlers from '../../../Trade/TradeOrderForm/withTradePercentageInputHandlers'
import transactionsActions from '../../../../redux/transactions/actions'
import {
  setSelectedMarket,
  addDialog,
  getQuote,
  executeQuote,
  handleAppBarMenu,
} from '../../../../redux/actions'
import { toFixedNoRound } from '../../../../utils/toFixedNoRound';
import { updateFormData } from '../../../../redux/layout/transactionsForm/actions';
import { getCurrencyBySymbol } from '../../../../models/Currency';
import { extractMinAmount } from '../../../../utils/modelUtils';

const getExpressTradeFormInputHandler = inputName =>
  ({ setFieldValue, ...props }) => inputValue => {
    const { 
      orderType, 
      market,
      getQuote,
      setLastInput,
      timer, 
      setTimer,
      setLoader,
      setKeyCounter,
      setPlayCounter
    } = props;
    setLastInput(inputName)
    setFieldValue(inputName, inputValue);
    setFieldValue('amountPercentage', undefined);
    setFieldValue('totalValuePercentage', undefined);
    switch (inputName) {
      case 'totalValue': {
        if(inputValue > 0){
          setLoader(true);
          setTimer(clearTimeout(timer));
          setTimer(setTimeout(() => { getQuote("quote_amount", inputValue, orderType, market) }, 2000));
          setKeyCounter(prevKey => prevKey + 1);
          setPlayCounter(false);
          setFieldValue('totalValue', inputValue);
        }else{
          setFieldValue('amount', inputValue);
        }
        break
      }
      case 'amount': {
        if(inputValue > 0){
          setLoader(true);
          setTimer(clearTimeout(timer));
          setTimer(setTimeout(() => { getQuote("base_amount", inputValue, orderType, market) }, 2000));
          setKeyCounter(prevKey => prevKey + 1);
          setPlayCounter(false);
          setFieldValue('amount', inputValue);
        }else{
          setFieldValue('totalValue', inputValue);
        }
        break
      }
    }
  }

const mapStateToProps = ({ market, user: { balance, limits, profile }, orders, credentials, layout, currencies }) => ({
  market: market.selectedMarket,
  userEmail: profile.email,
  kycLevel: profile.kyc_level,
  taxID: profile.tax_id,
  userId: profile.id,
  balance,
  limits,
  buyOrdersSerialized: orders.buyOrdersSerialized,
  sellOrdersSerialized: orders.sellOrdersSerialized,
  quoteValues: orders.RFQ,
  token: credentials.token,
  data: layout.transactionsForm.formData,
  currencies: currencies.currencies,
  markets: currencies.markets,
  anchorEl: layout.appBar.anchorEl,

})
const mapDispatchToProps = dispatch => ({
  hasNoWithdrawalOrDepositWarning: (currencyName) => dispatch(addDialog({
    title: 'common.attention',
    renderComponent: 'hasNoWithdrawalOrDepositWarning',
    componentProps: {
      currencyName,
    },
    availableChoices: [
      {
        label: 'common.understood',
        color: 'secondary',
        variant: 'raised',
        
      }
    ]
  })),
  executeQuote: (quoteId) => dispatch(executeQuote(quoteId)),
  updateForm: (values) => dispatch(updateFormData(values)),
  getQuote: (param, inputValue, orderType, market) => dispatch(getQuote(param, inputValue, orderType, market)),
  createOrder: (order, props) => dispatch(transactionsActions.createOrder(order, props)),
  setSelectedMarket: market => dispatch(setSelectedMarket(market)),
  setAnchorEl: (anchorEl) => dispatch(handleAppBarMenu(anchorEl, true)),
  warningCripto: (info) => {
    dispatch(
      addDialog({
        title: (info),
        availableChoices: [
          {
            label: 'common.understood',
            actionToTake: '',
            color: 'secondary',
            variant: 'raised',
          },
        ],
        actionsToTake: {
          relocationPage: () => window.location.href = '/login'
        }
      })
    )
  },
})

const TradeExpressSchema = Yup.object().shape({
  amount: Yup.number()
    .required('REQUIRED')
    .when(['$minAmountLimit', '$marketSymbol'], (minAmountLimit, marketSymbol, schema) => {
      return schema.min(minAmountLimit,
        { error: 'BELOW_MINIMUM_AMOUNT_FIELD',
          min: minAmountLimit, symbol: marketSymbol })
    })
    // When orderType is SELL, balance must be BTC
    .when('$orderType', {
      is: ORDER_TYPES.SELL,
      then: Yup.number()
        .when('$balance', (balance, schema) =>
          schema.max(balance, 'NOT_ENOUGH_BALANCE')
        )
    }),

  totalValue: Yup.number()
    .required('REQUIRED')
    .positive('BELOW_MINIMUM_AMOUNT')
    .when('$orderType', {
      // When orderType is BUY, balance must be BRL (or other user currecy)
      is: ORDER_TYPES.BUY,
      then: Yup.number()
        .when('$balance', (balance, schema) =>
          schema.max(balance, 'TO_RESTOCK_BALANCE')
        )
    })
})

const withExpressTradeForm = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFormik({
    enableReinitialize: false,
    mapPropsToValues: ({ market }) => ({
      paymentMethod: 'BALANCE',
      currency: market.split('-')[0],
      market: market,
      totalValue: undefined,
      amount: undefined,
      price: undefined,
      amountPercentage: undefined,
      totalValuePercentage: undefined,
      isMarketPriceSelected: true, // constant,
      currencyConfig: undefined
    }),
    validate: (values, props) => {
      const { market, orderType, markets} = props;
      const marketConfig = markets[market]
      const marketSymbol = values.currency;
      const minAmountLimit = extractMinAmount(
        marketSymbol,
        marketConfig
      );
  
      const balance = props.orderType.toUpperCase() === 'BUY'
        ? props.balance['BRL']
        : props.balance[values.currency]

      return TradeExpressSchema
        .validate(values, {
          abortEarly: false,
          context: {
            balance,
            orderType,
            marketSymbol,
            minAmountLimit
          }
        })
        .catch(err => {
          throw yupToFormErrors(err)
        })
    },
    clearForm:(formikBag) => {
      const {resetForm} = formikBag
      resetForm();
    }
  }),
  withTradePercentageInputHandlers,
  withState('lastInput', 'setLastInput', 'totalValue'),
  withState('timer', 'setTimer', undefined),
  withState('loader', 'setLoader', false),
  withState('playCounter', 'setPlayCounter', false),
  withState('keyCounter', 'setKeyCounter', 0),
  withState('duration', 'setDuration', 0),
  withHandlers({
    handleExpressTotalValuePercentChange: props => percent => {
      const {
        setFieldValue,
        balance,
        orderType,
        market,
        setKeyCounter,
        setLoader,
        setTimer,
        timer,
        setPlayCounter,
        getQuote,
        setLastInput
      } = props;
      const totalValue = balance.BRL * (percent / 100);
      if(totalValue > 0){
        setLastInput('totalValue');
        setLoader(true);
        setTimer(clearTimeout(timer));
        setTimer(setTimeout(() => { getQuote("quote_amount", totalValue, orderType, market) }, 2000));
        setKeyCounter(prevKey => prevKey+1);
        setPlayCounter(false);
        setFieldValue('totalValue', toFixedNoRound(totalValue, 2));
      }
      setFieldValue('totalValuePercentage', percent);
      setFieldValue('totalValue', toFixedNoRound(totalValue, 2));
    },
    handleExpressAmountPercentChange: props => percent => {
      const {
        setFieldValue,
        balance,
        orderType,
        market,
        setKeyCounter,
        setLoader,
        setTimer,
        timer,
        setPlayCounter,
        getQuote,
        setLastInput,
        currencies
      } = props;
      const currencyConfig = getCurrencyBySymbol(currencies, market.split("-")[0]);

      if (currencyConfig) {
        const amount = (balance[currencyConfig.symbol] || 0) * (percent / 100);
        if (amount > 0) {
          setLastInput("amount");
          setLoader(true);
          setTimer(clearTimeout(timer));
          setTimer(
            setTimeout(() => {
              getQuote("base_amount", amount, orderType, market);
            }, 2000)
          );
          setKeyCounter((prevKey) => prevKey + 1);
          setPlayCounter(false);
        }
        setFieldValue("amountPercentage", percent);
        setFieldValue("amount", amount);
      }
    },
    handleValueChange: getExpressTradeFormInputHandler('totalValue'),
    handleAmountChange: getExpressTradeFormInputHandler('amount'),
  })
)

export default withExpressTradeForm
