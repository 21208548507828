import React from "react";
import { connect } from "react-redux";
import { FIREBASE_LINKS } from "../../../config";
import {
  DialogTitle,
  DialogContent,
  Typography,
  Grid,
  FormControlLabel,
} from "@material-ui/core";
import { compose } from "recompose";
import { translate } from "react-i18next";
import { reduxForm, formValueSelector, isSubmitting } from "redux-form";
import SubmitButton from "../../../components/Forms/SubmitButton";
import { changeUserDeclarationDialog, addSnack } from "../../../redux/actions";
import {
  declarationTypes,
  pendingDocTypes,
  getDeclarationTypeByDocPending,
} from "./_constants";
import requestUserDeclaration from "../../../services/cbtcService/userDeclaration";
import CheckBoxField from "../../../components/Fields/CheckBoxField";

const userAgreementSubmit = async (form, dispatch, props) => {
  const { handleClose, pendingSends, openAnotherDeclarationDialog } = props;
  const hasUserAgreedTerms = form.hasUserAgreedTerm;
  if (!hasUserAgreedTerms) return;
  try {
    const newForm = {
      data: JSON.stringify({ user_agreement: hasUserAgreedTerms }),
      type: declarationTypes.AGREEMENT,
    };
    await requestUserDeclaration(newForm);
    const newPendingSends = pendingSends.filter(
      (item) => item !== pendingDocTypes.USER_AGREEMENT
    );
    if (newPendingSends.length > 0) {
      openAnotherDeclarationDialog({
        declarationType: getDeclarationTypeByDocPending(newPendingSends[0]),
        pendingSends: newPendingSends,
      });
    } else {
      handleClose();
    }
  } catch (error) {
    dispatch(addSnack({ message: "GENERIC_ERROR" }));
    return;
  }
};

const UserAgreementBody = (props) => {
  const { t, classes, hasUserAgreedTerm, handleSubmit, submitting } = props;
  return (
    <>
      <DialogTitle className={classes.dialogTitle}>
        {t("forms.userAgreement.dialog.title")}
      </DialogTitle>
      <DialogContent>
        <Typography className={classes.descriptionLabel}>
          {t("forms.userAgreement.dialog.toKeepUsingOurPlatform")}
          <br />
          {t("forms.userAgreement.dialog.weNeedThatYouAccept")}
          <a
            className={classes.linkStyle}
            href={FIREBASE_LINKS.urlBitybankUseTerms}
            target="_blank"
            rel="noopener noreferrer"
          >
            {t("forms.userAgreement.dialog.useTerms")}.
          </a>
        </Typography>
        <Grid container>
          <Grid direction="row">
            <form onSubmit={handleSubmit}>
              <FormControlLabel
                className={classes.resetMargin}
                control={
                  <CheckBoxField
                    name="hasUserAgreedTerm"
                    checked={hasUserAgreedTerm}
                    label={
                      <Typography component="span">
                        {t("forms.userAgreement.dialog.acceptPrefix")}{" "}
                        <a
                          className={classes.linkStyle}
                          href={FIREBASE_LINKS.urlBitybankUseTerms}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {t("forms.userAgreement.dialog.useTerms")}
                        </a>
                        {t("forms.userAgreement.dialog.acceptSuffix")}
                      </Typography>
                    }
                  />
                }
              />

              <SubmitButton
                disabled={!hasUserAgreedTerm || submitting}
                className={classes.submitButton}
                isSubmitting={submitting}
                color="primary"
                variant="raised"
                submitText={t("common.toConfirm")}
              />
            </form>
          </Grid>
        </Grid>
      </DialogContent>
    </>
  );
};

const selector = formValueSelector("userDeclarationTermsAgreement");

const mapDispatchToProps = (dispatch) => ({
  handleClose: () =>
    dispatch(
      changeUserDeclarationDialog({
        open: false,
        declarationType: null,
        pendingSends: [],
      })
    ),
  openAnotherDeclarationDialog: (data) =>
    dispatch(
      changeUserDeclarationDialog({
        open: true,
        declarationType: data.declarationType,
        pendingSends: data.pendingSends,
      })
    ),
});

const mapStateToProps = (state) => {
  return {
    hasUserAgreedTerm: selector(state, "hasUserAgreedTerm"),
    submitting: isSubmitting("userDeclarationTermsAgreement")(state),
    pendingSends: state.credentials.userDeclarationDialog.pendingSends,
  };
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  translate(),
  reduxForm({
    form: "userDeclarationTermsAgreement",
    onSubmit: userAgreementSubmit,
  })
);

export default enhance(UserAgreementBody);
