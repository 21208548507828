import { combineReducers } from 'redux';

import data from './data/reducer';
import service from './service/reducer';

const initialState = {
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    // case 'REMOVE_CREDENTIALS':
    //   return initialState;

    default:
      return combineReducers({
        data,
        service,
      })(state, action);
  }
};

export default reducer;
