import React from 'react'
import PropTypes from 'prop-types'

import TextFieldComponent from '../TextFieldComponent/index'
import MoneyInputFormat from './MoneyInputFormat'


const MoneyInput = props => {
  const {
    className,
    onChange,
    value,
    name,
    formatter,
    InputProps,
    disabled,
    i18nScope,
    i18nContext,
    fullWidth
  } = props
  
  return (
    <TextFieldComponent
      value={value}
      name={name}
      className={className}
      disabled={disabled}
      i18nScope={i18nScope}
      i18nContext={i18nContext}
      fullWidth={fullWidth}
      InputProps={{
				inputComponent: MoneyInputFormat,
        inputProps: {
          formatter,
          onChange,
          value
        },
        ...InputProps
      }}
    />
  )
}

MoneyInput.propTypes = {
  currency: PropTypes.string.isRequired,
  field: PropTypes.shape({
    value: PropTypes.number, // can be undefined
    name: PropTypes.string.isRequired
  }).isRequired,
  form: PropTypes.shape({
    setFieldValue: PropTypes.func.isRequired
  }).isRequired,
}

export default MoneyInput
