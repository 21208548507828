import * as R from 'ramda';
import * as actions from './actionTypes';

const initialRequestState = {
  status: 'init',
  counter: {
    requested: 0,
    succeed: 0,
    failed: 0,
  },
  previousResponse: null,
  response: null,
};

const initialState = {
  counter: {
    requested: 0,
    succeed: 0,
    failed: 0,
  },
  shortPublicSync: { ...initialRequestState },
  longPublicSync: { ...initialRequestState },
  privateSync: { ...initialRequestState },
  privateFetch: { ...initialRequestState },
};


const dataRequested = (state, { requestType }) => {
  const { counter: { requested, ...counter }, ...lastRequestState } = R.propOr(initialRequestState, requestType, state);
  return R.merge(state, {
    [requestType]: {
      status: 'fetching',
      ...lastRequestState,
      counter: R.merge(counter, {
        requested: requested + 1,
      }),
    },
  });
};

const fetchDataSucceed = (state, { requestType, response }) => {
  const {
    counter: { succeed, ...counter },
    response: previousResponse, ...lastRequestState
  } = R.propOr(initialRequestState, requestType, state);

  return R.merge(state, {
    [requestType]: {
      ...lastRequestState,
      status: 'succeed',
      response,
      previousResponse,
      counter: R.merge(counter, {
        succeed: succeed + 1,
      }),
    },
  });
};

const fetchDataFailed = (state, { requestType }) => {
  const {
    counter: {
      failed,
      ...counter
    }, ...lastRequestState
  } = R.propOr(initialRequestState, requestType, state);

  return R.merge(state, {
    [requestType]: {
      ...lastRequestState,
      status: 'failed',
      counter: R.merge(counter, {
        failed: failed + 1,
      }),
    },
  });
};

const appDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.FETCH_DATA_REQUESTED:
      return dataRequested(state, action);

    case actions.FETCH_DATA_SUCCEED:
      return fetchDataSucceed(state, action);

    case actions.FETCH_DATA_FAILED:
      return fetchDataFailed(state, action);

    default:
      return state;
  }
};

export default appDataReducer;
