import React from 'react';
import {
  compose,
  withProps,
} from 'recompose';

import { connect } from 'react-redux';
import { translate } from 'react-i18next';

import { reduxForm } from 'redux-form';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import ListItem from '@material-ui/core/ListItem';

import resetPasswordService from '../../../services/cbtcService/resetPassword';
import { addDialog } from '../../../redux/actions';
import SubmitButton from '../../../components/Forms/SubmitButton';
import validate from '../../../utils/validate';
import renderBranch from '../../../components/renderBranch';
import { valueToMd5 } from '../../../utils/valueToMd5';
import { withRouter } from 'react-router-dom';
import {withGoogleReCaptcha} from 'react-google-recaptcha-v3';


const resetPass = async (values, dispatch, props) =>{
  const {email, tax_id} = values;
  const { executeRecaptcha } = props.googleReCaptchaProps;
  try {
    const taxid_md5 = valueToMd5(tax_id);
    const captcha = await executeRecaptcha("resetPassword");
    const formValues =  {email, taxid_md5, captcha};
    return await resetPasswordService(formValues);
  }catch (e){
    console.error(e);
    const messageCod = e.message_cod || 'RELOAD_PAGE'
    throw {success: false, message_cod: messageCod};
  }
}

const ResetPassword = (props) => {
  const {
    handleSubmit,
    submitting,
    invalid,
    submitSucceeded,
    t,
  } = props;
  return (
    <ListItem >
        <SubmitButton
          onClick={handleSubmit}
          style={{width:'100%', minHeight: '80% !important', height: '80%'}}
          color='primary'
          submitText={submitSucceeded
            ? <div>{t('resetPassword.mailSent')}</div>
            : <div>{t('resetPassword.sendResetMail')}&nbsp;<FontAwesomeIcon icon='unlock' style={{marginBottom:'1px', marginLeft:'8px'}}/></div>}

          disabled={(submitting || invalid) || submitSucceeded}
        />
    </ListItem>
  );
};


const mapDispatchToProps = dispatch => ({
  onSubmitFail: (err, d, { message_cod }) => dispatch(addDialog({ // eslint-disable-line camelcase
    title: message_cod, // eslint-disable-line camelcase
    availableChoices: [
      {
        label: 'common.close',
        actionToTake: '',
        color: 'secondary',
        variant: 'raised',
      },
    ],
  })),
});

const mapStateToProps = ({ user: { profile: { email, tax_id } } }) => ({
  email, tax_id
});

const schemas = {
  email: {
    type: String,
    required: true,
  }
};

const enhance = compose(
  translate(),
  withRouter,
  withGoogleReCaptcha,
  connect(mapStateToProps, mapDispatchToProps),
  renderBranch(['email']),
  withProps(({ email, tax_id, history }) => {
    return {
      initialValues: {
        email,
        tax_id
      },
      onSubmitSuccess: (response) => {
        const nextLocation = {
          pathname: '/recoverPassword',
          state: { email, taxId: tax_id },
        };
        history.push(nextLocation);
      }
    }
  }),
  reduxForm({
    form: 'resetPasswordProfile',
    validate: validate(schemas),
    onSubmit: resetPass

  }),
);

export default enhance(ResetPassword);
