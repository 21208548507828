import { DEV } from '../config';

const getParametersByEnvironment = env => {
    const parameters = {
        'DEV' : {
            bench32PatternPrefix: 'addr_test1',
            byronPatternPrefix: 'DdzFF|Ae2',
            bench32Size: 110
        },
        'PROD': {
            bench32PatternPrefix: 'addr1',
            byronPatternPrefix: 'DdzFF|Ae2',
            bench32Size: 110
        }
    }
    if (env in parameters)
        return parameters[env];
    return parameters['PROD'];
}

const validateAdaAddress = address => {
    const parameters = DEV ? getParametersByEnvironment('DEV') : getParametersByEnvironment('PROD');

    const bench32Rule = `${parameters.bench32PatternPrefix}[a-z0-9].{32,${parameters.bench32Size}}$`;
    const byronRule = `${parameters.byronPatternPrefix}[1-9A-HJ-NP-Za-km-z]`;

    const bench32Regex = new RegExp(bench32Rule);
    const byronRegex = new RegExp(byronRule);

    return ( bench32Regex.test(address) || byronRegex.test(address) );
}

export default validateAdaAddress;