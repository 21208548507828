import React from 'react';
import FieldRow from '../../../components/Forms/FieldRow';
import EmailField from '../../../components/FormsV2/EmailField';
import PasswordField from '../../../components/FormsV2/PasswordField';

const CredentialsForm = props => {
  const {handleChange, values} = props;
  return (
    <React.Fragment>
      <FieldRow>
        <EmailField onChange={handleChange}
                    name="email"
                    value={values.email}/>
      </FieldRow>
      <FieldRow>
        <EmailField onChange={handleChange}
                    name="repeatEmail"
                    value={values.repeatEmail}/>
      </FieldRow>
      <FieldRow>
        <PasswordField onChange={handleChange}
                       name="pass"
                       value={values.pass}/>
      </FieldRow>

      <FieldRow>
        <PasswordField onChange={handleChange}
                       name="repeatPass"
                       value={values.repeatPass}/>
      </FieldRow>
    </React.Fragment>)
}

export default CredentialsForm;
