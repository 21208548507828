import React from "react";
import CriptoMaskedInput from "../../../../components/FormsV2/CriptoMaskedInput";
import { withStyles } from "@material-ui/core";
import { compose } from "recompose";
import { styles } from "./styles";

const ToAmountInput = ({ handleToInputChange, value, classes, currency, disabled }) => {
  return (
    <CriptoMaskedInput
      disabled={disabled}
      placeholder={`${currency.monetarySymbol} 0.${"0".repeat(
        currency.exhibitPrecision
      )}`}
      value={value}
      decimalScale={currency.exhibitPrecision}
      prefix={currency.monetarySymbol}
      name="toAmount"
      keepHeightWithHelperText={false}
      hasLabel={false}
      fixedDecimalScale
      onChange={handleToInputChange}
      customClasses={{ input: classes.input, helperTextContainer: classes.helperTextContainer }}
      formatter={(value) => value}
    />
  );
};

export default compose(withStyles(styles))(ToAmountInput);
