import { compose, pure, withState, withHandlers } from 'recompose';
import { connect } from 'react-redux';

import AppFrame from './AppFrame';
import {
  fetchOrderBookRequested,
  showBottomNavigation,
  hideBottomNavigation,
} from '../redux/actions';

const mapStateToProps = ({
  layout: { shouldShowBottomNavigation },
  credentials: { logged },
  market: {selectedMarket},
}) => ({
  shouldShowBottomNavigation,
  logged,
  selectedMarket,
});

const mapDispatchToProps = dispatch => ({
  fetchOrderBook: () => dispatch(fetchOrderBookRequested()),
  showNavigation: () => dispatch(showBottomNavigation()),
  hideNavigation: () => dispatch(hideBottomNavigation()),
});

const enhance = compose(
  pure,
  connect(mapStateToProps, mapDispatchToProps),
  withState('mobileOpen', 'handleDrawerToggle', false),
  withState('innerHeight', 'setInnerHeight', window.innerHeight),
  withHandlers({
    checkShouldRenderBottomNavigation: ({
      innerHeight, hideNavigation, showNavigation, shouldShowBottomNavigation,
    }) => () => {
      if ((window.innerHeight < (innerHeight * 0.7)) && shouldShowBottomNavigation) {
        hideNavigation();
      } else if ((window.innerHeight >= (innerHeight * 0.7)) && !shouldShowBottomNavigation) {
        showNavigation();
      }
    },
  })
);


export default enhance(AppFrame);
