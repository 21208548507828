const FETCH_EXTRACT = 'FETCH_EXTRACT';
const ENABLE_SYNC_EXTRACT = 'ENABLE_SYNC_EXTRACT';
const DISABLE_SYNC_EXTRACT = 'DISABLE_SYNC_EXTRACT';
const FETCH_EXTRACT_REQUESTED = 'FETCH_EXTRACT_REQUESTED';
const FETCH_EXTRACT_SUCCEED = 'FETCH_EXTRACT_SUCCEED';
const FETCH_EXTRACT_FAILED = 'FETCH_EXTRACT_FAILED';
const DOWNLOAD_EXTRACT_HISTORY_CSV = 'DOWNLOAD_EXTRACT_HISTORY_CSV';
const DOWNLOAD_EXTRACT_HISTORY_BISCOINT = 'DOWNLOAD_EXTRACT_HISTORY_BISCOINT';

export {
  FETCH_EXTRACT,
  ENABLE_SYNC_EXTRACT,
  DISABLE_SYNC_EXTRACT,
  FETCH_EXTRACT_REQUESTED,
  FETCH_EXTRACT_FAILED,
  FETCH_EXTRACT_SUCCEED,
  DOWNLOAD_EXTRACT_HISTORY_CSV,
  DOWNLOAD_EXTRACT_HISTORY_BISCOINT
};
