import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import ActivityIndicator from '../ActivityIndicator';

const SubmitButton = props => {
  const {
    submitText, disabled, style, className, classes, isSubmitting, ...rest
  } = props;
  return (
    <Button
      type="submit"
      variant="raised"
      color="primary"
      style={style}
      classes={classes}
      disabled={disabled}
      className={className}
      {...rest}
    >
      <span style={{
              display: 'flex',
              alignItems: 'center',
              gap: '10px',
      }}>
        {submitText}
        {isSubmitting && <ActivityIndicator disableWrapper={true} size={16} />}
      </span>
    </Button>
  );
};

SubmitButton.defaultProps = {
  disabled: false,
};

SubmitButton.propTypes = {
  submitText: PropTypes.string.isRequired,
  disabled: PropTypes.bool,

};

export default SubmitButton;
