import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import NumberFormat from 'react-number-format';
import InputField from '../Forms/TextField';


const PhoneMaskFormatInput = props => {
  const {
    inputRef, onChange, onBlur, value, ...other
  } = props;

  return (
    <NumberFormat
      {...other}
      value={value}
      ref={inputRef}
      onValueChange={values => {
        onChange(R.isNil(values.value) ? '' : values.value);
      }}
      onBlur={_event => {
        onBlur(value);
      }}
      format={"+55 (##) #####-####"}
      type="tel"
    />
  );
};

PhoneMaskFormatInput.defaultProps = {
  value: null,
};

PhoneMaskFormatInput.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  inputRef: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
};

const PhoneField = ({ InputProps, ...props }) => {
  return (
    <InputField
      {...props}
      // inputComponent={DateMaskFormatInput}
      InputProps={{
        ...InputProps,
        inputComponent: PhoneMaskFormatInput,
      }}
    />
  );
};

PhoneField.defaultProps = {
  InputProps: {},
};

PhoneField.propTypes = {
  InputProps: PropTypes.objectOf(PropTypes.any),
};

export default PhoneField;
