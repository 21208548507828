import React from 'react'

import Typography from '@material-ui/core/Typography'

const TitledPage = ({ title, children }) => {
  return (
    <React.Fragment>
      <Typography variant='display1' gutterBottom style={{ marginTop: 24}}>
        {title}
      </Typography>
      {children}
    </React.Fragment>
  )
}

export default TitledPage
