import React from "react";
import { styles } from "./styles";
import { withStyles } from "@material-ui/core/styles";
import { Typography, Grid } from "@material-ui/core";
import { compose } from "recompose";
import SelectButton from "../../../../components/FormsV2/Select";
import SelectItemChip from "../../../../components/SelectItemChip";
import PropTypes from "prop-types";
import { convertToTitleCase } from "../../../../utils/strings";

const CryptoConverterCard = ({
  label,
  currency,
  input,
  classes,
  handleCurrencySelector,
}) => {
  const selectButtonStyle = {
    backgroundColor: currency.color,
  };
  return (
    <Grid
      xs={12}
      container
      className={classes.cardContainer}
      alignItems="center"
      justify="space-between"
      direction="row"
    >
      <Grid container spacing={8} xs={12} sm={6} alignItems="center" direction="row" className={classes.selectButtonWrapper}>
        <Grid item xs={"auto"} sm={2}>
          <Typography className={classes.fontWeightBold} variant="body2">
            {convertToTitleCase(label)}
          </Typography>
        </Grid>

        <Grid item xs={"auto"} sm={4} > 
          <SelectButton
            style={selectButtonStyle}
            value={currency}
            customHandleClick={handleCurrencySelector}
            placeholder={currency.symbol}
            getLabel={(option) => {
              return (
                <SelectItemChip
                  itemName={option.symbol}
                  currency={option}
                  itemCode={option}
                />
              );
            }}
          />
        </Grid>
      </Grid>
      <Grid item sm={6} xs={12}>
        {input}
      </Grid>
    </Grid>
  );
};

CryptoConverterCard.propTypes = {
  currency: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  input: PropTypes.node.isRequired,
};

const enhance = compose(withStyles(styles));
export default enhance(CryptoConverterCard);
