import { put, takeLatest, call } from 'redux-saga/effects';
import { updateOrderBook } from './actions';
import { FETCH_ORDER_BOOK } from './actionTypes';
import fetchOrderBook from '../../services/cbtcService/orderBook';
import { removeCredentials } from '../actions';

function* fetchOrderBookSaga() {
  try {
    const orderBook = yield call(() => fetchOrderBook());

    if (orderBook) {
      yield put(updateOrderBook(orderBook));
    }
  } catch (error) {
    console.error('fetchOrderBookSaga', error);
  }
}

function* watchOrderBook() {
  yield takeLatest(FETCH_ORDER_BOOK, fetchOrderBookSaga);
}

export default watchOrderBook;

