import { put, takeLatest, call } from 'redux-saga/effects';
import { groupBy } from 'ramda';
import { updateOpenOrders } from './actions';
import { FETCH_OPEN_ORDERS } from './actionTypes';

import fetchOpenOrders from '../../services/cbtcService/openOrders';

function* fetchOpenOrdersSaga() {
  try {
    const openOrders = yield call(() => fetchOpenOrders());
    if (!Array.isArray(openOrders)) {
      return;
    }
    const myOpenOrders = groupBy(order => {
      return order.type;
    }, openOrders);

    const orderBooks = {
      SELL: myOpenOrders.SELL || [],
      BUY: myOpenOrders.BUY || [],
    };

    if (openOrders) {
      yield put(updateOpenOrders(orderBooks));
    }
  } catch (error) {
    console.error('fetchOrderBookSaga', error);
  }
}

function* removeOpenOrdersSaga() {
  try {
    yield put(updateOpenOrders({
      SELL: [],
      BUY: [],
    }));
  } catch (error) {
    console.error('removeOpenOrdersSaga', error);
  }
}

function* watchOpenOrders() {
  yield takeLatest(FETCH_OPEN_ORDERS, fetchOpenOrdersSaga);
  yield takeLatest('REMOVE_CREDENTIALS', removeOpenOrdersSaga);
}

export default watchOpenOrders;

