const FETCH_ORDER_BOOK = 'FETCH_ORDER_BOOK';
const FETCH_ORDER_BOOK_REQUESTED = 'FETCH_ORDER_BOOK_REQUESTED';
const FETCH_ORDER_BOOK_SUCCEED = 'FETCH_ORDER_BOOK_SUCCEED';
const FETCH_ORDER_BOOK_FAILED = 'FETCH_ORDER_BOOK_FAILED';
const UPDATE_ORDER_BOOK = 'UPDATE_ORDER_BOOK';

export {
  FETCH_ORDER_BOOK,
  FETCH_ORDER_BOOK_REQUESTED,
  FETCH_ORDER_BOOK_SUCCEED,
  FETCH_ORDER_BOOK_FAILED,
  UPDATE_ORDER_BOOK,
};
