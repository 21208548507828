import { put, takeLatest, select } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { START_KEEP_ALIVE_CHECK } from './actionTypes';
import {MS_TEN_MINUTES, MS_KEEP_ALIVE_INTERVAL_CHECK} from '../../config';
import { windowIsVisible } from '../../utils/keepAliveUtils';
import { updateKeepAliveTimestamp } from './actions';
import { fetchBalance } from '../actions';

function* checkUserActivity() {
  while (true){
    const lastTimestamp = yield select(state => state.keepAlive.timestamp)
    const timestampNow = Date.now()
    if( windowIsVisible() &&
      (timestampNow - lastTimestamp ) > MS_TEN_MINUTES && lastTimestamp !== 0){
      yield put(fetchBalance())
      yield put(updateKeepAliveTimestamp(timestampNow))
    }else if(!windowIsVisible())
      yield put(updateKeepAliveTimestamp(timestampNow))
    yield delay(MS_KEEP_ALIVE_INTERVAL_CHECK);
  }
}

function* watchUserActivity() {
  yield takeLatest(START_KEEP_ALIVE_CHECK, checkUserActivity);
}

export default watchUserActivity;
