import { createStore, applyMiddleware, compose } from 'redux';
import createEngine from 'redux-storage-engine-localstorage';
import filter from 'redux-storage-decorator-filter';
import {
  reducer as storageReducer,
  createMiddleware,
  createLoader,
} from 'redux-storage';
import createSagaMiddleware from 'redux-saga';

import * as R from 'ramda';

import rootReducer from './reducers';
import rootSaga from './sagas';
import { DEV } from '../config';
import {
  logger,
  // actionsBlacklist,
  // actionsWhitelist,
} from './dev';

const reducer = storageReducer(rootReducer);
const engine = filter(createEngine('redux-storage'),
  // White List
  [
    'user',
    'orderBook',
    'tradeHistory',
    'credentials',
    [
      'credentials', ['token', 'logged'],
    ],
    ['layout', 'newsDialog'],
  ],
  // Black list
  [
    // ['appData', 'credentials', 'captcha'],
  ]);

const middleware = createMiddleware(
  engine,
  [],
  [
    'REDUX_STORAGE_LOAD',
    'REMOVE_CREDENTIALS',
    'UPDATE_CREDENTIALS',
    'SET_NEWS_READ',
    'GENERATE_DEVICE_ID'
  ],
);

const sagaMiddleware = createSagaMiddleware();

const middlewares = R.reject(R.not, [middleware, sagaMiddleware, DEV && logger]);

const enhance = compose(applyMiddleware(...middlewares));
const reducers = R.reject(R.not, [
  reducer,
  DEV
    ? window.__REDUX_DEVTOOLS_EXTENSION__
    && window.__REDUX_DEVTOOLS_EXTENSION__({
      // eslint-disable-line
      // actionsBlacklist,
      // actionsWhitelist,
    })
    : false,
]);

const createStoreWithMiddleware = enhance(createStore);
const store = createStoreWithMiddleware(...reducers);

const load = createLoader(engine);

load(store)
  .then(newState => DEV && console.log('Loaded state:', newState)) // eslint-disable-line no-console
  .catch(() => console.log('Failed to load previous state')); // eslint-disable-line no-console

sagaMiddleware.run(rootSaga);


export default store;
