import React from 'react';
import { reduxForm } from 'redux-form';
import { compose } from 'recompose';
import * as R from 'ramda'

import { withStyles } from '@material-ui/core/styles';
import validate from '../../utils/validate';
import { isBBAccountDigitValid } from '../../utils/validateBankAccount'
import BankAccountField from './BankAccountField';
import classNames from 'classnames';


const styles = theme => ({
  root: {
    flex: 1,
  },
  container: {
    display: 'flex',
    // flex: 1,
    flexWrap: 'wrap',
    // width: '100%',
    justifyContent: 'space-between',
  },
  textField: {
    marginRight: theme.spacing.unit,
    // display: 'flex',
    flex: 1,
    maxWidth: 200,
    // width: '100%',
    // flexWrap: 'wrap',

  },
  selectField: {
    flex: 1,
    marginRight: theme.spacing.unit,
    maxWidth: 300,
    // width: '30%',
  },
  button: {
    maxHeight: theme.spacing.unit * 4,
    alignSelf: 'center',
  },
});

const validateBBAccountDigit = (values, props) => {
  const isBB = values.bank && values.bank.key === '001'
  if (!isBB) return {}
  const isDigitValid = isBBAccountDigitValid(values.account)

  return !isDigitValid
    ? { account: 'INVALID_DIGIT' }
    : {}
}

const _validate = (values, props) => {
  return R.merge(
    validate(schemas)(values, props),
    validateBBAccountDigit(values, props)
  )
}

const BankAccountForm = props => {
  const {
    handleSubmit,
    classes,
    disableSubmit,
    formWithdrawal,
    classEditBank
  } = props;
  return (
    <form onSubmit={handleSubmit} className={classes.root}>
      <BankAccountField disableSubmit={disableSubmit} classEditBank={classEditBank} formWithdrawal={formWithdrawal ? true : false} />
    </form>
  );
};

const schemas = {
  agency: {
    type: String,
    min: 4,
    regEx: /^[0-9]*$/,
  },
  account: {
    type: String,
    min: 5,
    regEx: /^[0-9]*$/,
  },
  bank: {
    type: Object,
    min: 3,
    properties: {
      key: String,
      labbel: String,
    },
  }
};

const enhance = compose(
  withStyles(styles),
  reduxForm({
    validate: _validate,
    form: 'bankAccount',
    enableReinitialize: true,
  }),
);

export default enhance(BankAccountForm);
