import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import NumberFormat from 'react-number-format';

const removeFormatting = val => R.join('',
  R.reject(R.isEmpty,
    R.split('_',
      R.join('',
        R.split('-', val)))));


const onlyOneX = val => {
  const OldVal = val.substring(0, val.length-1);
  return (val.split("").filter(a => (a==='X' || a==='x')).length === 1)? true && !Number.isNaN(Number(OldVal))  : false
}

const NumberMaskFormatInput = props => {
  const {
    inputRef, onChange, onBlur, value, ...other
  } = props;

  return (
    <NumberFormat
      {...other}
      value={value}
      ref={inputRef}
      onKeyPressed={(e) => {
        console.log(e)
      }}
      isAllowed={values =>  !Number.isNaN(Number(values.value)) || onlyOneX(values.value)}
      onValueChange={values => {
        onChange(R.isNil(values.value) ? '' : values.value);
      }}
      onBlur={_event => {
        onBlur(value);
      }}
      format={(val) => {
        const cleanVal = removeFormatting(val);
        const [init, end] = R.splitAt(-1, cleanVal);
        const insertLe = 3 - init.length;
        console.log(cleanVal, init, end)
        if (insertLe > 0) {
          const tiles = R.reduce((acc, el) => {
            const lala = R.concat('_', acc);
            return lala;
          }, '_', R.range(1, insertLe));
          return `${tiles}${init}-${end}`;
        }
        return `${init}-${end}`;
      }}
      removeFormatting={val => {
        const cleanVal = removeFormatting(val);

        return cleanVal;
      }}
      type="tel"
    />
  );
};

NumberMaskFormatInput.defaultProps = {
  value: null,
};

NumberMaskFormatInput.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  inputRef: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
};


export default NumberMaskFormatInput;
