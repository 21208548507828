import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import * as R from 'ramda';

import { withStyles } from '@material-ui/core/styles';

import Divider from '@material-ui/core/Divider';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';

import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import InfoTooltip from '../../components/InfoTooltip';
import DrawerLinkText from './DrawerLinkText';
import DrawerLink from './DrawerLink';
import { translate } from 'react-i18next';

const styles = theme => ({
  toolbar: theme.mixins.toolbar,
  container: {
    color: theme.palette.secondary.main,
    backgroundColor: theme.palette.primary.dark,

  },
  activeContainer: {
    backgroundColor: theme.palette.grey[700],
    '&:hover': {
      backgroundColor: theme.palette.grey[900],
    },
  },
  content: {
    color: theme.palette.grey[400],
  },
  activeContent: {
    color: theme.palette.grey[300],
  },
  avatar: {
    backgroundColor: theme.palette.secondary.main,
    borderRadius: '20%',
  },
});

const EMPTY_SPACE = ' ';
const FIRST_NAME = 0;

const formatUserName = (userName, translator, demoAccount) => {
  try{
    if(!userName && !demoAccount){
      return translator('common.demoAccountUserName')
    }
    else if(!userName && demoAccount){
      return translator('common.emptyUserName')
    }
    const name = userName.split(EMPTY_SPACE);
    if(name.length > 1)
      return `${name[FIRST_NAME]} ${name[name.length-1]}`;
    return userName;
  }
  catch (e) {
    return userName;
  }
}

const DrawerUserItem = ({
  name = '',
  icon,
  classes,
  scene,
  primary,
  currentScene,
  drawerNavigation,
  dispatch,
  tooltip,
  t,
  handleDrawerToggle,
  ...rest
}) => {
  const formattedName = formatUserName(name, t);
  const initials = R.map(R.head, R.split(' ', R.trim(formattedName)));

  return (
    <DrawerLink  {...{ scene, currentScene, ...rest }}>
      <ListItemAvatar>
        <Avatar className={classes.avatar}>
          {initials}
        </Avatar>
      </ListItemAvatar>
      {/* <DrawerLinkText
        currentScene={currentScene}
        scene={scene}
        primary={formattedName}
      /> */}
      <ListItemSecondaryAction >
        {tooltip &&
          <InfoTooltip listItemVariant {...tooltip} />
        }
      </ListItemSecondaryAction >
      <Divider />
    </DrawerLink>

  );
};

DrawerUserItem.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

const mapStateToProps = (state) => {
  const { user: { profile: { name } } } = state;

  return {
    name,
  };
};


const enhance = compose(
  translate(),
  withStyles(styles),
  connect(mapStateToProps, null),

);

export default enhance(DrawerUserItem);
