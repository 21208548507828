import * as R from 'ramda';
import {
  branch,
  renderComponent,
  mapProps,
} from 'recompose';

import ActivityIndicator from './ActivityIndicator';
import isNilOrEmpty from '../utils/isNilOrEmpty';

export const isLoading = (requiredProps = []) => props => {
  // eslint-disable-next-line no-use-before-define
  const checkThisRequiredPropIsArray = checkRequiredPropIsArray(props);
  const anyEmpty = R.any(
    isNilOrEmpty, R.map(checkThisRequiredPropIsArray, requiredProps));

  return anyEmpty;
};

const splitHead = R.splitAt(1, R.__);

const checkNextRequiredPropIsLoading = (props, a) => {
  const [head, rest] = splitHead(a);
  return isLoading(rest)(R.prop(head, props));
};

const checkNextRequiredPropIsArray = R.curry((props, a) => (
  R.is(Array, a[1])
    ? checkNextRequiredPropIsLoading(props, a)
    : R.path(a, props)));

const checkRequiredPropIsArray = R.curry((props, a) => (R.is(Array, a)
  ? checkNextRequiredPropIsArray(props, a)
  : R.prop(a, props)));

/**
 * Check if required properties loaded and return an ActivityIndicator if false
 * @param {(string[]|[string[]])} requiredProps
 */
const renderBranch = (requiredProps = []) => branch(
  isLoading(requiredProps),
  renderComponent(mapProps(() => ({ size: 10 }))(ActivityIndicator)));

export default renderBranch;
