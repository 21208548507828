import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { compose, pure, withProps } from 'recompose';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import * as R from 'ramda';

import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import Hidden from '@material-ui/core/Hidden';

import { withRouter } from 'react-router-dom';
import { removeCredentials } from '../../redux/actions';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Visibility from '@material-ui/icons/Visibility';
import BrasilNumberFormat from '../../utils/NumberFormat/BrasilNumberFormat';
import DrawerLinkItem from './DrawerLinkItem';
import DrawerItem from './DrawerItem';
import DrawerUserItem from './DrawerUserItem';
import InfoTooltip from '../../components/InfoTooltip';
import CriptoFormat from '../../utils/NumberFormat/CriptoFormat';
import ActivityIndicator from '../../components/ActivityIndicator';
import CurrencyFormat from '../../components/Currency/CurrencyFormat';
import AppBarTokenMenu from '../AppBar/AppBarTokenMenu';
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';
import AppDashboard from "../../containers/AppDashboard";
import {Typography} from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import { useState } from 'react';
import { useEffect } from 'react';
import { Currency, getCriptoCurrencies, getCurrencyBySymbol } from '../../models/Currency';
import { handleAppBarMenu } from "../../redux/actions";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItem from "@material-ui/core/ListItem";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import CurrencyIcon from "../../components/CurrencyIcon";
import ListItemText from "@material-ui/core/ListItemText";

const styles = (theme) => ({
  listItem: {
    display: "flex",
    justifyContent: "space-between",
    paddingLeft: 10,
    paddingRight: 10,
    borderRadius: 30,
  },
  listItemText: {
    color: "white",
  },
  textSizing: {
    fontSize: "0.875rem",
  },
  row: {
    alignItems: "center",
    background:
      "linear-gradient(90deg, rgba(16,16,16,1) 0%, rgba(74,74,74,1) 100%)",
    borderRadius: 30,
    marginRight: "4%",
    marginLeft: "6%",
    zIndex: 10,
  },
  svgColor: {
    fill: `${theme.palette.secondary.main}`,
  },
  drawerHeader: {
    cursor: "pointer",
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    padding: "8px 32px",
    marginTop: "10px",
  },
  flex: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  tokenMenu: {
    color: "white",
    marginTop: 15,
    "@media only screen and (min-height: 800px)": {
      marginBottom: 15,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  drawerControl: {
    marginLeft: "80%",
    color: "white",
    marginBottom: "-55px",
    zIndex: 20,
  },
  logout: {
    marginTop: "10%",
    borderRadius: "50px",
    border: `solid ${theme.palette.grey[500]} 2px`,
    width: "82%",
    height: "40px",
    display: "inline-table",
    marginLeft: "27px",
  },
  containerSpacing: {
    marginTop: "10px",
  },
  balanceSection: {
    display: "flex",
    color: "white",
    alignItems: "flex-start",
    flexDirection: "column",
    marginTop: 10,
  },
  balanceSectionCurrency: {
    color: "white",
    fontWeight: 500,
    fontSize: 12,
    lineHeight: "15px",
  },
  balanceSectionLabel: {
    color: theme.palette.grey[500],
    fontWeight: 500,
    fontSize: 12,
    fontStyle: "italic",
    textTransform: "none",
  },
  balanceInfoLabel: {
    color: theme.palette.grey[500],
    textTransform: "none",
    fontSize: 12,
    marginTop: 10,
    fontWeight: 500,
  },
  balanceInfoValue: {
    color: "white",
    fontWeight: 500,
  },
  divider: {
    backgroundColor: theme.palette.grey[800],
    height: 2,
    borderRadius: 50,
    width: "80%",
    marginLeft: "auto",
    marginRight: "auto",
  },
  iconVisibility: {
    display: "flex",
    justifyContent: "end",
    marginRight: "15px",
    marginLeft: "-2px",
  },
  customLeftTextClass: {
    color: theme.palette.grey[400],
    // display: 'flex',
    flex: 2,
    textTransform: 'uppercase',
    marginLeft: 16,
    paddingRight: 0,

    '&:first-child': {
      marginLeft: 0,

    },
  }
});

const BalanceSectionLabel = ({classes, currency, label}) => {
  return (<div className={classes.balanceSection}>
    <Typography className={classes.balanceSectionCurrency}>
      {currency}
    </Typography>
    <Typography className={classes.balanceSectionLabel}>
      {label}
    </Typography>
  </div>)
}

const BalanceDataInfo = ({classes, label, numberFormatComponent, showBalance}) => {
  return (
      <React.Fragment>
        <Typography className={classes.balanceInfoLabel}>
          {label}
        </Typography>
        <Typography className={classes.balanceInfoValue}>
          {showBalance ? numberFormatComponent : '• • • • • • •'}
        </Typography>
      </React.Fragment>
  )
}

const DrawerList = props => {
  const {
    classes,
    navItems,
    handleDrawerToggle,
    userBalance,
    scene,
    t,
    total,
    market,
    history,
    ticker,
    currencies,
    fullRoute,
    setAnchorEl,
    anchorEl,
    demoAccount,
  } = props;
  const [showBalance, setShowBalance] = useState(localStorage.getItem('balance_visibility') ? JSON.parse(localStorage.getItem('balance_visibility')) : true)
  useEffect(()=>{
    localStorage.setItem('balance_visibility', showBalance);
  },[showBalance])

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget, demoAccount);
  };
  const currency = market.split('-')[0];
  const tokenConfig = new Currency(getCurrencyBySymbol(currencies, currency) || getCurrencyBySymbol(currencies, "BTC"));
  const quoteCurrency = new Currency(getCurrencyBySymbol(currencies, "BRL"));
  const precision = tokenConfig.exhibitPrecision;
  const logoutItem = navItems.map(
      ({
         primary, tooltip, bottonNavigation, drawerNavigation = true, ...drawerItem
       }) => {
        const isSessionEndingButton = primary.includes('logout') || primary.includes('login');
        if (isSessionEndingButton ) {
          return (
              <DrawerLinkItem
                  currentScene={scene}
                  fullRoute={fullRoute}
                  key={`drawer_link_item_${primary}`}
                  primary={t(`screens.${primary}.title`)}
                  handleDrawerToggle={handleDrawerToggle}
                  {...drawerItem}
              />
          );
        }
        return null;
      },
  );

  const navigationItems = navItems.map(
    ({
      primary, tooltip, bottonNavigation, drawerNavigation = true, ...drawerItem
    }) => {
      const isSessionEndingButton = primary.includes('logout') || primary.includes('login');
      if (drawerNavigation && !isSessionEndingButton) {
        return (
          <DrawerLinkItem
            currentScene={scene}
            fullRoute={fullRoute}
            key={`drawer_link_item_${primary}`}
            primary={t(`screens.${primary}.title`)}
            handleDrawerToggle={handleDrawerToggle}
            {...drawerItem}
          />
        );
      }
      return null;
    },
  );

  return (
    <Fragment>
      <Hidden smDown>
        <div
          className={classes.drawerHeader}
          onClick={(e) => history.push("/express-trade/buy")}
        >
          <svg
            width="198"
            height="42"
            viewBox="0 0 198 42"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>{t("imageAlts.bityLogo.title")}</title>
            <desc>{t("imageAlts.bityLogo.desc")}</desc>
            <path
              d="M89.953 30.8723V26.7233H87.1479C85.4949 26.7233 84.9038 26.3251 84.9038 24.7856V19.2517H89.953V15.4416H84.9038V10.2881H80.2285C80.2285 15.293 80.2285 20.2978 80.2285 25.3087C80.2285 29.1782 82.4425 30.8663 86.0861 30.8663H89.953V30.8723Z"
              fill="white"
            />
            <path
              d="M78.0834 15.4482H73.4082V30.873H78.0834V15.4482Z"
              fill="white"
            />
            <path
              d="M78.0834 10.2949H73.4082V13.9208H78.0834V10.2949Z"
              fill="white"
            />
            <path
              d="M63.7757 26.79H56.7417V22.2784H64.3488C66.4542 22.2784 67.6969 22.5935 67.6969 24.5312C67.6969 26.6473 65.9897 26.784 63.7757 26.784M62.8105 14.361C65.0245 14.361 66.8161 14.4858 66.8161 16.5187C66.8161 18.5515 65.1029 18.6823 63.0035 18.6823H56.7417V14.361H62.8105ZM69.2292 20.305C70.5443 19.6215 71.2682 18.3791 71.2682 16.1264C71.2682 11.9953 68.457 10.2715 64.1075 10.2715H52.1992V30.8735H66.2792C69.4705 30.8735 72.1429 28.9893 72.1429 25.0186C72.1429 22.3082 71.0872 20.9648 69.2231 20.311L69.2292 20.305Z"
              fill="white"
            />
            <path
              d="M95.629 36.0919C99.5562 36.0919 101.643 35.2003 103.14 31.9786L110.771 15.4482H105.577L101.462 26.0405H101.149L96.6364 15.4482H91.3398L98.8685 30.9384C98.5548 31.616 98.0601 31.9192 96.9019 31.9192H93.0652V36.0978H95.623L95.629 36.0919Z"
              fill="white"
            />
            <path
              d="M114.678 23.2354V22.962C114.678 19.247 116.904 17.1487 120.705 17.1487C124.161 17.1487 126.544 18.611 126.544 22.962C126.544 27.313 124.197 28.805 120.644 28.805C117.091 28.805 114.684 26.7959 114.684 23.2354M114.684 34.969V26.0648H114.714C115.426 28.9536 117.863 30.7844 121.229 30.7844C126.08 30.7844 128.77 27.527 128.77 22.962C128.77 18.397 126.049 15.1396 121.296 15.1396C117.682 15.1396 115.396 16.9645 114.714 19.6749H114.467V15.4428H112.488V34.969H114.684Z"
              fill="white"
            />
            <path
              d="M133.071 30.4753V22.32C133.071 18.9438 134.428 17.0239 137.517 17.0239C140.171 17.0239 141.257 18.2722 141.257 20.8281V21.9872H143.453V20.4952C143.453 17.6956 141.909 15.1396 138.386 15.1396C135.206 15.1396 133.475 17.1487 133.011 19.5501H132.854V15.4428H130.875V30.4812H133.071V30.4753Z"
              fill="white"
            />
            <path
              d="M153.139 16.9339C156.722 16.9339 158.761 18.6101 158.761 21.7723V22.23H147.239C147.396 18.9429 149.188 16.9339 153.139 16.9339ZM153.199 30.7835C157.464 30.7835 160.613 28.6198 160.613 25.4873V25.2733H158.417V25.4873C158.417 27.6807 156.752 28.9884 153.163 28.9884C149.115 28.9884 147.354 26.9496 147.233 23.5674H160.674C160.764 23.1692 160.83 22.7174 160.83 22.1646C160.83 17.6887 157.651 15.1328 153.169 15.1328C148.259 15.1328 145.17 18.3901 145.17 22.9552C145.17 27.5202 148.289 30.7775 153.199 30.7775V30.7835Z"
              fill="white"
            />
            <path
              d="M171.829 34.957C173.464 34.957 174.423 34.107 174.423 32.7042C174.423 31.6818 173.862 30.9269 172.873 30.6238C171.003 30.0591 170.562 31.022 172.861 30.6059C176.191 29.9997 178.604 27.735 178.604 24.6619V24.4182H176.444V24.6025C176.444 27.2832 174.466 28.8049 170.979 28.8049C166.901 28.8049 165.139 26.6413 165.139 22.9619C165.139 19.2826 166.901 17.1189 170.979 17.1189C174.472 17.1189 176.444 18.6406 176.444 21.3214V21.5056H178.604V21.2619C178.604 17.642 175.268 15.1455 171.003 15.1455C166.123 15.1455 162.943 18.4028 162.943 22.9679C162.943 27.099 165.562 30.178 169.7 30.7129C169.73 31.2895 169.706 30.9329 169.754 31.8601H171.829C172.414 31.8601 172.909 31.985 172.909 32.7458C172.909 33.4472 172.414 33.5958 171.829 33.5958H169.386V34.9629H171.829V34.957Z"
              fill="white"
            />
            <path
              d="M189.003 28.8347C184.805 28.8347 182.886 26.6116 182.886 22.962C182.886 19.3124 184.799 17.0893 189.003 17.0893C193.208 17.0893 195.15 19.3421 195.15 22.962C195.15 26.5819 193.202 28.8347 189.003 28.8347ZM189.003 30.7843C194.01 30.7843 197.31 27.527 197.31 22.962C197.31 18.397 194.004 15.1396 189.003 15.1396C184.002 15.1396 180.727 18.397 180.727 22.962C180.727 27.527 184.032 30.7843 189.003 30.7843Z"
              fill="white"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M23.8407 26.7957H14.9668V22.2782H24.4138C26.5252 22.2782 27.7679 22.5933 27.7679 24.537C27.7679 26.659 26.0607 26.7957 23.8407 26.7957ZM26.8871 16.5125C26.8871 18.5989 25.1739 18.6762 23.0685 18.6762H14.9668V14.3489H22.8755C24.5465 14.3489 26.8871 14.2657 26.8871 16.5125ZM0 0H41.7574V41.1446H10.4122V30.8852H26.3502C29.5475 30.8852 32.226 28.995 32.226 25.0184C32.226 22.302 31.1703 20.9587 29.3002 20.3048C30.6153 19.6153 31.3452 18.373 31.3452 16.1202C31.3452 11.9772 28.528 10.2535 24.1725 10.2535H10.4122V30.8852H0V0Z"
              className={classes.svgColor}
            />
          </svg>
        </div>
      </Hidden>
      <List>
        <Hidden mdUp>
          <IconButton
            color="inherit"
            aria-label="close drawer"
            onClick={() => handleDrawerToggle(false)}
            className={classes.drawerControl}
          >
            <MenuIcon />
          </IconButton>
          <DrawerUserItem
            currentScene={scene}
            scene="profile"
            to="/profile"
            primary={t("screens.profile.title")}
            handleDrawerToggle={handleDrawerToggle}
          />
        </Hidden>
        <Hidden mdUp>
          <div className={classes.tokenMenu}>
            <AppBarTokenMenu variant="secondary" />
          </div>
        </Hidden>
        <Hidden smDown>
          <div className={classes.tokenMenu}>
            <div className={classes.row}>
              <List dense>
                <ListItem
                  button
                  classes={{ root: classes.listItem }}
                  onClick={handleClick}
                >
                  <ListItemIcon classes={{ root: classes.listItemText }}>
                    <CurrencyIcon currency={tokenConfig} />
                  </ListItemIcon>
                  <ListItemText
                    primary={tokenConfig.currencyLabel}
                    classes={{
                      root: classes.textSizing,
                      primary: classes.listItemText,
                    }}
                  />

                  {Boolean(anchorEl) ? (
                    <KeyboardArrowLeft fontSize={32} />
                  ) : (
                    <KeyboardArrowRight fontSize={32} />
                  )}
                </ListItem>
              </List>
            </div>
          </div>
        </Hidden>
        <AppDashboard
          userBalance={userBalance}
          market={market}
          ticker={ticker}
          symbol={currency}
        />
        {userBalance && (
          <React.Fragment>
            <DrawerItem
              label={
                <BalanceSectionLabel
                  classes={classes}
                  label={tokenConfig.name}
                  showBalance={showBalance}
                  currency={tokenConfig.symbol}
                />
              }
              leftText={
                <BalanceDataInfo
                  classes={classes}
                  label={`${t("common.available")}:`}
                  t={t}
                  showBalance={showBalance}
                  numberFormatComponent={
                    <CriptoFormat
                      value={Number(userBalance[tokenConfig.symbol] || 0)}
                      decimalScale={precision}
                      currencyTickerLast={
                        ticker[currency] ? ticker[currency].last : NaN
                      }
                    />
                  }
                />
              }
              rightText={
                <BalanceDataInfo
                  classes={classes}
                  label={`${t("common.inUse")}:`}
                  t={t}
                  showBalance={showBalance}
                  numberFormatComponent={
                    <CriptoFormat
                      value={Number(
                        userBalance[`${tokenConfig.symbol}_locked`] || 0
                      )}
                      decimalScale={precision}
                      currencyTickerLast={
                        ticker[currency] ? ticker[currency].last : NaN
                      }
                    />
                  }
                />
              }
            />

            <Divider classes={{ root: classes.divider }} />
            <DrawerItem
              label={
                <BalanceSectionLabel
                  classes={classes}
                  label={"Real"}
                  currency={"BRL"}
                />
              }
              leftText={
                <BalanceDataInfo
                  classes={classes}
                  label={`${t("common.available")}:`}
                  t={t}
                  showBalance={showBalance}
                  numberFormatComponent={
                    <BrasilNumberFormat
                      decimalScale={2}
                      value={Number(userBalance.BRL)}
                    />
                  }
                />
              }
              rightText={
                <BalanceDataInfo
                  classes={classes}
                  label={`${t("common.inUse")}:`}
                  t={t}
                  showBalance={showBalance}
                  numberFormatComponent={
                    <BrasilNumberFormat
                      decimalScale={2}
                      value={Number(userBalance.BRL_locked)}
                    />
                  }
                />
              }
            />
            <Divider classes={{ root: classes.divider }} />
          </React.Fragment>
        )}
        <DrawerItem
          customLeftTextClass={classes.customLeftTextClass}
          leftText={
            <BalanceDataInfo
              classes={classes}
              t={t}
              showBalance={showBalance}
              label={
                <div className={classes.flex}>
                  {t("info.estimatedTotal")}
                  <InfoTooltip
                    component="span"
                    gutterBottom
                    placement="right"
                    title={t("info.balanceTotal")}
                    wrapperRootHeader
                    iconProps={{ style: { fontSize: 14, margin: 4 } }}
                  />
                </div>
              }
              numberFormatComponent={
                !isNaN(total) ? (
                  <CurrencyFormat
                    isFiat
                    formatter={quoteCurrency._defaultFormatter()}
                    value={total}
                  />
                ) : (
                  <ActivityIndicator color="secondary" size={16} />
                )
              }
            />
          }
          rightText={
            <div className={classes.iconVisibility}>
              <IconButton
                color="secondary"
                aria-label="Toggle password visibility"
                onClick={() => {
                  setShowBalance(!showBalance);
                }}
              >
                {showBalance ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </div>
          }
        />

        <div className={classes.containerSpacing}>{navigationItems}</div>
        <div className={classes.logout}>{logoutItem}</div>
      </List>
    </Fragment>
  );
};

DrawerList.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = ({
  user: { balance },
  ticker,
  credentials: { demoAccount },
  market,
  currencies,
  layout: { appBar }
}) => ({
  userBalance: balance,
  ticker,
  market: market.selectedMarket,
  currencies: currencies.currencies,
  anchorEl: appBar.anchorEl,
  demoAccount,
});

const mapDispatchToProps = (dispatch) => ({
  dispatchRemoveCredentials: () => dispatch(removeCredentials()),
  setAnchorEl: (anchorEl, isCurrencySelectorFilter) =>
    dispatch(handleAppBarMenu(anchorEl, isCurrencySelectorFilter)),
});


const getTotalCryptos = ( userBalance, ticker, currencies ) =>{
  const totalByCurrency = currencies.map(tokenConfig => {
    const currency = tokenConfig.symbol;
    const availableBalance = userBalance[currency] ? userBalance[currency] : 0;
    const lockedBalance = userBalance[`${currency}_locked`] ? userBalance[`${currency}_locked`] : 0;
    
    if (currency in ticker) {
      if (ticker[currency] && ticker[currency].last) {
        return (availableBalance + lockedBalance) * ticker[currency].last;
      }
      return 0;
    }
    return 0;
  });
  return R.sum(totalByCurrency);
}

const enhance = compose(
  withRouter,
  translate(),
  pure,
  withStyles(styles),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withProps(props => {
    if (props.userBalance) {
      const {
        success, BRL, BRL_locked, BRL_debt, timestamp,
        ...userBalance
      } = props.userBalance;
      const { ticker } = props;
      const totalCryptos = getTotalCryptos(userBalance, ticker, getCriptoCurrencies(props.currencies));
      const total_BRL = BRL + BRL_locked + totalCryptos;
      return {
        total: total_BRL
      };
    }
    return null;
  }),
);

export default enhance(DrawerList);
