import { put, takeLatest } from 'redux-saga/effects';
import { FETCH_CURRENCIES_REQUESTED, FETCH_MARKET_REQUESTED, UPDATE_CURRENCIES, UPDATE_MARKET } from './actionTypes';
import { fetchService } from '../actions';

function* FetchCurrencies() {
  try {
    yield put(fetchService({
      requestType: 'fetchCurrencies',
      successAction: UPDATE_CURRENCIES,
      paramns: {
        privateService: true,
        options: {
          cmd: 'get_currencies',
          method: 'POST',
        },
      },
    }));
  } catch (e) {
    console.error(FETCH_CURRENCIES_REQUESTED, e);
  }
}

function* FetchMarket() {
  try {
    yield put(fetchService({
      requestType: 'fetchMarket',
      successAction: UPDATE_MARKET,
      paramns: {
        privateService: true,
        options: {
          cmd: 'get_markets',
          method: 'POST',
        },
      },
    }));
  } catch (e) {
    console.error(FETCH_MARKET_REQUESTED, e);
  }
}



function* watchCurrencies() {
  yield takeLatest(FETCH_MARKET_REQUESTED, FetchMarket);
  yield takeLatest(FETCH_CURRENCIES_REQUESTED, FetchCurrencies);
}

export default watchCurrencies;
