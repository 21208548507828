import Button from "@material-ui/core/Button";
import { Grid, Card, Typography } from "@material-ui/core";
import React from "react";
import { compose } from "recompose";
import { withStyles, } from "@material-ui/core/styles";
import { translate } from "react-i18next";
import PropTypes from "prop-types";
import BityProgressBar from "./ProgressBar";

const styles = (theme) => ({
  valueStyle: {
    color: "#62560A",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "bold",
    lineHeight: "normal",
  },
  labelStyle: {
    color: "#62560A",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
  },
  card: {
    position: "relative",
    display: "flex",
    flex: 1,
    borderRadius: "8px",
    border: "1px solid #D6D1B4",
    padding: "20px",
    background: "#FEF4D2",
    boxShadow: "none",
  },
});

const ProgressCard = (props) => {
  const { classes, label, levelsAmount, steps, max, progress } = props;
  const percentageProgress = progress !== null ? `${progress}%` : "";

  return (
    <Card className={classes.card}>
      <Grid container direction="column">
        <Grid container alignItems="center" justify="space-between">
          <Typography>{label}</Typography>
          <Grid>
            <Typography className={classes.valueStyle}>
              {percentageProgress}
            </Typography>
          </Grid>
        </Grid>
        <BityProgressBar
          current={0}
          progress={progress}
          steps={steps}
          levelsAmount={levelsAmount}
          max={max}
        />
      </Grid>
    </Card>
  );
};

const enhance = compose(withStyles(styles), translate());

ProgressCard.propTypes = {
  label: PropTypes.string,
  steps: PropTypes.array.isRequired,
  current: PropTypes.number.isRequired,
  progress: PropTypes.number,
  levelsAmount: PropTypes.number,
  max: PropTypes.number.isRequired,
};

ProgressCard.defaultProps = {
  label: "",
  steps: ["0%", "0.5%", "1%"],
  levelsAmount: 10,
  max: 1,
};

export default enhance(ProgressCard);
