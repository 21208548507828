import * as R from "ramda";
import { FETCH_REFERRALS_SUCCEED } from "./actionsTypes";

const initialState = {
  referrals: [],
};

const updateReferrals = (state, { response }) => {
  const data = response.data;
  return R.merge(state, {
    referrals: data,
  });
};

const mgmReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_REFERRALS_SUCCEED:
      return updateReferrals(state, action);
    default:
      return state;
  }
};

export default mgmReducer;
