import React from 'react';
import { compose, pure, lifecycle } from 'recompose';

import { withStyles } from '@material-ui/core/styles';
import Hidden from '@material-ui/core/Hidden';
import { translate } from 'react-i18next'
import AppBar from './AppBar';
import Alerts from './Alerts';
import BottomNavigation from './BottomNavigation';
import AppDrawer from './Drawer';
import SnackBarComponent from './SnackBar';
import AppContent from './AppContent';
import { addDialog } from '../redux/actions';
import { SlideDialog } from './Dialogs';
import AsideDialog from './Dialogs/AsideDialog';
import NewsDialog from './Dialogs/NewsDialog';
import RenewConnectionDialog from './Dialogs/RenewConnectionDialog';
import IntercomChat from './IntercomChat';
import AppOtpDialog from './Dialogs/AppOtpDialog';
import AppResendEmailDialog from "./Dialogs/AppResendEmailDialog";
import AppValidationTokenDialog from './Dialogs/AppValidationTokenDialog';
import AppSmsTokenDialog from "./Dialogs/AppSmsTokenDialog";
import UserDeclarationDialog from './Dialogs/UserDeclarationDialog/UserDeclarationDialog';
import AppFooter from '../components/Cards/AppFooter';
import { VERSION_WEB } from '../config';
import { connect } from 'react-redux';
import OtpAppDialog from '../components/OtpAppDialog';

const styles = _theme => ({
  root: {
    display: 'flex',
    alignItems: 'stretch',
    minHeight: '100vh',
    width: '100%',
  },
  card: {
    display: 'flex',
    alighItens: 'center',
    justifyContent:'center',
    width: '100%',
    borderTop: 15,
    overflow: 'visible',
    minHeight: '50px',
    borderRadius: 0
  },
  button: {
    margin: _theme.spacing.unit,
    marginLeft: 'auto',
    marginRight: 'auto',
    fontSize: '5'
  },
  buttonWrapper: {

  }
});

const AppFrame = (props) => {
  const {
    children,
    navigationOptions,
    classes,
    location,
    logged,
    mobileOpen,
    shouldShowBottomNavigation,
    handleDrawerToggle,
  } = props;
  
  const scene = location.pathname.split('/')[1];
  return (
    <div>
      <div className={classes.root}>
        <AppBar
          logged={logged}
          navigationOptions={navigationOptions}
          mobileOpen={mobileOpen}
          handleDrawerToggle={handleDrawerToggle}
        >
        </AppBar>
        <AppDrawer
                  fullRoute={location.pathname}
                  scene={scene}
                  // navItems={navItems}
                  logged={logged}
                  mobileOpen={mobileOpen}
                  handleDrawerToggle={handleDrawerToggle}
        />
        <AppContent logged={logged} style={{marginTop: 80}}>
          {children}
          <SnackBarComponent />
          <Alerts />
          <SlideDialog />
          <AsideDialog />
          <NewsDialog />
          <RenewConnectionDialog />
          <AppOtpDialog />
          <OtpAppDialog />
          <AppResendEmailDialog/>
          <AppValidationTokenDialog/>
          <AppSmsTokenDialog/>
          <UserDeclarationDialog/>
          {shouldShowBottomNavigation && <BottomNavigation logged={logged} scene={scene} />}
        </AppContent>
        <Hidden smDown>
          {logged && <IntercomChat />}
        </Hidden>
      </div>
      <Hidden smDown>
        <AppFooter
          siteButtonTitle="Site"
          feesButtonTitle="Taxas"
          blogButtonTitle="Blog"
          faqButtonTitle="FAQ"
          centralHelpTitle="Central de Ajuda"
          companyDetail="BITYPREÇO. - CNPJ 29.738.313/0001-23"
          version={VERSION_WEB}
          centralize={!logged}
          scene={scene}
        />
      </Hidden>
    </div>

  );
};

const mapDispatchToProps = dispatch => ({
  warningCripto: (formattedMessage) => {
    dispatch(
      addDialog({
        title: formattedMessage,
        availableChoices: [
          {
            label: 'common.understood',
            actionToTake: '',
            color: 'secondary',
            variant: 'raised',
          },
        ],
      })
    )
  },
});

const enhance = compose(
  pure,
  translate(),
  withStyles(styles, { withTheme: true }),
  lifecycle({
    componentDidMount() {
      const { checkShouldRenderBottomNavigation } = this.props;
      window.addEventListener('resize', checkShouldRenderBottomNavigation);
    },
    componentWillUnmount() {
      const { checkShouldRenderBottomNavigation } = this.props;
      window.removeEventListener('resize', checkShouldRenderBottomNavigation);
    },
  }),
  connect(null, mapDispatchToProps),
);


export default enhance(AppFrame);
