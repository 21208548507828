import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core/styles';
import AppCard from './AppCard';

const styles = theme => ({
  card: {
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      height: `calc(100vh - ${theme.spacing.unit * 19}px)`,
    },
  },
  cardHeader: {
    backgroundColor: theme.palette.background.default,
    [theme.breakpoints.down('sm')]: {

      width: '100%',
      position: 'fixed',
      top: theme.spacing.unit * 4.5,
      zIndex: 2,
    },
  },
  cardContent: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: `calc(100vh - ${theme.spacing.unit * 31}px)`,
      paddingTop: 0,
      marginTop: theme.spacing.unit * 12,
      overflowX: 'auto',
    },
  },
});


const enhance = compose(
  withStyles(styles),
);

const TradeCard = (props) => {
  const {
    children,
    cardClassName,
    cardLabel,
    action,
    noHeader,
    classes,
    alignCenter
  } = props;
  return (
    <AppCard
      alignCenter={alignCenter}
      noHeader={noHeader}
      cardClass={classes.card}
      cardHeaderClass={classes.cardHeader}
      cardContentClass={classes.cardContent}
      cardClassName={cardClassName}
      cardLabel={cardLabel}
      action={action}
    >
      {children}
    </AppCard>
  );
};

TradeCard.defaultProps = {
  action: null,
  cardClassName: '',
};

TradeCard.propTypes = {
  action: PropTypes.node,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  children: PropTypes.node.isRequired,
  cardClassName: PropTypes.string,
  cardLabel: PropTypes.node.isRequired,
};

export default enhance(TradeCard);
