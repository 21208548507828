export const SET_NEWS_READ = 'SET_NEWS_READ'
export const ADD_NEWS_DATA = 'ADD_NEWS_DATA'
export const FETCH_NEWS_DATA = 'FETCH_NEWS_DATA'
export const FETCH_NEWS_SUCCEED = 'FETCH_NEWS_SUCCEED'

export default {
  SET_NEWS_READ,
  ADD_NEWS_DATA,
  FETCH_NEWS_DATA,
  FETCH_NEWS_SUCCEED
}
