import store from '../redux/store';
import {VERSION_WEB} from '../config'
const getFingerPrintValue = async () => {
    if (window.fpPromise){
        const fp = await window.fpPromise;
        const result = await fp.get();
        return result.visitorId;
    }else
        return '';
}

const getDefaultReqHeaders = () => {

    const state = store.getState();
    const defaultHeaders = new Headers();
    defaultHeaders.append('app-version', VERSION_WEB);
    if (state.credentials && state.credentials.deviceId){
        defaultHeaders.append('DEVICE-ID', state.credentials.deviceId);

        return defaultHeaders;
    }

    return defaultHeaders;
}

export default getDefaultReqHeaders;




