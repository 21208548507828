import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import QRCode from 'qrcode.react';
import {
  compose
} from 'recompose';
import appMockURL from '../../../../assets/documents-limits/mockup-app.png'
import verified from '../../../../assets/verified-icon.svg'
import classNames from 'classnames';
import { translate } from 'react-i18next';

const styles = _theme => ({
  root: {
    witdh: '100%',
    backgroundColor: '#101010',
    borderRadius: '25px',
    alignItems: 'center',
    padding: '25px 35px',
    position: 'relative',
    margin: '20px 0',
    overflow: 'hidden',
    '@media screen and (max-width: 800px)': {
      margin: '10px 10px',
    },
    '@media screen and (max-width: 500px)': {
      flexDirection: 'column'
    }
  },
  flex: {
    display: 'flex',
    gap: '15px'
  },
  item: {
    justifyContent: 'space-around',
    maxWidth: '700px',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    gap: '12px',
    zIndex: 10,
    flexDirection: 'column'
  },
  verified: {
    zIndex: 10
  },
  textWhite: {
    color: '#FAFAFA',
    fontWeight: 500,
    fontSize: '30px'
  },
  textGray: {
    color: '#C8C8C8',
    fontSize: '16px',
    '@media screen and (max-width: 800px)': {
      color: '#FAFAFA'
    }
  },
  imageMockup: {
    backgroundImage: `url(${appMockURL})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    position: 'absolute',
    width: '800px',
    height: '600px',
    top: -205,
    zIndex: 1,
    right: '-20%',
    '@media screen and (max-width: 1300px)': {
      backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${appMockURL})`,
      },
    '@media screen and (max-width: 600px)': {
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      right: '0%',
      backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(${appMockURL})`,
    }
  },
});

const BannerKYCOk = (props) => {
  const { classes, t } = props;

  return (
    <div className={classNames(classes.root, classes.flex)}>
        <img className={classes.verified} src={verified} width={90} height={90} alt={t("imageAlts.verifiedLogo")}/>
        <div className={classNames(classes.item, classes.flex)}>
            <div className={classes.textWhite}>Sua conta está mais segura!</div>
            <div className={classes.textGray}>Seus documentos foram validados e você já pode aproveitar <br /> o melhor da Bitypreço e do Bitybank.</div>
        </div>
        <div className={classes.imageMockup}/>
    </div>
  );
};

const enhance = compose(
  translate(),
  withStyles(styles),
);


export default enhance(BannerKYCOk);
