import { put, takeLatest } from 'redux-saga/effects';

import {
  FETCH_BANK_LIST_REQUESTED,
} from './actionTypes';
import { fetchService } from '../../actions';

function* fetchBankListSaga(_action) {
  try {
    yield put(fetchService({
      requestType: 'fetchBankList',
      paramns: {
        privateService: true,
        options: {
          cmd: 'bank_list',
          method: 'POST',
        },
      },
    }));
  } catch (e) {
    console.error('fetchBankListSaga', e);
  }
}

function* watchBankList() {
  yield takeLatest(FETCH_BANK_LIST_REQUESTED, fetchBankListSaga);
}

export default watchBankList;
