import * as R from 'ramda';

const getHelperText = t => (error) => {
  if (R.is(Array, error)) {
    return t(...error);
  }
  return t(error);
};

export default getHelperText
