import React from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Chip from '@material-ui/core/Chip';
import IconButton from '@material-ui/core/IconButton';

/** Componente para visualização e cancelamento de upload de documentos */
const UploadDocumentPreview = (props) => {
  const {
    value, clearField, name, form,
  } = props;
  const docLogo = R.pathOr('', ['url'], value);

  if (docLogo) {
    return (
      <Chip

        avatar={(
          <Avatar
            component="label"
            htmlFor={`input-${form}-${name}`}
            style={{ width: 20, height: 20, marginLeft: 4 }}
            src={docLogo}
          />
        )}
        onDelete={() => clearField('documents', name)}
      />
    );
  }
  return (
    <IconButton component="label" htmlFor={`input-${form}-${name}`}>
      <CloudUploadIcon />
    </IconButton>
  );
};

UploadDocumentPreview.defaultProps = {
  value: { url: '' },
  clearField: a => a,
};

UploadDocumentPreview.propTypes = {
  value: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }),
  /** Função utilizada ao cancelar */
  clearField: PropTypes.func,
  /** Nome identificador referente ao formulário */
  form: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default UploadDocumentPreview;
