const initialState = [];

const notificationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_NOTIFICATIONS': {
      return action.notifications || [];
    }
    case 'SET_NOTIFICATIONS_READ': {
      return state.map(n => ({ ...n, read: true }));
    }
    case 'ADD_NOTIFICATION': {
      return [action.notification, ...state];
    }
    default: return state;
  }
};

export default notificationsReducer;
