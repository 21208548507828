import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'recompose';
import { translate } from 'react-i18next';
import ErrorBitpreco from '../../assets/error_bitpreço.svg';

const styles = theme => ({
  primaryColor: {
    backgroundColor: '#212121',
    color: '#ffffff',
    '&:hover': {
      backgroundColor: '#373737',
    },
  },
  secondaryColor: {
    backgroundColor: '#f9a924',
    '&:hover': {
      backgroundColor: '#c7871d',
    },
  },
  dialog: {
    backgroundColor: '#222222',
  },
  dialogTitle: {
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    padding: '40px 0 15px 0px',
  },
  dialogDivTitle: {
    paddingTop: "40px",
    margin: '0 auto',
    height: '160px',
    width: '80%',
  },
  dialogImage: {
    width: '100%',
    height: 'auto',
  },
  dialogContent: {
    width: '550px',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '80%',
      fontSize: '1rem',
      margin: '0 auto',
    },
  },
  dialogActions: {
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    padding: '0 0 30px 0',

  },
  dialogPaper: {
    [theme.breakpoints.down('xs')]: {
      width: '90%',
    },
  },
});

function ErrorHandler(props) {
  const {
    classes,
    t,
  } = props;
  return (
    <Dialog
      open
      aria-labelledby="form-dialog-title"
      disableBackdropClick
      disableEscapeKeyDown
      className={classes.dialog}
      PaperProps={{ className: classes.dialogPaper }}
    >
      <div className={classes.dialogDivTitle}>
        <img
          src={ErrorBitpreco}
          className={classes.dialogImage}
          alt={t("imageAlts.errorBity")}
        />
      </div>
      <DialogContent className={classes.dialogContent}>
        <DialogContentText style={{ textAlign: "center" }}>
          {t("errorBoundary.content")}
          <br />
          {t("errorBoundary.footer")}
        </DialogContentText>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button
          href="https://suporte.bity.com.br/pt-BR/"
          target="_blank"
          className={classes.primaryColor}
        >
          {t("errorBoundary.support")}
        </Button>
        <Button
          onClick={() => {
            window.location.reload();
          }}
          className={classes.secondaryColor}
          variant="raised"
        >
          {t("common.reloadPage")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const enhance = compose(
  withStyles(styles),
  translate(),
);

export default enhance(ErrorHandler);
