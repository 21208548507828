import service from '../service';

/* eslint-disable camelcase */
const register = async ({ confirmPassword, ...form }) => {
  const options = {
    cmd: 'create_user',
    method: 'POST',
  };

  const content = await service(options)(form);
  return content;
};


export default register;
