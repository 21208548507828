import { Link } from 'react-router-dom';
import { contactSupport } from "../../../utils/contactSupport";

const getFailDialogInfo = failType =>{
    switch (failType){
      case 'TAXID_ALREADY_REGISTERED':
        return {
          // eslint-disable-line camelcase
          title: 'TAXID_ALREADY_USED', // eslint-disable-line camelcase
          renderComponent: 'alreadyRegisteredTAXID',
          availableChoices: [
            {
              label: 'common.cancel',
              actionToTake: '',
              color: 'primary',
              variant: 'raised',
            },
            {
              label: 'CONTACT_SUPPORT',
              actionToTake: 'openIntercomChat',
              color: 'secondary',
              variant: 'raised',
            },
            {
              label: 'common.resetPassword',
              actionToTake: '',
              component: Link,
              to: '/resetPassword',
              color: 'secondary',
              variant: 'raised',
            },
          ],
        };
        case 'DIRECTOR_MUST_HAVE_APPROVED_ACCOUNT':
          return {
          // eslint-disable-line camelcase
          title: 'DIRECTOR_MUST_HAVE_APPROVED_ACCOUNT',
          centerTitle: true,
           
          renderComponent: 'directorMustHaveApprovedAccount',
          availableChoices: [
            {
              label: 'common.cancel',
              actionToTake: '',
              color: 'primary',
              variant: 'raised',
            },
            {
              label: 'common.validate_your_account',
              component: 'a',
              href: 'https://suporte.bity.com.br/pt-BR/articles/6967850-como-fazer-verificacao-de-conta-na-plataforma-bitypreco',
              target: '_blank',
              color: 'secondary',
              variant: 'raised',
            },
            {
              label: 'common.clickHere',
              actionToTake: 'reloadPage',
              color: 'secondary',
              variant: 'raised',
            },
          ],
          actionsToTake: {
            reloadPage: () => window.location.reload(false)
          }
        };
      default:
        return {
          // eslint-disable-line camelcase
          title: failType, // eslint-disable-line camelcase
          availableChoices: [
            {
              label: 'common.close',
              actionToTake: '',
              color: 'secondary',
              variant: 'raised',
            },
          ],
        }
    }
}

export default getFailDialogInfo;
