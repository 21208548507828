import {
  FETCH_OPEN_ORDERS,
  UPDATE_OPEN_ORDERS,
} from './actionTypes';

const fetchOpenOrders = () => ({
  type: FETCH_OPEN_ORDERS,
});

const updateOpenOrders = openOrders => ({
  type: UPDATE_OPEN_ORDERS,
  openOrders,
});

export default {
  fetchOpenOrders,
  updateOpenOrders,
};

export {
  fetchOpenOrders,
  updateOpenOrders,
};
