export class ConvertionQuoteRequestData {
  constructor({ from, to, amount, amountType }) {
    this.from = from;
    this.to = to;
    this.amount = amount;
    this.amountType = amountType;

  }

  toJson() {
    return {
        amount: this.amount,
        from: this.from,
        to: this.to,
        amountType: this.amountType
    };
  }
}