import React, { Fragment } from 'react';
import PropTypes from 'prop-types';


import { withStyles } from '@material-ui/core/styles';
import { compose, withHandlers, withState } from 'recompose';
import { withRouter } from 'react-router-dom';
import ExecutedOrders from './ExecutedOrders';
import SellButton from '../../components/Transactions/SellButton';
import BuyButton from '../../components/Transactions/BuyButton';
import StoplossButton from '../../components/Transactions/StopLossButton';
import OrderBook from './OrderBook/index';
import SwipeableViewsMobile from '../../components/SwipeableViewsMobile/SwipeableViewsMobile';
import { Currency, getCurrencyBySymbol } from '../../models/Currency';
import {connect} from "react-redux";
import OperationsDisabledCard from '../../components/Cards/OperationsDisabledCard';


const styles = theme => ({
  slideContainer: {
    height: `calc(100vh - ${theme.spacing.unit * 19}px)`,
    WebkitOverflowScrolling: 'touch', // iOS momentum scrolling
  },
  stepperRoot: {
    width: '100%',
    justifyContent: 'center',
    position: 'fixed',
    flexGrow: 1,
    bottom: theme.spacing.unit * 7,
  },
  stepperDot: {
    width: 100,
    backgroundColor: theme.palette.primary.main,
    borderRadius: '20%',
  },
  stepperDotActive: {
    width: 100,
    backgroundColor: theme.palette.secondary.main,
    borderRadius: '20%',
  },
  toolbar: {
    backgroundColor: theme.palette.primary.main,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    position: 'fixed',
    zIndex: 10,
    top: '160px',
    width: '100%',

    [theme.breakpoints.down('xs')]: {
      top: '144px',
    },
    
  },
  iconArrow: {
    display: 'flex',
    alignContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgb(255, 255, 255)',
    width: '5%',
    height: '75%',
    margin: 0,
  },
  iconPositionRight: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 'auto',
    marginBottom: 'auto',
    width: 35,
    height: 35,
    backgroundColor: '#bfbfbf',
    opacity: 0.65,
    boxShadow: '0px 5px 24px -6px rgba(0,0,0,0.69)',
    color: 'white',
    borderRadius: 100,
    margin: 0,
    marginLeft: -30,
    zIndex: 100,
  },
  iconPositionLeft: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 'auto',
    marginBottom: 'auto',
    width: 35,
    height: 35,
    backgroundColor: '#bfbfbf',
    opacity: 0.65,
    boxShadow: '0px 5px 24px -6px rgba(0,0,0,0.69)',
    color: 'white',
    borderRadius: 100,
    margin: 0,
    marginRight: -30,
    zIndex: 100,
  },
  rotateIcon: {
    transform: 'rotate(180deg)',
  },
  icon: {
    marginLeft: '30%',
  },
  operationsDisabledContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    padding: "0 8px"
  },
  operationsDisabledCard: {
    width: '100%',
    marginTop: '100px',
    marginLeft: 'auto',
    marginRight: 'auto',

    [theme.breakpoints.down('xs')]: {
      marginTop: '60px',
    },
  }
});

const mapStateToProps = state => ({
  market: state.market.selectedMarket,
  currencies: state.currencies.currencies,
});

const enhance = compose(
  // pure,
  withRouter,
  withStyles(styles, { withTheme: true }),
  connect(mapStateToProps),
  withState('isOrderBookSellShowAllSelected', 'setOrderBookSellShowAllSelected', true),
  withState('isOrderBookBuyShowAllSelected', 'setOrderBookBuyShowAllSelected', true),
  withHandlers({
    setActiveTabIndexSwipeable: ( ) => (setActiveTabIndex, val) => {
      setActiveTabIndex(val);
    },
  }),
);

const MobileWrapper = props => {
  const {
    classes,
    theme,
    currencies,
    market,
    isOrderBookSellShowAllSelected,
    setOrderBookSellShowAllSelected,
    isOrderBookBuyShowAllSelected,
    setOrderBookBuyShowAllSelected,
  } = props;

  const marketConfig = getCurrencyBySymbol(currencies, market.split('-')[0]) ||  getCurrencyBySymbol(currencies, "BTC");
  const currency = new Currency(marketConfig);
  const allowDepositAndWithdrawal = currency.hasDeposit && currency.hasWithdrawal;

  return (
    <Fragment>
      <div className={classes.toolbar}>
        <BuyButton symbol={currency.symbol} />
        <SellButton symbol={currency.symbol} />
        <StoplossButton symbol={currency.symbol} />
      </div>
      {!allowDepositAndWithdrawal && (
        <div className={classes.operationsDisabledContainer}>
          <OperationsDisabledCard currencyName={currency.symbol} customClass={classes.operationsDisabledCard} />
          </div>)}
      <div
        style={{
          width: "100%",
          height: "100%",
          position: "fixed",
          marginTop: !allowDepositAndWithdrawal ? "0" : "40px",

          [theme.breakpoints.down("xs")]: {
            marginTop: "0",
          },
        }}
      >
        <SwipeableViewsMobile steps={3} direction={"rtl"} initialTabIndex={0}>
          <OrderBook
            ordersType="BUY"
            isShowAllSelected={isOrderBookBuyShowAllSelected}
            setIsShowAllSelected={setOrderBookBuyShowAllSelected}
          />
          <OrderBook
            ordersType="SELL"
            isShowAllSelected={isOrderBookSellShowAllSelected}
            setIsShowAllSelected={setOrderBookSellShowAllSelected}
          />
          <ExecutedOrders />
        </SwipeableViewsMobile>
      </div>
    </Fragment>
  );
};

MobileWrapper.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default enhance(MobileWrapper);
