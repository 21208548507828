import service from '../service';

const fetchOrderBook = async (market) => {
  const options = {
    cmd: 'detailed_orderbook',
    method: 'POST',
  };
  const content = await service(options)({ market });

  return content;
};

export default fetchOrderBook;
