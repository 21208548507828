import { compose, withProps, lifecycle } from 'recompose';
import { connect } from 'react-redux';

import { translate } from 'react-i18next';
import { reduxForm } from 'redux-form';
import SimpleSchema from 'simpl-schema';
import { Link } from 'react-router-dom';

import validateDoc from '../../../components/Forms/asyncValidate';
import { valueToMd5 } from '../../../utils/valueToMd5';
import { RegisterPasswordSchema, TokenSchema } from '../../../utils/Schemas';
import { addDialog } from '../../../redux/layout/actions';

import RecoverPassword from './RecoverPassword';
import recoverPasswordService from '../../../services/cbtcService/recoverPassword';
import { addValidationTokenDialog } from '../../../redux/credentials/actions';
import { contactSupport } from '../../../utils/contactSupport';
import { withRouter } from 'react-router-dom';

const RecoverPasswordSchema = new SimpleSchema({
  new_pass: RegisterPasswordSchema,
  repeatNewPass : RegisterPasswordSchema,
  emailToken : TokenSchema
});
const validate = values => validateDoc({ values, schema: RecoverPasswordSchema });


const mapDispatchToProps = dispatch => ({
  addValidationTokenDialog: data => dispatch(addValidationTokenDialog(data)),
  addInitDialog: () => dispatch(addDialog({
    title: 'EMAIL_SENT', // eslint-disable-line camelcase
    availableChoices: [
      {
        label: 'common.close',
        actionToTake: '',
        color: 'secondary',
        variant: 'raised',
      },
    ],
  })),
  onSubmitFail: (err, d, { message_cod, remaining }) => { // eslint-disable-line camelcase
    if (message_cod === 'AUTHENTICATION_ERROR') { // eslint-disable-line camelcase
      return dispatch(addDialog({
        title: 'common.attention',
        centerTitle: true,
        renderComponent: 'onValidationTokenFail',
        disableBackdropClick: true,
        componentProps: {
          remaining
        },
        availableChoices: [
          {
            label: 'common.understood',
            actionToTake: '',
            color: 'secondary',
            variant: 'raised'
          }
        ]
      }));
    }
    else if (message_cod === 'TOO_MANY_AUTHENTICATION_ERRORS') { // eslint-disable-line camelcase
      return dispatch(addDialog({
        title: message_cod,
        centerTitle: true,
        disableBackdropClick: true,
        availableChoices: [
          {
            label: 'CONTACT_SUPPORT',
            actionToTake: 'openIntercomChat',
            color: 'primary',
            variant: 'raised'
          },
          {
            label: 'common.understood',
            actionToTake: '',
            component: Link,
            to: '/resetPassword',
            color: 'secondary',
            variant: 'raised',
          }
        ],
        actionsToTake: {
          openIntercomChat: () => contactSupport(),
        },
      }));
    }
    return dispatch(addDialog({
      title: message_cod, // eslint-disable-line camelcase
      availableChoices: [
        {
          label: 'common.close',
          actionToTake: '',
          color: 'secondary',
          variant: 'raised',
        },
      ],
    }));
  },
  onSubmitSuccess: ({ message_cod }) => dispatch(addDialog({ // eslint-disable-line camelcase
    title: message_cod, // eslint-disable-line camelcase
    availableChoices: [
      {
        label: 'common.close',
        actionToTake: '',
        component: Link,
        to: '/login',
        color: 'secondary',
        variant: 'raised',
      },
    ],
  })),
});
const mapStateToProps = ({ credentials: { logged } }) => ({
  logged,
});
const enhance = compose(
  translate(),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  lifecycle({
    componentDidMount() {
      const { location, history, addInitDialog } = this.props;
      const stateHasEmail = location.state && location.state.email;
      const stateHasTaxId = location.state && location.state.taxId;
      if (!stateHasEmail || !stateHasTaxId)
        history.push('/login');
      else
        addInitDialog();
    }
  }),
  withProps(({ location }) => {
    const email = location.state ? location.state.email : '';
    const taxId = location.state ? location.state.taxId : '';
    return {
      onSubmit: ({ new_pass, emailToken }) => {
        const newPassMd5 = valueToMd5(new_pass);
        const requestForm = {
          token: emailToken,
          new_pass,
          email,
          signature: valueToMd5(`${emailToken}|${taxId}|${newPassMd5}`)
        };
        return recoverPasswordService(requestForm);
      },
    };
  }),
  reduxForm({
    form: 'recoverPasswordForm',
    validate,
    // onSubmit: recoverPasswordService,
  }),
);


export default enhance(RecoverPassword);
