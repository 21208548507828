import withExecutedOrders from './withExecutedOrders'
import ExecutedOrders from './ExecutedOrders'
import React from 'react'

const WrappedExecutedOrders = withExecutedOrders(ExecutedOrders)

const ExecutedOrdersHistory = (props) => {
    const { onlyMineOrders } = props;
    return <WrappedExecutedOrders onlyMineOrders={ onlyMineOrders }/>
}
export default ExecutedOrdersHistory
