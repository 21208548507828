const UPLOAD_DOCUMENT_REQUESTED = 'UPLOAD_DOCUMENT_REQUESTED';
const UPLOAD_DOCUMENT_SUCCEED = 'UPLOAD_DOCUMENT_SUCCEED';
const UPLOAD_DOCUMENT_FAILED = 'UPLOAD_DOCUMENT_FAILED';
const UPLOAD_DOCUMENT_RESOLVED = 'UPLOAD_DOCUMENT_RESOLVED';

export {
  UPLOAD_DOCUMENT_REQUESTED,
  UPLOAD_DOCUMENT_SUCCEED,
  UPLOAD_DOCUMENT_FAILED,
  UPLOAD_DOCUMENT_RESOLVED,
};
