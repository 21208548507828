import { compose, setDisplayName } from 'recompose'
import { translate } from 'react-i18next'

import OrderBookFactory from './hoc/OrderBookFactory'
import withStateAndActions from './hoc/withStateAndActions'

import OrderBookFrame from './components/OrderBookFrame'
import OrderBookHeader from './components/OrderBookHeader'
import OrderBookTable from './components/OrderBookTable'
import OrderBookTableRow from './components/OrderBookTableRow'

const OrderBook = OrderBookFactory(
  OrderBookFrame,
  OrderBookHeader,
  OrderBookTable,
  OrderBookTableRow
)

const enhancer = compose(
  translate(),
  setDisplayName('OrderBook'),
  withStateAndActions
)

export default enhancer(OrderBook)
