import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import ActivityIndicator from "../ActivityIndicator";
import { convertTimestampToMMSS } from "../../utils/strings";

const setCountDownFormatted = (timestamp, setCountdown) => {
  setCountdown(convertTimestampToMMSS(Math.max(0, timestamp)));
};

const CountDownSubmitButton = (props) => {
  const {
    submitText,
    disabled,
    type = "submit",
    style,
    className,
    classes,
    isSubmitting,
    timestamp,
    onTimeout,
    ...rest
  } = props;
  const [countdown, setCountdown] = React.useState("");

  useEffect(() => {
    if (timestamp) {
      setCountDownFormatted(timestamp - new Date().getTime(), setCountdown);
    }

    const interval = setInterval(() => {
      if (timestamp) {
        const now = new Date().getTime();
        const distance = timestamp - now;
        if (distance <= 0) {
          if (onTimeout) {
            onTimeout();
          }
          clearInterval(interval);
        }
        setCountDownFormatted(distance, setCountdown);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [timestamp]);

  return (
    <Button
      type={type}
      variant="raised"
      color="primary"
      style={style}
      classes={classes}
      disabled={disabled}
      className={className}
      {...rest}
    >
      <span
        style={{
          display: "flex",
          alignItems: "center",
          gap: "10px",
        }}
      >
        {submitText}{" "}
        {timestamp && countdown.length > 0 && <span>({countdown})</span>}
        {isSubmitting && <ActivityIndicator disableWrapper={true} size={16} />}
      </span>
    </Button>
  );
};

CountDownSubmitButton.defaultProps = {
  disabled: false,
};

CountDownSubmitButton.propTypes = {
  submitText: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

export default CountDownSubmitButton;
