import { FETCH_LIMITS_REQUESTED } from './actionTypes';

const fetchLimitsRequested = () => ({
  type: FETCH_LIMITS_REQUESTED,
});

export {
  fetchLimitsRequested,
};

export default {
  fetchLimitsRequested,
};
