import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  card: {
    width: '100%',
  },
  cardHeader: {
    backgroundColor: theme.palette.background.default,
    [theme.breakpoints.down('sm')]: {

      width: '100%',
      position: 'fixed',
      // top: theme.spacing.unit * 4.5,
      zIndex: 2,
    },
  },
  cardAction: {
    alignSelf: 'flex-end',
    // alignSelf: 'center',
  },
  cardActionCenter: {
    alignSelf: 'center',
    // alignSelf: 'center',
  },
  cardContent: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      // height: `calc(100vh - ${theme.spacing.unit * 31}px)`,
      padding: theme.spacing.unit,
      // marginTop: theme.spacing.unit * 5,
      overflowX: 'auto',
    },
  },
});


const enhance = compose(
  withStyles(styles),
);

const AppCard = (props) => {
  const {
    children,
    cardClassName,
    cardLabel,
    cardClass,
    cardHeaderClass,
    cardContentClass,
    alignCenter,
    action,
    noHeader = false,
    classes,
  } = props;
  return (
    <Card {...props} className={classNames(classes.card, cardClass)}>
      {!noHeader && <CardHeader
        classes={{ root: classNames(classes.cardHeader, cardHeaderClass), action: (alignCenter ? classes.cardActionCenter : classes.cardAction) }}
        title={
          <Typography
            variant="title"
            className={cardClassName}
          >
            {cardLabel}
          </Typography>
        }
        action={action}
      />}
      <CardContent className={classNames(classes.cardContent, cardContentClass)}>
        {children}
      </CardContent>
    </Card>
  );
};

AppCard.defaultProps = {
  cardClassName: '',
  cardClass: '',
  cardHeaderClass: '',
  cardContentClass: '',
  action: null,
};

AppCard.propTypes = {
  action: PropTypes.node,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  children: PropTypes.node.isRequired,
  cardClassName: PropTypes.string,
  cardLabel: PropTypes.node.isRequired,
  cardClass: PropTypes.string,
  cardHeaderClass: PropTypes.string,
  cardContentClass: PropTypes.string,
};

export default enhance(AppCard);
