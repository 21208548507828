import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';

import { translate } from 'react-i18next';
import { Link } from 'react-router-dom';

import Typography from '@material-ui/core/Typography';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const styles = theme => ({
  root: {
    borderRadius: 0,
  },
  textPrimary: {
    color: theme.palette.primary.contrastText,
  },
});

const enhance = compose(
  // pure,
  translate(),
  withStyles(styles),
);

const TransactionButton = props => {
  const {
    classes, to, children, className, textPrimary,
  } = props;

  return (
    <Button
      // color="primary"
      variant="raised"
      classes={{
        root: classes.root,
        raised: textPrimary,
      }}
      className={className}
      fullWidth
      component={Link}
      to={to}
    >
      <Typography className={classes.textPrimary} variant="button" >
        {children}

      </Typography>
    </Button>
  );
};

TransactionButton.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default enhance(TransactionButton);
