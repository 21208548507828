import irImage from '../../../../assets/ir-background.png'
import irImageDesktop from '../../../../assets/ir-background-desktop.png'
import irImageMobile from '../../../../assets/ir-background-mobile.png'

const styles = theme => ({
    column:{
      display: 'flex',
      flexDirection: 'column',
    },
    row:{
      display: 'flex',
      alignItems: 'center',
    },
    container:{
      padding: '25px 20px 0 25px',
      gap: '15px',
      '@media screen and (max-width: 1100px)': {
       flexDirection: 'column',
      },
      '@media screen and (max-width: 800px)': {
        padding: '45px 20px 0 25px',
       },
      '@media screen and (max-width: 500px)': {
        padding: '0px',
      },
      '@media screen and (max-width: 321px)': {
        gap: '0px',
      },
    },
    card:{
      borderRadius: '10px',
      backgroundColor: '#f1f1f1',
      minWidth: '330px',
      width: '100%',
      padding: '0',
      '@media screen and (max-width: 1100px)': {
        width: '90%',
      },
      '@media screen and (max-width: 500px)': {
        margin: '20px 20px 0 20px',
      },
      '@media screen and (max-width: 321px)': {
        minWidth: '300px',
      },
    },
    bannerBackground: {
      borderRadius: '10px',
      width: '100%',
      height: '190px',
    },
    IRBackground: {
      justifyContent: 'center',
      backgroundImage: `url(${irImage})`,
      padding: '20px 30px',
      boxShadow: 'inset 661px 0 600px -601px #000',
      backgroundSize: '110%',
      backgroundPositionY:'43%',
      backgroundPositionX:'120px',
  
      [theme.breakpoints.down('md')]: {
        backgroundPositionY:'30%',
        backgroundPositionX:'100px',
      },
  
      [theme.breakpoints.down('sm')]: {
        backgroundPositionY:'45%',
        padding: '20px 0px 20px 20px',
      },

      '@media screen and (max-width: 1440px)': {
        backgroundImage: `url(${irImageDesktop})`,
      },
  
      '@media screen and (max-width: 822px)': {
        backgroundSize: 'cover',
        backgroundPositionY: '25%',
        backgroundPositionX: 'center',
        backgroundRepeat: 'no-repeat',
        boxShadow: 'inset 0 0 0 200px rgba(0, 0, 0, 0.4)',
      },
  
      [theme.breakpoints.down('xs')]: {
        boxShadow: 'inset 0 0 0 200px rgba(0, 0, 0, 0.5)',
        backgroundImage: `url(${irImageMobile})`,
      }
    },
    IRTitle:{
      width: '90%',
      color: 'white',
      fontWeight: 600, 
      fontSize: 23,
      lineHeight: '30px',
  
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
    },
  
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
      lineHeight: 1.3
    },
    },
    biscointIRContainer: {
      gap: '5px',
      marginTop: '20px',
      padding: '0 25px',
      display: 'flex',
      alignItems: 'center',
  
      '& p': {
        fontSize: '16px',
        fontWeight: '500',
      },
  
      '@media screen and (max-width: 1100px)': {
        flexDirection:'row',
  
        width:'90%',
        padding: '0 0 0 calc(25px + 5%)'
      },
      '@media screen and (max-width: 651px)': {
        flexDirection: 'column',
        marginBottom: '20px',
     
      },
  
      [theme.breakpoints.down('xs')]: {

        padding: '0 20px',
        width: '100%',
        gap: '0px',
        
        '& p': {
          fontSize: '14px',
          textAlign: 'center',
        },
      },
  
    },
    linkButton: {
      color: theme.palette.secondary.main,
      fontSize: '16px',
      fontWeight: '500',
      textDecoration: 'underline',
      cursor: 'pointer',
      textAlign: 'left',
    
      [theme.breakpoints.down('xs')]: {
        fontSize: '14px',
        textAlign: 'center',
      },
    },
    field: {
      display: 'flex',
      alignItems: 'center',
      width:'100%',
      borderBottom: '1px solid #7A7A7A',
      
      '@media screen and (max-width: 1100px)': {
        justifyContent: 'center',
        flexDirection:'column',
        alignItems:'center',
        width:'80%',
        margin: 'auto',
      },
    },
    knowMore:{
      backgroundColor: 'white',
      width: '115px',
      marginTop: '10px',
      padding: '5px 0',
      fontSize: '9pt',
      minHeight: '0px'
    },
    submitButton: {
      '@media screen and (max-width: 500px)': {
        width: '100%',
      },
    },
    downloadIRButton: {
      padding: '18px 0px',
      maxWidth: '600px',
      margin: 'auto',
      gap: '60px',
  
      '@media screen and (max-width: 500px)': {
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        margin: '0 20px',
      },
    },
    root: {
      backgroundColor: theme.palette.secondary.main,
      minHeight: theme.spacing.unit * 4,
      color: theme.palette.common.white,
  
      '@media screen and (max-width: 500px)': {
        minHeight: '39px',
        marginTop: '-18px'
      },
    },
    flex: {
      flexGrow: 1,
    },
  });

  export default styles;