import React, { Fragment } from 'react';
import classnames from 'classnames'
import PropTypes from 'prop-types';
import { compose, pure } from 'recompose';


import { withStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';

const styles = theme => {
  return {
    container: {
      color: theme.palette.grey[400],
      paddingLeft: theme.spacing.unit * 4,
      paddingRight: 0,
      height: theme.spacing.unit * 12
    },
    containerButton: {
      '&:hover': {
        backgroundColor: theme.palette.grey[900]
      }
    },
    activeContainerButton: {
      backgroundColor: theme.palette.grey[700]
    }
  };
};

const DrawerListItem = ({
  children, classes, onClick, button, active
}) => {

  return (
    <Fragment>
      <ListItem
        active
        button={button}
        onClick={onClick}
        className={classnames(
          classes.container,
          button ? classes.containerButton : null,
          active ? classes.activeContainerButton : null
        )}
      >
        {children}
      </ListItem >
    </Fragment>

  );
};


DrawerListItem.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

const enhance = compose(
  // withRouter,
  pure,
  withStyles(styles),
);

export default enhance(DrawerListItem);
