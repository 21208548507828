export const styles = (theme) => ({
    expressTrade: {
      margin: "19.40px 9px",
    },
    card: {
      maxWidth: 600,
      marginTop: theme.spacing.unit * 2,
      margin: "auto",
      paddingTop: theme.spacing.unit * 4,
      paddingRight: theme.spacing.unit * 8,
      paddingBottom: theme.spacing.unit * 4,
      paddingLeft: theme.spacing.unit * 8,
    },
    cardFade: {
      maxWidth: 600,
      marginTop: theme.spacing.unit * 2,
      margin: "auto",
      paddingTop: theme.spacing.unit * 4,
      paddingRight: theme.spacing.unit * 8,
      paddingBottom: theme.spacing.unit * 4,
      paddingLeft: theme.spacing.unit * 8,
      "&:hover": {
        background: "rgba(0,0,0,0.2)",
        zIndex: 1000,
      },
    },
    cardPlacement: {
      zIndex: 1050,
      width: 220,
      height: 130,
      marginTop: 120,
      left: "53%",
    },
    transactionContainer: {
      margin: "30px 0",
    },
    colorWhite: {
      color: "#FFFFFF",
      textAlign: "center",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "normal",
    },
    executedOrders: {
      marginTop: "30px",
    },
  });
  