import {
  ExpansionPanel,
  ExpansionPanelSummary,
  Typography,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import React, { useState } from "react";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { translate } from "react-i18next";
import PropTypes from "prop-types";
import DangerouslySetInnerHTML from "../../../../components/DangerouslySetInnerHTML";
import { formatPercentage } from "../../../../utils/NumberFormat/percentage";

const styles = (theme) => ({
  headerTitle: {
    color: "#656F79",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    textAlign: "left",
  },
  headerValueLabel: {
    color: "#101010",
    textAlign: "right",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
  },
  card: {
    borderRadius: "15px !important",
    background: "#F3F3F3",
    boxShadow: "none",
    "&:before": {
      display: "none",
    },
  },
  headerRow: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  rowContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "10px",
  },
  iconWrapper: {
    display: "flex",
    backgroundColor: "#F9F9F9",
    borderRadius: "15px",
    padding: "8px",
    alignItems: "center",
    justifyContent: "center",
  },
  icon: {
    color: "#F7B733",
    fontSize: "20px",
  },
  collapseContentCard: {
    textAlign: "left",
    borderRadius: "8px",
    background: "rgba(200, 206, 212, 0.5)",
    padding: "12px 20px",
    color: "#656F79",
    fontSize: "11px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    letterSpacing: "-0.22px",
  },
  secondContentCollumn: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
  },
  collapseColumn: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    padding: "0 24px 20px",
  },
  nextLevelValue: {
    color: "#101010",
    textAlign: "right",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "normal",
  },
});

const MissionInfo = (props) => {
  const {
    classes,
    cardIcon,
    collapseExtraContent,
    collapseFirstCardContent,
    collapseSecondCardContent,
    nextLevelValue,
    headerTitle,
    headerValueLabel
  } = props;
  const [expanded, setExpanded] = useState(false);

  const CollapseExtraContent = collapseExtraContent ? collapseExtraContent : null;

  const CardIcon = cardIcon;

  return (
    <ExpansionPanel
      expanded={expanded}
      onChange={() => {
        setExpanded(!expanded);
      }}
      className={classes.card}
    >
      <ExpansionPanelSummary expandIcon={<ExpandMore />}>
        <div className={classes.headerRow}>
          <div className={classes.rowContainer}>
            <div className={classes.iconWrapper}>
              <CardIcon className={classes.icon} />
            </div>
            <DangerouslySetInnerHTML
              title={headerTitle}
              className={classes.headerTitle}
            />
          </div>
          {nextLevelValue && (
            <div className={classes.secondContentCollumn}>
              <Typography className={classes.nextLevelValue}>
                {formatPercentage(nextLevelValue)}
              </Typography>
              {headerValueLabel && (
                <DangerouslySetInnerHTML title={headerValueLabel} className={classes.headerValueLabel} />
              )}
            </div>
          )}
        </div>
      </ExpansionPanelSummary>
      <div className={classes.collapseColumn}>
        {collapseFirstCardContent && (
          <div className={classes.collapseContentCard}>
            <DangerouslySetInnerHTML title={collapseFirstCardContent} />
          </div>
        )}

        {collapseSecondCardContent && (
          <div className={classes.collapseContentCard}>
            <DangerouslySetInnerHTML title={collapseSecondCardContent} />
          </div>
        )}

        {collapseExtraContent !== undefined && <CollapseExtraContent />}
      </div>
    </ExpansionPanel>
  );
};

const enhance = compose(withStyles(styles), translate());

MissionInfo.propTypes = {
  cardIcon: PropTypes.element.isRequired,
  collapseExtraContent: PropTypes.element,
  collapseFirstCardContent: PropTypes.string,
  collapseSecondCardContent: PropTypes.string,
  nextLevelValue: PropTypes.any,
  headerTitle: PropTypes.string,
  headerValueLabel: PropTypes.string,
};

export default enhance(MissionInfo);
