
import {
  UPDATE_ORDERS,
  FETCH_ORDERS_REQUESTED,
  FETCH_EXECUTED_ORDERS_REQUESTED,
  OPTIMISTIC_ADD_ORDER,
  OPTIMISTIC_REMOVE_ORDER,
  OPTIMISTIC_CANCEL_ORDER,
  ENABLE_SYNC_ORDERS,
  DISABLE_SYNC_ORDERS,
  GET_QUOTE,
  EXECUTE_QUOTE
} from './actionTypes';



const fetchOrdersRequested = () => ({
  type: FETCH_ORDERS_REQUESTED,
});

const getQuote = (param, inputValue, orderType, market) => {
  return {
    type: GET_QUOTE,
    param, 
    orderType, 
    market,
    inputValue
  }
};

const executeQuote = (quoteId) => {
  return {
    type: EXECUTE_QUOTE,
    quoteId, 
  }
};

const fetchExecutedOrdersRequested = () => ({
  type: FETCH_EXECUTED_ORDERS_REQUESTED,
})

const enableSyncOrders = () => ({
  type: ENABLE_SYNC_ORDERS,
  sync: true,
});

const disableSyncOrders = () => ({
  type: DISABLE_SYNC_ORDERS,
  sync: false,
});


const updateOrders = (action) => ({
  type: UPDATE_ORDERS,
  ...action,
});

const optimisticAddOrder = (action) => ({
  type: OPTIMISTIC_ADD_ORDER,
  ...action,
});

const optimisticRemoveOrder = (action) => ({
  type: OPTIMISTIC_REMOVE_ORDER,
  ...action,
});

const optimisticCancelOrder = (action) => ({
  type: OPTIMISTIC_CANCEL_ORDER,
  ...action,
});

export default {
  updateOrders,
  fetchOrdersRequested,
  fetchExecutedOrdersRequested,
  optimisticAddOrder,
  optimisticRemoveOrder,
  optimisticCancelOrder,
  getQuote,
  executeQuote,
  enableSyncOrders,
  disableSyncOrders,
};


export {
  getQuote,
  executeQuote,
  updateOrders,
  fetchOrdersRequested,
  fetchExecutedOrdersRequested,
  optimisticAddOrder,
  optimisticRemoveOrder,
  optimisticCancelOrder,

  enableSyncOrders,
  disableSyncOrders,
};
