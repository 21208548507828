import { UPDATE_TRANSACTION_FORM_OFFSET_TOP, UPDATE_FORM_DATE } from './actionTypes'

export const updateTransactionsTabsOffsetTop = offsetTop => ({
  type: UPDATE_TRANSACTION_FORM_OFFSET_TOP,
  offsetTop
})

export const updateFormData = (formData) => {
  return {
    type: UPDATE_FORM_DATE,
    payload: formData,
  };
};

export default { updateTransactionsTabsOffsetTop, updateFormData }
