import React from "react";
import { withStyles, Grid, Typography } from "@material-ui/core";
import { styles } from "./styles";
import { compose } from "recompose";
import classNames from "classnames";

const InformationRow = (props) => {
  const {
    classes,
    customClasses,
    label,
    componentLabel,
    value,
    componentValue,
  } = props;
  return (
    <Grid
      container
      className={classNames(classes.boxContainer, customClasses?.boxContainer)}
    >
      {label && <Typography className={"label"}>{label}</Typography>}
      {componentLabel && componentLabel}
      {value && <Typography className={"value"}>{value}</Typography>}
      {componentValue && componentValue}
    </Grid>
  );
};

const enhance = compose(withStyles(styles));

export default enhance(InformationRow);
