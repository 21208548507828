import React from 'react';
import { compose, pure } from 'recompose';
import { withStyles } from '@material-ui/core/styles';
import TrendingIcon from '@material-ui/icons/PlayArrow';

const styles = theme => ({
  icon: { ...theme.typography.subheading, color: 'inherit' },
  iconUp: { ...theme.typography.subheading, transform: 'rotate(-90deg)', color: theme.palette.green.main },
  iconDown: { ...theme.typography.subheading, transform: 'rotate(90deg)', color: theme.palette.red.main },
});

const Trending = props => {
  const {
    variation,
    classes,
  } = props;

  const className = variation === 0
    ? classes.icon
    : variation < 0
      ? classes.iconDown
      : classes.iconUp

  return (
    <TrendingIcon className={className} />
  );
};


const enhance = compose(
  pure,
  withStyles(styles),
);

export default enhance(Trending);
