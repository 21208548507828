import React from 'react'
import { translate } from 'react-i18next'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import { CURRENCY_ICONS_URL } from '../config'

const styles = theme => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    padding: '4px 2px',
    borderRadius: 16
  },
  containerCompactVariant :{
    display: 'flex',
    alignItems: 'center',
    padding: '1px 1px',
    borderRadius: 16,
  },
  containerFixedVariant :{
    display: 'flex',
    alignItems: 'center',
    padding: '1px 1px',
    borderRadius: 16,
    width: 130
  },
  iconWrapper: {
    minWidth: 32,
    maxWidth: 32,
    height: 32,
    borderRadius: 50,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 7
  },
  icon: {
    maxWidth: 32,
    objectFit: 'contain',
  },
  label: {
    paddingLeft: 32,
    paddingRight: 32,
    fontWeight: 500
  },
  labelCompactVariant: {
    paddingLeft: 8,
    paddingRight: 20,
    fontWeight: 500
  }
})

const classesVariant = classes =>  { return {
  default: {label: classes.label, container: classes.container},
  compact: {label: classes.labelCompactVariant, container: classes.containerCompactVariant},
  fixed: {label: classes.labelCompactVariant, container: classes.containerFixedVariant}
}};

const getVariantClass = (variant, classes) => {
  switch (variant) {
    case 'default': return classesVariant(classes).default;
    case 'compact': return classesVariant(classes).compact;
    case 'fixed-width': return classesVariant(classes).fixed;
    default: return classesVariant(classes).default;
  }
};

const TokenChip = ({ t, classes, currency, variant }) => {
  const currencyUrl = `${CURRENCY_ICONS_URL}-${currency.symbol.toLowerCase()}.svg`;
  const backgroundColor = currency.color;
  return (
    <Card className={getVariantClass(variant, classes).container}>
      <div
        className={classes.iconWrapper}
        style={{
          backgroundColor: backgroundColor,
        }}
      >
        <img src={currencyUrl} className={classes.icon} alt={t("imageAlts.coinLogo", {coin: currency.symbol})}/>
      </div>
      <Typography className={getVariantClass(variant, classes).label}>
        {currency.name}
      </Typography>
    </Card>
  );
}

export default translate()(withStyles(styles)(TokenChip))
