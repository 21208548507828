import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';

import { pure, compose, lifecycle } from 'recompose';
import { connect } from 'react-redux';
import { syncPublicData } from '../redux/appData/actions';

import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import AppRoute from './AppRoute';

import Login from '../pages/User/Login';
import Register from '../pages/User/Register';
import Validate from '../pages/User/Validate';
import ResetPassword from '../pages/User/ResetPassword';
import RecoverPassword from '../pages/User/RecoverPassword';
import Trade from '../pages/Trade';
import Profile from '../pages/Profile';
import Transaction from '../pages/Transaction';
import Extract from '../pages/Extract';
import Card from '../pages/Card';
import BankTransfers from '../pages/BankTransfers';
import ChatPage from '../pages/ChatPage';
import TradingViewChartPage from '../pages/TradingViewChartPage';

import ActivityIndicator from '../components/ActivityIndicator';
import SendReceiveCoins from '../pages/SendReceiveCoins';
import ExpressTrade from '../pages/ExpressTrade';
import MGM from '../pages/MGM';
import useTicker from '../hooks/useTicker';
import {generateDeviceId, updateTicker} from '../redux/actions';


// const AppRoute = props => {
//   return <PrivateRoute {...props}/>
// }

const AuthExample = (props) => {
  const { loadingStore, enableSyncTicker, disableSyncTicker } = props;
  useTicker(props.updateTicker, enableSyncTicker, disableSyncTicker);
  if (loadingStore) {
    return <ActivityIndicator />;
  }
  return (
    <Switch>
      <PublicRoute path="/login" component={Login} />
      <PublicRoute path="/validate" component={Validate} />
      <PublicRoute path="/register" component={Register} />
      <PublicRoute path="/resetPassword" component={ResetPassword} />
      <Route path="/chat/:intercom_hash" component={ChatPage} />
      <Route path="/chart/:market?" component={TradingViewChartPage} />
      <AppRoute forcePublic path="/recoverPassword" component={RecoverPassword} />

      <PrivateRoute path="/buy" component={Transaction} />
      <PrivateRoute path="/sell" component={Transaction} />
      <PrivateRoute path="/stoploss" component={Transaction} />
      <PrivateRoute path="/trade/:marketConfig?" component={Trade} />

      <PrivateRoute path="/profile/:subRoute" component={Profile} />
      <PrivateRoute path="/profile" component={Profile} />
      <PrivateRoute path="/updateConfirm" component={Profile} />

      <PrivateRoute path="/extract" component={Extract} />

      <PrivateRoute path="/card/:subRoute" component={Card} />
      <PrivateRoute path="/card" component={Card} />


      <PrivateRoute path="/bankTransfers/:transactionRoute" component={BankTransfers} />
      <PrivateRoute path="/bankTransfers" component={BankTransfers} />

      <PrivateRoute path="/sendReceiveCoins/:subRoute" component={SendReceiveCoins} />
      <PrivateRoute path="/sendReceiveCoins" component={SendReceiveCoins} />
      <PrivateRoute path="/express-trade/:orderType" component={ExpressTrade} />

      <PrivateRoute path="/mgm/:subRoute" component={MGM} />

      <PrivateRoute
        path="/process-credit-card"
        component={(props) => {
          const search = Object.fromEntries(new URLSearchParams(props.location.search));
          return (
            JSON.stringify(search, null, 2)
          );
        }}
      />
      <Route render={() => <Redirect to="/express-trade/buy" />} />
    </Switch>
  );
};

const mapStateToProps = ({ credentials: { loadingStore } }) => ({
  loadingStore,
});
const mapDispatchToProps = dispatch => ({
  startSyncPublicData: () => dispatch(syncPublicData()),
  updateTicker: ticker => dispatch(updateTicker(ticker)),
  enableSyncTicker: () => dispatch({ type: 'ENABLE_SYNC_TICKER' }),
  disableSyncTicker: () => dispatch({ type: 'DISABLE_SYNC_TICKER' }),
  generateDeviceId: () => dispatch(generateDeviceId())
});
const enhance = compose(
  pure,
  connect(mapStateToProps, mapDispatchToProps),
  lifecycle({
    componentDidMount() {
      const { startSyncPublicData } = this.props;
      startSyncPublicData();
    },
      componentDidUpdate() {
          const { generateDeviceId, loadingStore } = this.props;
          if (!loadingStore)
              generateDeviceId();
      }
  }),
);

export default enhance(AuthExample);
