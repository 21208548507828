import React, { useState } from 'react'
import { compose } from 'recompose'
import { withStyles } from '@material-ui/core/styles'

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import Hidden from '@material-ui/core/Hidden'

import InfoTooltip from '../../../../components/InfoTooltip'
import {isMobile} from '../../../../utils/checkScreenHelpers'
import { connect } from 'react-redux';
import { getCurrencyBySymbol } from '../../../../models/Currency'

const styles = _theme => ({
  table: {
    tableLayout: 'fixed'
  },
  cancelIcon: {
    cursor: 'pointer',
  },
  tableRowOdd: {
    cursor: 'pointer',
  },
  tableRowEven: {
    cursor: 'pointer',
    backgroundColor: 'rgb(250, 250, 250)'
  },
  tooltip: {
    fontSize: _theme.typography.pxToRem(15)
  },
  tableRow: {
    cursor: 'pointer',
    [_theme.breakpoints.down('sm')]: {
      webkitTouchCallout: 'none', /* iOS Safari */
      webkitUserSelect: 'none', /* Safari */
      khtmlUserSelect: 'none', /* Konqueror HTML */
      mozUserSelect: 'none', /* Old versions of Firefox */
      msUserSelect: 'none', /* Internet Explorer/Edge */
      userSelect: 'none',
      height: '32px'
    },
  },
  '@keyframes fadeHighlight': {
    from:  {backgroundColor: '#fde5bd'},
    to: {backgroundColor: 'auto'}
  },
  highlitedRow: {
    animation: 'fadeHighlight 1.5s linear',
    cursor: 'pointer'
  },
  mainCell: {
    paddingRight: 0,
    fontSize: _theme.typography.pxToRem(isMobile() ? 11.5 : 13)
  },
  rangeCell: {
    fontWeight: 500,
    textAlign: 'center',
    '& span.separator': {
      color: _theme.palette.text.secondary,
      marginRight: 5,
      marginLeft: 5,
      fontWeight: 400
    }
  },
  rightCell: {
    paddingRight: 0,
  },
  subCell: {
    color: _theme.palette.text.secondary,
    cursor: 'pointer',
    paddingRight: 24,
    fontSize: _theme.typography.pxToRem(isMobile() ? 11.5 : 13),
    [_theme.breakpoints.between('sm','md')]: {
      fontSize: isMobile() ? _theme.typography.pxToRem(11.5) : '0.9vw'
    }
  },
  headerCell: {
    paddingRight: 0,
    minWidth: 300,
    position: 'sticky',
    top: 0,
    backgroundColor: '#fff',
    width: '100%',
  },
  actionCell: {
    width: 30
  }
});


const OrderBookTable = ({OrderBookTableRow, ...props}) => {
  const {
    t,
    classes,
    orders,
    isShowAllSelected,
    ordersType,
    copyRow,
    confirmCancelOrder,
    market,
    markets,
    currencies,

  } = props

  const [collapsedRow, setCollapsetRow] = useState(0)
  const columnWidth = isShowAllSelected ? '33%' : '25%';
  const marketConfig = markets[market];
  const currency = getCurrencyBySymbol(currencies, market.split('-')[0]);
  return (
    <Table classes={{root: classes.table}}>
      <TableHead>
        <TableRow>
          <TableCell colSpan={3} className={classes.headerCell} numeric>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <div style={{width: columnWidth, textAlign: 'left'}}>
                {t('transactions.prices')}
              </div>
              <div style={{width: columnWidth, textAlign: 'right'}}>
                {t('transactions.amount')}
              </div>
              <div style={{width: columnWidth, textAlign: 'right'}}>
                {t('transactions.total')} R$
              </div>
              {!isShowAllSelected &&
                  <div style={{width: columnWidth}}>
                    <InfoTooltip
                      title={t('info.execAmount')}
                      wrapperProps={{ style: { justifyContent: 'flex-end' } }}
                      gutterBottom
                    >
                      <Hidden smDown>
                        {t('transactions.exec_amount')} %
                      </Hidden>
                    </InfoTooltip>
                  </div>
                  }
            </div>
          </TableCell>
        </TableRow>
      </TableHead>

      <TableBody>
        {orders.map((order, i) =>
          <OrderBookTableRow
            i={i}
            t={t}
            key={`OrderBookTableRow__${order.id}`}
            classes={classes}
            isCollapsed={i === collapsedRow}
            setCollapsetRow={setCollapsetRow}
            order={order}
            ordersType={ordersType}
            copyRow={copyRow}
            confirmCancelOrder={confirmCancelOrder}
            isShowAllSelected={isShowAllSelected}
            market={market}
            marketConfig={marketConfig}
            currency={currency}
          />
        )}
      </TableBody>
    </Table>
  )
}
const mapStateToProps = state => ({
  market: state.market.selectedMarket,
  markets: state.currencies.markets,
  currencies: state.currencies.currencies
})
export default compose(
  withStyles(styles),
  connect(mapStateToProps)
)(OrderBookTable)
