import React, { Fragment } from 'react';
import { compose, withHandlers, withState } from 'recompose';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { translate } from 'react-i18next';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import { withStyles } from '@material-ui/core/styles';

import AccountListItemField from '../components/AccountListItemField';
import QRimageRender from '../components/QRimageRender';
import OtpInstructions from '../components/OtpInstructions';
import { generateOtpRequested, activateOtpRequested } from '../../../redux/actions';
import validate from '../../../utils/validate';
import SubmitButton from '../../../components/Forms/SubmitButton';
import renderBranch from '../../../components/renderBranch';

import {normalize2FA} from '../../../utils/NumberFormat/normalizers';
import {isMobile} from  '../../../utils/checkScreenHelpers';
import { SUPPORT_MAIL } from '../../../config';

const styles = theme => ({
  fieldItem: {
    marginRight : '30%'
  },
  otpCenter: {
    maxWidth: '1000px',
    margin:'20px auto 0',
  },
  flexCenter: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column'
  }
});
const OtpForm = props => {
  const {
    submitting,
    pristine,
    invalid,
    handleSubmit,
    t,
    otp_active,
    qrCodeInfos,
    classes,
    copied,
    setCopied,
    user:{
      email
    }
  } = props;

  let qrUrl = ''
  if(qrCodeInfos && qrCodeInfos.response){
    qrUrl = 'otpauth://totp/'
    +encodeURIComponent('bitPreço: '+ email)
    + '?secret='+qrCodeInfos.response.secret}

  return (
    <Fragment >

      {(!otp_active && (qrCodeInfos && qrCodeInfos.response && qrCodeInfos.response.success )) &&

       (!isMobile() ? <ListItem className={classes.otpCenter}>
            <div style={{display : 'flex', flexDirection: 'column'}}>
              <div style={{display: 'flex'}}>
                <QRimageRender
                  qrUrl={qrUrl}
                  textCode={qrCodeInfos.response.secret}
                  setCopied={setCopied}
                  copied={copied}
                  t={t}
                />
                <OtpInstructions/>
              </div>
              <ListItemSecondaryAction>
                <form onSubmit={handleSubmit}>
                  <AccountListItemField
                    autoFocus
                    normalize={normalize2FA}
                    primary={t('forms.otpToken.activate')}
                    i18nScope="forms.otpToken"
                    name="otp_token"
                    classes={classes}
                    canEdit
                    listItemSecondaryAction={(
                      <SubmitButton
                        submitText={t('common.save')}
                        disabled={submitting || (pristine || invalid)}
                      />
                    )}
                  />
                </form>

              </ListItemSecondaryAction>
            </div>
          </ListItem> :
          <React.Fragment>
            <div className={classes.flexCenter}>
              <QRimageRender
                qrUrl={qrUrl}
                textCode={qrCodeInfos.response.secret}
                setCopied={setCopied}
                copied={copied}
                t={t}
              />
            </div>
            <div className={classes.flexCenter}>
              <OtpInstructions isMobile/>
            </div>
            <form onSubmit={handleSubmit}>
                  <AccountListItemField
                    primary={t('forms.otpToken.activate')}
                    i18nScope="forms.otpToken"
                    normalize={normalize2FA}
                    name="otp_token"
                    canEdit
                    listItemSecondaryAction={(
                      <SubmitButton
                        submitText={t('common.save')}
                        disabled={submitting || (pristine || invalid)}
                      />
                    )}
                  />
                </form>
          </React.Fragment>
      )}
    </Fragment>
  );
};
const schemas = {
  otp_token: {
    type: String,
    min: 6,
    max: 6,
  },
};

const mapStateToProps = (state) => ({
  otp_active: Number(state.user.profile.otp_active),
  qrCodeInfos: state.appData.service.generateOtp,
  user: state.user.profile
});

const mapDispatchToProps = (dispatch) => ({
  requestGenerateOtp: () => dispatch(generateOtpRequested()),
  onSubmit: otpToken => dispatch(activateOtpRequested(otpToken))
});

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withState('copied', 'setCopied', false),
  withState('open', 'setOpen', false),
  translate(),
  withStyles(styles),
  withHandlers({
		generateOtp: (props) => ({requestGenerateOtp, otp_active}) => {
      if(otp_active)
          window.location.href = `mailto:${SUPPORT_MAIL}?subject=Regerar 2FA`;
       else
          requestGenerateOtp();
    },
    onSubmit :  ({onSubmit,setOpen}) => (otpToken)=> {
      setOpen(false);
      onSubmit(otpToken);
    }
  }),
  renderBranch(['otp_active']),
  reduxForm({
    form: 'activateOtp',
    validate: validate(schemas),
  }),
);

export default enhance(OtpForm);
