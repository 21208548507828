import {
  UPDATE_KEEP_ALIVE_TIMESTAMP,
  START_KEEP_ALIVE_CHECK
} from './actionTypes';

const updateKeepAliveTimestamp = timestamp => ({
  type: UPDATE_KEEP_ALIVE_TIMESTAMP,
  timestamp
});

const startKeepAliveCheck = () =>({
  type: START_KEEP_ALIVE_CHECK
});

export default {
  updateKeepAliveTimestamp,
  startKeepAliveCheck
};

export {
  updateKeepAliveTimestamp,
  startKeepAliveCheck
};
