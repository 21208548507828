import React from 'react';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core/styles';
import { translate } from 'react-i18next';

import ActivityIndicator from '../../components/ActivityIndicator';
import SummaryLabel from '../../components/Summary/SummaryLabel';
import PrefixedContent from '../../components/Summary/PrefixedContent';
import { MarketInfo } from '../../models/MarketInfo';

const styles = _theme => ({
  flex: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
  },
});

const Price = props => {
  const {
    priceType,
    price,
    t,
    classes,
    marketConfig
  } = props;

  const market = marketConfig ? new MarketInfo(marketConfig) : null
  const decimalScale = marketConfig ?  market.quotePrecision : 0;

  if (!price || !market) {
    return <ActivityIndicator color="secondary" size={16} />;
  }

  return (
    <div >
      <SummaryLabel sumaryTitle={t(`market.btc.${priceType}`)} />
      <div className={classes.flex}>
        <PrefixedContent prefix="R$ " value={price} decimalScale={decimalScale}/>

      </div>
    </div>
  );
};

const enhance = compose(
  translate(),
  withStyles(styles),
);

export default enhance(Price);
