import * as R from 'ramda';

export const cpfMask = (val = '') => {
  const valLength = val.length;

  if (valLength <= 3) {
    return val;
  }

  if (valLength <= 9) {
    const values = R.splitEvery(3, val);
    return R.join('.', values);
  }

  if (valLength <= 11) {
    const [cpf, digito] = R.splitAt(9, val);
    const values = R.splitEvery(3, cpf);
    return `${R.join('.', values)}-${digito}`;
  }
  return R.tail(val)
};
