import privateService from '../privateService';

const fetchOpenOrders = async (market) => {
  const options = {
    cmd: 'open_orders',
    method: 'POST',
  };
  const content = await privateService(options, { market });
  return content;
};

export default fetchOpenOrders;
