export const styles = (theme) => ({
  pinInputContainer: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
  },
  pinInput: {
    textSecurity:'disc',
    fontFamily: "text-security-disc",
    width: "2.2rem !important",
    height: "2.2rem",
    padding: "10px",
    borderRadius: "8px",
    border: "1px solid #E0E0E0",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
    color: "#101010",
    textAlign: "center",
    "&:focus": {
      border: "1px solid #F7B733",
    },
  },
});
