import {
  FETCH_BALANCE,
  UPDATE_BALANCE,
} from './actionTypes';

const fetchBalance = (token) => ({
  type: FETCH_BALANCE,
  token,
});

const updateBalance = balance => ({
  type: UPDATE_BALANCE,
  balance,
});


export default {
  fetchBalance,
  updateBalance,
};

export {
  fetchBalance,
  updateBalance,
};
