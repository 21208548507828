export * from './appBar/actions';
export * from './dialog/actions';
export * from './newsDialog/actions';
export * from './bottomNavigation/actions';
export * from './snacks/actions';
export * from './transactionsForm/actions'

export const muteAppVolume = () => ({
  type: 'DISABLE_APP_SOUND',
});

export const enableAppVolume = () => ({
  type: 'ENABLE_APP_SOUND',
});
