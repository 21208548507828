import service from '../service';

const fetchTicker = async (market = 'ALL-BRL') => {
  const options = {
    cmd: 'ticker',
    method: 'POST',
  };
  const content = await service(options)({ market });
  return content;
};

export default fetchTicker;
