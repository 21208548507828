import { compose, withProps } from 'recompose'
import { TRANSFER_CRIPTO_OPTIONS } from './constants'

export default compose(
  withProps(props => {
    const criptoList = TRANSFER_CRIPTO_OPTIONS
    return {
      criptoList
    }
  })
)
