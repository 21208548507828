export const styles = (theme) => ({
    rowContainer: {
        display: "flex",
        flexDirection: "row",
        gap: "8px",
        alignItems: "center",
        width: "100%",
        marginBottom: "10px"
    },
    availableBalanceText: {
        fontFamily: "'Roboto','Helvetica', 'Arial', 'sans-serif'",
        fontSize: "14px",
    },
     availableBalanceAmount: {
        fontWeight: "bold",
     },
     maxButton: {
        backgroundColor: theme.palette.secondary.main,
        padding: '5px 10px',
        borderRadius: '15px',
        fontWeight: 'bold',
     }
});
