export const roundBRL = (value) =>{
    if(checkValue(value))
        return Number( value.toString().slice(0, value.toString().lastIndexOf(".")+3));
    return value;
    }

const checkValue = (value)=>{
  return value.toString().includes('.');
}

/*
    Quando fazendo operações às vezes a representação fica: 5e-7, mas precisamos de 0.0000005
*/
export const fixScientificNotation = (n)=>{ //Só usar para decimais(contendo o E -)

    if(n.toString().includes('e')){
        const arr = Array.from(n.toString());
        const countTimes = Number(arr.slice(arr.indexOf('e')+2, arr.length).join(''));
        const number = Number(arr.slice( 0, arr.indexOf('e')).filter(e=> e!='.').join(''));
        let result = '';
        for (let i = 0 ;i < countTimes-1 ; i++){
            result = result + '0';
        }
        if(arr[arr.indexOf('e')+1] === '-')
            return `0.${result}${number}`;
        //return `${number}${result}`;
        //return n;
    }
    return n;
}

export const fixBTCV2 = (value)=>{
    if(value.toString().includes('e')){
        return value.toFixed(8).replace(/\.?0+$/,"");
    }
    return value;
}

export const isBellowE8 = (n)=>{
    const arr = Array.from(n.toString());
    return arr.indexOf('e')>-1 &&
           arr.indexOf('-')>-1 &&
           Number(arr.slice(arr.indexOf('e')+2, arr.length).join(''))>8;
}

export const toDecimalPrecision = (num, precision) => {
    try {
        const result = new RegExp(`^-?\\d+(?:\.\\d{0,${precision || -1}})?`);
        return Number(num.toString().match(result)[0]);
    } catch (e) {
        return num;
    }
};

export const cryptoRoundDown = value => {
    const precision = 100000000;
    return String( Math.floor(value * precision) / precision );
}
