import React from 'react';
import UseBalanceButton from './UseBalanceButton';

export const ButtonAmount25 = ({ children, usePercentage, ...rest }) => {
    return (
      <UseBalanceButton isActive={usePercentage===25} {...rest}>
        {children}
      </UseBalanceButton>
    );
  };
export const ButtonAmount50 = ({ children, usePercentage, ...rest }) => {
    return (
      <UseBalanceButton isActive={usePercentage===50} {...rest}>
        {children}
      </UseBalanceButton>
    );
  };
export const ButtonAmount75 = ({ children, usePercentage, ...rest }) => {
    return (
      <UseBalanceButton isActive={usePercentage===75} {...rest}>
        {children}
      </UseBalanceButton>
    );
  };
export const ButtonAmount100 = ({ children, usePercentage, ...rest }) => {
    return (
      <UseBalanceButton isActive={usePercentage===100} {...rest}>
        {children}
      </UseBalanceButton>
    );
  };