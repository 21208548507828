import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import { compose } from 'recompose';
import moment from 'moment';


import Grid from '@material-ui/core/Grid';

import ExtractTableData from './ExtractTableData';
import CurrencyTableDataContent from './CurrencyTableDataContent';
import AmountTableDataContent from './AmountTableDataContent';

const ExtractListItemContent = props => {
  const { t, extract, currency } = props;

  const hour = moment(extract.concluded)
  const parsedHour = hour.isValid()
    ? hour.format('HH:mm:ss')
    : t('common.pending');

  return (
    <Grid container justify="space-between" wrap="nowrap" >
      <ExtractTableData
        small
        caption={t('transactions.currency')}
        content={t(`transactions.${extract.currency}`, {defaultValue: extract.currency})}
      />

      <ExtractTableData
        small
        caption={t('common.hour')}
        content={parsedHour}
      />
      <ExtractTableData
        caption={t('transactions.amount')}
        content={<AmountTableDataContent currency={currency} extract={extract} />}
      />
      <ExtractTableData
        caption={t('transactions.balance')}
        content={<CurrencyTableDataContent currency={currency} extract={extract} value={Number(extract.balance)} />}
      />
    </Grid>
  );
};

ExtractListItemContent.propTypes = {
  t: PropTypes.func.isRequired,
  extract: PropTypes.shape({
    currency: PropTypes.string,
  }).isRequired,
};

const enhance = compose(
  translate(),
);

export default enhance(ExtractListItemContent);
