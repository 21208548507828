import { all } from 'redux-saga/effects';

import watchBalance from './userBalance/saga';
import watchExtract from './extract/saga';
import watchFees from './fees/saga';
import watchLimits from './limits/saga';
import watchBankAccount from './bankAccount/saga';
import watchPixAccount from './account/saga';
import watchProfile from './profile/saga';
import watchDocuments from './documents/saga';
import watchCards from './card/saga';
import watchMgm from './mgm/saga';


function* userSaga() {
  yield all([
    watchProfile(),
    watchExtract(),
    watchBalance(),
    watchFees(),
    watchLimits(),
    watchBankAccount(),
    watchPixAccount(),
    watchDocuments(),
    watchCards(),
    watchMgm(),
  ]);
}

export default userSaga;
