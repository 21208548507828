import * as R from "ramda";
import { compose, withProps } from "recompose";
import { translate } from "react-i18next";
import CriptoMaskedInput from "./CriptoMaskedInput";
import { connect } from "formik";

const getProps = (props) => {
  const {
    t,
    name,
    formatter,
    onChange,
    formik: { errors },
    disabled,
    value,
    keepHeightWithHelperText = true,
    label,
    prefix,
    placeholder,
  } = props;
  const clearedName = name && name.includes("-") ? name.split("-")[0] : name;
  const error = R.path(R.split(".", clearedName))(errors);
  const shouldDisplayError = !!error && !disabled;
  const helperText = shouldDisplayError
    ? R.is(String, error)
      ? t(error)
      : t(error.error, error)
    : keepHeightWithHelperText ? ' ' : null; // ensure same row's height

  const value_ = value === undefined ? "" : value;

  return {
    label,
    placeholder,
    prefix,
    shouldDisplayError,
    helperText,
    value: value_,
    onChange,
    formatter,
    disabled,
  };
};

export default compose(
  translate(),
  connect,
  withProps(getProps)
)(CriptoMaskedInput);
