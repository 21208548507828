import {
  SET_NEWS_READ,
  ADD_NEWS_DATA,
  FETCH_NEWS_DATA
} from './actionTypes'

export const setNewsRead = newsId => ({
  type: SET_NEWS_READ,
  newsId
})

export const addNewsData = news => ({
  type: ADD_NEWS_DATA,
  news
})

export const fetchNewsData = (frameName) => ({
  type: FETCH_NEWS_DATA,
  frameName
})

export default {
  setNewsRead,
  addNewsData,
  fetchNewsData
}
