export const styles = (theme) => ({
  cryptoConverterContainer: {
    margin: "19.40px 9px",
  },
  card: {
    maxWidth: 600,
    marginTop: theme.spacing.unit * 2,
    margin: "auto",
    paddingTop: theme.spacing.unit * 4,
    paddingRight: theme.spacing.unit * 8,
    paddingBottom: theme.spacing.unit * 4,
    paddingLeft: theme.spacing.unit * 8,

    [theme.breakpoints.down("xs")]: {
      paddingTop: theme.spacing.unit,
      paddingRight: theme.spacing.unit * 2,
      paddingBottom: theme.spacing.unit * 2,
      paddingLeft: theme.spacing.unit * 2,

    },
  },
  cardFade: {
    maxWidth: 600,
    marginTop: theme.spacing.unit * 2,
    margin: "auto",
    paddingTop: theme.spacing.unit * 4,
    paddingRight: theme.spacing.unit * 8,
    paddingBottom: theme.spacing.unit * 4,
    paddingLeft: theme.spacing.unit * 8,
    "&:hover": {
      background: "rgba(0,0,0,0.2)",
      zIndex: 1000,
    },
  },
  cardPlacement: {
    zIndex: 1050,
    width: 220,
    height: 130,
    marginTop: 120,
    left: "53%",
  },
  transactionContainer: {
    margin: "30px 0",
  },
  colorWhite: {
    color: "#FFFFFF",
    textAlign: "center",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
  },
  colorWhiteWithBold: {
    color: "#FFFFFF",
    textAlign: "center",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "normal",
  },
  switchButton: {
    position: "absolute",
    left: 'calc(50% - 18px)',
    top: 'calc(0% - 18px)',
    width: 36,
    height: 36,
    borderRadius: 40,
    border: "2px solid #FFFFFF",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
    backgroundColor: theme.palette.secondary.main,
  },
  switchButtonWrapper: {
    position: "relative",
  },
  executedOrders: {
    marginTop: "30px",
  },
  submitButton: theme.buyButton,
});
