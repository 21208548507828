import React from 'react';
import PropTypes from 'prop-types';
import TableCell from '@material-ui/core/TableCell';
import { withStyles } from '@material-ui/core';

const style = theme => {
  return {
    dense: {
      paddingLeft: theme.spacing.unit * 0.75,
      paddingRight: theme.spacing.unit * 0.75,
      '&:first-child': {
        paddingLeft: theme.spacing.unit * 1.5,
        paddingRight: theme.spacing.unit * 0.75,
      },
      '&:last-child': {
        paddingLeft: theme.spacing.unit * 0.75,
        paddingRight: theme.spacing.unit * 1.5,
      },
    },
  };
};

const DenseMobileCell = ({ children, classes, ...rest }) => {
  // console.log('>>>>>>>>>>>>> classes', classes);

  return (
    <TableCell padding="dense" className={classes.dense} {...rest}>
      {children}
    </TableCell>
  );
};

DenseMobileCell.propTypes = {
  children: PropTypes.node.isRequired,
};

export default withStyles(style)(DenseMobileCell);
