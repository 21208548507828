import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose'
import { translate } from 'react-i18next'
import Trade from './Trade';
import WarningContainer from '../../containers/WarningCoin/index';
import { getCurrencyBySymbol } from '../../models/Currency';
import ActivityIndicator from '../../components/ActivityIndicator';
import { withStyles } from "@material-ui/core/styles";
import { Grid, Hidden } from '@material-ui/core';
import GridItem from './GridItem';
import { getChartConfig } from "../../components/TVChartContainer/utils";
import TradingViewChart from "../../components/TradingViewChart";
import { styles } from './styles';

const OrderBookPage = (props) => {
  const { market, markets, currencies, classes, theme, token } = props;
  const symbol = props.market.split("-")[0];
  const currency = getCurrencyBySymbol(currencies, symbol);
  const hasMarketLoaded = Object.keys(markets).length ? true : false;
  const baseCurrencySymbol = currency.symbol;
  const quoteCurrencySymbol = "BRL";
  const chartConfig = getChartConfig(baseCurrencySymbol, quoteCurrencySymbol);


  const allowedToken = markets[market] && !markets[market].orderbook_disable;
  return (
    <Fragment>
      {hasMarketLoaded ? (
        allowedToken ? (
          <>
          <Hidden smDown>
            <Grid className={classes.tradingViewContainer} container>
              <GridItem sm={12}>
                {currency && (
                  <TradingViewChart
                    key={chartConfig.key}
                    chartConfig={chartConfig}
                    token={token}
                  />
                )}
              </GridItem>
            </Grid>
            </Hidden>
            <Trade {...props} />
          </>
        ) : (
          <WarningContainer {...props} currency={currency} />
        )
      ) : (
        <div className={classes.loaderWrapper}>
          <ActivityIndicator color={theme.palette.secondary.main} size={50} />
        </div>
      )}
    </Fragment>
  );
};

const mapStateToProps = state => ({
  market: state.market.selectedMarket,
  token: state.credentials.token,
  markets: state.currencies.markets,
  currencies: state.currencies.currencies,
});
const enhance = compose(
  connect(mapStateToProps),
  translate(),
  withStyles(styles, { withTheme: true })
);

export default enhance(OrderBookPage)
