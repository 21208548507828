import LOGO_INTER from '../../../assets/LOGO_INTER.png'
import LOGO_NEON from '../../../assets/LOGO_NEON.png'
import LOGO_OUTROS from '../../../assets/LOGO_OUTROS.png'
import LOGO_BB from '../../../assets/LOGO_BB.png'
import LOGO_GLOBAL from '../../../assets/LOGO_GLOBALCARD.png'
import LOGO_BRASIL_PLURAL from '../../../assets/LOGO_BRASIL_PLURAL.png'
import LOGO_BRZ from '../../../assets/logo_BRZ.svg';
import LOGO_CBRL from '../../../assets/logo_CBRL.jpg';
import LOGO_PIX_ICON from '../../../assets/logo_pix_icon.png';
import QR_PIX from '../../../assets/qrpix.jpeg';

export const HIDDEN_BANKS = ['AB']
export const OTHER_BANKS = "OTHER_BANKS";

export const TRANSFER_FIAT_WITHDRAWAL_OPTIONS = [
  // { bankName: 'Global Card', address: 'GC|ONLINE' },
  { bankName: 'PIX', address: '' },
  //{ bankName: 'Alterbank', address: 'AB|ONLINE' },
  //Remove Temporary ERC20 options
  //{ bankName: 'BitcoinToYou', address: '' },
  //{ bankName: 'Bitrecife', address: '' },
  //{ bankName: 'Biscoint', address: '' },
  //{ bankName: 'Kamoney', address: '' },

];

export const BLOCK_TRANSFER_OPTION_WARNING = ['Alterbank', 'GC']

export const CBRL_EXCHANGE_OPTIONS = ['BitcoinToYou','Bitrecife', 'Biscoint', 'Kamoney'];

export const TRANSFER_CRIPTO_OPTIONS = [
  //{ bankName: 'CryptoBRL', bank: 'CBRL', currency: 'CBRL', logo: LOGO_CBRL,
  //  blockchainOptions: [{blockchain: "ERC20", value: "ETH", label: "ERC20 (Ethereum)"},
  //    {blockchain: "BEP20", value: "BSC", label: "BEP20 (Binance)"}]},
  { bankName: 'BRZ', bank: 'BRZ', currency: 'BRZ', logo: LOGO_BRZ,
    blockchainOptions: [ {blockchain: "ERC20", value: "ETH", label: "ERC20 (Ethereum)"},
      {blockchain: "BEP20", value: "BSC", label: "BEP20 (BSC)"},
      {blockchain: "SOL", value: "SOL", label: "SOL (Solana)"}]
  }
];

export const DEPOSIT_CRIPTO_TAX = {
};

export const MIN_TRANSFER_VALUE = 100

export const TRANSFER_TYPES = {
  WITHDRAWAL: 'WITHDRAWAL',
  DEPOSIT: 'DEPOSIT'
}

export const CRIPTO = 'CRIPTO';
export const FIAT = 'FIAT';

export const TRANSFER_CURRENCY_TYPES = {
  FIAT: FIAT,
  CRIPTO: CRIPTO
}




export const warningSettings = {
  'OTHER_BANKS': {
    title:
    'Depósitos vindos de outros bancos devem ser feitos na conta do Banco Genial',
    from: 'PIX',
    to: 'Banco Genial (Plural) - 125',
    fromLogo: LOGO_PIX_ICON,
    toLogo: LOGO_BRASIL_PLURAL,
    pixLogo: LOGO_PIX_ICON,
    qrPix: QR_PIX
  },
  '001': {
    title:
    'Para maior agilidade na aprovação do seu depósito informe abaixo a agência (com dígito) e o número da sua conta do Banco do Brasil',
    from: 'Banco do Brasil',
    to: 'Banco do Brasil',
    fromLogo: LOGO_BB,
    toLogo: LOGO_BB
  },
  '077': {
    title:
    'Não faça depósitos de outros bancos para o INTER pois a aprovação poderá demorar para ser efetivada. Faça Inter p/ Inter',
    from: 'Inter',
    to: 'Inter',
    fromLogo: LOGO_INTER,
    toLogo: LOGO_INTER
  },
  '655': {
    title:
    'Não faça depósitos de outros bancos para o NEON pois a aprovação poderá demorar para ser efetivada. Faça Neon p/ Neon',
    from: 'Neon',
    to: 'Neon',
    fromLogo: LOGO_NEON,
    toLogo: LOGO_NEON
  },
  '125': {
    title:
      'Depósitos vindos de outros bancos devem ser feitos na conta do Banco Genial',
    from: 'Outros Bancos',
    to: 'Banco Genial (Plural) - 125',
    fromLogo: LOGO_OUTROS,
    toLogo: LOGO_BRASIL_PLURAL
  },
  'GC': {
    title:
    'Finalize seu checkout pela globalcard',
    from: '',
    fromLogo: LOGO_GLOBAL
  }
};

