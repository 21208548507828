export const isObjectNotEmpty = (obj) => {
  for (let key in obj) {
    if (obj[key] !== null && obj[key] !== "") return true;
  }
  return false;
};

export const isObjectMapEmpty = (objectMap) => {
  for (let index in objectMap) {
    if (isObjectNotEmpty(objectMap[index])) {
      return false;
    }
  }
  return true;
};
