import { FETCH_ORDER_BOOK_SUCCEED, FETCH_ORDER_BOOK_FAILED, UPDATE_ORDER_BOOK } from './actionTypes';

const fetchOrderBookRequested = () => ({
  type: 'FETCH_SERVICE_REQUESTED',
  requestType: 'fetchOrderBook',
  successAction: FETCH_ORDER_BOOK_SUCCEED,
  failAction: FETCH_ORDER_BOOK_FAILED,
  paramns: {
    privateService: false,
    options: {
      cmd: 'detailed_orderbook',
      method: 'POST',
    },
  },
});

const updateOrderBook = (action) => ({
  ...action,
  type: UPDATE_ORDER_BOOK,
});

const fetchOrderSucceed = response => ({
  type: FETCH_ORDER_BOOK_SUCCEED,
  response,
});

export default {
  fetchOrderBookRequested,
  fetchOrderSucceed,
  updateOrderBook,
};

export {
  fetchOrderBookRequested,
  fetchOrderSucceed,
  updateOrderBook,
};
