import React, { useEffect, useRef, useState } from 'react'
import { compose } from 'recompose'
import { translate } from 'react-i18next'
import classNames from 'classnames'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import SubmitButton from '../../../Trade/TradeOrderForm/SubmitButton'
import CurrencyFormat from '../../../../components/Currency/CurrencyFormat'
import MoneyWithPercentageButtonsInput from '../../../Trade/TradeOrderForm/MoneyWithPercentageButtonsInput'
import { getPrice } from '../../../Trade/TradeOrderForm/marketPriceCalculator'
import withExpressTradeForm from './withExpressTradeForm'
import Select from '../../../../components/FormsV2/Select';
import SelectItemChip from '../../../../components/SelectItemChip';
import { ColorRing } from 'react-loader-spinner'
import isNilOrEmpty from '../../../../utils/isNilOrEmpty'
import moment from 'moment'
import CircleTimer from '../../../../components/Loaders/CircleTimer'
import { getCurrencyBySymbol, Currency } from '../../../../models/Currency'
import { MarketInfo, getVisibleMarkets } from '../../../../models/MarketInfo'
import OperationsDisabledCard from '../../../../components/Cards/OperationsDisabledCard'
import DangerouslySetInnerHTML from "../../../../components/DangerouslySetInnerHTML";
import { styles } from './styles'
import { checkNoTransferOperationsWarning, setNoTransferOperationsWarning } from '../../../../utils/cookies'
import { FIREBASE_CURRENCIES_ISSUES } from '../../../../config'
import InfoTooltip from '../../../../components/InfoTooltip'
import getCurrencyIssuesFoundBySymbolAndScreen from '../../../../utils/firebaseUtils'
import { CurrencyIssue } from '../../../../models/CurrencyIssue'

const getOrderbookPrice = (props) => {
  const { values, setFieldValue } = props;
  const acumulator = values.orderType === 'BUY'
    ? 'AMOUNTBTC'
    : 'TOTALVALUE';
  const price = getPrice(acumulator)(props);
  setFieldValue('price', price, false);
}

const finishedProgressBar = (props) => {
  const { lastInput, values, orderType, market, getQuote, setPlayCounter, setKeyCounter } = props;
  const param = lastInput === 'totalValue' ? 'quote_amount' : 'base_amount';
  if(values[`${lastInput}`]){
    setPlayCounter(false)
    getQuote(param, values[`${lastInput}`], orderType, market);
    setKeyCounter(prevKey => prevKey + 1)
  }
}

const ExpressTradeForm = props => {
  const {
    t,
    classes,
    orderType,
    resetForm,
    values,
    handleSubmit,
    setFieldValue,
    handleAmountChange,
    handleValueChange,
    updateForm,
    confirmTransaction,
    handleExpressAmountPercentChange,
    handleExpressTotalValuePercentChange,
    shouldDisplayTotalValuePercentageSwitch,
    shouldDisplayAmountPercentageSwitch,
    isDemoAccount,
    showConfirmTransaction,
    lastInput,
    quoteValues,
    setLoader,
    loader,
    playCounter,
    setPlayCounter,
    duration,
    keyCounter,
    setDuration,
    market,
    setErrors,
    setRemainingTime,
    errors,
    getQuote,
    currencies,
    markets,
    anchorEl,
    setAnchorEl,
    hasNoWithdrawalOrDepositWarning,
    userId
  } = props
  const symbol = values.currency;
  const mercadoPagoButton = useRef(null);
  const marketsList = getVisibleMarkets(markets);
  const [disabledMarket, setDisabledMarket] = useState();
  const [coinSelected, setCoinSelected] = useState();
  const [currenciesIssuesFound, setCurrenciesIssuesFound] = useState();

  const isDisabled = marketsList.some((coin)=> coin.base === symbol && coin.rfq_disabled);
  const quoteCurrencyData = getCurrencyBySymbol(currencies, 'BRL');
  const quoteCurrency = new Currency(quoteCurrencyData);
  let quoteCurrencyFormatter = quoteCurrency._defaultFormatter();
  const criptoCurrencyData = getCurrencyBySymbol(currencies, symbol);
  const criptoCurrency = new Currency(criptoCurrencyData);
  let criptoCurrencyFormatter = criptoCurrency._defaultFormatter();
  const allowedToDepositWithdrawToken = criptoCurrency.hasDeposit && criptoCurrency.hasWithdrawal;
  const currencyMarketData = markets[market];
  const priceDisplayQuoteCurrencyFormatter = quoteCurrency._defaultFormatter();
  const currencymarket = new MarketInfo(currencyMarketData ?? {});
  if (currencyMarketData) {
    priceDisplayQuoteCurrencyFormatter.decimalScale = currencymarket.quotePrecision;
  }
  const { firebaseCurrenciesIssues } = FIREBASE_CURRENCIES_ISSUES;

  useEffect(() => {
    if (firebaseCurrenciesIssues) {
      const currenciesIssuesData = getCurrencyIssuesFoundBySymbolAndScreen(
        firebaseCurrenciesIssues,
        symbol,
        orderType
      );
      const currenciesIssues =
        currenciesIssuesData &&
        currenciesIssuesData.map(CurrencyIssue.fromJson);
      setCurrenciesIssuesFound(currenciesIssues);
    }
  }, [firebaseCurrenciesIssues]);

  const submitData = () => {
    updateForm({
      market: market,
      quoteID: quoteValues.quote_id,
      totalValue: quoteValues.quote_amount,
      amount: quoteValues.base_amount,
      price: quoteValues.price,
      isPlaying: true,
      duration: duration,
      colors: [coinSelected.color],
      size: 33,
      strokeWidth: 2.5,
      keyCounter: keyCounter,
      resetExpressForm: resetForm,
      currencyConfig: criptoCurrencyData,
      quoteCurrencyConfig: quoteCurrencyData,
    });
  }


  useEffect(()=> {
      setCoinSelected(currencies.find((item)=> item.symbol === market.split('-')[0]));
      
  }, []);
  useEffect(() => {
    setDisabledMarket(isDisabled);
    if (!allowedToDepositWithdrawToken) {
      const tradeType = 'expressTrade';
      const hasViewedWarning = checkNoTransferOperationsWarning(userId, symbol, tradeType);
      if (!hasViewedWarning) {
      hasNoWithdrawalOrDepositWarning(symbol);
      setNoTransferOperationsWarning(userId, symbol, tradeType);
      }
    }

  }, [isDisabled, allowedToDepositWithdrawToken]);

  useEffect(() => {
    if (mercadoPagoButton && mercadoPagoButton.current) {
      mercadoPagoButton.current.innerHTML = ''
    }
    const script = document.createElement('script')
    script.src = 'https://www.mercadopago.com.br/integrations/v1/web-tokenize-checkout.js'
    script.setAttribute('data-public-key', 'TEST-e135c93c-0252-4d21-8def-92896344c41a')
    script.async = true
    script.setAttribute('data-transaction-amount', `${values.value}`)
    mercadoPagoButton.current.appendChild(script)
  }, [mercadoPagoButton, values.value])

  useEffect(() => {
      getOrderbookPrice(props);     
  }, [props.sellOrdersSerialized, props.buyOrdersSerialized])

  useEffect(() => {
    resetForm();
    getOrderbookPrice(props);     
  }, [orderType])


  useEffect(() =>{
    if(quoteValues && quoteValues.success){
      if(lastInput === 'totalValue' && quoteValues.base_amount !== values.amount && values.totalValue > 0){
        setFieldValue('amount', quoteValues.base_amount);
        setLoader(false);
      }
      if(lastInput === 'amount' && quoteValues.quote_amount !== values.totalValue && values.amount > 0){
        setFieldValue('totalValue', quoteValues.quote_amount);
        setLoader(false);
      }
      if( (values.totalValue > 0 || values.amount > 0) && quoteValues){
        let expirationAux = quoteValues.expiration.slice(0, -2) + ':' + quoteValues.expiration.slice(-2);
        const newDuration = moment(expirationAux).diff(moment().format(), 'seconds')
        setDuration(newDuration);
        submitData()
        setPlayCounter(true)
        setDuration(moment(quoteValues.expiration).diff(quoteValues.timestamp, 'seconds'));
      }
    }else if(quoteValues && quoteValues.success === false){
      setErrors({[lastInput]: quoteValues.message_cod})
      setLoader(false);
    }
  },[quoteValues]);

  useEffect(()=>{
    //get_quote just to have a value to put in the estimated quote
    getQuote("quote_amount", 10, orderType, market)
    resetForm();
  },[market])
  
  const submitButtonClass = classNames(orderType.toUpperCase() === 'BUY'
    ? classes.buy
    : classes.sell,
    classes.buttons
  )

  const hasQuoteValue = quoteValues && quoteValues.price;
  return (
    <React.Fragment>
      <form
        style={{ display: "none" }}
        onSubmit={(e) => e.preventDefault()}
        ref={mercadoPagoButton}
        action="/process-credit-card"
      />
      <form className={classes.root} onSubmit={handleSubmit}>
        <Grid container spacing={32}>
          <Grid container xs={12} justify="center">
            <Grid item>
              <FormControl component="fieldset">
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Typography variant="body2">
                    {t("transactions.chooseCurrency")}
                  </Typography>
                </div>
                <Select
                  value={currencyMarketData}
                  placeholder={values.currency}
                  customHandleClick={(e) => {
                    anchorEl ? setAnchorEl(null) : setAnchorEl(e.currentTarget);
                  }}
                  getLabel={(option) => {
                    const currencyConfig = getCurrencyBySymbol(
                      currencies,
                      option.base
                    );
                    return (
                      <SelectItemChip
                        itemName={currencyConfig.symbol}
                        itemCode={option.base}
                        currency={currencyConfig}
                      />
                    );
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <DangerouslySetInnerHTML
            className={classes.ctaText}
            Component='p'
              title={t(
                "screens.expressTrade.insertAmountInFiatToTradeInCripto",
                {
                  orderType: (orderType == "BUY"
                    ? t("screens.expressTrade.toBuy")
                    : t("screens.expressTrade.toSell")
                  ).toLowerCase(),
                  coin: criptoCurrency.name,
                  fiatCoin: t("transactions.FIAT").toLowerCase(),
                }
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <MoneyWithPercentageButtonsInput
              disabled={isDemoAccount || disabledMarket}
              fullWidth
              name="totalValue"
              value={values.totalValue}
              onChange={handleValueChange}
              i18nScope="screens.expressTrade.value"
              formatter={quoteCurrencyFormatter}
              shouldDisplayPercentageSwitch={
                shouldDisplayTotalValuePercentageSwitch
              }
              selectedPercentage={values.totalValuePercentage}
              setPercentage={handleExpressTotalValuePercentChange}
            />
          </Grid>

          <Grid item xs={12}>
            <MoneyWithPercentageButtonsInput
              disabled={isDemoAccount || disabledMarket}
              fullWidth
              name={"amount"}
              onChange={handleAmountChange}
              value={values.amount}
              formatter={criptoCurrencyFormatter}
              i18nContext={{
                tokenName: values.currency,
                decimals: "0".repeat(criptoCurrencyFormatter.decimalScale),
              }}
              shouldDisplayPercentageSwitch={
                shouldDisplayAmountPercentageSwitch
              }
              selectedPercentage={values.amountPercentage}
              setPercentage={handleExpressAmountPercentChange}
            />
          </Grid>

          <Grid container xs={12} justify="center" className={classes.relative}>
            <Grid item>
              <Typography>
                {t('screens.expressTrade.ConfirmTransactionDialog.estimatedQuote')} <b>{values.currency}</b>
              </Typography>
              {!loader && (
                <Typography align="center" className={classes.price}>
                  <CurrencyFormat
                    value={hasQuoteValue ? quoteValues.price : values.price}
                    formatter={priceDisplayQuoteCurrencyFormatter}
                    isFiat
                  />
                </Typography>
              )}
            </Grid>
            {!isNilOrEmpty(quoteValues) &&
              (values.totalValue > 0 || values.amount > 0) &&
              !loader &&
              isNilOrEmpty(errors) && (
                <div
                  className={classNames(
                    classes.progressBar,
                    showConfirmTransaction && classes.hidden
                  )}
                >
                  <CircleTimer
                    isPlaying={playCounter}
                    duration={duration}
                    colors={[`${coinSelected.color}`]}
                    size={40}
                    strokeWidth={3}
                    keyCounter={keyCounter}
                    setRemainingTime={setRemainingTime}
                    onComplete={() => finishedProgressBar(props)}
                  />
                </div>
              )}
          </Grid>

          {currenciesIssuesFound &&
            currenciesIssuesFound.map((currencyIssue, index) => (
              <Grid
               key={`currencyIssue-${index}`}
                container
                xs={12}
                justify="center"
                className={classes.currencyIssueSpacing}
              >
                <Typography variant="body2">{currencyIssue.title}</Typography>
                <InfoTooltip
                  wrapperRootHeader={true}
                  title={currencyIssue.description.join(" ")}
                />
              </Grid>
            ))}

          {loader && (values.totalValue || values.amount) && (
            <Grid item xs={12} className={classes.center}>
              <ColorRing
                visible={true}
                height="50"
                width="50"
                ariaLabel="blocks-loading"
                wrapperStyle={{}}
                wrapperClass="blocks-wrapper"
                colors={["#bababa", "#bababa", "#bababa", "#bababa", "#bababa"]}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <SubmitButton
              blockUserSubmit={isDemoAccount}
              className={submitButtonClass}
              fullWidth
              color="secondary"
              submitText={t(
                `screens.expressTrade.balanceButton${orderType.toUpperCase()}`
              )}
              type="button"
              classes={classes}
              t={t}
              disabled={disabledMarket || loader || !isNilOrEmpty(errors)}
              onClick={async () => {
                if (values.totalValue && values.amount) {
                  submitData();
                  confirmTransaction();
                }
              }}
            />
          </Grid>
        </Grid>
        {!allowedToDepositWithdrawToken && (
          <OperationsDisabledCard currencyName={values.currency} />
        )}
      </form>
    </React.Fragment>
  );
}
const enhance = compose(
  withExpressTradeForm,
  withStyles(styles),
  translate()
)

export default enhance(ExpressTradeForm)

