import React from 'react';
import { Button, Card, CardContent, Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import CurrencyIcon from '../../components/CurrencyIcon';
import { Link } from 'react-router-dom';

const WarningContainer = (props) => {
  const { t, classes, currency, handleClick, anchorEl, locationPath, coinsSelectorExtraData } = props;
  const screen = locationPath ? locationPath : 'trade';
  return (
    <div className={classes.gridContainer}>
      <Grid container style={{position:'relative'}}>
          <div className={classes.cardHeader}>
            <CurrencyIcon currency={currency}/>
          </div>
        <Card className={classes.cardWarning}>
          <CardContent className={classes.cardContent}>
            <Typography variant="body2" className={'title'}>{t(`screens.cryptoWarning.${screen}.title`, {coin: currency.name})}</Typography>
            <Typography variant="body2" className={classNames(classes.text, 'content')}>{t(`screens.cryptoWarning.${screen}.info`)}</Typography>
            <Grid container direction="column" className={classes.buttonContainer} spacing={3}>
              <Button
                variant="raised"
                color= {"secondary"}
                className={classNames('first-button', classes.text)}
                component={Link}
                to="/express-trade/buy"
              >
                {t(`screens.cryptoWarning.button`)}
              </Button>
              
              <Button
                className={classNames('second-button', classes.text)}
                onClick={(e) => handleClick(anchorEl ? null : e.currentTarget, coinsSelectorExtraData)}
              >
                {t(`screens.cryptoWarning.${screen}.button`)}
              </Button>
            </Grid>
          </CardContent>
        </Card>  
      </Grid>
    </div>
  );
};
export default WarningContainer;
