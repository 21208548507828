import React from "react";
import * as R from "ramda";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  compose,
  withHandlers,
  setPropTypes,
  defaultProps,
  withState,
  lifecycle,
} from "recompose";
import BTCField from "./BTCField";
import SwitchButtonWrapper from "../Buttons/SwitchButtonWrapper";
import InputAdornment from "@material-ui/core/InputAdornment";

import { withStyles } from "@material-ui/core/styles";

import { isMobile } from "../../utils/checkScreenHelpers";

import {
  ButtonAmount25,
  ButtonAmount50,
  ButtonAmount75,
  ButtonAmount100,
} from "./PercentageAmountButtons";

import { getCurrencyBySymbol } from "../../models/Currency";

const styles = (theme) => ({
  switchButtonWrapper: {
    height: 20,
    width: 150,
    display: "flex",
    justifyContent: "space-between",
    border: "1px solid rgba(0, 0, 0, 0.23)",
    marginBotton: 0,
  },
});

const CriptoAmountField = (props) => {
  const {
    onUseCriptoBalance,
    canUseCriptoBalance,
    className,
    name,
    usePercentage,
    scene,
    theme,
    onManualChange,
    classes,
    currency,
    currencies,
    disabled,
  } = props;

  const currencyConfig =
    getCurrencyBySymbol(currencies, currency) ||
    getCurrencyBySymbol(currencies, "BTC");

  return (
    <BTCField
      currency={currencyConfig}
      disabled={disabled}
      fieldChange={(value) => {
        onManualChange(0)(value);
      }}
      name={name}
      style={
        scene === "sell"
          ? {
              marginLeft: theme.spacing.unit,
              marginRight: theme.spacing.unit,
              width: isMobile() ? "100%" : "50%",
            }
          : null
      }
      className={scene === "sell" ? null : className}
      InputProps={{
        endAdornment: canUseCriptoBalance && !disabled && (
          <InputAdornment
            position="end"
            style={{ alignItems: "flex-end", margin: "0" }}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <SwitchButtonWrapper className={classes.switchButtonWrapper}>
                <ButtonAmount25
                  usePercentage={usePercentage}
                  onClick={() => {
                    onUseCriptoBalance(25);
                  }}
                >
                  25%
                </ButtonAmount25>
                <ButtonAmount50
                  usePercentage={usePercentage}
                  onClick={() => {
                    onUseCriptoBalance(50);
                  }}
                >
                  50%
                </ButtonAmount50>
                <ButtonAmount75
                  usePercentage={usePercentage}
                  onClick={() => {
                    onUseCriptoBalance(75);
                  }}
                >
                  75%
                </ButtonAmount75>
                <ButtonAmount100
                  usePercentage={usePercentage}
                  onClick={() => {
                    onUseCriptoBalance(100);
                  }}
                >
                  100%
                </ButtonAmount100>
              </SwitchButtonWrapper>
            </div>
          </InputAdornment>
        ),
      }}
    />
  );
};

CriptoAmountField.propTypes = {
  onUseCriptoBalance: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => {
  return {
    balance: state.user.balance,
    currencies: state.currencies.currencies,
  };
};

const enhance = compose(
  setPropTypes({
    change: PropTypes.func.isRequired,
    balance: PropTypes.shape({
      BTC: PropTypes.number,
    }),
  }),
  withStyles(styles),
  withState("usePercentage", "setPercentage", 0),
  defaultProps({
    currency: "BTC",
  }),
  withHandlers({
    touchChange:
      ({ change, touch }) =>
      (field, value) => {
        touch(field);
        change(field, value);
      },
  }),
  connect(mapStateToProps),
  withHandlers({
    onUseCriptoBalance: (props) => (percentage) => {
      const {
        balance,
        touchChange,
        name,
        withFees,
        currency,
        setPercentage,
        setUncheckBTCPercent,
        setUncheckBRLPercent,
        limits,
      } = props;
      const minWithdrawal = limits.minWithdrawal
        ? limits.minWithdrawal
        : 0.0001;
      setPercentage(percentage);
      if (setUncheckBTCPercent) setUncheckBTCPercent(false);
      if (setUncheckBRLPercent) setUncheckBRLPercent(true);
      if (withFees && balance[currency] >= minWithdrawal) {
        const totalFees = R.pathOr(0, ["fees", "totalFees"], props);
        const balanceWithFeesDiscount =
          percentage > 0
            ? balance[currency] * (percentage / 100) - totalFees
            : balance[currency] - totalFees;
        touchChange(name, balanceWithFeesDiscount);
      } else {
        touchChange(
          name,
          percentage > 0
            ? balance[currency] * (percentage / 100)
            : balance[currency]
        );
      }
    },
    onManualChange: (props) => (percentage) => (value) => {
      const { setPercentage, balance, currency, setUncheckBRLPercent } = props;
      /**
       * checks the typed value and sets the respective percentage (highlights the button)
       */

      /* totalFees will be 0 if there is no fees */
      if (setUncheckBRLPercent) setUncheckBRLPercent(true);
      const totalFees = R.pathOr(0, ["fees", "totalFees"], props);
      if (value === balance[currency] * 0.25 - totalFees) {
        setPercentage(25);
      } else if (value === balance[currency] * 0.5 - totalFees) {
        setPercentage(50);
      } else if (value === balance[currency] * 0.75 - totalFees) {
        setPercentage(75);
      } else if (value === balance[currency] - totalFees) {
        setPercentage(100);
      } else {
        setPercentage(percentage);
      }
    },
  }),
  lifecycle({
    componentDidUpdate() {
      const {
        uncheckBTCPercent,
        usePercentage,
        setPercentage,
        useCriptoBalance,
        onUseCriptoBalance,
      } = this.props;

      if (uncheckBTCPercent && usePercentage !== 0 && !useCriptoBalance)
        setPercentage(0);
      if (usePercentage !== 0) onUseCriptoBalance(usePercentage);
    },
  })
);

export default enhance(CriptoAmountField);
