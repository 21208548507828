const colors = {
  red: {
    main: '#d32f2f',
    light: '#ff6659',
    dark: '#9a0007',
  },
  green: {
    main: '#00796b',
    light: '#48a999',
    dark: '#004c40',
  },
  primaryBase: '#101010',
  secondaryBase: 'rgb(0,160,255)',
  secondarOpaque: (opacity = 0.5) => `rgba(0,160,255,${opacity})`,
};
// secondary #00A0FF
const blue = {
  colors,
};

export default blue;
