const FETCH_PIX_ACCOUNT = 'FETCH_PIX_ACCOUNT';
const UPDATE_PIX_ACCOUNT = 'UPDATE_PIX_ACCOUNT';
const CREATE_PIX_ACCOUNT = 'CREATE_PIX_ACCOUNT';
const REMOVE_CREDENTIALS_ACCOUNT = 'REMOVE_CREDENTIALS_ACCOUNT'
const FAIL_CREATING_PIX_ACCOUNT = 'FAIL_CREATING_PIX_ACCOUNT'
const GET_PIX_ACCOUNT = 'GET_PIX_ACCOUNT'
export {
  FETCH_PIX_ACCOUNT,
  UPDATE_PIX_ACCOUNT,
  CREATE_PIX_ACCOUNT,
  REMOVE_CREDENTIALS_ACCOUNT,
  FAIL_CREATING_PIX_ACCOUNT,
  GET_PIX_ACCOUNT
};
