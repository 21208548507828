import React from 'react';
import { connect } from 'react-redux';
import { compose, withProps } from 'recompose';
import { reduxForm } from 'redux-form';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import CancelIcon from '@material-ui/icons/Cancel';

import { confirmCancelSendCoins } from '../../../../redux/actions';
import { withStyles } from '@material-ui/core';

const styles = () => ({
  cancelIcon: {
    cursor: 'pointer'
  }
});

const CancelTransfer = props => {
  const { handleSubmit, classes } = props;

  return (
    <form onSubmit={handleSubmit}>
      <Tooltip placement="top" disableFocusListener title="Cancelar">
        <IconButton type="submit">
          <CancelIcon classes={{ root: classes.cancelIcon }} color="error" />
        </IconButton>
      </Tooltip>
    </form>
  );
};
const mapDispatchToProps = dispatch => ({
  onSubmit: form => dispatch(confirmCancelSendCoins(form))
});

const enhance = compose(
  connect(
    null,
    mapDispatchToProps
  ),
  withProps(props => {
    const { transId: trans_id, currency } = props;
    const initialValues = {
			trans_id,
			currency,
    };
    return {
      initialValues,
      form: `cancel-${trans_id}`
    };
  }),
  withStyles(styles),
  reduxForm()
);

export default enhance(CancelTransfer);
