import React from 'react'
import MaterialTextField from '@material-ui/core/TextField'
import InfoTooltip from '../../InfoTooltip'


const TextFieldComponent = ({
  field,
  labelText,
  placeholder,
  shouldDisplayError,
  helperText,
  tooltipTitle,
  className,
  InputProps,
  ...props
}) => {

  const tooltip = (
    <InfoTooltip title={tooltipTitle}>
      {labelText}
    </InfoTooltip>
  )
  const label = !!tooltipTitle
    ? tooltip
    : labelText

  return (
    <MaterialTextField
      label={label}
      placeholder={placeholder}
      error={shouldDisplayError}
      helperText={helperText}
      autoComplete="off"
      className={className}
      InputProps={InputProps}
      {...field}
      {...props}
    />
  )
}

export default TextFieldComponent
