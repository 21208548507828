import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import NumberFormat from 'react-number-format';
import TextField from './TextField';
import { BTC_SATOSHI_SCALE } from '../../config'
import { Currency } from '../../models/Currency';

const CLIPBOARD_DATA_TYPE = 'text/plain';
const EMPTY = "";

const removeWrongDots = (commaNumber) => {
	const cleanEntry = commaNumber.split(".").join(EMPTY);
	return cleanEntry.replace(',', '.');
};

const valueChangeFilter = (values, decimalScale) => {
	if ( !R.isNil(values.floatValue) ){
	 return decimalScale !== 0
			? values.floatValue.toFixed(decimalScale).replace(/\.?0+$/,"")
			: values.floatValue;
	}
	return '';
}

const BtcFormatInput = (props) => {
	const { inputRef, onChange, onBlur, value, fieldChange, currency, ...other } = props;

	const allowDotAsSeparator = (e) => {
		const { key, target } = e;
		const { selectionStart, value } = target;

		if (key === '.' && value[selectionStart] === ',') {
			e.preventDefault();
			target.selectionStart = selectionStart + 1;
		}
	};

	const onPasteMask = (e) => {
		const {target, clipboardData} = e;
		const clipValue = clipboardData.getData(CLIPBOARD_DATA_TYPE);
		const withComma = clipValue.replace('.', ',');
		const formattedValue = removeWrongDots(withComma);

		const decPlaces = Number(formattedValue).toFixed(BTC_SATOSHI_SCALE);
		target.value = decPlaces.replace('.', ',');
	};

  const hasNumericInput = R.not(R.isEmpty(value))
  const valueAsString = hasNumericInput
    ? Number(value).toFixed(BTC_SATOSHI_SCALE)
    : ''

  const currencyConfig = new Currency(currency);
  const formatter = currencyConfig._defaultFormatter();
  return (
    <NumberFormat
      {...other}
      value={valueAsString}
      ref={inputRef}
      onValueChange={(values) => {
        const value = valueChangeFilter(values, formatter.decimalScale);
        onChange(value);
        fieldChange(value);
      }}
      onKeyDown={allowDotAsSeparator}
      onPasteCapture={onPasteMask}
      onBlur={(_event) => {
        onBlur(value);
      }}
      // BTC values must ALWAYS be string
      // as BTC values get so small, javascript primitive Number uses
      // scientific notation which causes formatting bugs
      placeholder={currencyConfig.placeholder}
      isNumericString={true}
      prefix={formatter.prefix}
      decimalSeparator=","
      thousandSeparator="."
      decimalScale={formatter.decimalScale}
      fixedDecimalScale
      type="tel"
    />
  );
};

BtcFormatInput.defaultProps = {
	value: null
};

BtcFormatInput.propTypes = {
	value: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
	inputRef: PropTypes.any.isRequired,
	onChange: PropTypes.func.isRequired,
	onBlur: PropTypes.func.isRequired
};

const BTCField = ({ InputProps, fieldChange, ...props }) => {
	return (
		<TextField
			{...props}
			InputProps={{
				inputComponent: BtcFormatInput,
        inputProps: {
          fieldChange: fieldChange,
          currency: props.currency
        },
				...InputProps
			}}
		/>
	);
};

BTCField.defaultProps = {
	InputProps: {}
};

BTCField.propTypes = {
	InputProps: PropTypes.objectOf(PropTypes.any)
};

export default BTCField;
