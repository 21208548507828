import React from "react";
import TradingViewChartComponent from '../../components/TradingViewChart';
import { getChartConfig } from "../../components/TVChartContainer/utils";

const TradingViewChart = props => {
    const { match: { params : { baseCurrencySymbol, quoteCurrencySymbol } } } = props;
    const chartConfig = getChartConfig(baseCurrencySymbol, quoteCurrencySymbol);
    return (
        <TradingViewChartComponent
            key={chartConfig.key}
            chartConfig={chartConfig}
            hideExpandable
        />
    )
}

export default TradingViewChart;
