export const currencies = [
  {
    symbol: "USD",
    name: "Dollar",
    plural_name: "Dollars",
    monetary_symbol: "US$",
    color: "#65CFB1",
    exhibit_precision: 2,
    is_fiat: true,
    active: true,
    withdrawal: [],
    deposit: [],
    type: "exchange",
    isPublic: true,
  },
  {
    symbol: "CBRL",
    name: "CBRL",
    plural_name: "CBRLs",
    monetary_symbol: "CBRL",
    color: "#EAEAEA",
    exhibit_precision: 4,
    is_fiat: false,
    active: false,
    withdrawal: [],
    deposit: [],
    type: "exchange",
    isPublic: true,
  },
  {
    symbol: "ABFY",
    name: "Ambify",
    plural_name: "Ambifys",
    monetary_symbol: "ABFY",
    color: "#AFEC03",
    exhibit_precision: 4,
    is_fiat: false,
    active: true,
    withdrawal: [
      {
        network: "BSC",
        disabled: false,
        fixed_fees: {
          high: 10,
          medium: 10,
          low: 10,
        },
        percent_fee: 0,
        min_amount: 100,
        memo_enabled: false,
      },
    ],
    deposit: [
      {
        network: "BSC",
        disabled: false,
        fixed_fee: 0,
        percent_fee: 0,
        min_amount: 10,
        memo_enabled: false,
      },
    ],
    type: "exchange",
    isPublic: true,
  },
  {
    symbol: "ADA",
    name: "Ada-Cardano",
    plural_name: "Ada-Cardanos",
    monetary_symbol: "ADA",
    color: "#2565BB",
    exhibit_precision: 4,
    is_fiat: false,
    active: true,
    withdrawal: [
      {
        network: "ADA",
        disabled: false,
        fixed_fees: {
          high: 1,
          medium: 1,
          low: 1,
        },
        percent_fee: 0,
        min_amount: 5,
        memo_enabled: false,
      },
    ],
    deposit: [
      {
        network: "ADA",
        disabled: false,
        fixed_fee: 0,
        percent_fee: 0,
        min_amount: 0.1,
        memo_enabled: false,
      },
    ],
    type: "exchange",
    isPublic: true,
  },
  {
    symbol: "ATLAS",
    name: "Atlas",
    plural_name: "Atlas",
    monetary_symbol: "ATLAS",
    color: "#47AAAB",
    exhibit_precision: 4,
    is_fiat: false,
    active: true,
    withdrawal: [
      {
        network: "SOL",
        disabled: false,
        fixed_fees: {
          high: 100,
          medium: 100,
          low: 100,
        },
        percent_fee: 0,
        min_amount: 100,
        memo_enabled: false,
      },
    ],
    deposit: [
      {
        network: "SOL",
        disabled: false,
        fixed_fee: 0,
        percent_fee: 0,
        min_amount: 1,
        memo_enabled: false,
      },
    ],
    type: "exchange",
    isPublic: true,
  },
  {
    symbol: "AXS",
    name: "Axie",
    plural_name: "Axies",
    monetary_symbol: "AXS",
    color: "#00CDF4",
    exhibit_precision: 4,
    is_fiat: false,
    active: true,
    withdrawal: [
      {
        network: "ETH",
        disabled: false,
        fixed_fees: {
          high: 1,
          medium: 1,
          low: 1,
        },
        percent_fee: 0,
        min_amount: 1,
        memo_enabled: false,
      },
    ],
    deposit: [
      {
        network: "ETH",
        disabled: false,
        fixed_fee: 0,
        percent_fee: 0,
        min_amount: 1,
        memo_enabled: false,
      },
    ],
    type: "exchange",
    isPublic: true,
  },
  {
    symbol: "BNB",
    name: "BNB",
    plural_name: "BNBs",
    monetary_symbol: "BNB",
    color: "#E6B02B",
    exhibit_precision: 4,
    is_fiat: false,
    active: true,
    withdrawal: [
      {
        network: "BSC",
        disabled: false,
        fixed_fees: {
          high: 0.002,
          medium: 0.002,
          low: 0.002,
        },
        percent_fee: 0,
        min_amount: 0.01,
        memo_enabled: false,
      },
    ],
    deposit: [
      {
        network: "BSC",
        disabled: false,
        fixed_fee: 0,
        percent_fee: 0,
        min_amount: 0.001,
        memo_enabled: false,
      },
    ],
    type: "exchange",
    isPublic: true,
  },
  {
    symbol: "BRZ",
    name: "BRZ",
    plural_name: "BRZs",
    monetary_symbol: "BRZ",
    color: "#170636",
    exhibit_precision: 2,
    is_fiat: false,
    active: true,
    withdrawal: [
      {
        network: "ETH",
        disabled: false,
        fixed_fees: {
          high: 10,
          medium: 10,
          low: 10,
        },
        percent_fee: 0,
        min_amount: 100,
        memo_enabled: false,
      },
    ],
    deposit: [
      {
        network: "ETH",
        disabled: false,
        fixed_fee: 0,
        percent_fee: 0,
        min_amount: 10,
        memo_enabled: false,
      },
    ],
    type: "exchange",
    isPublic: true,
  },

  {
    symbol: "BTC",
    name: "Bitcoin",
    plural_name: "Bitcoins",
    monetary_symbol: "₿",
    color: "#FF9500",
    exhibit_precision: 8,
    is_fiat: false,
    active: true,
    withdrawal: [
      {
        network: "BTC",
        disabled: false,
        fixed_fees: {
          high: 0.0002,
          medium: 0.0001,
          low: 5.0e-5,
        },
        percent_fee: 0,
        min_amount: 0.0005,
        memo_enabled: false,
      },
      {
        network: "BSC",
        disabled: false,
        fixed_fees: {
          high: 2.0e-5,
          medium: 1.0e-5,
          low: 5.0e-6,
        },
        percent_fee: 0,
        min_amount: 0.0005,
        memo_enabled: false,
      },
      {
        network: "LN",
        disabled: false,
        fixed_fees: {
          high: 2.0e-5,
          medium: 1.0e-5,
          low: 5.0e-6,
        },
        percent_fee: 0,
        min_amount: 0.0005,
        memo_enabled: false,
      },
    ],
    deposit: [
      {
        network: "BTC",
        disabled: false,
        fixed_fee: 0,
        percent_fee: 0,
        min_amount: 0.0005,
        memo_enabled: false,
      },
      {
        network: "BSC",
        disabled: false,
        fixed_fee: 0,
        percent_fee: 0,
        min_amount: 0.0005,
        memo_enabled: false,
      },
      {
        network: "LN",
        disabled: false,
        fixed_fee: 0,
        percent_fee: 0,
        min_amount: 0.0005,
        memo_enabled: false,
      },
    ],
    type: "marketplace",
    isPublic: true,
  },
  {
    symbol: "CAKE",
    name: "Pancake Token",
    plural_name: "Pancake Tokens",
    monetary_symbol: "CAKE",
    color: "#BB743B",
    exhibit_precision: 4,
    is_fiat: false,
    active: true,
    withdrawal: [
      {
        network: "BSC",
        disabled: false,
        fixed_fees: {
          high: 0.2,
          medium: 0.2,
          low: 0.2,
        },
        percent_fee: 0,
        min_amount: 1,
        memo_enabled: false,
      },
    ],
    deposit: [
      {
        network: "BSC",
        disabled: false,
        fixed_fee: 0,
        percent_fee: 0,
        min_amount: 1,
        memo_enabled: false,
      },
    ],
    type: "exchange",
    isPublic: true,
  },
  {
    symbol: "CRZO",
    name: "Cruzeiro Token",
    plural_name: "Cruzeiro Tokens",
    monetary_symbol: "CRZO",
    color: "#70A0FF",
    exhibit_precision: 4,
    is_fiat: false,
    active: true,
    withdrawal: [
      {
        network: "BSC",
        disabled: false,
        fixed_fees: {
          high: 0.1,
          medium: 0.1,
          low: 0.1,
        },
        percent_fee: 0,
        min_amount: 1,
        memo_enabled: false,
      },
    ],
    deposit: [
      {
        network: "BSC",
        disabled: false,
        fixed_fee: 0,
        percent_fee: 0,
        min_amount: 1,
        memo_enabled: false,
      },
    ],
    type: "exchange",
    isPublic: true,
  },
  {
    symbol: "DOT",
    name: "Polkadot",
    plural_name: "Polkadots",
    monetary_symbol: "DOT",
    color: "#E6007A",
    exhibit_precision: 4,
    is_fiat: false,
    active: true,
    withdrawal: [],
    deposit: [],
    type: "exchange",
    isPublic: true,
  },
  {
    symbol: "ETH",
    name: "Ether",
    plural_name: "Ethers",
    monetary_symbol: "Ξ",
    color: "#7986CC",
    exhibit_precision: 8,
    is_fiat: false,
    active: true,
    withdrawal: [
      {
        network: "ETH",
        disabled: false,
        fixed_fees: {
          high: 0.001,
          medium: 0.001,
          low: 0.001,
        },
        percent_fee: 0,
        min_amount: 0.01,
        memo_enabled: false,
      },
      {
        network: "BSC",
        disabled: false,
        fixed_fees: {
          high: 0.0001,
          medium: 0.0001,
          low: 0.0001,
        },
        percent_fee: 0,
        min_amount: 0.01,
        memo_enabled: false,
      },
    ],
    deposit: [
      {
        network: "ETH",
        disabled: false,
        fixed_fee: 0,
        percent_fee: 0,
        min_amount: 0.005,
        memo_enabled: false,
      },
    ],
    type: "marketplace",
    isPublic: true,
  },
  {
    symbol: "GMT",
    name: "Stepn",
    plural_name: "Stepn",
    monetary_symbol: "GMT",
    color: "#E8CA7D",
    exhibit_precision: 4,
    is_fiat: false,
    active: true,
    withdrawal: [
      {
        network: "SOL",
        disabled: false,
        fixed_fees: {
          high: 0.5,
          medium: 0.5,
          low: 0.5,
        },
        percent_fee: 0,
        min_amount: 10,
        memo_enabled: false,
      },
    ],
    deposit: [
      {
        network: "SOL",
        disabled: false,
        fixed_fee: 0,
        percent_fee: 0,
        min_amount: 1,
        memo_enabled: false,
      },
    ],
    type: "exchange",
    isPublic: true,
  },
  {
    symbol: "LINK",
    name: "ChainLink",
    plural_name: "ChainLinks",
    monetary_symbol: "LINK",
    color: "#2A5ADA",
    exhibit_precision: 4,
    is_fiat: false,
    active: true,
    withdrawal: [],
    deposit: [],
    type: "exchange",
    isPublic: true,
  },
  {
    symbol: "LUNA",
    name: "Luna",
    plural_name: "Lunas",
    monetary_symbol: "LUNA",
    color: "#172447",
    exhibit_precision: 4,
    is_fiat: false,
    active: true,
    withdrawal: [],
    deposit: [],
    type: "exchange",
    isPublic: true,
  },
  {
    symbol: "LUNC",
    name: "Luna Classic",
    plural_name: "Lunas Classic",
    monetary_symbol: "LUNC",
    color: "#172447",
    exhibit_precision: 4,
    is_fiat: false,
    active: true,
    withdrawal: [],
    deposit: [],
    type: "exchange",
    isPublic: true,
  },
  {
    symbol: "MATIC",
    name: "Polygon",
    plural_name: "Polygons",
    monetary_symbol: "MATIC",
    color: "#8247E5",
    exhibit_precision: 4,
    is_fiat: false,
    active: true,
    withdrawal: [],
    deposit: [],
    type: "exchange",
    isPublic: true,
  },
  {
    symbol: "PAXG",
    name: "Ouro-PAX",
    plural_name: "Ouro-PAX",
    monetary_symbol: "PAXG",
    color: "#CCA727",
    exhibit_precision: 4,
    is_fiat: false,
    active: true,
    withdrawal: [
      {
        network: "ETH",
        disabled: false,
        fixed_fees: {
          high: 0.005,
          medium: 0.005,
          low: 0.005,
        },
        percent_fee: 0,
        min_amount: 0.01,
        memo_enabled: false,
      },
    ],
    deposit: [
      {
        network: "ETH",
        disabled: false,
        fixed_fee: 0,
        percent_fee: 0,
        min_amount: 0.01,
        memo_enabled: false,
      },
    ],
    type: "exchange",
    isPublic: true,
  },
  {
    symbol: "POLIS",
    name: "Polis",
    plural_name: "Polis",
    monetary_symbol: "POLIS",
    color: "#283044",
    exhibit_precision: 4,
    is_fiat: false,
    active: true,
    withdrawal: [
      {
        network: "SOL",
        disabled: false,
        fixed_fees: {
          high: 1,
          medium: 1,
          low: 1,
        },
        percent_fee: 0,
        min_amount: 1,
        memo_enabled: false,
      },
    ],
    deposit: [
      {
        network: "SOL",
        disabled: false,
        fixed_fee: 0,
        percent_fee: 0,
        min_amount: 1,
        memo_enabled: false,
      },
    ],
    type: "exchange",
    isPublic: true,
  },
  {
    symbol: "SLP",
    name: "Smooth Love Potion",
    plural_name: "Smooth Love Potions",
    monetary_symbol: "SLP",
    color: "#FF93A2",
    exhibit_precision: 4,
    is_fiat: false,
    active: true,
    withdrawal: [
      {
        network: "ETH",
        disabled: false,
        fixed_fees: {
          high: 3000,
          medium: 3000,
          low: 3000,
        },
        percent_fee: 0,
        min_amount: 100,
        memo_enabled: false,
      },
    ],
    deposit: [
      {
        network: "ETH",
        disabled: false,
        fixed_fee: 0,
        percent_fee: 0,
        min_amount: 100,
        memo_enabled: false,
      },
    ],
    type: "exchange",
    isPublic: true,
  },
  {
    symbol: "SOL",
    name: "Solana",
    plural_name: "Solanas",
    monetary_symbol: "SOL",
    color: "#069F9F",
    exhibit_precision: 4,
    is_fiat: false,
    active: true,
    withdrawal: [
      {
        network: "SOL",
        disabled: false,
        fixed_fees: {
          high: 0.01,
          medium: 0.01,
          low: 0.01,
        },
        percent_fee: 0,
        min_amount: 0.01,
        memo_enabled: false,
      },
    ],
    deposit: [
      {
        network: "SOL",
        disabled: false,
        fixed_fee: 0,
        percent_fee: 0,
        min_amount: 0.001,
        memo_enabled: false,
      },
    ],
    type: "exchange",
    isPublic: true,
  },
  {
    symbol: "UNI",
    name: "Uniswap Token",
    plural_name: "Uniswap Tokens",
    monetary_symbol: "UNI",
    color: "#D5136F",
    exhibit_precision: 4,
    is_fiat: false,
    active: true,
    withdrawal: [
      {
        network: "ETH",
        disabled: false,
        fixed_fees: {
          high: 1,
          medium: 1,
          low: 1,
        },
        percent_fee: 0,
        min_amount: 1,
        memo_enabled: false,
      },
    ],
    deposit: [
      {
        network: "ETH",
        disabled: false,
        fixed_fee: 0,
        percent_fee: 0,
        min_amount: 1,
        memo_enabled: false,
      },
    ],
    type: "exchange",
    isPublic: true,
  },
  {
    symbol: "USDC",
    name: "USDC",
    plural_name: "USDCs",
    monetary_symbol: "$",
    color: "#2775CA",
    exhibit_precision: 4,
    is_fiat: false,
    active: true,
    withdrawal: [
      {
        network: "ETH",
        disabled: false,
        fixed_fees: {
          high: 10,
          medium: 10,
          low: 10,
        },
        percent_fee: 0,
        min_amount: 1,
        memo_enabled: false,
      },
      {
        network: "SOL",
        disabled: false,
        fixed_fees: {
          high: 0.5,
          medium: 0.5,
          low: 0.5,
        },
        percent_fee: 0,
        min_amount: 1,
        memo_enabled: false,
      },
    ],
    deposit: [
      {
        network: "ETH",
        disabled: false,
        fixed_fee: 0,
        percent_fee: 0,
        min_amount: 1,
        memo_enabled: false,
      },
      {
        network: "SOL",
        disabled: false,
        fixed_fee: 0,
        percent_fee: 0,
        min_amount: 1,
        memo_enabled: false,
      },
    ],
    type: "exchange",
    isPublic: true,
  },
  {
    symbol: "USDT",
    name: "Dollar-Tether",
    plural_name: "Dollar-Tethers",
    monetary_symbol: "T",
    color: "#65CFB1",
    exhibit_precision: 4,
    is_fiat: false,
    active: true,
    withdrawal: [
      {
        network: "ETH",
        disabled: false,
        fixed_fees: {
          high: 10,
          medium: 10,
          low: 10,
        },
        percent_fee: 0,
        min_amount: 20,
        memo_enabled: false,
      },
      {
        network: "BSC",
        disabled: false,
        fixed_fees: {
          high: 0.5,
          medium: 0.5,
          low: 0.5,
        },
        percent_fee: 0,
        min_amount: 20,
        memo_enabled: false,
      },
    ],
    deposit: [
      {
        network: "ETH",
        disabled: false,
        fixed_fee: 0,
        percent_fee: 0,
        min_amount: 20,
        memo_enabled: false,
      },
      {
        network: "BSC",
        disabled: false,
        fixed_fee: 0,
        percent_fee: 0,
        min_amount: 20,
        memo_enabled: false,
      },
    ],
    type: "marketplace",
    isPublic: true,
  },
  {
    symbol: "XRP",
    name: "Ripple",
    plural_name: "Ripples",
    monetary_symbol: "XRP",
    color: "#23292F",
    exhibit_precision: 4,
    is_fiat: false,
    active: true,
    withdrawal: [],
    deposit: [],
    type: "exchange",
    isPublic: true,
  },
  {
    symbol: "BRL",
    name: "Real",
    plural_name: "Reais",
    monetary_symbol: "R$",
    color: "#169444",
    exhibit_precision: 2,
    is_fiat: true,
    active: true,
    withdrawal: [
      {
        network: "TED",
        disabled: false,
        fixed_fees: {
          high: 5,
          medium: 5,
          low: 5,
        },
        percent_fee: 0.2,
        min_amount: 0.01,
        memo_enabled: false,
      },
      {
        network: "PIX",
        disabled: false,
        fixed_fees: {
          high: 0,
          medium: 0,
          low: 0,
        },
        percent_fee: 0.2,
        min_amount: 0.01,
        memo_enabled: false,
      },
    ],
    deposit: [
      {
        network: "TED",
        disabled: false,
        fixed_fee: 0,
        percent_fee: 0,
        min_amount: 0.01,
        memo_enabled: false,
      },
      {
        network: "PIX",
        disabled: false,
        fixed_fee: 0,
        percent_fee: 0,
        min_amount: 0.01,
        memo_enabled: false,
      },
    ],
  },
];

